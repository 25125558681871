
const benefitsTranslations = {
    es_PE: {
        create: {
            academic_performance: {
                subjects: 'Cursos aprobados',
                validation_errors: {
                    subjects: 'Ingresa la cantidad de cursos (mínimo valor 1)',
                },
            },
        }
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Beneficios',
                description: 'Administración de beneficios'
            },
            bulk_delete: 'Eliminar beneficios',
            bulk_delete_error: 'No se pudieron eliminar los beneficios seleccionados',
            delete_error: 'No se pudo eliminar el beneficio seleccionado',
            headers: {
                code: 'Código',
                name: 'Nombre',
                benefit: 'Tipo',
                status: 'Estado',
                product: 'Producto',
                discount: 'Descuento',
            },
            referred: 'Referido',
            refer: 'Referente',
            filters: {
                active: 'Activo',
                inactive: 'Inactivo',
            },
            message: {
                actions: 'Acciones'
            },
            search:{
                gotopage:' Ir a pagina'
            }

        },
        create: {
            breadcrumbs: {
                title: 'Nuevo beneficio',
                description: 'Crea un nuevo beneficio'
            },
            title: 'Datos del beneficio',
            subtitle: 'Asigna la información básica del beneficio que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
            switchLabel: 'Habilitado',
            labels: {
                name: 'Nombre',
                code: 'Código',
                benefit_type: 'Tipo de beneficio',
                from: 'Aplica desde',
            },
            referred_friend: {
                refer_label: 'Monto que aplica al referente',
                referred_label: 'Monto que aplica al referido',
                amount: 'Monto',
            },
            unique_discount: {
                unique_label: 'Monto que aplica al descuento único',
            },
            community: {
                apply_to: 'Aplica a',
                or: 'ó',
                percentage: 'Porcentaje',
                amount: 'Monto',
            },
            validation_errors: {
                name: 'Ingresa el nombre del beneficio',
                code: 'Ingresa el código del beneficio',
                code_unique: 'Código ya existente',
                type: 'Selecciona un tipo de beneficio',
                codeAlphanumeric: 'Solo alfanuméricos',
                codeMaxLength: 'Máximo 10 caracteres',
                codeMinLength: 'Minimo 3 caracteres',
                errorDate : 'Fechas invalidas',
                descriptionMaxLength: 'Máximo 40 caracteres',
                descriptionAlphanumeric: 'Solo alfanuméricos',
            },
            sectionDocumentation:{
                title:'Documentación obligatoria',
                subtitle: 'Selecciona en esta sección la documentación necesaria para que este beneficio pueda ser aplicado.',
                transferList: {
                    firstTitle: 'Disponibles',
                    secondTitle: 'Seleccionada'
                },
                no_select: 'Precaucion la  documentación es obligatoria'
            },
            academic_performance: {
                rules_title: 'Reglas a las que aplica',
                rules_subtitle: 'Define un día y mes de comienzo y final para la vigencia del beneficio por desempeño académico. Ten en cuenta que podrás agregar la cantidad de fechas que creas necesario.',
                apply_to: 'Aplica a',
                from: 'Aplica desde',
                to: 'Aplica hasta',
                or: 'ó',
                percentage: 'Porcentaje',
                amount: 'Monto',
                average: 'Promedio necesario',
                subjects: 'Materias aprobadas',
                validation_errors: {
                    average: 'Ingresa un promedio (min. valor 1, max. valor, {maxAverage})',
                    subjects: 'Ingresa la cantidad de materias (minimo valor 1)',
                },
                rules: {
                    day: 'Día',
                    month: 'Mes',
                    from: 'Desde',
                    to: 'Hasta',
                    monthList: [
                        {id: 1, value: 'Enero', days: '31'},
                        {id: 2, value: 'Febrero', days: '29'},
                        {id: 3, value: 'Marzo', days: '31'},
                        {id: 4, value: 'Abril', days: '30'},
                        {id: 5, value: 'Mayo', days: '31'},
                        {id: 6, value: 'Junio', days: '30'},
                        {id: 7, value: 'Julio', days: '31'},
                        {id: 8, value: 'Agosto', days: '31'},
                        {id: 9, value: 'Septiembre', days: '30'},
                        {id: 10, value: 'Octubre', days: '31'},
                        {id: 11, value: 'Noviembre', days: '30'},
                        {id: 12, value: 'Diciembre', days: '31'},
                    ],
                    dayList: {
                        29: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
                        30: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
                        31: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                    }
                }
            },
            success_message: {
                title: 'Beneficio creado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear el beneficio',
                repeated: 'Ya existe ese tipo de beneficio para la fecha seleccionada',
            },
        },
        update: {
            breadcrumbs: {
                title: 'Editar beneficio',
                description: 'Modifica el beneficio seleccionado'
            },
            title: 'Datos del beneficio',
            subtitle: 'Asigna la información básica del beneficio que estás modificando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
            switchLabel: 'Habilitado',
            labels: {
                name: 'Nombre',
                code: 'Código',
                benefit_type: 'Tipo de beneficio',
                from: 'Aplica desde',
            },
            validation_errors: {
                name: 'Ingresa el nombre del beneficio',
                code: 'Ingresa el código del beneficio',
                code_unique: 'Código ya existente',
                type: 'Selecciona un tipo de beneficio',
                codeAlphanumeric: 'Solo alfanuméricos',
                codeMaxLength: 'Máximo 10 caracteres',
                codeMinLength: 'Minimo 3 caracteres',
                errorDate : 'Fechas invalidas',
                descriptionMaxLength: 'Máximo 40 caracteres',
                descriptionAlphanumeric: 'Solo alfanuméricos',
            },
            referred_friend: {
                from: 'Aplica desde',
                refer_label: 'Monto que aplica al referente',
                referred_label: 'Monto que aplica al referido',
                or: 'ó',
                percentage: 'Porcentaje',
                amount: 'Monto',
            },
            community: {
                apply_to: 'Aplica a',
                from: 'Aplica desde',
                or: 'ó',
                percentage: 'Porcentaje',
                amount: 'Monto',
            },
            academic_performance: {
                rules_title: 'Reglas a las que aplica',
                rules_subtitle: 'Reglas a cumplir para que el estudiante pueda aplicar al beneficio.',
                apply_to: 'Aplica a',
                from: 'Aplica desde',
                to: 'Aplica hasta',
                or: 'ó',
                percentage: 'Porcentaje',
                amount: 'Monto',
            },
            success_message: {
                title: 'Beneficio editado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar el beneficio',
                repeated: 'Ya existe ese tipo de beneficio para la fecha seleccionada',
            },
        },
        details:{
            breadcrumbs: {
                title: 'Detalles de beneficio',
                actions: 'Acciones'
            },
            resume: {
                productType: 'Aplica a ',
                product: 'Tipo de Benefico ',
                discountType: 'Descuento',
                applySince: 'Aplica desde',
                average: 'Promedio Min./ Max.',
                status: 'Estado',
                documentation: 'Documentación',
                BenefitsStudents: 'Estudiantes beneficiados actualmente',
                availableSlots: 'Cupos disponibles',
                noAverage: 'No aplica',
                active: 'Activa',
                inactive: 'Inactiva',
                automatic: 'Automática',
                manual: 'Manual'
              },
              table: {
                title: 'Estudiantes beneficiados',
                identification:'Identificacion',
                name: 'Nombre',
                career: 'Carrera',
                company: 'Empresa',
                delete: 'Dar de baja',
                bulkDelete: 'Dar de baja',
                addNew: 'Agregar nuevo estudiante',
              },
              drawer: {
                title: 'Nuevo estudiante beneficiado',
                subtitle: 'Agrega un nuevo estudiante al beneficio.',
                students: 'Estudiantes',
                description: 'Aquí podrás seleccionar a quienes asignarle un beneficio. Ten en cuenta que si el estudiante que buscas no está disponible en esta lista es posible que ya tenga un beneficio asignado o no cumpla los requisitos necesarios',
                company: 'Empresa',
                headers: {
                  name: 'Nombre',
                  average: 'Promedio',
                  token: 'Token',
                },
                success: {
                  title: 'Estudiantes agregados correctamente',
                  limitError: 'La cantidad de estudiantes seleccionada supera el cupo disponible.',
                  actions: {
                    back: 'Volver',
                  }
                }
              }
        }

    },
    en: {

    }
};

export default benefitsTranslations
