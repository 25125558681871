
import liquidationsTranslations from './Liquidations/Translations'
import dashboardTranslations from './Dashboard/Translations'

const commissionsTranslations = {
    es_PE: {
        dashboard: { ...dashboardTranslations.es_PE },
    },
    es: {
        liquidations: { ...liquidationsTranslations.es},
        dashboard: { ...dashboardTranslations.es },
    },
    en: {
        liquidations: { ...liquidationsTranslations.en },
        dashboard: { ...dashboardTranslations.en },
    }
}

export default commissionsTranslations