<template>
  <div>
    <v-container fluid class="d-flex pa-0 pb-8 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom" v-if="!updateSectionStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.sections.update.breadcrumbs.title')"
            :description="$t('modules.sections.update.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.sections.update.title')"
                :subtitle="$t('modules.sections.update.subtitle')"
                :switchValue="status"
                @switchChange="val => status = val"
                :switch-label="status? $t('modules.sections.update.labels.enabled') : $t('modules.sections.update.labels.disabled')"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.sections.validation_errors.name') : ''"
                      :label="`${$t('modules.sections.create.labels.name')} *`"
                      counter="75"
                      outlined
                      disabled
                      @blur="$v.description.$touch()"
                      v-model.trim="$v.description.$model"
                      @keypress="$validateAlphanumeric($event, $v.description.$model,40, true, true)"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedModalityType.$dirty && $v.selectedModalityType.$invalid) ? $t('modules.sections.validation_errors.modality') : ''"
                      :label="`${$t('modules.sections.update.labels.modality')} *`"
                      :items="modalityTypes"
                      item-text="meaning"
                      disabled
                      @blur="$v.selectedModalityType.$touch();"
                      outlined
                      return-object
                      v-model="$v.selectedModalityType.$model"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-autocomplete
                      :error-messages="($v.selectedTerm.$dirty && $v.selectedTerm.$invalid) ? $t('modules.sections.validation_errors.period') : ''"
                      :label="`${$t('modules.sections.update.labels.term')} *`"
                      :items="terms"
                      item-value="id"
                      item-text="description"
                      disabled
                      @blur="$v.selectedTerm.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedTerm.$model"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedLmsType.$dirty && $v.selectedLmsType.$invalid) ? $t('modules.sections.validation_errors.lms') : ''"
                      :label="`${$t('modules.sections.update.labels.lms_type')} *`"
                      :items="lmsTypes"
                      item-text="meaning"
                      @blur="$v.selectedLmsType.$touch()"
                      outlined
                      disabled
                      return-object
                      v-model="$v.selectedLmsType.$model"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :label="`${$t('modules.sections.update.labels.department')} *`"
                      :items="departments"
                      :disabled="true"
                      item-text="name"
                      @change="filterSubject"
                      outlined
                      return-object
                      class="no-message"
                      v-model="selectedDepartment"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :error-messages="($v.selectedSubject.$dirty && $v.selectedSubject.$invalid) ? $t('modules.sections.validation_errors.subject') : ''"
                      :label="`${$t('modules.sections.update.labels.subject')} *`"
                      :items="subjects"
                      :disabled="true"
                      item-text="description"
                      outlined
                      return-object
                      v-model="$v.selectedSubject.$model"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-text-field
                      :error-messages="($v.size.$invalid && $v.size.$dirty) ? 
                      ($v.size.maxValue ? $t('modules.sections.validation_errors.size') :
                      $t('modules.sections.validation_errors.limitSize')) : ''"
                      :label="`${$t('modules.sections.update.labels.size')} *`"
                      outlined
                      v-model.number="$v.size.$model"
                      min="0"
                      max="32767"
                      @keypress="$validateIntegerInputNumber($event, $v.size.$model, null, 5)"
                      type="number"
                      disabled
                      @blur="$v.size.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <autocomplete-teacher
                      :error-messages="($v.selectedTeacher.$dirty || $v.selectedTeacher.$invalid) ? $t('modules.sections.validation_errors.teacher') : ''"
                      :label="`${$t('modules.sections.update.labels.teacher')}*`"
                      :value.sync="$v.selectedTeacher.$model"
                      :list="teachers"
                      :disable-buttons="true"
                      :searchTeacher="true"
                    />
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.sections.update.tutors.title')"
                :subtitle="$t('modules.sections.update.tutors.subtitle')"
                class="mb-6"
              >
                <TransferList
                  ref="tutorsAdded"
                  :avatar="false"
                  :loading="loadingSearchTutors"
                  :firstListTitle="$t('modules.sections.update.tutors.transferList.firstTitle')"
                  :secondListTitle="$t('modules.sections.update.tutors.transferList.secondTitle')"
                  :availableItems.sync="tutors"
                  :added-items.sync="tutorsAdded"
                  areEquals="id"
                ></TransferList>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.sections.update.review.title')"
                :subtitle="$t('modules.sections.update.review.subtitle')"
                class="mb-6"
              ><br>
                <TransferList
                  ref="reviewersAdded"
                  :avatar="false"
                  :loading="loadingSearchReviewers"
                  :firstListTitle="$t('modules.sections.update.review.transferList.firstTitle')"
                  :secondListTitle="$t('modules.sections.update.review.transferList.secondTitle')"
                  :availableItems.sync="reviewers"
                  :added-items.sync="reviewersAdded"
                  areEquals="id"
                ></TransferList>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.sections.update.studenttitle')"
                :subtitle="$t('modules.sections.update.studentsubtitle')"
                class="mb-6"               
              >
                <v-row justify="center">
                  <v-col sm="12" class="viewSpaces">
                    <SuperTableSectionUpdate
                      :headers="headers"
                      :data="studentTable"
                      :page-count="pagination.pagesAmount"
                      :page="pagination.page"
                      :total-items="totalItems"
                      :sortBy.sync="sort.sortBy"
                      :sortDesc.sync="sort.sortDesc"
                      @input-pagination="onInputPagination"
                      @length-pagination-change="onLengthPaginationChange"
                    />
                  </v-col>
                </v-row>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/sections')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate || typeof this.selectedTeacher !== 'object' || $v.formValid.$invalid || $v.formValid.$anyError "
                  @end="resetButtonValues()"
                  @clicked="checkStatus()"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
        
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuperTableSectionUpdate from '../Components/SuperTable/superTableSectionUpdate';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import AutocompleteTeacher from '../Components/AutocompleteTeacher/AutocompleteTeacher.vue'
import { $terms, $departments, $types, $subjects, $sections, $student} from '../Services';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'

export default {
  name: 'SectionsUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    AutocompleteTeacher,
    SuperTableSectionUpdate,
    TransferList,
  },
  props: {
    insideDrawer: { type: Boolean, default: false }
  },
  data () {
    return {
      pagination: {
        limit: 20,
        pagesAmount: 1,
        page: 1,
      },
      studentTable: [],
      totalItems: null,
      sort: {
        sortBy: 'name',
        sortDesc: false,
      },
      updateSectionStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      tmpDrawer: {
        open: false,
        title: this.$t('modules.sections.update.breadcrumbs.title'),
        description: this.$t('modules.sections.update.breadcrumbs.description')
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.sections.update.success_message.title'),
        actionPrimary: { text: this.$t('modules.sections.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/sections') } },
      },

      status: true,
      lmsSectionId: '',
      selectedModalityType: [],
      selectedTerm: [],
      selectedLmsType: [],
      selectedDepartment: [],
      selectedSubject: [],
      selectedTeacher: null,
      sectionQuantity: '',
      size: '',
      description: '',

      modalityTypes: [],
      terms: [],
      lmsTypes: [],
      departments: [],
      subjects: [],
      teachers: [],
      students: [],
      headers: [
        {
          text: this.$t('modules.sections.update.career'),
          value: 'career',
        },
        {
          text: this.$t('modules.sections.update.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.sections.update.studentrut'),
          value: 'identification',
          align: 'center',
        },
      ],
      loadingSearchTutors: false,
      tutors: [],
      tutorsAdded: [],

      loadingSearchReviewers: false,
      reviewers: [],
      reviewersAdded: [],

    }
  },
  validations: {
    description: { required },
    selectedModalityType: { required },
    selectedTerm: { required },
    selectedLmsType: { required },
    selectedTeacher: { required },
    selectedSubject: { required },
    size: { required, minValue: minValue(0), maxValue: maxValue(32767)},
    formValid: ['description', 'selectedModalityType', 'selectedTerm', 'selectedLmsType', 'selectedTeacher', 'selectedSubject', 'size']
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.sections.UPDATE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    }),
  },
  methods: {
    async getTutors() {
      this.loadingSearchTutors = true;
      let tutorsFetch = [];
      try {
        tutorsFetch = await $sections.getTeachers('Tutor');
        this.tutors = tutorsFetch.data.content.map(item => {
          item.name = `${item.user.userData.name} ${item.user.userData.lastname}`;
          return item;
        });
      } catch (error) {
        this.tutors = [];
        throw error;
      } finally {
        this.loadingSearchTutors = false;
      }
    },
    async getReviewers() {
      this.loadingSearchReviewers = true;
      let reviewersFetch = [];
      try {
        reviewersFetch = await $sections.getTeachers('Corrector');
        this.reviewers = reviewersFetch.data.content.map(item => {
          item.name = `${item.user.userData.name} ${item.user.userData.lastname}`;
          return item;
        });
      } catch (error) {
        this.reviewers = [];
        throw error;
      } finally {
        this.loadingSearchReviewers = false;
      }
    },
    async fetchSection () {
      const section = await $sections.findById(this.$route.params.id);

      this.description = section.data.name;
      this.lmsSectionId = section.data.lmsSectionId;
      this.status = section.data.status;
      this.size = section.data.size;
      this.selectedTerm = section.data.term;
      this.selectedLmsType = section.data.lmsType;
      this.selectedModalityType = section.data.modalityType;
      this.selectedTeacher = section.data.assignedTeachers?.length > 0 ?
        this.teachers.filter(teacher => teacher.id == section.data.assignedTeachers[0].teacher?.id)[0]
        : null;
      this.tutorsAdded = section.data.assignedTutors?.map(tutor => {
        tutor.name = `${tutor.user.userData.name} ${tutor.user.userData.lastname}`;
        return tutor;
      });
      if (this.tutorsAdded?.length > 0)
        this.tutors = this.tutors.filter(tutor => 
          !this.tutorsAdded.some(tutorAdded => tutorAdded.user.id === tutor.user.id));

      this.reviewersAdded = section.data.assignedReviewers?.map(reviewer => {
        reviewer.name = `${reviewer.user.userData.name} ${reviewer.user.userData.lastname}`;
        return reviewer;
      });
      if (this.reviewersAdded?.length > 0)
        this.reviewers = this.reviewers.filter(reviewer => 
          !this.reviewersAdded.some(reviewerAdded => reviewerAdded.user.id === reviewer.user.id));

      this.selectedDepartment = section.data.subject.departments?.length > 0 ?
        this.departments.filter(dep => dep.id == section.data.subject.departments[0].id)[0]
        : null;

      if (this.selectedDepartment) {
        this.filterSubject(this.selectedDepartment).then(() => {
          [this.selectedSubject] = this.subjects.filter(sub => {
            return sub.id == section.data.subject.id;
          });
        });
      }
      
      
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
    },

    checkStatus () {
      if (this.status) {
        this.updateSection();
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.sections.update.warning_popup.title'),
          content: this.$t('modules.sections.update.warning_popup.content'),
          actionPrimary: {
            text: this.$t('modules.sections.update.warning_popup.update'),
            callback: this.updateSection
          },
          actionSecondary: {
            text: this.$t('modules.sections.update.warning_popup.cancel'),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      }
    },
    async updateSection () {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;

      const assignedTutors = this.tutorsAdded ? this.tutorsAdded.map(t => {return {user: {id: t.user.id}}}) : [];

      const assignedReviewers = this.reviewersAdded ? this.reviewersAdded.map(t => {return {user: {id: t.user.id}}}) : [];

      const assignedTeachers = this.selectedTeacher ? [{ user: { id: this.selectedTeacher.user.id } }] : [];

      const section = {
        name: this.description,
        lmsSectionId: this.lmsSectionId,
        status: this.status,
        size: this.size,
        term: { id: this.selectedTerm.id },
        subject: { id: this.selectedSubject.id },
        lmsType: { value: this.selectedLmsType.value },
        modalityType: { value: this.selectedModalityType.value },
        assignedTeachers,
        assignedTutors,
        assignedReviewers,
        user: this.userId ? this.userId : null
      }

      try {
        if(!this.$v.$invalid){
          await $sections.update(this.$route.params.id, section);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.sections.update.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.sections.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/sections') } };
          this.updateSectionStatus = true;
          this.saveButton.loading = false;
          this.saveButton.success = true;
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.sections.update.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.sections.update.success_message.actions.back'), callback: () => { this.updateSectionStatus = false } }
        this.updateSectionStatus = true;
        this.saveButton.error = true;
        this.resetButtonValues()
      } finally {
          this.saveButton.loading = false;
        }
    },

    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },

    async filterSubject (department) {
      const subjects = await $subjects.filterByDepartment(department.id);
      this.subjects = subjects.data;
      return Promise.resolve(subjects.data);
    },

    async fetchData () {
      const modalityTypes = await $types.find(null, null, { params: { type: 'MODALITY_TYPE' } });
      this.modalityTypes = modalityTypes.data;

      const lmsTypes = await $types.find(null, null, { params: { type: 'LMS_TYPE' } });
      this.lmsTypes = lmsTypes.data;

      const terms = await $terms.find(null, null, { params: { orderBy: 'classStartDate', orientation: 'desc' } });
      this.terms = terms.data.content;

      const departments = await $departments.find();
      this.departments = departments.data.content;

      const teachers = await $sections.getTeachers('Docente');
      this.teachers = teachers.data.content.map(teacher => {
        teacher.name = `${teacher.user.userData.name} ${teacher.user.userData.lastname}`
        return teacher;
      });

      await this.getTutors();
      await this.getReviewers();
    },
    async fetchStudent() {
      if (this.loading) return false;
              this.loading = true;
      try {
          const params = {
              page: this.pagination.page - 1,
              length: this.pagination.limit,
              orientation: this.sort.sortDesc ? 'desc' : 'asc',
              orderBy: this.sort.sortBy,
              idSection: this.$route.params.id,
          };
          const resp = await $student.find(null, null, { params })
          this.studentTable = resp.data.content
          this.totalItems = resp.data.totalElements;
          this.pagination.pagesAmount = resp.data.totalPages;
      } catch (error) {
          this.studentTable = [];
          this.pagination.page = 1
          this.pagination.pagesAmount = 1
          this.totalItems = 0;
          throw error;
      }finally {
          this.loading = false;
      }
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchStudent();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchStudent();
    },
    
  },
  async mounted () {
    this.fetchStudent();
    await this.fetchData();
    this.fetchSection();
  },
  watch: {
    sort: {
      handler() {
          this.fetchStudent();
      },
      deep: true
    }
  }
}
</script>
