<template>
  <v-container class="container-custom "  >
    <v-container class="container-custom  "> 
      <Breadcrumbs
        class="ma-2"
        size="sm"
        :title="$t('modules.portalalumno.admission.breadcrumbs.title')"
      ></Breadcrumbs>
      
        <title-component
          v-if="loggedUser"
          :title="$t('modules.portalalumno.admission.title', {name: loggedUser.student.user.userData.name})"
          :descriptionBold="$t('modules.portalalumno.admission.subtitle')
        "></title-component>
        <v-col sm="10" class="d-flex flex-column mx-auto" v-if="!loading">
          <v-row >
            <v-col cols="12" sm="4" v-for="(item, index) in items" :key="index">
              <InfoCard :disabled="item.disabled" :item="item" />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else class="d-flex justify-center align-center">
          <v-row>
            <v-col cols="4" class="d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TitleComponent from '@/components/TitleComponent/TitleComponent';
import InfoCard from '@/components/InfoCard/InfoCard';
import {mapGetters} from 'vuex';

export default {
  name: 'AdmissionStatus',
  components: {
    Breadcrumbs,
    TitleComponent,
    InfoCard,
  },
  data() { return {
    loading: false,
    loggedUser: null,
    processing: null,
    user: {
      name: 'Daniel'
    },
    systemConfig: null,
    documentationInfoCard: {
      title: 'Documentación obligatoria',
      documentation: [
        'Frente y dorso de tu cédula',
        'Licencia de Enseñanza Media (LEM)'
      ],
      status: 'P',
      disabled: false,
      action: {
        callback: () => {
          this.$router.push({ name: 'DocumentationUpload',  params : {dataDocumentation : { description : 'Carga de documentacion obligatoria', id: 16} }, replace: true })
        },
        disabled: false,
        loading: false,
        text: 'Subir documentación'
      }
    },
    contractInfoCard:  {
      action: {
        callback: () => {
          this.$router.push({name :'ContractSign'});
        },
        disabled: true,
        loading: false,
        text: null
      },
      disabled: true,
      documentation: [
        'Revisa el documento y firma.'
      ],
      observation: '',
      status: 'B',
      title: 'Contrato de Servicios'
    },
    inscriptionInfoCard: {
      action: {
        callback: () => {
          this.goToPayments()
        },
        disabled: true,
        loading: false,
        text: null
      },
      disabled: false,
      documentation: [],
      observation: '',
      status: 'B',
      title: 'Inscripción'
    }
  }},
  computed:{
    ...mapGetters({
       userData: 'commons/getStudentData'
     }),
    items() {
      if(this.systemConfig === null) {
        return [ this.documentationInfoCard ]
      }
      if(!this.systemConfig?.contract && this.systemConfig?.dues) {
        return [ this.documentationInfoCard, this.inscriptionInfoCard ]
      }
      return [ this.documentationInfoCard, this.contractInfoCard ]
    }
  },
  methods: {
    alertPopup(type, message) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t(`actions.${type}`),
        content: [{value: message}],
        actionPrimary: { text: this.$t('actions.accept'), callback() {}},
        icon: { color: type, name: 'mdi-close' },
        color: 'primary',
      })
    },
    goToPayments() {
      this.$store.commit('commons/SET_SIGNED_USERDATA', true)
      this.$router.push({path: '/payments'})
    }
  },
  async mounted () {
    this.loading = true;
    this.systemConfig = (await this.$getSystemConfig())?.data
    await this.$store.dispatch('commons/initData')
    await this.$store.dispatch('settings/initData');
    if (this.$route.fullPath.match(/.*\/ecert\?.*/)) {
        const token = this.$route.hash.match(new RegExp(/T=[^&]+/))[0].replace('T=', '')
        const code = this.$route.hash.match(new RegExp(/C=[^&]+/))[0].replace('C=', '')
        this.$router.push({name: 'Ecert', query: { T: token, C: code}})
    }
    this.loggedUser = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
    const status = this.userData.studentProcessing ? this.userData.studentProcessing.processingStatus : null

    if (this.userData.studentProcessing && status) {
      switch(status.meaning){
        case 'Pendiente':
          this.documentationInfoCard.disabled = true;
          this.documentationInfoCard.action.text = null;
          this.documentationInfoCard.status = 'R';
          
          if(this.systemConfig !== null && this.systemConfig.contract && this.systemConfig.dues) {
            this.contractInfoCard.disabled = true;
            this.contractInfoCard.observation = 'Primero la documentación debe ser aceptada.';
          }  
          
          if(this.systemConfig !== null && !this.systemConfig.contract && this.systemConfig.dues) {
            this.inscriptionInfoCard.disabled = true;
            this.inscriptionInfoCard.observation = 'Primero la documentación debe ser aceptada.';
          }

          break
        case 'Rechazado':
          this.documentationInfoCard.disabled = true;
          this.documentationInfoCard.action.text = null;
          this.documentationInfoCard.status = 'B';

          if(this.systemConfig !== null && this.systemConfig.contract && this.systemConfig.dues) {
            this.contractInfoCard.disabled = true;
            this.contractInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
          }

          if(this.systemConfig !== null && !this.systemConfig.contract && this.systemConfig.dues) {
            this.inscriptionInfoCard.disabled = true;
            this.inscriptionInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
          }
            
          break
        case 'Observado':
          this.documentationInfoCard.disabled = true;
          this.documentationInfoCard.status = 'W';

          if(this.systemConfig !== null && this.systemConfig.contract && this.systemConfig.dues) {
            this.contractInfoCard.disabled = true;
            this.contractInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
          }

          if(this.systemConfig !== null && !this.systemConfig.contract && this.systemConfig.dues) {
            this.inscriptionInfoCard.disabled = true;
            this.inscriptionInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
          }
            
          break
        case 'Aprobado':
          this.documentationInfoCard.disabled = true;
          this.documentationInfoCard.action.text = null;
          this.documentationInfoCard.status = 'S';

          if(this.systemConfig !== null && this.systemConfig.contract && this.systemConfig.dues) {
            this.contractInfoCard.disabled = false;
            this.contractInfoCard.status = 'P';
            this.contractInfoCard.action.text = 'Firmar contrato';
            this.contractInfoCard.action.disabled = false;
          }

          if(this.systemConfig !== null && !this.systemConfig.contract && this.systemConfig.dues) {
            this.inscriptionInfoCard.disabled = false;
            this.inscriptionInfoCard.status = 'P';
            this.inscriptionInfoCard.action.text = 'Confirmar inscripción';
            this.inscriptionInfoCard.action.disabled = false;
          }
            
          break
      }
      
    } else {
      this.documentationInfoCard.disabled = false;

      if(this.systemConfig !== null && this.systemConfig.contract && this.systemConfig.dues) {
        this.contractInfoCard.status = 'B';
        this.contractInfoCard.disabled = true;
        this.contractInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
      }

      if(this.systemConfig !== null && !this.systemConfig.contract && this.systemConfig.dues) {
        this.inscriptionInfoCard.status = 'B';
        this.inscriptionInfoCard.disabled = true;
        this.inscriptionInfoCard.observation = 'Primero debes cargar la documentación obligatoria';
      }
    }
    this.loading = false;
  }
}
</script>

<style lang="sass">
  .info-card
    min-height: 200px
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)

    &.active
      background: #f8fafe
      border-color: #274280 !important
      
      .card-title
        color: #274280
</style>