
<template>
    <div>
        <DueBanner v-if="dueBanner"></DueBanner>
        <template v-else-if="onboardingStatus.showBanners && !classStarted">
            <OnboardingCentralPrimaryBanner v-if="!!currentOnboardingCentralPrimaryBanner" :banner="currentOnboardingCentralPrimaryBanner" :class-start="onboardingStatus.classStart"></OnboardingCentralPrimaryBanner>
            <OnboardingCentralSecondaryBanner v-if="!!currentOnboardingCentralSecondaryBanner" :banner="currentOnboardingCentralSecondaryBanner"></OnboardingCentralSecondaryBanner>
        </template>
        <WelcomeBanner v-else></WelcomeBanner>
    </div>
</template>

<script>
    import WelcomeBanner from './WelcomeBanner';
    import DueBanner from './DueBanner';
    import OnboardingCentralPrimaryBanner from './OnboardingCentralPrimaryBanner';
    import OnboardingCentralSecondaryBanner from './OnboardingCentralSecondaryBanner';
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'PrincipalBanner',
        props: {
            classStarted: Boolean,
            onboardingStatus: Object
        },
        components: {
            WelcomeBanner,
            DueBanner,
            OnboardingCentralPrimaryBanner,
            OnboardingCentralSecondaryBanner
        },
        data() {
            return {
                onboardingCentralPrimaryBanners: [],
                onboardingCentralSecondaryBanners: [],
            }
        },
        created() {
            this.setOnboardingBanners()
        },
        computed: {
            onboardingHabilidadesUrl() {
              switch(this.$country) {
                case 'chile':
                  return 'https://instituto.ipp.cl/test-de-habilidades-final';
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/habilidades-teclab'
                default:
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
              }
            },
            onboardingCharlaBienvenidaUrl() {
              switch(this.$country) {
                case 'chile':
                  if(this.this.onboardingStatus?.aproboTest) {
                    return 'https://instituto.ipp.cl/onboarding-2';
                  }
                  return 'https://instituto.ipp.cl/onboarding-1'
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/charla-de-bienvenida'
                default:
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
              }
            },
            dueBanner() {
                return this.studentData.studentDue ? this.studentData.studentDue.countPaymentsDebt > 1 : false
            },
            currentOnboardingCentralPrimaryBanner() {
                return this.onboardingCentralPrimaryBanners.find(banner => banner.visible)
            },
            currentOnboardingCentralSecondaryBanner() {
                return this.onboardingCentralSecondaryBanners.find(banner => banner.visible)
            },
            ...mapGetters({
                studentData: 'commons/getStudentData',
            }),
        },
        methods: {
            getOnboardingDate() {
                const horario = this.onboardingStatus.horario ? this.onboardingStatus.horario.substr(0, 5) : ''
                if (this.onboardingStatus.onbordingDate) return moment(`${this.onboardingStatus.onbordingDate.substr(0, 10)} ${horario}`).locale('es').format('D [de] MMMM [a las] H[hs]')
                else return moment().locale('es').format('D [de] MMMM [a las] H[hs]')
            },
            setOnboardingBanners() {
                if (!this.onboardingStatus.showBanners || this.classStarted) return false
                const [name] = this.studentData.student.user.userData.name.split(' ')
                const studentName = this.$options.filters.$capitalizeAll(name)
                this.onboardingCentralPrimaryBanners = [
                    {
                        visible: !this.onboardingStatus.test,
                        backgroundColor: '#CBE1EC',
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/01.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.text.comment'),
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.buttons.first.text'),
                                size: 'lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingHabilidadesUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-hacertest-central'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.test.buttons.second.text'),
                                size: 'lg',
                                color: '#FFFFFF40',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.paraQueSirve,
                                icon: 'mdi-play-circle',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-¿paraquesirve?-central'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.schedule,
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/02.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.text.title', { studentName }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.text.comment')
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.schedule.buttons.first.text'),
                                size: 'x-lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-fechasdisponibles-bienvenida-central'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isSameOrAfter((new Date()).setHours(0,0,0,0))),
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/04.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.scheduled.text.title', { studentName, onboardingDate: this.getOnboardingDate() }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.scheduled.text.comment', { ref: this.$isTeclab ? 'http://bit.ly/CoachAcadémico' : 'mailto:inducciones@ipp.cl' })
                        }
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isBefore((new Date()).setHours(0,0,0,0))) && !this.onboardingStatus.onbording,
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/05.svg'),
                        text: {
                            caption: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.text.caption'),
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.text.comment')
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.reschedule.buttons.first.text'),
                                size: 'x-lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-nuevasfechas-bienvenida-central'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.onbording,
                        backgroundColor: '#BDE8F0',
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/06.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.text.comment')
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.first.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoUsarPortal,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comousarportal-central'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.second.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoEstudiar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comoestudiar-central'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.centralPrimaryBanner.onboarded.buttons.third.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoAprobar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comoaprobar-central'
                                }
                            }
                        ]
                    }
                ]

                this.onboardingCentralSecondaryBanners = [
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.habilidades,
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/03.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.text.comment')
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.lackSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Banner OBH',
                                action: 'banner-ingresacanvas-habilidades-central'
                            }
                        }
                    }, {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.habilidades,
                        image: require('@/assets/dashboard-assets/onboarding/before-class-start/07.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.text.comment'),
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.centralSecondaryBanner.enoughSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Banner OBH',
                                action: 'banner-ingresacanvas-tecnicasestudio-central'
                            }
                        }
                    }
                ]
            }
        }
    }
</script>