import Vue from 'vue';
import Router from 'vue-router';
import Base from './views/Base.vue';
import ModulesRouter from '../modules/main.router.js';
import LoginRoute from '../modules/LoginApplication/Routes';
import ValidatorRoute from '../modules/portaladministrativo/Validator/Routes';
import LoginApplication from '../modules/LoginApplication/LoginApplication';
import Validator from '../modules/portaladministrativo/Validator/Validator';
import Ecert from '../modules/portaladministrativo/Ecert/Ecert';
import EcertRoute from '../modules/portaladministrativo/Ecert/Routes';
import MercadoPagoDebit from '../modules/portaladministrativo/MercadoPagoDebit/MercadoPagoDebit';
import MercadoPagoRoute from '../modules/portaladministrativo/MercadoPagoDebit/Routes';
import NotFoundState from './components/NotFoundState/NotFoundState';
import ExamTest from '../modules/portaladministrativo/ExamTest/ExamTest';
import ExamTestRoute from '../modules/portaladministrativo/ExamTest/Routes';
import ListStudents from '../core/constants/listStudents';
import Elibro from '../modules/portaladministrativo/Elibro/Elibro';
import ElibroRoute from '../modules/portaladministrativo/Elibro/Routes';

import Configuration from './constants/configuration';

import AuthService from './plugins/auth.service';
import app from './main';
import store from '../store';
import {$configuration} from '@/services';

Vue.use(Router);
let initialLoaded = false;
let systemConfig = null

const router = new Router({
  mode: 'history',
  // eslint-disable-next-line no-process-env
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: { requiresAuth: true, name: 'Base' },
      component: Base,
      children: ModulesRouter
    },
    {
      path: '/login',
      meta: { name: 'login' },
      component: LoginApplication,
      children: LoginRoute
    },
    {
      path: '/validator',
      meta: { name: 'validator' },
      component: Validator,
      children: ValidatorRoute
    },
    {
      path: '/ecert',
      meta: { name: 'ecert' },
      component: Ecert,
      children: EcertRoute
    },
    {
      path: '/pagos',
      meta: { name: 'pagos' },
      component: MercadoPagoDebit,
      children: MercadoPagoRoute
    },
    {
      path: '/practicadeexamen',
      meta: { name: 'practicadeexamen' },
      component: ExamTest,
      children: ExamTestRoute
    },
    {
      path: '/logout',
      meta: { name: 'logout' }
    },
    {
      path: '/e-libro',
      meta: { name: 'e-libro', institutes:'ipp' },
      component: Elibro,
      children: ElibroRoute
    },
    {
      path: '*',
      meta: { requiresAuth: true, name: 'Base' },
      component: Base,
      children: [
        {
          path: '',
          component: NotFoundState
        }
      ]
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isPortalAlumno = Configuration.value('app') == 'portalalumno'
    const isPortalAdministrativo = Configuration.value('app') == 'portaladministrativo'
    const isTeclab = Configuration.value('theme') == 'teclab'
    const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
    const dniAlumnoBloqueado = isPortalAlumno && AuthService.isStudent() && alumnoSeleccionado && alumnoSeleccionado.userData ? ListStudents.find(dni => dni == alumnoSeleccionado.userData.username) : null;
    if (AuthService.checkTokenExpired() || !AuthService.checkUserLoggedIn()) {
      AuthService.clearStorage()
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else if (to.path == '/') {
      if (isPortalAlumno && (AuthService.isStudent() || alumnoSeleccionado)) {
        next('/dashboard');
      } else {
        next();
      }
    } else if (to.path == '/access-denied' || to.path == '/module-inactive' || to.path == '/access-blocked' ) {
      next();
    } else if (isPortalAlumno && !alumnoSeleccionado && !AuthService.isStudent()) {
      next('/');
    } else if (dniAlumnoBloqueado && to.path != '/dashboard' && ( to.path != '/reglamento/confirm' && isTeclab )) {
      next({ path: '/dashboard', query: {pp: true} })
    } else if (
                to.path == '/profile' ||
                (isPortalAdministrativo && (to.path == '/status-connection' || to.path == '/module-control')) ||
                (isPortalAlumno && (
                  to.path == '/profile/studentFinder' ||
                  to.path.includes('/admission') ||
                  (to.path == '/reglamento/confirm' && AuthService.isStudent() && isTeclab)
                ))
              ) {
      next();
    } else {
      if (!initialLoaded) {
        await store.dispatch('commons/initData');
        systemConfig = (await $configuration.find())?.data
        initialLoaded = true;
      }
      store.dispatch('commons/setLoadingDrawer', false);
      const regulation = isPortalAlumno && AuthService.isStudent() && store.getters['commons/getStudentData'] ? store.getters['commons/getStudentData'].regulationAccepted : true;
      const { modulesblocked } = AuthService.isStudent() ?  store.getters['commons/getStudentData'] : { modulesblocked : [] }
      const firstLogin = isPortalAlumno && AuthService.isStudent() && store.getters['commons/getStudentData']? store.getters['commons/getStudentData'].firstLogin : false
      const signed = isPortalAlumno && /*AuthService.isStudent() &&*/ store.getters['commons/getStudentData'] && store.getters['commons/getStudentData'].studentType?.value == 7 ? store.getters['commons/getStudentData'].signed : true
      // const documentationObligatory = isPortalAlumno && AuthService.isStudent() && store.getters['commons/getStudentData']? store.getters['commons/getStudentData'].studentProcessing : null
      const isPreregistratedOrStudying = store.getters['commons/getStudentData']?.careerStatus.value == 51 || store.getters['commons/getStudentData']?.careerStatus.value == 54;
      const isBlocked = (module, listModules) => {
        if (AuthService.isStudent()){
          const listModul =   listModules.map(m => m.modulePath)
          return listModul.includes(module)
        } else {
          return false
        }
      }
      if ( isBlocked(to.path , modulesblocked) ) {
        next('/access-blocked')
      } else if (!regulation && !systemConfig?.contract) {
          if(to.path === '/reglamento/confirm') {
              next()
          }
          next({path:'/reglamento/confirm'})
      }else if(firstLogin){
        next({name: 'DocumentationUpload', params : {dataDocumentation : { description : 'Carga de documentacion obligatoria', id: 16} }, replace: true })
      }else if(!signed && isPreregistratedOrStudying){
          if(!systemConfig?.contract) {
              if(to.name === 'RenewalStatus') {
                  next()
              } 
              else if(to.name === 'Payments' && to.params?.termIds) {
                  next()
              } 
              else {
                  next({name: 'RenewalStatus', replace: true})
              }
          } else {
              if(to.name === 'AdmissionStatus') {
                  next()
              } else {
                  next({name: 'AdmissionStatus', params : {dataDocumentation : { description : 'Carga de documentación obligatoria', id: 16} }, replace: true })    
              }
          }
      }else if (app && app.$permissions) {
        const { group, path, permission, institutes } = to.meta;
        if (path && permission !== undefined) {
          // Definimos si la ruta esta habilitada para cierto instituto o no
          let availableForInstitute = null;
          if (institutes && institutes != '') {
            availableForInstitute = false;
            const instArr = institutes.split('.');
            instArr.forEach(institute => {
              if (Configuration.value('theme') == institute) {
                availableForInstitute = true;
                return false;
              }
            });
          }

          let perm = app.$permissions;
          if (group && group != '') {
            const groupArr = group.split('.');
            groupArr.forEach(permissionGroup => {
              perm = perm[permissionGroup];
            });
          }
          perm = perm[path];
          if (!perm[permission] || availableForInstitute === false)
            next('/access-denied');
          else if (!perm.STATUS) next('/module-inactive');
          else next();
        } else {
          // next({path: '/access-denied'})
          next();
        }
      } else {
        next();
      }
    }
  } else if (
    to.path === '/login' &&
    AuthService.checkUserLoggedIn() &&
    !AuthService.checkTokenExpired()
  ) {
    next(from.path);
  } else if (to.path === '/logout') {
    AuthService.logout();
  } else {
    next();
  }
});

export default router;
