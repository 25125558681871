<template>
        <v-card
            elevation="2"
            outlined
            width="225"
            min-height="163"
            class="cursor mx-2 mb-5 w-5 d-flex align-center"
            @click="sendToEntry"
        >
            <v-container class="d-flex flex-column justify-center">
                <v-list-item-avatar class="ma-0" tile size="55">
                    <v-img class="elevation-6" alt="avatar" :src="card.img" ></v-img>
                </v-list-item-avatar>
                <v-list-item-title class="text-lg-h6 mb-1 mt-3 text-center text-wrap text-primary" size="22">
                    {{ card.title }}
                </v-list-item-title>
            </v-container>
        </v-card>
</template>

<script>
    export default {
        name:'ReglamentoCard',
        props: {
            card: Object,
        },
        methods: {
            sendToEntry() {
                this.$emit('sendToEntry', this.card.href)
            }
        },
    }
</script>

<style lang="sass" scoped>
    .cursor:hover
        cursor: pointer
        background-color: #eee 
</style>