<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="isNI ? $t('modules.portalalumno.procedures.reEnrollmentStatus.breadcrumbs.enrollmentTitle') : $t('modules.portalalumno.procedures.reEnrollmentStatus.breadcrumbs.reEnrollmentTitle')"
        :analytics="{category: 'Tramites', label: 'Rematriculacion Step 1'}"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <template v-if="!loading">
            <Renewal v-if="uploadDocumentation" :type="selectedRenewal" @goBack="onGoBack" :procedure="model.benefit.data.procedure" @uploadDocumentation="documentationFinish"></Renewal>
            <SuccessMessage
              v-if="warningAnexo"
              :title="successMessage.title"
              :type="successMessage.type"
              :actionPrimary="successMessage.actionPrimary"
              :actionSecondary="successMessage.actionSecondary"
            ></SuccessMessage>
            <template class="d-flex align-center justify-center" v-if="!uploadDocumentation && !warningAnexo ">
              <v-row v-if="needGenerateMatricula">
                <v-col cols="12" class="card-title-reEnrollment d-flex flex-column align-start mx-auto px-10 py-6 mt-2 mb-2" :style="`background-image: url('${require('@/assets/images/portalalumno/procedures-header.png')}')`">
                  <div class="d-flex flex-column">
                    <span class="headline white--text" v-if="studentData">{{ `${studentData.student.user.userData.name},` }}</span>
                    <span class="white--text caption">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.subtitle', { period }) }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!needGenerateMatricula && !isNI">
                <v-col cols="12">
                  <MatriculaStatus/>
                </v-col>
              </v-row>
              <v-row v-if="showItem">
                <v-col cols="12">
                  <p class="subtitle-2 my-0 font-weight-light">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.scholarshipWarning') }}</p>
                  <v-row align="stretch">
                    <v-col cols="6" v-if="item">
                      <InfoCard :disabled="item.disabled" :item="item"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!needGenerateMatricula && !loading && !periodsSelectionSectionIsLoading">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <p class="subtitle-2 font-weight-light mb-0" style="font-size: 18px" v-if="!isNI">{{ 'Ya has completado el pago de tu matrícula.' }}</p>
                      <p class="subtitle-2 font-weight-light mb-0" style="font-size: 18px">{{ 'Debes seleccionar los períodos que deseas cursar.' }}</p>
                    </v-col>
                    <v-col cols="12">
                      <PeriodsSelectionSection :loading="periodsSelectionSectionIsLoading || loading" :periods="periods" @input="onPeriodUpdate"></PeriodsSelectionSection>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" v-if="!hasContract">
                      <ContractPreview 
                          :selectedPeriods="selectedPeriods" 
                          :periodsSelectionSectionIsLoading="periodsSelectionSectionIsLoading || loading" 
                          :accounts="accounts"
                          :payments="payments">
                      </ContractPreview>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex pt-0 justify-end">
                  <Button
                    :loading="loadingSubmitButton"  
                    :disabled="isSubmitButtonDisabled"
                    :text="$t(submitButtonLabel)"
                    primary
                    @clicked="onSubmitClick"
                  ></Button>
                </v-col>
              </v-row>
            </template>
          </template>
          <v-row v-else>
            <v-col sm="12" class="d-flex align-center justify-center py-10">
              <v-progress-circular block indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import InfoCard from '../../Components/InfoCard/InfoCard';
import Renewal from './Renewal'
import { $studentProcessing , $terms, $studentAccount, $students, $productsWithPricePeriods } from '../../services';
import Button from '@/components/Button/Button'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import ContractPreview from '../../Components/ReEnrollment/ContractPreview'
import MatriculaStatus from '../../Components/ReEnrollment/MatriculaStatus'
import PeriodsSelectionSection from '../../Components/ReEnrollment/PeriodsSelectionSection'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment';

export default {
  name: 'ReEnrollment',
  components: {
    Breadcrumbs,
    InfoCard,
    Renewal,
    Button,
    SuccessMessage,
    ContractPreview,
    MatriculaStatus,
    PeriodsSelectionSection
  },
  data () { 
    return {
      period: '',
      periods: [],
      accounts: [],
      teclabReenrollment: null,
      adaptationInsuranceTerms: null,
      payments: [],
      periodsSelectionSectionIsLoading: false,
      item: null,
      reEnrollmentCase: 'benefit',
      reEnrollmentOptions: ['prontoPago','benefit','scholarship'],
      needGenerateMatricula: false,
      uploadDocumentation: false,
      loading: false,
      loadingSubmitButton: false,
      warningAnexo: false,
      successMessage: {
        title: null,
        type: null,
        actionPrimary: null,
        actionSecondary: null,
      },
      model: { 
        prontoPago: { 
          dataToShow: [],
          dataNeeded: [],
          data: {
            warning: [],
          }
        },
        scholarship: {
          dataToShow: ['scholarshipAlert',],
          dataNeeded: [],
          data: {
            warning: ['R'],
            state: null,
            scholarshipAlert: 'Para poder aspirar a la renovación de una beca, deberás tener un promedio ponderado igual o mayor a cinco punto cinco (5.5), y no haber tenido asignaturas reprobadas.'
          }
        },
        benefit: {
          dataToShow: [],
          dataNeeded: ['state'],
          data: {
            warning: ['R','P','W'],
            state: null,
            procedure: null,
            benefitStateBlocked: [],
            benefitCardBlocked: ['S','D','R'],
          }
        }
      },
      systemConfig: null,
      selectedPeriods: []
  }},
  methods: {
    async initialize () {
      this.systemConfig = (await this.$getSystemConfig())?.data
      await this.getProductsWithPricePeriods()
      await this.checkCorrectFlow()
      await this.getCurrentPeriod()
      await this.defineCase()
      // Get item to show, depending on the case.
      this.item = await this.getItem()
    },
    onSubmitClick() {
      if(this.needGenerateMatricula) {
        this.goToMatricula()
      }
      else if(this.systemConfig?.contract) {
        this.goToAnexo()
      }
      else {
        this.goToPayments()
      }
    },
    goToMatricula() {
      this.$trackEvent('Tramites', 'Comenzar', 'Rematriculacion Step 1')
      // Redirect to re-enrollment view.
      this.$router.push({name: 'Rematrícula'})
    },
    goToAnexo() {
      if (this.showWarning){
        const option = this.reEnrollmentCase === 'benefit' ? 'convenio' : 'beca'
        const approve = this.reEnrollmentCase === 'benefit' ? 'aprobado' : 'aprobada'

        this.successMessage.type = 'warning'
        this.successMessage.title = `Si aceptas el anexo sin que tu ${option} haya sido ${approve}, perderás el descuento del mismo sobre tu arancel.`;
        this.successMessage.actionSecondary = { text: this.$t('actions.cancel'), callback: () => {this.warningAnexo = false} }
        this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.reEnrollmentStatus.startArancel'), callback: () => {this.$router.push({name: 'anexo'})} }
        this.warningAnexo = true;
      } else {
        const approved = this.model[this.reEnrollmentCase].data.state === 'S';
        this.$router.push({name: 'anexo', params: {approved}})
      }
    },
    goToPayments() {
      const selectedPeriodsIds = this.selectedPeriods.map(period => period.id ).toString()
      this.$router.push({name: 'Payments', params: { termIds: selectedPeriodsIds }})
    },
    onGoBack(){
      this.uploadDocumentation = false;
    },
    async getCurrentPeriod(){
      this.loading = true
      const periodData = await $terms.currentPeriod({ params: { reenrollment: true }})
      this.period = periodData.data.content[0].description
      this.loading = false
    },
    async documentationFinish(){
      this.item = await this.getItem()
      this.uploadDocumentation = false
    },
    async checkCorrectFlow(){
      this.loading = true;
      const MATRICULAID = this.$sisConstants('modules.products.matricula')
      const ARANCELID = this.$sisConstants('modules.products.arancel')
      const reEnrollmentOptions = await $studentAccount.find(`reEnrollmentStatus/${this.studentRegister}`)
      let optionStatus = null
      
      if (reEnrollmentOptions.data.tariff) {
        const tariffData = await $studentAccount.find(`paymentStatus/${this.studentRegister}?idProduct=${ARANCELID}`)
        optionStatus = tariffData.data
      } else if (reEnrollmentOptions.data.reEnrollment) {
        const reEnrollmentData = await $studentAccount.find(`paymentStatus/${this.studentRegister}?idProduct=${MATRICULAID}`)
        optionStatus = reEnrollmentData.data
      }
      if(optionStatus) {
        const { status, generateTicket } = optionStatus
        const { reEnrollment, tariff } = reEnrollmentOptions.data

        // Tiene que pagar matricula, no generó ticket por ende no pagó
        this.needGenerateMatricula = reEnrollment && tariff && !generateTicket && !status;
      }
      this.loading = false;
    },
    defineCase(){
      // Sets the payment case presented by the user.
      if (this.studentData.benefit) this.reEnrollmentCase = 'benefit'
      else if (this.studentData.scholarship) this.reEnrollmentCase = 'scholarship'
      else this.reEnrollmentCase = 'prontoPago'
    },
    async getBenefitState() {
      try{
        const state = await $studentProcessing.find(`benefitStatus?idStudentCareer=${this.studentRegister}`)
        const status = state.data.status.value
        
        if (status == 91) this.model.benefit.data.state = 'R'
        else if (status == 92) this.model.benefit.data.state = 'D'
        else if (status == 93) this.model.benefit.data.state = 'S'
        else if (status == 113) {
          this.model.benefit.data.procedure = state.data.id;
          this.model.benefit.data.state = 'W'
        }

      } catch {
        this.model.benefit.data.state = 'P'
      }
    },
    async getScholarshipState() {
      try{
        const state = await $students.find(`renewScholarshipStatus/${this.studentRegister}`)
        const status = state.data.renewScholarship.value

        if (status == 77) this.model.scholarship.data.state = 'S'
        else if (status == 78) this.model.scholarship.data.state = 'R'
        else if (status == 150) this.model.scholarship.data.state = 'D'
      }catch{
        this.model.scholarship.data.state = 'D'
      }
    },
    async getItem(){
      let item
      let cardBlocked
      let cardStatus

      if (this.reEnrollmentCase == 'benefit') {
        await this.getBenefitState()
        cardStatus = this.model.benefit.data.state;
        cardBlocked = this.model.benefit.data.benefitCardBlocked;
        item = {
          title: this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardBenefit.title'),
          documentation: [this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardBenefit.description')],
          warning: this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardBenefit.warning'),
          disabled: cardBlocked.includes(cardStatus),
          status: cardStatus, // S o R o D o P
          action: {
            text: cardBlocked.includes(cardStatus) ? '' : cardStatus === 'W' ? this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardBenefit.actionWarningTitle') : this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardBenefit.actionTitle'),
            disabled: cardBlocked.includes(cardStatus),
            callback: () => {this.selectedRenewal = 'benefitRenewal'; this.uploadDocumentation = true}
          }
        }
      } else if (this.reEnrollmentCase == 'scholarship') {
        await this.getScholarshipState()
        item = {
          title: this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardScholarship.title'),
          documentation: [this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardScholarship.description')],
          status: this.model.scholarship.data.state, // S o R scholarship
          warning: this.$t('modules.portalalumno.procedures.reEnrollmentStatus.cardScholarship.warning'),
          disabled: false,
          action: {
            text: '',
            disabled: false,
            callback() {}
          }
        }
      }

      return item
    },
    onPeriodUpdate(selectedPeriods) {
      this.selectedPeriods = selectedPeriods
    },
    dateFormat(dateString) {
      const date = new Date(dateString)
      return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear().toString().substr(-2)}`
    },
    async getAdaptationInsurance() {
      try {
        const {data} = await $studentAccount.find('getAdaptationInsuranceTerms', null, { params: { studentId: this.studentId }})
        this.adaptationInsuranceTerms = data.termIdLst
      } catch (error) {
        this.adaptationInsuranceTerms = []
        throw error
      }
    },
    async getProductsWithPricePeriods() {
        const response = await $studentAccount.findById(this.studentId)
        this.loading = true
        this.periodsSelectionSectionIsLoading = true
        try {
          const payments = []
          const {data} = await $productsWithPricePeriods.find(null, null, {params: { studentCareer: this.studentRegister }})
          const terms = data.products.map(product => {
            return {
              id: product.idTerm,
              code: product.termCode,
              description: product.termDescription,
              classStartDate: product.classStart,
              classEndDate: product.classEnd }
          })
          const uniqTerms = _.uniqBy(terms, 'id')
          this.accounts = data.products.map( product => { return {
            paymentInstallment: product.installment,
            paymentInstallments: product.installments,
            paymentAmount: product.amount ? parseInt(this.$decryter(product.amount)) : null,
            paymentDueDate: product.dueDate,
            expirationDate: product.dueDate,
            isMandatory: product.mandatory,
            term: { id: product.idTerm, code: product.termCode, description: product.termDescription }
          }})
          data.benefit?.products.map(item => {item.amount = item.amount? parseInt(this.$decryter(item.amount)):null,
              item.percentage = item.percentage? parseInt(this.$decryter(item.percentage)):null});
          data.products?.map(item =>{item.amount = parseInt(this.$decryter(item.amount))});
          data.discount?.details.map(item => {item.bianualAmount= item.bianualAmount? parseInt(this.$decryter(item.bianualAmount)):null
            item.bianualPercentage= item.bianualPercentage? parseInt(this.$decryter(item.bianualPercentage)):null
            item.bimesterAmount= item.bimesterAmount? parseInt(this.$decryter(item.bimesterAmount)):null
            item.bimesterPercentage= item.bimesterPercentage? parseInt(this.$decryter(item.bimesterPercentage)):null})
          data.referedCredits = data.referedCredits? parseInt(this.$decryter(data.referedCredits)):null;
          data.scholarship?.products.map(item => {item.amount = item.amount? parseInt(this.$decryter(item.amount)):null
              item.percentage = item.percentage? parseInt(this.$decryter(item.percentage)):null});
          data.uniqueDiscount = data.uniqueDiscount? parseInt(this.$decryter(data.uniqueDiscount)):null;
          this.teclabReenrollment = data
          this.periods = uniqTerms.sort((a, b) => moment(a.classStartDate) -moment(b.classStartDate)).map(period => {
            return { ...period, ...{ selected: true, longitud: uniqTerms.length } }
          })
          this.selectedPeriods = this.periods.map(period => { return {
            id: period.id,
            code: period.code,
            description: period.description,
            classStartDate: period.classStartDate
          } })
          if (this.$isTeclab) {
            response.data.accounts.map(item =>{item.paymentAmount = parseFloat(this.$decryter(item.paymentAmount))})
            response.data.uniqueDiscount = response.data.uniqueDiscount? parseFloat(this.$decryter(response.data.uniqueDiscount)):null
            if(this.systemConfig?.adaptationInsurance) {
              await this.getAdaptationInsurance()
            }
            if (this.teclabReenrollment && this.teclabReenrollment.products) {
              this.teclabReenrollment.products.forEach((prod) => {
                let total = prod.amount
                let off = 0
                let extraOff = 0
                let benefitAmount = 0
                let scholarshipAmount = 0

                const discount = this.teclabReenrollment.discount?.details?.find(disc => disc.billingProduct.id === prod.idProduct)
                if (discount) {
                  if (prod.idProduct === this.$sisConstants('modules.products.matricula')) {
                    if (discount.bianualAmount) off += discount.bianualAmount / total * 100
                    else if (discount.bianualPercentage) off += discount.bianualPercentage
                  } else if (prod.idProduct === this.$sisConstants('modules.products.arancel')) {
                    let discountOff = 0
                    if (discount.bimesterAmount) discountOff = discount.bimesterAmount / total * 100
                    else if (discount.bimesterPercentage) discountOff = discount.bimesterPercentage
                    off += discountOff

                    let bianualOff = 0
                    if (discount.bianualAmount) bianualOff = discount.bianualAmount / total * 100
                    else if (discount.bianualPercentage) bianualOff = discount.bianualPercentage
                    if (bianualOff && bianualOff > discountOff) extraOff = bianualOff - discountOff
                  }
                }

                if (this.teclabReenrollment.benefit?.statusProcessing?.status.value === 93 || !this.teclabReenrollment.benefit?.documentation.length) {
                  const benefit = this.teclabReenrollment.benefit?.products?.find(b => b.billingProduct === prod.idProduct)
                  if (benefit && (this.$isTeclab || off < 100)) {
                    benefitAmount = benefit.percentage ? total * benefit.percentage / 100 : benefit.amount
                    if (benefitAmount > total) {
                      benefitAmount = total
                    }
                    if (this.$isIPP) {
                      total -= benefitAmount
                    }
                    off += benefitAmount / prod.amount * 100
                  }
                }
                
                if(this.systemConfig?.adaptationInsurance) {
                  const isAdaptationInsurance = (prod.idProduct === this.$sisConstants('modules.products.arancel')
                          || prod.idProduct === this.$sisConstants('modules.products.matricula'))
                      && this.adaptationInsuranceTerms.includes(prod.idTerm)
                  if (isAdaptationInsurance) {
                    benefitAmount = prod.amount
                    off = 100
                  }
                }

                if (this.teclabReenrollment.referedCredits) {
                  this.referedCredits = this.teclabReenrollment.referedCredits
                  if (this.$isIPP) {
                    total -= this.referedCredits
                  }
                }

                if (!this.teclabReenrollment.scholarship?.renew || this.teclabReenrollment.scholarship?.renew.status?.value === 77) {
                  const scholarship = this.teclabReenrollment.scholarship?.products?.find(s => s.billingProduct === prod.idProduct)
                  if (scholarship && (this.$isTeclab || off < 100)) {
                    scholarshipAmount = scholarship.percentage ? total * scholarship.percentage / 100 : scholarship.amount
                    if (scholarshipAmount > total) {
                      scholarshipAmount = total
                    }
                    if (this.$isIPP) {
                      total -= scholarshipAmount
                    }
                    off += scholarship.percentage || scholarship.amount / total * 100
                  }
                }
                if (this.$isIPP && off > 100) off = 100
                if(this.systemConfig.dues && this.systemConfig.tariff >= 1) {
                  payments.push({
                    idProduct: prod.idProduct,
                    accountDescription: prod.name,
                    scholarshipAmount,
                    benefitAmount,
                    benefitExtra: null,
                    term: { id: prod.idTerm, description: prod.termCode},
                    paymentStudentRecord: this.studentRegister,
                    off,
                    reenrollmentExtraOff: extraOff,
                    isMandatory: prod.mandatory,
                    paymentAmount: prod.amount,
                    paymentDueDate: prod.dueDate,
                    paymentInstallment: prod.installment,
                    paymentInstallments: prod.installments,
                  })
                }
                else {
                  payments.push({
                    idProduct: prod.idProduct,
                    accountDescription: prod.name,
                    scholarshipAmount,
                    benefitAmount,
                    benefitExtra: null,
                    term: { id: prod.idTerm, description: prod.termCode},
                    paymentStudentRecord: this.studentRegister,
                    off,
                    reenrollmentExtraOff: extraOff,
                    isMandatory: prod.mandatory,
                    paymentAmount: prod.amount,
                    paymentDueDate: prod.dueDate,
                    paymentInstallment: 1,
                    paymentInstallments: 1,
                  })
                }
              });
            }
            payments.sort((a, b) => {
              if (b.billingProduct?.id == this.$sisConstants('modules.products.derecho_examen')) return 1
              const dateA = new Date(a.paymentDueDate)
              const dateB = new Date(b.paymentDueDate)
              if (dateA > dateB) return 1
              if (dateA < dateB) return -1
              if (a.billingProduct?.id == this.$sisConstants('modules.products.arancel') && b.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return 1
              if (b.billingProduct?.id == this.$sisConstants('modules.products.arancel') && a.billingProduct?.id == this.$sisConstants('modules.products.matricula')) return -1
              return 0
            });

            const adaptationInsuranceIndex = payments.findIndex(payment => payment.adaptationInsurance)
            if (adaptationInsuranceIndex !== -1) {
              payments.map((payment, index) => {
                payment.disabled = true
                if (adaptationInsuranceIndex === 0) {
                  if (payment.adaptationInsurance && (index === 0 || payments[index - 1].adaptationInsurance)) {
                    payment.disabled = false
                  }
                }
                if (index < adaptationInsuranceIndex) {
                  payment.disabled = false
                }
                return payment
              })
            }
            
            const groupsId = {
              arancel: 1,
              repactacion: 2,
              independent: 3,
              rematricula: 4,
            }

            payments.forEach(account => {

              const description = account.accountDescription.toLowerCase()
              const exp = new Date(account.paymentDueDate)
              let arr = null
              let title = null

              if (description.match(/.*cuota.*/)) title = account.accountDescription
              else if (account.billingProduct && account.billingProduct.id == 83) title = `Repactación cuota ${account.paymentInstallment}/${account.paymentInstallments}`
              else title = account.accountDescription

              let groupId = null
              if (account.billingProduct && (!account.reenrollmentId || this.$isIPP))  {
                switch (account.billingProduct.id) {
                  case 80:
                  case 81:
                    groupId = groupsId.arancel
                    break;
                  case 82:
                  case 85:
                  case 86:
                    groupId = groupsId.independent
                    break;
                  case 83:
                    groupId = groupsId.repactacion
                    break;
                  default:
                    groupId = groupsId.repactacion
                    break;
                }
              } else if (account.reenrollmentId) {
                groupId = groupsId.rematricula;
              } else {
                groupId = groupsId.arancel;
              }

              if (account.term && account.benefitExtra > 0) {
                this.showExtraBenefit = true
              }

              let off = 0
              const discounts = []
              let tmpTotal = account.paymentAmount
              if (groupId == groupsId.independent) {
                if (account.scholarshipAmount && account.scholarship) {
                  tmpTotal -= account.scholarshipAmount
                  const fullScholarship = account.scholarshipAmount >= account.paymentAmount
                  off += !fullScholarship ? account.scholarshipAmount / account.paymentAmount * 100 : 100
                  discounts.push({
                    description: account.scholarship.description,
                    off: !fullScholarship ? account.scholarshipAmount / account.paymentAmount * 100 : 100,
                    amount: !fullScholarship ? account.scholarshipAmount : account.paymentAmount
                  })
                }
                if (account.discountAmount && account.discount) {
                  const fullDiscount = account.discountAmount >= tmpTotal
                  if (!fullDiscount) off += (100 - off) * account.discountAmount / tmpTotal
                  else off = 100
                  discounts.push({
                    description: account.discount.description,
                    off: !fullDiscount ? (100 - off) * account.discountAmount / tmpTotal : 100 - off,
                    amount: !fullDiscount ? account.discountAmount : tmpTotal
                  })
                }
              }

              arr = {
                idProduct: account.idProduct,
                title,
                groupId,
                independent: groupId == groupsId.independent,
                isMandatory: false,
                // status: account.paymentStatus.value,

                // this is required for re-enrollment payment
                // isHiddenProduct: account.billingProduct && this.productsToHide.includes(`${account.billingProduct.id}`),
                // billingProduct: account.billingProduct ? account.billingProduct.id : null,

                options: {
                  term: account.term,
                  dueDate: account.paymentDueDate,
                  expiration: `Hasta el ${this.dateFormat(account.paymentDueDate)}`,
                  paymentInstallment: account.paymentInstallment,
                  paymentInstallments: account.paymentInstallments,
                  month: exp.getUTCMonth() + 1,
                  scholarshipAmount: account?.scholarshipAmount || 0,
                  benefitAmount: account?.benefitAmount || 0,
                  benefitExtra: account.benefitExtra,
                  extraOff: 0,
                  reenrollmentExtraOff: account.reenrollmentExtraOff ? account.reenrollmentExtraOff : 0,
                  addedExtraOff: 0,
                  off: this.$options.filters.$roundNumber(account.off ? account.off : off),
                  discounts,
                  amount: account.paymentAmount,
                  adaptationInsurance: account.adaptationInsurance,
                  disabled: !!account?.disabled,
                }
              }
              if (arr) {
                  if (this.systemConfig.dues) {
                    arr.isMandatory = account.isMandatory;
                  }
                  this.payments.push(arr)
              }
            });
          }
          // eslint-disable-next-line no-useless-catch
        } catch(err) {
          throw err
        } finally {
          this.loading = false
          this.periodsSelectionSectionIsLoading = false
        }
    },

  },
  computed: {
    ...mapGetters({
      studentId: 'commons/getStudentId',
      studentRegister: 'commons/getStudentActualRegister',
      studentData: 'commons/getStudentData',
    }),
    hasContract() {
      return this.systemConfig?.contract
    },
    isSubmitButtonDisabled() {
      if(!this.needGenerateMatricula && this.selectedPeriods.length < 1) {
        return true
      }
      if(!this.systemConfig?.contract && !this.needGenerateMatricula) {
        return false
      }
      return this.systemConfig?.contract && !this.needGenerateMatricula
    },
    submitButtonLabel() {
      if(this.needGenerateMatricula) {
        return 'modules.portalalumno.procedures.reEnrollmentStatus.startReEnrollment'
      }
      if(!this.systemConfig?.contract) {
        return 'modules.portalalumno.procedures.reEnrollmentStatus.goToPay'
      }
      return 'modules.portalalumno.procedures.reEnrollmentStatus.startArancel'
    },
    reEnrollmentBlocked() {
      return (this.reEnrollmentCase === 'benefit' && this.model.benefit.data.benefitStateBlocked.includes(this.model.benefit.data.state))
    },
    showItem() {
      if(this.needGenerateMatricula) return false
      else return this.reEnrollmentCase !== 'prontoPago'
    },
    showWarning() {
      const currentCase = this.model[this.reEnrollmentCase].data
      return currentCase.warning.includes(currentCase.state)
    },
    disabledAnexo() {
      return !this.needGenerateMatricula && this.showWarning && this.reEnrollmentCase === 'benefit'
    },
    isNI() {
      return this.studentData.studentType.meaning.toUpperCase() === 'INGRESANTE'
    }
  },
  mounted () {
    this.loading = true
    this.initialize()
  }
}
</script>

<style lang="sass" scoped>
  .card-title-reEnrollment
    background-size: cover
    background-position-x: 36%
</style>