import NotFoundState from '@/components/NotFoundState/NotFoundState'
import AcademicView from './Academic';
import Documentation from './Documentation/Documentation';
import DocumentationRoute from './Documentation/Routes';
import IntermediateDegree from './IntermediateDegree/IntermediateDegree';
import IntermediateDegreeRoute from './IntermediateDegree/Routes';
import Gradebook from './Gradebook/Gradebook';
import GradebookRoute from './Gradebook/Routes';
import ProfessionalizingPractice from './ProfessionalizingPractice/ProfessionalizingPractice';
import ProfessionalizingPracticeRoute from './ProfessionalizingPractice/Routes';
import Periods from './Periods/Periods';
import PeriodsRoute from './Periods/Routes';
import Sanctions from './Sanctions/Sanctions';
import SanctionsRoute from './Sanctions/Routes';
import ProfessionalPracticeTerms from './ProfessionalPracticeTerms/ProfessionalPracticeTerms';
import ProfessionalPracticeTermsRoute from './ProfessionalPracticeTerms/Routes';
import EquivalencesRoute from './Equivalences/Routes';
import Equivalences from './Equivalences/Equivalences';

const AcademicRoute = {

    path: '/academic',
    meta: {
        name: {
            en: 'Academic',
            es: 'Académica'
        },
    },
    component: AcademicView,
    children:[
        {
            path: '/',
            component: NotFoundState,
        },{
            path: 'documentation',
            meta: { name: { es: 'Generación de Documentación', en: 'Generación de Documentación' } },
            component: Documentation,
            children: DocumentationRoute
        },{
            path: 'intermediate-degree',
            meta: { name: { es: 'Titulo intermedio', en: 'Intermediate degree' } },
            component: IntermediateDegree,
            children: IntermediateDegreeRoute
        },{
            path: 'gradebook',
            meta: { name: { es: 'Libro matriz', en: 'Gradebook' } },
            component: Gradebook,
            children: GradebookRoute
        },{
            path: 'professionalizing-practice',
            meta: { name: { es: 'Práctica profesionalizante', en: 'Professionalizing practice' } },
            component: ProfessionalizingPractice,
            children: ProfessionalizingPracticeRoute
        },{
          path: 'periods',
          meta: { name: { es: 'Periodos', en: 'Periods' } },
          component: Periods,
          children: PeriodsRoute
        },{
          path: 'sanctions',
          meta: { name: { es: 'Sanciones', en: 'Sanctions' } },
          component: Sanctions,
          children: SanctionsRoute
        },{
          path: 'practice-terms',
          meta: { name: { es: 'Periodos de prácticas profesionales', en: 'Professional practice terms' } },
          component: ProfessionalPracticeTerms,
          children: ProfessionalPracticeTermsRoute
        },{
            path: 'equivalences',
            meta: { name: { es: 'Equivalencias', en: 'Equivalences' } },
            component: Equivalences,
            children: EquivalencesRoute
          }
    ]
};

export default AcademicRoute
