
import Constants from '@/plugins/constants'

// General routes
import ApplicationsRoute from './Applications/Routes';
import ProfileRoute from './Profile/Routes';


// Portal administrativo routes
import AcademicRoute from './portaladministrativo/Academic/router.js'
import AdmissionRoute from './portaladministrativo/Admission/router.js';
import BenefitsRoute from './portaladministrativo/Benefits/Routes'
import CareersRoute from './portaladministrativo/Careers/Routes';
import CommissionsRoute from './portaladministrativo/Commissions/router.js'
import CommunitiesRoute from './portaladministrativo/Communities/Routes'
import ConciliationRoute from './portaladministrativo/Conciliation/Routes'
import DashboardRoute from './portaladministrativo/Dashboard/Routes';
import DegreesRoute from './portaladministrativo/Degrees/Routes'
import DepartmentsRoute from './portaladministrativo/Departments/Routes'
import DiscountsRoute from './portaladministrativo/Discounts/Routes'
import ExamEditorRoute from './portaladministrativo/ExamEditor/Routes'
import ExamEnablerRoute from './portaladministrativo/ExamEnabler/Routes'
import ExamExceptionRoute from './portaladministrativo/ExamException/Routes'
import ExamTitleNotesRoute from './portaladministrativo/ExamTitleNotes/Routes'
import ExceptionsRoute from './portaladministrativo/Exceptions/Routes'
import PrepaidsRoute from './portaladministrativo/Prepaids/Routes'
import PriceRoute from './portaladministrativo/Prices/Routes'
import PricingRoute from './portaladministrativo/Pricing/router.js';
import ProceduresRoute from './portaladministrativo/Procedures/Routes'
import ProductRoute from './portaladministrativo/Products/routes';
import QuartersRoute from './portaladministrativo/Quarters/routes';
import ReportsRoute from './portaladministrativo/Reports/Routes'
import RolesRoute from './portaladministrativo/Roles/Routes';
import ScholarshipsRoute from './portaladministrativo/Scholarships/Routes';
import SectionsRoute from './portaladministrativo/Sections/Routes'
import SettingsRoute from './portaladministrativo/Settings/Routes';
import SistemaQExamBoardRoute from './portaladministrativo/SistemaQExamBoard/Routes'
import studentPreRegistrationRoute from './portaladministrativo/StudentPreRegistration/Routes'
import StudentSubjectsRoute from './portaladministrativo/StudentSubjects/Routes'
import SubjectsRoute from './portaladministrativo/Subjects/routes'
import SubjectsSyncRoute from './portaladministrativo/SubjectsSync/Routes'
import UsersRoute from './portaladministrativo/Users/Routes';
import ZonesRoute from './portaladministrativo/Zones/routes';
import MessagingRoute from './portaladministrativo/Messaging/routes';
import ExamsRoute from './portaladministrativo/Exams/router'
import PaymentIntentsRoute from './portaladministrativo/PaymentIntents/Routes'
import StatusConnectionRoute from './portaladministrativo/StatusConnection/Routes'
import ModuleControlRoute from './portaladministrativo/ModuleControl/Routes'

// Portal comisiones routes
import ComisionesRoute from './comisiones/router'

// Portal alumno routes
import PortalAlumnoRoute from './portalalumno/router'



const ModulesRouter = [
    ApplicationsRoute,
];

const portaladministrativoRouter = [
    AcademicRoute,
    AdmissionRoute,
    BenefitsRoute,
    CareersRoute,
    CommissionsRoute,
    CommunitiesRoute,
    ConciliationRoute,
    DashboardRoute,
    DegreesRoute,
    DepartmentsRoute,
    DiscountsRoute,
    ExamEditorRoute,
    ExamEnablerRoute,
    ExamExceptionRoute,
    ExamTitleNotesRoute,
    ExceptionsRoute,
    PrepaidsRoute,
    PriceRoute,
    PricingRoute,
    ProceduresRoute,
    ProductRoute,
    QuartersRoute,
    ReportsRoute,
    RolesRoute,
    ScholarshipsRoute,
    SectionsRoute,
    SettingsRoute,
    SistemaQExamBoardRoute,
    studentPreRegistrationRoute,
    StudentSubjectsRoute,
    SubjectsRoute,
    SubjectsSyncRoute,
    UsersRoute,
    ZonesRoute,
    MessagingRoute,
    ExamsRoute,
    PaymentIntentsRoute,
    StatusConnectionRoute,
    ModuleControlRoute,
];

if (Constants.APP == 'portaladministrativo') ModulesRouter.push( ...portaladministrativoRouter, ProfileRoute)
else if (Constants.APP == 'comisiones') ModulesRouter.push( ...ComisionesRoute, ProfileRoute )
else if (Constants.APP == 'portalalumno') ModulesRouter.push( ...PortalAlumnoRoute )

export default ModulesRouter;
