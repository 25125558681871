<template>
    <v-container>
        <v-row align="center" class="mt-2 mb-8">
            <v-col sm="5">
                <span class="subtitle-text">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subtitle') }}</span>
            </v-col>
            <v-col class="d-flex justify-end" sm="7">
                <div :key="`semester-${index}`" class="px-2" v-for="(item, index) in states">
                    <v-card :color="item.colorDec" class="caption states-card text-center py-2 px-6" flat>
                        <span :class="item.colorText" class="text-no-wrap text-uppercase ">{{ item.state }}</span>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <div class="study-plan">
            <div class="study-plan__bimesters-headers">
                <h6 class="study-plan__bimesters-title primary--text">
                    {{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.bimesterA') }}
                </h6>
                <h6 class="study-plan__bimesters-title primary--text">
                    {{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.bimesterB') }}
                </h6>
            </div>
            <div>
                <div class="study-plan-grid">
                    <div :class="['study-plan__semester__header lighten-4', isExamenTitulo(indexS) ? '' : 'primary']" :key="`semester-header-${indexS}`" v-for="indexS in semesters">
                        <span :class="[`grid-column-${indexS}`, 'caption', 'font-weight-medium', 'white--text']">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.period',{periodType:indexS}) }}</span>
                    </div>

                    <template v-for="indexS in semesters">
                        <template v-if="!isExamenTitulo(indexS)">
                            <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreA[indexS - 1]">
                                <template v-slot:activator="{ on }">
                                    <v-card
                                        class="study-plan__semester__bimester__subject subject-card lighten-4"
                                        :class="[index === studyPlans.bimestreA[indexS - 1].length ? 'mb-6' : '', `grid-column-${indexS}`, `grid-row-${index+2}`]"
                                        :color="cardColor(item.state)"
                                        @click="dialog = true, subjectClicked(index, item)"
                                        flat outlined
                                        v-on="on"
                                    >
                                        <div :class="textColor(item.state)" class="study-plan__semester__bimester__subject-text text-truncated pa-2">{{ item.title }}</div>
                                    </v-card>
                                </template>
                                <span>{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view') }}</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <div :class="['study-plan__semester__bimester__subject-semestral', 'grid-2', `grid-column-${indexS}`]" :key="`bimester-1-${indexS}`">
                                <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreA[indexS - 1]">
                                    <template v-slot:activator="{ on }">
                                        <v-card :class="['subject-card lighten-4', `grid-row-${index}`, 'grid-column-1 , ml-4']" :color="cardColor(item.state)" @click="dialog = true, subjectClicked(index, item)" flat outlined v-on="on">
                                            <div :class="textColor(item.state)" class="study-plan__semester__bimester__subject-text text-truncated pa-2">{{ item.title }}</div>
                                        </v-card>
                                    </template>
                                    <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-if="studyPlans.semestrales[indexS-1].length > 0">
                            <div
                                :class="[withMargin(indexS - 1) ? 'study-plan__semester__bimester__subject-semestral-with-margin': '','study-plan__semester__bimester__subject-semestral', `grid-${studyPlans.semestrales[indexS-1].length}`, `grid-column-${indexS}`]"
                                :key="`bimester-1-${indexS}`"
                            >
                                <v-tooltip :key="`${index}-${item.title}`" top v-for="(item, index) in studyPlans.semestrales[indexS-1]">
                                    <template v-slot:activator="{ on }">
                                        <v-card :class="['subject-card lighten-4', `grid-row-${index}`]" :color="cardColor(item.state)" @click="dialog = true, subjectClicked(index, item)" flat outlined v-on="on">
                                            <div :class="textColor(item.state)" class="study-plan__semester__bimester__subject-text"><p>{{ item.title }}</p></div>
                                        </v-card>
                                    </template>
                                    <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>

                    <template v-for="indexS in semesters">
                        <v-tooltip :key="item.title" top v-for="(item, index) in studyPlans.bimestreB[indexS-1]">
                            <template v-slot:activator="{ on }">
                                <v-card :class="['study-plan__semester__bimester__subject', 'subject-card lighten-4', `grid-column-${indexS}`, `grid-row-${index+5}`]" :color="cardColor(item.state)" @click="dialog = true, subjectClicked(index, item)" flat outlined v-on="on">
                                    <div :class="textColor(item.state)" class="study-plan__semester__bimester__subject-text text-truncated pa-2">{{item.title}}</div>
                                </v-card>
                            </template>
                            <span>{{$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_view')}}</span>
                        </v-tooltip>
                    </template>
                </div>
            </div>
        </div>
        <v-dialog :width="((preRequisitesFrom.length > 1) || (preRequisitesOf.length > 1)) ? '80%' : '60%'" v-model="dialog">
            <v-card>
                <v-card-title class="primary white--text d-flex" primary-title>
                    <div class="headline flex-grow-1 text-center">{{ subjectTitle }}</div>
                    <v-btn icon dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-12">
                    <v-row>
                        <v-col sm="4" class="px-0">
                            <h3 class="prerequisites-title my-0 text-right">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement') }}:</h3>
                        </v-col>
                        <v-col sm="8" class="body-1">
                            <div class="pl-5 mb-2" v-if="!preRequisitesFrom.length">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.no_requirement')}}</div>
                            <div v-else :key="`subject-from-${index}`" class="pl-5 mb-2 text-no-wrap" v-for="(item, index) in preRequisitesFrom">{{ item }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="9" class="mx-auto">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" class="px-0">
                            <h3 class="prerequisites-title my-0 text-right">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.requirement_of') }}:</h3>
                        </v-col>
                        <v-col sm="8" class="body-1">
                            <div class="pl-5 mb-2" v-if="!preRequisitesOf.length">{{ $t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.no_requirement_of')}}</div>
                            <div v-else :key="`subject-Of-${index}`" class="pl-5 mb-2 text-no-wrap" v-for="(item, index) in preRequisitesOf">{{ item }}</div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: 'StudyPlanComponent',
        props: {
            rawStudyPlans: Array
        },
        data() {
            return {
                dialog: false,
                semesters: 0,
                subjectsLastSemesters: [],
                subjectTitle: '',
                preRequisitesOf: '',
                preRequisitesFrom: '',
                states: [
                    {
                        state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.noStudying'),
                        color: 'blue-grey lighten-4',
                        colorDec: '#f9fafc',
                        colorText: 'grey--text text--darken-2'
                    },{
                        state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.studying'),
                        color: '#616DDC',
                        colorDec: '#717EFF',
                        colorText: 'white--text'
                    },{
                        state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.aproved'),
                        color: '#C4E9C4',
                        colorDec: '#D6FFD6',
                        colorText: 'grey--text text--darken-2'
                    },{
                        state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.available'),
                        color: 'lighten-4',
                        colorDec: '#52D87E',
                        colorText: 'white--text'
                    },{
                        state: this.$t('modules.portalalumno.academic_charge.studyPlan.studyPlanView.subjectsStates.reproved'),
                        color: '#F699CC',
                        colorDec: '#F4BADA',
                        colorText: 'pink--text text--darken-4'
                    }
                ],
                studyPlans: { // Defino 9 semestres por defecto para no tener que generarlos dinamicamente
                    bimestreA: [[], [], [], [], [], [], [], [], []],
                    bimestreB: [[], [], [], [], [], [], [], [], []],
                    semestrales: [[], [], [], [], [], [], [], [], []]
                },
            }
        },
        methods: {
            // Data parcer
            isExamenTitulo(index) {
                const count = this.studyPlans.bimestreA[index - 1].length + this.studyPlans.bimestreB[index - 1].length + this.studyPlans.semestrales[index - 1].length
                return (index == this.semesters && count == 1);
            },
            withMargin(index){
                return this.studyPlans.bimestreA[index].length !== 0 || this.studyPlans.bimestreB[index].length !== 0; 
            },
            stateParcer(status) {
                if (status.toUpperCase() == 'LIBRE') return this.$isTeclab ? 'Desaprobada' :  'Reprobada'
                else if (status.toUpperCase() == 'REGULAR') return this.$isTeclab ? 'Regular' : 'Habilitada'
                else return status
            },
            subjectsParcer(studyPlans) {
                studyPlans.forEach(subject => {
                    const subjectParsed = {
                        title: subject.subject.descripcion,
                        state: !subject.subjectStatus ? 'No cursado' : this.stateParcer(subject.subjectStatus.meaning),
                        semester: subject.semester,
                        bimester: subject.subject.duration.meaning === 'Semestral' ? 'C' : subject.termType.meaning,
                        preRequisitesOf: subject.preRequisites ? subject.preRequisites : [],
                        preRequisitesFrom: subject.preRequisitesFrom ? subject.preRequisitesFrom : []
                    };

                    if (this.semesters < subjectParsed.semester) this.semesters = subjectParsed.semester;

                    switch (subjectParsed.bimester) {
                        case 'A':
                            this.studyPlans.bimestreA[subjectParsed.semester - 1].push(subjectParsed);
                            break;
                        case 'B':
                            this.studyPlans.bimestreB[subjectParsed.semester - 1].push(subjectParsed);
                            break;
                        case 'C':
                            this.studyPlans.semestrales[subjectParsed.semester - 1].push(subjectParsed);
                            break
                    }
                });
                this.$emit('lodingState', true);
            },
            subjectClicked(index, item) {
                this.subjectTitle = item.title;
                this.preRequisitesOf = item.preRequisitesOf
                this.preRequisitesFrom = item.preRequisitesFrom
                if (item.title == 'PRÁCTICA LABORAL') this.$trackEvent('Carga académica', 'Click - Práctica laboral', 'Plan de estudio')
                else if (item.title == 'TRABAJO DE TÍTULO') this.$trackEvent('Carga académica', 'Click - Trabajo de título', 'Plan de estudio')
                else if (item.title == 'EXAMEN DE TÍTULO') this.$trackEvent('Carga académica', 'Click - Examen de título', 'Plan de estudio')
            },
            cardColor(status) {
                for (let i = 0; i < this.states.length; i++) {
                    if (status.toLowerCase() === this.stateParcer(this.states[i].state).toLowerCase()) {
                        return this.states[i].colorDec
                    }
                }
            },
            textColor(status) {
                for (let i = 0; i < this.states.length; i++) {
                    if (status.toLowerCase() === this.stateParcer(this.states[i].state).toLowerCase()) {
                        return this.states[i].colorText
                    }
                }
            },
            countSubjectsLastSemesters() {
                for (let i = 0; i < this.studyPlans.length; i++) {
                    if (this.studyPlans[i].semester === this.semesters) {
                        this.subjectsLastSemesters.push(this.studyPlans[i])
                    }
                }
            },
            showSemestralSubject(index) {
                return this.studyPlans.semestrales[index - 1].length > 0
            }
        }
    }
</script>

<style lang="sass" scoped>
    .study-plan
        display: flex
        align-items: stretch

        &-grid
            min-width: auto
            width: 100%
            padding: 0 0.25rem
            display: grid
            grid-auto-columns: auto
            grid-row-gap: 0.3rem
            grid-column-gap: 0.3rem

        &__bimesters
            &-headers
                display: flex
                flex-direction: column
                justify-content: space-around
                padding: 1.5rem 2rem 0 0
            &-title
                text-align: center
                writing-mode: vertical-lr
                transform: rotate(180deg)

        &__semester
            &__header
                width: 100%
                height: 1.5rem
                margin-bottom: 0.25rem
                display: flex
                justify-content: center
                align-items: center
                border-radius: 5px

            &__bimester
                margin-bottom: 0.25rem

                &__subject
                    width: 6.5rem

                    &-text
                        font-size: 0.625rem !important
                        line-height: 1.1

                    &-semestral
                        display: grid
                        grid-column-gap: 0.3rem
                        grid-row: 2 / 8
                        width: auto
                        &-with-margin
                            margin-left: 6.8rem
                        & > div
                            /*display: flex*/
                            /*justify-content: center*/
                            /*align-items: center*/
                            /*width: auto*/
                            display: inline-flex
                            text-align: center

                            .study-plan__semester__bimester__subject-text
                                writing-mode: vertical-lr
                                transform: rotate(180deg)
                                width: 1.5rem
                                text-align: center
                
                                & > p
                                    padding: .3rem

    .v-dialog.v-dialog--active
        &:not(.v-dialog--fullscreen)
            max-height: 70%
            overflow: hidden
            .v-card__text
                overflow: auto
                max-height: 400px

        .prerequisites-title
            font-family: "DM Sans", "Roboto" !important
            letter-spacing: 0.0125em !important
            font-weight: 700 !important
            font-size: 1.25rem !important

    .subtitle-text
        font-size: 1rem
        line-height: 1

    .states-card
        padding: 0
</style>