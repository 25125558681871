<template>
  <OutlinedCard
    :title="$t('modules.admission.preregistration.create.preregistrationInfo.title')"
    class="mb-6"
  >
    <v-row>
      <v-col class="col-12">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.preregistrationInfo.saleTerm.title') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-select
          ref="selectedSaleTerm"
          v-model="$v.preregStudentData.selectedSaleTerm.$model"
          :error-messages="$v.preregStudentData.selectedSaleTerm.$error ? $t('modules.admission.preregistration.validations.saleTermRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.saleTerm.labels.term')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.saleTerm')"
          outlined
          :items="saleTerms"
          item-text="code"
          return-object
          @blur="$v.preregStudentData.selectedSaleTerm.$touch()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.preregistrationInfo.applicantData.title') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-text-field
          :error-messages="($v.preregStudentData.name.$dirty && $v.preregStudentData.name.$invalid) ?
                  ((!$v.preregStudentData.name.required) ? $t('modules.admission.preregistration.validations.nameRequired') :
                  (!$v.preregStudentData.name.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.nameAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.nameMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.name.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.name.$model,60, true, true)"
          v-model="$v.preregStudentData.name.$model"
          ref="name"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.name')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-6">
        <v-text-field
          :error-messages="($v.preregStudentData.lastname.$dirty && $v.preregStudentData.lastname.$invalid) ?
                  ((!$v.preregStudentData.lastname.required) ? $t('modules.admission.preregistration.validations.lastnameRequired') :
                  (!$v.preregStudentData.lastname.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.lastnameAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.lastnameMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.lastname.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.lastname.$model,60, true, true)"
          v-model="$v.preregStudentData.lastname.$model"
          ref="lastname"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.lastname')"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <BirthdayDatePicker
          v-model="$v.preregStudentData.birthDate.$model"
          :required="$v.preregStudentData.birthDate.$invalid"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.birthDate')"
          :hide-details="false"
          ref="birthDate"
          @save="(date) => {$v.preregStudentData.birthDate.$model = date}"
        ></BirthdayDatePicker>
      </v-col>
      <v-col class="col-4">
        <AutocompleteInput
          :value.sync="$v.preregStudentData.birthPlace.$model"
          :required="$v.preregStudentData.birthPlace.$invalid"
          :errorMessages="$t('modules.admission.preregistration.validations.birthPlaceRequired')"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.birthPlace')"
          disable-buttons
          ref="birthPlace"
        ></AutocompleteInput>
      </v-col>
      <v-col class="col-4">
        <v-autocomplete
          :error-messages="$v.preregStudentData.selectedNationality.$error ? $t('modules.admission.preregistration.validations.nationalityRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.nationality')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.nationalities')"
          outlined
          :items="nationalities"
          ref="selectedNationality"
          item-text="demonym"
          item-value="id"
          v-model="$v.preregStudentData.selectedNationality.$model"
          @blur="$v.preregStudentData.selectedNationality.$touch()"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-select
          :error-messages="$v.preregStudentData.selectedIdentificationType.$error ? $t('modules.admission.preregistration.validations.identificationTypeRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.identificationType')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.identificationType')"
          outlined
          :items="identificationTypes"
          :loading="loadingIdentificationTypes"
          disabled
          ref="selectedIdentificationType"
          item-text="meaning"
          v-model="$v.preregStudentData.selectedIdentificationType.$model"
          @blur="$v.preregStudentData.selectedIdentificationType.$touch()"
        />
      </v-col>
      <v-col class="col-3">
        <IdentificationInput
          :error-messages="($v.preregStudentData.identification.$dirty && $v.preregStudentData.identification.$invalid) ?
                  (!$v.preregStudentData.identification.required ? $t('modules.admission.preregistration.validations.identificationRequired') :
                  $t('modules.admission.preregistration.validations.identificationUnique')) :
                  ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.identification')"
          :id-type="!$isTeclab ? 'RUT' : ''"
          :loading="loadingIdentification"
          v-model="$v.preregStudentData.identification.$model"
          ref="identification"
          @posTimer="() => { this.validateIdentification() }"
          @validateRut="(status) => { this.validRUT = status }"
          @blur="$v.preregStudentData.identification.$touch()"
        >
        </IdentificationInput>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          v-if="!$isTeclab"
          :error-messages="($v.preregStudentData.serialNumber.$dirty && $v.preregStudentData.serialNumber.$invalid) ?
                  ((!$v.preregStudentData.serialNumber.required) ? $t('modules.admission.preregistration.validations.serialNumberRequired') :
                  (!$v.preregStudentData.serialNumber.numeric) ? $t('modules.admission.preregistration.validations.serialNumberNumeric') :
                  $t('modules.admission.preregistration.validations.serialNumberMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.serialNumber.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.serialNumber.$model,10, false, false)"
          v-model="$v.preregStudentData.serialNumber.$model"
          ref="serialNumber"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.serialNumber')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col v-if="hasDebt" class="col-12 pt-0">
        <v-alert
          class="text-body-2 mb-0"
          text
          color="#94A21E"
        >
          {{ $t('modules.admission.preregistration.validations.warnings.debt') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-select
          :error-messages="$v.preregStudentData.selectedGender.$error ? $t('modules.admission.preregistration.validations.genderRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.gender')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.gender')"
          outlined
          :items="genders"
          :loading="loadingGenders"
          ref="selectedGender"
          item-text="meaning"
          v-model="$v.preregStudentData.selectedGender.$model"
          @blur="$v.preregStudentData.selectedGender.$touch()"
        />
      </v-col>
      <v-col class="col-4">
        <v-select
          :error-messages="$v.preregStudentData.selectedMaritalStatus.$error ? $t('modules.admission.preregistration.validations.maritalStatusRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.maritalStatus')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.maritalStatus')"
          outlined
          :items="maritalStatus"
          :loading="loadingMaritalStatus"
          ref="selectedMaritalStatus"
          item-text="meaning"
          v-model="$v.preregStudentData.selectedMaritalStatus.$model"
          return-object
          @blur="$v.preregStudentData.selectedMaritalStatus.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-text-field
          :error-messages="($v.preregStudentData.email.$dirty && $v.preregStudentData.email.$invalid) ?
                  ((!$v.preregStudentData.email.required) ? $t('modules.admission.preregistration.validations.emailRequired') :
                  $t('modules.admission.preregistration.validations.emailFormat')) :
                  ''"
          @blur="$v.preregStudentData.email.$touch()"
          v-model="$v.preregStudentData.email.$model"
          ref="email"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.email')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-6">
        <TelephoneInput
          :value.sync="$v.preregStudentData.telephone.$model"
          :placeholder="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.telephone')"
          :required="$v.preregStudentData.telephone.$dirty && $v.preregStudentData.telephone.$invalid"
          ref="telephone"
          @blur="$v.preregStudentData.telephone.$touch()"
          @validate="isValidPhone"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="!$isTeclab ? 6 : 12">
        <v-autocomplete
          :error-messages="$v.preregStudentData.selectedCareer.$error ? $t('modules.admission.preregistration.validations.careerRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.career')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.career')"
          outlined
          :items="careers"
          ref="selectedCareer"
          item-text="description"
          v-model="$v.preregStudentData.selectedCareer.$model"
          return-object
          @change="$emit('careerChange', $v.preregStudentData.selectedCareer.$model)"
          @blur="$v.preregStudentData.selectedCareer.$touch()"
        />
      </v-col>
      <v-col v-if="!$isTeclab" class="col-6">
        <v-select
          :error-messages="$v.preregStudentData.selectedStudyPlan.$error ? $t('modules.admission.preregistration.validations.studyPlanRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.applicantData.labels.studyPlan')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.studyPlan')"
          outlined
          :items="studyPlans"
          ref="selectedStudyPlan"
          v-model="$v.preregStudentData.selectedStudyPlan.$model"
          return-object
          @blur="$v.preregStudentData.selectedStudyPlan.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.preregistrationInfo.address.title') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-text-field
          :error-messages="($v.preregStudentData.address.$dirty && $v.preregStudentData.address.$invalid) ?
                  ((!$v.preregStudentData.address.required) ? $t('modules.admission.preregistration.validations.addressRequired') :
                  (!$v.preregStudentData.address.alphaNumWithSpaces) ? $t('modules.admission.preregistration.validations.addressAlphanumeric') :
                  $t('modules.admission.preregistration.validations.addressMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.address.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.address.$model,60, true, false)"
          v-model="$v.preregStudentData.address.$model"
          ref="address"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.address')"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-text-field
          :error-messages="($v.preregStudentData.number.$dirty && $v.preregStudentData.number.$invalid) ?
                  ((!$v.preregStudentData.number.required) ? $t('modules.admission.preregistration.validations.addressNumberRequired') :
                  (!$v.preregStudentData.number.numeric) ? $t('modules.admission.preregistration.validations.addressNumberNumeric') :
                  $t('modules.admission.preregistration.validations.addressNumberMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.number.$touch()"
          type="number"
          v-model.number="$v.preregStudentData.number.$model"
          ref="number"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.number')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          :error-messages="($v.preregStudentData.floor.$dirty && $v.preregStudentData.floor.$invalid) ?
                  // ((!$v.preregStudentData.floor.required) ? $t('modules.admission.preregistration.validations.addressFloorRequired') :
                  ((!$v.preregStudentData.floor.numeric) ? $t('modules.admission.preregistration.validations.addressFloorNumeric') :
                  $t('modules.admission.preregistration.validations.addressFloorMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.floor.$touch()"
          v-model.number="$v.preregStudentData.floor.$model"
          ref="floor"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.floor')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          :error-messages="($v.preregStudentData.apartment.$dirty && $v.preregStudentData.apartment.$invalid) ?
                  // ((!$v.preregStudentData.apartment.required) ? $t('modules.admission.preregistration.validations.addressApartmentRequired') :
                  ((!$v.preregStudentData.apartment.alphaNum) ? $t('modules.admission.preregistration.validations.addressApartmentAlphanumeric') :
                  $t('modules.admission.preregistration.validations.addressApartmentMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.apartment.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.apartment.$model,4, true, false)"
          v-model="$v.preregStudentData.apartment.$model"
          ref="apartment"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.apartment')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          :error-messages="($v.preregStudentData.zipCode.$dirty && $v.preregStudentData.zipCode.$invalid) ?
                  ((!$v.preregStudentData.zipCode.required) ? $t('modules.admission.preregistration.validations.zipCodeRequired') :
                  (!$v.preregStudentData.zipCode.alphaNumWithSpaces) ? $t('modules.admission.preregistration.validations.zipCodeAlphanumeric') :
                  $t('modules.admission.preregistration.validations.zipCodeMaxLength')) :
                  ''"
          @blur="$v.preregStudentData.zipCode.$touch()"
          @keypress="$validateAlphanumeric($event, $v.preregStudentData.zipCode.$model,10, false, false)"
          v-model="$v.preregStudentData.zipCode.$model"
          ref="zipCode"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.zipCode')"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <AutocompleteInput
          :value.sync="$v.preregStudentData.city.$model"
          :required="$v.preregStudentData.city.$invalid"
          :errorMessages="$t('modules.admission.preregistration.validations.addressCityRequired')"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.address.labels.city')"
          disable-buttons
          ref="city"
        >
        </AutocompleteInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.preregistrationInfo.previousStudies.title') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-5">
        <v-select
          :error-messages="$v.preregStudentData.selectedStudentLevel.$error ? $t('modules.admission.preregistration.validations.studentLevelRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.previousStudies.labels.level')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.studentLevel')"
          outlined
          :items="studentLevels"
          :loading="loadingStudentLevels"
          ref="selectedStudentLevel"
          item-text="meaning"
          v-model="$v.preregStudentData.selectedStudentLevel.$model"
          @blur="$v.preregStudentData.selectedStudentLevel.$touch()"
        />
      </v-col>
    </v-row>
    <span class="text-body-1 font-weight-bold">{{ $t('modules.admission.preregistration.create.preregistrationInfo.previousStudies.subtitle') }}</span>
    <v-row>
      <v-col class="col-6">
        <AutocompleteInput
          :value.sync="$v.institutionCity.$model"
          :required="$v.institutionCity.$invalid"
          :errorMessages="$t('modules.admission.preregistration.validations.institutionCityRequired')"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.previousStudies.labels.city')"
          disable-buttons
          ref="institutionCity"
        >
        </AutocompleteInput>
      </v-col>
      <v-col class="col-6">
        <v-autocomplete
          :error-messages="$v.preregStudentData.selectedInstitution.$error ? $t('modules.admission.preregistration.validations.institutionRequired') : ''"
          :label="$t('modules.admission.preregistration.create.preregistrationInfo.previousStudies.labels.institution')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.institution')"
          outlined
          :items="institutions"
          :loading="loadingInstitution"
          ref="selectedInstitution"
          item-text="name"
          item-value="id"
          v-model="$v.preregStudentData.selectedInstitution.$model"
          @blur="$v.preregStudentData.selectedInstitution.$touch()"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import BirthdayDatePicker from '@/components/BirthdayDatePicker/BirthdayDatePicker';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import { required, helpers, maxLength, alphaNum, numeric, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { $institutions, $nationalities, $preRegister, $studentAccount, $types } from '../Services';
import moment from 'moment';

export default {
  name: 'PreregistrationInfo',
  components: {
    OutlinedCard,
    BirthdayDatePicker,
    AutocompleteInput,
    IdentificationInput,
    TelephoneInput
  },
  props: {
    careers: { type: Array, default: () => [] },
    career: { type: Object, default: null },
    studyPlan: { type: Object, default: null },
    city: { type: Object, default: null },
    telephone: { type: String, default: null },
    email: { type: String, default: null },
    name: { type: String, default: null },
    studentDataUpdate: { type: Object, default: null },
    saleTerms: { type: Array, default: () => [] }
  },
  data() {
    return {
      preregStudentData: {
        selectedSaleTerm: null,
        name: null,
        lastname: null,
        birthDate: null,
        birthPlace: null,
        selectedNationality: null,
        selectedIdentificationType: null,
        identification: null,
        serialNumber: null,
        selectedGender: null,
        selectedMaritalStatus: null,
        email: null,
        telephone: '',
        selectedCareer: null,
        selectedStudyPlan: null,
        address: null,
        number: null,
        floor: null,
        apartment: null,
        zipCode: null,
        city: null,
        selectedStudentLevel: null,
        selectedInstitution: null,
      },
      nowDate: moment().format('YYYY-MM-DD'),
      birthDateMenu: false,
      nationalities: [],
      loadingNationalities: false,
      identificationTypes: [],
      identificationTypeIdRUT: 17,
      identificationTypeIdDNI: 183,
      loadingIdentificationTypes: false,
      originalIdentification: null,
      uniqueIdentification: true,
      hasDebt: false,
      loadingIdentification: false,
      validRUT: true,
      genders: [],
      loadingGenders: false,
      maritalStatus: [],
      loadingMaritalStatus: false,
      telephoneValid: true,
      studentLevels: [],
      loadingStudentLevels: false,
      institutions: [],
      institutionCity: null,
      loadingInstitution: false,
      baseValidation: {
        selectedSaleTerm: { required },
        name: {
          required,
          maxLength: maxLength(60),
          alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
        },
        lastname: {
          required,
          maxLength: maxLength(60),
          alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
        },
        birthDate: { required },
        birthPlace: { required },
        selectedNationality: { required },
        selectedIdentificationType: { required },
        identification: {
          required,
          unique() { return this.validRUT && this.uniqueIdentification }
        },
        selectedGender: { required },
        selectedMaritalStatus: { required },
        email: {
          required,
          email
        },
        telephone: {
          valid() {
            return this.telephoneValid
          }
        },
        selectedCareer: { required },
        address: {
          required,
          maxLength: maxLength(60),
          alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]*(?:[a-z0-9]+\s[a-z0-9]*)*$/i)
        },
        number: {
          required,
          maxLength: maxLength(10),
          numeric
        },
        floor: {
          // required,
          maxLength: maxLength(3),
          numeric
        },
        apartment: {
          // required,
          maxLength: maxLength(4),
          alphaNum
        },
        zipCode: {
          required,
          maxLength: maxLength(10),
          alphaNum
        },
        city: { required },
        selectedStudentLevel: { required },
        selectedInstitution: { required }
      }
    }
  },
  validations() {
    if (this.$isTeclab) {
      return {
        preregStudentData: this.baseValidation,
        institutionCity: { required }
      }
    } else {
      return {
        preregStudentData: {
          ...this.baseValidation,
          serialNumber: {
            required,
            maxLength: maxLength(10),
            numeric
          },
          selectedStudyPlan: { required },
        },
        institutionCity: { required }
      }
    }
  },
  computed: {
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions'
    }),
    studyPlans() {
      return this.career
        ? this.career.studyPlans.map(plan => (
          {
            text: `(${plan.studyPlan.description}) ${plan.degreesFinal.description}`,
            value: plan.studyPlan.id
          }
        ))
        : [];
    }
  },
  watch: {
    preregStudentData: {
      handler() {
        this.$emit('setStudentData', this.preregStudentData);
      },
      deep: true
    },
    saleTerms(termList) {
      const [term] = termList
      this.preregStudentData.selectedSaleTerm = term
    },
    career(selectedCareer) {
      this.preregStudentData.selectedCareer = selectedCareer
    },
    studyPlan(selectedStudyPlan) {
      this.preregStudentData.selectedStudyPlan = selectedStudyPlan
    },
    city(city) {
      this.preregStudentData.city = city
    },
    telephone(tel) {
      this.preregStudentData.telephone = tel
    },
    email(email) {
      this.preregStudentData.email = email
    },
    name(name) {
      this.preregStudentData.name = name
    },
    studentDataUpdate(data) {
      if (data) {
        this.preregStudentData.selectedSaleTerm = data.term
        this.preregStudentData.name = data.student.user.userData.name
        this.preregStudentData.lastname = data.student.user.userData.lastname
        this.preregStudentData.birthDate = data.student.user.userData.birthDate
        this.preregStudentData.birthPlace = data.student.user.userData.birthPlace
        this.preregStudentData.selectedNationality = data.student.user.userData.nationality?.id;
        this.preregStudentData.selectedIdentificationType = data.student.user.userData.identificationType.value
        this.preregStudentData.identification = data.student.user.userData.identification
        this.originalIdentification = data.student.user.userData.identification
        this.preregStudentData.serialNumber = data.student.user.userData.serialNumber
        this.preregStudentData.selectedGender = data.student.user.userData.gender.value
        this.preregStudentData.selectedMaritalStatus = data.student.user.userData.maritalStatus
        this.preregStudentData.email = data.student.user.userData.email
        this.preregStudentData.telephone = data.student.user.userData.cellphone;
        if (data.career) {
          this.preregStudentData.selectedCareer = data.career;
          const degree = data.degree ? data.degree.description : '';
          this.preregStudentData.selectedStudyPlan = { id: data.career.studyPlan.id, description: `(${data.career.studyPlan.description}) ${degree}` };
        }
        if (data.student.user.userData.address) {
          this.preregStudentData.address = data.student.user.userData.address.street.trim();
          this.preregStudentData.number = data.student.user.userData.address.number;
          this.preregStudentData.floor = data.student.user.userData.address.floor;
          this.preregStudentData.apartment = data.student.user.userData.address.apartment;
          this.preregStudentData.zipCode = data.student.user.userData.address.zipCode;
          this.preregStudentData.city = data.student.user.userData.address.cityWithState;
        }
        this.preregStudentData.selectedStudentLevel = data.student.level?.value || null;
        this.institutionCity = {
          cityState: data.student.institution?.city || '',
          idCity: data.student.institution?.cityId || null,
          idRegion: data.student.institution?.regionId || null
        }
        this.preregStudentData.selectedInstitution = data.student.institution?.id || null;

      }
    },
    async institutionCity() {
      this.loadingInstitution = true
      try {
        const params = {
          city: this.institutionCity?.idCity || null,
          region: this.institutionCity?.idRegion || null,
          orientation: 'asc',
          orderBy: 'name',
          length: 500
        }
        const {data} = await $institutions.find(null, null, { params })
        this.institutions = data.content
      } catch (err) {
        this.institutions = []
        throw err
      } finally {
        this.loadingInstitution = false
      }
    }
  },
  mounted() {
    this.getGenders()
    this.getIdentificationTypes()
    this.getMaritalStatus()
    this.getStudentLevels()
    this.getNationalities()
  },
  methods: {
    async getGenders() {
      this.loadingGenders = true
      try {
        const {data} = await $types.find(null, null, { params: { type: 'GENDER_TYPE' } })
        this.genders = data
      } catch (err) {
        this.genders = []
        throw err
      } finally {
        this.loadingGenders = false
      }
    },
    async getIdentificationTypes() {
      this.loadingIdentificationTypes = true
      try {
        const {data} = await $types.find(null, null, { params: { type: 'IDENTIFICATION_TYPE' } })
        this.identificationTypes = data
        this.preregStudentData.selectedIdentificationType = !this.$isTeclab ? this.identificationTypeIdRUT : this.identificationTypeIdDNI
      } catch (err) {
        this.identificationTypes = []
        throw err
      } finally {
        this.loadingIdentificationTypes = false
      }
    },
    async getMaritalStatus() {
      this.loadingMaritalStatus = true
      try {
        const {data} = await $types.find(null, null, { params: { type: 'MARITAL_STATUS' } })
        this.maritalStatus = data
      } catch (err) {
        this.maritalStatus = []
        throw err
      } finally {
        this.loadingMaritalStatus = false
      }
    },
    async getStudentLevels() {
      this.loadingStudentLevels = true
      try {
        const {data} = await $types.find(null, null, { params: { type: 'STUDENT_LEVEL_TYPE' } })
        this.studentLevels = data
      } catch (err) {
        this.studentLevels = []
        throw err
      } finally {
        this.loadingStudentLevels = false
      }
    },
    async getNationalities() {
      this.loadingNationalities = true
      try {
        const {data} = await $nationalities.find()
        this.nationalities = data
      } catch (err) {
        this.nationalities = []
        throw err
      } finally {
        this.loadingNationalities = false
      }
    },
    formatDateToCurrent(date) {
      return date
        ? moment(date).format( this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase() )
        : null;
    },
    async checkDebt() {
      try {
        const {data} = await $studentAccount.find('debt', null, {params: {identification: this.$isTeclab ? this.$v.preregStudentData.identification.$model : this.$v.preregStudentData.identification.$model.slice(0, -2)}})
        this.hasDebt = data?.amount >= 0
      } catch (error) {
        this.hasDebt = false
        throw error
      }
    },
    async validateIdentification() {
      this.$v.preregStudentData.identification.$touch();
      if (this.$v.preregStudentData.identification.$model) {
        if (this.originalIdentification === this.preregStudentData.identification) {
          this.uniqueIdentification = true;
          return;
        }
        this.loadingIdentification = true;
        try {
          const {data} = await $preRegister.find('exists', null, {params: {identification: this.$v.preregStudentData.identification.$model}});
          this.uniqueIdentification = !data.exists;
          if (data.exists) await this.checkDebt()
          this.loadingIdentification = false;
        } catch (error) {
          this.uniqueIdentification = false;
          throw error
        } finally {
          this.$v.preregStudentData.identification.$touch();
        }
      }
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.telephone.handleInput(this.preregStudentData.telephone, phoneObject)
    },
    async validateStudentData() {
      if (this.$v.$invalid) {
        await this.$v.$touch();
        this.$refs.telephone.handleBlur()
        this.$refs.birthPlace.triggerOuterValidation()
        this.$refs.birthDate.triggerOuterValidation()
        this.$refs.city.triggerOuterValidation()
        this.$refs.institutionCity.triggerOuterValidation()
        Object.keys(this.$v.preregStudentData).some(input => {
          if (input.includes('$')) return false;
          if (this.$v.preregStudentData[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'nearest', behavior: 'smooth'});
            return true
          }
        })
        this.$emit('validationError')
      } else {
        this.$emit('validationSuccess')
      }
    }
  }
}
</script>
