<template>
  <div>
    <v-container fluid class="container-custom fullHeight">
      <Breadcrumbs
        :title="$t('modules.exams_group.statements.programsCreate.breadcrumbs.title')">
        <a v-if="!createNewProgram" href="/templates/template-SisQ.xlsx" download> 
          <Button
            class="ml-4"
            depressed
            outlined
            icon="mdi-download"
            :text="$t('modules.exams_group.statements.subjectsTable.template')">
          </Button>
          </a>
      </Breadcrumbs>
      <v-container class="container-custom px-6" v-if="!createNewProgram">
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <OutlinedCard
              :title="$t('modules.exams_group.statements.programsCreate.programName')"
              class="mb-6"
              @switchChange="changeStatus"
              :switch-value="status"
              :switch-label="(status) ? $t('enabled') : $t('disabled')" 
              switcher>
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :label="`${$t('modules.exams_group.statements.programsCreate.insertName')}*`"
                      v-model="$v.program.description.$model"
                      outlined
                      :error-messages="($v.program.description.$dirty && $v.program.description.$invalid) ?
                        ((!$v.program.description.required) ? $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionRequired') :
                        (!$v.program.description.alphaNumWithSpaces) ? $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionAlphanumericWithSpaces') :
                        $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionMaxLength')) :
                        ''"
                      @keypress="$validateAlphanumeric($event, $v.program.description.$model,60, true)"
                      @blur="$v.program.description.$touch()"
                    ></v-text-field>
                  </v-col>
                    <v-col sm="3" class="py-2">
                      <v-text-field
                        :label="`${$t('modules.exams_group.statements.programsCreate.code')} *`"
                        outlined
                        v-model="$v.program.code.$model"
                        :error-messages="($v.program.code.$dirty && $v.program.code.$invalid) ?
                        ((!$v.program.code.required) ? $t('modules.exams_group.statements.programsCreate.validation_errors.codeRequired') :
                        (!$v.program.code.maxLength) ? $t('modules.exams_group.statements.programsCreate.validation_errors.codeMaxLength') :
                        $t('modules.exams_group.statements.programsCreate.validation_errors.codeAlphanumeric')) :
                        ''"
                        @keypress="$validateAlphanumeric($event, $v.program.code.$model,10)"
                        @blur="$v.program.code.$touch()"
                      ></v-text-field>
                  </v-col>
                </v-row>
            </OutlinedCard>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="primary ma-auto" width="90%"></v-divider>
          <v-card-text>
            <v-row justify="center" class="" >
                <v-col cols="8" class="" align="center">
                  <DropFiles
                    title="Subir archivo con módulos y temas"
                    extension=".xls,.xlsx,.xlt"
                    type="file"
                    limitSizeBytes="2000000"
                    :showTitle="true"
                    :showDashed="true"
                    @update:fileInput="setFileProgram($event)"
                    @removeFile="removeFile()"
                    v-model='file'
                  >
                  </DropFiles>
                </v-col>
            </v-row>
          </v-card-text>
        </v-row>

        <div class="d-flex justify-end">
          <Button
            class="ml-4"
            @clicked="$router.go(-1)"
            @end="resetButtonValues"
            depressed
            white
            :text="$t('modules.exams_group.statements.programsCreate.cancel')"
          >
          </Button>
          <Button
            :loading="createButton.loading"
            :success="createButton.success"
            :error="createButton.error"
            depressed
            class="ml-4"
            :text="$t('modules.exams_group.statements.programsCreate.create')"
            :successText="$t('actions.created')"
            :errorText="$t('actions.error')"
            @end="resetButtonValues"
            @clicked="createProgramPopUp"
            :disabled="!canCreate" 
          ></Button>
        </div>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import DropFiles from '../Components/DropFiles'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import Button from '@/components/Button/Button'
import { mapGetters } from 'vuex'
import { required, maxLength, helpers, alphaNum } from 'vuelidate/lib/validators';
import { $statements } from '../Services';

export default {
  name: 'ProgramsCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    DropFiles,
    Button,
    SuccessMessage
  },
  props: {
    switcher: Boolean,
    switchValue: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isActive: true,
      createNewProgram: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        subtitle: null,
        actionPrimary: null,
        actionSecondary: null
      },
      program: {
          materia: { id: '' },
          description: null,
          code: null,  
      },
      status: true,
      userId: '',
      file: null,
    }
  },
  computed: {
    ...mapGetters(['commons/getUserData', 'commons/getUserID']),

      canCreate () {
          return this.$permissions.portaladministrativo.exams.statements.CREATE
      },
  },
  methods: {
    async postStatement(formData){
      this.createButton.loading = true;
      try {
        await $statements.formData(formData, `program`);
        this.createdSuccesfully();
      } catch (error) {
        this.showError(error);
        throw error;
      } finally {
        this.createButton.loading = false;
        this.createButton.error = false;
      }
    },
    async createProgram () {
      if( this.$v.$invalid ) {
        return this.$v.$touch()
      }
      const {description,code} = this.program
      const userId = this['commons/getUserID'];
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('program', JSON.stringify({
        materia: { id: this.$route.params.subject_id },
        description,
        code,
        status: this.status}));
      formData.append('userId', userId)
        this.postStatement(formData)
    },
    createdSuccesfully() {
      this.createButton.success = true;
      this.successMessage.type = 'success';
      this.successMessage.title = this.$t(
        'modules.exams_group.statements.programsCreate.success_message.title'
      );
      this.successMessage.actionPrimary = {
        text: this.$t('modules.exams_group.statements.programsCreate.finish'),
        callback: () => {
          this.$router.go(-1)
        }
      };
      this.successMessage.actionSecondary = {
        text: this.$t('modules.exams_group.statements.programsCreate.success_message.actions.secondary_text'),
        callback: () => {
          this.createNewProgram = false;
          this.resetForm()
        }
      };      
      this.successMessage.subtitle = null;
      this.createNewProgram = true;
    },
    showError(error) {
      this.createButton.loading = false;
      this.createButton.error = true;
      this.createNewProgram = true;
      this.successMessage.type = 'error';
      this.successMessage.title = this.$t('modules.users.table.massImportDrawer.messages.error');
      this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createNewProgram = false; }};
      this.successMessage.actionSecondary = null;
      this.setFileProgram(null);
      switch(error.status) {
        case 219:
          this.successMessage.subtitle = this.$t(`responseCodeMeaning.${error.code}`, { hoja: error.errorDetail?.sheet, columna: error.errorDetail?.column, fila: error.errorDetail?.row });
          break;
        case 220:
          this.successMessage.subtitle = this.$t(`responseCodeMeaning.${error.code}`, { module: error.errorDetail?.module });
          break;
        case 222:
          this.successMessage.subtitle = this.$t(`responseCodeMeaning.${error.code}`, { hoja: error.errorDetail?.sheet, columna: error.errorDetail?.column, fila: error.errorDetail?.row });
          break;
        default:
          this.successMessage.subtitle = error?.code ? this.$t(`responseCodeMeaning.${error.code}`) : null;
          break;
      }
    },

    resetForm(){
      this.program.materia.id = ''
      this.program.description = null
      this.program.code = null
      this.$v.$reset()
    },
    setFileProgram(file) {
      this.file = file;
    },
    removeFile(i) {
      this.filelist.splice(i, 1);
    },
   clearData(){
       this.program.materia.id = ''
       this.program.description = null
       this.program.code = null
       this.$v.$reset()
       this.file = null
    },
    changeStatus() {
      this.status = !this.status;
    },
    createProgramPopUp () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('errorMessage') }],
          actionPrimary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      }  
      else if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createProgram() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createProgram();
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    program: {
        description: {
          required,
          maxLength: maxLength(60),
          alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]*(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
        },
        code: {
          required,
          maxLength: maxLength(10),
          alphaNum,
        },
      },
  },
};
</script>

<style lang="sass" scoped></style>
