<template>
  <v-card class="pa-6 mb-3">
    <template v-if="title">
      <v-card-title class="ma-0 px-0 pt-0 pb-2 justify-center font-weight-medium card-title">{{ title }}</v-card-title>
      <v-divider class="divider-color"></v-divider>
    </template>
    <div class="text-center pa-6" v-if="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <template v-if="exams.length">
        <div v-for="(item, index) in exams" :key="index">
          <v-list-item two-line>
            <v-list-item-content class="pb-2 pt-6">
              <v-list-item-title class="d-flex justify-space-between">
                <div class="text-body-1 font-weight-medium text-wrap pb-3 exam-title">{{ item.materia }}</div>
                <v-btn v-if="canDelete" class="text-right ml-10 exam-button" outlined fab @click="$emit('deletePopup', item.pedidoExamenId)">
                  <v-icon class="exam-button-icon">mdi-close</v-icon>
                </v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="py-1">{{ item.anioCursado }}</div>
                <div class="py-1">{{ item.estado }}</div>
                <div class="py-1">{{ item.detalleHorarioExamen }}</div>
                <template v-if="item.fechaLimitePagoPendiente && isTeclab">
                  <div class="py-1 pending-payment"><strong>{{ $t('modules.portalalumno.exams.dateStep.requestList.pendingPayment.title') }}</strong></div>
                  <div class="pending-payment">{{ $t('modules.portalalumno.exams.dateStep.requestList.pendingPayment.subtitle', { pendingPaymentDate: item.fechaLimitePagoPendiente }) }}</div>
                </template>
              </v-list-item-subtitle>
              <v-list-item-content class="pb-0">
                <v-alert 
                    v-if="item.procteringType"
                    rounded="lg"
                    text
                    color="info"
                    class="py-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="pl-1 pb-1">
                          <img v-if="item.procteringType.value ==  272" :src="require('@/assets/images/portalalumno/klarway.png')" height="25px"/>
                          <img v-else :src="require('@/assets/images/portalalumno/sumadi.png')"  height="35px"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pt-0 px-0">
                          <p class="grey--text section-content mb-0">{{ $t('modules.portalalumno.exams.notification_proctoring.text_1') }}{{ item.procteringType.meaning }}.<br>{{ $t('modules.portalalumno.exams.notification_proctoring.text_2') }}<br>
                            <span v-if="item.procteringType.value == 272">
                              <strong>{{ $t('modules.portalalumno.exams.notification_proctoring.text_3') }}</strong> {{ $t('modules.portalalumno.exams.notification_proctoring.text_4') }}<br>
                            </span>
                            {{ $t('modules.portalalumno.exams.notification_proctoring.text_5') }}
                            <a  @click="$router.push('/apps')" style="text-decoration: underline">{{ $t('modules.portalalumno.exams.notification_proctoring.text_6') }}</a>
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>
                </v-alert>
              </v-list-item-content>
              <v-list-item-content class="pt-0">
                <v-divider></v-divider>
                <div class="text-caption text-right pt-2 attempts-color">{{ $t('modules.portalalumno.exams.dateStep.requestList.attempts.text_1') }} {{ item.cantIntentos }} {{ $t('modules.portalalumno.exams.dateStep.requestList.attempts.text_2') }}</div>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="divider-color"></v-divider>
        </div>
      </template>
      <div class="justify-center align-center" v-else>
        <div class="text-center py-2"><v-icon>mdi-desktop-mac</v-icon></div>
        <div class="text-center text-caption caption-color mt-0">
          {{ $t('modules.portalalumno.exams.dateStep.requestList.inscriptionList.emptyListMessage') }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'ExamsRequestList',
    props: {
      title: String,
      exams: Array,
      loading: Boolean
    },
    computed: {
      canDelete() {
        return this.$permissions.portalalumno.exams.DELETE
      },
    },
  };
</script>

<style lang="sass" scoped>

.divider-color
  border-color: #B3BBB5

.exam-title
  color: var(--v-primary-base)
  line-height: 1
  letter-spacing: -0.5px

.exam-button
  border-width: 2px
  border-color: #777
  height: 1.25rem
  width: 1.25rem

.exam-button-icon
  font-size: small !important

.attempts-color
  color: #61BCCC

.card-title
  color: var(--v-primary-base)
  line-height: 1
  letter-spacing: -0.5px

.caption-color
  color: #777

.pending-payment
  color: #a81912
  white-space: pre-wrap

</style>
