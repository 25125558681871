<template>
  <div class="deny-overflow">
    <v-container class="pa-0 pb-0" fluid>
      <v-row justify="center">
        <v-card class="pa-10">
          <v-col class="ml-9" sm="10">
            <v-card-title class="headline pl-0 mb-3">{{ $t('modules.sistemaQ.create.modal.title') }}</v-card-title>
            <v-card-text class="pl-0 mb-3">{{ $t('modules.sistemaQ.create.modal.subtitle') }}</v-card-text>
            <v-select
              :items="items"
              :placeholder="$t('modules.sistemaQ.create.modal.justify.placeholder')"
              class="mb-3"
              dense
              item-value="value"
              outlined
              v-model="selectedJust"
            ></v-select>

            <div class="d-flex justify-end">
              <Button
                :text="$t('modules.sistemaQ.create.button.cancel')"
                @clicked="onCancelClicked"
                class="mr-4"
                white>
              </Button>
              <Button
                :disabled="!selectedJust"
                :error="createButton.error"
                :errorText="createButton.errorText"
                :loading="createButton.loading"
                :success="createButton.success"
                :successText="$t('actions.created')"
                :text="$t('modules.sistemaQ.create.button.accept')"
                @clicked="onAcceptClick"
                @end="resetButtonValues()"
              ></Button>
            </div>
          </v-col>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="sass" scoped>
  .containerPadding
    padding-left: 34px
    padding-right: 34px

    .deny-overflow
      overflow-x: hidden
</style>

<script>
  import {$types, $exams} from '../services';
  import Button from '@/components/Button/Button';

  export default {
    name: 'SistemaQExamDetailModal',
    components: {Button},
    async mounted() {
      const fetchedJustifications = await $types.find(null, null, {params: {type: 'EXAM_REQUEST_PERIOD_JUST'}});
      this.items = fetchedJustifications.data.map(just => ({text: just.meaning, value: just.value}));
      this.splittedIdParams = this.$router.history.current.params.id.split('-')
    },
    data() {
      return {
        selectedJust: null,
        splittedIdParams: [],
        createButton: {
          loading: false,
          success: false,
          error: false,
          errorText: 'Error'
        },
        items: [],
      };
    },
    methods: {
      onCancelClicked() {
        this.selectedJust = null;
        this.resetButtonValues();
        this.$emit('cancelClick')
      },
      async onAcceptClick() {
        try {
          this.createButton.loading = true;
          for (const index in this.splittedIdParams) {
            await $exams.create({}, {}, `periods/disable/${this.splittedIdParams[index]}?justificationId=${this.selectedJust}`)
          }
          const successMessage = {
            type: 'success',
            title: this.$t('modules.sistemaQ.create.successAnulation_popup.title'),
          };
          this.createButton.success = true;
          this.$emit('successMessage', successMessage)
        } catch (err) {
          const successMessage = {
            type: 'error',
            title: (err.codeMeaning == '') ? this.$t('modules.sistemaQ.create.error_message.anulation_title') : err.codeMeaning,
          };
          this.createButton.error = true;
          this.$emit('successMessage', successMessage)
        } finally {
          this.createButton.loading = false;
          this.selectedJust = null
        }
      },
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false
      },
      removeGroup(index) {
        this.groups.splice(index, 1)
      },
      addGroup() {
        const mockup = {time: null};
        this.groups.push({...mockup});
      },
    }
  };
</script>
