<template>
  <div>
    <v-row class="mt-5" v-if="title">
      <v-col cols="12">
        <h6 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingTitle') }}</h6>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-select
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.taxSituation') + ' *'"
          :items="taxSituationTypes"
          v-model="$v.data.taxSituation.$model"
          outlined
          item-text="meaning"
          :error-messages="$v.data.taxSituation.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.data.taxSituation.$touch()"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          :label="(isCuit ? $t('modules.portalalumno.payments.paymentsStudents.view.labels.businessName') : $t('modules.portalalumno.payments.paymentsStudents.view.labels.name')) + ' *'"
          v-model="$v.data.name.$model"
          outlined
          :error-messages="$v.data.name.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.data.name.$touch()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <IdentificationInput
          v-model="$v.data.id.$model"
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.idoncard', {identificationType: isCuit ? 'CUIT' : $countryConstants.identificationType}) + ' *'"
          :id-type="isCuit ? 'CUIT' : 'DNI'"
          :error-messages="$v.data.id.$error
            ? (!$v.data.id.required
              ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required')
              : $t('modules.portalalumno.payments.paymentsStudents.inputErrors.length'))
            : ''"
          @blur="$v.data.id.$touch()"
          @validateRut="validateRut"
        ></IdentificationInput>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          :label="`${$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetaddress')} *`"
          v-model="$v.data.street.$model"
          outlined
          :error-messages="$v.data.street.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.data.street.$touch()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
          type="number"
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetnumber') + ' *'"
          v-model.number="$v.data.number.$model"
          @keypress="$validateIntegerInputNumber($event, $v.data.number.$model, null, 7)"
          outlined
          :error-messages="$v.data.number.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.data.number.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-autocomplete
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.state') + ' *'"
          :items="states"
          v-model="$v.state.$model"
          outlined
          item-text="descripcion"
          :error-messages="$v.state.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.state.$touch()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.city') + ' *'"
          :items="cities"
          :loading="loadingCities"
          v-model="$v.data.city.$model"
          outlined
          item-text="descripcion"
          :error-messages="$v.data.city.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
          @blur="$v.data.city.$touch()"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" sm="2">
        <v-text-field
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetfloor')"
          v-model="data.floor"
          @keypress="$validateIntegerInputNumber($event, data.floor, null, 3)"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2">
        <v-text-field
          :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetapartment')"
          v-model="data.apartment"
          @keypress="$validateLengthInput($event, 6)"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
import { required } from 'vuelidate/lib/validators'
import { $billingData, $types, $provincias, $localidades } from '../../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentBillingDataTeclab',
  props: {
    title: {type: Boolean, default: true},
    selectedStudent: {type: Number, default : null}
  },
  components: {
    IdentificationInput,
  },
  data() {
    return {
      taxSituationTypes: [],
      states: [],
      cities: [],
      loadingCities: false,
      state: null,
      data: {
        taxSituation: null,
        validRut: false,
        name: null,
        id: null,
        city: null,
        street: null,
        number: null,
        floor: null,
        apartment: null,
      },
      withCuit: [359]
    }
  },
  computed: {
    isCuit() {
      return this.withCuit.includes(this.data.taxSituation)
    },
    studentIdSelected(){
      return this.selectedStudent != null ?  this.selectedStudent : this.studentId
    },
    ...mapGetters({
      studentId: 'commons/getStudentId',
      studentData: 'commons/getStudentUserData'
    })
  },
  async mounted() {
    this.getStates()
    await this.getTaxSituationTypes()
    this.fetchData()
  },
  methods: {
    async getTaxSituationTypes() {
      try {
        const {data} = await $types.find(null, null, { params: { type: 'TAX_TREATMENT_TYPE'} });
        this.taxSituationTypes = data
      } catch (error) {
        this.taxSituationTypes = [];
        throw error;
      }
    },
    async getStates() {
      try {
        const {data} = await $provincias.find()
        this.states = data
      } catch (err) {
        this.states = []
        throw err
      }
    },
    async fetchData() {
      try {
        const { data } = await $billingData.find( null,  null , {params:  {idStudent : this.studentIdSelected} } )

        this.data.taxSituation = data.taxTreatment?.value
        this.data.validRut = true
        this.data.name = data.name
        this.data.id = data.docNumber
        this.data.user_id = data.id
        this.data.street = data.address
        this.data.number = data.addressNumber
        this.data.floor = data.addressFloor
        this.data.apartment = data.addressDepartment
        this.data.state = data.stateFacturante
        this.data.taxSituationType = data.taxSituationType?.value
        this.state = data.stateFacturante
        this.data.receiptType = data.receiptType?.value
        this.data.taxTreatment = data.taxTreatment?.value
        this.data.city_id = data.city
        this.data.city = data.cityFacturante
        this.data.billingMail = data.billingMail
        this.data.phone = data.phone
        this.$emit('validate', !this.$v.$invalid && this.data.validRut)
      } catch (error) {

        this.data.taxSituation = null
        this.data.validRut = false
        this.data.name = null
        this.data.id = null
        this.data.street = null
        this.data.number = null
        this.data.floor = null
        this.data.apartment = null
        this.state = null
        this.data.city = null
        throw error
      }
    },
    async save() {
      try {
        await $billingData.create({
          student: {id: this.studentId},
          taxTreatment: {value: this.data.taxSituation},
          identificationType: this.isCuit ? {value: 18} : {value: 17},
          docNumber: this.data.id,
          name: this.data.name,
          address: this.data.street,
          addressNumber: this.data.number,
          addressFloor: this.data.floor,
          addressDepartment: this.data.apartment,
          stateFacturante: this.state,
          cityFacturante: this.data.city,
          contactName : this.data.name,
          contactMail: this.studentData.email,
          billingMail: this.studentData.email
        });
        return true
      } catch {
        return false
      }
    },
    validateRut(status) {
      this.data.validRut = status
      if (this.$v.$anyDirty) this.$emit('validate', !this.$v.$invalid && status)
    },
  },
  watch:{
    data: {
      async handler(value) {
        this.$emit('update', value)
      },
      deep: true
    },
    async state() {
      this.data.stateFacturante = this.state
      this.loadingCities = true
      try {
        const {data} = await $localidades.find(null, null, {params: {prov: this.state}})
        this.cities = data
      } catch {
        this.cities = []
      } finally {
        this.loadingCities = false
      }
    },
    $v: {
      handler(value) { if (value.$anyDirty) this.$emit('validate', !value.$invalid && this.data.validRut) },
      deep: true
    },
     async studentIdSelected() {
         this.getStates()
        await this.getTaxSituationTypes()
        this.fetchData()
      },
  },
  validations: {
    state: {required},
    data: {
      taxSituation: {required},
      name: {required},
      id: {
        required,
        min(value) {
          return this.isCuit ? value?.length > 12 : true;
        }
      },
      city: {required},
      street: {required},
      number: {required},
    }
  },
}
</script>
