<template>
  <div>
    <v-container class="pa-0 pb-8" fluid>
      <v-container class="container-custom px-6">
        <v-row justify="center">
          <v-col sm="12">
            <Breadcrumbs :title="$t('modules.portalalumno.profile.breadcrumbs.title')">
             <Button
                :text="$t('modules.portalalumno.profile.changePassword.label')"
                outlined
                v-show="show"
                @clicked="openModal"
                class="mr-2"
              ></Button>
              <Button
                :text="$t('modules.portalalumno.profile.edit.label')"
                color="primary"
                @clicked="enableEdit"
                v-show="show"
              ></Button>
            </Breadcrumbs>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-6">
          <v-col class="viewSpaces" sm="12">
            <OutlinedCard class="profile-header" containerClass="mt-0">
              <v-row>
                <v-col cols="12" md="2" class="d-flex align-center justify-center">
                  <AvatarUpload
                    :rounded="true"
                    :image="imagePreview"
                    @save="(file) => createImage(file)"
                  ></AvatarUpload>
                </v-col>
                <v-col cols="12" md="8" class="d-flex flex-column justify-center">
                 <span >{{userData.nombre}} {{userData.apellido}}</span>
                  <span class="body-1 font-weight-bold">{{userData.carrera}}</span> 
          </v-col>
        </v-row>
            </OutlinedCard>
            <OutlinedCard class="mt-6" containerClass="mt-0">
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-icon class="mr-2" color="light-blue darken-2">mdi-clipboard-account</v-icon>
                  <h2 class="my-0 body-1 font-weight-bold">{{ $t('modules.portalalumno.profile.sectionData.title') }}</h2>
                </v-col>
                <v-col cols="12" md="3" class="pb-0">
                  <v-select
                    disabled
                    v-model="userData.identificationType"
                    :items="listIdentifications"
                    :label="$t('modules.portalalumno.profile.sectionData.identificationType')"
                    return-object
                    outlined
                    item-text="meaning"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" class="pb-0">
                  <IdentificationInput    
                    v-model="$v.userData.dni.$model"      
                    :label="$t('modules.portalalumno.profile.sectionData.identification')"
                    @blur="$v.userData.dni.$touch()"
                    @validateRut="validateRut"
                    disabled
                    :idType="idType"
                    outlined
                    required
                  />
                </v-col>
                <v-col cols="12" md="3" class="pb-0">
                  <v-menu
                      ref="menu"
                      :disabled="!disabled"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      bottom
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on }">
                          <v-text-field
                              :disabled="!disabled"
                              :value="userData.fecha"
                              :label="$t('modules.portalalumno.profile.sectionData.birthDate')"
                              append-icon="mdi-calendar"
                              readonly
                              outlined
                              v-on="on"
                              hide-details
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="userData.fecha"
                          color="primary"
                          scrollable
                          no-title
                          :max="new Date().toISOString().substr(0, 10)"
                          @input="menu = false"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" class="pb-0">
                  <v-select
                    :disabled="!disabled"
                    v-model="userData.sexo"
                    :items="listGender"
                    :label="$t('modules.portalalumno.profile.sectionData.gender')"
                    outlined
                    item-text="meaning"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!disabled"
                    v-model="$v.userData.$model.comentario"
                    :label="$t('modules.portalalumno.profile.sectionData.about')"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </OutlinedCard>
            <OutlinedCard class="mt-6" containerClass="mt-0">
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-icon class="mr-2" color="light-blue darken-2">mdi-map-marker</v-icon>
                  <h2 class="my-0 body-1 font-weight-bold">{{ $t('modules.portalalumno.profile.sectionContact.address') }}</h2>
                </v-col>

                <v-col  cols="12" sm="6" class="pb-0">
                  <v-text-field
                    v-model="userData.domicilioDTO.calle"
                    :label="$t('modules.portalalumno.profile.sectionContact.street')"
                    outlined
                    type="text"
                    :disabled="!disabled">
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="2" class="pb-0">
                  <v-text-field
                    v-model="userData.domicilioDTO.numero"
                    :label="$t('modules.portalalumno.profile.sectionContact.number')"
                    @keypress="$validateIntegerInputNumber($event, userData.numero, null, 6)"
                    outlined
                    type="text"
                    :disabled="!disabled">
                  </v-text-field>
                </v-col>
                <v-col cols="6" sm="2" class="pb-0">
                  <v-text-field
                    v-model="$v.userData.domicilioDTO.piso.$model"
                    :label="$t('modules.portalalumno.profile.sectionContact.floor')"
                    :errorMessages="($v.userData.domicilioDTO.piso.$dirty && $v.userData.domicilioDTO.piso.$invalid) ?  $t('modules.portalalumno.profile.sectionContact.validations.floorValidation') : ''"
                    @keypress="$validateIntegerInputNumber($event, $v.userData.domicilioDTO.piso.$model, 200)"
                    outlined
                    :disabled="!disabled">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" class="pb-0">
                  <v-text-field
                    v-model="$v.userData.domicilioDTO.depto.$model"
                    :label="$t('modules.portalalumno.profile.sectionContact.apartment')"
                    :errorMessages="($v.userData.domicilioDTO.depto.$dirty && $v.userData.domicilioDTO.depto.$invalid) ?  $t('modules.portalalumno.profile.sectionContact.validations.deptoLenght') : ''"
                    @keypress="$validateLengthInput($event, 10)"
                    outlined
                    type="text"
                    :disabled="!disabled">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="pb-0">
                  <AutocompleteInput
                    ref="domicilio"
                    :value.sync="userData.domicilioDTO.cityWithState"
                    :disabled="!disabled"
                    disableButtons
                    :error="$v.userData.domicilioDTO.cityWithState.idCity.$invalid && $v.userData.domicilioDTO.cityWithState.idCity.dirty"
                    :errorMessages="$t('modules.portalalumno.profile.sectionContact.validations.city')"
                    :label="$t('modules.portalalumno.profile.sectionContact.cityWithState')"
                    :required="$v.userData.domicilioDTO.cityWithState.idCity.$invalid"
                    @save="(val) => {
                        this.$v.userData.domicilioDTO.cityWithState.$model = val;
                        this.$v.userData.domicilioDTO.cityWithState.idCity.$touch();}">
                  </AutocompleteInput>
                </v-col>
                <v-col cols="12" sm="4" class="pb-0">
                  <TelephoneInput
                    :disabled="!disabled"
                    v-model="userData.domicilioDTO.telefonoDos"
                    :value.sync="userData.domicilioDTO.telefonoDos"
                    :placeholder="$t('modules.portalalumno.profile.sectionContact.phonePlaceholder')"
                  ></TelephoneInput>
                </v-col>
                <v-col sm="4" class="pb-0">
                  <TelephoneInput
                    ref= "phoneOne"                      
                    :disabled="!disabled"
                    v-model="userData.domicilioDTO.telefonoUno"
                    :error="$v.userData.domicilioDTO.telefonoUno.$invalid && $v.userData.domicilioDTO.telefonoUno.dirty"
                    :value.sync="userData.domicilioDTO.telefonoUno"
                    :placeholder="$t('modules.portalalumno.profile.sectionContact.cellphonePlaceholder') + ' *'"
                    required
                  ></TelephoneInput>
                </v-col>
              </v-row>
            </OutlinedCard>
            <OutlinedCard class="mt-6" containerClass="mt-0">
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-icon class="mr-2" color="light-blue darken-2">mdi-account-group</v-icon>
                  <h2 class="my-0 body-1 font-weight-bold">{{ $t('modules.portalalumno.profile.sectionData.socialMedia') }}</h2>
                </v-col>
                <v-col cols="12" lg="6" class="pb-0">
                  <v-text-field
                    :disabled="!disabled"
                    v-model="userData.facebook"
                    label="Facebook"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pb-0">
                  <v-text-field
                    :disabled="!disabled"
                    v-model="userData.linkedin"
                    label="Linkedin"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pb-0">
                  <v-text-field
                    :disabled="!disabled"
                    v-model="userData.twitter"
                    label="Twitter"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pb-0">
                  <v-text-field
                    :disabled="!disabled"
                    v-model="userData.instagram"
                    label="Instagram"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </OutlinedCard>
            <div class="d-flex justify-end mb-4">
              <Button
                white
                :text="$t('actions.cancel')"
                @clicked="disableEdit"
                @end="resetButtonValues"
                class="mr-4"
                v-show="!show"
              ></Button>
              <Button
                :loading="saveButton.loading"
                :success="saveButton.success"
                :error="saveButton.error"
                :text="$t('actions.save')"
                :successText="$t('actions.saved')"
                :errorText="$t('actions.error')"
                @clicked="validateEdit"
                @end="resetButtonValues"
                color="primary"
                v-show="!show"
              ></Button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import AvatarUpload from '@/components/AvatarUpload/AvatarUpload';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import { required, numeric, maxValue, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { $alumnos, $types } from '../Services';
import Constants from '@/plugins/constants'
import { mapGetters } from 'vuex'

export default {
  components: {
    Button,
    Breadcrumbs,
    OutlinedCard,
    AvatarUpload,
    AutocompleteInput,
    TelephoneInput,
    IdentificationInput,
  },

  data() {
    return {
      activeModal: false,
      passwordErrorOld: false,
      securityProgessBar: {
      color: 'error',
      label: this.$t('modules.profile.changePassword.securityProgressBar.label'),
      category: this.$t('modules.profile.changePassword.securityProgressBar.category.down')
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
      showConfirmation: false,
      showNew: false,
      showOld: false,
      passwordOld: [],
      passwordNew: null,
      passwordConfirmation: null,
      dialog:false,
      show: false,
      menu: false,
      disabled: false,
      updateStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false,
      },
      foto: '',
      imagePreview: '',
      userData: {
        id: '',
        nombre: '',
        apellido: '',
        carrera: '',
        dni: '',
        identificationType:'',
        fecha: null,
        sexo: '',
        domicilioDTO: {
          calle: '',
          numero: '',
          piso: '',
          depto: '',
          telefonoDos: '',
          telefonoUno: '',
          cityWithState: { idCity: null, cityState: null, idRegion: null },
          localidadId: '',
          localidad:'',
          provinciaId: '',
          provincia: '',
        },
        ingresoEconomico: 0,
        notebook: 0,
        smartphone: 0,
        internetCasa: 0,
        email: '',
        comentario: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
        whatsapp: '',
        publico: 0,
        editado: 0,
        tituloDTO: null,
      },
      listGender: [],
      listIdentifications: [],
      validRut: true,
      idType: null,
    };
  },
  
  mounted () {
    this.getDataTypes();
    this.getProfile();
    this.setDefaultIdType();
  },

  computed: {
      ...mapGetters({
      studentCareer: 'commons/getStudentCareer',
      studentRegister: 'commons/getStudentActualRegister',
      studentUserId: 'commons/getStudentUserID',
      getStudentUser: 'commons/getStudentUser',
      userId: 'commons/getUserID',
    }),
  },
  methods: {
        openModal(){
          this.$store.dispatch('commons/openPasswordPopUp')
        },
        closeModal(){
          this.$store.dispatch('commons/closePasswordPopUp')
        },
        createImage(file) {
          try {
            this.foto = file;
            const reader = new FileReader();

        reader.onload = (e) => {
          this.setImagePreview(e.target.result);
        };
        reader.onerror = () => {
          this.setImagePreview(this.foto);
          reader.abort();
        };
        reader.readAsDataURL(this.foto);
      } catch (err) {
        this.setImagePreview(this.foto);
      }
    },
    setImagePreview(image) {
      this.imagePreview = image;
    },
    resetButtonValues() {
      this.dialog = false
      this.passwordErrorOld = false
      this.$v.passwordOld.$reset()
      this.passwordOld = [];
      this.$v.passwordNew.$reset()
      this.passwordNew = null;
      this.$v.passwordConfirmation.$reset()
      this.passwordConfirmation = null;
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    async getProfile(){
      const res = await $alumnos.find('perfil', null, {params: {leg:this.studentRegister}})
        this.userData.id = res.data.id;
        this.userData.nombre = res.data.nombre;
        this.userData.apellido = res.data.apellido;
        this.userData.fullName = res.data.fullName;
        this.userData.carrera = res.data.carrera;
        this.userData.sexo = res.data.sexType.value;
        this.foto = `${Constants.URL_DATA_SERVER}/users/${res.data.userId}/avatar`;
        this.imagePreview = `${Constants.URL_DATA_SERVER}/users/${res.data.userId}/avatar`;
        this.userData.fecha = res.data.fecha;
        this.userData.dni = res.data.identification;
        this.userData.identificationType = res.data.identificationType;
        this.userData.domicilioDTO.calle = res.data.domicilioDTO.calle;
        this.userData.domicilioDTO.depto = res.data.domicilioDTO.depto;
        this.userData.domicilioDTO.piso = res.data.domicilioDTO.piso;
        this.userData.domicilioDTO.numero = res.data.domicilioDTO.numero;
        this.userData.domicilioDTO.telefonoDos = res.data.domicilioDTO.telefonoDos;
        this.userData.domicilioDTO.telefonoUno = res.data.domicilioDTO.telefonoUno;
        this.userData.domicilioDTO.cityWithState = res.data.domicilioDTO.cityWithState;
        this.userData.email = res.data.email,
        this.userData.comentario = res.data.comentario;
        this.userData.facebook = res.data.facebook;
        this.userData.twitter = res.data.twitter;
        this.userData.instagram = res.data.instagram;
        this.userData.linkedin = res.data.linkedin;
        this.show = true;
    },
    async validateEdit(){
      if(this.$v.userData.domicilioDTO.cityWithState.$invalid){
        this.$refs.domicilio.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
        this.$refs.domicilio.handleBlur();
      }else if(this.$v.userData.domicilioDTO.telefonoUno.$invalid){
        this.$refs.phoneOne.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
        this.$refs.phoneOne.handleBlur();
      }else{
        this.updateProfile()
      }
    },
    async updateProfile() {
      try {
        if(this.$v.userData.$invalid) return this.$v.$touch()
        this.saveButton.loading = true
        const body = new FormData();
        body.append('avatar', this.foto);
        this.userData.userId = this.userId;
        body.append('userData', JSON.stringify(this.userData));
        await $alumnos.formData(body, 'perfil')
        this.saveButton.success = true;
        this.disableEdit();
      } catch (error) {
        this.saveButton.error = true;
        throw error;
      } finally {
        this.saveButton.loading = false;
      }
    },
    async getDataTypes() {
      try {
        const genders = await $types.find(null, null, { params: { type: 'GENDER_TYPE' } });
        this.listGender = genders.data;

        const identifications = await $types.find( null, null, { params: { type: 'IDENTIFICATION_TYPE' } });
        this.listIdentifications = identifications.data;
      } catch (error)       {
        this.listGender = []
        this.listIdentifications = []
        throw error
      }
    },
    enableEdit () {
      this.disabled = !this.disabled;
      this.show = false
    },
    disableEdit () {
      this.disabled = !this.disabled;
      this.show = true
    },
    validateRut(status) {
      this.validRut = status
      if (this.$v.$anyDirty) this.$emit('validate', !this.$v.$invalid && status)
    },
       setDefaultIdType() {
         if (this.$isTeclab) this.idType = 'DNI'
      else this.idType = 'RUT'
      },
    },
      validations: {
        userData: {
          dni: { required, valid() { return this.validRut } },
          domicilioDTO : {
            cityWithState: {
              idCity: { required: requiredIf(function (cityWithState) {return cityWithState?.idRegion == null})},
              idRegion: { required: requiredIf(function (cityWithState) {return cityWithState?.idCity == null}) },
            },
            piso: {
              numeric,
              maxValue: maxValue(200),
            },
            depto: {
              maxLength: maxLength(5),
            },
            telefonoUno:{
              required
            },
          },
        },
      }
  }
</script>
