<template>
  <v-dialog
    v-model="active"
    max-width="600px"
    :persistent="$store.state.commons.hasExpired"
    @click:outside="resetButtonValues"
  >
    <OutlinedCard  v-if="!statusOk"
      :title="$t('components.changePasswordAlert.changePassword.label')"
      :subtitle="$store.state.commons.changePasswordPopUp ? $t('components.changePasswordAlert.changePassword.description2') : $t('components.changePasswordAlert.changePassword.description')"
      class="border-change-password"
    >
      <br>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :error-messages="($v.passwordOld.$dirty && $v.passwordOld.$invalid) ?
                (!$v.passwordOld.required ? $t('components.changePasswordAlert.sectionData.validations.passwordOldRequired') :
                $t('components.changePasswordAlert.sectionData.validations.passwordOldInconrrect')) :
                ''"
            :label="$t('components.changePasswordAlert.create.passswordOld')"
            outlined
            @blur="$v.passwordOld.$touch()"
            @change="incorrectPasswordOld = true"
            v-model="$v.passwordOld.$model"
            :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showOld = !showOld"
            :type="showOld ? 'text' : 'password'"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="($v.passwordNew.$dirty && $v.passwordNew.$invalid) ?
                (!$v.passwordNew.maxLength) ? $t('components.changePasswordAlert.sectionData.validations.passwordMax') :
                ((!$v.passwordNew.required) ? $t('components.changePasswordAlert.sectionData.validations.passwordNewRequired') :
                (!$v.passwordNew.minLength) ?  $t('components.changePasswordAlert.sectionData.validations.passwordMin') :
                (!$v.passwordNew.mediumLevel) ?  $t('components.changePasswordAlert.sectionData.validations.minRequired') :
                (!$v.passwordNew.strongLevel) ?  $t('components.changePasswordAlert.sectionData.validations.minRequired') :
                $t('components.changePasswordAlert.sectionData.validations.passwordNewRepeat')) :
                ''"
            :label="$t('components.changePasswordAlert.create.passswordNew')"
            outlined
            @blur="$v.passwordNew.$touch()"
            v-model="$v.passwordNew.$model"
            :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showNew = !showNew"
            :type="showNew ? 'text' : 'password'"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="($v.passwordConfirmation.$dirty && $v.passwordConfirmation.$invalid) ?
                 ((!$v.passwordConfirmation.required) ? $t('components.changePasswordAlert.sectionData.validations.passwordConfirmationRequired') :
                (!$v.passwordConfirmation.sameAsNewPass) ? $t('components.changePasswordAlert.sectionData.validations.sameAsNewPass') :
                '' ) :
                ''"
            :label="$t('components.changePasswordAlert.create.passswordConfirmation')"
            outlined
            @blur="$v.passwordConfirmation.$touch()"
            v-model="$v.passwordConfirmation.$model"
            :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirmation = !showConfirmation"
            :type="showConfirmation ? 'text' : 'password'"
            @paste.prevent
          >
          </v-text-field>
          <br>
          <div class="linear-container" style="min-height: 4px;">
            <v-progress-linear background-color="#eee"
            :color="securityProgressBar.color"
            :value="progressBarValue">
            </v-progress-linear>
            <div class="d-flex justify-space-between">
              <span class="caption">{{ securityProgressBar.label }}</span>
              <span class="caption">{{ securityProgressBar.category }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <Button
          v-if="!$store.state.commons.hasExpired"
          white
          :text="$t('actions.cancel')"
          @clicked="resetButtonValues"
          class="mr-4"
        ></Button>
        <Button
          :loading="saveButton.loading"
          :success="saveButton.success"
          :error="saveButton.error"
          :text="$t('actions.save')"
          :successText="$t('actions.saved')"
          :errorText="$t('actions.error')"
          @clicked="changePassword"
        ></Button>
      </div>
    </OutlinedCard>
    <SuccessMessage 
        class="white"
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary">
    </SuccessMessage>
  </v-dialog>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { $users } from '../../../modules/portalalumno/Profile/Services';
import { mapGetters } from 'vuex';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

export default {
  name: 'ChangePasswordPopUp',
  components: {
    OutlinedCard,
    Button,
    SuccessMessage
  },

  data() {
    return {
      passwordOld: null,
      passwordNew: null,
      passwordConfirmation: null,
      incorrectPasswordOld: true,
      showOld: false,
      showNew: false,
      showConfirmation: false,
      statusOk: null,
      securityProgressBar: {
        color: 'error',
        label: this.$t('modules.profile.changePassword.securityProgressBar.label'),
        category: this.$t('modules.profile.changePassword.securityProgressBar.category.down')
      },
      successMessage: {
        actionPrimary: null,
        title: null,
        type: null,
      },
      saveButton: {
        loading: false,
        success: false,
        error: false,
      }
    };
  },
  validations: {
    passwordOld: {
      required,
      incorrectPassword() {
        return this.incorrectPasswordOld;
      }
    },
    passwordNew: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(20),
      mediumLevel() {
        const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
        return mediumRegex.test(this.passwordNew);
      },
      strongLevel() {
        const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@\\#$\\/%^~,;¿¡:"&*{}()|\\[\\]\\-_+=.])[A-Za-z\\d@\\-¡¿",;.:_$#\\/^+!%*?{}()&~\\[\\]\\=]{8,20}$');
        return strongRegex.test(this.passwordNew);
      },
      repeated(val) {
        return !(this.$v.passwordOld.$anyDirty && this.$v.passwordOld.required && val === this.$v.passwordOld.$model);
      },
    },
    passwordConfirmation: {
      required,
      sameAsNewPass(value) {
        return value === this.passwordNew;
      }
    }
  },
  computed: {
    progressBarValue() {
      let val = 0;
      if (this.$v.passwordNew.required) {
        this.changeProgressBar('error', this.$t('modules.profile.changePassword.securityProgressBar.category.down'));
        val++;
      }
      if (this.$v.passwordNew.mediumLevel) {
        this.changeProgressBar('warning', this.$t('modules.profile.changePassword.securityProgressBar.category.middle'));
        val++;
      }
      if (this.$v.passwordNew.strongLevel) {
        this.changeProgressBar('success', this.$t('modules.profile.changePassword.securityProgressBar.category.high'));
        val++;
      }
      return (val / 3) * 100;
    },
    active() {
      return ((this.$isPortalAdministrativo || (this.$isPortalAlumno && !this.$isGestor)) && this.$store.state.commons.hasExpired) || this.$store.state.commons.changePasswordPopUp
    },
    ...mapGetters({
      getStudentUser: 'commons/getStudentUser',
      getLoggedUser: 'commons/getUserID'
    }),
  },
  methods: {
    async changePassword() {
      this.saveButton.loading = true;
        if (this.$v.$invalid) {
          this.$v.$touch();
          this.saveButton.loading = false;
      } else {
        try {
          await $users.updatePassword(this.getStudentUser ?? this.getLoggedUser, 'change-password', {}, {
            prevPassword: this.passwordOld,
            newPassword: this.passwordNew
          });
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.profile.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.resetButtonValues()} };
          this.statusOk = true
          this.$store.dispatch('commons/setHasExpired', false)
          this.saveButton.success = true;
        } catch (err) {
          if (err.status == 204) {
            this.incorrectPasswordOld = false;
          } else {
              this.statusOk = true
              this.successMessage.type = 'error';
              this.successMessage.title = (err.codeMeaning == '') ? this.$t('modules.profile.error_message.title') : err.codeMeaning
              this.successMessage.actionPrimary = {text: this.$t('modules.profile.success_message.actions.back'), callback: () => {this.resetButtonValues()}}
          }
            this.saveButton.error = true;
          throw err;
        } finally {
          this.saveButton.loading = false;
        }
      }
    },
    changeProgressBar(color, category) {
      this.securityProgressBar.color = color;
      this.securityProgressBar.category = category;
    },
    resetButtonValues() {
      this.statusOk = false
      this.incorrectPasswordOld = true;
      this.passwordOld = [];
      this.passwordNew = null;
      this.passwordConfirmation = null;
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
      this.$v.$reset();
      this.$store.dispatch('commons/closePasswordPopUp');
    },
  }
};
</script>

<style lang="sass" scoped>
.border-change-password
  margin-bottom: 0 !important
</style>
