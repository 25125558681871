<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.exceptions.payments.breadcrumbs.title')"
                    :description="$t('modules.exceptions.payments.breadcrumbs.description')"
                ></Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">

                        <v-row class="mb-10">
                            <v-col sm="3" class="d-flex align-start py-0 justify-end">
                                <span class="body-1 primary--text font-weight-bold">{{ this.$isIPP ? $t('modules.exceptions.payments.labels.rut') : $t('modules.exceptions.payments.labels.dni')}}</span>
                            </v-col>
                            <v-col sm="9" class="d-flex align-start py-0">
                                <span class="body-1 text-uppercase">{{ student.identification }}</span>
                            </v-col>

                            <v-col sm="3" class="d-flex align-start pb-0 pt-1 justify-end">
                                <span class="body-1 primary--text font-weight-bold">{{ $t('modules.exceptions.payments.labels.student') }}</span>
                            </v-col>
                            <v-col sm="9" class="d-flex align-start pb-0 pt-1">
                                <span class="body-1 text-uppercase">{{ student.name }}</span>
                            </v-col>

                            <v-col sm="3" class="d-flex align-start pb-0 pt-1 justify-end">
                                <span class="body-1 primary--text font-weight-bold">{{ $t('modules.exceptions.payments.labels.career') }}</span>
                            </v-col>
                            <v-col sm="9" class="d-flex align-start pb-0 pt-1">
                                <span class="body-1 text-uppercase">{{ student.career }}</span>
                            </v-col>

                            <v-col sm="3" class="d-flex align-start pb-0 pt-1 justify-end">
                                <span class="body-1 primary--text font-weight-bold">{{ $t('modules.exceptions.payments.labels.studyPlan') }}</span>
                            </v-col>
                            <v-col sm="9" class="d-flex align-start pb-0 pt-1">
                                <span class="body-1 text-uppercase">{{ student.studyPlan }}</span>
                            </v-col>

                            <v-col sm="3" class="d-flex align-start pb-0 pt-1 justify-end">
                                <span class="body-1 primary--text font-weight-bold">{{ $t('modules.exceptions.payments.labels.term') }}</span>
                            </v-col>
                            <v-col sm="9" class="d-flex align-start pb-0 pt-1">
                                <span class="body-1 text-uppercase">{{ student.term }}</span>
                            </v-col>
                        </v-row>
                        <PaymentSuperTable
                            :headers="headers"
                            :data="payments"
                            @click-row="openPopup"
                            @changeStatus="changeStatus"
                            ref="paymentsTabla"
                            :clear-selected="clearSelected"
                            :page-count="pagination.pagesAmount"
                            :page="pagination.page"
                            :totalItems="totalItems"
                            @searchAction="searchAction"
                            :sortBy.sync="sort.sortBy"
                            :sortDesc.sync="sort.sortDesc"
                            @input-pagination="onInputPagination"
                            @length-pagination-change="onLengthPaginationChange"
                        />

                        <PaymentsPopup
                            v-if="selectedPayment"
                            :modalOpen="paymentPopupOpen"
                            :selectedPayment="selectedPayment"
                            :paymentStatus="paymentStatus"
                            @success="getPayments"
                            @close="() => {this.paymentPopupOpen = false; this.selectedPayment = null}"
                        />
                        <TemporaryRightDrawer
                            @tmpDrawerOpen="drawerOpen = false"
                            :open="drawerOpen"
                            :title="$t('modules.exceptions.payments.bankTransferPanel.title')"
                            :description="$t('modules.exceptions.payments.bankTransferPanel.subtitle')"
                        >
                            <BankTransferPanel
                                :payments="bulkItems"
                                @close="closeDrawer"
                                :user="student.rut"
                                @update="initialize"
                            />
                        </TemporaryRightDrawer>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import PaymentSuperTable from '../Components/PaymentSuperTable/PaymentSuperTable';
    import PaymentsPopup from '../Components/PaymentsPopup/PaymentsPopup';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import { $types, $studentAccount } from '../Services';
    import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
    import BankTransferPanel from '../Components/BankTransferPanel';


    export default {
        name: 'ExceptionsPaymentsTable',
        components: {
            PaymentSuperTable,
            PaymentsPopup,
            Breadcrumbs,
            TemporaryRightDrawer,
            BankTransferPanel,
        },
        provide(){
            return {
                studentId: this.$route.query.studentId,
            }
        },
        data () {
            return {
                search: '',
                payments: [],
                paymentPopupOpen: false,
                paymentStatus: [],
                selectedPayment: null,
                student: {
                    identification: null,
                    name: null,
                    career: null,
                    studyPlan: null,
                    term: null,
                },
                clearSelected: '',
                totalItems: null,
                pagination: {
                    limit: 20,
                    page: 1,
                    pageAmount: 1
                },
                sort: {
                    sortBy: 'id',
                    sortDesc: false,
                },
                headers: [
                    {
                        text: this.$t('modules.exceptions.payments.headers.id'),
                        value: 'id',
                        align: 'center',
                    },{
                        text: this.$t('modules.exceptions.payments.headers.description'),
                        value: 'accountDescription',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.payments.headers.amount'),
                        value: 'paymentAmount',
                        align: 'center',
                        class: 'text-nowrap',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.payments.headers.expiration'),
                        value: 'expiration',
                        align: 'center',
                    },{
                        text: this.$t('modules.exceptions.payments.headers.term'),
                        value: 'term',
                        sortable: false,
                        align: 'center',
                    },{
                        text: this.$t('modules.exceptions.payments.headers.status'),
                        value: 'paymentStatus',
                        sortable: false,
                        align: 'center',
                    }
                ],
                drawerOpen: false,
                bulkItems: [],
            }
        },
        mounted() {
            this.initialize();
        },
        methods: {
            initialize(){
                this.getPayments();
                this.getPaymentStatus();
            },
            openPopup(payment) {
                this.selectedPayment = payment
                this.paymentPopupOpen = true
            },
            searchAction(data) {
                this.pagination.page = 1;
                this.search = data;
                this.getPayments();
            },
            successMessage() {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.exceptions.table.actions.success') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                    icon: { color: 'success', name: 'mdi-check' },
                    color: 'primary',
                })
            },
            errorMessage(error) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.error'),
                    content: [{ value: error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.exceptions.table.actions.error') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary',
                })
            },
            async getPayments() {
                try {
                    const params = {
                        page: this.pagination.page - 1,
                        length: this.pagination.limit,
                        orderBy: this.sort.sortBy,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                        search: this.search
                    }
                    const response = await $studentAccount.findById(this.$route.params.studentRecord, 'adjustment', {params} );
                    const {data}  = response
                    this.payments = data.accounts.content.map(account => {
                        account.paymentAmount = Math.round(account.paymentAmount)
                        return account
                    })
                    this.student.identification = data.identification
                    this.student.name = `${data.name} ${data.lastName}`
                    this.student.career = `${data.careerCode} - ${data.career}`
                    this.student.studyPlan = data.studyPlan
                    this.student.term = data.termValidFrom

                    this.payments.sort((a, b) => new Date(b.expiration) - new Date(a.expiration));
                    this.totalItems = data.accounts.totalElements
                    this.pagination.pagesAmount = data.accounts.totalPages
                    this.paymentPopupOpen = false
                } catch (error) {
                    this.payments = [];
                    this.pagination.page = 1
                    this.pagination.pagesAmount = 1
                    this.totalItems = 0;
                }
            },
            async getPaymentStatus() {
                try {
                    const response = await $types.find(null, null, { params: { type: 'PAYMENT_STATUS' } });
                    this.paymentStatus = response.data.filter(status => status.value == 114 || status.value == 115 || status.value == 116 || status.value == 117)
                } catch {
                    this.paymentStatus = []
                }
            },
            changeStatus(items){
                this.bulkItems = items;
                this.drawerOpen = true;
            },
            closeDrawer(){
                this.bulkItems = [];
                this.drawerOpen = false;
                this.$refs.paymentsTabla.selectedItems = [];
            },
            onInputPagination(event) {
                this.pagination.page = event;
                this.getPayments();
            },
            onLengthPaginationChange(event) {
                this.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.getPayments();
            },
        },
        computed: {
            
        },
        watch: {
            sort: {
            handler() {
                this.getPayments();
            },
            deep: true
            }
        }
    }
</script>
