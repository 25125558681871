<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="px-0">
      <div class="px-md-10">

        <transition name="fade">
          <div>
            <div class="d-flex">
              <v-btn icon @click="goBack"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <h4 class="d-flex align-center primary--text ml-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.creditcardSingle') }}</h4>
            </div>

            <h5 v-if="cardIndex != null" class="mb-0 mt-8 pl-3">{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.labels.creditCard')} ${cardIndex + 1}` }}</h5>

            <GatewayMercadoPago
              ref="gateway"
              :cardIndex="cardIndex"
              :resumen="resumen"
              :summaryButtons="summaryButtons"
              :subscription="subscription"
              :pendingPayment="pendingPayment"
              :selectedMethod="selectedMethod"
              :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
              @paymentError="$emit('paymentError')"
              @changeStep="$emit('changeStep', $event)"
              @paymentProcessed="$emit('paymentProcessed', $event)"
              @setDue="$emit('setDue', $event)"
              @resetDue="$emit('setDue')"
            ></GatewayMercadoPago>

            <v-row class="my-10"></v-row>

          </div>
        </transition>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import GatewayMercadoPago from '../Components/Gateways/MercadoPago'

  export default {
    name: 'PaymentsSingleCardView',
    props: {
      resumen: Object,
      selectedMethod: Object,
      summaryButtons: Object,
      cardIndex: {type: Number, default: null},
      pendingPayment: {type: Boolean, default: false},
      subscription: {type: Object, default: null},
      hideInstallments: {type: Boolean, default: false},
      unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
    },
    components: {
      GatewayMercadoPago,
    },
    data() {
      return {
        
      }
    },
    methods: {
      saveBillingData() {
        this.$refs.gateway.saveBillingData()
      },
      goBack() {
        if (!this.summaryButtons.primary.loading) this.$emit('goBackPayment')
      },
    },
  }
</script>

<style scoped lang='sass'>
  .fade
    &-enter-active
      transition: opacity 0.5s
    &-enter,
    &-leave-active
      opacity: 0
</style>
