<template>
    <div v-if="benefit">
        <v-row class="px-3" v-if="!sendDocumentationStatus">
            <v-col cols="12">
                <div class="mx-5 my-3">
                    <h4 class="title font-weight-bold">{{ $t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.title') }}</h4>
                    <h4 class="body-2 my-2" v-for="(doc, i) in benefit.documentation" :key="`${doc.id}`">
                        {{ `${i + 1}) ` }}
                        <strong>{{ doc.name }}</strong>
                        <span v-if="doc.description">{{ ` - ${doc.description}` }}</span>
                    </h4>
                </div>
                <DropFiles
                    :key="dropFilesKey"
                    v-model="files"
                    class="mx-5 mt-5"
                    extension=".pdf,.jpg,.jpeg,.png"
                    :type-file="['image/*', 'application/pdf']"
                    limitSizeBytes="2000000"
                    multiple
                    :docTypes="benefit.documentation"
                ></DropFiles>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
                <Button
                    white
                    class="my-6"
                    :text="$t('actions.close')"
                    :disabled="sendButton.loading"
                    @clicked="closeConfirmation"
                ></Button>
                <Button
                    class="mx-5 my-6"
                    :loading="sendButton.loading"
                    :success="sendButton.success"
                    :error="sendButton.error"
                    :text="$t('actions.send')"
                    :successText="$t('actions.sent')"
                    :errorText="'Error'"
                    @end="resetButtonValues"
                    @clicked="confirmationPopup"
                ></Button>
            </v-col>
        </v-row>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
        />
    </div>
</template>

<script>
    import DropFiles from '@/components/DropFiles/DropFiles';
    import Button from '@/components/Button/Button';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { $studentProcessing } from '../../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'DocumentationUpload',
        props: {
            benefit: { type: Object, default: null }
        },
        components: {
            DropFiles,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                sendDocumentationStatus: false,
                files: [],
                dropFilesKey: 0,
                processingId: 0,
                sendButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: 'success',
                    title: '',
                    actionPrimary: { text: '', callback() {} },
                },
            }
        },
        computed: {
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentId: 'commons/getStudentId',
                studentData: 'commons/getStudentData',
                studentUserId: 'commons/getStudentUserID'
            }),
        },
        methods: {
            confirmationPopup() {
                if (!this.files.length) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.no_files'),
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    const fileWithoutType = this.files.find(file => file.docType == null)

                    if (fileWithoutType) {
                        this.$store.dispatch('commons/openPopUp', {
                            title: this.$t('alert'),
                            content: [{ value: this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.file_without_type'), }],
                            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                            icon: { color: 'warning', name: 'mdi-alert' },
                            color: 'primary',
                        });
                    } else {
                        this.$store.dispatch('commons/openPopUp', {
                            title: this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.send_confirmation'),
                            content: this.files.map(file => ({ value: file.file.name })),
                            actionPrimary: { text: this.$t('actions.send'), callback: () => this.sendDocumentation() },
                            actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                            icon: { color: 'warning', name: 'mdi-alert' },
                            color: 'primary',
                        });
                    }
                }
            },
            async sendDocumentation() {
                this.sendButton.loading = true;

                const model = {
                    studentRecord: this.studentRegister,
                    benefit: { id: this.benefit.id },
                    student: {
                        id: this.studentId,
                        user: {
                            name: this.studentData.student.user.name,
                        },
                    },
                    user: this.studentUserId ? this.studentUserId : null,
                    idDocumentation: null,
                    processingId: this.processingId
                };

                try {
                    for (let i = 0; i < this.files.length; i++) {
                        const file = this.files[i];

                        const formData = new FormData()
                        formData.append('documentation', file.file);
                        formData.append('model', JSON.stringify(model));
                        formData.append('partial', 1);
                        formData.append('idDocumentation', file.docType.id);

                        const response = await $studentProcessing.formData(formData, 'uploadDocumentation')
                        const data = JSON.parse(await response.text())
                        if (data.processingId) this.processingId = data.processingId
                    }

                    if (!this.processingId) throw 'Ocurrio un error generando el tramite'

                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.success_message');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.close() } }
                    this.sendButton.success = true;
                } catch (error) {
                    this.processingId = null
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning && error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.error_message');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.sendDocumentationStatus = false; }}
                    this.sendButton.error = true;

                    throw error
                } finally {
                    this.sendDocumentationStatus = true;
                    this.sendButton.loading = false;
                }
            },
            resetButtonValues() {
                this.sendButton.loading = false;
                this.sendButton.success = false;
                this.sendButton.error = false;
            },
            closeConfirmation() {
                if (this.files.length) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.portalalumno.payments.paymentsStudents.banners.benefit.upload_view.pending_files'),
                        actionPrimary: { text: this.$t('actions.yes'), callback: () => this.close() },
                        actionSecondary: { text: this.$t('actions.no'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.close()
                }
            },
            close() {
                this.$emit('close', this.processingId)
                this.files = []
                this.dropFilesKey++
                this.processingId = null
                this.sendDocumentationStatus = false
                this.resetButtonValues()
            },
        },
    }
</script>
