<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewBannerStatus">
      <Breadcrumbs
        :title="$t('modules.messaging.banners.create.breadcrumbs.title')"
        :description="$t('modules.messaging.banners.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row>
        <v-col class="viewSpace" sm="6">
          <OutlinedCard
            :title="$t('modules.messaging.banners.create.mainData.title')"
            :subtitle="$t('modules.messaging.banners.create.mainData.subtitle')"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            @switchChange="switchStatus"
            switcher
            class="px-0"
          >
            <v-row dense class="pb-2">
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.description.$dirty && $v.description.$invalid) ?
                  ((!$v.description.required) ? $t('modules.messaging.banners.validations.nameRequired') :
                  (!$v.description.alphaNumWithSpaces) ? $t('modules.messaging.banners.validations.nameAlphanumericWithSpaces') :
                  $t('modules.messaging.banners.validations.nameMaxLength')) :
                  ''"
                  v-model="$v.description.$model"
                  @blur="$v.description.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.description.$model,60, true)"
                  ref="description"
                  :label="`${$t('modules.messaging.banners.create.mainData.labels.name')} *`"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                  ((!$v.code.required) ? $t('modules.messaging.banners.validations.codeRequired') :
                  (!$v.code.alphaNum) ? $t('modules.messaging.banners.validations.codeAlphanumeric') :
                  (!$v.code.maxLength) ? $t('modules.messaging.banners.validations.codeMaxLength') :
                  $t('modules.messaging.banners.validations.codeUnique')) :
                  ''"
                  v-model="$v.code.$model"
                  @blur="validateCode"
                  @keypress="$validateAlphanumeric($event, $v.code.$model,20)"
                  ref="code"
                  :loading="loadingCode"
                  :label="`${$t('modules.messaging.banners.create.mainData.labels.code')} *`"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.order.$dirty && $v.order.$invalid) ?
                  ((!$v.order.minValue) ? $t('modules.messaging.banners.validations.orderMinValue') :
                  (!$v.order.numeric) ? $t('modules.messaging.banners.validations.orderNumeric') :
                  $t('modules.messaging.banners.validations.orderRequired')) : ''"
                  v-model="$v.order.$model"
                  @blur="$v.order.$touch()"
                  ref="order"
                  type="number"
                  :label="`${$t('modules.messaging.banners.create.mainData.labels.order')} *`"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col sm="6">
                <v-menu
                  v-model="menuFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  bottom
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="($v.from.$dirty && $v.from.$invalid) ?
                      $t('modules.messaging.banners.validations.dateRequired') : ''"
                      v-model="from"
                      @blur="$v.from.$touch()"
                      :label="$t('modules.messaging.banners.create.mainData.labels.fromDate')"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="from"
                    color="primary"
                    :min="(()=>(new Date().toISOString().substr(0, 10)))()"
                    :max="(()=>(to ? shiftDate(to, false) : null))()"
                    @input="menuFrom=false"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="6">
                <v-menu
                  v-model="menuTo"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  bottom
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="to"
                      :label="$t('modules.messaging.banners.create.mainData.labels.toDate')"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      v-on="on"
                      v-bind="attrs"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="to"
                    color="primary"
                    :min="(()=>(from ? shiftDate(from, true) : shiftDate(new Date(), true)))()"
                    @input="menuTo=false"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.messaging.banners.create.bannerDesign.title')"
          >
            <v-select
              :error-messages="($v.bannerType.$dirty && $v.bannerType.$invalid) ?
              $t('modules.messaging.banners.validations.bannerTypeRequired') : ''"
              v-model="$v.bannerType.$model"
              @blur="$v.bannerType.$touch()"
              ref="bannerType"
              :label="$t('modules.messaging.banners.create.bannerDesign.bannerType.label')"
              :items="bannerTypes"
              :placeholder="$t('modules.messaging.banners.create.bannerDesign.bannerType.placeholder')"
              class="my-6"
              outlined
              item-text="meaning"
            ></v-select>
            <v-expansion-panels
              v-if="bannerType == '273'"
              v-model="openedPanel"
              class="banner--expansion-panels"
              accordion
              focusable
            >
              <v-expansion-panel ref="title comment">
                <v-expansion-panel-header>{{
                    $t('modules.messaging.banners.create.bannerDesign.headers.text')
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-6">
                  <v-text-field
                    :error-messages="($v.title.$dirty && $v.title.$invalid) ?
                    ((!$v.title.required) ? $t('modules.messaging.banners.validations.titleRequired') :
                    $t('modules.messaging.banners.validations.titleMaxLength')) :
                    ''"
                    v-model="$v.title.$model"
                    @blur="$v.title.$touch()"
                    class="pb-2"
                    outlined
                    :label="`${$t('modules.messaging.banners.create.bannerDesign.labels.mainText')} *`"
                  ></v-text-field>
                  <v-text-field
                    :error-messages="($v.comment.$dirty && $v.comment.$invalid) ?
                    $t('modules.messaging.banners.validations.commentMaxLength') : ''"
                    v-model="$v.comment.$model"
                    @blur="$v.comment.$touch()"
                    class="pt-2"
                    outlined
                    :label="$t('modules.messaging.banners.create.bannerDesign.labels.secondaryText')"
                  ></v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>{{
                    $t('modules.messaging.banners.create.bannerDesign.headers.background')
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5">
                  <div class="d-lg-flex align-center">
                    <div class="col-lg-6">
                      {{ $t('modules.messaging.banners.create.bannerDesign.labels.backgroundColor') }}
                      <v-color-picker
                        v-model="color"
                        mode="hexa"
                        hide-mode-switch
                      ></v-color-picker>
                    </div>
                    <v-divider
                      class="mx-3 d-none d-lg-block"
                      vertical
                    ></v-divider>
                    <DropFiles
                      class="font-size-x-small text-center"
                      :extension="'.jpg, .png'"
                      :type-file="'image/*'"
                      :limit-size-bytes="'500000'"
                      :clear-validation.sync="clearDropFileValidation"
                      :show-title="false"
                      :show-dashed="true"
                      :size="'x-sm'"
                      :file-input.sync="bgImg"
                      @remove="bgImg = null"
                    >
                    </DropFiles>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel ref="img">
                <v-expansion-panel-header>{{
                    $t('modules.messaging.banners.create.bannerDesign.headers.images')
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5">
                  <div class="d-md-flex">
                    <DropFiles
                      class="font-size-x-small text-center"
                      :extension="'.jpg, .png'"
                      :type-file="'image/*'"
                      :limit-size-bytes="'500000'"
                      :required="$v.img.$anyError"
                      :clear-validation.sync="clearDropFileValidation"
                      :show-title="false"
                      :show-dashed="true"
                      :size="'x-sm'"
                      :file-input.sync="$v.img.$model"
                      @remove="img = null"
                    >
                    </DropFiles>
                    <v-divider
                      class="mx-4 d-none d-md-block"
                      vertical
                    ></v-divider>
                    <div class="pt-4 pt-md-0">
                      {{ $t('modules.messaging.banners.create.bannerDesign.labels.imagePosition') }}
                      <v-radio-group
                        v-model="imagePosition"
                        hide-details
                      >
                        <v-radio
                          :label="$t('modules.messaging.banners.create.bannerDesign.labels.left')"
                        >
                        </v-radio>
                        <v-radio
                          :label="$t('modules.messaging.banners.create.bannerDesign.labels.right')"
                        >
                        </v-radio>
                      </v-radio-group>
                      <v-checkbox
                        class="mt-2"
                        v-model="imageCorner"
                        :label="$t('modules.messaging.banners.create.bannerDesign.labels.corner')"
                      >
                      </v-checkbox>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel ref="groups">
                <v-expansion-panel-header>{{
                    $t('modules.messaging.banners.create.bannerDesign.headers.buttons')
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5 mb-n4">
                  <MultipleFormGroup
                    ref="groups"
                    class="banner--multipleFormGroup"
                    :items="$v.groups.$each.$iter"
                    :length="groups.length"
                    @addGroup="addGroup"
                    @removeGroup="removeGroup"
                    :canAddGroup="addGroupStatus"
                  >
                    <template v-slot:default="{item}">
                      <v-container fluid class="pa-0">
                        <v-row class="pt-2" no-gutters>
                          <v-col class="pb-0 pr-1" sm="6">
                            <v-text-field
                              :error-messages="(item.text.$dirty && item.text.$invalid) ?
                              ((!item.text.required) ? $t('modules.messaging.banners.validations.buttonTextRequired') :
                              (!item.text.alphaNumWithSpaces) ? $t('modules.messaging.banners.validations.buttonTextAlphanumericWithSpaces') :
                              $t('modules.messaging.banners.validations.buttonTextMaxLength', {maxLength})) :
                              ''"
                              v-model="item.text.$model"
                              @blur="item.text.$touch()"
                              @keypress="$validateAlphanumeric($event, item.text.$model, maxLength, true)"
                              outlined
                              :label="$t('modules.messaging.banners.create.bannerDesign.labels.buttonTitle')"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pb-0 pl-1" sm="6">
                            <v-text-field
                              :error-messages="(item.link.$dirty && item.link.$invalid) ?
                              ((!item.link.required) ? $t('modules.messaging.banners.validations.buttonLinkRequired') :
                              $t('modules.messaging.banners.validations.buttonLinkMaxLength')) :
                              ''"
                              v-model="item.link.$model"
                              @blur="item.link.$touch()"
                              outlined
                              :label="$t('modules.messaging.banners.create.bannerDesign.labels.buttonLink')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                  </MultipleFormGroup>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div v-if="bannerType == '274'">
              <DropFiles
                class="font-size-small text-center"
                :extension="'.jpg, .png, .gif'"
                :type-file="'image/*'"
                :limit-size-bytes="'1000000'"
                :required="$v.gif.$anyError"
                :clear-validation.sync="clearDropFileValidation"
                :show-title="false"
                :show-dashed="true"
                :size="'sm'"
                :file-input.sync="$v.gif.$model"
                @remove="gif = null"
              >
              </DropFiles>
              <v-text-field
                :error-messages="($v.bannerLink.$dirty && $v.bannerLink.$invalid) ?
                ((!$v.bannerLink.required) ? $t('modules.messaging.banners.validations.bannerLinkRequired') :
                $t('modules.messaging.banners.validations.bannerLinkMaxLength')) :
                ''"
                v-model="$v.bannerLink.$model"
                @blur="$v.bannerLink.$touch()"
                ref="bannerLink"
                class="pt-8"
                outlined
                :label="$t('modules.messaging.banners.create.bannerDesign.labels.bannerLink')"
              ></v-text-field>
            </div>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/messaging/banners')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="validateBanner"
            ></Button>
          </div>
        </v-col>
        <v-col sm="6" class="pt-5 pr-13">
          <OutlinedCard
            class="sticky"
            :title="$t('modules.messaging.banners.create.banner.preview')"
          >
            <generic-banner v-if="bannerType == '273'" :banner="banners" class="transform"></generic-banner>
            <v-img class="gif" :src="loadGif()" v-if="bannerType == '274'"></v-img>
          </OutlinedCard>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import GenericBanner from '../Components/GenericBanner';
import DropFiles from '../Components/DropFiles';
import {required, alphaNum, maxLength, helpers, requiredUnless, requiredIf, numeric, minValue} from 'vuelidate/lib/validators';
import {$banners, $types} from '../Services';
import moment from 'moment';
import Colors from '@/constants/colors';

export default {
  name: 'BannersCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    MultipleFormGroup,
    GenericBanner,
    DropFiles
  },
  data() {
    return {
      description: '',
      code: null,
      codeUnique: null,
      loadingCode: false,
      status: true,
      order: null,
      color: '',
      bannerType: '',
      bannerTypes: [
        {
          text: '',
          value: '273'
        },
        {
          text: '',
          value: '274'
        }
      ],
      menuFrom: false,
      menuTo: false,
      from: new Date().toISOString().substr(0, 10),
      to: '',
      gif: null,
      img: null,
      bgImg: null,
      title: '',
      comment: '',
      imagePosition: 0,
      imageCorner: false,
      bannerLink: '',
      groups: [
        {
          text: '',
          link: ''
        }
      ],
      addGroupStatus: true,
      openedPanel: null,
      panelValidation: {
        'title': 0,
        'comment': 0,
        'img': 2,
        'groups': 3
      },
      clearDropFileValidation: false,
      createNewBannerStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.messaging.banners.CREATE;
    },
    banners() {
      return {
        color: this.color,
        bgImg: this.bgImg ? URL.createObjectURL(new Blob([this.bgImg], {type: 'application/zip'})) : null,
        image: {
          src: this.img ? URL.createObjectURL(new Blob([this.img], {type: 'application/zip'}))
            : require('@/assets/placeholder.png'),
          position: this.imagePosition,
          corner: this.imageCorner,
        },
        text: [this.title, this.comment],
        button: this.groups
      };
    },
    maxLength() {
      let max
      switch (this.groups.length) {
        case 1: max = 10; break;
        case 2: max = 10; break;
        case 3: max = 10; break;
      }
      return max
    },
    isManualBannerType() {
      return this.bannerType == '273'
    }
  },
  mounted() {
    this.setPrimaryColor()
    this.getType()
  },
  methods: {
    switchStatus(val) {
      this.status = val
      if(!this.status) this.order = null
    },
    loadGif() {
      return URL.createObjectURL(new Blob([this.gif], {type: 'application/zip'}));
    },
    shiftDate(date, add) {
      return add ? moment(date).add(1, 'days').toISOString().substr(0, 10) :
        moment(date).subtract(1, 'days').toISOString().substr(0, 10);
    },
    async validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $banners.find('exists', null, {params: {code: this.$v.code.$model}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    setPrimaryColor() {
      this.color = this.$isTeclab ? Colors.teclab.primary.base : Colors.ipp.primary.base
    },
    async getType() {
        try {
          const banTypes = await $types.find(null, null, {
            params: { type: 'BANNER_TYPE'}
          });
          this.bannerTypes = banTypes.data
        } catch (error) {
          this.bannerTypes = [];
           throw error;
        }
    },
    addGroup() {
      const mockup =
        {
          text: '',
          link: ''
        };
      this.groups.push({
        ...mockup
      });
      this.addGroupStatus = this.groups.length < 3;
    },
    removeGroup(index) {
      this.groups.splice(index, 1);
      this.addGroupStatus = true;
    },
    validateBanner() {
      this.clearDropFileValidation = true
      if (this.$v.$invalid || !this.codeUnique) {
        this.$v.$touch();
        if (!this.codeUnique) {
          this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'});
        } else {
          Object.keys(this.$v).some(input => {
            if (input.includes('$')) return false;
            if (this.$v[input].$error) {
              Object.keys(this.$refs).some(ref => {
                if (ref.includes(input)) {
                  if(input in this.panelValidation) {
                    this.openedPanel = this.panelValidation[input]
                  } else {
                    this.openedPanel = null
                  }
                  this.$refs[ref][0]
                    ? this.$refs[ref][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                    : this.$refs[ref].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                }
              })
              return true;
            }
          });
        }
      } else {
        this.createBannerPopUp()
      }
    },
    createBannerPopUp () {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createBanner() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createBanner();
      }
    },
    async createBanner() {
      this.createButton.loading = true;
      try {
        const formData = new FormData();
        let newBanner;
        if (this.bannerType == '273') {
          newBanner = {
            visible: this.status,
            code: this.code,
            description: this.description.trim(),
            color: this.color,
            type: {value:this.bannerType},
            order: this.order,
            dateFrom: this.from,
            dateTo: this.to || null,
            title: this.title,
            comment: this.comment,
            images:
              this.bgImg ? [
                {
                  fileName: this.img.name,
                  position: this.imagePosition,
                  corner: this.imageCorner,
                  background: false,
                },
                {
                  fileName: this.bgImg.name,
                  position: 0,
                  corner: false,
                  background: true
                }
              ] : [
                {
                  fileName: this.img.name,
                  position: this.imagePosition,
                  corner: this.imageCorner,
                  background: false,
                }
              ],
            buttons: this.groups
          }
          if (this.bgImg) {
            formData.append('banner', this.bgImg)
            formData.append('banner', this.img)
          } else {
            formData.append('banner', this.img)
          }
        } else {
          newBanner = {
            code: this.code,
            description: this.description.trim(),
            visible: this.status,
            order: this.order,
            dateFrom: this.from,
            dateTo: this.to || null,
            type: {value:this.bannerType},
            color: this.color,
            title: '',
            comment: '',
            images: [
              {
                fileName: this.gif.name,
                position: 0,
                corner: false,
                background: false,
              }
            ],
            buttons: [
              {
                text: '',
                link: this.bannerLink
              }
            ]
          }
          formData.append('banner', this.gif)
        }
        formData.append('model', JSON.stringify(newBanner))
        await $banners.formData(formData)
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.messaging.banners.create.successMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.close'), callback: () => {
            this.$router.push('/messaging/banners');
          }
        };
        this.successMessage.actionSecondary = {
          text: this.$t('actions.create_another'), callback: () => {
            this.createNewBannerStatus = false;
          }
        };
        this.createNewBannerStatus = true;
        this.createButton.success = true;
        this.createButton.loading = false;
        this.clearData();
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.messaging.banners.create.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewBannerStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewBannerStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.description = null;
      this.code = null;
      this.status = true;
      this.order = null;
      this.setPrimaryColor()
      this.bannerType = '';
      this.from = new Date().toISOString().substr(0, 10);
      this.to = '';
      this.gif = null;
      this.img = null;
      this.bgImg = null;
      this.title = '';
      this.comment = '';
      this.imagePosition = 0;
      this.imageCorner = false;
      this.bannerLink = '';
      this.groups = [];
      this.addGroup();
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    description: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]*(?:[a-z0-9]+\s[a-z0-9]*)*$/i)
    },
    code: {
      required,
      alphaNum,
      maxLength: maxLength(20),
      unique() {
        return this.codeUnique != false;
      },
    },
    order: {
      required,
      numeric,
      minValue: minValue(0)
    },
    from: {required},
    bannerType: {required},
    title: {
      required: requiredIf('isManualBannerType'),
      maxLength: maxLength(65)
    },
    comment: {
      maxLength: maxLength(120)
    },
    img: { required: requiredIf('isManualBannerType') },
    gif: { required: requiredUnless('isManualBannerType') },
    bannerLink: {
      maxLength: maxLength(120)
    },
    groups: {
      $each:
        {
          text: {
            required: requiredIf('isManualBannerType'),
            alphaNumWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ0-9]*(?:[a-zá-úñ0-9]+\s[a-zá-úñ0-9]*)*$/i),
            maxLength(value) {
              let max
              switch (this.groups.length) {
                case 1: max = 10; break
                case 2: max = 10; break
                case 3: max = 10; break
              }
              return max >= value.length
            }
          },
          link: {
            required: requiredIf('isManualBannerType'),
            maxLength: maxLength(120)
          }
        }
    }
  }
};
</script>

<style lang="sass" scoped>
.viewSpace
  padding-top: 20px !important
  padding-bottom: 20px !important
  padding-left: 50px !important

.sticky
  position: sticky
  top: 20px
  height: 400px

.transform
  transform-origin: top
  transform: scale(1.2)

.font-size-x-small
  font-size: x-small

.font-size-small
  font-size: small

.gif
  cursor: pointer
  max-height: 300px

</style>

<style lang="sass">
.banner--multipleFormGroup
  > .multipleFormGroup__form-group
    width: 90%
    > .multipleFormGroup__form-group__content
      width: 97%

.banner--expansion-panels
  > .v-expansion-panel
    > .v-expansion-panel-header
      background-color: #F7F7F7
      border-color: #D0D0D0
      color: #939393
    > .v-expansion-panel-header--active
      background-color: #F2FAFE
      color: #46494A
      &:before
        opacity: 0 !important
      &:hover
        background-color: #F0EFEF
</style>
