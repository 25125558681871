<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!success">
        <Breadcrumbs v-if="!insideDrawer"
          :title="$t('modules.users.update.breadcrumbs.title')"
          :description="$t('modules.users.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <UserEditPersonalForm
                ref="personalForm"
                :genderTypes="genderTypes"
                :identificationTypes="identificationTypes"
                :nationalities="nationalities"
                :userData="$v.userData"
                :avatarFile.sync="userData.avatar"
                @setImagePreview="(image)=>{this.imagePreview = image}"
                @validateCode="validateCode"
                @validateRut="(status) => {this.validRut = status}"
                :loadingCode="loadingCode"
                :codeUnique.sync="codeUnique"
                :is-student="isStudent"
              ></UserEditPersonalForm>
              <UserEditPreviousStudies
                ref="previousStudies"
                v-if="isStudent"
                :userData="$v.userData"
                :student-levels="studentLevels"
                :institution-city="institutionCity"
              >
              </UserEditPreviousStudies>
              <UserEditContactForm
                ref="contactForm"
                :userData="$v.userData">
              </UserEditContactForm>

              <OutlinedCard
                v-if="$v.userData.$model.legajo"
                :title="$t('modules.users.update.sectionAcademic.title')"
                class="mb-6"
              >
                <v-row no-gutters>
                  <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionAcademic.fields.fileNumber') }}</span>
                  </v-col>
                  <v-col sm="4" class="align-start pb-1 pt-3">
                    <span>{{ $v.userData.$model.legajo }}</span>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                v-if="!insideDrawer && canUpdateRole"
                :title="$t('modules.users.update.sectionRoles.title')"
                class="mb-6"
              >
                <TransferList
                  :avatar="false"
                  :loading="loadingSearchRoles"
                  :firstListTitle="$t('modules.users.create.transferList.firstTitle')"
                  :secondListTitle="$t('modules.users.create.transferList.secondTitle')"
                  :availableItems.sync="roles"
                  :added-items.sync="rolesAdded"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchRoles"
                  @searchItemRightOnBackend="searchRolesAdded"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="rolesAdded.length === 0"
                >
                  {{ $t('modules.users.update.sectionRoles.validation_errors.roles') }}
                </v-alert>
                <div class="d-flex justify-end">
                  <Button
                    v-if="canCreateRole"
                    class="mt-4"
                    outlined
                    depressed
                    :text="$t('modules.users.update.createRol')"
                    @clicked="tmpDrawerOpen"
                  ></Button>
                </div>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="!insideDrawer ? $router.replace('/users') : $emit('closeDrawer')"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  @clicked="validateUser"
                  @end="resetButtonValues"
                  :disabled="!insideDrawer ? !canUpdateUser || this.rolesAdded.length === 0 : !canUpdateUser"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
      <TemporaryRightDrawer
        v-if="canCreateRole"
        :open="tmpDrawer.open"
        @tmpDrawerOpen="tmpDrawerOpen"
        :title="tmpDrawer.title"
        :description="tmpDrawer.description"
      >
        <RolesCreate
          @closeDrawer="()=>{this.tmpDrawer.open = false; this.getRoles()}"
          :insideDrawer="true"
        ></RolesCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<style lang="sass">
  .containerPadding
    padding-left: 28px
    padding-right: 28px
</style>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import UserEditPersonalForm from '../Components/UserEditForm/UserEditPersonalForm';
import UserEditPreviousStudies from '../Components/UserEditForm/UserEditPreviousStudies';
import UserEditContactForm from '../Components/UserEditForm/UserEditContactForm';
import Constants from '@/plugins/constants';
import TransferList from '@/components/TransferList/TransferList';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import RolesCreate from '../../Roles/Views/RolesCreate';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

import { required, email, minLength, requiredIf } from 'vuelidate/lib/validators';
import {$types, $users, $roles, $student, $nationalities} from '../services';
import { mapGetters } from 'vuex';

export default {
  components: {
    Breadcrumbs,
    UserEditPersonalForm,
    UserEditPreviousStudies,
    UserEditContactForm,
    TransferList,
    TemporaryRightDrawer,
    RolesCreate,
    OutlinedCard,
    Button,
    SuccessMessage
  },
  props: {
    insideDrawer: {type: Boolean, default: false},
  },
  data () {
    return {
      paginationLimit : 100,
      loadingSearchRoles: false,
      originalCode: null,
      success: false,
      codeUnique: true,
      loadingCode: false,
      validRut: true,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      tmpDrawer: {
        open: false,
        title: this.$t('modules.users.update.tmpDrawer.title'),
        description: this.$t('modules.users.update.tmpDrawer.description')
      },
      genderTypes: [],
      identificationTypes: [],
      nationalities: [],
      studentLevels: [],
      institutionCity: null,
      status: true,
      imagePreview: null,
      userData: {identificationType:{type: '',value: ''}},
      roles: [],
      rolesAdded: [],
      alumnoRolId: 1,
      selectedRoles: [],
      successMessage: {
        title: this.$t('modules.users.update.messages.successUpdate'),
        actionPrimary: { text: this.$t('modules.users.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/users') } }
      }
    }
  },
  validations: {
    userData: {
      name: { required },
      lastname: { required },
      birthDate: { required },
      birthPlace: { required: requiredIf(function (userData) { return this.isStudent || userData.birthPlace }) },
      nationality: { required: requiredIf(function (userData) { return this.isStudent || userData.nationality  }) },
      gender: { required },
      identificationType: { required },
      identification: {
        required,
        minLength: minLength(7),
        unique() {return this.codeUnique !== false},
      },
      serialNumber: { required: requiredIf('$isIPP') },
      email: { required, email },
      cellphone: { required },
      address: {
        street: { required: requiredIf( userData => userData?.address?.street ) },
        cityWithState: {
          idCity: {
            required: requiredIf(cityWithState => !!cityWithState?.idCity )
          },
          idRegion: {
            required: requiredIf( cityWithState => !!cityWithState?.idRegion )
          },
        },
        number: {},
        floor: {},
        apartment: {},
        zipCode: {},
      },
    },
  },
  mounted () {
    this.getNationalities()
    this.getStudentLevels()
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.getRoles()
      const genderResponse = await $types.find(null, null, { params: { type: 'GENDER_TYPE' } });
      this.genderTypes = genderResponse.data;

      const identificationResponse = await $types.find( null, null, { params: { type: 'IDENTIFICATION_TYPE' } })
      this.identificationTypes = identificationResponse.data;

      const {id} = this.$route.params;
      this.$users.getUserDataById(id)
        .then(async res => {

          // if (this.userCanSeeCeo(res.ceos)) {
            res.userData.avatar = `${Constants.URL_DATA_SERVER  }/users/${  id  }/avatar`;
            res.userData.originalAvatar = res.userData.avatar;
            res.userData.legajo = res.legajo;
            res.userData.address?.cityWithState
            res.userData.status = res.status;
            res.userData.phone = res.userData.phone || ''
            res.userData.level = null
            res.userData.institution = null

            this.userData = res.userData;
            this.originalCode = res.userData.identification
            
            this.rolesAdded = res.roles ? res.roles.map(item => { return {id: item.id, name: item.name} }) : [];
            this.roles = this.roles
                .filter(item => !this.rolesAdded.map(item => item.id).includes(item.id))
                .map(item => ({ name: item.name, id: item.id }));

            if (this.isStudent) {
            try {
              const resp = await $student.find(`detail/${this.$route.params.id}`)
              this.userData.birthPlace = resp.data.student.user.userData.birthPlace
              this.userData.nationality = resp.data.student.user.userData.nationality
              this.userData.level = resp.data.student.level?.value
              this.institutionCity = {
                cityState: resp.data.student.institution?.city || resp.data.student.institution?.region || '',
                idCity: resp.data.student.institution?.cityId || null,
                idRegion: resp.data.student.institution?.regionId || null
              }
              this.userData.institution = resp.data.student.institution?.id
            } catch (error) {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t('actions.error'),
                content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.users.update.messages.studentFetchError')}],
                actionPrimary: {
                  text: this.$t('actions.accept'), callback() {
                  }
                },
                icon: {color: 'error', name: 'mdi-close'},
                color: 'primary',
              });
              throw error
            }
          }
          // } else {
          //   this.$accessDenied()
          // }
        });
    },
    userCanSeeCeo(userCeos) {
      if (this.ceos !== null) {
        let found = false
        this.ceos.forEach (ceoId => {
          userCeos.forEach(uCeo => {
            if (ceoId === uCeo.id) {
              found = true
              return false
            }
          })
          if (found) return false
        })
        if (!found) return false
      }
      return true
    },
    async validateCode() {
        this.$v.userData.identification.$touch();
        if (this.$v.userData.identification.$model && this.$v.userData.identification.$model != this.originalCode) {
            this.codeUnique = null;
            this.loadingCode = true;
            try {
                const response = await $users.find('exists', null, {params: {identification: this.$v.userData.identification.$model}});
                this.codeUnique = !response.data.exists;
                this.loadingCode = false;
            } catch (error) {
                this.codeUnique = null;
                throw error
            } finally {
                this.$v.userData.identification.$touch();
            }
        }
    },
    searchRoles (event) {
      this.getRoles(event);
    },
    searchRolesAdded (event) {
      this.rolesAdded = event
    },
    async getRoles(rol) {
      this.loadingSearchRoles = true;
      let roles = [];

      const params = {
        orderBy: 'id',
        orientation: 'desc',
        page: 0,
        length: 500,
      }

      if (rol) params.name = rol
      roles = await $roles.find(null, null, {params: params }); // eslint-disable-line
      
      if (roles.data && roles.data.content.length) {
        roles = roles.data.content.map(item => {return { id: item.id, name: item.name }});
        this.roles = roles;
      }
      this.loadingSearchRoles = false;
    },
    async getNationalities() {
      try {
        const {data} = await $nationalities.find()
        this.nationalities = data
      } catch (err) {
        this.nationalities = []
        throw err
      }
    },
    async getStudentLevels() {
      try {
        const {data} = await $types.find(null, null, { params: { type: 'STUDENT_LEVEL_TYPE' } })
        this.studentLevels = data
      } catch (err) {
        this.studentLevels = []
        throw err
      }
    },
    checker: (arr, target) => target.every(v => arr.includes(v)),
    arraysEqual (a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      if (this.checker(a, b))
        if (a.length === b.length)
          return true;
        else
          return false;
      return false;
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open
    },
    //GET NESTED PROPERTY BY ARRAY REDUCE
    getNestedProperty (path) {
      path = path.split('.');
      return path.reduce((obj, key) => (obj && obj[key]) ? obj[key] : null, this);
    },
    updateUserPopUp() {
      if (!this.userData.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{value: this.$t('disabledMessage')}],
          actionPrimary: {text: this.$t('actions.save'), callback: () => {this.updateUser()}},
          actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.updateUser();
      }
    },
    async validateUser() {
      if (this.$v.userData.$invalid) {
        this.$v.userData.$touch();
        await this.$refs.personalForm.$refs.identification.blur({target: {value: this.userData.identification}})
        this.$refs.personalForm.$refs.birthPlace?.triggerOuterValidation()
        this.$refs.personalForm.$refs.birthDate.triggerOuterValidation()
        this.$refs.contactForm.$refs.cellphone.handleBlur()
        this.$refs.contactForm.$refs.cityWithState?.triggerOuterValidation()
        Object.keys(this.$v.userData).some(input => {
          if (input == 'address') {
            Object.keys(this.$v.userData.address).some(address => {
              if (address.includes('$')) return false;
              if (this.userData.address && this.$v.userData.address[address].$error) {
                this.$refs.contactForm.$refs[address].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                return true;
              }
            })
          } else {
            if (input.includes('$')) return false;
            if (this.$v.userData[input].$error) {
              if (this.$refs.personalForm.$refs?.[input]) {
                this.$refs.personalForm.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              } else if (this.$refs.previousStudies?.$refs?.[input]) {
                this.$refs.previousStudies.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              } else {
                this.$refs.contactForm.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              }
              return true;
            }
          }
        });
      } else {
        this.updateUserPopUp()
      }
    },
    async updateUser() {
      if (!this.canUpdateUser) return false;
      this.saveButton.loading = true;

      const roles = this.rolesAdded.map(role => {
        return { id: role.id };
      });

      const updatedUser = {
        id: this.$route.params.id,
        status: this.userData.status,
        userData: {
          name: this.userData.name,
          lastname: this.userData.lastname,
          email: this.userData.email,
          birthDate: this.userData.birthDate,
          gender: {value: this.userData.gender.value},
          identificationType: {value: this.userData.identificationType.value},
          identification: this.userData.identification,
          serialNumber: this.userData.serialNumber,
          phone: this.userData.phone,
          cellphone: this.userData.cellphone,
          institution: this.userData.institution,
          levelType: {value: this.userData.level},
          birthPlace: this.userData.birthPlace,
          nationality: this.userData.nationality ? this.userData.nationality : null,
          address: !this.userData.address 
          ? null
          : {
            street: this.userData.address?.street,
            number: this.userData.address?.number,
            floor: this.userData.address?.floor,
            apartment: this.userData.address?.apartment,
            zipCode: this.userData.address?.zipCode,
            cityWithState: {
              idCity : this.userData.address?.cityWithState?.idCity,
              idRegion : this.userData.address?.cityWithState?.idRegion,
            }
          },
        },
        roles,
        userUpdate: this['commons/getUserID'],
      };

      try {
        await $users.update(this.$route.params.id, updatedUser)

        if (this.userData.avatar !== this.userData.originalAvatar) {
          const formData = new FormData();
          formData.append('file', this.userData.avatar);
          const userId = this.$route.params.id;
          this.$users.sendAvatar(formData, userId);
        }

        this.saveButton.success = true;
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.users.update.success_message.title');
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = {text: this.$t('modules.users.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/users') }}
        } else {
          this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('closeDrawer'), this.success = false}}
        }
      } catch (error) {
        this.saveButton.error = true;
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.users.update.error_message.title');
        this.successMessage.actionPrimary = {text: this.$t('modules.users.update.success_message.actions.back'), callback: () => { this.success = false }}
      } finally {
        this.resetButtonValues()
        this.success = true;
      }
    },
    saveValue (field, value,userD) {
      this.saveButton.loading = true;

      if (field.type === 'avatarUpload') {
        const formData = new FormData();
        formData.append('file', value);
        const userId = this.$route.params.id;
        this.$users.sendAvatar(formData, userId).then(() => {
          this.userData.userData.avatar = `${this.userData.userData.avatar  }?${  new Date().getSeconds().toString()}`;
        })
      } else {

        const user = this.createAssociativeArray(field.data.keypath, value);
        user.userData= userD
        user.id = Number(this.$route.params.id);

        this.$users.updateUser(user).then(() => {
          this.saveButton.loading = false;
          this.saveButton.success = true;
          if (field.data.keypath == 'roles') this.success = true;
        }).catch(() => {
          this.saveButton.loading = false;
          this.saveButton.error = true;
        })
      }

    },
    createAssociativeArray (string, object) {
      const parts = string.split('.');
      const last = parts[parts.length - 1];
      const tree = {};

      const node = parts.slice(0, -1).reduce(function (memo, current) {
        return (memo[current] = {});
      }, tree);

      node[last] = object;
      return tree;
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
  },
  computed: {
    canUpdateUser() {
      return this.$permissions.portaladministrativo.users.UPDATE
    },
    canCreateRole() {
      return this.$permissions.portaladministrativo.roles.CREATE
    },
    canUpdateRole() {
      return this.$permissions.portaladministrativo.users.ASIGNAR_ROLES
    },
    ceos() {
      const rolCeo = this['commons/getUserRoles'].find(rol => {return rol.rolType.meaning == 'Ceo'})
      if (rolCeo) {
        let ceos = this['commons/getUserCeos']
        if (ceos) {
          ceos = ceos.map(ceo => {return ceo.id})
          return ceos
        } else {
          return []
        }
      }
      return null
    },
    ...mapGetters([
      'commons/getUserRoles',
      'commons/getUserCeos',
      'commons/getUserID',
    ]),
    isStudent() {
      return this.rolesAdded.some(rol => rol.id == this.alumnoRolId)
    }
  },

}
</script>
