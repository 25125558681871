<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight" v-if="!selectedInstallments">
      <v-container fluid class="px-0">
        <div class="px-md-10">
          <div class="d-flex">
            <v-btn icon @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <h4 class="d-flex align-center primary--text ml-2">{{ $t('modules.portalalumno.payments.paymentsStudents.view.subscription.payment', { org: 'Teclab' }) }}</h4>
          </div>
          <v-row>
            <v-col class="col-4">
              <v-select
                :label="$t('modules.portalalumno.payments.paymentsStudents.view.subscription.paymentMethod')"
                outlined
                :items="subscriptionPaymentTypes"
                item-text="meaning"
                v-model="selectedSubscriptionPaymentType"
              />
            </v-col>
            <v-col class="col-4">
              <v-select
                v-if="filteredSubscriptions"
                :label="$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installments') | $capitalize"
                outlined
                :items="filteredSubscriptions"
                v-model="selectedSubscription"
                return-object
              >
                <template v-slot:item="{ item }">
                  {{ `${item.installments} ${$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installments')}` }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ `${item.installments} ${$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installments')}` }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-data-table
            v-if="selectedSubscription"
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-filtering
            disable-pagination
            disable-sort
          ></v-data-table>
        </div>
      </v-container>
    </v-container>
    <PaymentsCashView
      ref="subscriptionCash"
      v-if="selectedInstallments && selectedSubscription.suscriptionPaymentType.meaning.toUpperCase() == 'EFECTIVO'"
      :resumen="resumen"
      :coupon="coupon"
      :summaryButtons="summaryButtons"
      :selectedMethod="{type: 'ticket'}"
      is-subscription
      @goBackPayment="goBackToSubscriptionPaymentMethod"
      @paymentProcessed="createSubscription"
    ></PaymentsCashView>
    <PaymentsSingleCardView
      ref="subscriptionCard"
      v-if="selectedInstallments && selectedSubscription.suscriptionPaymentType.meaning.toUpperCase() == 'TARJETA'"
      :subscription="selectedSubscription"
      :hide-installments="!!selectedSubscription"
      :resumen="resumen"
      :selectedMethod="selectedMethod"
      :summaryButtons="summaryButtons"
      @paymentProcessed="createSubscription"
      @goBackPayment="goBackToSubscriptionPaymentMethod"
      @setDue="setDue"
    ></PaymentsSingleCardView>
  </div>
</template>

<script>
import PaymentsSingleCardView from './PaymentsSingleCardView';
import PaymentsCashView from './PaymentsCashView';
import moment from 'moment'
import {$payments} from '../Services';
import {mapGetters} from 'vuex';

export default {
  name: 'PaymentsSubscriptionView',
  props: {
    subscriptions: Array,
    subscriptionPaymentTypes: Array,
    selectedMethod: Object,
    coupon: Object,
    resumen: Object,
    summaryButtons: Object,
    reenrollment: { type: Object, default: null },
    unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
  },
  components: {
    PaymentsSingleCardView,
    PaymentsCashView
  },
  data() {
    return {
      selectedInstallments: false,
      selectedSubscriptionPaymentType: null,
      selectedSubscription: null,
      adminExpenses: null,
      filteredSubscriptions: null,
      headers: [
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.description'), value: 'description' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.expiration'), value: 'expiration' },
        { text: this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.amount'), value: 'amount' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      loggedUserId: 'commons/getUserID'
    }),
  },
  watch: {
    selectedSubscriptionPaymentType(value) {
      this.selectedSubscription = null
      let filteredDues = this.subscriptions.filter(subscription => subscription.suscriptionPaymentType.value == value)
      if (this.resumen.items.subtotal.items.filter(price => price.title.toUpperCase().includes('ARANCEL')).length < 2) {
        filteredDues = filteredDues.length ? [filteredDues.shift()] : []
      }
      this.filteredSubscriptions = filteredDues
    },
    selectedSubscription(value) {
      this.summaryButtons.primary.disabled = !value
      const totalWithoutAdminExpenses = this.resumen.items.subtotal.monto - this.resumen.items.discounts.monto - this.resumen.items.scholarships.monto - this.resumen.items.benefits.monto
      this.adminExpenses = ((totalWithoutAdminExpenses * (this.selectedSubscription?.adminExpenses || 0)) / 100).toFixed(2)
      this.resumen.items.adminExpenses.monto = this.adminExpenses
      this.resumen.items.adminExpenses.percentage = this.selectedSubscription?.adminExpenses
      this.items = []
      for (let due = 0; due <= this.selectedSubscription?.installments; due++) {
        this.items.push({
          description: due ? `${this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.installment')} N°${due}` : this.$t('modules.portalalumno.payments.paymentsStudents.view.subscription.adminExpenses'),
          expiration: due ? moment(this.selectedSubscription.payDay, 'DD').add(due, 'months').format('D/M/YYYY') : moment().format('D/M/YYYY'),
          amount: due ? (totalWithoutAdminExpenses / this.selectedSubscription.installments).toFixed(2) : this.adminExpenses
        })
      }
      this.resumen.total = totalWithoutAdminExpenses + parseFloat(this.resumen.items.adminExpenses.monto)
    }
  },
  methods: {
    paymentInstallmentsSelected() {
      if (!this.selectedInstallments) {
        this.selectedInstallments = true
        this.summaryButtons.primary.disabled = true
        this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.paymentLink')
      } else {
          if (this.selectedSubscription.suscriptionPaymentType.meaning.toUpperCase() == 'EFECTIVO') {
          this.$refs.subscriptionCash.generateCoupon()
        } else {
          this.$refs.subscriptionCard.saveBillingData()
        }
      }
    },
    goBackToSubscriptionPaymentMethod() {
      this.selectedInstallments = false
      this.summaryButtons.primary.disabled = false
      this.summaryButtons.primary.text = this.$t('modules.portalalumno.payments.paymentsStudents.view.labels.continue')
      this.$emit('setDue')
    },
    async createSubscription(payload) {
      const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'))
      const cardNameInArray = payload.cardName?.split(' ') || [alumnoSeleccionado.student.user.userData.name, alumnoSeleccionado.student.user.userData.lastname]
      const suscripcionData = {
        firstName: cardNameInArray[0],
        lastName: cardNameInArray[cardNameInArray.length - 1],
        email: alumnoSeleccionado.student.user.userData.email,
        token: payload?.token || '',
        transaction_amount: ((this.resumen.total - this.resumen.items.adminExpenses.monto) / this.selectedSubscription.installments).toFixed(2),
        userType: 'ADVISOR',
        legajo: alumnoSeleccionado.academicRegister,
        userId: this.loggedUserId,
        intencion_de_pago_id: this.resumen.paymentIntent,
        setup_fee: this.resumen.items.adminExpenses.monto,
        repetitions: this.selectedSubscription.installments,
        debit_date: this.selectedSubscription.payDay,
        first_debit_date: moment(this.selectedSubscription.payDay, 'DD').add(1, 'months'),
        description: 'GASTO_ADMINISTRATIVO',
        efectivo: payload.efectivo,
        termId: this.reenrollment?.products.reduce(function (a, b) { return a.dueDate < b.dueDate ? a : b }).idTerm || null,
        idsPending: this.unselectedReenrollmentsAccountIds
      }
      try {
        await $payments.create(suscripcionData, {}, 'subscription');
        if (payload.data?.id) {
          //Pago con tarjeta
          const {data} = await $payments.updatePayment(payload.data.id, 'capture')
          window.Mercadopago.clearSession()
          this.$emit('changeStep', data.status);
        } else if (payload.efectivo) {
          //Pago con efectivo
          this.$emit('changeStep')
        }
      } catch {
        if (payload.data.id) {
          await $payments.updatePayment(payload.data.id, 'cancel')
        }
        this.summaryButtons.primary.loading = false
        this.summaryButtons.primary.error = true
        this.$store.dispatch('commons/openPopUp', {
          title: '¡Ups!',
          content: [{ value: 'Ocurrio un error al procesar tus datos.' }],
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.summaryButtons.primary.error = false
      }
    },
    setDue(due) {
      this.$emit('setDue', due)
    }
  }
}
</script>

<style scoped>

</style>
