const sanctionsTranslations = {
    es_CL: {
      sanctionAssign: {
        create: {
          cardUser: {
            subtitle: 'Buscá por RUT al alumno que se le aplicará la sanción',
          }
        }
      },
    },
    es_PE: {
        delete: {
            delete_message: 'La sanción no se puede eliminar porque tiene estudiantes activos asignados'
        },
        sanctionAssign: {
            table: {
                headers: {
                    column_5: 'Estudiante',
                }
            },
            create: {
                title: 'Asignar sanción al estudiante',
                cardUser: {
                    title: 'Datos del estudiante',
                    subtitle: 'Buscá por DNI al estudiante que se le aplicará la sanción',
                },
                cardSanction: {
                    subject: 'Curso'
                }
            },
            update: {
                cardUser: {
                    title: 'Datos del estudiante',
                    subtitle: 'Buscá por DNI al estudiante que se le aplicará la sanción',
                },
                cardSanction: {
                    subject: 'Curso'
                },
            }
        }
    },
    es: {
        table:{
          breadcrumbs:{
            title:'Sanciones',
            bulkDelete:'Eliminar sanciones',
            description: 'Administración de sanciones',
            button:{
              name: 'Nuevo',
              assign: 'Asignaciones'
            }
          },
          headers:{
            code : 'Codigo',
            title : 'Nombre',
            type: 'Área',
            level: 'Nivel',
            duration: 'Vigencia',
            status: 'Estado'
          },
          bulkDelete:'Eliminar sanciones',
        },
        create:{
            breadcrumbs: {
                  title: 'Nueva sanción',
                  description: 'Crea una nueva sanción.',
              },
            title:'Datos de la sanción',
            subtitle: 'Agrega la información junto con una breve descripción que permita tener datos relevantes.',
            labels:{
                name:'Descripcion',
                code:'Codigo',
                title: 'Nombre',
                type:'Tipo ',
                level:'Nivel',
                type_sanction:'Tipo de  sanción',
                duration:'Vigencia',
                description:'Descripcion',
                switch:{
                  isPermanent:'Es permanente',
                  cancellation:'Anulación de examen',
                  discharge:'Descargo'
                }
            },
            sectionModules:{
              title: 'Módulos que afecta',
              subtitle: 'Selecciona a que módulos afecta esta sanción.',
              transferList:{
                firstTitle:'Disponibles',
                secondTitle: 'Afectadas'

              }
            },
            success_message:{
              title: 'Sanción creada correctamente',
              actions: {
                primary_text: 'Cerrar',
                secondary_text: 'Crear otra',
                back: 'Volver',
            },
            },
            error_message:{
              title: 'Hubo un erro al crear la sanción',
            },
            errors_validations:{
              name: {
                required: 'La descripcion es obligatoria',
                maxLength: 'La descripcion es muy larga',
                minLength: 'La descripcion es muy corta',
                alphaNumWithSapces: 'No se admiten caracteres especiales'
              },
              code: {
                required: 'El codigo es obligatorio',
                maxLength: 'El codigo es muy largo',
                minLength: 'El codigo es muy corto',
                alphaNum: 'No se admiten caracteres especiales ni espacios',
                unique: 'El codigo ya existe'
              },
              typeSanction: {
                required: 'El tipo de sanción es obligatorio',
              },
              level: {
                required: 'El nivel de sanción es obligatorio',
              },
              duration: {
                required: 'Los dias son obligatorios',
                between: 'Debe ser mayor a 1'
              },
            }
        },
        details:{
          breadcrumbs:{
            actions:'Acciones'
          },
          table:{
            type: 'Tipo',
            level: 'Nivel',
            duration: 'Vigencia',
            examCancellation: {
              title: 'Anulación de examen',
              apply: 'Si aplica',
              noApply: 'No aplica'
            },
            discharge: {
              title: 'Descargo',
              apply : 'Si aplica',
              noApply : 'No aplica'
            },
            permanent: 'Permanente'
          },
          sectionModules:{
            title: 'Módulos que afecta',
            noModules:{
              message:'Sin modulos'
            }
          },
          DeletePopUp:{
            title:'¿Esta seguro de eliminar?'
          }
        },
        update: {
          breadcrumbs:{
            title: 'Actualizá la sanción',
            description: 'Actualizá la descripcion de la sanción',
            actions:'Acciones'
          },
          succesMessage: 'Sanción actualizada con éxito',
          error_message: {
            title: 'Ocurrió un error al actualizar la sanción',
          },
          btn: {
            update: 'Actualizar'
          }
        },
        delete: {
          delete_message: 'La sanción no se puede eliminar porque tiene alumnos activos asignados'
        },
        sanctionAssign: {
          table: {
            title: 'Sanciones asignadas',
            description: 'Sanciones aplicadas segun el reglamento',
            btnAssign: 'Asignar',
            headers: {
              column_1: 'Lo asigna',
              column_2: 'Fecha',
              column_3: 'Area',
              column_4: 'Tipo',
              column_5: 'Alumno',
              column_7: 'Vigente hasta',
            }
          },
          create: {
            title: 'Asignar sanción al alumno',
            description: 'Aplica una sanción segun el reglamento',
            cardUser: {
              title: 'Datos del alumno',
              subtitle: 'Buscá por DNI al alumno que se le aplicará la sanción',
              document: 'Documento',
              name: 'Nombre y apellido',
              doc_type: 'Tipo de documento',
              doc_number: 'Numero de documento',
              career: 'Carrera',
              legacy: 'Legajo'
            },
            cardSanction: {
              title: 'Asignación',
              subtitle: 'Configurá que tipo de sanción querés aplicar',
              area: 'Área',
              type: 'Tipo',
              reason: 'sanción',
              subject: 'Materia',
              dateEnd: 'Vencimiento',
              examens: 'Examenes',
              observation: 'Observación',
              message: 'La detección del uso de celulares, cuadernos, materiales, auriculares o cualquier otro dispositivo o recurso que se considere inapropiado durante el examen y revisión del mismo.La nota del examen se modifica a 1 y se bloquea la inscripción a esa materia durante 30 días.',

            },
            message_succes: 'Sanción asignada con éxito',
            message_error: 'Hubo un problema al asignar la sanción'
          },
          update: {
            title: 'Detalle de la sanción',
            cardUser: {
              title: 'Datos del alumno',
              subtitle: 'Buscá por DNI al alumno que se le aplicará la sanción',
              document: 'Documento',
              name: 'Nombre y apellido',
              doc_type: 'Tipo de documento',
              doc_number: 'Numero de documento',
              career: 'Carrera',
              legacy: 'Legajo'
            },
            cardSanction: {
              title: 'Asignación',
              subtitle: 'Detalle de la sanción asignada',
              area: 'Área',
              type: 'Tipo',
              reason: 'Motivo',
              subject: 'Materia',
              dateEnd: 'Vencimiento',
              examens: 'Examenes',
              observation: 'Observación',
              permanent: 'Permanente'
            },

          }

        }
     },
    en: {}
  };

export default sanctionsTranslations
