<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!updateExamStatus">
        <Breadcrumbs :title="`${$t('modules.sistemaQ.create.detail.title')} ${examBoard.time}`"></Breadcrumbs>
        <v-row justify="center" v-if="!loading">
          <v-col class="viewSpaces" sm="12">
            <OutlinedCard
              :switch-label="$t('modules.sistemaQ.create.card.switch')"
              :switchLabel="formattedStatus"
              :switchValue="examBoard.status"
              :title="$t('modules.sistemaQ.update.cardTitle')"
              @switchChange="onSwitchChange"
              class="mb-6"
              switcher
            >
              <v-row class="my-4">
                <v-col class="d-flex align-center" sm="3">
                  <v-card-text class="ml-10 py-0">{{ $t('modules.sistemaQ.create.detail.ceo') }}</v-card-text>
                </v-col>
                <v-col class="d-flex align-center" sm="3">
                  <v-card-text class="py-0">{{ examBoard.ceo }} </v-card-text>
                </v-col>
              </v-row>

             <v-row class="my-4">
                <v-col class="d-flex align-center" sm="3">
                  <v-card-text class="ml-10 py-0">{{ $t('modules.sistemaQ.create.card.date') }}</v-card-text>
                </v-col>
                   <v-row>
                      <v-col sm="4" class="px-0 py-0">
                        <v-menu
                          ref="dateFromMenu"
                          v-model="dateFromMenu"
                          :close-on-content-click="false"
                          :return-value.sync="examBoard.date"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formatDateToCurrent(examBoard.date)"
                              :label="$t('modules.sistemaQ.create.card.from')"
                              persistent-hint
                              append-icon="mdi-calendar"
                              readonly
                              color="primary"
                              outlined
                              v-on="on"
                              :error-messages="($v.examBoard.date.$dirty && !examBoard.date) ? $t('modules.sistemaQ.errors.dateRequired') : ($v.examBoard.date.$error ? $t('modules.sistemaQ.errors.datePair') : '')"
                              @blur="$v.examBoard.date.$touch()"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="examBoard.date"
                            color="primary"
                            no-title>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onCancelDatePickerClick('dateFromMenu')"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick('dateFromMenu', examBoard.date)"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
              </v-row>
              <v-row class="my-4">
                <v-col class="d-flex align-center" sm="3">
                  <v-card-text class="ml-10 py-0">{{ $t('modules.sistemaQ.create.detail.start') }}</v-card-text>
                </v-col>
                   <v-row>
                      <v-col sm="4" class="px-0 py-0">
                        <v-select
                          :label="$t('modules.sistemaQ.create.card.time')"
                          outlined
                          :items="list"
                          class="ancho2"
                          v-model="$v.hourStart.$model"
                          :error-messages="$v.hourStart.$model >= $v.hourEnd.$model ? $t('modules.sistemaQ.errors.hourInvalid') : ''"
                          @blur="$v.hourStart.$touch()"
                        ></v-select>
                      </v-col>
                    </v-row>
              </v-row>

              <v-row class="my-4">
                <v-col class="d-flex align-center" sm="3">
                  <v-card-text class="ml-10 py-0">{{ $t('modules.sistemaQ.update.to') }}</v-card-text>
                </v-col>
                   <v-row>
                      <v-col sm="4" class="px-0 py-0">
                        <v-select
                          :label="$t('modules.sistemaQ.update.to')"
                          outlined
                          :items="list"
                          class="ancho2"
                          v-model="$v.hourEnd.$model"
                          :error-messages="$v.hourStart.$model >= $v.hourEnd.$model ? $t('modules.sistemaQ.errors.hourInvalid') : ''"
                          @blur="$v.hourEnd.$touch()"
                        ></v-select>
                      </v-col>
                    </v-row>
              </v-row>

              <v-row>
                <v-col sm="4">

                  <v-card-text class="font-weight-black">{{$t('modules.sistemaQ.create.card.type')}}:</v-card-text>

                </v-col>
              </v-row>
              
              <v-row class="my-4" align="start" v-if="hasProctoringLab">
                <v-col class="d-flex align-center mt-2" sm="3">
                  <v-card-text class="ml-10 py-0"> {{ $t('modules.sistemaQ.create.card.lab') }}: </v-card-text>
                </v-col>

                <v-col class="d-flex align-center flex-column " sm="6">
                  <MultipleFormGroup
                    :items="$v.groupLaboratory.$each.$iter"
                    @removeGroup="removeGroupLaboratory"
                    @addGroup="addGroupLaboratory"
                    :length="groupLaboratory.length"
                    styleless
                  >
                    <template v-slot:default="{item}">

                      <v-row class="" justify-sm="center" g>

                        <v-col sm="6" class="px-2 py-0">
                          <v-select
                            v-model="item.proctoringId.$model"
                            label="Proctoring"
                            :items="proctoringTypes"
                            item-text="meaning"
                            outlined
                            class="ancho2"
                            :error-messages="(item.quantity.$model > 0) && item.proctoringId.$invalid 
                            ? (!item.proctoringId.required ? $t('modules.sistemaQ.errors.proctoringRequired') 
                            : $t('modules.sistemaQ.errors.proctoringRepeated')) : '' "
                            @blur="item.$touch()"
                          ></v-select>
                        </v-col>

                        <v-col sm="3" class="px-2 py-0">
                          <v-text-field
                            type="number"
                            v-model.number="item.quantity.$model"
                            class="ancho2"
                            outlined
                            placeholder="0"
                            min="0"
                            max="350"
                            @keypress="$validateIntegerInputNumber($event, item.quantity, 350)"
                            :error-messages="item.quantity && item.quantity.$error 
                              ? $t('modules.sistemaQ.errors.quantityEnable',{valor: `${studentsProctoring[item.proctoringId.$model] }` })
                              : ''"
                            @blur="item.quantity.$touch()"
                            />

                        </v-col>

                      </v-row>

                    </template>
                  </MultipleFormGroup>
                </v-col>
              </v-row>

              <v-row class="my-4" align="start" v-if="hasProctoringNote">
                <v-col class="d-flex align-center mt-2" sm="3">
                  <v-card-text class="ml-10 py-0"> {{ $t('modules.sistemaQ.create.card.notebook') }}: </v-card-text>
                </v-col>

                <v-col class="d-flex align-center flex-column " sm="6">
                  <MultipleFormGroup
                    :items="$v.groupNotebook.$each.$iter"
                    @removeGroup="removeGroupNotebook"
                    @addGroup="addGroupNotebook"
                    :length="groupNotebook.length"
                    styleless
                  >
                    <template v-slot:default="{item}">

                      <v-row class="" justify-sm="center" g>

                        <v-col sm="6" class="px-2 py-0">
                          <v-select
                            v-model="item.proctoringId.$model"
                            label="Proctoring"
                            :items="proctoringTypes"
                            item-text="meaning"
                            outlined
                            class="ancho2"
                            :error-messages="(item.quantity.$model > 0) && item.proctoringId.$invalid 
                            ? (!item.proctoringId.required ? $t('modules.sistemaQ.errors.proctoringRequired') 
                            : $t('modules.sistemaQ.errors.proctoringRepeated')) : '' "
                            @blur="item.$touch()"
                          ></v-select>
                        </v-col>

                        <v-col sm="3" class="px-2 py-0">
                          <v-text-field
                            type="number"
                            v-model.number="item.quantity.$model"
                            class="ancho2"
                            outlined
                            placeholder="0"
                            min="0"
                            max="350"
                            @keypress="$validateIntegerInputNumber($event, item.quantity, 350)"
                            :error-messages="item.quantity.$error 
                              ? $t('modules.sistemaQ.errors.quantityEnable',{valor: `${studentsProctoring[item.proctoringId.$model] }` })
                              : ''"
                            @blur="item.quantity.$touch()"
                            />

                        </v-col>

                      </v-row>

                    </template>
                  </MultipleFormGroup>
                </v-col>
              </v-row>

            </OutlinedCard>
            <OutlinedCard
              :subtitle="examBoard.students.length ? '' : $t('modules.sistemaQ.create.detail.subtitleDetail')"
              :title="$t('modules.sistemaQ.create.detail.subtitle')"
              class="mb-6 pa-10"
            >
              <v-list>
                <v-list-item
                  :key="item.title"
                  @click="()=>goToDetail(item.userId)"
                  class="pa-0"
                  v-for="item in examBoard.students"
                >
                  <v-list-item-content class="py-0 ml-10">
                    <v-list-item-title class="body-2">{{ item.name }} {{ item.lastname }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content class="py-0">
                    <v-list-item-title class="body-2 grey--text text--lighten-1"
                                       v-text="`${$t('modules.sistemaQ.create.detail.phone')} ${item.cellphone}`"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content class="py-0">
                      <v-col md=7>
                      <v-select
                          v-model="item.proctoringType"
                          label="Proctoring"
                          :items="proctoringTypes"
                          item-text="meaning"
                          outlined
                          class="ancho2"
                          disabled
                        ></v-select>
                        </v-col>
                  </v-list-item-content>
                  
                  <v-list-item-icon class="py-0">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </OutlinedCard>
            <v-row>
              <v-col>
                <div class="d-flex justify-start" v-if="canDelete && isDateEnabled">
                  <Button
                    :text="$t('modules.sistemaQ.create.button.void_all')"
                    @clicked="anulateBoard"
                    :disabled="!canDelete"
                    class="mr-4"
                    outlined>
                  </Button>
                </div>
              </v-col>
              <v-col>
                <div class="d-flex justify-end">
                  <Button
                    :text="$t('modules.sistemaQ.create.button.cancel')"
                    @clicked="cancelClick"
                    class="mr-4"
                    white>
                  </Button>
                  <Button
                    :disabled="!canUpdate || isUpdateButtonDisabled"
                    :error="createButton.error"
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :successText="$t('actions.created')"
                    :text="$t('modules.sistemaQ.create.button.save')"
                    @clicked="updateClick"
                  ></Button>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <SuccessMessage
        :actionPrimary="successMessage.actionPrimary"
        :title="successMessage.title"
        :type="successMessage.type"
        v-else
      />
    </v-container>
    <v-dialog
      v-if="canDelete"
      height="300"
      v-model="dialog"
      width="500"
    >
      <SistemaQExamModal
        @cancelClick="onCancelClick"
        @successMessage="anulationSuccess"
      ></SistemaQExamModal>
    </v-dialog>
  </div>
</template>

<style lang="sass" scoped>
  .containerPadding
    padding-left: 34px
    padding-right: 34px

    .disabledd
      color: #FAFAF !important

    .pa-0.v-list-item.v-list-item--link.theme--light
      height: 1px !important
</style>

<script>
  import {$exams, $types} from '../services';
  import {required, minValue, requiredIf} from 'vuelidate/lib/validators';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import Button from '@/components/Button/Button';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import SistemaQExamModal from './SistemaQExamModal.vue'
  import moment from 'moment'
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup.vue';

  export default {
    name: 'SistemaQExamDetial',
    components: {
      OutlinedCard,
      Button,
      Breadcrumbs,
      SistemaQExamModal,
      SuccessMessage,
      MultipleFormGroup
    },
    data() {
      return {
        groupLaboratory: [ {examPeriodType:'70' ,proctoringId:null, quantity:0} ],
        groupNotebook: [ {examPeriodType:'71' ,proctoringId:null, quantity:0} ],
        proctoringTypes:[],
        updateExamStatus: false,
        splittedIdParams: this.$router.history.current.params.id.split('-'),
        list: [],
        hourStart: null,
        hourEnd: null,
        dateFromMenu: false,
        hasLab: false,
        hasNote: false,
        loading: true,
        dialog: false,
        createButton: {
          loading: false,
          success: true,
          error: true,
          errorText: 'Error'
        },
        examBoard: {
          time: '',
          startTime: '',
          labQuantity: 0,
          noteQuantity: 0,
          status: true,
          date: null,
          students: [],
          ceo: null,
          proctoringType:'',
        },
        successMessage: {
          type: 'success',
          title: this.$t('modules.sistemaQ.create.success_message.title'),
          actionPrimary: {
            text: this.$t('modules.sistemaQ.create.success_message.actions.primary_text'),
            callback: () => {
              this.$router.push('/exam-board')
            }
          },
        },
      };
    },

    async mounted() {
      this.getProctoringTypes()
      this.generateTimes('HH:MM') //this.selectedTimeFormat
      await this.initialData()
    },
    
    validations: {
      examBoard: {
        date: {required}
      },
      hourStart: {required},
      hourEnd: {required},
      groupLaboratory: {
        $each: {
          examPeriodType:{required},
          proctoringId: {
            required:requiredIf( (value) => value.quantity > 0 ),
            isUnique(value) {
                return this.groupLaboratory.filter(item => item.proctoringId == value).length <= 1
            }
          },
          quantity: {
            minValue: minValue( 0 ),
            // difQuantity(item, itemPadre){
            //   if(!itemPadre.proctoringId) return true
            //   const proctoringQuantity = this.studentsProctoring[itemPadre.proctoringId] || 0
            //   const notebook = this.groupNotebook.find( () => itemPadre.proctoringId)
            //   return (proctoringQuantity - notebook?.quantity) <= item
            // }
          },
        }
      },
      groupNotebook: {
        $each: {
          examPeriodType:{required},
          proctoringId: {
            required:requiredIf( (value) => value.quantity > 0 ),
            isUnique(value) {
                return this.groupNotebook.filter(item => item.proctoringId == value).length <= 1
              }
          },
          quantity: {
            minValue: minValue( 0 ),
            // difQuantity(item, itemPadre){
            //   if(!itemPadre.proctoringId) return true
            //   const proctoringQuantity = this.studentsProctoring[itemPadre.proctoringId] || 0
            //   const notebook = this.groupLaboratory.find( () => itemPadre.proctoringId )
            //   return (proctoringQuantity - notebook?.quantity) <= item
            // }
          },
        }
      },
    },

    computed: {
      canUpdate() {
        return this.$permissions.portaladministrativo.exams.creation.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.exams.creation.DELETE
      },
      formattedStatus() {
        return this.examBoard.status ? this.$t('modules.sistemaQ.create.cards.switch.enable') : this.$t('modules.sistemaQ.create.cards.switch.disable')
      },
      isSaveDisabled() {
        return this.$v.examBoard.labQuantity.$error || this.$v.examBoard.noteQuantity.$error
      },
      isDateEnabled() {
        const date = new moment(this.examBoard.time)
        const today = new moment().format('DD/MM/YYYY - HH:MM:SS')
        return today < date._i
      },
      isUpdateButtonDisabled () {
        return this.$v.$invalid
      },
      studentsProctoring(){
        const ocurrence = {}
        for (const {proctoringType} of this.examBoard.students){
          const key = proctoringType
          ocurrence[key] ? ocurrence[key] += 1 : ocurrence[key] = 1
        }
        return ocurrence
      },
      hasProctoringNote(){
        return !this.groupNotebook.find( item => item.proctoringId == null )
      },
      hasProctoringLab(){
        return !this.groupLaboratory.find( item => item.proctoringId == null )
      }
    },

    methods: {
      async initialData(){
        const {data} = this.splittedIdParams.length === 1 
        ? await $exams.findById(`periods/details?id=${this.splittedIdParams[0]}`)
        : await $exams.findById(`periods/details?id=${this.splittedIdParams[0]},${this.splittedIdParams[1]}`)

        const groupNotebook = data.proctoring.filter( (item) => item.examPeriodType == 71 )
        const groupLaboratory = data.proctoring.filter( (item) => item.examPeriodType == 70 )

        this.groupNotebook = groupNotebook.length > 0 ? groupNotebook : [ {examPeriodType:'71' ,proctoringId:null, quantity:0} ]
        this.groupLaboratory = groupLaboratory.length > 0 ? groupLaboratory : [ {examPeriodType:'70' ,proctoringId:null, quantity:0} ]

        this.examBoard.ceo = data.ceo;
        this.examBoard.labQuantity = data.labQuantity || 0;
        this.examBoard.noteQuantity = data.noteQuantity || 0;
        this.examBoard.students = data.students;
        this.examBoard.time = `${moment(data.turnDate).format('DD/MM/YYYY')} - ${data.turnStart}`;
        this.examBoard.date = data.turnDate
        this.hourStart = data.turnStart;
        this.hourEnd = data.turnEnd;
        this.examBoard.status = data.status;

        this.hasLab = data.labQuantity || 0;
        this.hasNote = data.noteQuantity || 0;
        
        this.loading = false
      },
      async getProctoringTypes() {
        const { data } =  await $types.find(null, null, { params: { type: 'PROCTORING_TYPE' } })
        this.proctoringTypes = data
      },

      removeGroupLaboratory(index) {
        const {proctoringId} = this.groupLaboratory[index]
        const idRepeat = this.groupLaboratory.filter( (id) => id.proctoringId == proctoringId)
        
        for (const id in this.studentsProctoring){
          if(id == proctoringId && idRepeat.length == 1) {
            return this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.warning'),
              content: [{value:'Esta mesa no se puede eliminar porque tiene estudiantes inscritos'}],
              actionPrimary: { text: this.$t('actions.accept'), callback() {} },
              icon: { color: 'warning', name: 'mdi-alert' },
              color: 'primary'
            });
          }
        }
      this.groupLaboratory.splice(index, 1);
      },
      removeGroupNotebook(index) {
        const {proctoringId} = this.groupNotebook[index]
        const idRepeat = this.groupNotebook.filter( (id) => id.proctoringId == proctoringId)

        for (const id in this.studentsProctoring){
          if(id == proctoringId && idRepeat.length == 1) {
            return this.$store.dispatch('commons/openPopUp', {
              title: this.$t('actions.warning'),
              content: [{value:'Esta mesa no se puede eliminar porque tiene estudiantes inscritos'}],
              actionPrimary: { text: this.$t('actions.accept'), callback() {} },
              icon: { color: 'warning', name: 'mdi-alert' },
              color: 'primary'
            });
          }
        }
        this.groupNotebook.splice(index, 1);
      },
      addGroupLaboratory() {
        this.groupLaboratory.push({examPeriodType:'70' ,proctoringId:'',quantity:0} );
      },
      addGroupNotebook() {
        this.groupNotebook.push({examPeriodType:'71' ,proctoringId:'',quantity:0} );
      },
      formatDateToCurrent (date) {
        return date ? moment(date).format('DD/MM/YYYY') : null
      },
      onCancelDatePickerClick (model) {
        this[model] = false
      },
      onOkDatePickerClick (ref, date) {
        this.$refs[ref].save(date)
      },
      goToDetail(id){
        this.$router.push(`/users/details/${id}`)
      },
      anulationSuccess(success_message) {
        if (success_message.type === 'success') {
          success_message.actionPrimary = {
            text: this.$t('modules.sistemaQ.create.success_message.actions.primary_text'),
            callback: () => {
              this.$router.push('/exam-board')
            }
          }
        } else {
          success_message.actionPrimary = {
            text: this.$t('modules.sistemaQ.create.success_message.actions.back'),
            callback: () => {
              this.updateExamStatus = false
            }
          }
        }
        this.successMessage = success_message;
        this.dialog = false;
        this.updateExamStatus = true
      },
      async updateClick() {
        if (!this.examBoard.status) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.sistemaQ.update.warning_popup.title'),
            content: this.$t('modules.sistemaQ.update.warning_popup.content'),
            actionPrimary: {
              text: this.$t('modules.sistemaQ.update.buttons.accept'),
              callback: async () => {
                await this.triggerExamBoardUpdate()
              }
            },
            actionSecondary: {
              text: this.$t('modules.sistemaQ.update.buttons.cancel'),
              callback() {
              }
            },
            icon: {color: 'warning', name: 'mdi-eye-off-outline'},
            color: 'primary',
          })
        } else {
          this.triggerExamBoardUpdate()
        }
      },
      async triggerExamBoardUpdate() {
        if (!this.canUpdate) return false
        let queryCapacity = this.examBoard.labQuantity ? `${this.examBoard.labQuantity}, ` : '';
        queryCapacity += this.examBoard.noteQuantity ? `${this.examBoard.noteQuantity}` : '';
        const proctoring = []
        this.groupNotebook.forEach((item)=> proctoring.push(item))
        this.groupLaboratory.forEach((item)=> proctoring.push(item))
        this.examBoard.proctoring = proctoring

        try {
          const [id1, id2] = this.splittedIdParams;
          let updateIdQuery = id1;
          updateIdQuery += id2 ? `,${id2}` : '';
          await $exams.update('periods', this.examBoard.proctoring, {
            params: {
              periods: updateIdQuery,
              capacity: queryCapacity,
              status: this.examBoard.status,
              proctoring: this.examBoard.proctoringType.value,
              turnDate: this.examBoard.date,
              turnStart: this.hourStart,
              turnEnd: this.hourEnd
            }
          });
          this.successMessage.type = 'success',
          this.successMessage.title = this.$t('modules.sistemaQ.update.success_message.title');
          this.successMessage.actionPrimary = {
            text: this.$t('modules.sistemaQ.create.success_message.actions.primary_text'),
            callback: () => {
              this.$router.push('/exam-board')
            }
          }
        } catch (err) {
          this.successMessage.type = 'error',
            this.successMessage.title = err.codeMeaning;
          this.successMessage.actionPrimary = {
            text: this.$t('modules.sistemaQ.create.success_message.actions.back'),
            callback: () => {
              this.updateExamStatus = false
            }
          }
        } finally {
          this.updateExamStatus = true
        }
      },
      onSwitchChange() {
        this.examBoard.status = !this.examBoard.status
      },
      onCancelClick() {
        this.dialog = false
      },
      anulateBoard() {
        if (!this.canDelete) return false
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.sistemaQ.create.warning_anulation.title'),
          content: [{value: this.$t('modules.sistemaQ.create.warning_anulation.content')}],
          actionPrimary: {
            text: this.$t('modules.sistemaQ.create.button.accept'), callback: () => {
              this.dialog = true
            }
          },
          actionSecondary: {
            text: this.$t('modules.sistemaQ.create.button.cancel'), callback() {
            }
          },
          icon: {color: 'warning', name: 'mdi-eye-off-outline'},
          color: 'primary',
        })
      },
      cancelClick() {
        this.redirectTo('/exam-board')
      },
      redirectTo(route) {
        this.$router.replace(route)
      },
      generateTimes (format) {
        if (format.text === 'AM/PM') {
          this.list = [
            {text: '00:00:00 AM', value: '00:00:00'},
            {text: '01:00:00 AM', value: '01:00:00'},
            {text: '02:00:00 AM', value: '02:00:00'},
            {text: '03:00:00 AM', value: '03:00:00'},
            {text: '04:00:00 AM', value: '04:00:00'},
            {text: '05:00:00 AM', value: '05:00:00'},
            {text: '06:00:00 AM', value: '06:00:00'},
            {text: '07:00:00 AM', value: '07:00:00'},
            {text: '08:00:00 AM', value: '08:00:00'},
            {text: '09:00:00 AM', value: '09:00:00'},
            {text: '10:00:00 AM', value: '10:00:00'},
            {text: '11:00:00 AM', value: '11:00:00'},
            {text: '12:00:00 AM', value: '12:00:00'},
            {text: '01:00:00 PM', value: '13:00:00'},
            {text: '02:00:00 PM', value: '14:00:00'},
            {text: '03:00:00 PM', value: '15:00:00'},
            {text: '04:00:00 PM', value: '16:00:00'},
            {text: '05:00:00 PM', value: '17:00:00'},
            {text: '06:00:00 PM', value: '18:00:00'},
            {text: '07:00:00 PM', value: '19:00:00'},
            {text: '08:00:00 PM', value: '20:00:00'},
            {text: '09:00:00 PM', value: '21:00:00'},
            {text: '10:00:00 PM', value: '22:00:00'},
            {text: '11:00:00 PM', value: '23:00:00'},
            {text: '12:00:00 PM', value: '24:00:00'}
          ]
        } else {
          this.list = [
            {text: '00:00:00', value: '00:00:00'},
            {text: '01:00:00', value: '01:00:00'},
            {text: '02:00:00', value: '02:00:00'},
            {text: '03:00:00', value: '03:00:00'},
            {text: '04:00:00', value: '04:00:00'},
            {text: '05:00:00', value: '05:00:00'},
            {text: '06:00:00', value: '06:00:00'},
            {text: '07:00:00', value: '07:00:00'},
            {text: '08:00:00', value: '08:00:00'},
            {text: '09:00:00', value: '09:00:00'},
            {text: '10:00:00', value: '10:00:00'},
            {text: '11:00:00', value: '11:00:00'},
            {text: '12:00:00', value: '12:00:00'},
            {text: '13:00:00', value: '13:00:00'},
            {text: '14:00:00', value: '14:00:00'},
            {text: '15:00:00', value: '15:00:00'},
            {text: '16:00:00', value: '16:00:00'},
            {text: '17:00:00', value: '17:00:00'},
            {text: '18:00:00', value: '18:00:00'},
            {text: '19:00:00', value: '19:00:00'},
            {text: '20:00:00', value: '20:00:00'},
            {text: '21:00:00', value: '21:00:00'},
            {text: '22:00:00', value: '22:00:00'},
            {text: '23:00:00', value: '23:00:00'},
            {text: '24:00:00', value: '24:00:00'}
          ]
        }
        this.rawList = [...this.list]
      },
    },
  };
</script>