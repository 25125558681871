const statusConnectionTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Estado de servicios',
                description: 'Análisis de estado'
            },
            service:{
                $academic: {
                    title: 'Academic',  
                },
                $billing: {
                    title: 'Billing',
                },
                $commission: {
                    title: 'Commission',
                },
                $core: {
                    title: 'Core',
                },
                $elibro: {
                    title: 'E-Libro',
                },
                $email: {
                    title: 'Email',
                },
                $hubspot: {
                    title: 'Hubspot',
                },
                $klarway: {
                    title: 'Klarway',
                },
                $messages: {
                    title: 'Messages',
                },
                $payment: {
                    title: 'Payment',
                },
                $practica: {
                    title: 'Practica',
                },
                $pricing: {
                    title: 'Pricing',
                },
                $qSystem: {
                    title: 'Q-System',
                },
                $report: {
                    title: 'Report',
                },
                $student: {
                    title: 'Student',
                },
                $sumadi: {
                    title: 'Sumadi',
                },
                $whatsapp: {
                    title: 'Whatsapp',
                },
            },
            icon:{
                null:{
                    description:'mdi-clock-outline',
                    color:'orange'
                },
                true:{
                    description:'mdi-check-circle',
                    color:'green'
                },
                false:{
                    description:'mdi-close-circle',
                    color:'red'
                }
            }
        },
    },
    en: {

    }
};

export default statusConnectionTranslations;