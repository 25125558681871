<template>
  <div>
    <div class="atm-ticket-card mt-2 mb-2 pa-8">
      <v-row>
        <v-col class="text-left pt-0" cols="6">
          <img width="auto" height="30" :src="logoPath" :alt="`${institute} Logo`" />
        </v-col>
        <v-col class="text-right pt-0" cols="6">
          <img
              v-if="coupon.payment?.thumbnail"
              :alt="coupon.payment?.payment_type_id"
              height="30"
              :src="coupon.payment.thumbnail"
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <div class="coupon-info pa-6">
            <v-row>
              <v-col class="text-left py-0" cols="6">
                <div class="coupon-info--title">{{$t('modules.portalalumno.payments.paymentsStudents.view.coupons.amountTitle')}}</div>
                <div class="coupon-info--description pt-2">{{ (resumen.items.adminExpenses.monto || resumen.total) | $currency }}</div>
              </v-col>
              <v-col class="text-right py-0" cols="6">
                <div class="coupon-info--title">{{$t('modules.portalalumno.payments.paymentsStudents.view.coupons.expirationDateTitle')}}</div>
                <div class="coupon-info--description pt-2">{{ expirationDateDescription }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="coupon-info--code-info-card pa-4">
                      <p class="coupon-info--code-info-card--title mb-2">{{$t('modules.portalalumno.payments.paymentsStudents.view.coupons.numberTitle')}}</p>
                      <p class="coupon-info--code-info-card--code mb-0">{{ coupon.number }}</p>
                    </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="atm-ticket-extra-info">
      <v-row>
        <v-col cols="12">
          <p class="text-center atm-ticket-extra-info--reminder mb-0" v-html="$t('modules.portalalumno.payments.paymentsStudents.view.coupons.info')"></p>
          <p class="text-center atm-ticket-extra-info--reminder mb-0" v-html="$t('modules.portalalumno.payments.paymentsStudents.view.coupons.infoLink', {service: this.coupon.externalResourceUrl})"></p>
        </v-col>
        <v-col cols="12" class="py-0">
          <p class="text-center atm-ticket-extra-info--disclaimer mb-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.codeReminder') }}</p>
          <p class="text-center atm-ticket-extra-info--disclaimer mb-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.paymentReminder') }}</p>
          <p class="text-center atm-ticket-extra-info--disclaimer mb-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.disclaimer', {org: institute}) }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
moment.locale('es');
export default {
  name: 'AtmTicket',
  props: ['coupon', 'resumen', 'logoPath', 'institute'],
  computed: {
    expirationDayNumber() {
      return moment(this.coupon.dateOfExpiration).format('DD')
    },
    expirationMonthName() {
      return moment(this.coupon.dateOfExpiration).format('MMMM')
    },
    expirationDateDescription() {
      return `${this.expirationDayNumber} de ${this.expirationMonthName}`
    }
  }
}
</script>

<style lang="sass" scoped>
.atm-ticket-extra-info
  &--reminder
    font-family: 'Roboto'
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.8
    letter-spacing: -0.3px
    color: rgba(0, 0, 0, 0.87)
    text-align: center
  &--disclaimer
    max-width: 100%
    margin-left: auto
    margin-right: auto
    font-family: 'Roboto'
    font-size: 12px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.67
    letter-spacing: 0.22px
    color: rgba(0, 0, 0, 0.87)

.atm-ticket-card
  max-width: 100%
  margin: 0 auto
  background-color: white
  border-radius: 8px
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
.coupon-info
  border-radius: 10px
  background-color: #eeeeee
  &--description
    font-family: 'Roboto'
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 0.67
    letter-spacing: -0.72px
    color: rgba(33, 33, 33, 0.75)
  &--title
    font-family: 'DM Sans'
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.5
    letter-spacing: -0.3px
    color: rgba(33, 33, 33, 0.75)
  &--code-info-card
    border-radius: 10px
    background-color: #ffffff
    border: solid 1.8px #7b7b7b4a
    text-align: center
    &--title
      font-family: 'Roboto'
      font-size: 12px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.8
      letter-spacing: -0.3px
      color: rgba(33, 33, 33, 0.75)
      text-align: center
    &--code
      font-family: 'Roboto'
      font-size: 35px
      font-weight: bold
      font-stretch: normal
      font-style: normal
      line-height: 0.67
      letter-spacing: 0.5px
      color: rgba(33, 33, 33, 0.75)
@media only screen and (max-width: 600px)
  .atm-ticket-card
    max-width: 80% !important

@media only screen and (min-width: 800px)
  .atm-ticket-card
    max-width: 65% !important

@media only screen and (min-width: 950px)
  .atm-ticket-card
    max-width: 45% !important
</style>