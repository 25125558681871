<template>
    <v-container fluid class="pa-0">
        <iframe
            :src="url"
            frameborder="0"
            style="width: 100%; height: 100%"
        ></iframe>
    </v-container>
</template>

<script>
import Configuration from '../../../../../core/constants/configuration';
    export default {
        name: 'GradebookView',
        components: { },
        data() {
            return {}
        },
        computed: {
            url() {
                const subdomain = (Configuration.value('env') == 'production') ? 'reciclado' : 'recicladopre'
                return `https://${subdomain}.teclab.edu.ar/libro-matriz/index.html`
            }
        }
    };
</script>