<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="() => false"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs
            :title="$t('modules.users.table.breadcrumbs.title')"
            :description="$t('modules.users.table.breadcrumbs.description')"
          >
            <div class="d-flex justify-end">
              <Button
                class="mr-4"
                v-if="canUpdate"
                @clicked="tmpDrawerOpen"
                :icon="'mdi-download'"
                :text="$t('actions.load')"
                depressed
                outlined
              ></Button>
              <Button
                v-if="canCreate"
                @clicked="newUser"
                :icon="'mdi-plus'"
                :text="$t('actions.new')"
                depressed
              ></Button>
            </div>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :users="users"
                :permissions="{ read: canRead, update: canUpdate, delete: canDelete }"
                :query="query"
                :pageCount="asidebar.pagination.pagesAmount"
                :page="asidebar.pagination.currentPage"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                :totalItems="totalItems"
                :deleteTitle="$t('modules.users.table.bulk_delete')"
                @bulk-assign="bulkAssign"
                @bulk-delete="bulkDeletePopUp"
                @deleteUser="deletePopUp"
                @openDetails="openDetails"
                @updateUser="updateUser"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                @searchAction="search"
              ></SuperTable>
            </v-col>
          </v-row>
        </v-container>
        <TemporaryRightDrawer
          @tmpDrawerOpen="tmpDrawerOpen"
          :open="tmpDrawer.open"
          :title="tmpDrawer.title"
          :description="tmpDrawer.description"
        >
          <MassImportWorkData
            @close="tmpDrawer.open = false"
          ></MassImportWorkData>
        </TemporaryRightDrawer>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import AsideBar from '@/components/AsideBar/AsideBar';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import MassImportWorkData from '../Components/MassImportWorkData/MassImportWorkData';
import Constants from '@/plugins/constants'

import { mapGetters } from 'vuex';
import { $users, $roles, $careers} from '../services';

export default {
  components: {
    MassImportWorkData,
    SuperTable,
    Breadcrumbs,
    Button,
    AsideBar,
    TemporaryRightDrawer
  },
  data () {
    return {
      markedUsers: [],
      query: null,
      totalItems: 0,
      headers: [
        {
          text: this.$t('modules.users.table.headers.name'),
          value: 'name',
          sort: () => 0
        },
        {
          text: this.$t('modules.users.table.headers.roles'),
          value: 'roles',
          sortable: false
        },
        {
          text: this.$t('modules.users.table.headers.careerCode'),
          value: 'careerCode',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('modules.users.table.headers.lastActivity'),
          value: 'lastActivity',
          sort: () => 0
        },
        {
          text: this.$t('modules.users.table.headers.status'),
          value: 'status',
          sort: () => 0,
          align: 'center'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      asidebar: {
        title: 'Refinar búsqueda',
        pagination: {
          limit: 20,
          page: 1,
          currentPage: 1,
          pagesAmount: 1
        },
        items: [
          {
            name: this.$t('modules.users.table.headers.roles'),
            filters: null
          },
          {
            name: this.$t('modules.users.table.headers.status'),
            filters: [
              {
                name: this.$t('modules.users.table.status.actives'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
              {
                name: this.$t('modules.users.table.status.inactives'),
                type: 'check',
                value: true,
                dataFiltered: ''
              },
            ]
          },
          {
            name: this.$t('modules.users.table.headers.nameCareer'),
            filters: null
          },
          {
            name: this.$t('modules.users.table.headers.academicStatus'),
            filters: null
          },
        ]
      },
      sort: {
        sortBy: 'name',
        sortDesc: false,
      },
      users: [],
      tmpDrawer: {
        open: false,
        title: this.$t('modules.users.table.massImportDrawer.breadcrumbs.title'),
        description: this.$t('modules.users.table.massImportDrawer.breadcrumbs.description')
      }
    }
  },
  methods: {
    async getRoles() {
      const roles = (await $roles.find(null, null, { params: { page: 0, length: 500 } })).data.content;
      const rolId = this.$route.query.rol
      this.asidebar.items[0].filters = roles.map(item => {
        const check = (rolId) ? ((item.id == rolId) ? true : false) : true
        return {
          id: item.id,
          name: item.name,
          type: 'check',
          value: check,
          dataFiltered: ''
        }
      });
    },
    async getCareers() {
      const careers = (await $careers.find(null, null, { params: { page: 0, length: 500 } })).data;
      const careerId = this.$route.query.rol
      this.asidebar.items[2].filters = careers.map(item => {
        const check = (careerId) ? ((item.id == careerId) ? false : true) : false
        return {
          id: item.id,
          name: item.description,
          type: 'check',
          value: check,
          dataFiltered: ''
        }
      });
    },
    async getCareerStatus() {
      const careerStatus = (await $careers.find('status', null, { params: { page: 0, length: 500 } })).data;
      const careerStatusValue = this.$route.query.rol
      this.asidebar.items[3].filters = careerStatus.map(item => {
        const check = (careerStatusValue) ? ((item.value == careerStatusValue) ? false : true) : false
        return {
          id: item.value,
          name: item.meaning,
          type: 'check',
          value: check,
          dataFiltered: ''
        }
      });
    },
    async onInputPagination(event) {
      this.asidebar.pagination.page = event;
      this.asidebar.pagination.currentPage = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.asidebar.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.fetchData();
    },
    async search(data) {
      this.query = data;
      this.fetchData();
    },
    openDetails(id) {
      // if (this.canRead) 
      this.$router.push(`users/details/${id}`);
    },
    tmpDrawerOpen() {
      this.tmpDrawer.open = !this.tmpDrawer.open;
    },
    deletePopUp (id) {
      const user = this.users.find(user => user.userId == id);
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [{
          value: `${user.name  } ${  user.lastname}`
        }],
        actionPrimary: {
          text: this.$t('actions.accept'), callback: () => {
            this.deleteUser(id)
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {

          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    bulkDeletePopUp (users) {
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: users.map(user => ({
          value: `${user.name  } ${  user.lastname}`
        })),
        actionPrimary: {
          text: this.$t('actions.accept'), callback: () => {
            this.bulkDelete(users)
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {

          }
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    bulkDelete (users) {
      const itemsForDelete = users.map((user) => {
        return { id: user.userId }
      });
      this.$users.bulkDeleteUsers(itemsForDelete)
        .then(() => {
          this.fetchData()
        })
        .catch(() => {
          this.$store.dispatch('commons/openPopUp', {
            title: 'Error',
            content: [{value: this.$t('modules.users.table.bulk_delete_error')}],
            actionPrimary: {
              text: this.$t('actions.accept'), callback() {}
            },
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary',
          })
          this.fetchData()
        })
    },
    bulkAssign () {
      return;
    },
    updateUser (id) {
      if (this.canUpdate) this.$router.push(`/users/update/${id}`)
    },
    async deleteUser (id) {
      try {
        await $users.delete(id);
        this.users = this.users.filter(user => user.userId !== id)
      } catch(error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{value: this.$t('modules.users.table.delete_error')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
      }
    },
    newUser () {
      if (this.canCreate) this.$router.push('/users/create')
    },
    async fetchData () {
      if(this.loading) return; //won't fetch if it's already fetching

      let sortCol = 'name';
      switch (this.sort.sortBy) {
        case 'name':
        default:
          sortCol = 'userData.name';
          break;
        case 'status':
          sortCol = 'status';
          break;
        case 'lastActivity':
          sortCol = 'userLastLogin';
          break;
      }

      const params = {
        page: this.asidebar.pagination.page - 1,
        length: this.asidebar.pagination.limit,
        orderBy: sortCol,
        orientation: this.sort.sortDesc ? 'desc' : 'asc',
        userId: this['commons/getUserData'].userId
      }

      const reqBody = {
        roles: this.selectedRoles,
        careers: this.selectedCareers,
        careerStatus: this.selectedCareerStatus,
        status: this.selectedStatus,
        search: this.query || ''
      }

      // const userCeos = this.ceos
      // if (userCeos) reqBody.ceos = userCeos

      try {
        const users = await $users.findFiltered('filter', null, reqBody, { params });
        this.totalItems = users.data.totalElements;
        this.asidebar.pagination.pagesAmount = users.data.totalPages;
        this.users = users.data.content.map(item => {
          const user = {};
          user.userId = item.id;
          user.name = item.userData.name;
          user.lastname = item.userData.lastname;
          user.avatar = `${Constants.URL_DATA_SERVER}/users/${item.id}/avatar`;
          user.roles = item.roles
          user.status = item.status;
          user.ceos = item.ceos
          user.lastActivity = item.userLastLogin;
          user.selected = false;
          user.careerCode = item.careerCode
          return user;
        });
      } catch (error) {
        this.users = [];
      }

    }
  },
  mounted () {
    this.getRoles();
    this.getCareers();
    this.getCareerStatus(); 
  },
  computed: {
    canRead() {
      return this.$permissions.portaladministrativo.users.READ
    },
    canCreate() {
      return this.$permissions.portaladministrativo.users.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.users.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.users.DELETE
    },
    selectedRoles() {
      if(this.asidebar.items[0].filters) {
        const filtered = this.asidebar.items[0].filters.filter(item => item.value==true);

        if(filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);

        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedStatus() {
      const ret = [];
      if(this.asidebar.items[1].filters) {
        const filtered = this.asidebar.items[1].filters.filter(item => item.value==true);

        if(filtered.length == 1) filtered[0].disabled = true;
        else filtered.forEach(item => item.disabled = false);

        if(this.asidebar.items[1].filters[0].value) ret.push(true);
        if(this.asidebar.items[1].filters[1].value) ret.push(false);
      }
      return ret;
    },
    selectedCareers() {
      if(this.asidebar.items[2].filters) {
        const filtered = this.asidebar.items[2].filters.filter(item => item.value==true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },
    selectedCareerStatus() {
      if(this.asidebar.items[3].filters) {
        const filtered = this.asidebar.items[3].filters.filter(item => item.value==true);
        filtered.forEach(item => item.disabled = false);
        return filtered.map(item => item.id);
      }
      return [];
    },

    

    ceos() {
      const rolCeo = this['commons/getUserRoles'].find(rol => {return rol.rolType.meaning == 'Ceo'})
      if (rolCeo) {
        let ceos = this['commons/getUserCeos']
        if (ceos) {
          ceos = ceos.map(ceo => {return ceo.id})
          return ceos
        } else {
          return []
        }
      }
      return null
    },
    loading() {
      return this['commons/getLoadingState'].status
    },
    ...mapGetters([
      'commons/getUserRoles',
      'commons/getUserCeos',
      'commons/getLoadingState',
      'commons/getUserData',
    ])
  },
  watch: {
    
    selectedRoles() {
      this.fetchData();
    },
    selectedCareers() {
      this.fetchData();
    },
    selectedCareerStatus() {
      this.fetchData();
    },
    selectedStatus() {
      this.fetchData();
    },
    sort: {
      handler(){
        this.fetchData();
      },
      deep: true
    }
  }

}
</script>
