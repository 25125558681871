<template>
    <v-container class="d-flex flex-row pa-0 fullHeight scrollableContent" fluid>
        <v-container class="container container-custom px-6">
            <Breadcrumbs :title="$t('modules.examExceptions.table.breadcrumbs.title')" :description="$t('modules.examExceptions.table.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col sm="12" class="mb-12">
                    <SuperTable
                        :headers="headers"
                        :data="exams"
                        :pageCount="pagination.pagesAmount"
                        :page="pagination.currentPage"
                        :totalItems="totalItems"
                        @searchAction="onSearchAction($event)"
                        @items-per-page-change="onLengthPaginationChange"
                        @input-pagination="onInputPagination"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import { $examsException } from '../Services';

    export default {
        name: 'ExamExceptionTable',
        components:{
            SuperTable,
            Breadcrumbs,
        },
        data () {
            return {
                totalItems: 0,
                exams: [],
                pagination: {
                    limit: 20,
                    page: 1,
                    currentPage: 1,
                    pagesAmount: 1,
                },
                breadcrumbs: {
                    title: this.$t('modules.preRegistration.table.breadcrumbs.title'),
                    description: this.$t('modules.preRegistration.table.breadcrumbs.subtitle'),
                    button: {
                        name: this.$t('actions.new'),
                        icon: 'mdi-plus',
                        color: 'primary'
                    }
                },
                headers: [
                    {
                        text: this.$t('modules.examExceptions.table.headers.identification'),
                        value: 'identification',
                    },{
                        text: this.$t('modules.examExceptions.table.headers.name'),
                        value: 'name'
                    },{
                        text: this.$t('modules.examExceptions.table.headers.lastname'),
                        value: 'lastname'
                    },{
                        text: this.$t('modules.examExceptions.table.headers.code'),
                        value: 'code'
                    },{
                        text: this.$t('modules.examExceptions.table.headers.career'),
                        value: 'career'
                    },{
                        text: this.$t('modules.examExceptions.table.headers.ceo'),
                        value: 'ceo'
                    }
                ],
            }
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            onSearchAction (event) {
                this.fetchData(event);
            },
            onInputPagination (event) {
                this.pagination.page = event
                this.pagination.currentPage = event
                this.fetchData()
            },
            onLengthPaginationChange (event) {
                this.pagination = {
                    page: 1,
                    limit: event,
                    currentPage: 1,
                    pagesAmount: 1,
                }
                this.fetchData()
            },
            async fetchData(search = null) {
                const exams = await $examsException.getAvailableStudents({params: {page: this.pagination.page - 1, length: this.pagination.limit, param: search}})
                this.exams = exams.data.content.map(student => {
                    return {
                        id: student.id,
                        identification: student.identification,
                        name: student.name,
                        lastname: student.lastname,
                        code: student.career.code,
                        career: student.career.description,
                        ceo: student.ceo.description,
                        legajo: student.studentCareer,
                    }
                })
                this.pagination.pagesAmount = exams.data.totalPages
                this.totalItems = exams.data.totalElements
            },
        }
    }
</script>