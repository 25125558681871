<template>
  <RecoveredForm
    :loading="loading"
    :logoPath="logoPath"
    :recoveredData="recoveredData"
    :errorAuth="errorAuth"
    @recoveredAction="recoveredAction"
  >
    <template v-if="/\/login\/new-password.*/g.test(currentPath)">
      <RecoveredPassForm :passInputs="passInputs" @validatePassSecurity="validatePassSecurity" :errorAuth.sync="errorAuth"></RecoveredPassForm>
    </template>
    <template v-else>
      <RecoveredUserForm :userInputs="userInputs"></RecoveredUserForm>
    </template>
  </RecoveredForm>
</template>

<script>
import RecoveredForm from '../Components/RecoveredForm/RecoveredForm'
import RecoveredPassForm from '../Components/RecoveredForm/RecoveredPassForm'
import RecoveredUserForm from '../Components/RecoveredForm/RecoveredUserForm'

import { mapGetters } from 'vuex'

export default {
  components: {
    RecoveredForm,
    RecoveredPassForm,
    RecoveredUserForm
  },
  props: {
    logoPath: String
  },
  data () {
    return {
      loading: false,
      errorAuth: false,
      serverError: false,
      currentPath: '',
      recoveredData: {
        title: '',
        authError: false,
        authErrorMessage: this.$t('modules.login.recovered.user.authError')
      },
      userInputs: {
        email: '',
        password: ''
      },
      passInputs: {
        newPass: '',
        newPassRepeated: ''
      },

      validationCode: ''
    }
  },
  async mounted () {
    this.currentPath = this.$route.fullPath;

    //CHECK IF IS THE RECOVER MAIL FLOW

    if ( !( /\/login\/new-password.*/g.test(this.currentPath) ) ){

      this.recoveredData.title = this.$t('modules.login.recovered.user.title');
      this.userInputs.email = this['login/getRecoveryData'].email;
      return;
    }



    let validationCode = this.$route.query.cc;// base64 encoded string 'codigo'
    let email = this.$route.query.m; // base64 encoded string 'mail'


    this.recoveredData.title = this.$t('modules.login.recovered.password.title');


    validationCode = decodeURIComponent(validationCode);
    email = decodeURIComponent(email);

    validationCode = this.b64DecodeUnicode(validationCode);
    email = this.b64DecodeUnicode(email);

    const isCodeValid = await this.validateCode(email, validationCode);
    if(!isCodeValid) await this.$router.replace('/login/security-code-password')

    await this.$store.dispatch('login/setRecoveryInfo', { username: email })
    await this.$store.dispatch('login/setValidationCode', validationCode)

  },
  computed: {
    ...mapGetters([
      'login/getRecoveryData'
    ])
  },
  methods: {
    b64DecodeUnicode (str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function (c) {
        return `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`;
      }).join(''));
    },
    validateCode (mail, code) {
      return new Promise((resolve) => {
        this.$auth.validateCode(mail, code)
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    recoveredAction () {
      if (/\/login\/new-password.*/g.test(this.currentPath)) {
        if (this.passInputs.newPass === this.passInputs.newPassRepeated) {
          const pass = this.passInputs.newPass;
          const {validationCode} = this['login/getRecoveryData'];
          const {username} = this['login/getRecoveryData'];
          this.loading = true;
          this.$auth.changePassword(username, validationCode, pass, pass)
            .then(() => {
              this.$router.push('/login')
            })
            .catch(error => {
              this.recoveredData.authError = true;
              this.recoveredData.authErrorMessage = this.$t('modules.login.recovered.password.changeError');
              throw new Error(error)
            }).finally(() => {
              this.loading = false;
            })
        }
      } else {
        this.loading = true;
        this.$auth.login(this.userInputs.email, this.userInputs.password)
          .then(() => {
            this.loading = false;
          })
          .catch((err) => {
            if (err === 'invalid_grant')
              this.errorAuth = true;
            else
              this.serverError = true;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    validatePassSecurity () {
      //
    },
  }
}
</script>
