const paymentsTranslations = {
  es_CL: {
    // banner: 'Puedes obtener un descuento adicional de 5% pagando el total de las cuotas',
    banners: {
      title: 'Puedes obtener un descuento adicional de {percentage}% pagando el total de las cuotas',
      no_subjects_quota: 'Aún no cumplís con el cupo requerido de asignaturas. Debés tener al menos 7 asignaturas aprobadas para poder reinscribirte'
    },
    view: {
      labels: {
        idoncard: 'Número de RUT',
        selectPaymentHelp: 'Si no encuentras tu medio de pago, comunícate con Sofía',
        no_cash_method: 'Si deseas pagar en efectivo, lo puedes realizar en cualquier caja <strong>Servipag</strong> a lo largo del país, indicando solo tu <strong>Rut</strong>.'
      },
      coupons: {
          info: 'Ingresa a <a href="{service}" target="_blank">{service}</a> y encuentra tu sucursal más cercana para realizar el pago.',
      },
    },
  },
  es_PE: {
      banners: {
          title: 'Puedes obtener un descuento adicional de {percentage}% pagando el total de las cuotas',
          no_subjects_quota: 'Aún no cumples con el cupo requerido de cursos. Debés tener al menos 7 cursos aprobados para poder reinscribirte',
      },
      view: {
          coupons: {
              nextSteps: 'Con este cupón debes acercarte a una sucursal habilitada y pagar',
              print: 'Imprimir cupón',
              numberTitle: 'Este es tu código de pago CIP',
              amountTitle: 'Vas a pagar',
              expirationDateTitle: 'Tienes tiempo hasta el',
              info: 'Los horarios de atención y montos de pago dependen de cada establecimiento.',
              infoLink: 'Para más información, haz click <a href="{service}" target="_blank">aquí</a>',
              codeReminder: 'Recuerda que los códigos tienen un tiempo de vigencia. Una vez expirado, el código ya no es válido',
              paymentReminder: 'Este cupón no constituye pago.',
              disclaimer: 'El pago no se verá reflejado hasta que se acredite. Esto es ajeno a {org}.',
          },
          labels: {
              selectPaymentHelp: 'Si no encuentras tu medio de pago, comunicate con Albert',
              creditcardSingle: 'Selecciona como lo quieres pagar',
              city: 'Distrito',
              state: 'Departamento'
          } 
      }
  },  
  es: {
    banners: {
      title: 'Si abonás con tarjeta Visa, Mastercard, o Amex ¡Tenés cuotas sin interés!',
      installments: '¡Cuotas sin interés!',
      available_banks: 'Unicamente en bancos habilitados',
      scholarship_title: 'Tu beca se ha renovado automáticamente',
      no_subjects_quota: 'Aún no cumplís con el cupo requerido de materias. Debés tener al menos 7 materias aprobadas para poder reinscribirte',
      debt: {
        title: '¡Tenés una deuda pendiente!',
        subtitle: 'Es importante que saldes tu deuda para reinscribirte a tu próximo periodo',
      },
      benefit: {
        renewed: 'Tu beneficio ha sido renovado correctamente',
        want_renew: '¿Querés renovar tu Beneficio {benefit}?',
        must_approve: 'La documentación que enviaste debe ser aprobada para que se aplique el beneficio',
        no_renew_benefit: 'No pudimos renovar tu beneficio',
        no_renew: 'No pudimos renovar tu beca, tu promedio es menor al requerido. Revisá los requisitos.',
        upload_button: 'Subir documentación',
        upload_title: 'Beneficio',
        upload_description: 'Subir documentación',
        see_regulation: 'Ver reglamento',
        upload_view: {
          title: 'Adjunte la siguiente documentación',
          no_files: 'No se detectaron archivos para enviar',
          pending_files: 'Hay archivos pendientes de enviar. ¿Desea salir?',
          send_confirmation: '¿Desea enviar los siguientes archivos? No se podrán modificar',
          file_without_type: 'Se encontraron uno o mas archivos sin el tipo de documentación seleccionado',
          success_message: 'La documentación se envió correctamente',
          error_message: 'Ocurrió un error enviando la documentación',
        }
      }
    },
    inputErrors: {
      required: 'Este campo es obligatorio',
      length: 'El formato es inválido',
    },
    breadcrumbs:{
      title: 'Pagos',
      description:'Acá podrás hacer tus pagos y ver las promociones vigentes.'
    },
    finalView:{
      successMessage:{
        title:'¡Listo, se acreditó tu pago!',
        description:'En tu resúmen verás el cargo de {total} como MERCADO PAGO.',
      },
      processMessage:{
        title:'Tu pago está siendo revisado.',
        description: 'En el plazo de 72 horas habiles confirmaremos si tu pago fue aprobado o rechazado.'
      },
      rejectedMessage:{
        title:'Hubo un error al procesar tu pago.',
        description:''
      },
      successDebitMessage: {
        title: '¡Listo!',
        description: 'Serás redireccionado para realizar el pago.',
      },
      rejectedDebitMessage: {
        title: 'Hubo un error al procesar tu pago.',
        description: ''
      },
    },
    view: {
      reenrollment: {
        error_creating: 'Ocurrió un error generando los cupones'
      },
      repayment: {
        banner: 'Recuerda que solo podrás repactar tu deuda una vez por semestre',
        debt_title: 'Deuda a repactar',
        title: 'Selecciona una de las opciones de repactación disponibles',
        subtitle: 'El primer vencimiento será el día 15 del siguiente mes a la fecha del pago del pie.',
        basicPayment: 'Pie',
        totalInstallments: 'Saldo',
        option_installments: ' cuotas de ',
        installment: 'Cuota | Cuotas',
      },
      summary: {
        title: 'Tu resumen',
        total: 'Total',
        dues: 'Con {cardName} abonarás \n {installments} cuotas de {installment_amount} por mes'
      },
      stepper: {
        selection: 'Selección',
        payment: 'Pago',
        confirmation: 'Confirmación'
      },

      coupons: {
        print: 'Imprimir cupón de pago',
        reminder: 'Recuerda que este cupón no constituye pago.',
        instructions: 'Díctale estos números al cajero',
        nextSteps: 'Ahora debes acercarte a una sucursal de {service} y pagar',
        nextSteps1: 'Ahora debes acercarte a una sucursal de',
        nextSteps2: 'y pagar',
        sentOn: 'Enviado el',
        couponNumber: 'Cupón N°:',
        info: 'Ingresa a {service} y encuentra tu sucursal más cercana para realizar el pago.',
        disclaimer: 'El pago no se verá reflejado hasta que se acredite. Ten en cuenta que el cupón tiene una validez de 72 hs. y pueden pasar hasta 2 días hábiles hasta que se acredite. Esto es ajeno a {org}.',

      },

      history: {
        title: 'Historial de pagos',
        description: 'Aquí encontrarás los pagos que has realizado a lo largo de tu carrera.',
        headers: {
          number: 'Número',
          description: 'Descripción',
          date: 'Fecha de pago',
          amount: 'Monto',
          status: 'Estado',
          bills: 'Comprobante',
        }
      },

      labels: {
        how_many_installments: '¿En cuantas cuotas quieres pagarlo?',
        no_pending_payments: 'No tienes pagos pendientes',
        cardTitle: 'Datos de Tarjeta',
        billingTitle: 'Datos de Facturación',
        selectPayment: '¿Cómo prefieres pagar?',
        selectPaymentHelp: 'Si no encontrás tu medio de pago, comunicate con Albert',
        creditcardPayment: 'Pago con tarjeta de crédito 2',
        creditcardBilling: 'Ingresa los datos de Facturación',
        creditcardMultiple: '¿Cuánto vas a pagar con cada tarjeta?',
        creditcardSingle: 'Seleccioná como lo querés pagar',
        creditcardMultipleDescription: 'Ingresa los montos asociados a cada tarjeta',
        creditcardPaymentsDone: 'Mis Pagos Realizados',
        paymentsPending: 'Pendientes de pago',
        paymentsCash: '¿Dónde prefieres pagar?',
        paymentsExpiration: 'Vencimiento',
        creditcardPaid: '¿Qué vas a pagar?',
        paymentInvoice: 'Comprobante de Pago',
        downloadInvoice: 'Descargar Comprobante',
        paymentDate: 'Fecha',
        seeMyPayments: 'Ver mis pagos',
        type: 'Tipo',
        details: 'Detalle',
        paymentMethod: 'Método de Pago',
        paymentLink: 'Pagar',
        back: 'Volver',
        repayment: 'Repactar',
        nextMonthsPayments: 'En los próximos meses',
        promotionsLink: 'ver promociones',
        promotions: 'Promociones',
        creditCard: 'Tarjeta N°',
        tea: 'TEA',
        cft: 'CFT',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        continue: 'Continuar',
        applyAdaptationInsurance: 'Aplicar Seguro de Adaptación',
        nameoncard: 'Titular de la Tarjeta',
        idoncard: 'Número de DNI',
        cardnumber: 'Número de la Tarjeta',
        cardvfcode: 'Código de Seguridad',
        cardexpiration: 'Vencimiento',
        taxSituation: 'Situación fiscal',
        name: 'Nombre completo',
        businessName: 'Razón social',
        state: 'Provincia',
        city: 'Localidad',
        streetaddress: 'Dirección',
        streetnumber: 'Número',
        streetfloor: 'Piso',
        streetapartment: 'Dpto.',
        amount: 'Monto',
        date: 'Fecha de Pago',
        concept: 'Concepto',
        time: 'Período 2',
        period: 'Período',
        no_cash_method: 'Pronto tendrás opciones de pago en efectivo.',
        due: 'Cuota'
      },
      subscription: {
        payment: 'Financiación {org}',
        paymentMethod: 'Medio de pago',
        installments: 'cuotas',
        installment: 'Cuota',
        description: 'Descripción',
        expiration: 'Vencimiento',
        amount: 'Monto',
        adminExpenses: 'Gastos administrativos'
      },
      creditCard:{
        labels:{
          cardName: 'Nombre completo',
          cardHolder: 'Titular de la tarjeta',
          cardExpires: 'Venc.'
        }
      },
      benefit: {
        type: {
          uniqueDiscount: 'Descuento único ',
          referedFriend: 'Amigo referido',
        }
      },
    },
    errorsMP:{
        cc_rejected_bad_filled_card_number: 'Número de Tarjeta incorrecto, revisa y volve a intentarlo.',
        cc_rejected_bad_filled_date: 'Fecha de vencimiento incorrecta, revisa y volve a intentarlo.',
        cc_rejected_bad_filled_other: 'Alguno de los datos indicados es incorrecto, revisa y volve a intentarlo.',
        cc_rejected_bad_filled_security_code: 'Código de seguridad incorrecto, revisa y volve a intentarlo.',
        cc_rejected_blacklist: 'No pudimos procesar tu pago con esta tarjeta. Elige otra tarjeta u otro medio de pago.',
        cc_rejected_call_for_authorize: 'Tu banco solicita que te comuniques para pedir autorización, comunicate al teléfono que está al dorso de tu tarjeta.',
        cc_rejected_card_disabled: 'Tu tarjeta no esta habilitada. Para habilitarla comunicate al teléfono que está al dorso de tu tarjeta.',
        cc_rejected_card_error: 'No pudimos procesar tu pago. Comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
        cc_rejected_duplicated_payment: 'Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
        cc_rejected_high_risk: 'Tu pago fue rechazado. Elige otra tarjeta u otro medio de pago.',
        cc_rejected_insufficient_amount: 'Tu banco rechazo la operación por fondos insuficientes. Por favor comunicate con tu banco al teléfono que está al dorso de tu tarjeta.',
        cc_rejected_invalid_installments: 'Tu banco no procesa pagos en ese numero de cuotas. Revisa el numero de cuotas y volver a intentarlo.',
        cc_rejected_max_attempts: 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
        cc_rejected_other_reason: 'No pudimos procesar tu pago. Por favor comunicate con tu banco al teléfono que está al dorso de tu tarjeta.'
    },
    checkingAccount: {
      account: {
        breadcrumb: {
          title: 'Cuenta corriente'
        }
      },
      details: {
        breadcrumb: {
          title: 'Detalle de cuenta corriente'
        }
      },
      teclabCredits: {
        breadcrub: {
          title: 'Creditos TECLAB'
        }
      }
    }
  },
  en: {
    view: {
      summary: {
        title: 'Summary',
        total: 'Total'
      },
      stepper: {
        selection: 'Selection',
        payment: 'Payment',
        confirmation: 'Confirmation'
      },

      coupons: {
        print: 'Print payment coupon',
        reminder: 'Remember that this coupon does not represent a payment.',
        instructions: 'Tell the following numbers to the cashier',
        nextSteps1: 'Now you should go to a branch of',
        nextSteps2: 'and pay',
        sentOn: 'Sent on',
        couponNumber: 'Coupon #:',
        info: 'Please enter Servipag.com and find the closest branch to yur location and make the peyment.',
        disclaimer: 'Payment will not be visible until authorized. Please have in mind that coupons are valid for 72 hours and it may take up to 2 working for them to be authorized. This issue is external to {org}.',

      },

      labels: {
        selectPayment: 'How would you like to pay?',
        creditcardPayment: '2 credir cards payment',
        creditcardBilling: 'Please enter your billing information',
        creditcardMultiple: 'How much are you going to pay with each card?',
        creditcardSingle: 'Which card are you going to pay with?',
        creditcardMultipleDescription: 'Please enter the amounts below',
        creditcardPaymentsDone: 'My previous payments',
        paymentsPending: 'Pending payments',
        paymentsCash: 'Where would you like to pay?',
        paymentsExpiration: 'Expiration date',
        creditcardPaid: 'What are you going to pay?',
        paymentInvoice: 'Payment invoice',
        downloadInvoice: 'Download invoice',
        paymentDate: 'Date',
        seeMyPayments: 'See my payments',
        type: 'Type',
        details: 'Details',
        paymentMethod: 'payment Method',
        paymentLink: 'pay',
        nextMonthsPayments: 'Next months´ payments' ,
        promotionsLink: 'see promotions',
        creditCard: 'Card #',
        tea: 'TEA',
        cft: 'CFT',
        cancel: 'Cancel',
        confirm: 'Confirm',
        nameoncard: 'Name on Card',
        idoncard: 'ID. Number',
        cardnumber: 'Card Number',
        cardvfcode: 'CVC',
        cardexpiration: 'Expiration',
        financialstatus: 'Financial status',
        name: 'Name',
        state: 'State',
        city: 'City',
        streetaddress: 'Street name',
        streetnumber: 'Street number',
        streetfloor: 'Floor',
        streetapartment: 'Dept.',
        amount: 'Amount',
        date: 'Payment Date',
        concept: 'In behalf of',
        time: 'Period 2',
        period: 'Period'
      },
      subscription: {
        payment: '{org} financing',
        paymentMethod: 'Payment method',
        installments: 'installments',
        installment: 'Installment',
        description: 'Description',
        expiration: 'Expiration',
        amount: 'Amount',
        adminExpenses: 'Setup fee'
      }
    }
  }
};


export default paymentsTranslations
