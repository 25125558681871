<template>
  <v-card class="pa-0" height="min-content">
    <v-toolbar
      dark
      class="px-2"
      color="primary"
    >
      <v-toolbar-title class="font-weight-bold">
        {{ summaryUpdate ? $t('modules.admission.preregistration.update.summary.title', {admissionTerm})  : $t('modules.admission.preregistration.create.summary.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid class="pa-4">
      <div class="px-2">
       <template v-if="total.prices.length">
          <div class="d-flex justify-space-between py-1 text-body-2" v-for="price in total.prices" :key="`summary-${price.key}`">
            <span>{{ price.name }}</span>
            <span>{{ price.amount | $currency }}</span>
          </div>
       </template>
        <template v-else>
          <div class="d-flex justify-space-between py-1 text-body-2">
            <span>{{ $t('modules.admission.preregistration.create.summary.enrollmentPlaceholder') }}</span>
            <span>— — —</span>
          </div>
          <div class="d-flex justify-space-between py-1 text-body-2">
            <span>{{ $t('modules.admission.preregistration.create.summary.tariffPlaceholder') }}</span>
            <span>— — —</span>
          </div>
        </template>
        <template v-if="total.saving">
          <template v-for="saving in total.savings">
            <template v-if="saving.referredFriend">
              <div class="primary--text d-flex justify-space-between py-1 text-body-2" :key="`summary-${saving.key}`">
                <span v-if="saving.amount" class="saving-max-width">{{ saving.name }}</span>
                <span v-if="saving.amount" class="text-no-wrap">{{ saving.amount | $currency }}</span>
              </div>
            </template>
            <template v-else-if="saving.amount">
              <div class="success--text d-flex justify-space-between py-1 text-body-2" v-for="(detail, index) in saving.details" :key="`${index}summary-${saving.key}`">
                <span class="saving-max-width">{{ `${detail.name} ${detail.percentage ? `${detail.percentage}% Off`: ''}` }}</span>
                <span class="text-no-wrap">{{ detail.amount | $currency }}</span>
              </div>
            </template>
          </template>
        </template>
      </div>
      <v-alert
        dense
        dark
        color="#77AEFF"
        class="px-2 my-3"
      >
        <div class="d-flex justify-space-between text-h6">
          <span>{{ $t('modules.admission.preregistration.create.summary.total') }}</span>
          <span v-if="total.cost">{{ total.cost + total.saving | $currency }}</span>
          <span v-else>— — —</span>
        </div>
      </v-alert>
      <v-alert
        v-if="total.saving"
        dense
        text
        color="success"
        class="px-2 my-3"
      >
        <div class="d-flex justify-space-between text-body-2">
          <span>{{ $t('modules.admission.preregistration.create.summary.totalSaving') }}</span>
          <span>{{ -total.saving | $currency }}</span>
        </div>
      </v-alert>
      <v-sheet
        v-if="(($isTeclab && total.saving) || dues) && !summaryUpdate"
        outlined
        rounded
        dark
        color="#67C1B8"
        class="pa-4 text-center text-body-2 line-break"
      >
        <span v-if="dues">{{ $t('modules.admission.preregistration.create.summary.dues', {cardName: dues.creditCard ? dues.creditCard.name : (dues.type.meaning || 'Pronto Pago'), installments: dues.due ? dues.due.installments : dues.value, installment_amount: dues.due ? $options.filters.$currency(dues.due.installment_amount) : $options.filters.$currency(dues.amount)}) }} <br> {{ dues.adminExpenses ? $t('modules.admission.preregistration.create.summary.duesAdminExpenses', {adminExpenses: $options.filters.$currency(dues.adminExpenses)}) : '' }} <br> </span>
        <v-divider v-if="prepaids.length || ($isTeclab && total.dues)" class="ma-2"></v-divider>
        <template v-if="prepaids.length">
          <span v-for="prepaid in prepaids" :key="`summary-${prepaid.dayFrom}.${prepaid.dayTo}.${prepaid.id}`">{{ $t('modules.admission.preregistration.create.summary.prepaids', {dayFrom: prepaid.dayFrom, dayTo: prepaid.dayTo, saving: $options.filters.$currency(total.cost + total.saving - ((dues.due ? dues.due.installments : dues.value) * (((100 - prepaid.percentage) * (dues.due ? dues.due.installment_amount : dues.amount)) / 100)))}) + '\n' }}</span>
        </template>
        <span v-if="$isTeclab && total.saving">{{ $t('modules.admission.preregistration.create.summary.totalSavingToday', {totalSaving: $options.filters.$currency(-total.saving)}) }}</span>
      </v-sheet>
      <div v-if="total.prices.length && !summaryUpdate" class="d-flex flex-column pa-2">
        <span v-if="dues" class="text-caption pb-1">{{ `CFT_${dues.resumen ? dues.resumen.cft : '0%'} TEA_${dues.resumen ? dues.resumen.tea : '0%'}` }}</span>
        <span class="text-body-2">{{ $t('modules.admission.preregistration.create.summary.approval', {theme: !$isTeclab ? 'IPP' : 'Teclab'}) }}</span>
        <span class="text-body-2">{{ $t('modules.admission.preregistration.create.summary.validity', {todayDate}) }}</span>
      </div>
      <div>
        <Button
          :text="$t('modules.admission.preregistration.create.summary.buttons.sendSummary')"
          icon="mdi-email"
          :disabled="!total.prices.length"
          block
          class="py-2"
          @clicked="$emit('showEmailSummary')"
        ></Button>
        <Button
          :text="$t('modules.admission.preregistration.create.summary.buttons.sendPaymentLink')"
          icon="mdi-send"
          :disabled="!isCreated"
          :loading="paymentLinkButton.loading"
          :success="paymentLinkButton.success"
          :error="paymentLinkButton.error"
          :successText="$t('actions.created')"
          :errorText="$t('actions.error')"
          block
          class="py-2"
          @clicked="$emit('sendPaymentLink')"
        ></Button>
<!--        <Button-->
<!--          text="Pago online"-->
<!--          icon="mdi-credit-card-outline"-->
<!--          disabled-->
<!--          block-->
<!--          class="py-2"-->
<!--          @clicked="$router.replace('/academic/banners')"-->
<!--        ></Button>-->
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Button from '@/components/Button/Button';
import moment from 'moment';

export default {
  name: 'Summary',
  components: {
    Button
  },
  props: {
    prepaids: Array,
    dues: Object,
    total: Object,
    summaryUpdate: { type: Boolean, default: false },
    admissionTerm: String,
    isCreated: { type: Boolean, default: false },
    paymentLinkButton: { type: Object, default: null }
  },
  data() {
    return {
      todayDate: moment().format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="sass" scoped>

.line-break
  white-space: pre-line
  line-height: 1.7

.saving-max-width
  max-width: 65%

</style>
