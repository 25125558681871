<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.portalalumno.procedures.reEnrollmentStatus.anexo.title')" :callback="goBack"></Breadcrumbs>
      <v-row justify="center" v-if="!finishConfirm">
        <v-col class="viewSpaces" cols="12">
          <v-row justify="center">
            <v-col sm="12" cols="12" v-if="loading" class="d-flex mt-6">
              <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
            </v-col>
            <v-col cols="10" v-else>
              <DocumentPreview :title="$t('modules.portalalumno.procedures.reEnrollmentStatus.anexo.title')" :src="fileSource"/>         
            </v-col>
          </v-row>
          <v-row justify="center" v-if="!loading" class="mt-4 mb-8">
            <v-col cols="10" align="end">
              <v-btn @click="confirmAnexo" :loading="confirmStatus.loading" color="primary" rounded large>{{$t('modules.portalalumno.procedures.reEnrollmentStatus.anexo.sign')}}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      ></SuccessMessage>
    </v-container>
  </v-container>
</template>

<script>
  import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { $students, $productWithPrice, $discounts, $reEnrollment, $benefits, $scholarships } from '../../services';
  import { mapGetters } from 'vuex'

  export default {
    name: 'ContractSign',
    components: {
      DocumentPreview,
      Breadcrumbs,
      SuccessMessage,
    },
    data() {
      return {
          fileSource: '',
          loading: false,
          approved: false,
          reEnrollmentCase: null,
          confirmStatus: {
            loading: false,
            error: false,
            success: false,
          },
          successMessage: {
            type: null,
            title: null,
            actionPrimary: null,
          },
          discounts: [],
          total: null,
          installments: 5,
      }
    },
    computed: {
      finishConfirm(){
        return this.confirmStatus.error || this.confirmStatus.success
      },
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentData: 'commons/getStudentData',
        studentUserId: 'commons/getStudentUserID',
        userId: 'commons/getUserID',
      }),
    },
    mounted() {
      this.initialize();
    },
    methods: {
      async initialize(){
        this.loading = true;
        
        this.approved = this.$route.params.approved
        if (this.studentData.benefit && this.approved) this.reEnrollmentCase = 'benefit'
        else if (this.studentData.scholarship && this.approved) this.reEnrollmentCase = 'scholarship'
        else this.reEnrollmentCase = 'prontoPago'
        
        const ARANCELID = this.$sisConstants('modules.products.arancel');
        const totalDue = await this.getProducts(ARANCELID)
        this.total = totalDue

        try {
          const discount = await this.getDiscounts(ARANCELID)
          if (discount) {
            this.discounts.push({
              discount : { id : discount.id },
              discountAmount : totalDue * discount.details[0].bianualPercentage / 100,
            })
          }
        } catch { this.discounts = [] }

        if (this.reEnrollmentCase === 'benefit'){
          // eslint-disable-next-line no-useless-catch
          try {
            const benefit = await this.getBenefits(this.studentData.benefit.id)
            if (benefit) {
              this.discounts.push({
                benefit: { id: this.studentData.benefit.id },
                benefitAmount: totalDue * benefit.percentage / 100,
              })
            }
          } catch (error) { throw error }
        } else if (this.reEnrollmentCase === 'scholarship'){
          // eslint-disable-next-line no-useless-catch
          try {
            const scholarship = await this.getScholarships(this.studentData.scholarship.id, {average: this.studentData.average})
            if (scholarship) {
              this.discounts.push({
                scholarship: { id: this.studentData.scholarship.id },
                scholarshipAmount: totalDue * scholarship.percentage / 100,
              })
            }
          } catch (error) { throw error }
        }

        try {
          const params = {}
          if (this.reEnrollmentCase == 'benefit') params.benefit = this.studentData.benefit.id
          else if (this.reEnrollmentCase == 'scholarship') params.scholarship = this.studentData.scholarship.id

          const response = await $students.create({ paymentAmount : this.total, paymentInstallments : this.installments , userId: this.userId}, { params }, `contractReenrollment/${this.studentRegister}`);
          this.fileSource = response.data;
          this.loading = false;
        } catch (error) {
          this.fileSource = ''
          throw error
        }
      },
      async getProducts(id) {
        const products = await $productWithPrice.find(null, null, { params: {
          careerId: this.studentData.career.id,
          cityId: this.studentData.city.id,
          student: this.studentData.student.id,
          studentType: 8,
        }})

        let total = 0
        products.data.forEach(product => {
          if (product.id === id) total += product.amount
        })
        
        return total
      },
      async getDiscounts(id) {
        try{
          const discounts = await $discounts.find('date', null, { params: {
            cityId: this.studentData.city.id,
            idCareer: this.studentData.career.id,
            idProduct: id,
            studentType: 8,
          }})
          return discounts.data
        } catch {
          return 0
        }
      },
      async getBenefits(id) {
        try{
          const benefit = await $benefits.find(`${id}/student`)
          return benefit.data.details[0]
        } catch {
          return 0
        }
      },
      async getScholarships(id, average) {
        try{
          const scholarship = await $scholarships.create(average, null, `${id}/student`)
          return scholarship.data.scholarshipProduct[0]
        } catch {
          return 0
        }
      },
      goBack() {
        this.$router.push('renewalStatus')
      },
      async confirmAnexo () {
        try {
          this.confirmStatus.loading = true
          const arancelObject = {
            studentCareer: this.studentRegister,
            products:[
              {
                billingProduct:{ id: this.$sisConstants('modules.products.arancel') },
                amount: this.total,
                installmentAmount: this.total / this.installments,
                installment: this.installments,
              }
            ],
            discounts : this.discounts,
            benefit: (this.studentData.benefit && this.approved) ? {id: this.studentData.benefit.id} : null,
            scholarship: (this.studentData.scholarship && this.approved) ? {id: this.studentData.scholarship.id} : null,
            user: this.studentUserId ? this.studentUserId : null
          }

          await $reEnrollment.create(arancelObject);
          this.$router.replace('/payments')

        } catch {
          this.successMessage.type = 'error'
          this.successMessage.title = this.$t('modules.portalalumno.procedures.reEnrollment.createError');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.confirmStatus.error = false; this.confirmStatus.success = false; } }
          this.confirmStatus.error = true;
          this.confirmStatus.loading = false
        }
      }
    }
  }
</script>
