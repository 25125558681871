<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="filterChanged"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs
            :title="$t('modules.examTitleNotes.table.breadcrumbs.title')"
            :description="$t('modules.examTitleNotes.table.breadcrumbs.description')"
          >
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :data="users"
                :pageCount="pagination.pagesAmount"
                :page="pagination.currentPage"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                :totalItems="totalItems"
                :permissions="{ update: canUpdate, create: canCreate }"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                @searchAction="searchAction"
                @createOpen="tmpDrawerCreateOpen"
                @updateOpen="tmpDrawerUpdateOpen"
              ></SuperTable>
            </v-col>
          </v-row>

          <TemporaryRightDrawer
            v-if="canCreate"
            @tmpDrawerOpen="tmpDrawerCreateOpen"
            :open="tmpDrawerCreate.open"
            :title="tmpDrawerCreate.title"
            :description="tmpDrawerCreate.description"
          >
            <ExamTitlesNotesCreate
              :isOpen="tmpDrawerCreate.open"
              @closeDrawer="onCloseCreateDrawer"
              :selectedExam="selectedExam"
            ></ExamTitlesNotesCreate>
          </TemporaryRightDrawer>
          
          <TemporaryRightDrawer
            v-if="canUpdate"
            @tmpDrawerOpen="tmpDrawerUpdateOpen"
            :open="tmpDrawerUpdate.open"
            :title="tmpDrawerUpdate.title"
            :description="tmpDrawerUpdate.description"
          >
            <ExamTitlesNotesUpdate
              :isOpen="tmpDrawerUpdate.open"
              @closeDrawer="onCloseUpdateDrawer"
              :selectedExam="selectedExam"
            ></ExamTitlesNotesUpdate>
          </TemporaryRightDrawer>

        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import ExamTitlesNotesCreate from '../Views/ExamTitlesNotesCreate';
import ExamTitlesNotesUpdate from '../Views/ExamTitlesNotesUpdate';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import AsideBar from '@/components/AsideBar/AsideBar';
import { $examsTitle } from '../Services';

export default {
  components: {
    SuperTable,
    Breadcrumbs,
    TemporaryRightDrawer,
    ExamTitlesNotesCreate,
    ExamTitlesNotesUpdate,
    AsideBar
  },
  data () {
    return {
      search: '',
      users: [],
      totalItems: 0,
      selectedExam: {},
      tmpDrawerCreate: {
        open: false,
        title: this.$t('modules.examTitleNotes.create.breadcrumbs.title'),
        description: this.$t('modules.examTitleNotes.create.breadcrumbs.description')
      },
      tmpDrawerUpdate: {
        open: false,
        title: this.$t('modules.examTitleNotes.update.breadcrumbs.title'),
        description: this.$t('modules.examTitleNotes.update.breadcrumbs.description')
      },
      pagination: {
        limit: 20,
        page: 1,
        currentPage: 1,
        pagesAmount: 1
      },
      headers: [
        {
          text: this.$t('modules.examTitleNotes.table.headers.name'),
          value: 'name'
        },{
          text: this.$t('modules.examTitleNotes.table.headers.lastname'),
          value: 'lastname',
        },{
          text: this.$t('modules.examTitleNotes.table.headers.identification'),
          value: 'identification',
          sortable: false
        },{
          text: this.$t('modules.examTitleNotes.table.headers.career_id'),
          value: 'careerCode',
          sortable: false
        },{
          text: this.$t('modules.examTitleNotes.table.headers.career'),
          value: 'carrear',
          sortable: false
        },{
          text: this.$t('modules.examTitleNotes.table.headers.date'),
          value: 'date',
          sortable: false,
          align:'center',
        },{
          text: this.$t('modules.examTitleNotes.table.headers.act'),
          value: 'act',
          align:'center',
          sortable: false
        },{
          text: this.$t('modules.examTitleNotes.table.headers.grade'),
          value: 'grade',
          align:'center',
          sortable: false
        },{
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      asidebar: {
        title: 'Refinar búsqueda',
        items: [
          {
            name: this.$t('modules.examTitleNotes.table.asidebar.notas'),
            filters: [
              {
                name: this.$t('modules.examTitleNotes.table.asidebar.alumnos_sin_nota'),
                type: 'check',
                value: true,
                dataFiltered: 0
              },{
                name: this.$t('modules.examTitleNotes.table.asidebar.alumnos_con_nota'),
                type: 'check',
                value: true,
                dataFiltered: 0
              },
            ]
          },{
            name: this.$t('modules.examTitleNotes.table.asidebar.desde'),
            filters: [
              {
                type: 'date',
                name: this.$t('modules.examTitleNotes.table.asidebar.desde'),
                value: null,
                isCalendarOpen: false,
              },
            ]
          },{
            name: this.$t('modules.examTitleNotes.table.asidebar.hasta'),
            filters: [
              {
                type: 'date',
                name: this.$t('modules.examTitleNotes.table.asidebar.hasta'),
                value: null,
                isCalendarOpen: false,
              },
            ]
          },
        ]
      },
      sort: {
        sortBy: 'name',
        sortDesc: false,
      },
    }
  },
  methods: {
    tmpDrawerCreateOpen (item = null) {
      if(item) this.selectedExam = item;
      this.tmpDrawerCreate.open = !this.tmpDrawerCreate.open;
      if (!this.tmpDrawerCreate.open) this.fetchData();
    },
    onCloseCreateDrawer() {
      this.tmpDrawerCreate.open = false;
      this.fetchData()
    },
    tmpDrawerUpdateOpen (item = null) {
      if(item) this.selectedExam = item
      this.tmpDrawerUpdate.open = !this.tmpDrawerUpdate.open;
      if (!this.tmpDrawerUpdate.open) this.fetchData();
    },
    onCloseUpdateDrawer() {
      this.tmpDrawerUpdate.open = false;
      this.fetchData()
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.pagination.currentPage = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.fetchData();
    },
    searchAction(data) {
      this.search = data;
      this.fetchData();
    },
    filterChanged(parent, filter, value){
      this.asidebar.items[parent].filters[filter].value = value;
      this.fetchData();
    },
    async fetchData () {
      const params = {
        page: this.pagination.page - 1,
        length: this.pagination.limit,
        // orderBy: sortCol,
        // orientation: this.sort.sortDesc ? 'desc' : 'asc',
      }

      const reqBody = {
        from : this.asidebar.items[1].filters[0].value ? this.asidebar.items[1].filters[0].value : null,
        to : this.asidebar.items[2].filters[0].value ? this.asidebar.items[2].filters[0].value : null,
        noScore : this.asidebar.items[0].filters[0].value,
        score : this.asidebar.items[0].filters[1].value,
        search : this.search
      }

      try {
        const users = await $examsTitle.filterExams(reqBody, { params });
        this.totalItems = users.data.totalElements;
        this.pagination.pagesAmount = users.data.totalPages;

        this.asidebar.items[0].filters[1].dataFiltered = 0;
        this.asidebar.items[0].filters[0].dataFiltered = 0;
        this.users = users.data.content.map((item, index) => {

          if(item.exam && item.exam.score) this.asidebar.items[0].filters[1].dataFiltered++;
          else this.asidebar.items[0].filters[0].dataFiltered++;
          
          return {
            table_id: item.exam ? item.exam.id : index,
            id: item.idStudentSubject,
            idStudentCareer: item.idStudentCareer,
            name: item.studentName,
            lastname: item.studentLastname,
            careerCode: item.careerCode,
            identification: item.studentIdentification,
            carrear: item.careerName,
            date: item.exam ? item.exam.date : '-',
            act: item.exam?.act,
            grade: item.exam ? item.exam.score : null,
            id_exam: item.exam ? item.exam.id : null,
            description: item.exam ? item.exam.description : null,
          }
        });
      } catch (error) {
        this.users = [];
      }
    }
  },
  mounted () {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.UPDATE
    },
  },
  watch: {
    sort: {
      handler(){
        this.fetchData();
      },
      deep: true
    }
  }

}
</script>
