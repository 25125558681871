<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="px-0">
            <div class="px-3">
                <ImageBanner
                    src="images/portalalumno/reprogramacion.png"
                    alt="Reprogramación"
                    :title="$t('modules.portalalumno.payments.paymentsStudents.view.repayment.banner')"
                    textColor="#910012"
                    backgroundColor="#fff4c9"
                ></ImageBanner>

                <v-row class="my-4">
                    <v-col cols="12">
                        <div class="d-flex flex-row align-center">
                            <div style="flex: 1">
                                <h4 class="red-text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.debt_title') }}</h4>
                                <h4 class="d-sm-none red-text">{{ debt | $currency }}</h4>
                                <h6 class="body-1 text-muted">{{ installments }}</h6>
                            </div>
                            <h4 class="d-none d-sm-block red-text">{{ debt | $currency }}</h4>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="my-4">
                    <v-col cols="12 py-0">
                        <h5 class="title primary--text my-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.title') }}</h5>
                        <p class="body-1 mb-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.subtitle') }}</p>
                    </v-col>
                </v-row>

                <v-row class="my-4">
                    <v-col cols="12" class="text-center" v-if="loading">
                        <v-progress-circular color="primary" indeterminate />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-for="option in options" :key="option.id">
                        <div class="reprogram-card pa-4" :class="{'active': selectedOption && selectedOption.id == option.id}" @click="selectOption(option)">
                            <div class="option-container py-1 align-center">
                                <h6>{{ option.description }}</h6>
                                <div class="option-payment">
                                    <div class="old-price" v-if="option.percentageDiscount">{{ debt | $currency }}</div>
                                    <div class="money-value text-nowrap">{{ option.finalAmount | $currency }}</div>
                                    <div class="discount-value" v-if="option.percentageDiscount">{{ `${option.percentageDiscount}% off`  }} </div>
                                </div>
                            </div>
                            <v-divider class="my-4"></v-divider>
                            <div class="d-flex flex-row align-center summary-row">
                                <h6 class="caption my-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.basicPayment') }}</h6>
                                <h6 class="caption my-0 summary-currency">{{ option.basicToPayAmount | $currency }}</h6>
                            </div>
                            <div class="d-flex flex-row align-center summary-row">
                                <h6 class="caption my-0">{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.totalInstallments') }}</h6>
                                <h6 class="caption my-0 summary-currency">{{ option.totalInstallmentsAmount | $currency }}</h6>
                            </div>
                            <p class="summary-installments mb-0 mt-1">{{ option.installments }}{{ $t('modules.portalalumno.payments.paymentsStudents.view.repayment.option_installments') }}{{ option.installmentAmount | $currency }}</p>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-container>
</template>

<script>
    import ImageBanner from '@/components/Banner/ImageBanner';
    import { $repayments } from '../../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'Repayments',
        props: {
            resumen: Object,
            summaryButtons: Object,
        },
        components: {
            ImageBanner,
        },
        data() {
            return {
                debt: null,
                loading: true,
                options: [],
                selectedOption: null,
                accountList: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        computed: {
            installments() {
                let str = `${this.$tc('modules.portalalumno.payments.paymentsStudents.view.repayment.installment', this.resumen.items.repayments.items.length)} `
                this.resumen.items.repayments.items.forEach((payment, index) => {
                    if (index != 0) str = `${str} - ${payment.title}`
                    else str = str + payment.title
                });
                return str
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserId: 'commons/getStudentUserID'
            }),
        },
        methods: {
            async fetchData() {
                try {
                    this.loading = true
                    this.debt = JSON.parse(JSON.stringify(this.resumen.total))
                    this.accountList = this.resumen.items.subtotal.items.map(item => {return {id: item.id}})
                    const resp = await $repayments.findById(this.studentRegister, 'options', {params: {amountDebt: this.debt}})
                    this.options = resp.data.studentRepaymentOptions
                    this.options.sort((a, b) => a.percentageDiscount - b.percentageDiscount);
                } catch (error) {
                    this.options = []
                    this.selectedOption = null
                    throw error
                } finally {
                    this.loading = false
                }
            },
            async confirm() {
                if (this.selectedOption == null) return false
                try {
                    this.summaryButtons.primary.loading = true
                    this.summaryButtons.secondary.disabled = true

                    const resp = await $repayments.create({
                        repaymentId: this.selectedOption.id,
                        studentCareerId: this.studentRegister,
                        amountDebt: this.debt,
                        studentAccountList: this.accountList,
                        user: this.studentUserId ? this.studentUserId : null
                    })
                    this.resumen.items.subtotal.items[0].id = resp.data.id

                    this.summaryButtons.primary.success = true
                    this.$emit('continue')
                } catch (error) {
                    this.summaryButtons.primary.error = true
                    throw error
                } finally {
                    this.summaryButtons.primary.loading = false
                    this.summaryButtons.secondary.disabled = false
                }
            },
            selectOption(option) {
                this.selectedOption = option
                this.summaryButtons.primary.disabled = false
                this.resumen.items.subtotal.items = [{
                    id: option.id,
                    monto: option.basicToPayAmount,
                    title: this.$t('modules.portalalumno.payments.paymentsStudents.view.repayment.basicPayment')
                }]
                this.resumen.items.subtotal.monto = option.basicToPayAmount
                this.resumen.total = option.basicToPayAmount
            },
        },
    }
</script>

<style lang="sass" scoped>

    .red-text
        color: #f72b2b !important

    .reprogram-card
        font-family: 'Roboto'
        font-size: 12px
        font-weight: bold
        border-radius: 5px
        box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
        border: solid 1px #d9d9d9
        background-color: white

        &:hover
            box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
            border: solid 1px #0036b1
            cursor: pointer

        &.active
            background-color: #d9e2fa
            border: solid 1px #6087e1

        .option-container
            display: flex
            flex-direction: row
            min-height: 70px

        .option-payment
            flex: 1
            text-align: right

            .old-price
                line-height: 1.21
                color: rgba(33, 33, 33, 0.5)
                text-decoration: line-through

            .money-value
                font-family: 'DM Sans'
                font-size: 18px
                line-height: 1.33
                letter-spacing: -0.54px
                // color: #012169

            .discount-value
                line-height: 1.17
                color: #2d9400

        .summary-row

            .summary-currency
                flex: 1
                text-align: right

        .summary-installments
            text-align: right
            font-size: 11px !important
            color: rgba(33, 33, 33, 0.5) !important

</style>
