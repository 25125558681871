<template>
    <div class="regularised-subjects__content d-flex align-center justify-center py-2">
        <v-progress-circular v-if="loading" indeterminate color="#ccc"></v-progress-circular>
        <div v-else-if="!subjects.length" class="text-center grey--text text--darken-2">
            <v-icon class="mr-3">mdi-inbox</v-icon>
            <span>{{ $t('modules.portalalumno.dashboard.subjectsTab.regular.noSubjects') }}</span>
        </div>
        <div class="regularised-subjects__content-grid pl-3" v-else>
            <div class="d-flex table-sizing">
                <div></div>
                <div class="caption text-center">{{ $t('modules.portalalumno.dashboard.subjectsTab.regular.regular') }}</div>
                <div class="caption text-center">{{ $t('modules.portalalumno.dashboard.subjectsTab.regular.expiration') }}</div>
            </div>
            <div class="regularised-subjects__content-grid-row table-sizing d-flex py-2" v-for="(subject,idx) in subjects" :key="`regularised-${idx}`">
                <div class="subject-name primary--text font-weight-bold">{{ subject.name }}</div>
                <div class="caption text-center align-self-center">{{ subject.regularised }}</div>
                <div class="caption text-center align-self-center">{{ subject.dueDate }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { $materias } from '../Services';
    import { mapGetters } from 'vuex';

    export default {
        name: 'regularisedSubjects',
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            subjects(){
                if (this.cacheSubjects) {
                    return this.cacheSubjects.map(subject => {
                        return {
                            name: subject.descripcion,
                            regularised: moment(subject.vigenciaDesde, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                            dueDate: moment(subject.vigenciaHasta, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                        }
                    });
                } else return []
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                cacheSubjects: 'commons/getStudentRegularisedSubjects',
            }),
        },
        mounted(){
            if (!this.cacheSubjects) this.getData();
        },
        methods:{
            async getData(){
                try {
                    this.loading = true
                    const response = await $materias.find('regulares', null, {params: { leg: this.studentRegister }})
                    await this.$store.dispatch('commons/setStudentRegularisedSubjects', response.data.regularesDTO);
                } catch(err) {
                    this.$store.dispatch('commons/setStudentRegularisedSubjects', []);
                    throw err
                } finally {
                    this.loading = false;
                }
            },
        },
    }
</script>

<style lang="sass" scoped>

  .table-sizing 
    div:nth-child(1)
      width: 64%
    div:nth-child(2)
      width: 18%
    div:nth-child(3)
      width: 18%

  .regularised-subjects
    &__content
        width: 100%
        max-height: 254px
        min-height: 250px
        &-grid
          height: 100%
          max-height: 100%
          width: 100%
          overflow-y: scroll
          &-row
            border-bottom: 1px solid #ddd
  @media only screen and (max-width: 600px) 
    
    .table-sizing 
      div:nth-child(1)
        width: 52%
      div:nth-child(2)
        width: 24%
      div:nth-child(3)
        width: 24%

    .regularised-subjects__content
        &-grid-row .subject-name
            font-size: 13px
        .caption
            font-size: 10px !important
</style>