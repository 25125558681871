<template>
  <v-dialog :value="open" @input="closeDialog" max-width="600px" class="dialog">
    <v-card>
      <v-card-title>{{ $t('modules.portalalumno.dashboard.referFriend.title') }}</v-card-title>
      <v-card-text>{{ $t('modules.portalalumno.dashboard.referFriend.subtitle') }}</v-card-text>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="selectedOption" class="ml-5 mt-0" hide-details>
              <v-radio value="whatsapp">
                <template v-slot:label>
                  <span class="d-flex align-center"><v-icon class="mx-2">mdi-whatsapp</v-icon> Whatsapp</span>
                </template>
              </v-radio>
              <v-radio value="email" class="my-2">
                <template v-slot:label>
                  <span class="d-flex align-center"><v-icon class="mx-2">mdi-email-outline</v-icon> Mail</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="8" v-if="selectedOption == 'email'">
            <p class="body-2 ml-5 mb-2">{{ $t('modules.portalalumno.dashboard.referFriend.email_subtitle') }}</p>
            <v-text-field
              class="ml-5"
              :error-messages="emailError"
              outlined
              :label="$t('modules.portalalumno.dashboard.referFriend.email')"
              v-model="$v.email.$model"
              @blur="$v.email.$touch()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end px-4 py-6">
        <Button :text="$t('actions.cancel')" white @clicked="closeDialog" class="mr-4" />
        <Button
          class="mr-3"
          :loading="shareButton.loading"
          :success="shareButton.success"
          :error="shareButton.error"
          :text="$t('modules.portalalumno.dashboard.referFriend.share')"
          :successText="$t('actions.success')"
          :errorText="$t('actions.error')"
          @end="resetButtonValues"
          @clicked="share"
        ></Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { $referir, $student } from '../Services'  // eslint-disable-line

export default {
  name: 'ReferFriendModal',
  props: {
    open: { type: Boolean, default: false },
    token: { type: String, default: null },
  },
  components: {
    Button,
  },
  data() {
    return {
      loading: false,
      email: null,
      selectedOption: null,
      shareButton: {
        loading: false,
        success: false,
        error: false,
      }
    }
  },
  methods: {
    closeDialog () {
      if (this.open) {
        this.clearData();
        this.$emit('close');
      }
    },
    async share() {
      if (this.selectedOption == 'email') {
        this.$v.$touch()
        if (this.$v.$invalid) return false
      }

      try {
        this.shareButton.loading = true

        const { data } = await $student.findById(this.studentId, 'token')

        if (this.selectedOption == 'whatsapp') {
          
          const uri = this.$vuetify.breakpoint.xsOnly ? 'whatsapp://send?text=' : 'https://wa.me/?text='
          window.open(`${uri}${data.url}`, '_blank')

        } else if (this.selectedOption == 'email') {

          await $referir.create({
            to: this.email,
            from: this.$options.filters.$capitalizeAll(this.studentUserData.name),
            token: data.url
          })

          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.dashboard.referFriend.email_sent'),
            actionPrimary: { text: this.$t('actions.accept'), callback () {} },
            icon: { color: 'success', name: 'mdi-check' },
            color: 'primary',
          })
        }

        this.shareButton.success = true
        this.closeDialog()

      } catch (error) {
        this.shareButton.error = true
        throw error
      } finally {
        this.shareButton.loading = false
      }
    },
    clearData() {
      this.email = null;
      this.selectedOption = null
      this.resetButtonValues()
      this.$v.$reset()
    },
    resetButtonValues() {
      this.shareButton.loading = false
      this.shareButton.success = false
      this.shareButton.error = false
    },
  },
  computed: {
    ...mapGetters({
      studentId: 'commons/getStudentId',
      studentUserData: 'commons/getStudentUserData',
    }),
    emailError() {
      let error = ''
      if (this.$v.email.$dirty) {
        if (!this.$v.email.required) error = this.$t('modules.portalalumno.dashboard.referFriend.validation.required')
        else if (!this.$v.email.email) error = this.$t('modules.portalalumno.dashboard.referFriend.validation.email')
      }

      return error;
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="sass" scoped>
  .dialog
    width: 400px
    height: 250px
</style>
