const practiceTranslations = {
    es: {
        breadcrumbs: {
            title: 'Práctica Profesionalizante',
        },
        stepper: {
            stepper_one: {
                step_1: 'Requisitos necesarios',
                step_2: 'Sobre la PP',
                step_3: 'Búsqueda de Empresa',
                step_4: 'Gestión del convenio',
                step_5: 'Cursado',
            },
            stepper_mobile: {
                step_1: 'Requisitos',
                step_2: 'Sobre la PP',
                step_3: 'Empresa',
                step_4: 'Convenio',
                step_5: 'Cursado',
            },
            dateStep: {
                requirements: {
                    title: '{name}, la Práctica Profesionalizante es una de las etapas más productivas de la carrera.',
                    subtitle: 'Los estudiantes que aprueban los Exámenes Finales requisito, están habilitados para cursarla. Rendí los que tengas pendientes y preparate.',
                    requirementsList: 'Estos son los requisitos necesarios para comenzar el trámite',
                    startDate: 'Próximo comienzo de cursado',
                    referrer_name: 'Nombre de la empresa o referente',
                    time: 'Tenés tiempo de anotarte hasta el: ',
                    next_step: 'El paso siguiente es dejar los datos de la empresa en la que querés realizar tu práctica.',
                    text: '¿Querés ir adelantando gestiones? Si no tenés organización todavía, descargá la carta de presentación que te servirá para acércate a la empresa y consultar la posibilidad de que te permitan hacer tu PP.',
                    requirements: {
                        title: '8 materias aprobadas.'
                    },
                    cards: {
                        card_models: {
                            title: 'Modelos de convenios',
                            text: 'Accedé a los modelos que convenios que deben firmar todas las empresas (en caso de que no hay uno vigente) que reciben estudiantes que harán su PP'
                        },
                        card_rules: {
                            title: 'Reglamento',
                            text: 'Conocé la reglamentación de la Práctica Profesionalizante y aclará las dudas que tengas sobre ésta etapa académica.',
                            text_2: 'Visualizá el reglamento haciendo click',
                            text_3: 'Dale un vistazo a las preguntas frecuentes de la PP haciendo click'
                        },
                        card_info: {
                            title: 'Espacio informativo',
                            text: '¿Qué es la PP?',
                            textPP: 'La Práctica Profesionalizante (PP), es una materia e instancia académica de carácter obligatorio para todos los alumnos de Teclab. Es un espacio de integración de los contenidos y habilidades claves de la carrera a una realidad y espacio concreto. A través de la Práctica Profesionalizante el alumno accede a una formación integral, permitiéndole reconocer el valor del trabajo como una actividad socialmente productiva, aprendiendo a aprender y a emprender a partir de la resolución de problemas en contextos laborales.',
                            requirements: {
                                title: 'Requisitos',
                                text: 'Para anotarte a la PP I necesitás:',
                                list: {
                                    item_one: 'Aprobar los exámenes finales de 4 materias.',
                                    item_two: 'Estar al día con el pago del bimestre a cursar.',
                                    item_three: 'Para anotarte a la PP II necesitás:',
                                    item_four: 'Aprobar los exámenes finales de 4 materias del segundo año de la carrera.',
                                    item_five: 'Estar al día con el pago del bimestre a cursar.'
                                },
                                first: {
                                    title: 'Estoy al dîa',
                                    subtitle: '¡Excelente!',
                                    text: 'Ahora, solo necesitás anotarte a la materia. Te compartimos el calendario de inscripciones'
                                },
                                second: {
                                    title: 'Me faltan requisitos',
                                    subtitle: 'De acuerdo al Reglamento de PP, no está permitida la inscripción (sin excepciones) sin el cumplimiento previo de los requisitos.¿Qué te está haciendo falta?',
                                    first_item: 'Rendir finales: Aprobá los que te estén haciendo falta y anotate',
                                    second_item: ' Abonar: Pagá lo que tengas pendiente. Si ya abonaste y pensás que se trata de algún error, comunicate con los Coach',
                                    derive: 'Derivar a Asesores',
                                    payments: 'Ir a pagos'
                                }
                            },
                            courses: {
                                title: '¿Cómo se cursa?',
                                subtitle: 'La Práctica Profesionalizante se cursa en un bimestre en el que deben complementarse 150 horas compuestas por:',
                                list: {
                                    first_item: 'Instancia Organizacional [140 horas]: el alumno se insertará dentro de una organización pública o privada realizando tareas relacionadas con su carrera de acuerdo al nivel de avance que haya obtenido.',
                                    second_item: 'Cursado Virtual [10 horas]: El que alumno accede a contenido destinado a mejorar su paso por la organización, obteniendo el mayor provecho posible de la experiencia'
                                },
                                schedule: {
                                    title: 'Horarios',
                                    text: 'Los horarios se coordinan de acuerdo a la disponibilidad del estudiante y la organización; se pueden definir de manera libre siempre y cuando no supere el máximo de seis horas por día y se cumplan con las 140 horas requeridas en el reglamento de la PP.'
                                },
                                tasks: {
                                    title: 'Tareas',
                                    text: 'Las tareas a realizar durante la PP deben estar relacionadas con tu carrera; a continuación una guia que puede orientarte en la defición de las mismas:'
                                },
                                qualification: {
                                    title: 'Calificación',
                                    text: 'La calificación de la PP es cualitativa, por lo tanto es suficiente con que te aparezca en el listado de materias “aprobadas”'
                                },
                                reglamento: {
                                    title: 'Reglamento',
                                    text: 'Conocé más detalles de la PP en acá'
                                }
                            },
                            place: {
                                title: '¿Dónde se hace?',
                                subtitle: 'En organizaciones públicas y privadas del todo el país.',
                                requirements: {
                                    title: 'Requisitos de la empresa',
                                    title_list: 'La organización debe cumplir con las siguientes condiciones:',
                                    item_one: 'Ser una organización legalmente constituida',
                                    item_two: 'Darte la oportunidad de realizar tu PP',
                                    item_three: 'Acceder a firmar los convenios correspondientes',
                                    item_four: 'Asignarte un referente/supervisor',
                                    item_five: 'Asignarte tareas/funciones relacionadas con tu carrera',
                                },
                                search_company: {
                                    title: 'Búsqueda de empresa',
                                    text: 'La búsqueda de organización es responsabilidad del estudiante; Teclab podrá apoyar la asignación de empresa de acuerdo a sus vacantes disponibles.',
                                },
                                list_company: {
                                    title: 'Listado de Empresas',
                                    text: 'Por razones de confidencialidad, no divulgamos listados de empresas con las cuales tenemos convenios vigentes para la realización de la Práctica Profesionalizante. Una vez recibida tu inscripción a la PP, analizaremos tu caso y buscaremos ubicar alguna vacante que se ajuste a tu situación. Luego nos vamos a contactar con vos para formalizar el vínculo.'
                                },
                                exceptions: {
                                    title: 'Excepciones',
                                    text: 'El instituto contempla aquellos casos en los que el estudiante ya cuenta con experiencia laboral previa en el área de la carrera que está cursando. Es por ello que en el Reglamento se expone la posibilidad de la validación de la experiencia laboral como Práctica Profesionalizante.',
                                    requirements: {
                                        title: 'Requisitos',
                                        item_one: 'Tu Experiencia Laboral tiene que estar relacionada con los contenidos de la carrera que estás cursando y debe ser igual o superior a 2 años.',
                                        item_two: 'Deberás certificar la experiencia informada en el formulario, adjuntando los certificados y/o constancias pertinentes.',
                                        item_three: ' Teclab verificará la documentación y en caso de proceder, aceptará el trámite; si se evidencia alguna inconsistencia, Teclab te pedirá la corrección del trámite o éste será rechazado,'
                                    },
                                    steps: {
                                        title: 'Paso a paso',
                                        step_one: 'Te anotás a la materia',
                                        step_two: 'Informás al área de PP tu intención de solicitar la validación',
                                        step_three: 'Te enviamos el formulario de solicitud',
                                        step_four: 'Adjuntás los comprobantes de dicha expericia (constancias y certificados laborales)',
                                        step_five: 'Teclab informa la aprobación/desaprobación de la validación',
                                        step_six: 'El estudiante envía la documentación original adjuntada en el formulario digital',
                                        step_seven: 'Teclab informa al estudiante sobre la calificación definitiva de la materia.',
                                        step_eight: 'Tené en cuenta que la calificación se verá reflejada en tu portal, al finalizar el bimestre para el cuál inscribís tu PP',
                                    }
                                }
                            },
                            steps: {
                                title: 'Paso a paso',
                                text: 'A continuación te orientamos para que no te cuelgues y avances con tu PP.',
                                inscription: {
                                    title: 'Inscripción',
                                    text: 'Si cumplís con los requisitos, podés anotarte al cursado de la Práctica Profesionalizante a través de la pestaña "Prácticas" de tu portal en las fechas establecidas por el instituto.',
                                },
                                assign: {
                                    title: 'Asignación',
                                    text: 'Una vez que recibamos tu inscripción, analizaremos tu caso y procedemos con la formalización de la asignación comunicándote el siguiente paso.',
                                },
                                signature: {
                                    title: 'Firma de Convenios',
                                    subtitle: 'La firma de los convenios se realiza de la siguiente manera:',
                                    agreement: {
                                        company: {
                                            title: 'Convenio de marco',
                                            text: 'Establece la relación que existirá entre las partes. Es firmado por el representante legal de la organización y el rector de IPP.'
                                        },
                                        singular: {
                                            title: 'Convenio individual',
                                            text: 'Lo firmás vos, también lo firma la persona que será tu referente en la organización y los firmamos en Teclab; se indican tus horarios, tareas y acuerdo de confidencialidad.',

                                        }
                                    },
                                    footer: 'La firma se hace totalmente online; no es necesario que la organización cuente con el servicio de firma digital ya que en Teclab contamos con el proveedor.'
                                },
                                start: {
                                    title: 'Inicio de cursado',
                                    text: 'Con los convenios firmados, estás en condiciones de iniciar la PP. Te enviaremos una comunicación oficial de inicio de práctias y solo a partir de ese momento podés iniciar.',

                                }
                            },
                            calendar: {
                                title: 'Calendario de PP',
                                text: 'A continuación, te presentamos las fechas más importantes'
                            }
                        }
                    },
                    download: 'Descargar carta',
                    help_title: '¿No encontraste lo que buscabas?',
                    help_text: 'Mandanos un WhatsApp y aclaramos tus dudas',
                },
                title_conditions: 'Términos y Condiciones',
                conditions: 'Declaro que toda la información que ingresaré y los documentos que envío, con motivo del inicio de los trámites correspondientes a la materia Práctica Profesionalizante, son reales y veraces en su totalidad; así también, declaro que he leído la reglamentación que rige la materia y estoy enterado de los tiempos y procedimientos que debo cumplir, así como los derechos que ostento en mi condición de estudiante.',
                warning_title: 'Advertencia',
                warning_text: 'Debe informar el nombre de la empresa',
                about: {
                    title: 'Ingresá tus datos',
                    title_view: 'Sobre la PP',
                    subtitle: 'A continuación, te pedimos nos indiques como estás pensando realizar tu Práctica Profesionalizante. Con éstos datos, el área encargada iniciará las gestiones correspondientes a cada caso',
                    infoPP: {
                        title: '¿Dónde harás la PP?',
                        subtitle: 'Seleccioná una de las opciones',
                        sameCompany: 'Me gustaría en mi trabajo actual',
                        otherCompany: 'Tengo una empresa para sugerir'
                    },
                    personalDetails:{
                        title: 'Datos Personales',
                        name: 'Nombre',
                        lastname: 'Apellido',
                        id: 'DNI',
                        email: 'Email',
                        tel: 'Teléfono',
                        career: 'Carrera',
                        cityWithState: 'Ubicación',
                    },
                    cv:{
                        title: 'Curriculum',
                        subtitle: 'Carga tu CV',
                        text:'En el caso de no tener cv podés',
                        link:'descargar una plantilla.',
                    },
                    optionCompany: {
                        actualCompany: {
                            text_old: 'Teclab facilita la realización de la PP en tu trabajo actual, siempre y cuando se cumplan las siguientes condiciones:',
                            text: 'Podés realizar tu práctica en tu trabajo si éste está relacionado con tu carrera, y tu Organización está de acuerdo y te permite realizar la práctica con ellos. El Área de Prácticas se pondrá en contacto con tu Organización para validar esta información.',
                            subtitle_conditions:'Por reglamento la Organización deberá cumplir con los siguientes requisitos:',
                            condition_one: 'a) Que sea una empresa legalmente constituida.',
                            condition_two: 'b) Te de la oportunidad para hacer tu PP.',
                            condition_three: 'c) Acceda a firmar los convenios correspondientes.',
                            condition_four: 'd) Que se te asigne un tutor en la empresa, quien se encargará de avaluarte y hacerte seguimiento.',
                            condition_five: 'e) Que las tareas a realizar estén relacionadas con tu carrera.'
                        },
                        otherCompany: {
                            subtitle: 'En este caso, vos nos informás los datos de contacto de la organización y Teclab se podrá en contacto para aclarar las dudas que haya sobre la PP y avanzar con la firma de los convenios.'
                        },
                        form: {
                            first_section: 'Datos de la empresa',
                            reason: 'Razón Social',
                            address: 'Dirección',
                            cuit: 'CUIT',
                            second_section: 'Datos del referente',
                            name_company: 'Nombre del contacto de la empresa',
                            referrer: 'Cargo del referente',
                            email: 'E-mail del contacto',
                            tel: 'Teléfono',
                            invalid: 'Ingrese un DNI valido',
                            otherCompany: {
                                third_section:'Horarios',
                                selectDate: 'Seleccioná una de las opciones',
                                loadCV: 'Cargar CV',
                                selectFile: 'Seleccionar archivo',
                                download_template: 'En el caso de no tener CV'
                            },
                            minLength: 'Ingrese un DNI valido',
                            minLengthTel: 'Minimo 10 digitos',
                            emailRequired: 'El email es invalido'
                        }
                    },
                },
                assignCompany: {
                    option_one: {
                        title: 'Búsqueda y asignación de Empresa',
                        first_subtitle: 'Gracias por informarnos sobre tu Práctica Profesionalizante. La siguiente etapa consiste en formalizar la asignación de la empresa, ¿cómo lo hacemos?',
                        first_item: '1. Nos pondremos en contacto con la organización',
                        second_item: '2. Analizamos la viabilidad de asignarte y que realices allí la PP',
                        third_item: '3. En caso de avanzar, quedas oficialmente asignado y avanzamos con el siguiente paso',
                        actualCompany: {
                            subtitle: 'Nos dijiste que te gustaria realizar tu PP en ',
                            subtitle_2: ', tu actual trabajo',
                            next: '¿Qué sigue?',
                            subtitle_steps: 'La siguiente etapa consiste en formalizar la asignación de la empresa, ¿cómo lo hacemos?:',
                            step_one: '1. Teclab verificará que la organización cumpla con las siguientes condiciones para que puedas realizar tu instancia organizacional:',
                            sub_condition_one: ' Que sea una empresa legalmente constituida',
                            sub_condition_two: ' Que la organización esté de acuerdo y te permita realizar la PP, firmando los convenios correspondientes',
                            sub_condition_three: ' Que se te asigne un tutor en la empresa, quien se encargará de avaluarte y hacerte seguimiento',
                            sub_condition_four: ' Que las tareas a realizar estén relacionadas con tu carrera ',
                            step_two: '2. Desde el área de Práctica Profesionalizante le pediremos a la organización que nos confirme que te acepta como practicante, le informaremos en qué consiste la PP y aclararemos cualquier duda que haya sobre esta etapa práctica',
                            text: 'Si todo está bien, te lo haremos saber para continuar con la siguiente etapa: la firma de los respectivos convenios, mediante los cuales quedarás oficialmente asignado y en condiciones de iniciar la PP.',
                            days: 'Tené en cuenta que este proceso puede demorar hasta 5 días hábiles.'
                        },
                        otherCompany: {
                            subtitle: 'Nos dijiste que te gustaría realizar tu PP en ',
                            next: '¿Qué sigue?',
                            step_one: 'Teclab verificará que los datos aportados por vos sean válidos, además, que la organización cumpla con las siguientes condiciones para que puedas realizar tu instancia organizacional:',
                            days: 'Tené en cuenta que este proceso puede demorar hasta 8 días hábiles.',
                            important: 'Importante:',
                            important_one: 'Teclab hará las gestiones correspondientes, pero no condiciona a la empresa para recibir estudiantes por las razones que considere.',
                            important_two: 'En caso de que la organización no acepte la firma del convenio o no esté en condiciones de recibir al estudiante podrás proponer una nueva organización.'

                        },
                    },
                    option_two: {
                        title: 'Gestión del formulario',
                        first_subtitle: 'Te hemos enviado el Formulario de Validación a tu correo electrónico',
                        second_subtitle: 'Recordá que, deberás completar el formulario teniendo en cuenta las siguientes indicaciones:',
                        requirements: {
                            item_one: {
                                title: 'Tu experiencia laboral tiene que estar relacionada con los contenidos de tu carrera y debe ser igual o superior a un año.',
                                subtitle: 'Deberás certificar la experiencia, adjuntando en el formulario la siguiente documentación:',
                                subitem_one: 'Último recibo de sueldo o factura (en caso de ser monotributista).',
                                subitem_two: 'Certificado Laboral expedido por el área de RRHH.',
                                subitem_three: 'Nota firmada por un referente de tu organización validando las tareas realizadas.',
                                text: 'La documentación debe tener el membrete institucional de la organización que la expida, así mismo debe estar debidamente scaneada y su visualización tiene que ser clara y prolija.',
                            },
                            item_two: 'Desde Teclab verificaremos la información compartida y en caso de proceder, se aceptará el trámite. Si se evidencia alguna inconsistencia, te pediremos la corrección del trámite o éste será rechazado. No se aceptan formularios incompletos o con errores ortográficos.',
                            item_three: 'La decisión final de tu solicitud de validación te será comunicada.'
                        },
                        text: 'Antes de comenzar, te recomendamos darle un vistazo al Tutorial de Validaciones que realizamos desde el área para orientarte acerca de tus gestiones:'
                    }
                },
                agreement: {
                    option_one: {
                        title: 'Aceptación del formulario',
                        subtitle: 'La solicitud que nos hiciste, para validar tu EXPERIENCIA LABORAL como PRÁCTICA PROFESIONALIZANTE… ¡ha sido aprobada!',
                        text: 'Ahora solo queda esperar hasta el inicio del bimestre para que puedas comenzar con el Cursado Virtual de tu materia.'
                    },
                    option_two: {
                        title: 'Gestión del Marco de Convenio',
                        subtitle: 'Ya queda poco, con la asiganción de la empresa podemos comenzar con la firma del convenio.',
                        requirements: {
                            title: 'Requisitos necesarios:',
                            item_one: 'Firma de convenio alumno',
                            item_two: 'Firma de convenio empresa',
                        }
                    }
                },
                authorized: {
                    title: '¡Felicitaciones, ya podés cursar tu Práctica!',
                    subtitle: 'Te recordamos que la Práctica Profesionalizante la podés realizar desde el portal de estudios',
                    start: 'Ir a CANVAS'
                }
            }
        },
    },
    es_CL:{
        breadcrumbs: {
            title: 'Inscripción de Práctica Laboral o Profesional',
        },
        stepper: {
            stepper_one: {
                step_1: 'Introducción y requisitos',
                step_2: 'Inscripción',
                step_3: 'Validacion información',
                step_4: 'Gestión del convenio',
                step_5: 'Práctica inscrita',
            },
            stepper_mobile: {
                step_1: 'Requisitos',
                step_2: 'Inscripción',
                step_3: 'Validacion',
                step_4: 'Convenio',
                step_5: 'Inscrita',
            },
            dateStep: {
                requirements: {
                    title: '{name} te damos la bienvenida a la práctica… una de las etapas más productivas de la carrera.',
                    subtitle: 'Esta sección es informativa de la inscripción de tu práctica. El siguiente paso se habilitará cuando cumplas con los requisitos de inscripción',
                    requirementsList: 'Estos son los requisitos:',
                    requirements: {
                        title: 'Llegar a 5to semestre para carreras técnicas y 8vo semestre para carreras profesionales',
                        subtitle: 'Para cursar su práctica, el estudiante deberá cumplir los siguientes requisitos:',
                        subitem_one: 'Tener la calidad de Alumno Regular.',
                        subitem_two: 'Cumplir con los requisitos académicos establecidos en el Plan de Estudios de la carrera respectiva.',
                        subitem_three: 'Inscribir la asignatura dentro del período de carga académica correspondiente.',
                        subitem_four: 'Gestionar un Centro de Práctica que cumpla con los requisitos y condiciones establecidos por IPP, el que deberá ser aprobado por este último.',
                        subitem_five: 'En el caso de las carreras de educación o cuando la naturaleza de la Práctica así lo requiera, acreditar que no se encuentra en el Registro de inhabilidades para condenados por delitos sexuales de menores, creado por la Ley 20.594.'
                    },
                    startDate: 'Próximo periodo de inscripción de prácticas semestrales:',
                    referrer_name: 'Nombre de la empresa o supervisor',
                    time: 'Tienes tiempo de inscribirte hasta el: ',
                    next_step: 'Una vez inicie el periodo y cumplas con los requisitos, inscríbete lo antes posible de forma que puedas cumplir con las horas correspondientes a tu carrera.',
                    search_practice_center: 'No olvides ir buscando un Centro de Práctica',
                    text: '¿Quieres ir adelantando gestiones? Si no tienes un Centro de Práctica todavía, descarga la carta de presentación que te servirá para acercarte a una organización. Consulta con ellos la posibilidad de que te permitan hacer tu práctica con ellos.',
                    letter: 'Personaliza la carta ingresando el nombre de la empresa o contacto.',
                    cards: {
                        card_models: {
                            title: 'Modelos de convenio',
                            text: 'Revisa un ejemplo del Convenio Individual de Prácticas que deberá firmar el Centro donde realices tu práctica.',
                            text_2: 'Descárgalo aquí',
                        },
                        card_rules: {
                            title: 'Reglamento',
                            text: 'Conoce el reglamento de prácticas y aclara las dudas que tengas sobre esta etapa académica.',
                            text_2: 'Ver reglamento aquí',
                            text_3: 'Dale un vistazo a las preguntas frecuentes de la PP haciendo click'
                        },
                        card_info: {
                            title: 'Espacio informativo',
                            text: '¿Qué es la práctica?',
                            practice: {
                                subtitle: '¿Qué es la práctica? El reglamento de práctica indica lo siguiente:',
                                first_item: 'La Práctica Laboral o Profesional, corresponde a aquella asignatura donde el estudiante demuestra competencias declaradas en el Perfil de Egreso, mediante el desarrollo de actividades prácticas en una posición o proyecto laboral afín a la carrera cursada, asumiendo el rol de su especialidad e integrando y aplicando los conocimientos y capacidades desarrolladas en su proceso formativo.',
                                second_item: 'La Práctica Laboral o Profesional se desarrollará en un periodo semestral y en un Centro de Práctica bajo la inspección directa de un Supervisor de Práctica y con acompañamiento de un Tutor de Práctica de su área profesional. Debido al carácter semestral de la asignatura, sólo podrá inscribirse en el primer bimestre del semestre respectivo, coincidiendo con la vigencia del contrato de prestación de servicios educacionales.',
                                third_item: 'El proceso de Práctica Laboral y/o Profesional no podrá convalidarse o reconocerse por otra vía. Por lo tanto, aun cuando los estudiantes realicen labores en un área relacionada con su carrera, deberán necesariamente cursar esta asignatura.'
                            },
                            questions: 'Preguntas frecuentes',
                            opportunities: 'Portal de oportunidades de empleos y prácticas'
                        }
                    },
                    download: 'Descargar',
                    name_company: 'Nombre de la empresa o contacto',
                    help_title: '¿No encontraste lo que buscabas?',
                    help_text: 'Escríbenos a practicas@ipp.cl y aclararemos tus dudas.',
                    email : 'practicas@ipp.cl',
                    button_diabled: 'Este botón se habilitará cuando cumplas con los requisitos. Cualquier duda escribe a ',
                },
                title_conditions: 'Términos y Condiciones',
                conditions: 'Declaro que toda la información que ingresaré y los documentos que envío, con motivo del inicio de los trámites correspondientes a la materia Práctica Profesionalizante, son reales y veraces en su totalidad; así también, declaro que he leído la reglamentación que rige la materia y estoy enterado de los tiempos y procedimientos que debo cumplir, así como los derechos que ostento en mi condición de estudiante.',
                warning_title: 'Advertencia',
                warning_text: 'Debe informar el nombre de la empresa',
                about: {
                    title: '¡Ya cumples con los requisitos para inscribir tu práctica!',
                    subtitle: 'Completa la información para la inscripción de tu práctica. Con esta información el Área de Prácticas y Titulaciones iniciará las gestiones correspondientes a cada caso.',
                    subtitle_place: '¿En qué ciudad realizarás la práctica?',
                    infoPP: {
                        title: '¿Dónde harás tu práctica?',
                        subtitle: 'Seleccioná una de las opciones',
                        sameCompany: 'Trabajo actual',
                        otherCompany: 'Conseguí un Centro de Prácticas',
                    },
                    personalDetails:{
                        title: 'Datos Personales',
                        name: 'Nombre',
                        lastname: 'Apellido',
                        id: 'RUT',
                        email: 'Email',
                        tel: 'Teléfono',
                        career: 'Carrera',
                        cityWithState: 'Ubicación',
                    },
                    optionCompany: {
                        actualCompany: {
                            text: 'Puedes realizar tu práctica en tu trabajo si éste está relacionado con tu carrera y tu organización está de acuerdo y te permite realizar la práctica con ellos. El Área de Prácticas y Titulaciones se pondrá en contacto con tu organización para validar esta información.',
                            subtitle_conditions: 'Por reglamento el Centro de Práctica deberá cumplir con, al menos, los siguientes requisitos:',
                            condition_one: 'a) Deberá estar legalmente establecido.',
                            condition_two: 'b) No podrá ser de propiedad del estudiante en práctica, ni estar dirigido por el mismo.',
                            condition_three: 'c) Deberá contar con un profesional de la disciplina que cursa el estudiante, que ejerza el rol de Supervisor de Práctica.',
                            condition_four: 'd) Deberá suscribir el Convenio Individual de Prácticas, en la que complete los datos requeridos y declare comprometerse a encomendar al estudiante labores relacionadas con su carrera o especialidad y a evaluar las mismas mediante los instrumentos correspondientes.',
                        },
                        otherCompany: {
                            subtitle: 'En este caso tú nos informas el Centro de Práctica que conseguiste y el Área de Prácticas y Titulaciones se pondrá en contacto con la organización para validar información.'
                        },
                        form: {
                            first_section: 'Datos de la empresa',
                            reason: 'Nombre del Centro de Práctica',
                            address: 'Dirección',
                            cuit: 'Rut del Centro de Práctica',
                            second_section: 'Datos del referente',
                            name_company: 'Nombre completo de quien supervisará tu práctica',
                            referrer: 'Cargo del supervisor',
                            email: 'Email del supervisor',
                            tel: 'Teléfono del supervisor',
                            invalid: 'Ingrese un RUT valido',
                            otherCompany: {
                                third_section: 'Horarios',
                                selectDate: 'Seleccioná una de las opciones',
                                loadCV: 'Cargar CV',
                                selectFile: 'Seleccionar archivo',
                                download_template: 'En el caso de no tener CV'
                            }
                        }
                    },
                },
                assignCompany: {
                    option_one: {
                        title: 'Recibimos tu inscripción',
                        first_subtitle: 'El Área de Prácticas y Titulaciones ya recibió la información de tu inscripción y se encuentra en validación con la organización que informaste. ¿Cómo lo hacemos?',
                        first_item: '1. Nos pondremos en contacto con la organización',
                        second_item: '2. Analizamos la viabilidad de que realices ahí la practica.',
                        third_item: '3. En caso de avanzar, quedas oficialmente asignado y avanzamos con el siguiente paso',

                        actualCompany: {
                            subtitle: 'Nos dijiste que te gustaria realizar tu PP en ',
                            subtitle_2: ', tu actual trabajo',
                            next: 'Esta validación puede tomar hasta 72 hrs.',
                            subtitle_steps: 'La siguiente etapa consiste en formalizar la asignación de la empresa, ¿cómo lo hacemos?:',
                            step_one: 'En caso de avanzar quedarás asignado y continuarás a la etapa de gestión del Convenio Individual de Prácticas, que tiene los siguientes pasos: ',
                            sub_condition_one: '1 - Te lo enviaremos para que completes los detalles de tu práctica.',
                            sub_condition_two: '2 - Validaremos la información en función del reglamento, las tareas asignadas y la cantidad de horas que debes cumplir según tu carrera.',
                            sub_condition_three: '3 - De cumplir todos los requisitos lo enviaremos en el siguiente orden para las firmas:',
                            sub_condition_four: ' Que las tareas a realizar estén relacionadas con tu carrera ',
                            step_two: 'Esta gestión y validación puede tomar hasta 7 días hábiles desde que completas los datos del convenio y que tú y tu organización firmen a tiempo.',
                            text: 'Este convenio constituye el comprobante del seguro escolar acogido al D.S. 313 MAYO/1973. LEY 16.744 DE ACCIDENTES DEL TRABAJO, que cubre cualquier accidente de nuestro estudiante en el trayecto a su institución, o en sus dependencias, producto del proceso de práctica que desarrolla.',
                            days1: 'Si tienes alguna duda recuerda escribirnos a',
                            days2: 'indicando tu Rut y la etapa de la inscripción en la que te encuentras.',
                        },
                    },
                },
                agreement: {
                    option_one: {
                        title: 'Aceptación del formulario',
                        subtitle: 'La solicitud que nos hiciste, para validar tu EXPERIENCIA LABORAL como PRÁCTICA PROFESIONALIZANTE… ¡ha sido aprobada!',
                        text: 'Ahora solo queda esperar hasta el inicio del bimestre para que puedas comenzar con el Cursado Virtual de tu materia.'
                    },
                    option_two: {
                        title: 'Gestión del Convenio Individual de Prácticas y estado de firmas',
                        subtitle: 'Ya queda poco, con la validación de la empresa podremos comenzar con completar el convenio, realizar su validación por parte del área de prácticas y finalmente la firma del convenio por las partes.',
                        requirements: {
                            title: 'Estado de las firmas',
                            item_one: 'Firma de estudiante',
                            item_two: 'Firma de organización',
                            item_three: 'Firma Instituto IPP'
                        },
                        manage_signature: 'Gestiona cuanto antes tu firma y la de tu organización.',
                        text_help: 'Si tienes alguna duda recuerda escribirnos a practicas@ipp.cl, indicando tu Rut y la etapa de la inscripción en la que te encuentras.'
                    }
                },
                authorized: {
                    title: '¡Felicitaciones, ya tienes inscrita tu práctica!',
                    subtitle: 'Ahora se cargará la asignatura en CANVAS para que puedas iniciar tu práctica y realizar las actividades.',
                    text: 'Te recordamos que debes realizar las tareas asignadas por tu Centro de Práctica, llevar registro de asistencia y completar los módulos de la asignatura.',
                    congratulations: '¡Mucho éxito!',
                    start: 'Ir a CANVAS'
                }
            }
        },
    },
    es_PE: {
        stepper: {
            dateStep: {
                requirements: {
                    requirements: {
                        subitem_three: 'Inscribir el curso dentro del período de carga académica correspondiente.',
                        cards: {
                            card_info: {
                                courses: {
                                    list: {
                                        first_item: 'Instancia Organizacional [140 horas]: el estudiante se insertará dentro de una organización pública o privada realizando tareas relacionadas con su carrera de acuerdo al nivel de avance que haya obtenido.',
                                        second_item: 'Cursado Virtual [10 horas]: El que estudiante accede a contenido destinado a mejorar su paso por la organización, obteniendo el mayor provecho posible de la experiencia'
                                    },
                                },
                            }
                        },
                    },
                    cards: {
                        card_info: {
                            requirements: {
                                list: {
                                    item_one: 'Aprobar los exámenes finales de 4 cursos.',
                                    item_four: 'Aprobar los exámenes finales de 4 cursos del segundo año de la carrera.',
                                },
                                first: {
                                    text: 'Ahora, solo tienes que anotarte al curso. Te compartimos el calendario de inscripciones'
                                },
                                second: {
                                    first_item: 'Realizar finales: Aprueba los que estén haciendo falta y anotate'
                                }
                            },
                            courses: {
                                qualification: {
                                    text: 'La calificación de la PP es cualitativa, por lo tanto es suficiente con que te aparezca en el listado de cursos “aprobados”'
                                },
                            },
                            place: {
                                exceptions: {
                                    steps: {
                                        step_one: 'Te anotás al curso',
                                        step_seven: 'Teclab informa al estudiante sobre la calificación definitiva del curso.',
                                    }
                                }
                            }
                        }
                    },
                },
                about: {
                    subtitle_place: '¿En qué distrito realizarás la práctica?',
                },
                agreement: {
                    option_one: {
                        text: 'Ahora solo queda esperar hasta el inicio del bimestre para que puedas comenzar con el Cursado Virtual de tu curso.'
                    },
                    option_two: {
                        requirements: {
                            item_one: 'Firma de convenio estudiante',
                        }
                    }
                },
                authorized: {
                    subtitle: 'Ahora se cargará el curso en CANVAS para que puedas iniciar tu práctica y realizar las actividades.',
                    text: 'Te recordamos que debes realizar las tareas asignadas por tu Centro de Práctica, llevar registro de asistencia y completar los módulos de el curso.',
                }
            }
        },
    },
    en: {},
};

export default practiceTranslations;
