<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h5 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.cardTitle') }}</h5>
            </v-col>

            <v-col class="d-flex align-center mb-8" cols="12">
                <VuePaycard
                    :has-random-backgrounds="false"
                    :is-card-number-masked="false"
                    :value-fields="valueFields"
                    :labels="labels"
                    :inputFields="{ cardName: 'v-card-name' }"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <div id="form-checkout__cardNumber" class="mp_container" :class="{'is-invalid': !cardNumberValid}"></div>
                <small class="px-3 red--text" v-show="!cardNumberValid">{{ $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>

            <v-col cols="12" sm="3">
                <div id="form-checkout__expirationDate" class="mp_container" :class="{'is-invalid': !expirationDateValid}"></div>
                <small class="px-3 red--text" v-show="!expirationDateValid">{{ $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>

            <v-col cols="12" sm="3">
                <div id="form-checkout__securityCode" class="mp_container" :class="{'is-invalid': !securityCodeValid}"></div>
                <small class="px-3 red--text" v-show="!securityCodeValid">{{ $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') }}</small>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.nameoncard')"
                    v-model="cardName"
                    data-card-field
                    outlined
                    :error-messages="$v.cardName.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.cardName.$touch()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
                <IdentificationInput
                    v-model="cardId"
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.idoncard') + ' *'"
                    id-type="DNI"
                    :error-messages="$v.cardId.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.cardId.$touch()"
                    @validateRut="validCardRut = $event"
                ></IdentificationInput>
                <input type="hidden" id="deviceId" name="deviceId" v-model="deviceId" />
            </v-col>
        </v-row>
        <v-row v-if="showInstallments">
            <v-col cols="12" class="pb-0">
                <h5 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.how_many_installments') }}</h5>
            </v-col>
            <v-col cols="12" md="8">
                <v-select
                    v-model="$v.selectedInstallment.$model"
                    @change="setDue"
                    label="Cantidad de cuotas"
                    :items="installments"
                    item-text="recommended_message"
                    return-object
                    :error-messages="$v.selectedInstallment.$anyError ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.selectedInstallment.$touch()"
                    outlined
                ></v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="resumen_interes">
                <p v-if="resumen_interes.tea.length" class="my-0 caption">{{`TEA: ${resumen_interes.tea[1]}`}}</p>
                <p class="my-0" v-if="resumen_interes.tea.length">{{`CFT: ${resumen_interes.cft[1]}`}}</p>
            </v-col>
        </v-row>

        <template v-if="$isTeclab">
            <PaymentBillingDataTeclab
                v-if="showBillingData"
                ref="billingData"
                @update="(data) => { billingData = data }"
                @validate="(val) => { billingDataValid = val }"
            ></PaymentBillingDataTeclab>
        </template>
        <template v-else>
            <PaymentBillingData
                v-if="showBillingData"
                ref="billingData"
                @update="(data) => { billingData = data }"
                @validate="(val) => { billingDataValid = val }"
            ></PaymentBillingData>
        </template>
    </v-container>
</template>

<style lang="sass" scoped>
    .mp_container
        height: 44px
        padding: 12px
        color: rgba(0, 0, 0, 0.87)
        line-height: 16px
        border: thin solid rgba(0, 0, 0, 0.38)
        border-radius: 4px

        &.is-invalid
            border: thin solid red

</style>

<script>

    import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
    import PaymentBillingData from '../PaymentBillingData/PaymentBillingData'
    import PaymentBillingDataTeclab from '../PaymentBillingData/PaymentBillingDataTeclab';
    import { $payments, $paymentMethod } from '../../Services';
    import { mapGetters } from 'vuex'
    import { required } from 'vuelidate/lib/validators'
    import { VuePaycard } from 'vue-paycard';
    import commons from '../../Mixins/commons';

    export default {
        name: 'GatewayMercadoPago',
        mixins: [ commons ],
        components: {
            IdentificationInput,
            PaymentBillingData,
            PaymentBillingDataTeclab,
            VuePaycard,
        },
        props: {
            pendingPayment: { type: Boolean, default: false },
            selectedMethod: { type: Object, default: null },
            summaryButtons: { type: Object, default: null },
            subscription: { type: Object, default: null },
            hideInstallments: { type: Boolean, default: false },
            cardIndex: { type: Number, default: null },
            resumen: { type: Object },
            unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
        },
        data() {
            return {
                deviceId: null,
                token: null,
                mp: null,
                cardId: null,
                validCardRut: false,
                billingDataValid: false,
                billingData: null,
                cardName: null,
                cardNumberValid: true,
                expirationDateValid: true,
                securityCodeValid: true,
                cardNumberElement: null,
                expirationDateElement: null,
                securityCodeElement: null,
                currentBin: null,
                selectedPaymentMethod: null,
                selectedInstallment: null,
                resumen_interes: null,
                installments: [],
                issuers: [],
                selectedIssuer: null,
                labels: {
                    cardName: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardName'),
                    cardHolder: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardHolder'),
                    cardMonth: 'MM',
                    cardYear: 'YY',
                    cardExpires: this.$t('modules.portalalumno.payments.paymentsStudents.view.creditCard.labels.cardExpires'),
                    cardCvv: 'CVV'
                },
            }
        },
        computed: {
            dataInvalid() {
                return !this.selectedInstallment || !this.validCardRut || !this.billingDataValid
            },
            showBillingData(){
                return this.selectedInstallment || this.hideInstallments
            },
            valueFields(){
                return {
                    cardName: this.cardName ? this.cardName : '',
                    cardNumber: '' ,
                    cardMonth:  '',
                    cardYear: '',
                    cardCvv: '',
                }
            },
            showInstallments(){
                return this.cardNumberValid && !this.$v.cardName.$invalid && this.securityCodeValid && this.expirationDateValid && !this.$v.cardId.$invalid && !this.hideInstallments
            },
            totalAmount() {
                if (this.cardIndex != null) return this.resumen.cards[this.cardIndex].amount
                else return this.resumen.total
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserData: 'commons/getStudentUserData',
                loggedUsername: 'commons/getUserName',
                studentData: 'commons/getStudentData',
            }),
        },
        created() {
            this.initForm()
        },
        beforeDestroy() {
            this.cardNumberElement.unmount()
            this.expirationDateElement.unmount()
            this.securityCodeElement.unmount()
        },
        methods: {
            async initForm() {
                const style = {
                    'placeholder-color': 'rgba(0, 0, 0, 0.6)',
                    'color': 'rgba(0, 0, 0, 0.87)',
                }
                this.mp = await new window.MercadoPago(this.$getMpKey())
                this.cardNumberElement = this.mp.fields.create('cardNumber', { placeholder: 'Número de tarjeta', style }).mount('form-checkout__cardNumber');
                this.expirationDateElement = this.mp.fields.create('expirationDate', { placeholder: 'MM/YY', style }).mount('form-checkout__expirationDate');
                this.securityCodeElement = this.mp.fields.create('securityCode', { placeholder: 'CVV', style }).mount('form-checkout__securityCode');
                this.cardNumberElement.on('validityChange', (e) => { this.cardNumberValid = e.errorMessages ? !e.errorMessages.length : false })
                this.expirationDateElement.on('validityChange', (e) => { this.expirationDateValid = e.errorMessages ? !e.errorMessages.length : false })
                this.securityCodeElement.on('validityChange', (e) => { this.securityCodeValid = e.errorMessages ? !e.errorMessages.length : false })
                this.cardNumberElement.on('binChange', this.binChange)
            },
            async binChange(data) {
                const { bin } = data;
                try {
                    if (!bin && this.selectedPaymentMethod?.id) {
                        this.selectedPaymentMethod = null;
                        this.resetDue()
                    }

                    if (bin && bin !== this.currentBin) {
                        const { results } = await this.mp.getPaymentMethods({ bin });
                        this.resetDue()
                        
                        if (results && results.length) {
                            // eslint-disable-next-line prefer-destructuring
                            const paymentMethod = results[0];

                            this.selectedPaymentMethod = paymentMethod;
                            this.updatePCIFieldsSettings(paymentMethod);
                            await this.updateIssuer(paymentMethod, bin);
                            this.updateInstallments(paymentMethod);
                        } else {
                            this.selectedPaymentMethod = null
                        }
                    }

                    this.currentBin = bin;
                } catch (e) {
                    this.resetDue()
                }
            },
            updatePCIFieldsSettings(paymentMethod) {
                const { settings } = paymentMethod;

                const cardNumberSettings = settings[0].card_number;
                this.cardNumberElement.update({ settings: cardNumberSettings });

                const securityCodeSettings = settings[0].security_code;
                this.securityCodeElement.update({ settings: securityCodeSettings });
            },
            async updateIssuer(paymentMethod, bin) {
                const { additional_info_needed, issuer } = paymentMethod;
                let issuerOptions = [issuer];

                if (additional_info_needed.includes('issuer_id')) {
                    try {
                        issuerOptions = await this.mp.getIssuers({ id: paymentMethod.id, bin });
                    } catch (e) {
                        this.resetDue()
                    }
                }

                this.issuers = issuerOptions
                if (issuerOptions.length == 1) this.selectedIssuer = issuer
                else this.selectedIssuer = null
            },
            async updateInstallments(paymentMethod) {
                try {
                    const params = {
                        payment_method_id: paymentMethod.id,
                        amount: this.totalAmount,
                        careerId: this.studentData.career.id,
                        studentType: this.studentData.studentType.value,
                    }
                    if (this.selectedIssuer) params.card_issuer = this.selectedIssuer.id

                    const { data } = await $paymentMethod.find('installments', null, { params })
                    this.installments = data.installments[0].payer_costs
                } catch (e) {
                    this.installments = []
                }
            },
            resetDue() {
                this.selectedInstallment = null
                this.$v.selectedInstallment.$reset()
                this.resumen_interes = null

                if (this.cardIndex != null) {
                    this.resumen.cards[this.cardIndex].total = this.resumen.cards[this.cardIndex].amount
                    this.resumen.cards[this.cardIndex].cft = '0,00%'
                    this.resumen.cards[this.cardIndex].tea = '0,00%'
                } else {
                    this.resumen.cft = '0,00%'
                    this.resumen.tea = '0,00%'
                }
                
                this.$emit('resetDue')
            },
            setDue(value) {
                let cft = ['CFT', null]
                let tea = ['TEA', null]

                if (this.$countryConstants.payment.isCftEnabled) {
                    const tax = value.labels.find(label => label.includes('CFT') && label.includes('TEA'))?.split('|') ?? ('CFT_0,0%|TEA_0,0%').split('|')
                    cft = (tax.length) ? tax[0].split('_') : ['CFT', '0,0%']
                    tea = (tax.length) ? tax[1].split('_') : ['TEA', '0,0%']
                    this.resumen_interes = { cft, tea }
                }

                if (this.cardIndex != null) {
                    this.resumen.cards[this.cardIndex].total = value.total_amount
                    this.resumen.cards[this.cardIndex].cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.cards[this.cardIndex].tea = tea[1] // eslint-disable-line prefer-destructuring
                } else {
                    this.resumen.cft = cft[1] // eslint-disable-line prefer-destructuring
                    this.resumen.tea = tea[1] // eslint-disable-line prefer-destructuring
                }
                
                this.$emit('setDue', {
                    installments: this.selectedInstallment.installments,
                    installment_amount: this.selectedInstallment.installment_amount,
                    total_amount: this.selectedInstallment.total_amount,
                    credit_card_fee: this.selectedInstallment?.credit_card_fee ?? null,
                    name: this.selectedPaymentMethod.name
                })
            },
            async saveBillingData() {
                this.summaryButtons.primary.loading = true

                try {
                    await this.$refs.billingData.save()
                    this.createToken()
                } catch(error) {
                    this.errorLoading('¡Ups!', 'Se genero un error en la validación de datos. Por favor, vuelva a intentarlo')
                    this.summaryButtons.primary.loading = false
                    this.summaryButtons.primary.error = true

                    throw error;
                }
            },
            async createToken() {
                
                try {
                    const token = await this.mp.fields.createCardToken({
                        cardholderName: this.cardName,
                        identificationType: this.$countryConstants.identificationType,
                        identificationNumber: this.cardId.replace('-',''),
                    });
                    this.token = token.id;
                    this.createPayment()
                } catch (error) {
                    this.token = null
                    throw error
                }
            },
            async createPayment() {
                let desc = '';
                const conceptos = this.resumen.items.subtotal.items;
                let firstAmount = null
                let secondAmount = null

                conceptos.forEach((concepto,index) => {
                    if(index === 0) desc = concepto.title;
                    else desc += ` - ${concepto.title}`
                });

                const cardholderName = this.cardName;
                const array = cardholderName.split(' ');
                const firstName = array[0]; // eslint-disable-line
                const lastName = array[array.length - 1]; // eslint-disable-line

                if (this.cardIndex === null){
                    firstAmount = this.subscription ? parseFloat(this.resumen.items.adminExpenses.monto).toFixed(2) : 
                                    (this.$isTeclab ?  this.totalAmount.toFixed(2) : this.totalAmount.toFixed(0))
                } else {
                    if (this.resumen.cards.length > 1 && this.cardIndex === 0) {
                        firstAmount = this.resumen.cards[0].amount
                        secondAmount = parseFloat(this.resumen.cards[1].amount)
                    } else {
                        firstAmount = parseFloat(this.resumen.cards[1].amount)
                        secondAmount = this.resumen.cards[0].amount;
                    }
                }
            
                firstAmount = this.$encrypter(parseFloat(firstAmount).toFixed(2));
                if (secondAmount !== null) secondAmount = this.$encrypter(parseFloat(secondAmount).toFixed(2))
                let percentageSubscription = parseFloat(this.resumen.items.adminExpenses.percentage).toFixed(2)
                if (percentageSubscription !== null) percentageSubscription = this.$encrypter(percentageSubscription)

                const paymentData = {
                    'legajo': this.studentRegister,
                    'amount': firstAmount,
                    'description': this.subscription ? 'GASTO_ADMINISTRATIVO' : desc,
                    'payment_method_id': this.selectedPaymentMethod.id,
                    'token': this.token,
                    'installments': this.subscription ? this.subscription.installments : (this.selectedInstallment?.installments || 1),
                    'payer_email': this.studentUserData.email,
                    'issuer_id': this.selectedIssuer.id,
                    'usr_portal': this.loggedUsername,
                    'discount_intent': this.resumen.paymentIntent,
                    'intencion_de_pago_id': this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                    'user_type': this.$isGestor ? 'ADVISOR' : 'STUDENT',
                    'doc_number': this.cardId,
                    'doc_type': this.$countryConstants.identificationType,
                    'device_id': this.deviceId,
                    'firstName': firstName,
                    'lastName': lastName,
                    'capture': !this.pendingPayment && !this.subscription,
                    'pago_una_tarjeta': this.selectedMethod.id != 'TC2',
                    'credit_card_fee': this.selectedInstallment?.credit_card_fee ?? null,
                    'secondAmount': secondAmount,
                    'percentage': percentageSubscription,
                    'idsPending': this.unselectedReenrollmentsAccountIds 
                };

                try {
                    const { data } = await $payments.create(paymentData);
                    // approved, in_process

                    if (data && data.status === 'rejected' && this.isCodeError(data.status_detail)){
                        this.errorLoading('¡Ups!', this.errorMessage(data.status_detail))
                        this.summaryButtons.primary.error = true
                    } else if (data.status === 'approved' || data.status === 'in_process') {
                        this.$emit('changeStep', data.status);
                        this.successLoading()
                    } else if (data.status === 'authorized') {
                        if (this.pendingPayment) {
                            //Pago con dos tajetas
                            this.$emit('paymentProcessed', { index: this.cardIndex, paymentId: JSON.parse(data.full_response).id })
                        } else {
                            //Pago con suscripción
                            this.$emit('paymentProcessed',{ cardName: this.cardName, token: this.token, data, efectivo: 'false' })
                        }
                    }
                } catch (response) {
                    this.errorLoading('¡Ups!', 'Se genero un error en el proceso de pago. Por favor, vuelva a intentarlo')
                    this.summaryButtons.primary.error = true
                    throw response
                } finally {
                    this.summaryButtons.primary.loading = false
                }
            },
            successLoading() {
                this.summaryButtons.primary.loading = false
                this.summaryButtons.primary.success = true
                setTimeout(() => {
                    this.summaryButtons.primary.success = false
                    this.summaryButtons.primary.disabled = true
                }, 2500)
            },
            errorLoading(title = null, message = null) {
                this.summaryButtons.primary.loading = false
                this.summaryButtons.primary.error = true
                this.summaryButtons.primary.error = false
                if (title && message) {
                    this.$store.dispatch('commons/openPopUp', {
                        title,
                        content: [{ value: message }],
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary',
                    });
                }
                if (this.cardIndex) this.$emit('paymentError')  // Si paga con varias tarjetas y el error fue en otro que no sea en el primero, vuelve a iniciar
            },
            isCodeError(data){
                const codes = [
                    'cc_rejected_bad_filled_card_number',
                    'cc_rejected_bad_filled_date',
                    'cc_rejected_bad_filled_other',
                    'cc_rejected_bad_filled_security_code',
                    'cc_rejected_blacklist',
                    'cc_rejected_call_for_authorize',
                    'cc_rejected_card_disabled',
                    'cc_rejected_card_error',
                    'cc_rejected_duplicated_payment',
                    'cc_rejected_high_risk',
                    'cc_rejected_insufficient_amount',
                    'cc_rejected_invalid_installments',
                    'cc_rejected_max_attempts',
                    'cc_rejected_other_reason'
                ]
                return codes.includes(data);
            },
            errorMessage(error){
                const codes = {
                    cc_rejected_bad_filled_card_number: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_card_number') ,
                    cc_rejected_bad_filled_date: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_date'),
                    cc_rejected_bad_filled_other:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_other'),
                    cc_rejected_bad_filled_security_code: this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_bad_filled_security_code'),
                    cc_rejected_blacklist:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_blacklist'),
                    cc_rejected_call_for_authorize:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_call_for_authorize'),
                    cc_rejected_card_disabled:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_card_disabled'),
                    cc_rejected_card_error:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_card_error'),
                    cc_rejected_duplicated_payment:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_duplicated_payment'),
                    cc_rejected_high_risk:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_high_risk'),
                    cc_rejected_insufficient_amount:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_insufficient_amount'),
                    cc_rejected_invalid_installments:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_invalid_installments'),
                    cc_rejected_max_attempts:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_max_attempts'),
                    cc_rejected_other_reason:  this.$t('modules.portalalumno.payments.paymentsStudents.errorsMP.cc_rejected_other_reason')
                }
                return codes[error]
            },
        },
        watch: {
            billingDataValid() {
                if (this.selectedInstallment) this.setDue(this.selectedInstallment)
            },
            dataInvalid(value) {
                this.summaryButtons.primary.disabled = value
            }
        },
        validations: {
            securityCodeValid: {required},
            expirationDateValid: { required },
            cardNumberValid: { required },
            cardId: { required },
            cardName: { required },
            selectedInstallment: { required },
        },
    }
</script>