<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters class="justify-space-between">
      <v-col sm="4" class="mt-4 mb-2 d-flex" >
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
      <v-col sm="8" class="mt-4 mb-2">
          <div class="d-flex justify-end">
              <Button
                class="ml-4"
                @clicked="newProgram"
                depressed
                :text="$t('modules.exams_group.statements.subjectsTable.newProgram')"
              ></Button>
            </div>
          
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :show-select="false"
          :headers="headers"
          :items="sections"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
        <template v-slot:[`header.countStatementsFinal`]="{header}" > 
                      <span class="headerQuestions">{{ header.text }}</span> 
        </template>
        <template v-slot:[`header.countStatementsPractice`]="{header}" > 
                      <span class="headerQuestions">{{ header.text }}</span> 
        </template>
        <template v-slot:[`item.countStatementsFinal`]="{item}">
                      {{ ` 1 - ${item.countStatementsFinal.difficultyOne} `}}
                      <br>
                      {{ ` 3 - ${item.countStatementsFinal.difficultyThree} `}}
                      <br>
                      {{ ` 5 - ${item.countStatementsFinal.difficultyFive} `}}
          </template>
          <template v-slot:[`item.countStatementsPractice`]="{item}">
                      {{ ` 1 - ${item.countStatementsPractice.difficultyOne} `}}
                      <br>
                      {{ ` 3 - ${item.countStatementsPractice.difficultyThree} `}}
                      <br>
                      {{ ` 5 - ${item.countStatementsPractice.difficultyFive} `}}
          </template>
          <template v-slot:[`item.updatedAt`]="{item}">
                      {{ item.updatedAt }}
          </template>

         <template v-slot:[`item.status`]="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template> 

          <template v-slot:[`item.action`]="{ item }" >
              <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list class="pa-0">
                      <v-list-item  @click="editItem(item.id, item.code, item.description, item.status)">
                          <v-list-item-title>
                              <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item  @click="newStatements(item.id)">
                          <v-list-item-title>
                              <v-icon>mdi-upload</v-icon> {{ $t('modules.exams_group.statements.actions.statements') }}
                          </v-list-item-title>
                      </v-list-item>
                  </v-list>
              </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model="page"
          max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.v-expansion-panels
    .v-expansion-panel-content
        padding-left: 10px
        padding-bottom: 0px
        padding-right: 16px
</style>

<script>
import Button from '@/components/Button/Button'
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTable',
  components:{
    Button
  },
  props: {
    sections: Array,
    headers: Array,
    totalElements: Number,
    permissions: {
      update: false,
    },
  },
  data () {    
    return {
      selectedItems: [],
      search: '',
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
    }
  },
  methods: {
    getAvatar (id) {
      return `${Constants.URL_DATA_SERVER  }/users/${  id  }/avatar`;
    },
    editItem (id, code, description, status) {
      this.$emit('updateUser', id, code, description, status);
    },
    searchMethod () {
      const params = { 
        search: this.search,
        period: this.periodSelected,
      }
      this.$emit('search',params);
    },
    newProgram () {
      this.$router.push(`${this.$route.params.subject_id}/create`)
    },   
    newStatements (id)  {
      this.$router.push({path:`/exams/statements/contents/${id}`, query:{code:this.$route.query.code, description:this.$route.query.description}} )
    }
  },
watch: {
    'page': function (val) {
      if (val > this.pageCount)
        this.page = this.pageCount;
      if (val <= 1)
        this.page = 1;
    },
    'itemsPerPage': function (val) {
      this.$emit('update:itemsPerPage',val)
    },
    'sections': function(val){
      if(this.periodSelected === null && val.length > 0){
        this.periodSelected = val[0].term?.id
      }
    }
  },
  computed: {
    areItemsSelected () {
      return !!this.selectedItems.length
    },
    pageCount () {
      let counter = this.totalElements / this.itemsPerPage;

      if (counter < 1) {
        counter = 1
      }

      return Math.ceil(counter)
    },
    itemsCount () {
      const counter = {
        totalItems: this.totalElements,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.totalElements) {
        counter.endItem = this.totalElements
      }

      return counter
    }
  },
};
</script>
<style scoped>
.headerQuestions{
    white-space: pre-line;
}
</style>
