<template>
  <v-container>
    <v-card class=" py-5 rounded-lg" :class="!$vuetify.breakpoint.mobile ? 'px-10' : ''" outlined>
      <v-container class="pb-0">
        <section v-if="false">
          <h4>{{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.title" ) }} </h4>
          <v-row>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.name" ) }} 
              </h3>
              <h2 class="body-2"> {{ studentUserData.name }} </h2>
            </v-col>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.lastname" ) }} 
              </h3>
              <h2 class="body-2">{{ studentUserData.lastname }}</h2>
            </v-col>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.id" ) }} 
              </h3>
              <h2 class="body-2">{{ studentUserData.identification }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.email" ) }} 
              </h3>
              <h2 class="body-2"> {{ studentUserData.email }} </h2>
            </v-col>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.tel" ) }} 
              </h3>
              <h2 class="body-2">{{ studentUserData.cellphone }}</h2>
            </v-col>
            <v-col sm="4">
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.career" ) }} 
              </h3>
              <h2 class="body-2">{{ studentCareer.description }}</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 class="caption primary--text font-weight-bold">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.personalDetails.cityWithState" ) }} 
              </h3>
              <h2 class="body-2"> {{ userData.address.cityWithState.cityState }} </h2>
            </v-col>
          </v-row>
        </section>
        <h4 v-if="!$isTeclab" class="my-3">
          {{
            $t(
              "modules.portalalumno.practice.stepper.dateStep.about.subtitle_place"
            )
          }}
        </h4>
        <h4 v-else class="my-3">
          {{
            $t(
              "modules.portalalumno.practice.stepper.dateStep.about.infoPP.title"
            )
          }}
        </h4>
        <AutocompleteInput
          :disableButtons="true"
          :label="'Seleccionar localidad'"
          :value.sync="$v.studentLocation.$model"
          :required="$v.studentLocation.$invalid"
          :error-messages="
            $t('modules.academic.periods.create.errors.required')
          "
          ref="studentLocation"
        />
        <v-divider></v-divider>
        <article class="mt-4">
          <h4 v-if="$isTeclab">
            {{
              $t(
                "modules.portalalumno.practice.stepper.dateStep.about.title_view"
              )
            }}
          </h4>
          <h4 v-else>
            {{
              $t(
                "modules.portalalumno.practice.stepper.dateStep.about.infoPP.title"
              )
            }}
          </h4>
          <h4 class="text--secondary body-2">
            {{
              $t(
                "modules.portalalumno.practice.stepper.dateStep.about.infoPP.subtitle"
              )
            }}
          </h4>
          <v-btn-toggle v-model="toggle_exclusive" mandatory class="d-flex align-center justify-center" >
            <v-col class="d-flex justify-end">
              <v-btn
                small
                :outlined="selectCompany != 263"
                color="primary"
                class="font-weight-bold rounded-lg py-10"
                @click="selectOption(263)"
                :class="selectCompany == 263 ? 'primary text--white' : null"
                max-width="180px"
                width="inherit"
              >
                  {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.infoPP.sameCompany" ) }}
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-start" >
              <v-btn
                small
                :outlined="selectCompany != 264"
                color="primary"
                class="font-weight-bold rounded-lg py-10"
                @click="selectOption(264)"
                :class="selectCompany == 264 ? 'primary text--white' : null"
                max-width="180px"
                width="inherit"
              >
                  {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.infoPP.otherCompany" ) }}
              </v-btn>
            </v-col>
          </v-btn-toggle>
        </article>
        <!--  -->
        <v-card  
            class="my-5 font-weight-bold caption text--secondary pa-6 rounded-lg elevation-0 primary"
            :class="$isTeclab ? ' lighten-5' : ' lighten-1'"
        >
            <p v-if="selectCompany == 263">
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.text" ) }}
            </p>
            <p v-if="selectCompany == 264" >
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.otherCompany.subtitle" ) }} 
            </p>
            <p class="mb-0" >
                {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.subtitle_conditions" ) }}
            </p>
            <div>
                <p class="mb-0 text-wrap">
                    {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.condition_one" ) }}
                </p>
                <p class="mb-0 text-wrap">
                    {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.condition_two" ) }}
                </p>
                <p class="mb-0 text-wrap">
                    {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.condition_three" ) }}
                </p>
                <p class="mb-0 text-wrap">
                    {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.condition_four" ) }}
                </p>
                <p class="mb-0 text-wrap">
                    {{ $t( "modules.portalalumno.practice.stepper.dateStep.about.optionCompany.actualCompany.condition_five" ) }}
                </p>
            </div>
        </v-card>
      </v-container>
      <FormPP
        ref="formPP"
        :invalidDays="invalidDays"
        :inscription="$v.inscription"
        @changeValueInvalid="changeValueInvalid"
        :selectCompany="selectCompany"
        :file.sync="file"
      />
    </v-card>
    <div class="mx-auto mt-8 d-flex justify-end pt-4">
      <Button
        class="mx-8"
        :text="$t('actions.cancel')"
        white
        @clicked="$emit('goBack')"
      ></Button>
      <Button
        :text="$t('actions.send')"
        :loading="createButton.loading"
        :success="createButton.success"
        :error="createButton.error"
        :successText="$t('actions.created')"
        :errorText="'Error'"
        @end="resetButtonValues"
        @clicked="validateForm"
      ></Button>
    </div>
  </v-container>
</template>

<script>
import FormPP from '../Components/FormPP.vue';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import Button from '@/components/Button/Button';
import {
  email,
  required,
  minLength,
  requiredIf,
  maxLength,
} from 'vuelidate/lib/validators';
import { $saveCompanyPractice } from '../Services/index';
import { mapGetters } from 'vuex';

export default {
  name: 'AboutPP',
  props: {
    companyPractice: { type: Object },
  },
  components: {
    FormPP,
    AutocompleteInput,
    Button,
  },
  data() {
    return {
      inscription: {
        razonSocial: null,
        validacion: null,
        direccion: null,
        cuit: null,
        contacto: null,
        email: null,
        telefono: null,
        cargoReferente: null,
        legajo: null,
        ppId: null,
        tipoEmpresaSeleccionId: null,
        localidadId: null,
        estadoPPId: null,
        fechaModificacion: null,
        practicaHorariosDTOList: [],
      },
      invalidDays: false,
      studentLocation: null,
      name_business: null,
      selectCompany: 263,
      createButton: {
        loading: false,
        success: false,
        error: false,
      },
      file:null,
      toggle_exclusive:undefined,
    };
  },
  computed: {
    ...mapGetters({
      academicRegister: 'commons/getStudentActualRegister',
      userData: 'commons/getUserData',
      studentUserData: 'commons/getStudentUserData',
      studentCareer: 'commons/getStudentCareer',
    }),
  },
  methods: {
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    selectOption(value) {
      this.selectCompany = value;
      this.inscription.practicaHorariosDTOList = [];
    },
    changeValueInvalid(value) {
      this.invalidDays = value;
    },
    validateForm() {
      if (this.$v.$invalid) {
        this.invalidDays = true;
        this.$refs.studentLocation.triggerOuterValidation();
        this.$v.$touch();
        // solo si hay un error en studentLocation
        if (!this.studentLocation) {
          this.$refs.studentLocation.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }
      } else {
        this.sendFormPP();
      }
    },
    async sendFormPP() {
      this.createButton.loading = true;
      this.inscription.fechaModificacion = this.companyPractice
        ? this.companyPractice.fechaModificacion
        : null;
      this.inscription.legajo = this.academicRegister;
      this.inscription.estadoPPId = 264;
      this.inscription.tipoEmpresaSeleccionId = this.selectCompany;
      this.inscription.ppId = this.companyPractice
        ? this.companyPractice.ppId
        : null;
      this.localidadId = 528;
      this.inscription.validacion = null;
      this.inscription.localidadId = this.studentLocation.idCity
        ? this.studentLocation.idCity
        : null;
      this.inscription.regionId = this.studentLocation.idRegion
        ? this.studentLocation.idRegion
        : null;
      if (this.selectCompany == 263) {
        this.inscription.practicaHorariosDTOList = [];
        this.file = null
      }
      try {
        const formData = new FormData();
        formData.append('model', JSON.stringify(this.inscription));
        formData.append('file', this.file);
        const res = await $saveCompanyPractice.formData(formData);
        if (res.status == 200) {
          this.createButton.loading = false;
          this.createButton.success = true;
          this.$emit('changeStep');
        }
      } catch (err) {
        this.createButton.loading = false;
        this.createButton.error = true;
        return false;
      }
    },
  },
  validations: {
    studentLocation: {
      required,
    },
    inscription: {
      razonSocial: {
        required,
      },
      direccion: {
        required,
      },
      cuit: {
        maxLength: maxLength(13),
        minLength: minLength(10),
        required,
      },
      contacto: {
        required,
      },
      cargoReferente: {
        required,
      },
      email: {
        required,
        email,
      },
      telefono: {
        required,
      },
      practicaHorariosDTOList: {
        required: requiredIf(function () {
          return this.selectCompany == 264;
        }),
      },
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-btn__content 
  white-space: normal
  flex: auto
  min-width: min-content
</style>
