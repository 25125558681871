<template>
	<section>
		<v-data-table
			:headers="headers"
			:items="subtypeSimpleDocs"
			:items-per-page="50"
			class="elevation-0 text--secondary"
			hide-default-footer
			style="width: 100%"
			dense
			:loading="loading"
			item-key="studentId"
		>
			<template v-slot:item="{ item }">
				<tr v-if="item.id == selected.id" class="grey lighten-3">
					<td>
						<v-text-field
							class="my-2"
							outlined
							:label="$t('modules.academic.documentation.docTypes.headers.description')"
							v-model.trim="selected.description"
							:error-messages="
								$v.selected.description.$dirty && $v.selected.description.$error
									? $t('modules.academic.documentation.form.required')
									: ''
							"
							hide-details
							:disabled="loading"
							@blur="$v.selected.description.$touch()"
						/>
					</td>
					<td>
						<v-text-field
							class="my-2"
							outlined
							:label="$t('modules.academic.documentation.docTypes.headers.subDescription')"
							v-model.trim="selected.subDescription"
							:error-messages="
								$v.selected.subDescription.$dirty && $v.selected.subDescription.$error
									? $t('modules.academic.documentation.form.required')
									: ''
							"
							hide-details
							:disabled="loading"
							@blur="$v.selected.subDescription.$touch()"
						/>
					</td>
					<td>
						<v-text-field 
							class="my-2"
							outlined
							v-model.trim="selected.validity" 
							type="number" 
							:disabled="loading"
							:label="$t('modules.academic.documentation.docTypes.headers.validity')"
							:error-messages="
								$v.selected.validity.$dirty && $v.selected.validity.$error
									? $t('modules.academic.documentation.form.invalid')
									: ''
							"
							hide-details
							@blur="$v.selected.validity.$touch()"
						/>
					</td>
					<td>
						<v-btn icon @click="openModal('insert',item)" v-if="item.documentation" :disabled="loading">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
						<v-btn icon @click="openModal('update',item)" v-else :disabled="loading">
							<v-icon>mdi-check</v-icon>
						</v-btn>
						<v-btn icon @click="reset()" :disabled="loading" v-if="!item.documentation">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</td>
					<td>
						<v-btn icon @click="deleteNewSubType(item)" v-if="item.documentation" :disabled="loading">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn icon @click="openModal('delete',item)" v-else :disabled="loading">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
				<tr v-else>
					<td style="text-transform: capitalize">
						{{ item.description ? item.description.toLowerCase() : ''  }}
					</td>
					<td style="text-transform: capitalize">
						{{ item.subDescription ? item.subDescription.toLowerCase() : '' }}
					</td>
					<td>{{ item.validity ? item.validity : 0 }}</td>
					<td>
						<v-btn icon @click="edit(item)" :disabled="loading">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</td>
					<td>
						<v-btn icon @click="deleteNewSubType(item)" v-if="item.documentation" :disabled="loading">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
						<v-btn icon @click="openModal('delete',item)" v-else :disabled="loading">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
		<v-dialog
			v-model="successMessage.active"
			max-width="600px"
			:persistent="successMessage.type === 'success'"
			@click:outside="resetModal"
		>
			<SuccessMessage 
				class="white"
				:type="successMessage.type"
				:title="successMessage.title"
				:actionPrimary="successMessage.actionPrimary"
				:actionSecondary="successMessage.actionSecondary"
			/>
		</v-dialog>
	</section>
</template>

<script>
import { $documentations } from '../Services';
import { required, helpers, maxValue, minValue } from 'vuelidate/lib/validators';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

export default {
	name: 'SubTypeTable',
	props: {
		subtypeSimpleDocs: Array,
		typeId: Number,
	},
	components: {
		SuccessMessage,
	},
	data() {
		return {
			headers: [
				{
					text: this.$t('modules.academic.documentation.docTypes.headers.description'),
					value: 'description',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.docTypes.headers.subDescription'),
					value: 'subDescription',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.docTypes.headers.validity'),
					value: 'validity',
					sortable: false,
				},
				{
					text: '',
					value: 'edit',
					sortable: false,
				},
				{
					text: '',
					value: 'delete',
					sortable: false,
				},
			],
			loading: false,
			selected: {},
			successMessage: {
				active: false,
				actionPrimary: null,
				actionSecondary: null,
				title: null,
				type: null,
			},
		};
	},
	methods: {
		async insertSubType(item) {
			if (this.selected.id != item.id) return;
			await this.$v.$touch();
			if (this.$v.$error) return;
			try {
				this.loading = true;
				await $documentations.create(
					{
						description: this.selected.description,
						subDescription: this.selected.subDescription,
						required: false,
						validity: this.selected.validity ? this.selected.validity : 0,
						status: true,
						documentation: {
							id: this.typeId,
						},
					},
					null,
					`subtypes`
				);
				this.openModalSuccess('inserted')
			} catch (e) {
				this.openModalError('E209');
				throw e;
			} finally {
				this.loading = false;
			}
		},
		async updateSubType(item) {
			if (this.selected.id != item.id) return;
			await this.$v.$touch();
			if (this.$v.$error) return;
			try {
				this.loading = true;
				await $documentations.update(`subtypes/${this.selected.id}`, {
					description: this.selected.description,
					required: true,
					subDescription: this.selected.subDescription,
					validity: this.selected.validity,
					status: this.selected.status,
				});
				this.openModalSuccess('updated')
			} catch (e) {
				this.openModalError('E210');
				throw e;
			} finally {
				this.loading = false;
			}
		},
		async deleteSubType(item) {
			if (!item) return;
			try {
				this.loading = true;
				await $documentations.update(`subtypes/${item.id}`, {
					description: item.description,
					required: true,
					subDescription: item.subDescription,
					validity: item.validity,
					status: false,
				});
				this.openModalSuccess('deleted')
			} catch (e) {
				this.openModalError('E211');
				throw e;
			} finally {
				this.loading = false;
			}
		},
		edit(item) {
			if(!this.subtypeSimpleDocs[this.subtypeSimpleDocs.length-1]?.description) this.deleteNewSubType();
			this.reset();
			this.selected = { ...item };
		},
		deleteNewSubType() {
			this.subtypeSimpleDocs.pop();
			this.reset();
		},
		reset() {
			this.selected = {};
			this.$v.$model = {};
			this.$v.$reset();
		},
		openModal(type,item){
			if(type != 'delete') {
				this.$v.$touch();
				if (this.$v.$error) return;
			}
			this.successMessage.active = true;
			this.successMessage.type = 'warning';
			this.successMessage.title = `
				${(this.$t(`modules.academic.documentation.popUp.subtype.${type}`))} 
				"${item?.description ? item?.description : this.selected.description}" 
				${this.$t('modules.academic.documentation.popUp.continue')}
			`;
			this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { 
				switch(type){
					case 'insert':
						this.insertSubType(item);
						break;
					case 'update':
						this.updateSubType(item);
						break;
					case 'delete':
						this.deleteSubType(item);
						break;
					default:
						break;
				}
			} };
			this.successMessage.actionSecondary = { text: this.$t('actions.cancel'), callback: () => { this.resetModal() } };
		},
		openModalSuccess(type){
			this.successMessage.active = true;
			this.successMessage.type = 'success';
			this.successMessage.title = this.$t(`modules.academic.documentation.popUp.subtype.${type}`);
			this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { 
				this.reset();
				this.resetModal();
				this.$emit('getDocTypes'); 
			} };
			this.successMessage.actionSecondary = null;
		},
		openModalError(type){
			this.successMessage.active = true;
			this.successMessage.type = 'error';
			this.successMessage.title = this.$t(`responseCodeMeaning.${type}`);
			this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.resetModal() } };
			this.successMessage.actionSecondary = null;
		},
		resetModal(){
			if(this.successMessage.type === 'success') return;
			this.successMessage.active = false;
			this.successMessage.type = null;
			this.successMessage.title = null;
			this.successMessage.actionPrimary = null;
			this.successMessage.actionSecondary = null;
		},
	},
	validations: {
		selected: {
			description: { required, alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i) },
			subDescription: { required, alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i) },
			validity: { maxValue: maxValue(3600), minValue: minValue(0) }
		},
	},
};
</script>
