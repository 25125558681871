<template >
<div class="loginFormCard fullHeightViewport">
  <div class="py-6 d-flex flex-column">
    <div class="d-flex justify-start">
      <v-img
        max-height="50px"
        max-width="150px"
        class="loginFormCard__logo"
        contain
        :src="logoPath"
      ></v-img>
    </div>
    <div>
      <h3 class="display-1 primary--text">{{ recoveryData.title }}</h3>
      <p class="body-2 mb-0">{{ recoveryData.description }}</p>
    </div>
  </div>
  <form @keyup.enter="logIn">
    <v-sheet class="error">
    </v-sheet>
    <slot></slot>
    <div class="py-4" v-if="recoveryData.detail">
      <p class="caption detail-text">{{ recoveryData.detail }}</p>
    </div>
    <div class="py-3">
      <vue-recaptcha :sitekey="siteKey" loadRecaptchaScript @verify="onVerify"
        @expired="onExpired"></vue-recaptcha>
    </div>
    <div class="d-flex justify-space-between pt-3">
      <v-btn @click="goBack" class="px-6" text rounded large>Atrás</v-btn>
      <v-btn @click="sendSecurityCode" color="primary" class="px-6" :loading="loading" rounded large :disabled="!(canGoNextStep && validCaptcha)">Siguiente</v-btn>
    </div>
  </form>
</div>

</template>

<style lang="sass">
    @media (max-width: 768px)
        .loginFormCard
            & .display-1
                margin-top: 1rem
            & form
                & .detail-text
                    margin-bottom: 0 !important
</style>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RecoveryForm',
  components: {
    VueRecaptcha
  },
  props: {
    loading: Boolean,
    logoPath: String,
    recoveryData: Object,
    canGoNextStep: Boolean
  },
  data () {
    return {
      validCaptcha: false,
    }
  },
  computed: {
    siteKey() {
      if(this.$country === 'peru') {
        return '6Ld3k50jAAAAAPJyOUv3bwutLvrBx0nmdFYkKHVb'
      }
      if(this.$country === 'argentina') {
        return '6Le3p9AbAAAAAALXOsWRMmiOhllexcDsnlAOK9R2'
      }
      if(this.$country === 'chile') {
        return '6LcOzcIUAAAAAJkIG1uFST_T2T_NOmRPrBylyQWC'
      }
      else return ''
    }
  },
  methods: {
    sendSecurityCode () {
      this.$emit('sendSecurityCode');
    },
    goBack () {
      this.$router.go(-1)
    },
    onVerify () {
      this.validCaptcha = true;
    },
    onExpired () {
      this.validCaptcha = false;
    },
  }
};
</script>
