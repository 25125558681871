import { render, staticRenderFns } from "./RecoveryPassForm.vue?vue&type=template&id=56c5487c&scoped=true&"
import script from "./RecoveryPassForm.vue?vue&type=script&lang=js&"
export * from "./RecoveryPassForm.vue?vue&type=script&lang=js&"
import style0 from "./RecoveryPassForm.vue?vue&type=style&index=0&id=56c5487c&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c5487c",
  null
  
)

export default component.exports