
const subjectsSyncTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Sincronización de Materias',
        description: 'Administración de sincronización de materias'
      },
      headers: {
        code: 'Código',
        description: 'Nombre',
        termCode: 'Periodo',
        oldModality: 'R19',
        synchronize: 'Sincronización',
      },
      popupTitle: 'Estás seguro de {action}?',
      sync: 'Sincronizar',
      desync: 'Desincronizar',
      updateError: 'No se pudo {action} la materia',
      bulkUpdateError: 'No se pudieron {action} las materias seleccionados',
      labels: {
        term: 'Periodo'
      }
    },
    header:{
      type:{
        course:'Cursado',
        onBoarding:'On Boarding',
        leveling:'Nivelación',
        practice:'Prácticas Profesionales'
      }
    }
  },
  es_CL: {
    table: {
      breadcrumbs: {
        title: 'Sincronización de Asignaturas',
        description: 'Administración de sincronización de asignaturas'
      },
      updateError: 'No se pudo {action} la asignatura',
      bulk_update_error: 'No se pudieron {action} las asignaturas seleccionados',
    }
  },
  es_PE: {
      table: {
          breadcrumbs: {
              title: 'Sincronización de Cursos',
              description: 'Administración de sincronización de cursos'
          },
          updateError: 'No se pudo {action} el curso',
          bulkUpdateError: 'No se pudieron {action} los cursos seleccionados',
      },
  },  
  en: {

  }
};

export default subjectsSyncTranslations
