<template>
  <div v-if="banner.type.meaning.toUpperCase() == 'CREADOR DE BANNER MANUAL'" class="banner" :style="`background: ${banner.color}; backgroundImage: url(${banner.bgImg})`">
    <div :class="['banner__content', {'right': banner.image.position == 1}]">
      <img :src="banner.image.src" :class="['banner__content__img', {'img-corner': banner.image.corner}]">
      <div class="banner__content__text" v-if="banner.text[1]">
        <div class="banner__content__text__title" v-html="banner.text[0]"></div>
        <div class="banner__content__text__comment" v-html="banner.text[1]"></div>
      </div>
      <p v-html="banner.text[0]" v-else></p>
    </div>
    <div class="d-flex">
      <a v-for="(button, index) in banner.button" :key="index" class="banner__button">{{button.text}}</a>
    </div>
  </div>
  <v-img class="gif" v-else :src="`${banner.image.src}`" :style="'transform: scale(1)'"></v-img>
</template>

<script>
export default {
  name: 'GenericBanner',
  props: {
    banner: Object,
  }
}
</script>

<style lang="sass" scoped>
.banner
  width: 88%
  margin: auto
  border-radius: 5px
  padding: 20px
  background-repeat: no-repeat !important
  background-size: contain !important
  background-position: center !important
  border: #BCC4CB dashed 1px
  &__content
    display: flex
    flex-direction: row
    align-items: center
    p
      width: 70%
      font-size: 14px
      color: #fff
      margin-left: .5em
      text-align: center
      word-break: break-word
    &__text
      display: flex
      flex-direction: column
      flex-grow: 1
      align-items: center
      text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.4)
      word-break: break-word
      &__title
        color: #ffffff
        font-weight: bold
        font-size: 19px
      &__comment
        color: #ffffff
        font-size: 12px
        line-height: 14px
        margin-top: 1em
    &__img
      max-width: 50%
      margin-right: 10px
      object-fit: contain
  &__button
    background: #fff
    width: 90%
    height: 33px
    border-radius: 16px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    display: flex
    justify-content: center
    align-items: center
    color: #012169
    font-size: 12px
    margin: 1.5em 10px auto
    text-decoration: none
    &:hover
      cursor: pointer

  .right
    flex-direction: row-reverse

  .img-corner
      margin-top: -20px
      margin-left: -20px
      margin-bottom: -1.5rem !important
      align-self: flex-start

  .right
    > .img-corner
      margin-right: -20px !important
    > .banner__content__img
      margin-left: 10px !important
      margin-right: 0

</style>
