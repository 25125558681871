const appBarTranslations = {
  es_CL: {
    no_student: 'No se encontró un estudiante con el RUT {identification}',
    communications: {
      new_message: 'Tienes un nuevo mensaje',
    },
  },
  es: {
    no_student: 'No se encontró un estudiante con el DNI {identification}',
    navigationDrawerApps: {
      'title': 'Aplicaciones',
    },
    profileDropdown: {
      'title': 'Mi cuenta',
      'notifications': 'Notificaciones',
      'logout': 'Cerrar sesión'
    },
    communications: {
      title: 'Comunicaciones',
      hello: 'Hola {student}',
      new_message: 'Tenés un nuevo mensaje',
      empty_title: 'Todo tranquilo por acá',
      empty_subtitle: 'No tenés nada nuevo',
      see_all: 'Ver todos',
      time: {
        minutes: 'Hace {minutes} min.',
        hours: 'Hace {n} hora | Hace {n} horas',
        yesterday: 'Ayer',
      }
    }
  },

  en: {
    navigationDrawerApps: {
      'title': 'Applications',
    },
    profileDropdown: {
      'title': 'My account',
      'notifications': 'Notifications',
      'logout': 'Logout'
    }
  }
};

export default appBarTranslations




