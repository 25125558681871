const SubjectsTranslations = {
    es_PE: {
        create: {
            breadcrumbs: {
                title: 'Nuevo curso',
                description: 'Crea un nuevo curso',
            },
            cardOne: {
                title: 'Datos del curso',
                labelEnable: 'Habilitado',
                labelDisable: 'Deshabilitado',
                subtitle: 'Agrega un nombre y condiciones de aprobación al curso. Recuerda que estos datos deben ser lo más precisos posible ya que establecerán diferentes criterios de cursado.',
                items: {
                    'type': 'Tipo de curso',
                },
            },
            cardTwo: {
                subtitle: 'Selecciona las competencias que va a adquirir el estudiante al finalizar el curso.',
            },
            validations: {
                modalityTypesRequired: 'Selecciona un tipo de curso',
            },
            success_message: {
                title: 'Curso creado correctamente',
            },
            error_message: {
                title: 'Se produjo un error al crear el curso',
            },
            warning_status: {
                title: 'Estás creando un curso deshabilitado',
            }
        },
        update: {
            breadcrumbs: {
                title: 'Editar curso',
                description: 'Edita un Curso',
            },
            cardOne: {
                title: 'Datos del curso',
                labelEnable: 'Habilitado',
                labelDisable: 'Deshabilitado',
                subtitle: 'Agrega un nombre y condiciones de aprobación al curso. Recuerda que estos datos deben ser lo más precisos posible ya que establecerán diferentes criterios de cursado.',
                items: {
                    'type': 'Tipo de curso',
                }
            },
            cardTwo: {
                title: 'Competencias',
                subtitle: 'Selecciona las competencias que va a adquirir el estudiante al finalizar el curso.',
                label1: 'Disponibles',
                label2: 'Seleccionados',
            },
            validations: {
                modalityTypesRequired: 'Selecciona un tipo de curso',
            },
            warning_popup: {
                title: 'Se está guardando el curso como deshabilitado',
            },
            success_message: {
                title: 'Curso modificado correctamente',
                description: 'modificado',
            },
            error_message: {
                title: 'Se produjo un error al modificar el curso',
            }
        },
    },
    es: {
        create: {
            breadcrumbs: {
                title: 'Nueva asignatura',
                description: 'Crea una nueva asignatura',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary'
                }
            },
            cardOne: {
                title: 'Datos de la asignatura',
                labelEnable: 'Habilitada',
                labelDisable: 'Deshabilitada',
                subtitle: 'Agrega un nombre y condiciones de aprobación a la asignatura. Recuerda que estos datos deben ser lo más precisos posible ya que establecerán diferentes criterios de cursado.',
                items: {
                    'name': 'Nombre',
                    'code': 'Código',
                    'type': 'Tipo de asignatura',
                    'approve': 'Aprueba con',
                    'credits': 'Créditos',
                    'hours': 'Horas',
                    'duration': 'Duración',
                    'label1': 'Bimestral',
                    'label2': 'Semestral',
                    'modality': 'Reglamento 19',
                    'examType': 'Tipo de examen'
                },
            },
            cardTwo: {
                title: 'Competencias',
                subtitle: 'Selecciona las competencias que va a adquirir el estudiante al finalizar la asignatura.',
                label1: 'Disponibles',
                label2: 'Seleccionadas',
                no_selected_warning: 'Selecciona al menos una competencia',
            },
            buttonCompetition: {
                label: 'Nueva competencia'
            },
            validations: {
                codeRequired: 'Ingresa el código',
                modalityTypesRequired: 'Selecciona un tipo de asignatura',
                creditsRequired: 'Ingresa créditos',
                hoursRequired: 'Ingresa horas',
                durationRequired: 'Selecciona una duración',
                codeExist: 'Ese código ya existe',
                examTypeRequired: 'Modalidad de examen requerida'
            },
            success_message: {
                title: 'Asignatura creada correctamente',
                competence_title: 'Competencia creada correctamente',
                description: 'creada',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otra',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear la asignatura',
                competence_title: 'Se produjo un error al crear la competencia',
            },
            warning_status: {
                title: 'Estás creando una asignatura deshabilitada',
                actions: {
                    primary_text: 'Crear',
                    secondary_text: 'Cancelar',
                },
            }
        },
        update: {
            breadcrumbs: {
                title: 'Editar asignatura',
                description: 'Edita una Asignatura',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary'
                }
            },
            cardOne: {
                title: 'Datos de la asignatura',
                labelEnable: 'Habilitada',
                labelDisable: 'Deshabilitada',
                subtitle: 'Agrega un nombre y condiciones de aprobación a la asignatura. Recuerda que estos datos deben ser lo más precisos posible ya que establecerán diferentes criterios de cursado.',
                items: {
                    'name': 'Nombre',
                    'code': 'Código',
                    'type': 'Tipo de asignatura',
                    'approve': 'Aprueba con',
                    'credits': 'Créditos',
                    'hours': 'Horas',
                    'duration': 'Duración',
                    'label1': 'Bimestral',
                    'label2': 'Semestral',
                }
            },
            cardTwo: {
                title: 'Competencias',
                subtitle: 'Selecciona las competencias que va a adquirir el estudiante al finalizar la asignatura.',
                label1: 'Disponibles',
                label2: 'Seleccionadas',
            },
            buttonCompetition: {
                label: 'Nueva competencia'
            },
            validations: {
                descriptionRequired: 'Ingresa el nombre',
                codeRequired: 'Ingresa el código',
                modalityTypesRequired: 'Selecciona un tipo de asignatura',
                creditsRequired: 'Ingresa créditos',
                hoursRequired: 'Ingresa horas',
                durationRequired: 'Selecciona duración',
                codeExist: 'Ese código ya existe'
            },
            warning_popup: {
                title: 'Se está guardando la asignatura como deshabilitada',
                content: '',
                update: 'Guardar',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Asignatura modificada correctamente',
                description: 'modificada',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar la asignatura',
            }
        },
        competition: {
            breadcrumbs: {
                title: 'Nueva competencia',
                subtitle: 'Crea una nueva competencia',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary'
                }
            },
            cardOne: {
                title: 'Nombre y descripción',
                subtitle: 'Define un nombre y una descripción para la nueva competencia.',
                items: {
                    'name': 'Nombre',
                    'description': 'Descripción',
                },

            },
            validations: {
                nameRequired: 'Ingresa nombre',
                descriptionRequired: 'Ingresa descripción',
            }
        },
        table: {
            breadcrumbs: {
                title: 'Asignaturas',
                description: 'Administración de asignaturas'
            },
            headers: {
                'code': 'Código',
                'description': 'Nombre',
                'type': 'Tipo',
                'status': 'Estado',
                'credits': 'Créditos',
                'hours': 'Horas',
                'countCareers': 'Carreras',
                'action': ''
            },
            message: {
                delete: '¿Estás seguro de eliminar?',
                title: 'Estás a punto de eliminar la siguiente asignatura',
                deleteAction: 'Si, eliminar',
                cancelAction: 'Cancelar'
            },
            search: {
                search: 'Buscar',
                gotopage: 'ir a página',
                edit: 'Editar',
                delete: 'Eliminar',
                bulkDelete: 'Eliminar asignaturas'
            },
            error_message: {
                delete_message: 'No se puede eliminar la asignatura',
                bulk_delete_message: 'No se pueden eliminar la asignaturas'
            }
        },
        validations: {
            nameRequired: 'Ingresa el nombre de la asignatura',
            nameAlphanumericWithSpaces: 'Solo alfanuméricos',
            nameMaxLength: 'Máximo 60 caracteres',
            codeRequired: 'Ingresa el código de la asignatura',
            codeAlphanumeric: 'Solo alfanuméricos',
            codeMaxLength: 'Máximo 10 caracteres',
            codeUnique: 'Código ya existente',
            examTypeRequired: 'Seleccione el tipo de modalida del examen',
            typeRequired: 'Seleccione el tipo de asignatura',
            durationRequired: 'Seleccione una duración'
        }

    },

    en: {



    }
}


export default SubjectsTranslations
