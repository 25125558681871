const preregistrationTranslations = {
  es_CL: {
    table: {
      breadcrumbs: {
        title: 'Preinscripción de alumno',
        description: 'Administración de preinscripción de alumno',
      }
    },
    create: {
      breadcrumbs: {
        title: 'Preinscripción de alumno',
      },
      preregistrationInfo: {
        address: {
          labels: {
            city: 'Comuna'
          }
        },
        previousStudies: {
          labels: {
            city: 'Comuna'
          }
        }
      },
    }
  },
  es_PE: {
      create: {
          preregistrationInfo: {
              address: {
                  labels: {
                      city: 'Distrito'
                  }
              },
              previousStudies: {
                  labels: {
                      city: 'Distrito',
                  }
              }
          },
          emailSummary: {
              studentData: {
                  city: 'Distrito',
              },
          },
          leadInfo: {
              labels: {
                  city: 'Distrito',
              }
          },
          priceSimulator: {
              subtitles: {
                  goodAverage: 'Buen Estudiante',
              }
          },
          errorMessage: {
              withoutPrice: 'No se han encontrado precios para dicha carrera y distrito',
          }
      },
      validations: {
          cityRequired: 'Ingrese el distrito',
          addressCityRequired: 'Ingrese el distrito',
          institutionCityRequired: 'Ingrese el distrito para buscar instituciones'
      },
  },  
  es: {
    table: {
      breadcrumbs: {
        title: 'Preinscripción de estudiante',
        description:'Administración de preinscripción de estudiante',
      },
      headers: {
        name: 'Nombre',
        identification: 'Identificación',
        career: 'Carrera',
        startDate: 'Inicio',
        status: 'Estado',
      },
      messages: {
        deleteSuccess: 'Preinscripción dada de baja correctamente',
        deleteError: 'No se dar de baja la preinscripción seleccionado',
        deletePopup: '¿Estás seguro de dar de baja?'
      }
    },
    create: {
      breadcrumbs: {
        title: 'Preinscripción de estudiante',
        description:'Crear una nueva preinscripción',
      },
      stepper: {
        first: 'Info del Lead',
        second: 'Simular Precio',
        third: 'Formas de pago',
        fourth: 'Preinscripción'
      },
      leadInfo: {
        title: 'Información del Lead',
        labels: {
          leadEmail: 'Email lead',
          career: 'Carrera',
          studyPlan: 'Plan de estudio',
          name: 'Nombre',
          city: 'Ciudad',
          telephone: 'Teléfono',
        }
      },
      priceSimulator: {
        title: 'Simulador de Precios',
        subtitles: {
          priceList: 'Precio de Lista',
          goodAverage: 'Buen Alumno',
          discounts: 'Descuentos',
          benefits: 'Beneficios',
          scholarships: 'Becas'
        },
        alerts: {
          discount: 'Descuento válido hasta el {date}'
        },
        benefits: {
          title: 'Beneficios',
          labels: {
            communityCompany: 'Empresa Comunidad',
            referredFriend: 'Amigo Referido',
            referredCompany: 'Empresa Referida'
          },
          newReferredCompany: {
            title: 'Agregar empresa referida',
            labels: {
              name: 'Razón social',
              cuit: 'CUIT',
              contactName: 'Contacto',
              position: 'Cargo',
              email: 'Email',
              cellphone: 'Teléfono'
            },
          }
        },
        scholarship: {
          title: 'Becas',
          labels: {
            select: 'Seleccionar Beca',
            enrollment: 'Matricula {discount}',
            tariffA: 'Arancel A {discount}',
          }
        }
      },
      paymentMethod: {
        title: 'Formas de Pago',
        type: {
          credit: 'Tarjeta de crédito',
          prepaid: 'Pronto pago',
          cash: 'Contado',
          subscription: 'Suscripción'
        },
        alerts: {
          totalDues: 'Si paga el total de las cuotas obtiene una bonificación del {percentage}% adicional'
        },
        labels: {
          creditCardType: 'Tipo de tarjeta',
          bank: 'Banco',
          prepaidDues: 'Cuotas',
          subscriptionDues: 'Cantidad de cuotas',
          totalDues: '{percentage}% extra por pago total de cuotas!',
          cash: 'Realizaras un solo pago de',
          subscriptionPaymentMethod: 'Forma de Pago',
          prepaidDayRange: 'Pagando del {dayFrom} al {dayTo}',
          installmentsOf: '{installments} cuotas de {installmentAmount}'
        },
        subtitle: {
          duesPrepaid: 'Pagaras {dues} cuotas de',
          prepaidTotal: 'Pagaras un total de'
        }
      },
      preregistrationInfo: {
        title: 'Preinscripción',
        saleTerm: {
          title: 'Periodo de ingreso',
          labels: {
            term: 'Periodo'
          }
        },
        applicantData: {
          title: 'Datos postulante',
          labels: {
            name: 'Nombre',
            lastname: 'Apellido',
            birthDate: 'Fecha nacimiento',
            birthPlace: 'Lugar de nacimiento',
            nationality: 'Nacionalidad',
            identificationType: 'Tipo identificación',
            identification: 'Número',
            serialNumber: 'Número de serie',
            gender: 'Género',
            maritalStatus: 'Estado civil',
            email: 'Email',
            telephone: 'Teléfono',
            career: 'Carrera',
            studyPlan: 'Plan de estudio'
          }
        },
        address: {
          title: 'Domicilio',
          labels: {
            address: 'Domicilio',
            number: 'Número',
            floor: 'Piso',
            apartment: 'Departamento',
            zipCode: 'Código postal',
            city: 'Localidad'
          }
        },
        previousStudies: {
          title: 'Estudios previos',
          subtitle: 'Datos del colegio',
          labels: {
            level: 'Nivel de estudios alcanzados',
            city: 'Localidad',
            institution: 'Nombre del colegio'
          }
        }
      },
      summary: {
        buttons: {
          sendSummary: 'Enviar resumen',
          sendPaymentLink: 'Enviar link de pago'
        },
        title: 'Tu Resumen',
        enrollmentPlaceholder: 'Matrícula',
        tariffPlaceholder: 'Arancel',
        total: 'TOTAL',
        totalSaving: 'Ahorro Total',
        totalSavingToday: '¡Si pagás hoy ahorrás {totalSaving}!',
        subtotal: 'Subtotal',
        discount: 'Descuento',
        discounts: 'Descuentos',
        dues: 'Con {cardName} abonarás \n {installments} cuotas de {installment_amount} por mes',
        duesAdminExpenses: 'más gastos administrativos de {adminExpenses}',
        prepaids: 'Pagando del {dayFrom} al {dayTo} te ahorras {saving}',
        approval: '* Beneficio sujeto a aprobación de {theme}',
        validity: '* Promo válida hasta {todayDate}'
      },
      emailSummary: {
        subject: 'Tu resumen de aranceles',
        validity: 'Descuento válido hasta <br> el {todayDate}',
        totalSaving: 'Ahorro Total',
        totalSavingToday: '¡Si pagás hoy ahorrás {totalSaving} por mes!',
        buttons: {
          sendSummary: 'Enviar resumen'
        },
        greeting: {
          title: 'Hola {name}',
          subtitle: 'Te compartimos el resumen de tu consulta sobre aranceles.',
          date: 'Fecha'
        },
        studentData: {
          career: 'Carrera',
          term: 'Periodo',
          city: 'Localidad',
          adviser: 'Asesor'
        },
        footer: {
          titleIPP: '¡Es tu momento de estudiar en IPP!',
          titleTeclab: '¡Cambiá tu futuro, estudiá una carrera!',
          subtitleIPP: '38 años formando profesionales y siendo lideres de la educación en Chile',
          subtitleTeclab: '¡La grandeza está en vos, Teclab está en vos! - El equipo de Teclab.edu.ar',
          telephoneIPP: '600 4600 477',
          telephoneTeclab: '0810 888 9900',
          cellphoneIPP: '+227603370'
        }
      },
      successMessage: {
        preregistration: 'Preinscripción creada correctamente',
        lead: 'Lead creado correctamente',
        emailSummary: 'Email enviado correctamente',
        referredCompany: 'Empresa referida creada correctamente',
        paymentLink: 'Link de pago enviado correctamente'
      },
      errorMessage: {
        preregistration: 'Ocurrió un error al crear la preinscripción',
        lead: 'Ocurrió un error al crear el lead',
        withoutPrice: 'No se han encontrado precios para dicha carrera y ciudad',
        emailSummary: 'Ocurrió un error al enviar el resumen',
        referredCompany: 'Ocurrió un error al crear la empresa referida',
        paymentLink: 'Ocurrió un error al enviar el link de pago'
      }
    },
    update: {
      breadcrumbs: {
        title: 'Preinscripción de estudiante',
        description:'Actualizar una nueva preinscripción',
      },
      summary: {
        title: 'Postulación {admissionTerm}',
        discount: 'Descuento',
      },
      successMessage: {
        update: 'Preinscripción actualizada correctamente',
        emailSummary: 'Email enviado correctamente'
      },
      errorMessage: {
        update: 'Ocurrió un error al actualizar la preinscripción',
        lead: 'Ocurrió un error al actualizar el lead',
        emailSummary: 'Ocurrió un error al enviar el resumen',
      }
    },
    validations: {
      leadEmailRequired: 'Ingrese el email del lead',
      leadEmailFormat: 'Ingrese un formato de email válido',
      careerTypeRequired: 'Debe seleccionar un tipo de carrera',
      careerRequired: 'Debe seleccionar una carrera',
      cityRequired: 'Ingrese la ciudad',
      prepaidDuesRequired: 'Debe seleccionar cantidad de cuotas',
      subscriptionPaymentMethodRequired: 'Debe seleccionar una forma de pago',
      subscriptionDuesRequired: 'Debe seleccionar cantidad de cuotas',
      saleTermRequired: 'Ingrese el periodo',
      nameRequired: 'Ingrese el nombre',
      nameAlphabeticWithSpaces: 'Solo alfabéticos',
      nameMaxLength: 'Máximo 60 caracteres',
      lastnameRequired: 'Ingrese el apellido',
      lastnameAlphabeticWithSpaces: 'Solo alfabéticos',
      lastnameMaxLength: 'Máximo 60 caracteres',
      birthDateRequired: 'Debe seleccionar una fecha',
      birthPlaceRequired: 'Ingrese el lugar de nacimiento',
      nationalityRequired: 'Ingrese la nacionalidad',
      identificationTypeRequired: 'Debe seleccionar un tipo de identificación',
      identificationRequired: 'Ingrese identificación',
      identificationUnique: 'Identificación ya existente',
      serialNumberRequired: 'Ingrese el número de serie',
      serialNumberNumeric: 'Solo numéricos',
      serialNumberMaxLength: 'Máximo 10 caracteres',
      genderRequired: 'Debe seleccionar un género',
      maritalStatusRequired: 'Debe seleccionar un estado civil',
      emailRequired: 'Ingrese el email',
      emailFormat: 'Formato de email inválido',
      studyPlanRequired: 'Debe seleccionar un plan de estudio',
      addressRequired: 'Ingrese el domicilio',
      addressAlphanumeric: 'Solo alfanuméricos',
      addressMaxLength: 'Máximo 60 caracteres',
      addressNumberRequired: 'Ingrese el número',
      addressNumberNumeric: 'Solo numéricos',
      addressNumberMaxLength: 'Máximo 10 caracteres',
      addressFloorRequired: 'Ingrese el piso',
      addressFloorNumeric: 'Solo numéricos',
      addressFloorMaxLength: 'Máximo 3 caracteres',
      addressApartmentRequired: 'Ingrese el departamento',
      addressApartmentAlphanumeric: 'Solo alfanuméricos',
      addressApartmentMaxLength: 'Máximo 4 caracteres',
      zipCodeRequired: 'Ingrese el código postal',
      zipCodeAlphanumeric: 'Solo alfanuméricos',
      zipCodeMaxLength: 'Máximo 10 caracteres',
      addressCityRequired: 'Ingrese la ciudad',
      studentLevelRequired: 'Debe seleccionar un nivel de estudio',
      institutionCityRequired: 'Ingrese la ciudad para buscar instituciones',
      institutionRequired: 'Debe seleccionar una institución',
      cuitRequired: 'Ingrese el CUIT',
      cuitFormat: 'Formato de CUIT inválido',
      cuitUnique: 'CUIT ya existente',
      businessNameRequired: 'Ingrese la razón social',
      businessNameAlphabeticWithSpaces: 'Solo alfabéticos',
      businessNameMaxLength: 'Máximo 60 caracteres',
      contactNameRequired: 'Ingrese el nombre',
      contactNameAlphabeticWithSpaces: 'Solo alfabéticos',
      contactNameMaxLength: 'Máximo 60 caracteres',
      positionRequired: 'Ingrese el cargo',
      positionAlphabeticWithSpaces: 'Solo alfabéticos',
      positionMaxLength: 'Máximo 60 caracteres',
      warnings: {
        leadEmailNotFound: 'El email ingresado no esta registrado en el sistema!',
        debt: 'El estudiante seleccionado posee una deuda pendiente de pago'
      },
      selectsEmpty: {
        saleTerm: 'No se encontraron periodos',
        career: 'No se encontraron carreras',
        identificationType: 'No se encontraron tipos de identificación',
        gender: 'No se encontraron géneros',
        maritalStatus: 'No se encontraron estados civiles',
        studentLevel: 'No se encontraron niveles de estudio',
        institution: 'No se encontraron instituciones',
        communityCompany: 'No se encontraron empresas comunidad',
        referredCompany: 'No se encontraron empresas referidas',
        scholarship: 'No se encontraron becas',
        studyPlan: 'No se encontraron planes de estudio',
        nationalities: 'No se encontraron nacionalidades',
        dues: 'No se encontraron cuotas',
        subscriptionPaymentMethod: 'No se encontraron formas de pago',
        subscriptionDues: 'No se encontraron cuotas'
      },
    },

  },
  en: {

  }
};

export default preregistrationTranslations
