<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.statusconnection.table.breadcrumbs.title')"
                    :description="$t('modules.statusconnection.table.breadcrumbs.description')"
                >
                </Breadcrumbs>
                <v-row class="my-4">
                    <v-col v-for="service in services" :key="service.id" cols="12" md="4" sm="6" class="mx-auto">
                        <v-card @click="getCheck(service, true)">
                            <v-card-title class="pb-0 justify-center">
                                <v-icon :color="$t(`modules.statusconnection.table.icon.${service.status}.color`)" x-large>
                                    {{$t(`modules.statusconnection.table.icon.${service.status}.description`)}}
                                </v-icon>
                            </v-card-title>
                            <v-card-title class="justify-center text-center text-break" >
                                {{$t(`modules.statusconnection.table.service.${service.id}.title`)}}
                            </v-card-title>
                            <v-card-text class="text-center mb-4 pb-0" style="min-height: 40px;" >
                                {{service.name}}  {{service.version}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import api from '@/api';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';

    export default {
        components: { 
            Breadcrumbs,
        },
        data(){
            return{
                services:[],
            }
        },
        mounted(){
           this.getService();
        },
        methods:{
            getService(){
                Object.entries(api).forEach(service => {
                    if(service[1].$checkStatus){
                        this.services.push({id:service[0], status:null, name:'', version:''})
                        this.getCheck(service)
                    }
                })
            },
            async getCheck(service, individual = false){
                const serv = individual ? service : this.services.find(el => el.id == service[0]);
                try {
                    if(individual) service.status = null;
                    const apiService = individual ? api[service.id] : service[1];
                    const data = await apiService.$checkStatus.find();
                    serv.name = data.data.name
                    serv.version = data.data.version
                    if(data.status === 200){
                        serv.status = true;
                    }
                } catch (error) {
                    serv.status = false;
                    throw error;
                }
            }
        }
   }
</script>