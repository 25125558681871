<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
        :title= "`${this.$route.query.code} - ${this.$route.query.description}`"
        >          
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
                :periods="periods"
                :headers="headers"
                :sections="sections"
                :clear-selected="clearSelected"
                :show-select="false"
                :page.sync="page"
                :itemsPerPage.sync="itemsPerPage"
                :totalElements="totalItems"
                :permissions="{update: canUpdate}"
                @updateUser="updateProgram"
                />
          </v-col>
      </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import SuperTable from '../Components/programsTable'
import {$statements} from '../Services/index'

export default {
name:'Programs',
components: {
  SuperTable,
  Breadcrumbs,
},
data() {
  return {
    sections: [],
    periods:[],

    page: 1,
    itemsPerPage: 20,

    totalItems: 0,
    paramSearch: null,
    paramPeriod: null,
    clearSelected: null,
    
    headers:[
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.code'),
        value: 'code',
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.name'),
        align: 'center',
        value: 'description',
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.questionsFinal'),
        align: 'center',
        value: 'countStatementsFinal',
        sortable: false,
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.questionsPractice'),
        align: 'center',
        value: 'countStatementsPractice',
        sortable: false,
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.updated'),
        align: 'center',
        value: 'updatedAt',
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.state'),
        align: 'center',
        value: 'status',
      },
      {
        text: '',
        value: 'action',
        sortable: false,
    }
    ]
  }
},
mounted () {
  this.getPrograms();
},
methods: {
  async getPrograms() {
    try {
      const programs = await $statements.find(`programs/${this.$route.params.subject_id}`, null, { params: { page: 0, length: 500, orderBy: 'description', orientation: 'asc' }})
      this.totalItems = programs?.data?.totalElements;
      this.sections = programs.data.content
      } catch  {
          this.programs = []
      }
  },
  updateProgram(id,code,description) {
      if (this.canUpdate) this.$router.push({path:`update/${id}`, query: {code, description}})
  },
},
computed: {
    canCreate() {
        return this.$permissions.portaladministrativo.exams.statements.CREATE
    },
    canUpdate() {
        return this.$permissions.portaladministrativo.exams.statements.READ || this.$permissions.portaladministrativo.exams.statements.UPDATE
    },    
},
}
</script>