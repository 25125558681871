const profileTranslations = {
    es_CL: {
      studentFinder: {
        breadcrumbs: {
          title: 'Buscador de estudiantes',
        },
        input: {
          cityWithState: 'Ciudad - Región',
          nameStudent: 'Nombre del estudiante',
        },
      },
    },
    es_PE: {
        sectionData: {
            about: 'Acerca de ti',
        },
        sectionContact: {
            apartment: 'Apartamento',
            cityWithState: 'Distrito',
            validations: {
                city: 'Ingresa mínimo 3 caracteres para filtrar el distrito',
            }
        },
        studentFinder: {
            breadcrumbs: {
                title: 'Buscador de estudiantes',
            },
            input: {
                cityWithState: 'Distrito - Departamento',
                nameStudent: 'Nombre del estudiante',
            },
        },
        perfilAlumno:{
            title:'Perfil del estudiante',
        }
    },
    es: {
      create:{
        passswordOld:'Ingrese contraseña actual *',
        passswordNew: 'Ingrese nueva contraseña *',
        passswordConfirmation: 'Confirme su nueva contraseña *',
        successMessage: 'Contraseña editada correctamente',
        errorMessage: 'Se produjo un error al editar la contraseña',
        
      },
      tooltips: {
        select_image: 'Selecciona una imagen'
      },
  
      breadcrumbs: {
        title: 'Mi perfil',
      },
  
      success_message: {
        title: 'Perfil editado correctamente',
        confirmation_message: 'Si modifica el usuario, deberá volver a iniciar sesión. ¿Desea continuar?',
        actions: {
          primary_text: 'Cerrar',
          back: 'Volver',
        },
      },
  
      error_message: {
        title: 'Se produjo un error al editar el perfil',
      },

      confirmationModal: {
        title: '¿Desea guardar los cambios?',
        actionPrimary: 'Guardar',
        actionSecondary: 'Cancelar',
      },
      
      edit: {
        label: 'Editar Perfil',
      },

      changePassword: {
        label: 'Cambiar contraseña',
        description: 'Para poder cambiar su contraseña actual debe contener entre 8 y 28 caracteres, al menos una mayúscula, minúscula y un número.',
      },
      sectionData: {
          title: 'Datos personales',
          avatar: 'Avatar',
          avatarDescription: 'Puedes personalizar tu cuenta con una foto',
          name: 'Nombre',
          lastname: 'Apellido',
          birthDate: 'Fecha de nacimiento',
          gender: 'Sexo',
          email: 'Usuario',
          password: 'Contraseña',
          passwordDescription: 'Cambiar contraseña',
          identificationType: 'Tipo de identificación',
          identification: 'Número de identificación',
          about: 'Acerca de vos',
          socialMedia: 'Redes Sociales',
            validations: {
              minRequired: 'La contraseña debe contener al menos una mayúscula, minúscula y un número.',
              nameRequired: 'Debes ingresar tu nombre',
              lastnameRequired: 'Ingresa tu/s apellido/s',
              emailRequired: 'Debes ingresar un email',
              emailEmail: 'Ingresa un email válido. Ej.: micorreo@ejemplo.com',
              identificationRequired: 'Ingresa tu número de identificación',
              passwordOldRequired: 'Debes ingresar tu contraseña actual',
              passwordNewRequired: 'Debes ingresar una nueva contraseña',
              passwordConfirmationRequired: 'Debes confirmar tu nueva contraseña',
              passwordMin: 'Mínimo 8 caracteres',
              passwordMax: 'Máximo 28 caracteres',
              passwordNewRepeat: 'Su contraseña nueva debe ser diferente a la antigua contraseña',
              sameAsNewPass: 'Debe ingresar la misma contraseña',
              passwordOldInconrrect: 'La contraseña actual ingresada es incorrecta'
            }
      },
  
      sectionContact: {
        address: 'Domicilio',
        title: 'Información de contacto',
        email: 'Correo electrónico',
        street: 'Calle',
        number: 'Número',
        floor: 'Piso',
        apartment: 'Departamento',
        zipCode: 'Código postal',
        cityWithState: 'Localidad',
        phone: 'Teléfono fijo',
        cellphone: 'Teléfono móvil',
        phonePlaceholder: 'Ingresa tu número',
        cellphonePlaceholder: 'Ingresa tu número',
        validations: {
          emailRequired: 'Debes ingresar un Email',
          emailEmail: 'Ingresa un Email válido. Ej.: micorreo@ejemplo.com',
          identificationRequired: 'Ingresa tu número de identificación',
          city: 'Ingresa mínimo 3 caracteres para filtrar la ciudad',
          floorValidation: 'El valor debe ser solo numérico',
          deptoLenght: 'Debes ingresar 5 caracteres como máximo'
        }
      },
  
      sectionAcademic: {
        'title': 'Información académica',
        'fileNumber': 'Número de legajo',
        'userProfile': 'Perfil de usuario'
      },

      studentFinder: {
        breadcrumbs: {
          title: 'Buscador de alumnos',
        },
        title: 'Filtro',
        input: {
          career: 'Carrera',
          cityWithState: 'Ciudad - Provincia',
          nameStudent: 'Nombre del alumno',
        },
      },

      perfilAlumno:{
        title:'Perfil del alumno',
        about:'Acerca de mi',
        back:'Volver a la busqueda'
      }
  
    },
    en: {
      
    }
  };
  
  export default profileTranslations
  