
export default {

    argentina: {
        canvas: {
            url: 'https://teclab.instructure.com/',
            ssoUrl: 'https://servicios.teclab.edu.ar/sso/SSOSAML.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: '$',
        currency_decimals: 2,
        identificationType: 'DNI',
        minAverage: 6,
        maxAverage: 10,
        payment: {
            isCftEnabled: true
        },
    },
    chile: {
        canvas: {
            url: 'https://ipp.instructure.com/',
            ssoUrl: 'https://certificados.ipp.cl/canvasSSO/SSOSaml.aspx',
            ssoKey: 'CanvasIPP',
        },
        currency: '$',
        currency_decimals: 0,
        identificationType: 'RUT',
        minAverage: 4,
        maxAverage: 7,
        payment: {
            isCftEnabled: false
        },
    },
    peru: {
        canvas: {
            url: 'https://teclabperu.instructure.com/',
            ssoUrl: 'https://api.teclab.edu.pe/sso/SSOSAML.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: 'S/',
        currency_decimals: 2,
        identificationType: 'DNI',
        minAverage: 13,
        maxAverage: 20,
        payment: {
            isCftEnabled: false
        },
    },
    mexico: {
        canvas: {
            url: 'https://teclabmexico.instructure.com/',
            ssoUrl: 'https://api.teclab.edu.mx/sso/SSOSAML.aspx',
            ssoKey: 'IlumnoCanvasUvaKey',
        },
        currency: 'MXN$',
        currency_decimals: 2,
        identificationType: 'DNI',
        minAverage: 5,
        maxAverage: 10,
        payment: {
            isCftEnabled: false
        },
    }

}