
const subjectsTranslations = {
    es_CL: {
      breadcrumbs: {
        title: 'Carga académica / Inscripción a asignaturas',
        description:'Acá podés inscribirte en tus asignaturas.'
      },
      subjects_title: 'Asignaturas a las que estas inscripto',
      no_subjects_title: 'No estás inscrito en ninguna asignatura.',
      available_title: 'Asignaturas a las que puedes inscribirte',
      unenroll_title_confirmation: '¿Quieres retirar la inscripción de la siguiente asignatura?',
      elective_required: 'Debe seleccionar una asignatura',
      optativeModule: {
        what_is_text: 'Es la posibilidad que tienes de obtener el título Técnico mientras te encuentras cursando la Carrera Profesional.',
        requisites_text: 'Tener todas las asignaturas Aprobadas/Habilitadas hasta 4 Semestre inclusive.',
        how_text: 'El estudiante debe inscribir las asignaturas opcionales dependiendo de su plan de estudios (Trabajo de título/Práctica Laboral).',
        about_text: 'En paralelo al 5° semestre de la carrera profesional, se inscribirá la asignatura “Trabajo de Título” y “Práctica Laboral”, que tienen una duración semestral. El primero se basa en una metodología de análisis de casos.',
        resumen: 'Si al finalizar el 5° semestre, el estudiante Aprueba todas las asignaturas correspondientes a la carrera técnica, entre ellas Práctica Laboral y Trabajo de Título, quedará como Egresado de la carrera Técnica. Pudiendo realizar la solicitud para rendir Examen de Título Técnico (debiendo cancelar el derecho a este).',
      },
      debt: {
        orNoSubjects: 'Tienes pagos pendientes o no tienes asignaturas para el periodo',
        description: 'Para poder inscribirte en las asignaturas, debes estar al día.',
      }
    },
    es_PE: {
        breadcrumbs: {
            title: 'Carga académica / Inscripción a cursos',
            description:'Aquí podés inscribirte en tus cursos.'
        },
        subjects_title: 'Cursos a los que estas inscripto',
        no_subjects_title: 'No estás inscrito en ningún curso.',
        available_title: 'Cursos a los que puedes inscribirte',
        unenroll_title_confirmation: '¿Quieres retirar la inscripción del siguiente curso?',
        elective_required: 'Debe seleccionar un curso',
        optativeModule: {
            requisites_text: 'Tener todos los cursos Aprobados/Regulares hasta 4 Semestre inclusive.',
            how_text: 'El estudiante debe inscribir los cursos opcionales dependiendo de su plan de estudios (Trabajo de título/Práctica Laboral).',
            about_text: 'En paralelo al 5° semestre de la carrera profesional, se inscribirá el curso “Trabajo de Título” y “Práctica Laboral”, que tienen una duración semestral. El primero se basa en una metodología de análisis de casos.',
            resumen: 'Si al finalizar el 5° semestre, el estudiante Aprueba todos los cursos correspondientes a la carrera técnica, entre ellas Práctica Laboral y Trabajo de Título, quedará como Egresado de la carrera Técnica. Pudiendo realizar la solicitud para realizar Examen de Título Técnico (debiendo cancelar el derecho a este).',
        },
        debt: {
            orNoSubjects: 'Tienes pagos pendientes o no tienes cursos para el periodo',
            description: 'Para poder inscribirte en los cursos, debes estar al día.',
        }
    },
    es: {
      breadcrumbs: {
        title: 'Carga académica / Inscripción a materias',
        description:'Acá podés inscribirte en tus materias.'
      },
      subjects_title: 'Materias a las que estas inscripto',
      no_subjects_title: 'No estás inscrito en ninguna materia.',
      available_title: 'Materias a las que puedes inscribirte',
      start: 'Inicia',
      end: 'Finaliza',
      enroll_title_confirmation: '¿Desea confirmar la inscripción?',
      enroll: 'Inscribir',
      unenroll_title_confirmation: '¿Quieres retirar la inscripción de la siguiente materia?',
      unenroll: 'Retirar',
      elective_required: 'Debe seleccionar una materia',
      optative: 'Optativa',
      optativeModule: {
        bannerText: '¡Ya puedes obtener tu salida intermedia!',
        see_more: 'Ver más',
        title: 'Recuerda que puedes obtener tu Salida Intermedia, mientras te encuentras cursando la carrera Profesional',
        what_is_title: '¿Qué es una Salida Intermedia?',
        what_is_text: 'Es la posibilidad que tienes de obtener el título Asistente/Auxiliar mientras te encuentras cursando la Carrera Profesional.',
        requisites_title: 'Los requisitos son:',
        requisites_text: 'Tener todas las materias Aprobadas/Regulares hasta 4 Semestre inclusive.',
        how_title: '¿Cómo se gestiona?',
        how_text: 'El estudiante debe inscribir las materias opcionales dependiendo de su plan de estudios (Trabajo de título/Práctica Laboral).',
        when_title: '¿Cuándo se debe realizar?',
        when_text: 'Durante el periodo de Confirmación de carga académica, una vez iniciado el Quinto Semestre.',
        about_title: '¿De qué se trata?',
        about_text: 'En paralelo al 5° semestre de la carrera profesional, se inscribirá la materia “Trabajo de Título” y “Práctica Laboral”, que tienen una duración semestral. El primero se basa en una metodología de análisis de casos.',
        resumen: 'Si al finalizar el 5° semestre, el estudiante Aprueba todas las materias correspondientes a la carrera técnica, entre ellas Práctica Laboral y Trabajo de Título, quedará como Egresado de la carrera Técnica. Pudiendo realizar la solicitud para rendir Examen de Título Técnico (debiendo cancelar el derecho a este).',
      },
      debt: {
        title: 'Tienes pagos pendientes',
        orNoSubjects: 'Tienes pagos pendientes o no tienes materias para el periodo',
        description: 'Para poder inscribirte en las materias, debes estar al día.',
        action: 'Ir a pagos',
      }
    },
    en: {

    }
};
  
  
  export default subjectsTranslations