
<template>
    <div class="due">
        <div class="due--left">
            <span class="due--highlighted-text">
                <big><b>{{ studentUserData.name | $capitalizeAll }},</b></big><br>
                <template v-if="dueInstallments">
                    <span v-if="dueAlert">{{ $t('modules.portalalumno.dashboard.banners.due.installmentsAlert', { dueInstallments }) }}</span>
                    <span v-else>{{ $t('modules.portalalumno.dashboard.banners.due.installments', { dueInstallments }) }}</span>
                </template>
            </span>
            <p v-if="dueAlert" v-html="$t('modules.portalalumno.dashboard.banners.due.reminderAlert', { nextDueDate })"></p>
            <p v-else v-html="$t('modules.portalalumno.dashboard.banners.due.reminder')"></p>
            <v-btn class="primary--text" color="white" rounded style="width: inherit" @click="redirect">{{ $t('modules.portalalumno.dashboard.banners.due.showDetail') }}</v-btn>
        </div>
        <div class="due--right">
            <img :src="require(`@/assets/dashboard-assets/dashboard-due-alert${dueAlert ? '' : '-2'}.png`)"/>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DueBanner',
        computed: {
            nextDueDate() {
                return this.studentData.studentDue && this.studentData.studentDue.nextDueDate ? moment(this.studentData.studentDue.nextDueDate).format('DD/MM') : null
            },
            dueInstallments() {
                return this.studentData.studentDue ? this.studentData.studentDue.countPaymentsDebt : null
            },
            dueAlert() {
                return this.dueInstallments < 3
            },
            ...mapGetters({
                studentUserData: 'commons/getStudentUserData',
                studentData: 'commons/getStudentData',
            }),
        },
        methods: {
            redirect() {
                this.$router.push('/payments')
            }
        }
    }
</script>

<style lang="sass" scoped>
    /* Uses cases */
    .due
        background: #f24545
        border-radius: 5px
        width: 100%
        height: auto
        padding: 25px
        display: flex
        justify-content: space-between
        color: #fff
        font-size: 14px
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08)
        &--left
            width: 65%
            display: flex
            justify-content: center
            flex-direction: column
            p
                margin-top: 15px
        &--right
            width: 35%
            display: flex
            justify-content: center
            align-content: center
            img
                position: relative
                height: auto
                max-width: 100%
        &--highlighted-text
            font-size: 20px
            line-height: 25px
  
    @media only screen and (max-width: 600px) 
        .due
            &--left
                width: 100%
            &--right
                display: none
        
</style>