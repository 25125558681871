<template>
  <v-card class="px-4" outlined >
    <template v-slot:progress="">
      <v-row>
        <v-col cols="12" class="pa-6 text-center">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="selectedPeriods.length < 1">
      <v-col cols="12">
        <p class="mb-0 ContractPreviewPeriodsDetailsCard-title pa-6 text-center">No has seleccionado ningún periodo</p>
      </v-col>
    </v-row>
    <v-row v-else-if="payments.length < 1">
      <v-col cols="12">
        <p class="mb-0 ContractPreviewPeriodsDetailsCard-title pa-6 text-center">No existen cuotas para los períodos seleccionados</p>
      </v-col>
    </v-row>
    <v-row v-if="selectedPayments.length > 0">
      <v-col class="col-sm-12 col-md-3">
        <div class="d-flex fill-height align-center">Matricula</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in filteredPaymentList(6606, 80)" :key="index">
        <PeriodCard 
            :payment="payment"
            :due="`Cuota ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
    <v-row v-if="filteredPaymentList(6606, 81).length > 0">
      <v-col class="col-sm-12 col-md-3">
        <div class="d-flex fill-height align-center">Arancel 1A</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in filteredPaymentList(6606, 81)" :key="index">
        <PeriodCard 
            :payment="payment"
            :due="`Cuota ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
    <v-row v-if="filteredPaymentList(6609, 80).length > 0">
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in filteredPaymentList(6609, 80)" :key="index">
        <PeriodCard 
            :payment="payment"
            :due="`Cuota ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
    <v-row v-if="filteredPaymentList(6609, 81).length > 0">
      <v-col class="col-sm-12 col-md-3">
        <div class="d-flex fill-height align-center">Arancel 1B</div>
      </v-col>
      <v-col cols="12" sm="12" md="3" v-for="(payment, index) in filteredPaymentList(6609, 81)" :key="index">
        <PeriodCard 
            :payment="payment"
            :due="`Cuota ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}`" 
            :price="payment.options.amount" 
            :expiration-date="payment.options.dueDate" 
            :is-mandatory="payment.mandatory"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ContractPreviewPeriodsDetailsPeriodCard  from './ContractPreviewPeriodsDetailsAccountCard.vue';
export default {
  name: 'PeriodsDetails',
  props: ['selectedPeriods', 'payments', 'loading'],
  data() {
    return {
      systemConfig: null
    }
  },
  computed: {
    selectedPayments() {
      const paymentsCopy = this.payments
      const orderedPayments = paymentsCopy.sort((a, b) => (a.idProduct - b.idProduct))
      const selectedPeriodsIds = this.selectedPeriods.map(period => period.id )
      return orderedPayments.filter(payment => selectedPeriodsIds.includes(payment.options.term.id))
    },
    selectedPeriodsDescription() {
      return this.selectedPeriods.map(period => period.description).join(' ')
    },
    selectedPeriodsCount() {
      return this.selectedPeriods.length
    }
  },
  components: {
    PeriodCard: ContractPreviewPeriodsDetailsPeriodCard
  },
  methods: {
    filteredPaymentList(termId, idProduct){
      const paymentsCopy = this.payments
      const filteredPayments = paymentsCopy.filter(e => e.options.term.id == termId && e.idProduct == idProduct)
      const selectedPeriodsIds = this.selectedPeriods.map(period => period.id )
      return filteredPayments.filter(payment => selectedPeriodsIds.includes(payment.options.term.id))
    }
  }
}
</script>

<style lang="sass" scoped>
.ContractPreviewPeriodsDetailsCard
  &-title
    font-size: 13px
</style>
