<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    class="birthdayDatePicker"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :error-messages="($v.date.$dirty && $v.date.$invalid) ? $t('modules.admission.preregistration.validations.birthDateRequired') : ''"
        :hide-details="hideDetails"
        readonly
        :label="label"
        outlined
        v-on="on"
        class="birthdayDatePicker__input"
        append-icon="mdi-calendar"
        @blur="$v.date.$touch()"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<style lang="sass">
.birthdayDatePicker
  position: relative
  &__input
    .v-input__slot
      min-height: 44px !important
      .v-label
        top: 13px
      fieldset
        background: transparent !important
    .v-text-field__details
      min-height: 0
      margin-bottom: 0 !important
    &.v-input--is-focused
      & + .independentInput__actions
        height: auto
        opacity: 1
        top: 46px

  &__actions
    z-index: 2
    display: flex
    justify-content: flex-end
    position: absolute
    top: 0
    right: 0
    height: 0
    opacity: 0
    overflow: hidden
    .v-btn
      min-width: 40px
      margin: 4px
    &:hover
      height: auto
      opacity: 1
      top: 46px
</style>

<script>
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'BirthdayDatePicker',
  props: {
    value: String,
    label: String,
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: true }
  },
  data () {
    return {
      date: null,
      menu: false,
    }
  },
  validations: {
    date: {
      required: requiredIf('isRequired')
    }
  },
  computed: {
    isRequired() {
      return this.required
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    value(val) {
      if (this.value == '') return;
      
      if (typeof this.value === 'string') {
        const aux = new Date(val).toISOString().substr(0, 10);
        if(this.date != aux) {
          this.updateDate();
        }
      }
      else {
        if(this.date != this.value)
          this.updateDate();
      }
    },
    date(d) {
      this.$emit('input', d)
    }
  },
  methods: {
    updateDate() {
      if (typeof this.value === 'string')
        this.date = new Date(this.value).toISOString().substr(0, 10);
      else
        this.date = this.value;
    },

    save (date) {
      this.$refs.menu.save(date);
      this.$emit('save', this.date);
    },
    triggerOuterValidation() {
      this.$v.$touch()
    }
  },
  mounted() {
    this.updateDate();
  }
}
</script>
