<template>
    <div class="approved-subjects__content d-flex flex-column flex-sm-row align-center justify-space-around py-3 pl-3 py-sm-5 pl-sm-5">
        <v-dialog v-model="modal" width="fit-content" scrollable >
            <SubjectModal :selected-subject="selectedSubject" :selected-subject-parts="selectedSubjectParts" @close="closeSubjectModal"/>
        </v-dialog>
        <v-progress-circular v-if="loading" indeterminate color="#ccc"></v-progress-circular>
        <div v-else-if="!subjects.length" class="text-center grey--text text--darken-2">
            <v-icon class="mr-3">mdi-inbox</v-icon>
            <span>{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.noSubjects') }}</span>
        </div>
        <template v-else>
            <div class="approved-subjects__content-grid">
                <div class="d-flex align-center table-sizing">
                    <div class="caption">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.subjects') }}</div>
                    <div class="caption text-center">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.examDate') }}</div>
                    <div class="caption text-center">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.score') }}</div>
                    <div v-if="mod2019"></div>
                </div>
                <div class="approved-subjects__content-grid-row d-flex table-sizing py-2" v-for="(subject,idx) in subjects" :key="`approved-${idx}`">
                    <div class="body-1 primary--text font-weight-bold">{{ subject.name }}</div>
                    <div class="caption d-flex align-center justify-center">{{ subject.examDate }}</div>
                    <div class="body-1 primary--text font-weight-bold d-flex align-center justify-center">{{ subject.score }}</div>
                    <div class="d-flex align-center" v-if="subject.mod2019">
                        <v-icon color="#4586de" size="20" class="view-subject" @click="openSubjectModal(subject)">mdi-eye</v-icon>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column">
              <div v-if="$isTeclab" class="resume fullHeight pt-4 pt-sm-0">
                  <div class="average d-flex align-center justify-center justify-sm-space-around">
                      <div class="circle-blue d-flex align-center justify-center ma-2">
                          <span class="headline white--text text-center">{{ score.withDeferrals }}</span>
                      </div>
                      <span class="ml-2 caption text-center text-sm-left">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.averageWithDeferrals') }}</span>
                  </div>
              </div>
              <div class="resume fullHeight pt-4 pt-sm-0">
                <div class="average d-flex align-center justify-center justify-sm-space-around">
                  <div class="circle-blue d-flex align-center justify-center ma-2">
                    <span v-if="$isTeclab" class="headline white--text text-center">{{ score.withoutDeferrals ? score.withoutDeferrals.toFixed(1) : "-"  }}</span>
                    <span v-else class="headline white--text text-center">{{ score.withoutDeferrals ?  Math.round(score.withoutDeferrals * 10) / 10  : "-"  }}</span>
                  </div>
                  <span class="ml-2 caption text-center text-sm-left">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.averageWithoutDeferrals') }}</span>
                </div>
              </div>
            </div>
        </template>
    </div>
</template>

<script>
    import moment from 'moment'
    import { $materias } from '../Services';
    import { mapGetters } from 'vuex';
    import SubjectModal from './SubjectModal';

    export default {
        name: 'ApprovedSubjects',
        components: { SubjectModal },
        data() {
            return {
                loading: false,
                modal: false,
                selectedSubject: null,
                approvedSubjectsFromAPI: [],
            }
        },
        computed: {
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                cacheSubjects: 'commons/getStudentApprovedSubjects',
            }),
            score() {
              const isWithoutDeferralsNumeric = this.cacheSubjects?.notaAprobados && typeof this.cacheSubjects.notaAprobados == 'number';
              const isWithDeferralsNumeric = this.cacheSubjects?.notaAplazos && typeof this.cacheSubjects.notaAplazos == 'number';

              const withoutDeferrals = isWithoutDeferralsNumeric ? this.cacheSubjects.notaAprobados : null;
              const withDeferrals = isWithDeferralsNumeric ? this.cacheSubjects.notaAplazos.toFixed(1) : null;

              return { withoutDeferrals, withDeferrals };
            },
            subjects(){
                if (this.cacheSubjects) {
                    return this.cacheSubjects.aprobadasDTOList.map(subject => {
                        const examsKeys = ['nota1','nota2','nota3','nota4']

                        return {
                            name: subject.nombre,
                            score: subject.nota,
                            examScore: subject.examen,
                            mod2019: subject.mod2019,
                            exams: examsKeys.map(examKey => {
                                const exam = subject[examKey]
                                if(exam !== null){
                                    if (exam.notaReemplazo) return {score: exam.notaReemplazo, recovery: true}
                                    return {score: exam.nota, recovery: false};
                                }
                            }),
                            term: subject.term,
                            examDate: moment(subject.fecha, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                            recovery: subject.recuperatorio
                        }
                    });
                } else return []
            },
            mod2019() {
                return !!this.subjects.find(subject => subject.mod2019)
            },
            selectedSubjectParts() {
                if (!this.selectedSubject) return []

                const { exams } = this.selectedSubject;

                return [
                    { title: 'Año / Período', content: this.selectedSubject.term || '-' },
                    { title: 'M1', content: exams[0]?.score.toFixed(1) || '-' },
                    { title: 'M2', content: exams[1]?.score.toFixed(1) || '-' },
                    { title: 'M3', content: exams[2]?.score.toFixed(1) || '-' },
                    { title: 'M4', content: exams[3]?.score.toFixed(1) || '-' },
                    { title: 'Examen', content: this.selectedSubject.examScore || '-' },
                    { title: 'Recuperatorio', content: this.selectedSubject.recovery || '-' },
                    { title: 'Nota Final', content: this.selectedSubject.score },
                ]
            }
        },
        mounted(){
            if (!this.cacheSubjects) this.getData();
        },
        methods: {
            async getData(){
                try{
                    this.loading = true
                    const response = await $materias.find('aprobadas', null, {params: { leg: this.studentRegister }})
                    response.data.aprobadasDTOList.sort((a, b) => moment(b.fecha, 'DD-MM-YYYY') - moment(a.fecha, 'DD-MM-YYYY'))
                    await this.$store.dispatch('commons/setStudentApprovedSubjects', response.data);
                } catch(err) {
                    this.$store.dispatch('commons/setStudentApprovedSubjects', null);
                    throw err
                } finally {
                    this.loading = false;
                }
            },
            openSubjectModal(subject) {
                this.selectedSubject = subject;
                this.modal = true;
            },
            closeSubjectModal() {
                this.modal = false;
                this.selectedSubject = null;
            }
        },
    }
</script>

<style lang='sass' scoped>

  .table-sizing
    div:nth-child(1)
      flex: 1
    div:nth-child(2)
      width: 20%
    div:nth-child(3)
      width: 15%
    div:nth-child(4)
      width: 7.7%

  .approved-subjects
    &__content
        width: 100%
        max-height: 254px
        min-height: 250px
        &-grid
          height: 100%
          max-height: 100%
          width: 78.85%
          overflow-y: scroll
          &-row
            border-bottom: 1px solid #ddd
            .view-subject:hover
              color: var(--v-primary-base) !important

        .resume
          .average
            .circle-blue
              width: 50px
              height: 50px
              border-radius: 50%
              background: var(--v-primary-base)

              span
                width: 50px

  @media only screen and (max-width: 600px)
    .approved-subjects
      &__content
          max-height: 254px * 2
          &-grid
            width: 100%
            &-row
              width: 100%
          .resume
            min-height: auto
</style>
