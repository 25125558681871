<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs
                :title="$t('modules.portalalumno.reglamento.breadcrumbs.title')"
                :description="$t('modules.portalalumno.reglamento.breadcrumbs.description')"
                :callback="() => { this.backHome() }"
            ></Breadcrumbs>
            <v-row justify="center">

                <v-col sm="12" md="5" lg="8" class="order-last order-sm-first margin-bot">
                    <div v-show="!selectedCapitulo">
                        <v-card-title class=" py-0 justify-center">
                            <h4 class="text-center">
                                {{ $t('modules.portalalumno.reglamento.label.shortCut') }}
                            </h4>
                        </v-card-title>
                        <v-row max-height="1000px" justify="center" justify-lg="start" class="mt-8">
                            <div v-for="card in cards" :key="card._id">
                                <Reglamento-card :card="card" @sendToEntry="sendToEntry"></Reglamento-card>
                            </div>
                        </v-row>
                    </div>

                    <div v-if="selectedCapitulo">
                        <v-card-title class= "py-0 justify-center lime darken-1">
                            <h4 class="text-center title">{{ selectedCapitulo.Nombre }}</h4>
                        </v-card-title>
                        <v-row class="mt-8">
                                <v-container v-for="subcapitulo in selectedCapitulo.Subcapitulos"  :key="subcapitulo._id">
                                    <h5 :id="`sub-${subcapitulo._id}`">{{ subcapitulo.Nombre}}</h5>
                                    <div v-html="subcapitulo.Contenido"></div>
                                </v-container>
                        </v-row>
                    </div>
                </v-col>

                <v-col sm="12" md="7" lg="4" id="indices">
                    <v-row  class="mx-auto " justify="center">
                        <v-card width="100%">
                            <v-card-title class="py-0 white--text light-blue darken-3 justify-center text-uppercase">
                                <h4 class="title"> {{ $t('modules.portalalumno.reglamento.label.indices') }} </h4>
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <v-combobox
                                    v-model="searchArt"
                                    @change="selectCapitulo($event)"
                                    item-text="Nombre"
                                    return-object
                                    :items="autocompleteItems"
                                    :label="$t('modules.portalalumno.reglamento.label.search')"
                                    prepend-inner-icon="mdi-magnify"
                                    :search-input.sync="searchText"
                                    :filter="filterCapitulosByText"
                                    outlined
                                    auto-select-first
                                    :menu-props="{maxWidth: '338'}"
                                    clearable
                                ></v-combobox>
                            </v-card-actions>
                            <v-card-title class="py-0 white--text light-blue darken-1 justify-space-between">
                                <h4 class="title"> {{ $t('modules.portalalumno.reglamento.label.shortCut') }} </h4>
                                <v-icon class="white--text" @click="backHome">mdi-home</v-icon>
                            </v-card-title>

                            <v-list style="max-height: 600px" class="overflow-y-auto">
                                <v-list-group v-for="capitulo  in capituloNews" :key="capitulo._id" v-model="capitulo.active" @click="selectCapitulo(capitulo)">
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="capitulo.Nombre"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <v-list-item v-for="subcapitulo  in capitulo.Subcapitulos"  :key="subcapitulo._id" @click="selectCapitulo(capitulo, subcapitulo._id)">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="subcapitulo.Nombre"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>


<script>
    import ReglamentoCard from '../Components/ReglamentoCard.vue'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Constants from '@/plugins/constants'
    export default {
        components: {
            Breadcrumbs,
            ReglamentoCard
        },
        name: 'ReglamentoView',
        data() {
            return {
                autocompleteItems: [],
                capituloNews: [],
                selectedEntryId: null,
                searchArt: '',
                searchText: null,
                cards: [],
                selectedCapitulo: null,
            }
        },
        computed: {
            token(){
                return this.$isTeclab ? '9e705928b960e3c338102ab3233e1e' : '9b840d8b42fa7c01c06df4e95913d0'
            },
            uriBase() {
                return `https://${Constants.DOMAIN}`
            },
            preFix() {
                const regulationType = this.$router.currentRoute.fullPath.split('/').pop()
                return regulationType != 'reglamento' ? regulationType.charAt(0).toUpperCase() + regulationType.slice(1) : ''
            },
        },
        methods: {
            backHome() {
                this.selectedCapitulo = null
                this.selectedEntryId = null
                this.searchText = ''
                this.autocompleteItems = this.capituloNews
            },
            filterCapitulosByText(item, queryText, itemText) {
                const query = queryText.toLocaleLowerCase()
                return itemText.toLocaleLowerCase().indexOf(query) > -1 ||
                    !!item.Subcapitulos.find(subcapitulo => subcapitulo.filteredContent.indexOf(query) > -1)
            },            
            sendToEntry(id) {
                this.capituloNews.forEach(capitulo => {
                    if (capitulo._id == id) {
                        this.selectCapitulo(capitulo)
                    } else {
                        capitulo.Subcapitulos.forEach(subcapitulo => {
                            if (subcapitulo._id == id) this.selectCapitulo(capitulo, subcapitulo._id)
                        });
                    }
                });
            },
            async getFetchCapitulo() { 
                try {
                    const ruta = `/cockpit/api/collections/get/${this.preFix}CapituloNew`
                    const res = await fetch( `${this.uriBase}${ruta}?token=${this.token}`, {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ sort: { orden: 1 }, populate: 1 })
                        
                    })
                    const resJson = await res.json()
                    this.capituloNews = await resJson.entries.map( e => {
                        e.active = false
                        e.Subcapitulos.map(subcapitulo => {
                            subcapitulo.filteredContent = subcapitulo.Contenido.replace(/<[^>]+>/g, '').toLowerCase()
                            return subcapitulo
                        })
                        e.Subcapitulos.sort((a, b) => a.orden - b.orden)
                        return e
                    })
                    this.autocompleteItems = this.capituloNews
                } catch (error) {
                    this.capituloNews = []
                    this.autocompleteItems = []
                    throw error

                }
            },
            async getFetchCard () {
                const ruta = `/cockpit/api/collections/get/${this.preFix}ShortCodeHome`
                try {
                    const res = await fetch( `${this.uriBase}${ruta}?token=${this.token}` )
                    const cards = await res.json()
                    this.cards = cards.entries.map(e => {
                        return {
                            img: e.Icono.path.includes('/') ? `${this.uriBase}/${e.Icono.path}` : require(`@/assets/${e.Icono.path}`),
                            title: e.Titulo,
                            action: e.LinkText,
                            href: e.IDLink._id,
                            orden: e.orden
                        }
                     }).sort((a, b) => a.orden - b.orden)
                } catch (error) {
                    this.cards = []
                    throw error
                }
            },
            selectCapitulo(capitulo, subcapitulo_id = null) {
                this.selectedCapitulo = capitulo
                this.selectedEntryId = subcapitulo_id
                setTimeout(() => {
                const art = document.getElementById(`sub-${this.selectedEntryId}`);
                art.scrollIntoView({block: 'start', behavior: 'smooth'})}, 300)
            },
        },
        async mounted () {
            await this.getFetchCard();
            await this.getFetchCapitulo();
        },
        watch:{
            preFix(value, old){
                if(old !== value){
                    this.getFetchCapitulo()
                    this.getFetchCard()
                }
            }
        }
    }
</script>

<style lang="sass" scoped>
    .bg 
        background-color: #ecedf0
    .v-menu__content
        background-color: red !important

</style>
