<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.reEnrollmentStatus.breadcrumbs.title')"
        :callback="goBack"
        isDescriptionFullLength
      ></Breadcrumbs>
      <v-row justify="center" v-if="!confirmStatus">
        <v-col class="viewSpaces" cols="12" sm="8">
          <v-row>
            <v-col sm=12>
              <span class="primary--text headline">{{ `Periodo: ${period}` }}</span>
              <p class="body-2 mt-5">{{ 'Estás por comenzar el proceso de rematriculación y te guiaremos para que realices la simulación de pago, luego si estás de acuerdo puedes confirmar la simulación y realizar el pago.' }}</p>
              <p class="body-2 mt-5">{{ 'Estos son los items que deberás pagar, selecciona cada uno.' }}</p>
            </v-col>
            <v-col sm=12 class="d-flex justify-center" v-if="show('loading')">
              <v-progress-circular
                block
                class="mt-5"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
            <template v-if="commonData.paymentItems.length">
              <v-col cols="12" v-for="(payment) in commonData.paymentItems" :key="payment.id">
                <v-card outlined class="py-3 px-3 payment-item">
                  <v-row justify="space-between" no-gutters>
                    <v-col class="d-flex justify-start align-center text-center">
                      <span class="black--text body-1 font-weight-bold pl-3">{{ payment.title }}</span>
                    </v-col>
                    <v-col class="text-right">
                      <div class="mx-2 d-flex flex-column align-end justify-center fill-height">
                        <span v-if="(payment.off)" class="font-weight-bold grey--text caption strikethrough">{{ payment.amount | $currency }}</span>
                        <span class="primary--text body-1 font-weight-bold">{{ payment.amount - (payment.off * payment.amount / 100) | $currency }}</span>
                        <span v-if="(payment.off)" class="font-weight-bold success--text caption">{{ payment.off }}% Off</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
            <v-col v-else sm="12" class="d-flex justify-center">
              <div class="error-banner d-flex justify-center align-center mt-5 red--text">
                <span class="body-2 ml-3">{{ errors }}</span>
              </div>
            </v-col>
            <v-col sm="12" class="d-flex justify-end">
              <Button
                white
                class="mr-4"
                @clicked="goBack"
                :text="$t('actions.cancel')"
              ></Button>
              <Button
                :disabled="!commonData.subTotalItems.length"
                text="Pagar"
                :loading="loading"
                @clicked="confirm"
              ></Button>
            </v-col>
            <v-col sm=12 v-if="show('scholarshipAlert')">
              <div class="alert-banner d-flex justify-start align-center mt-5">
                <img :src="require('@/assets/images/portalalumno/bell.png')" class="bell-image ml-4" />
                <span class="body-2 ml-3">{{ show('scholarshipAlert') }}</span>
              </div>
            </v-col> 
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <Summary
            :subtotalItems="commonData.subTotalItems"
            :discountsItems="commonData.discounts"
          ></Summary>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      ></SuccessMessage>
    </v-container>
  </v-container>
</template>

<script>
  import { $productWithPrice, $discounts, $terms, $reEnrollment } from '../../services';
  import Button from '@/components/Button/Button'
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import Summary from '../../Components/Summary/Summary'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ReEnrollment',
    components: {
      Button,
      Breadcrumbs,
      Summary,
      SuccessMessage,
    },
    data() {
      return {
        matriculaID: this.$sisConstants('modules.products.matricula'),
        reEnrollmentCase: 'prontoPago',
        reEnrollmentOptions: ['prontoPago', 'benefit', 'scholarship'],
        loading: false,
        confirmStatus: false,
        successMessage: {
          type: null,
          title: null,
          actionPrimary: null,
        },
        model: { 
          prontoPago: { 
            dataToShow: ['loading'],
            dataNeeded: [],
            data: {loading: false}
          },
          scholarship: {
            dataToShow: ['loading'],
            dataNeeded: [],
            data: {
              loading: false,
              scholarshipAlert: 'Para poder aspirar a la renovación de una beca, deberás tener un promedio ponderado igual o mayor a cinco punto cinco (5.5), y no haber tenido asignaturas reprobadas.'
            }
          },
          benefit: {
            dataToShow: ['loading'],
            dataNeeded: [],
            data: {loading: false}
          }
        },
        errors: null,
        period: '',
        commonData: {
          paymentItems: [],
          discounts: [],
          subTotalItems: [],
        }
      }
    },
    computed: {
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentData: 'commons/getStudentData',
        studentId: 'commons/getStudentId',
        studentUserId: 'commons/getStudentUserID',
      }),
      subtotal(){
        let subtotal = 0
        this.commonData.subTotalItems.forEach(item => {subtotal += item.amount})
        const scholarship = this.commonData.discounts.find(discount => discount.isScholarship)
        if (scholarship) subtotal -= scholarship.amount
        return subtotal
      },
      discounts(){
        let discounts = 0
        let auxSubtotal = this.subtotal
        
        this.commonData.discounts.forEach(item =>{
          const currentDiscount = auxSubtotal * item.percentage / 100
          auxSubtotal -= currentDiscount
          discounts += currentDiscount
        })

        return discounts
      },
      discountsAmounts (){
        const amounts = []
        let auxSubtotal = this.subtotal
        const discounts = this.commonData.discounts.filter(discount => !discount.isScholarship)
        discounts.forEach(item =>{
          const currentDiscount = auxSubtotal * item.percentage / 100
          auxSubtotal -= currentDiscount
          amounts.push(currentDiscount)
        })
        return amounts
      },
    },
    created() {
      this.initialize()
    },
    methods: {
      async initialize () {
        this.getCurrentPeriod()
        await this.defineCase()
        await this.getDiscounts(this.matriculaID)
        await this.getProducts(this.matriculaID)
        this.toggleItem(0)
      },
      async getCurrentPeriod(){
        const periodData = await $terms.currentPeriod()
        this.period = periodData.data.content[0].description
      },
      defineCase(){
        if (this.studentData.benefit) this.reEnrollmentCase = 'benefit'
        else if (this.studentData.scholarship) this.reEnrollmentCase = 'scholarship'
        else this.reEnrollmentCase = 'prontoPago'   
      },
      async getProducts(id){
        this.model[this.reEnrollmentCase].data.loading = true;
        const studentCareer = this.studentData.career.id
        const studentCity = this.studentData.city.id

        const products = await $productWithPrice.find(null, null, { params: {
          careerId: studentCareer,
          cityId: studentCity,
          studentType: 8,
          student: this.studentId
        }})

        this.commonData.paymentItems = products.data.filter(product => product.id === id)
        .map(product => {
          let finalAmount = product.amount
          let totalDiscount = 0

          if (product.scholarship) {
            const { scholarship } = product
            let finalDiscount = 0
            let amount = 0
            if (scholarship.amount) {
              if (scholarship.amount >= finalAmount) {
                finalAmount = 0
                totalDiscount = 100
                finalDiscount = 100
                amount = finalAmount
              } else {
                finalDiscount = scholarship.amount / finalAmount * 100
                amount = scholarship.amount   // eslint-disable-line
                totalDiscount += finalDiscount
                finalAmount -= scholarship.amount
              }
            } else if (scholarship.percentage) {
              finalDiscount = scholarship.percentage
              amount = finalAmount * scholarship.percentage / 100
              totalDiscount += (100 - totalDiscount) * scholarship.percentage / 100
              finalAmount -= finalAmount * scholarship.percentage / 100
            }

            const finalScholarship = {
              id: scholarship.id,
              name: scholarship.description,
              amount,
              percentage: finalDiscount,
              isScholarship: true,
            }

            if (finalAmount) this.commonData.discounts.unshift(finalScholarship)
            else this.commonData.discounts = [ finalScholarship ]
          }

          if (finalAmount && this.commonData.discounts.length) {
            const discount = this.commonData.discounts[this.commonData.discounts.length - 1]  // eslint-disable-line
            let finalDiscount = 0
            let amount = 0
            if (discount.amount) {
              if (discount.amount >= finalAmount) {
                finalAmount = 0
                totalDiscount = 100
                finalDiscount = 100
                amount = finalAmount
              } else {
                finalDiscount = discount.amount / finalAmount * 100
                amount = discount.amount // eslint-disable-line
                totalDiscount += finalDiscount
                finalAmount -= discount.amount
              }
            } else if (discount.percentage) {
              finalDiscount = discount.percentage
              amount = finalAmount * discount.percentage / 100
              totalDiscount += (100 - totalDiscount) * discount.percentage / 100
              finalAmount -= finalAmount * discount.percentage / 100
            }

            this.commonData.discounts[this.commonData.discounts.length - 1].amount = amount
          }

          return {
            title: product.name,
            amount: product.amount,
            id: product.id,
            off: totalDiscount,
          }
        })
        if (!this.commonData.paymentItems.length) this.errors = 'No se encontraron matrículas para el CSE y CARRERA especificados.'
        this.model[this.reEnrollmentCase].data.loading = false;
      },
      roundNumber(number) {
        return Math.round((number + Number.EPSILON) * 100) / 100
      },
      async getDiscounts(id){
        try {
          const discounts = await $discounts.find('date', null, {params: { cityId: this.studentData.city.id, idCareer: this.studentData.career.id, studentType: 8, idProduct: id }})
          this.commonData.discounts = [{
            name: discounts.data.description,
            id: discounts.data.id,
            amount: discounts.data.details[0].bianualAmount,
            percentage: discounts.data.details[0].bianualPercentage,
            isScholarship: false,
          }]
        } catch {
          this.commonData.discounts = []
        }
      },
      show(element) {
        return (this.reEnrollmentCase && // Existe un caso
                this.model[this.reEnrollmentCase].dataToShow.includes(element) && // El elemento está para mostrarse en ese caso
                this.model[this.reEnrollmentCase].data[element]) // El elemento tiene un valor (También devuelve dicho valor)
      },
      toggleItem(item){
        if (this.commonData.paymentItems[item].selected) {
          this.commonData.subTotalItems = []
          this.commonData.paymentItems.forEach((subTotalItem, index) => {
            if (index >= item) subTotalItem.selected = false;
            else this.commonData.subTotalItems.push(subTotalItem)
          })
        } else {
          this.commonData.subTotalItems = [] 
          this.commonData.paymentItems.forEach((subTotalItem, index) => {
            if (index <= item) {
              subTotalItem.selected = true;
              this.commonData.subTotalItems.push(subTotalItem)
            }
          })
        }
      },
      goBack(){
        this.$trackEvent('Tramites', 'Cancelar', 'Rematriculacion Step 2')
        this.$router.push('renewalStatus')
      },
      async confirm () {
        const discounts = this.commonData.discounts.filter(discount => !discount.isScholarship)
        const reEnrollmentObject = {
          student: this.studentId,
          scholarship: null,
          benefit: null,
          studentCareer: this.studentRegister,
          products: [{
            billingProduct: { id: this.matriculaID },
            amount: this.subtotal,
            installmentAmount: this.subtotal,
            installment: 1
          }],
          user: this.studentUserId ? this.studentUserId : null,
          discounts: discounts.map((discount, index) => {
            return {
              discount: {id: discount.id},
              discountAmount: this.discountsAmounts[index]
            }
          })
        }

        try {
          this.loading = true
          this.$trackEvent('Tramites', 'Pagar', 'Rematriculacion Step 2')
          await $reEnrollment.create(reEnrollmentObject);
          if (this.subtotal) this.$router.push({name: 'Payments', params: {billingProduct: this.matriculaID }})
          else {
            this.successMessage.type = 'success'
            this.successMessage.title = this.$t('modules.portalalumno.procedures.reEnrollment.createSuccess');
            this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.reEnrollment.createSuccessButtonText'), callback: () => { this.$router.push({ name: 'RenewalStatus' }) }}
            this.confirmStatus = true;
          }
        } catch {
          this.successMessage.type = 'error'
          this.successMessage.title = this.$t('modules.portalalumno.procedures.reEnrollment.createError');
          this.successMessage.actionPrimary = { text: this.$t('actions.goBack'), callback: () => { this.confirmStatus = false; this.commonData.subTotalItems = [] }}
          this.confirmStatus = true;
        } finally {
          this.loading = false
        }
      }    
    },
  }
</script>

<style lang="sass" scoped>
  .strikethrough
    text-decoration: line-through

  .payment-item
    width: 100%
    border: 1px solid #6087e1 !important
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
    background: #d9e2fa

  .discount-banner
    background: rgba(217, 217, 217, 0.1)
    height: 90px
    border-radius: 5px

  .discount
    background: white
    height: 75%
    color: #3bc000
    width: 20%

  .alert-banner
    background: rgba(237, 139, 0, .1)
    min-height: 60px
    border-radius: 5px

  .error-banner
    width: 100%
    min-height: 60px
    border-radius: 5px

  .bell-image
    max-height: 30px

</style>
