<template>
    <v-text-field
        :value="value"
        @input="updateValue"
        outlined
        :loading="loading"
        type="text"
        v-mask="mask"
        autocomplete="off"
        :disabled="disabled"
        @keyup="keyup"
        @blur="blur"
        :label="label"
        :error-messages="(!validRut && dirty && value != '' && isRUT) ? $t('components.identificationInput.invalid_rut') : errorMessages"
    ></v-text-field>
</template>

<script>
    export default {
        name: 'IdentificationInput',
        props: {
            value: String,
            label: String,
            errorMessages: String,
            loading: Boolean,
            idType: {type: String, default: 'RUT' },
            disabled: { type: Boolean, default: false }
        },
        data() {
            return {
                timeout: null,
                dirty: false,
                validRut: false,
            }
        },
        methods: {
            blur(event) {
                this.validateRut(event.target.value)
                this.$emit('blur')
            },
            updateValue(value) {
                this.$emit('input', value)
            },
            keyup(event) {
                this.validateRut(event.target.value)
            },
            validateRut(rut, dirty = true) {
                if (rut == null || rut == undefined || rut == '') return false

                if (this.isRUT) {
                    let valor = rut.replace('.', '')
                    valor = valor.replace('-', '')
                    const cuerpo = valor.slice(0, -1)
                    const dv = valor.slice(-1).toUpperCase()

                    let suma = 0
                    let multiplo = 2

                    for (let i = cuerpo.length - 1; i >= 0; i--) {
                        suma += (multiplo * cuerpo.charAt(i));
                        (multiplo < 7) ? multiplo++ : multiplo = 2
                    }

                    let dvEsperado = 11 - (suma % 11)

                    if (dvEsperado == 10) dvEsperado = 'K'
                    else if (dvEsperado == 11) dvEsperado = 0
                    this.validRut = (dvEsperado == dv)
                } else {
                    this.validRut = true
                }

                if (dirty) this.dirty = true
                this.$emit('validateRut', this.validRut)
                this.initTimer()
            },
            initTimer() {
                this.$emit('preTimer')
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    if (this.validRut) this.$emit('posTimer')
                    clearTimeout(this.timeout)
                }, 1000)
            },
        },
        computed: {
            mask() {
                if (this.isRUT) return ['#####-X','######-X','#######-X', '########-X']
                else if (this.idType == 'CUIT'){
                  return '##-########-#'
                } else {
                  return '#########'
                }
            },
            isRUT(){
                return this.$isIPP ? true : false
            },
        },
        watch: {
            idType() {
                this.validateRut(this.value, false)
            }
        },

    }
</script>