import Reglamento from '../Reglamento';
import ReglamentoView from '../Views/ReglamentoView'
import ReglamentoConfirm from '../Views/ReglamentoConfirm.vue'

const ReglamentoRoute =
{
  path: '/reglamento',
  meta: {
    name: {
      en: 'Reglamento',
      es: 'Reglamento'
    }
  },
  component: Reglamento,
  children: [
    {
      path: '',
      component: ReglamentoView,
      meta: { name: 'Index', group: 'portalalumno', path: 'reglamento', permission: 'READ' }
    },
    {
      path: 'confirm',
      component: ReglamentoConfirm,
      meta: { name: 'Confirm', group: 'portalalumno', path: 'reglamento', permission: 'READ' }
    },
    {
      path: 'honestidad',
      component: ReglamentoView,
      meta: { name: 'honestidad', group: 'portalalumno', path: 'reglamento', permission: 'READ' }
    },
    {
      path: 'practicas',
      component: ReglamentoView,
      meta: { name: 'practicas', group: 'portalalumno', path: 'reglamento', permission: 'READ' }
    },
    {
      path: 'evaluaciones',
      component: ReglamentoView,
      meta: { name: 'evaluaciones', group: 'portalalumno', path: 'reglamento', permission: 'READ' }
    },
  ]
}

export default ReglamentoRoute