import subscriptionRulesTranslations from './SubscriptionRules/Translations';
import billingTranslations from './Billing/Translations';
import referredCompaniesTranslations from './ReferredCompanies/Translations';
import creditCardInterestTranslations from './CreditCardInterest/Translations';

const pricingTranslations = {
  es: {
    subscription_rules: { ...subscriptionRulesTranslations.es },
    billing: { ...billingTranslations.es },
    referred_companies: { ...referredCompaniesTranslations.es },
    interest: { ...creditCardInterestTranslations.es}
  },
  en: {
    subscription_rules: { ...subscriptionRulesTranslations.en },
    referred_companies: { ...referredCompaniesTranslations.en },
    billing: { ...billingTranslations.en },
    interest: { ...creditCardInterestTranslations.en}
  }
};

export default pricingTranslations
