
const dashboardTranslations = {
  es_CL: {
    home: {
      no_student: {
        subtitle: 'Para realizar alguna gestión, ingresa el RUT de un estudiante en el buscador ubicado en la esquina superior derecha de la pantalla.',
      },
    },
    subjectsTab: {
      inProgress: {
        noSubjects: 'No tienes asignaturas en curso',
        regular: 'Habilitada'
      },
      regular: {
        title: 'HABILITADAS',
        noSubjects: 'No tienes asignaturas habilitadas',
        regular: 'Habilitada',
      },
      approved: {
        noSubjects: 'No tienes asignaturas aprobadas',
        subjects: 'Asignaturas',
        modal: {
          recoveredScores: 'Notas recuperadas por asignatura'
        }
      }
    },
    onboarding: {
      centralPrimaryBanner: {
        classStart: 'Las clases comienzan el {classStart}',
        test: {
          text: {
            title: 'Haz tu Test de Habilidades',
            comment: '¡No tardarás más de 15 minutos en hacerlo! Tus respuestas nos ayudarán a conocerte mejor y brindarte herramientas para que tu avance sea exitoso.'
          },
          buttons: {
            first: {
              text: 'Hacer el Test'
            },
            second: {
              text: '¿Para qué sirve?'
            }
          }
        },
        schedule: {
          text: {
            title: '{studentName}, agenda tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
            comment: 'Tendrás un encuentro virtual con tu Asesor de Inducción. ¡Tú eliges el día y el horario! Súmate para conocer todo sobre la Modalidad 100% Online y las herramientas digitales que usarás durante la carrera.<br><strong>¡No te lo pierdas!</strong>'
          },
          buttons: {
            first: {
              text: 'Ver fechas disponibles'
            },
          }
        },
        scheduled: {
          text: {
            title: '{studentName}, el <span style="background-color: #A2DAA8">{onboardingDate}</span> tienes<br>tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a> por Zoom',
            comment: 'Revisa tu correo electrónico y verifica que hayas recibido el enlace. Si no te llegó, <a href="{ref}" style="text-decoration: underline">contacta</a> a tu Asesor de Inducción.'
          }
        },
        reschedule: {
          text: {
            caption: 'Te perdiste la Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
            title: 'No te preocupes, ¡puedes elegir<br>una nueva fecha!',
            comment: 'Reagenda el encuentro virtual con tu Asesor de Inducción.'
          },
          buttons: {
            first: {
              text: 'Ver nuevas fechas'
            }
          }
        },
        onboarded: {
          text: {
            title: 'Estas guías te prepararán mejor<br>antes de empezar las clases',
            comment: 'Vas a encontrar toda la información que necesitas para tener la mejor #ExperienciaIPP'
          },
          buttons: {
            first: {
              text: '¿Cómo usar el Campus?'
            },
            second: {
              text: '¿Cómo estudiar?'
            },
            third: {
              text: '¿Cómo aprobar?'
            }
          }
        }
      },
      centralSecondaryBanner: {
        lackSkills: {
          text: {
            title: 'Potencia tus Habilidades',
            comment: 'Refuerza tus competencias con los cursos pensados para ti.'
          },
          button: {
            text: 'Ingresar'
          }
        },
        enoughSkills: {
          text: {
            title: 'Aprovecha el curso de Técnicas de Estudio',
            comment: 'Empieza tu clases con mayor facilidad.'
          },
          button: {
            text: 'Ingresar al curso'
          }
        }
      },
      sidePrimaryBanner: {
        test: {
          text: {
            title: 'Haz tu Test de Habilidades',
            comment: 'Solo son 15 min. Tus respuestas nos ayudarán a conocerte mejor para brindarte el apoyo que necesitas antes del inicio de clases.'
          },
          buttons: {
            first: {
              text: 'Hacer el Test'
            },
            second: {
              text: '¿Para qué sirve?'
            }
          }
        },
        schedule: {
          text: {
            title: '{studentName}, agenda tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
            comment: 'Elige la fecha y hora para el encuentro con tu Asesor de Inducción<br><strong>¡No te lo pierdas!</strong>'
          },
          buttons: {
            first: {
              text: 'Ver fechas disponibles'
            },
          }
        },
        scheduled: {
          text: {
            title: 'El <span style="background-color: #A2DAA8">{onboardingDate}</span> es tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
            comment: 'Revisa el correo electrónico. Si no recibiste el enlace, <a href="mailto:inducciones@ipp.cl" style="text-decoration: underline">contacta</a> a tu Asesor de Inducción.'
          }
        },
        reschedule: {
          text: {
            caption: 'Te perdiste tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
            title: 'No te preocupes, ¡puedes elegir<br>una nueva fecha!',
            comment: 'Reagenda el encuentro virtual con tu Asesor de Inducción.'
          },
          buttons: {
            first: {
              text: 'Ver nuevas fechas'
            }
          }
        },
        onboarded: {
          text: {
            title: 'Guías para empezar mejor tus clases',
          },
          buttons: {
            first: {
              text: '¿Cómo usar el Campus?'
            },
            second: {
              text: '¿Cómo estudiar?'
            },
            third: {
              text: '¿Cómo aprobar?'
            }
          }
        }
      },
      sideSecondaryBanner: {
        lackSkills: {
          text: {
            title: 'Potencia tus Habilidades',
            comment: 'Refuerza tus competencias con los cursos pensados para ti.'
          },
          button: {
            text: 'Ingresar'
          }
        },
        enoughSkills: {
          text: {
            title: 'Aprovecha las Técnicas de Estudio',
            comment: 'Empieza tus clases con todas las herramientas 100% Online.'
          },
          button: {
            text: 'Ingresar al curso'
          }
        }
      }
    },
    banners: {
      welcome: {
        message: 'En este portal encontrarás toda la información necesaria para cursar tu carrera'
      }
    },
    referFriend: {
      subtitle: 'Compartí IPP con tus amigos y obtené grandes beneficios en tu próximo semestre',
    },
  },
  es_PE: {
      breadcrumbs: {
          description:'Acá podrás ver el estado general de tu carrera relacionado a tus cursos y al calendario académico junto a las promociones vigentes.'
      },
      home: {
          no_student: {
              subtitle: 'Para realizar alguna gestión, ingresa el DNI de un estudiante en el buscador ubicado en la esquina superior derecha de la pantalla.',
          },
      },
      subjectsTab: {
          inProgress: {
              noSubjects: 'No tienes nada pendiente por cursar',
          },
          regular: {
              noSubjects: 'No tienes cursos regulares',
          },
          approved: {
              title: 'APROBADOS',
              noSubjects: 'No tienes cursos aprobados',
              subjects: 'Cursos',
              modal: {
                  recoveredScores: 'Notas recuperadas por curso'
              }
          }
      },
      onboarding: {
          centralPrimaryBanner: {
              schedule: {
                  text: {
                      title: '{studentName}, agenda tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
                      comment: 'Tendrás un encuentro virtual con tu Asesor de Inducción. ¡Tú eliges el día y el horario! Súmate para conocer todo sobre la Modalidad 100% Online y las herramientas digitales que usarás durante la carrera.<br><strong>¡No te lo pierdas!</strong>'
                  },
              },
          },
          centralSecondaryBanner: {
              enoughSkills: {
                  text: {
                      title: 'Aprovecha las Técnicas de estudio',
                      comment: 'Completa tu cursada con mayor facilidad'
                  },
                  button: {
                      text: 'Ingresar al curso'
                  }
              }
          },
          sidePrimaryBanner: {
              test: {
                  text: {
                      title: 'Haz tu Test de Habilidades',
                      comment: 'Solo son 15 min. Tus respuestas nos ayudarán a conocerte mejor para brindarte el apoyo que necesitas antes del inicio de clases.'
                  },
                  buttons: {
                      first: {
                          text: 'Hacer el Test'
                      },
                      second: {
                          text: '¿Para qué sirve?'
                      }
                  }
              },
              schedule: {
                  text: {
                      title: '{studentName}, agenda tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
                      comment: 'Elige la fecha y hora para el encuentro con tu Asesor de Inducción<br><strong>¡No te lo pierdas!</strong>'
                  },
                  buttons: {
                      first: {
                          text: 'Ver fechas disponibles'
                      },
                  }
              },
              scheduled: {
                  text: {
                      title: 'El <span style="background-color: #A2DAA8">{onboardingDate}</span> es tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
                      comment: 'Revisa el correo electrónico. Si no recibiste el enlace, <a href="mailto:inducciones@ipp.cl" style="text-decoration: underline">contacta</a> a tu Asesor de Inducción.'
                  }
              },
              reschedule: {
                  text: {
                      caption: 'Te perdiste tu Charla de <a href="javascript:void(0)">#BienvenidaIPP</a>',
                      title: 'No te procupes, ¡puedes elegir<br>una nueva fecha!',
                      comment: 'Reagenda el encuentro virtual con tu Asesor de Inducción.'
                  },
                  buttons: {
                      first: {
                          text: 'Ver nuevas fechas'
                      }
                  }
              },
              onboarded: {
                  text: {
                      title: 'Guías para empezar mejor tus clases',
                  },
                  buttons: {
                      first: {
                          text: '¿Cómo usar el Campus?'
                      },
                      second: {
                          text: '¿Cómo estudiar?'
                      },
                      third: {
                          text: '¿Cómo aprobar?'
                      }
                  }
              }
          },
          sideSecondaryBanner: {
              enoughSkills: {
                  text: {
                      title: 'Aprovecha las Técnicas de estudio',
                      comment: 'Completa tu cursada con mayor facilidad'
                  },
                  button: {
                      text: 'Ingresar'
                  }
              }
          },
      },
      banners: {
          due: {
              reminder: 'Ten en cuenta que para poder inscribirte a nuevos cursos debes <strong>regularizar tu deuda.</strong>',
              reminderAlert: 'Te recordamos que si <strong>al día {nextDueDate} tienes 3 cuotas adeudadas,</strong> tu condición de estudiante será dada de baja.',
          },
          welcome: {
              greeting: '¡Hola {studentName}!',
              message: 'En este portal vas a encontrar  toda la información necesaria para cursar tu carrera'
          }
      },
  },
  es: {
    referFriend: {
      title: 'Referí a tu amigo',
      subtitle: 'Compartí Teclab con tus amigos y obtené grandes beneficios en tu próximo cuatrimestre',
      email: 'Email',
      share: 'Compartir',
      email_subtitle: 'Ingresá el mail de quien quieras referir',
      email_sent: 'Mail enviado correctamente',
      validation: {
        required: 'Debes ingresar un mail',
        email: 'El mail tiene un formato inválido',
      }
    },
    breadcrumbs: {
      title: 'Dashboard',
      description:'Acá podrás ver el estado general de tu carrera relacionado a tus materias y al calendario académico junto a las promociones vigentes.'
    },
    home: {
      message: 'Para realizar alguna gestión dirígete al menú lateral. Ahí encontrarás todas las opciones disponibles.',
      no_student: {
        title: '¡Hola {name}!',
        subtitle: 'Para realizar alguna gestión, ingresa el DNI de un alumno en el buscador ubicado en la esquina superior derecha de la pantalla.',
      },
    },
    subjectsTab: {
      inProgress: {
        title: 'EN CURSO',
        noSubjects: 'No tenés materias en curso',
        selfAssessments: 'Autoevaluaciones',
        regular: 'Regular',
        approved: 'Aprobada',
        disapproved: 'No aprobada',
        pending: 'Pendiente'
      },
      regular: {
        title: 'REGULARES',
        noSubjects: 'No tenés materias regulares',
        regular: 'Regularizada',
        expiration: 'Vencimiento'
      },
      approved: {
        title: 'APROBADAS',
        noSubjects: 'No tenés materias aprobadas',
        subjects: 'Materias',
        examDate: 'Fecha examen',
        score: 'Nota',
        averageWithDeferrals: 'Promedio con aplazos',
        averageWithoutDeferrals: 'Promedio sin aplazos',
        modal: {
          formula: 'Fórmula de nota (M1 + M2 + M3 + M4) / 4) * 0.6) + (EX * 0.4)',
          recoveredScores: 'Notas recuperadas por materia'
        }
      }
    },
    banners: {
      due: {
        installmentsAlert: 'Parece que se te han vencido {dueInstallments} cuotas',
        installments: 'Tienes {dueInstallments} cuotas vencidas',
        reminderAlert: 'Te recordamos que si <strong>al día {nextDueDate} tienes 3 cuotas adeudadas,</strong> tu condición de alumno será dada de baja.',
        reminder: 'Ten en cuenta que para poder inscribirte a nuevas asignaturas debes <strong>regularizar tu deuda.</strong>',
        showDetail: 'Ver detalle'
      },
      welcome: {
        greeting: '¡Hola {studentName}!',
        message: 'En este portal vas a encontrar  toda la información necesaria para cursar tu carrera'
      }
    },
    calendar: {
      title: 'CALENDARIO ACADÉMICO',
      dates: 'FECHAS DE INTERÉS'
    },
    onboarding: {
      centralPrimaryBanner: {
        classStart: 'Las clases comienzan el {classStart}',
        test: {
          text: {
            title: 'Hacé tu Test de Habilidades',
            comment: 'No te demora más de 15 minutos hacerlo y allí encontrarás preguntas que nos orientarán para ayudarte a tener una cursada exitosa.'
          },
          buttons: {
            first: {
              text: 'Hacer el test'
            },
            second: {
              text: '¿Para qué sirve?'
            }
          }
        },
        schedule: {
          text: {
            title: '{studentName}, agendá tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
            comment: 'Realizá un encuentro virtual con tu Coach Académico. Vos elegis el día y horario. El 80% de los estudiantes que asistieron aprobaron sus exámenes finales.<br><strong>¡No te lo pierdas!</strong>'
          },
          buttons: {
            first: {
              text: 'Ver fechas disponibles'
            },
          }
        },
        scheduled: {
          text: {
            title: '{studentName}, el <span style="background-color: #A2DAA8">{onboardingDate}</span> tenés tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a> por Zoom',
            comment: 'Revisá tu correo electrónico para verificar que hayas recibido el enlace. Si no te llegó, <a href="{ref}" target="_blank" style="text-decoration: underline">contactate</a> con tu Coach Académico.'
          }
        },
        reschedule: {
          text: {
            caption: 'Te perdiste tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
            title: 'No te preocupes, te damos nuevas fechas',
            comment: 'Reagendá el encuentro virtual con tu Coach Académico.'
          },
          buttons: {
            first: {
              text: 'Ver nuevas fechas'
            }
          }
        },
        onboarded: {
          text: {
            title: 'Estas guías potencian tu cursada',
            comment: 'Vas a encontrar toda la información necesaria para que puedas tener una mejor experiencia.'
          },
          buttons: {
            first: {
              text: '¿Cómo usar el Portal?'
            },
            second: {
              text: '¿Cómo estudiar?'
            },
            third: {
              text: '¿Cómo aprobar?'
            }
          }
        }
      },
      centralSecondaryBanner: {
        lackSkills: {
          text: {
            title: 'Potenciá tus Habilidades',
            comment: 'Reforzá tu conocimiento con los cursos pensados para vos'
          },
          button: {
            text: 'Ingresar'
          }
        },
        enoughSkills: {
          text: {
            title: 'Aprovechá las Técnicas de estudio',
            comment: 'Completá tu cursada con mayor facilidad'
          },
          button: {
            text: 'Ingresar al curso'
          }
        }
      },
      sidePrimaryBanner: {
        test: {
          text: {
            title: 'Hacé el Test de Habilidades',
            comment: 'Son 15 minutos y encontrarás preguntas que nos orientarán para ayudarte a tener una cursada exitosa.'
          },
          buttons: {
            first: {
              text: 'Hacer el test'
            },
            second: {
              text: '¿Para qué sirve?'
            }
          }
        },
        schedule: {
          text: {
            title: '{studentName}, agendá tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
            comment: 'Elegí fecha y hora y realizá el encuentro con tu Coach.<br><strong>¡No te lo pierdas!</strong>'
          },
          buttons: {
            first: {
              text: 'Ver fechas disponibles'
            },
          }
        },
        scheduled: {
          text: {
            title: 'El <span style="background-color: #A2DAA8">{onboardingDate}</span> por Zoom es tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
            comment: 'Revisá tu correo electrónico y si no tenés el enlace <a href="{ref}" target="_blank" style="text-decoration: underline">comunicate</a> con tu Coach'
          }
        },
        reschedule: {
          text: {
            caption: 'Te perdiste tu Charla de <a href="javascript:void(0)">#BienvenidaTeclab</a>',
            title: 'No te preocupes, te damos nuevas fechas',
            comment: 'Reagendá el encuentro virtual con tu Coach Académico'
          },
          buttons: {
            first: {
              text: 'Ver nuevas fechas'
            }
          }
        },
        onboarded: {
          text: {
            title: 'Guías que potencian tu cursada',
          },
          buttons: {
            first: {
              text: '¿Cómo usar el Portal?'
            },
            second: {
              text: '¿Cómo estudiar?'
            },
            third: {
              text: '¿Cómo aprobar?'
            }
          }
        }
      },
      sideSecondaryBanner: {
        lackSkills: {
          text: {
            title: 'Potenciá tus Habilidades',
            comment: 'Reforzá tu conocimiento con los cursos pensados para vos'
          },
          button: {
            text: 'Ingresar'
          }
        },
        enoughSkills: {
          text: {
            title: 'Aprovechá las Técnicas de estudio',
            comment: 'Completá tu cursada con mayor facilidad'
          },
          button: {
            text: 'Ingresar'
          }
        }
      },
    }
  },
  en: {

  }
};


  export default dashboardTranslations
