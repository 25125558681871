<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewSubject">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.subjects.create.breadcrumbs.title')"
            :description="$t('modules.subjects.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :switch-value="newSubject.status"
                @switchChange="changeStatus()"
                class="mb-6"
                :subtitle="$t('modules.subjects.create.cardOne.subtitle')"
                :switch-label="changeStatuslabel"
                switcher
                :title="$t('modules.subjects.create.cardOne.title')"
              >
                <v-row>
                  <v-col sm="7" class="columnstwo">
                    <v-text-field
                      :error-messages="($v.newSubject.description.$dirty && $v.newSubject.description.$invalid) ?
                      ((!$v.newSubject.description.required) ? $t('modules.subjects.validations.nameRequired') :
                      (!$v.newSubject.description.alphaNumWithSpaces) ? $t('modules.subjects.validations.nameAlphanumericWithSpaces') :
                      $t('modules.subjects.validations.nameMaxLength')) :
                      ''"
                      :label="`${$t('modules.subjects.create.cardOne.items.name')} *`"
                      outlined
                      v-model="$v.newSubject.description.$model"
                      @blur="()=>$v.newSubject.description.$touch()"
                      @keypress="$validateAlphanumeric($event, $v.newSubject.description.$model,60, true)"
                      ref="description"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="2" class="columnstwo">
                    <v-text-field
                      :error-messages="($v.newSubject.code.$dirty && $v.newSubject.code.$invalid) ?
                      ((!$v.newSubject.code.required) ? $t('modules.subjects.validations.codeRequired') :
                      (!$v.newSubject.code.alphaNum) ? $t('modules.subjects.validations.codeAlphanumeric') :
                      (!$v.newSubject.code.maxLength) ? $t('modules.subjects.validations.codeMaxLength') :
                      $t('modules.subjects.validations.codeUnique')) :
                      ''"
                      :loading="loadingCode"
                      :label="`${$t('modules.subjects.create.cardOne.items.code')} *`"
                      type="text"
                      outlined
                      v-model="$v.newSubject.code.$model"
                      
                      @blur="validateCode"
                      ref="code"
                      @keypress="$validateAlphanumeric($event, $v.newSubject.code.$model,10)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="5" class="columns textfieldheight">
                    <v-select
                      outlined
                      :label="`${$t('modules.subjects.create.cardOne.items.type')} *`"
                      v-model="$v.newSubject.type.$model"
                      :items="modalityTypes"
                      :error-messages="($v.newSubject.type.$dirty && $v.newSubject.type.$invalid) ? $t('modules.subjects.validations.typeRequired') : ''"
                      @blur="()=>$v.newSubject.type.$touch()"
                      item-text="meaning"
                      ref="type"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="columns textfieldwidth">
                    <v-text-field
                      :error="$v.newSubject.credits.$anyError"
                      :error-messages="($v.newSubject.credits.$dirty && $v.newSubject.credits.$invalid) ? $t('modules.subjects.create.validations.creditsRequired') : ''"
                      :label="`${$t('modules.subjects.create.cardOne.items.credits')}  *`"
                      outlined
                      v-model.trim="$v.newSubject.credits.$model"
                      type="number"
                      min="1"
                      max="999"
                      @keypress="$validateIntegerInputNumber($event, $v.newSubject.credits.$model, null, 3)"
                      @blur="()=>$v.newSubject.credits.$touch()"
                      ref="credits"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3" class="columns textfieldwidth">
                    <v-text-field
                      :error="$v.newSubject.hours.$anyError"
                      :error-messages="($v.newSubject.hours.$dirty && $v.newSubject.hours.$invalid) ? $t('modules.subjects.create.validations.hoursRequired') : ''"
                      :label="`${$t('modules.subjects.create.cardOne.items.hours')} *`"
                      outlined
                      v-model.trim="$v.newSubject.hours.$model"
                      type="number"
                      min="1"
                      max="3000"
                      @keypress="$validateIntegerInputNumber($event, $v.newSubject.hours.$model, 3000)"
                      @blur="()=>$v.newSubject.hours.$touch()"
                      ref="hours"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="2" class="columns textfieldwidth">
                    <v-radio-group
                      class="text-uppercase"
                      row
                    >{{$t('modules.subjects.create.cardOne.items.duration')}}</v-radio-group>
                  </v-col>
                  <v-col sm="6" class="columns textfieldwidth">
                    <v-radio-group v-model="newSubject.duration.value" row>
                      <v-radio
                        v-for="n in durationTypes"
                        color="primary"
                        :key="n.value"
                        :label="`${n.meaning}`"
                        :value="n.value"
                        ref="duration"
                      ></v-radio>
                      <v-alert
                          text
                          type="error"
                          v-if="$v.newSubject.duration.$anyError"
                        >{{ $t('modules.subjects.validations.durationRequired') }}
                      </v-alert>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="4">
                    <v-select
                      outlined
                      :label="`${$t('modules.subjects.create.cardOne.items.examType')} *`"
                      v-model="$v.newSubject.examType.$model"
                      :items="examenTypes"
                      :error-messages="($v.newSubject.examType.$dirty && $v.newSubject.examType.$invalid) ? $t('modules.subjects.validations.examTypeRequired') : ''"
                      @blur="()=>$v.newSubject.examType.$touch()"
                      item-text="meaning"
                      ref="examType"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col sm="4" class="columns textfieldheight">
                    <v-checkbox
                      v-model="modalityStatus"
                      :label="`${$t('modules.subjects.create.cardOne.items.modality')}`"
                    />
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                :subtitle="$t('modules.subjects.create.cardTwo.subtitle')"
                :title="$t('modules.subjects.create.cardTwo.title')"
                class="mb-6"
              >
                <TransferList
                  ref="competitions"
                  :avatar="false"
                  :loading="loadingSearchCompetitions"
                  :firstListTitle="$t('modules.subjects.create.cardTwo.label1')"
                  :secondListTitle="$t('modules.subjects.create.cardTwo.label2')"
                  :availableItems.sync="competitions"
                  :added-items.sync="$v.newSubject.competitions.$model"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchCompetitions"
                  @searchItemRightOnBackend="searchCompetitionsAdded"
                ></TransferList>
                <v-alert
                  text
                  type="error"
                  class="mt-4"
                  v-if="$v.newSubject.competitions.$dirty && $v.newSubject.competitions.$anyError"
                >{{ $t('modules.subjects.create.cardTwo.no_selected_warning') }}</v-alert>
                <div class="d-flex justify-end" v-if="canCreate">
                  <Button
                    class="mt-8"
                    outlined
                    :text="$t('modules.subjects.create.buttonCompetition.label')"
                    @clicked="tmpDrawer.open = true"
                    @end="resetButtonValues()"
                  ></Button>
                </div>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/subjects')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate"
                  @end="resetButtonValues()"
                  @clicked="verifySubject"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
      <TemporaryRightDrawer
        v-if="canCreate"
        :open="tmpDrawer.open"
        @tmpDrawerOpen="tmpDrawerOpen"
        :title="$t('modules.subjects.competition.breadcrumbs.title')"
        :description="$t('modules.subjects.competition.breadcrumbs.subtitle')"
      >
        <CompetencesCreate
          @closeDrawer="()=>{this.tmpDrawer.open = false; this.getCompetitions(null, true)}"
          :insideDrawer="true"
          v-if="tmpDrawer.open"
        ></CompetencesCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import CompetencesCreate from './CompetencesCreate';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { required, minValue, maxLength, helpers, alphaNum} from 'vuelidate/lib/validators';
import Button from '@/components/Button/Button';
import { $types, $subjects, $competitions } from '../Services';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

export default {
  name: 'SubjectCreate',
  components: {
    TransferList,
    OutlinedCard,
    Breadcrumbs,
    Button,
    CompetencesCreate,
    TemporaryRightDrawer,
    SuccessMessage
  },
  data () {
    return {
      paginationLimit : 100,
      loadingSearchCompetitions: false,
      modalityTypes: [],
      durationTypes: [],
      insideDrawer: false,
      tmpDrawer: {
        open: false,
        title: this.$t('modules.subjects.competition.breadcrumbs.title'),
        description: this.$t('modules.subjects.competition.breadcrumbs.subtitle')
      },
      createNewSubject: null,
      successMessage: {
        title: null,
        actionPrimary: null
      },
      finishButton: {
        loading: false,
        success: false,
        error: false,
        text: this.$t('actions.create'),
        successText: this.$t('actions.created'),
        errorText: 'error'
      },
      loadingCode: false,
      codeUnique: null,
      newSubject: {
        code: null,
        description: '',
        status: true,
        hours: null,
        credits: null,
        duration: {
          value: 49
        },
        type: null,
        examType: null,
        competitions: []
      },
      itemsCount: 0,
      modalityStatus: false,
      examenTypes: [],
      competitions: [],
      createButton: {
        loading: false,
        success: false,
        error: false
      }
    };
  },
  async mounted () {
    this.getCompetitions();
    this.getModalityTypes();
    this.getExamTypes();
    this.getDurationTypes();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.subjects.CREATE
    },
    changeStatuslabel () {
      return this.newSubject.status
        ? this.$t('modules.subjects.create.cardOne.labelEnable')
        : this.$t('modules.subjects.create.cardOne.labelDisable');
    },
  },
  methods: {
    async validateCode() {
      this.$v.newSubject.code.$touch();
      if (this.$v.newSubject.code.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $subjects.find('exists', null, {params: {code: this.$v.newSubject.code.$model}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.newSubject.code.$touch();
        }
      }
    },
    changeStatus () {
      this.newSubject.status = !this.newSubject.status;
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
      this.getCompetitions(null, true);
    },
    removeItems () { },
    addItems (items) {
      this.newSubject.competitions = items;
    },
    async getModalityTypes () {
      try{
        const fetchedModalityTypes = await $types.find(null, null, {params: { type: 'SUBJECT_TYPE' }});
        this.modalityTypes = fetchedModalityTypes.data;
      }catch(error){
        this.modalityTypes = [];
        throw error;
      }
    },
    async getExamTypes () {
      try{
        const { data } = await $types.find(null, null, {params: {type: 'EXAM_TYPE'}});
        this.examenTypes = data
      }catch{
        this.examenTypes = [];
      }
    },
    async getDurationTypes () {
      const fetchedDurationTypes = await $types.find(null, null, {params: { type: 'SUBJECT_DURATION' }});
      this.durationTypes = fetchedDurationTypes.data;
      this.durationTypes = this.durationTypes.map(type => {
        return {
          type: type.type,
          value: type.value,
          meaning: type.meaning
        };
      });
    },
    searchCompetitions (event) {
      this.getCompetitions(event);
    },
    searchCompetitionsAdded (event) {
      this.newSubject.searchCompetitionsAdded = event
    },
    async getCompetitions(competition, drawer = false) {
        this.loadingSearchCompetitions = true;
        let competitions = [];
        if (competition) {
          competitions = await $competitions.find(null, null, {params: { name: competition , length: this.paginationLimit, orderBy: 'id', orientation: 'desc' }});
        } else {
          competitions = await $competitions.find(null, null, {params: { length: this.paginationLimit, orderBy: 'id', orientation: 'desc' }});
        }
        if (competitions.data && competitions.data.length) {
          competitions = competitions.data.map(item => {
            return {
              id: item.id,
              name: item.name
            };
          });
        }
        if(drawer) {
          this.competitions = competitions
            .filter(
              item =>
                !this.newSubject.competitions
                  .map(item => item.id)
                  .includes(item.id)
            )
            .map(item => ({ name: item.name, id: item.id }));
        } else {
          this.competitions = competitions;
        }
      this.loadingSearchCompetitions = false;
    },
    async createSubject () {
      if (this.$v.newSubject.$invalid || !this.codeUnique) {
        this.$v.newSubject.$touch();
        if(!this.codeUnique) {
          this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
        } else {
          Object.keys(this.$v.newSubject).some(input => {
            if (input.includes('$')) return false;
            if (this.$v.newSubject[input].$error) {
              this.$refs[input][0]
                  ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                  : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              return true
            }
          })
        }
        this.createButton.error = true;
        this.createButton.loading = false;
    } else {
        if (!this.canCreate) return false
        this.createButton.loading = true;
        this.newSubject.competitions = this.newSubject.competitions.map(item => {return {id: item.id}});
        this.newSubject.hasOldModality = this.modalityStatus;
        this.newSubject.description = this.newSubject.description.trim()
        this.newSubject.type = {value: this.newSubject.type}
        this.newSubject.examType = {value: this.newSubject.examType}
        try {
          await $subjects.create(this.newSubject);
          this.successMessage.type = 'success';
          this.successMessage.title = `${this.newSubject.description} ${this.$t('modules.subjects.create.success_message.description')}`
          if (!this.insideDrawer) {
            this.successMessage.actionPrimary = {text: this.$t('modules.subjects.create.success_message.actions.primary_text'), callback: () => {this.$router.push('/subjects')}};
            this.successMessage.actionSecondary = {text: this.$t('modules.subjects.create.success_message.actions.secondary_text'), callback: () => {this.createNewSubject = false}};
          } else {
            this.successMessage.actionPrimary = {text: this.$t('modules.subjects.create.success_message.actions.primary_text'), callback: () => {this.$emit('closeDrawer')}};
            this.successMessage.actionSecondary = null;
          }
          this.clearData();
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.subjects.create.error_message.title');
          this.successMessage.actionSecondary = null;
          if (!this.insideDrawer) {
            this.successMessage.actionPrimary = {text: this.$t('modules.subjects.create.success_message.actions.primary_text'), callback: () => {this.$router.push('/subjects')}};
          } else {
            this.successMessage.actionPrimary = {text: this.$t('modules.subjects.create.success_message.actions.primary_text'), callback: () => {this.$emit('closeDrawer')}};
          }
        } finally {
          this.createNewSubject = true;
          this.createButton.loading = false;
          this.createButton.error = true;
        }
      }
    },
    async verifySubject () {
      if (!this.newSubject.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.subjects.create.warning_status.title'),
          content: null,
          actionPrimary: {
            text: this.$t(
              'modules.subjects.create.warning_status.actions.primary_text'
            ),
            callback: () => {
              this.createSubject();
            }
          },
          actionSecondary: {
            text: this.$t(
              'modules.subjects.create.warning_status.actions.secondary_text'
            ),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary'
        });
        this.createButton.loading = false;
      } else {
        this.createSubject();
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.newSubject.code = null,
        this.newSubject.description = null,
        this.newSubject.status = true,
        this.newSubject.hours = null,
        this.newSubject.credits = null,
        this.newSubject.examType = null,
        this.newSubject.duration = {
          value: 49
        },
        this.newSubject.type =  null,
        this.newSubject.competitions = [];
      this.getCompetitions()
    }
  },
  validations: {
    newSubject: {
      description: { 
            required,
            maxLength: maxLength(60),
            alphaNumWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ0-9]*(?:[a-zá-úñ0-9]+\s[a-zá-úñ0-9]*)*$/i),
      },
      code: { 
        required,
        alphaNum,
        maxLength: maxLength(10),
        unique() {
        return this.codeUnique != false;
         },
      },
      type: { required },
      credits: { required, minValue: minValue(0) },
      hours: { required, minValue: minValue(0) },
      duration: { required },
      examType: { required },
      status: { required },
      competitions: { required },    
       
    }
  }
};
</script>

<style lang="sass">
  .containerPadding
    padding-left: 34px
    padding-right: 34px
</style>