export default {
    es_CL: {
        errors: {
            cityRequired: 'Ingrese una ciudad',
        },
        selectEntryOptionItems: {
            province: 'Región *',
        },
        form: {
            locality: 'Ciudad',
            institutionCity: 'Comuna',
        },
        detail: {
            location: 'Ciudad',
        },
        table: {
            headers: {
                city: 'Ciudad',
            },
            search: {
                placeholder: 'RUT',
            },
        },
        create: {
            province: 'Región *',
        },
    },
    es_PE: {
        errors: {
            cityRequired: 'Ingrese un distrito',
        },
        selectEntryOptionItems: {
            province: 'Departamento *',
        },
        form: {
            locality: 'Distrito',
            institutionCity: 'Distrito',
        },
        detail: {
            location: 'Distrito',
            city: 'Distrito',
        },
        create: {
            message: {
                successCreationSubtitle: 'Los datos de acceso fueron enviados por mail al estudiante para continuar con la preinscripción.',
            },
            province: 'Departamento *',
        },
        table: {
            headers: {
                city: 'Distrito',
            },
        },
    },
    es: {
        popup: {
            withoutPrice: 'No se han encontrado precios para dicha carrera y CSE',
            no_hubspot_id: 'No se encontro ningun contacto en Hubspot asociado con el mail ingresado',
        },
        errors: {
            required: 'Campo obligatorio',
            format: 'Formato invalido',
            cityRequired: 'Ingrese una localidad',
            alphabetical:'Solo letras y no más de un espaciado'
        },
        selectsEmpty: {
            careers: 'No se encontraron Carreras',
            studyPlans: 'No se encontraron menciones',
            terms: 'No se encontraron Periodos'
        },
        selectEntryOptionItems: {
            career: 'Carrera',
            province: 'Provincia *',
        },
        summary: {
            summary: 'Tu resumen',
            total: 'Total',
            conditionsTax: 'T.E.A 46,21 % - C.F.T 57,96%',
            periodPromotion: '* Promoción válida hasta el'
        },
        view: {
            labels: {
                concept: 'Concepto',
                paymentsExpiration: 'Vencimiento',
                amount: 'Monto'
            }
        },
        form: {
            discount: 'Descuento',
            installments: 'Cuotas',
            personalInfo: 'Datos del postulante',
            email: 'Correo electrónico',
            docType: 'Tipo de identificación',
            docNumber: 'Número de identificación',
            docSerial: 'Número de serie',
            audit: 'Auditoría de Postulación',
            name: 'Nombres',
            lastName: 'Apellidos',
            maritalStatus: 'Estado civil',
            birthDate: 'Fecha de nacimiento',
            gender: 'Género',
            birthPlace: 'Lugar de nacimiento',
            nationality: 'Nacionalidad',
            street: 'Calle',
            number: 'Número',
            secondaryDegree: 'Titulo secundario',
            floor: 'Piso',
            department: 'Dto.',
            city: 'Comuna',
            zipCode: 'Código Postal',
            phone: 'Teléfono fijo',
            cellphone: 'Teléfono móvil',
            preinscription: 'Datos de postulación',
            previousStudies: 'Estudios Previos',
            institutionCity: 'Ciudad',
            institution: 'Colegio',
            educationalLevel: 'Nivel de estudios',
            career: 'Carrera',
            good_average: 'Buen promedio',
            locality: 'Localidad',
            searchByInput: 'Buscar por',
            courseStart: 'Inicio de cursado',
            studyPlan: 'Mención',
            term: 'Período',
            paymentAndDiscounts: 'Pagos y descuentos',
            payment: 'Descuentos',
            finance: 'Financiacíon',
            uniquePay: 'Pago único',
            duesOf: 'Cuotas de',
            enrollmentText: 'Matrícula 2020',
            tariffText: 'Arancel',
            listPrice: 'Precio de lista',
            benefits: 'Beneficios',
            typeBenefits: {
                communityIpp: 'Comunidad Ipp',
            },
            prices:{
                referredFriend: 'Amigo referido',
                registration: 'Matrícula',
                tariff: 'Arancel'
            },
            paymentMethod: 'Forma de pago',
            typeOfDiscount: 'Descuento aplicado:',
            scholarships: 'Becas',
            validPromotion: '* Promoción válida hasta el',
            bonificationWarning1: 'Si paga el total de las cuotas obtiene ',
            bonificationWarning2: 'una bonificación del 10 % ',
            bonificationWarning3: 'adicional.',
            detailsWarning: 'El detalle de las cuotas se verá reflejado en el resumen de su tarjeta de crédito.',
        },
        detail: {
            data: 'Datos del postulante',
            editdata: 'Editar Datos',
            tariff: 'Postulación',
            numb: '30/04/2020',
            semtariff: 'Arancel Semestral',
            documentation: 'Documentación obligatoria',
            audit: 'Auditoría de Postulación',
            gotoProcess: 'Ir al trámite',
            viewHistory: 'Ver Historial',
            viewFile: 'Ver Archivo',
            status: 'Aprobado',
            statusTitle: 'Estado',
            file: 'Archivo',
            fileName: 'Nombre del archivo.jpg',
            date: 'Fecha',
            dates: '05/12/2019 18:21:14',
            completeName: 'Nombre Completo',
            maritalStatus: 'Estado civil',
            site: 'Centro de servicio',
            rut: 'Rut',
            serialNumber: 'Número de serie',
            birthDate: 'Fecha de Nacimiento',
            gender: 'Género',
            male: 'Masculino',
            email: 'Email',
            email2: 'j.juarez@gmail.com',
            nationality: 'Nacionalidad',
            admission: 'Período de Admisión',
            birthday:'Fecha de Nacimiento',
            street: 'Calle',
            address: 'Dirección',
            total: 'Total a pagar',
            number: 'Número',
            floor: 'Piso',
            department: 'Dto.',
            city: 'Ciudad',
            zipCode: 'Código Postal',
            phone: 'Teléfono fijo',
            cellphone: 'Teléfono móvil',
            benefit: 'Beneficio aplicado',
            benefit30:'30% Beca Rendimiento Académico',
            career: 'Carrera',
            location: 'Localidad',
            serviceCenter: 'Centro de Servicio',
            requestStatus: 'Estado solicitud',
            nº: '$70.000',
            licence: 'Licencia de enseñanza media',
            identity: 'Cédula de identidad vigente',
            dues: 'Cuotas',
            expiration: 'Vencimiento',
            amount: 'Monto',
            unsubscribe: 'Dar de baja'
        },
        create: {
            message: {
                successCreationTitle: 'Preinscripción creada correctamente',
                successCreationSubtitle: 'Los datos de acceso fueron enviados por mail al alumno para continuar con la preinscripción.',
                error: 'Error al preinscribir',
                debt: 'El estudiante seleccionado, posee una deuda pendiente de pago.',
            },
            modalActions: {
                accept: 'Aceptar',
                createAnother: 'Crear otra',
                back: 'Volver'
            },
            breadcrumbs: {
                title: 'Preinscripción de estudiante',
                description: 'Crea una nueva preinscripción',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                },
                iconDescription: 'Acciones'
            },
            preregister: 'Completar Matrícula',
            preregistered: 'Preinscripto',
            province: 'Provincia *',
            discountDetailTitle1: 'Pagando hasta el día 5',
            discountDetailTitle2: 'Pagando hasta el día 10',
            discountDetailTitle3: 'Pagando hasta el día 15',
            discountLabel1: '30% de dto.',
            discountLabel2: '30% de dto.',
            discountLabel3: '30% de dto.',
            creditCards: 'Tarjetas',
            cash: 'Contado',
            countDues: 'Selecciona la cantidad de cuotas para pago total',
            dues: 'Cuotas'
        },
        update: {
            preregister: 'Guardar Matrícula',
            message: {
                success: '¡Preinscripción editada correctamente!',
                error: 'Error al editar preinscripción'
            },
            modalActions: {
                accept: 'Aceptar',
                back: 'Volver'
            },
            breadcrumbs: {
                title: 'Editar preinscripción',
                description:'Editar los datos de la preinscripción',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                }
            },
        },
        table: {
            of: 'de',
            goToPage: 'Ir a página',
            rowActions: {
                edit: 'Editar',
                unsubscribe: 'Dar de baja'
            },
            headers: {
                name: 'Nombre',
                RUT: 'Identificación',
                term: 'Inicia',
                city: 'Localidad',
                career: 'Carrera',
                status: 'Estado'
              },
            search: {
                placeholder: 'DNI',
                buttonLabel: 'Buscar'
            },
            breadcrumbs: {
                title: 'Preinscripción de estudiante',
                subtitle:'Administración de preinscripciones',
                button: {
                    icon: 'mdi-pencil',
                    name: 'Editar',
                    color: 'primary',
                }
            },
            asidebar: {
                firstFilter: {
                    name: 'Carrera',
                },
                secondFilter: {
                    name: 'Centro de servicio'
                },
                thridFilter: {
                    name: 'Estado',
                    inputs: {
                        firstInput: {
                            name: 'Anulado'
                        },
                        secondInput: {
                            name: 'Pendiente'
                        },
                        thirdInput: {
                            name: 'Inscrito'
                        }
                    }
                },
                fourthFilter: {
                    name: 'Inicio cursado'
                },
            },
            messages: {
                success: '¡Preinscripción dada de baja correctamente!',
                successBulk: '¡Preinscripciones dadas de baja correctamente!',
                askDelete: '¿Estás seguro de eliminar?',
            }
        },
    },
    en: {



    }
};
