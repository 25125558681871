<template>
    <v-dialog v-model="modalOpen" max-width="600px" @click:outside="closeDialog" content-class="modal-content">
        <v-card class="px-5 py-6">
            <v-card-title class="headline" v-if="title">{{ title }}</v-card-title>
            <!-- <v-divider v-if="divider" class="my-0"></v-divider> -->
            <v-card-subtitle class="mt-0" v-if="subtitle" v-html="subtitle"></v-card-subtitle>
            <v-card-text v-if="rules">
                <ol class="mx-3">
                    <li class="my-2" v-for="(rule, index) in rules" :key="index" v-html="rule"></li>
                </ol>
            </v-card-text>

            <v-card-actions class="d-flex justify-end ma-0 mt-6 pa-0">
                <v-spacer></v-spacer>
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDialog"
                    class="mr-4"
                ></Button>
                <Button
                    :text="$t('actions.accept')"
                    @clicked="confirm"
                ></Button> 
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Button from '@/components/Button/Button';

    export default {
        name:'ModalRulesPopup',
        components:{
            Button,
        },
        props:{
            title: {type: String, default: null},
            subtitle: {type: String, default: null},
            rules: {type: Array, default: null},
            modalOpen: Boolean,
        },
        methods:{
            closeDialog() {
                this.$emit('cancel')
            },
            confirm() {
                this.$emit('confirm')
            },
        },
    }
</script>