<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!showMessage">
      <Breadcrumbs :title="$t('modules.portalalumno.exams.breadcrumbs.title')"></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <v-stepper v-model="step" class='stepper paymentStepper' :alt-labels="$isMobile">
             <v-stepper-header class="stepper-header" >
                  <v-stepper-step class="stepper-step" :complete="step > 1" :color="step > 1 ? 'green' : 'primary'" step="1">
                      {{ $t('modules.portalalumno.exams.stepperHeader.boards') }}
                  </v-stepper-step>
                  <v-stepper-step class="stepper-step" :complete="step > 2" :color="step > 2 ? 'green' : 'primary'" step="2" >
                      {{ $t('modules.portalalumno.exams.stepperHeader.schedules') }}
                  </v-stepper-step>
                  <v-stepper-step step="3" class="stepper-step">
                      {{ $t('modules.portalalumno.exams.stepperHeader.subjects') }}
                  </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row>
                  <v-col md="8">
                    <div class="text-center pb-8">
                      <div class="boards-title">{{ $t('modules.portalalumno.exams.dateStep.title') }}</div>
                      <div class="text-caption boards-subtitle mt-0">{{ $t('modules.portalalumno.exams.dateStep.subtitle') }}</div>
                    </div>
                    <ExamsCalendar
                      :dates="dates"
                      :date-button="dateButton"
                      @dateSelection="onDayClick"
                      @getMonthAndYear="getMonthAndYear"
                      @getDates="fetchDates"
                    ></ExamsCalendar>
                    <div class="mx-auto d-flex pt-4 calendar-button">
                      <Button
                          :disabled="!dateButton.visible"
                          :text="$t('actions.next')"
                          :loading="dateButton.loading"
                          :success="dateButton.success"
                          :error="dateButton.error"
                          :successText="$t('actions.success')"
                          :errorText="$t('actions.error')"
                          @clicked="goToSchedules"
                          @end="resetButtonValues(dateButton)"
                      ></Button>
                    </div>
                  </v-col>
                  <v-col md="4" class="margin-bot">
                    <ExamsRequestList
                        :title="$t('modules.portalalumno.exams.dateStep.requestList.title')"
                        :exams="examRequests"
                        :loading="loading"
                        @deletePopup="questionWhyCloseExam"
                    ></ExamsRequestList>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <template v-if="examLimitReached">
                    <v-card flat class="text-center mb-3">
                      <v-icon color="warning" size="154px">
                        mdi-shield-alert
                      </v-icon>
                    <v-card-text class="text-center text-h5">
                      {{$t('modules.portalalumno.exams.scheduleStep.error_message.examLimitReached')}}
                    </v-card-text>
                  </v-card>
                </template>
                <template  v-else>
                  <div class="text-center text-weight-medium step-title">
                    {{ `${$t('modules.portalalumno.exams.scheduleStep.title')} ${titleFormattedDate}` }}
                  </div>
                  <ExamsScheduleCardList
                  :schedules="schedules"
                  :schedule-button="scheduleButton"
                  @cardSelection="onScheduleClick"
                  ></ExamsScheduleCardList>
                </template>
                  <v-row class="justify-center my-6">
                    <Button
                    class="mx-8"
                    :text="$t('actions.return')"
                    :disabled="dateButton.backDisable"
                    white
                    @clicked="backToDates"
                    ></Button>
                    <Button
                    v-if="scheduleButton.visible"
                    :text="$t('actions.next')"
                    :loading="scheduleButton.loading"
                    :success="scheduleButton.success"
                    :error="scheduleButton.error"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    @clicked="goToSubjects"
                    @end="resetButtonValues(scheduleButton)"
                    ></Button>
                  </v-row>
                </v-stepper-content>
              <v-stepper-content step="3">
                <div class="text-center text-weight-medium step-title">
                  {{ `${$t('modules.portalalumno.exams.subjectStep.title')} ${titleFormattedDate}` }}
                </div>
                <div class="text-center text-weight-medium step-title">
                  {{ subjects.length ? $t('modules.portalalumno.exams.subjectStep.subtitle') : $t('modules.portalalumno.exams.subjectStep.emptyListMessage') }}
                </div>
                <ExamsSubjectCardList
                  :subjects="subjects"
                  :subject-button="subjectButton"
                  @cardSelection="onSubjectClick"
                ></ExamsSubjectCardList>
                <v-row class="justify-center py-4">
                  <Button
                    class="mx-8"
                    :text="$t('actions.return')"
                    :disabled="scheduleButton.backDisable"
                    white
                    @clicked="backToSchedules"
                  ></Button>
                  <Button
                    v-if="subjectButton.visible"
                    :text="$t('actions.confirm')"
                    :loading="subjectButton.loading"
                    :success="subjectButton.success"
                    :error="subjectButton.error"
                    :successText="$t('actions.success')"
                    :errorText="$t('actions.error')"
                    @clicked="attemptsValidation"
                    @end="resetButtonValues(subjectButton)"
                  ></Button>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <ExamDeleteOption v-if="deleteExam" @validateCloseExam="deleteExamPopUp" :exams="examRequests" :open="deleteExam" @close="deleteExam = false"/>
    </v-container>
      <SuccessMessage
        v-else
        :title="successMessage.title"
        :subtitle="successMessage.subtitle"
        :type="successMessage.type"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      >
        <div v-if="proctoring">
          <p class="text-muted mt-0 text-center mb-10">Turno :  {{ detalleHorarioExamen }}hs</p>
          <v-alert
            rounded="lg"
            text
            color="info"
            class="py-2"
          >
              <v-container>
                <v-row :class="['align-center' ,{'d-flex flex-column':$vuetify.breakpoint.xs }]">
                  <v-col
                  :cols="$vuetify.breakpoint.xs?0:4"
                   class="d-flex align-center "
                   :class="{'px-1':$vuetify.breakpoint.xs}">
                    <img :src="proctoring.logo" :height="proctoring.name == 'Klarway' ? '35px' : '40px'"/>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.xs?0:8" class="pt-0 px-0">
                    <p class="grey--text section-content mb-0">{{ $t('modules.portalalumno.exams.notification_proctoring.text_1') }}{{ proctoring.name }}.
                      <br>{{ $t('modules.portalalumno.exams.notification_proctoring.text_2') }}<br>
                      <span v-if="proctoring.name == 'Klarway'"><strong>{{ $t('modules.portalalumno.exams.notification_proctoring.text_3') }}</strong> {{ $t('modules.portalalumno.exams.notification_proctoring.text_4') }}<br></span>{{ $t('modules.portalalumno.exams.notification_proctoring.text_5') }} <a href="/apps" style="text-decoration: underline">{{ $t('modules.portalalumno.exams.notification_proctoring.text_6') }}</a></p>
                  </v-col>
                </v-row>
              </v-container>
          </v-alert>
        </div>
      </SuccessMessage>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import ExamsCalendar from '../Components/ExamsCalendar';
  import ExamsRequestList from '../Components/ExamsRequestList';
  import ExamDeleteOption from '../Components/ExamDeleteOption';
  import ExamsScheduleCardList from '../Components/ExamsScheduleCardList';
  import ExamsSubjectCardList from '../Components/ExamsSubjectCardList';
  import Button from '@/components/Button/Button';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { $examenes } from '../Services';
  import moment from 'moment';
  import { mapGetters } from 'vuex'

  export default {
    name: 'ExamsView',
    components: {
      Breadcrumbs,
      ExamsCalendar,
      ExamsRequestList,
      ExamsScheduleCardList,
      ExamsSubjectCardList,
      ExamDeleteOption,
      Button,
      SuccessMessage
    },
    data() {
      return {
        dates: [new Date(1900, 1, 1)],
        examRequests: [],
        materiasDisponibles: false,
        schedules: [],
        examLimitReached:null,
        subjects: [],
        step: 1,
        pedidoExamen: null,
        pageMonth: new Date().getMonth() + 1,
        pageYear: new Date().getFullYear(),
        selectedDate: null,
        selectedSchedule: null,
        selectedSubject: null,
        deleteExam: null,
        dateButton: {
          visible: false,
          backDisable: true,
          loading: false,
          success: false,
          error: false,
        },
        scheduleButton: {
          visible: false,
          backDisable: true,
          loading: false,
          success: false,
          error: false,
        },
        subjectButton: {
          visible: false,
          loading: false,
          success: false,
          error: false,
        },
        loading: false,
        showMessage: false,
        successMessage: {
          type: null,
          title: null,
          subtitle: null,
          actionPrimary: null,
          actionSecondary: null
        },
        proctoring:null,
        detalleHorarioExamen: ''
      };
    },
    computed: {
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentUserId: 'commons/getStudentUserID',
      }),
      canEnroll() {
        return this.canCreate && this.materiasDisponibles
      },
      canCreate() {
        return this.$permissions.portalalumno.exams.CREATE
      },
      titleFormattedDate() {
        const date = moment(this.selectedDate);
        return `${date.locale('es').format('dddd')} ${date.format('DD-MM-YYYY')}`;
      },
    },
    mounted() {
      this.fetchExamRequests();
    },
    methods: {
      questionWhyCloseExam(value){
        this.deleteExam = true
        this.pedidoExamen = value
      },
      deleteExamPopUp(value){
        this.deleteExam = false
        this.deleteExamRequestPopup(value);
      },
      async fetchDates() {
        try {
          if (!this.canEnroll) return false
          const datesRaw = await $examenes.find(`fechasExamen`, null, {
            params: {
              idCeo: this.$isTeclab ? '369' : '546',
              todos: 'false',
              leg: this.studentRegister,
              month: this.pageMonth,
              year: this.pageYear
            }
          });
          const availableDates = datesRaw.data.fechaExamenDisponible;
          this.dates = availableDates.length
              ? availableDates.map(date => moment(date, 'DD/MM/YYYY').format('YYYY/MM/DD'))
              : [new Date(1900, 1, 1)];
        } catch (err) {
          this.dates = [new Date(1900, 1, 1)];
          throw err;
        }
      },
      async fetchExamRequests() {
        try {
          this.loading = true;
          const examRequests = await $examenes.find('listaPedidos', null, { params: { leg: this.studentRegister } });
          this.examRequests = examRequests.data.items;
          this.materiasDisponibles = examRequests.data.materiasDisponibles;
          this.fetchDates();
        } catch (err) {
          this.examRequests = [];
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.exams.dateStep.error_message.title'),
            content: null,
            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary'
          });
          throw err;
        } finally {
          this.loading = false;
        }
      },
      getMonthAndYear(month, year) {
        this.pageMonth = month;
        this.pageYear = year;
      },
      onDayClick(selectedDate) {
        this.dateButton.visible = !!selectedDate;
        this.selectedDate = selectedDate;
      },
      onScheduleClick(selectedSchedule) {
        this.scheduleButton.visible = !!selectedSchedule;
        this.selectedSchedule = selectedSchedule;
      },
      onSubjectClick(selectedSubject) {
        this.subjectButton.visible = !!selectedSubject;
        this.selectedSubject = selectedSubject;
      },
      async goToSchedules() {
        if (!this.dateButton.success && !this.dateButton.error) {
          try {
            this.dateButton.loading = true;
            const schedules = await $examenes.find(`ceoagenda`, null, {
              params: {
                idCeo: this.$isTeclab ? '369' :'546',
                fecha: moment(this.selectedDate).format('DD/MM/YYYY'),
                studentCareerId: this.studentRegister
              }
            });
            this.schedules = schedules.data.items;
            this.examLimitReached = schedules.data.examLimitReached
            this.$trackEvent('Exámenes', 'click - siguiente', 'Selección fecha');
            this.dateButton.success = true;
            this.step++;
          } catch (err) {
            this.schedules = [];
            this.dateButton.error = true;
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.portalalumno.exams.dateStep.error_message.title'),
              content: null,
              actionPrimary: { text: this.$t('actions.accept'), callback() {} },
              icon: {color: 'error', name: 'mdi-close'},
              color: 'primary'
            });
            throw err;
          } finally {
            this.dateButton.loading = false;
          }
        }
      },
      async goToSubjects() {
        if (!this.scheduleButton.success && !this.scheduleButton.error) {
          try {
            this.scheduleButton.loading = true;
            const subjects = await $examenes.find(`inscripcion`, null, { params: { leg: this.studentRegister, idCeoAgenda: this.selectedSchedule } });
            this.subjects = subjects.data.items;
            this.$trackEvent('Exámenes', 'click - Siguiente2', 'Selección horario');
            this.scheduleButton.success = true;
            this.step++;
          } catch (err) {
            this.subjects = [];
            this.scheduleButton.error = true;
            this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.portalalumno.exams.scheduleStep.error_message.title'),
              content: null,
              actionPrimary: { text: this.$t('actions.accept'), callback() {} },
              icon: {color: 'error', name: 'mdi-close'},
              color: 'primary'
            });
            throw err;
          } finally {
            this.scheduleButton.loading = false;
          }
        }
      },
      backToDates() {
        this.$trackEvent('Exámenes', 'click - Volver', 'Selección horario');
        this.schedules = [];
        this.scheduleButton.visible = false;
        this.dateButton.backDisable = true;
        this.selectedDate = null;
        this.selectedSchedule = null;
        this.step--;
      },
      backToSchedules() {
        this.$trackEvent('Exámenes', 'click - Volver', 'Selección materia');
        this.subjects = [];
        this.scheduleButton.backDisable = true;
        this.selectedSchedule = null;
        this.selectedSubject = null;
        this.step--;
      },
      resetButtonValues(button) {
        if (!button.error) {
          button.visible = false;
          button.backDisable = false;
        }
        button.loading = false;
        button.success = false;
        button.error = false;
      },
      attemptsValidation() {
        if (this.$isTeclab && this.selectedSubject.attemptsNumber >= 3) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.exams.create.attempts_message.title'),
            content: [{value: this.$t('modules.portalalumno.exams.create.attempts_message.subtitle1')},
              {value: this.$t('modules.portalalumno.exams.create.attempts_message.subtitle2')}],
            actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createExamRequest()},
            actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary'
          })
        } else {
          this.createExamRequest()
        }
      },
      async createExamRequest() {
        try {
          this.subjectButton.loading = true;
          const subject = this.subjects.find(subject => subject.alumnoMateriaId == this.selectedSubject.id);
          const resp = await $examenes.create({}, {
            params: {
              leg: this.studentRegister,
              idCeo: this.$isTeclab ? 369 : 546,
              idCeoAgenda: this.selectedSchedule,
              idAlumnoMateria: this.selectedSubject.id,
              idUsuario: this.studentUserId,
              attemptsNumber: this.selectedSubject.attemptsNumber + 1
            }
          }, 'inscripcion');
          this.proctoring = resp.data.proctoringType === 272 ? {
            name: 'Klarway',
            logo: require('@/assets/images/portalalumno/klarway.png')
          } : {name: 'Sumadi', logo: require('@/assets/images/portalalumno/sumadi.png')}
          this.detalleHorarioExamen = resp.data.turn
          this.successMessage.type = 'success';
          this.successMessage.title = resp.data.pendingPayment ? this.$t('modules.portalalumno.exams.create.success_message.pendingPayment.title')  : this.$t('modules.portalalumno.exams.create.success_message.title');
          this.successMessage.subtitle = resp.data.pendingPayment ? this.$t('modules.portalalumno.exams.create.success_message.pendingPayment.subtitle') : `${this.$t('modules.portalalumno.exams.create.success_message.subtitle')} ${subject.materia}`;
          this.successMessage.actionSecondary = {
            text: this.$t('actions.close'), callback: () => { this.$router.push('/dashboard') }
          };
          this.successMessage.actionPrimary = resp.data.pendingPayment
            ? { text: this.$t('modules.portalalumno.exams.create.success_message.pendingPayment.goToPayments'), callback: () => { this.$router.push('/payments') } }
            : { text: this.$t('actions.create_another'), callback: () => {
                this.pageMonth = new Date().getMonth() + 1;
                this.pageYear = new Date().getFullYear();
                this.showMessage = false;
                this.step = 1;
                this.fetchExamRequests();
                this.fetchDates();
              }
            };
          this.$trackEvent('Exámenes', 'click - Confirmar', 'Selección materia');
          this.subjectButton.success = true;
          this.showMessage = true;
        } catch (error) {
          this.proctoring = null
          this.successMessage.type = 'error';
          this.successMessage.title = error.code == 'E203' ? this.$t('modules.portalalumno.exams.create.error_message.E203') : this.$t('modules.portalalumno.exams.create.error_message.title');
          this.successMessage.subtitle = null;
          this.successMessage.actionPrimary = {
            text: this.$t('actions.return'), callback: () => {
              this.showMessage = false;
            }
          };
          this.successMessage.actionSecondary = null;
          this.subjectButton.error = true;
          this.showMessage = true;
        } finally {
          this.subjectButton.loading = false;
        }
      },
      deleteExamRequestPopup(value) {
        if (this.$isTeclab) {
          const examRequest = this.examRequests.find(examRequest => examRequest.pedidoExamenId == this.pedidoExamen);
          const mensaje = !examRequest.examRequestAlert ? this.$t('modules.portalalumno.exams.delete.confirmation_delete.title') :
                                                          this.$t('modules.portalalumno.exams.delete.confirmation_delete.title_exam_request');
          this.$store.dispatch('commons/openPopUp', {
            title: mensaje,
            content: [{value: examRequest.materia}],
            actionPrimary: {text: this.$t('actions.annul'), callback: () => this.deleteExamRequest(value)},
            actionSecondary: {
              text: this.$t('actions.cancel'), callback() {
              }
            },
            icon: {color: 'error', name: 'mdi-delete'},
            color: 'primary'
          });
        } else {
          this.deleteExamRequest(value)
        }
      },
        async deleteExamRequest(value) {
        const body = {
          user: this.studentUserId,
          observation: value.comments ? value.comments : '' ,
          reason: {
            value: value.option.value
          }
        }
        try {
          this.loading = true;
          await $examenes.create(body, null ,`inscripcion/${this.pedidoExamen}`);
        } catch {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.exams.delete.error_message.title'),
            content: null,
            actionPrimary: { text: this.$t('actions.accept'), callback() {} },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary'
          });
        } finally {
          await this.fetchExamRequests();
          this.loading = false;
        }
      },
    },
    watch: {
      step() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  };
</script>

<style scoped lang="sass">

.calendar-button
  width: 58%
  justify-content: flex-end

.boards-title
  color: #777
  text-align: center
  margin-top: 0
  font-size: 1.3rem
  font-weight: bold
  letter-spacing: -1px

.boards-subtitle
  color: #777

.step-title
  letter-spacing: -0.5px
  color: #777

.stepper-col
  background: #ecedf0
  height: 100%
  padding: 18px 40px 40px

.stepper
  background: transparent
  border: none
  box-shadow: none !important

.stepper-header
  box-shadow: none
  overflow: hidden
  border: solid 1px #c1c1c1
  height: auto
  border-radius: 6px

.v-stepper__step__step
  display: none !important

.stepper-tabs
  width: 100%
  border-radius: 0.25em

ul
  margin: 0
  padding: 0
  list-style-type: none
  overflow: hidden
  white-space: nowrap

li
  background: #ecedf0
  text-align: center
  font-size: 16px
  font-weight: bold
  color: #c7c7c7
  width: 33.33%
  padding: 6px 6px 6px 10px
  position: relative
  display: inline-block
  vertical-align: top

  &.active
    background: white
    color: var(--v-primary-base)

    + li:before
      background: white

  &.active:before
    background: #ecedf0

  + li:before
    content: ''
    position: absolute
    top: 0em
    bottom: 0
    border-right: solid 2px #c1c1c1
    border-top: solid 2px #c1c1c1
    pointer-events: none
    box-sizing: border-box

    /* a shadow effect can be added too
    width: 2em
    transform: rotate(30deg) skewy(30deg)
    z-index: 1

  + li:before
    left: -1.5em

@media only screen and (max-width: 600px)
  li, li + li
    padding: 1em
    font-size: 12px
    width: 30%

  li:nth-child(3)
    width: 40%
  .v-stepper--alt-labels .v-stepper__step
    flex-basis: 0px !important
@media screen and (min-width: 640px)
  .paymentStepper.v-stepper:not(.v-stepper--vertical) .v-stepper__label
      display: flex
  .v-stepper--alt-labels .v-stepper__step
    flex-basis: 0px !important

</style>
