<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <div class="settings-container">
            <div class="title primary--text px-6 pt-4">{{$t('modules.modulecontrol.table.asidebar.title')}} </div>
            <AsideBar :categories="categories" @click="getStatusTable"></AsideBar>
        </div>
        <v-container fluid class="container-custom scrollableContent pa-0 ">
            <v-row no-gutters justify="center">
                <v-col sm="10">
                    <Breadcrumbs
                        :title="$t('modules.modulecontrol.table.breadcrumbs.title')"
                        :description="$t('modules.modulecontrol.table.breadcrumbs.description')"
                        class="mt-2"
                    >
                    </Breadcrumbs>
                </v-col>
            </v-row>
            <v-row justify="center" class="mx-8">
                <v-col sm="12" class="viewSpaces">
                    <SuperTable
                        :headers="headers"
                        :validations="validations"
                        :correction="correction"
                        @getDataCorrectionTable="getDataCorrectionTable"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
    import AsideBar from '../Components/AsideBar/AsideBar';
    import SuperTable from '../Components/SuperTable/SuperTable';
    import api from '@/api';
   
    export default {
        components: { 
            Breadcrumbs,
            AsideBar,
            SuperTable,
        },
         data () {
            return {
                service:'',
                correction: [],
                services:[],
                validations:[],
                categories: [],
                permissions: [],
                headers: [
                    {
                    text: this.$t('modules.modulecontrol.table.headers.name'),
                    value: 'name',
                    sortable: false
                    },
                    {
                    text: this.$t('modules.modulecontrol.table.headers.description'),
                    value: 'description',
                    sortable: false
                    },
                    {
                    text: this.$t('modules.modulecontrol.table.headers.status'),
                    value: 'status',
                    }
                ],
            }
        },
        methods:{
            getService(){
                Object.entries(api).forEach(service => {
                    if(service[1].$systemValidation){
                        this.fetchModule(service);
                    }
                })
            },
            async fetchModule(service){
                if(service[0] == '$core'){
                    const {data}= await service[1].$systemValidation.find();     
                    // Se toma la información del servicio de core, se la ordena, luego se ve que permisos posee segun el rol, y luego se formatea para poder utilizarla en el asidebar 
                    data.sort((a, b) => {
                        return a.service.localeCompare(b.service) || a.group.localeCompare(b.group);
                    });

                    this.categories = data.reduce((acc, item) => {
                        if (acc[acc.length - 1]?.title != item.service) {
                            acc.push({
                                title: item.service,
                                groups: [{
                                    group: item.group,
                                    validations: [{
                                        name: item.name,
                                        permission: item.permission,
                                        description: item.description,
                                        url: item.url,
                                        optionalUrl: item.optionalUrl,
                                        status: '',
                                        correction:''
                                    }]
                                }]
                            })
                        } else {
                            if (acc[acc.length - 1]?.groups[acc[acc.length - 1]?.groups.length - 1]?.group != item.group) {
                                acc[acc.length - 1]?.groups.push({
                                    group: item.group,
                                    validations: [{
                                        name: item.name,
                                        permission: item.permission,
                                        description: item.description,
                                        url: item.url,
                                        optionalUrl: item.optionalUrl,
                                        status: '',
                                        correction:''
                                    }]
                                })
                            } else {
                                acc[acc.length - 1]?.groups[acc[acc.length - 1]?.groups.length - 1]?.validations.push({
                                    name: item.name,
                                    permission: item.permission,
                                    description: item.description,
                                    url: item.url,
                                    optionalUrl: item.optionalUrl,
                                    status: '',
                                    correction:''
                                })
                            }
                        }
                        return acc
                    }, [])
                }else{
                    // Se carga el array de service con todos los servicios que no sean core, para luego poder hacer la llamada a los sub endpoints para la informacion de las tablas en el metodo getStatusTable
                    this.services.push(service)
                }   
            },
            // Método para cargar el estado de las validaciones
            async getStatusTable(info){  
                [ this.validations, this.service ] = info;
                this.correction = []
                this.services.map(item=>{
                    if(item[0] == `$${this.service}`){
                        this.validations.map(async (val) => {
                            if(!val.status){
                                try {
                                    const {data} = await item[1].$systemValidation.find(val.url)
                                    val.status = data.message
                                    return val
                                } catch (error) {
                                    val.status = ''
                                    throw error
                                }
                            }
                        })
                    }
                })
            }, 
            // Método para llamar al endpoint de optionalUrl que trae los datos que hay que corregir
            getDataCorrectionTable(name){
                this.services.map(item=>{
                    if(item[0] == `$${this.service}`){
                        this.validations.map(async (val) => {
                            if( name == val.name){
                                if(val.optionalUrl){
                                    try {
                                        const {data} =  await item[1].$systemValidation.find(val.optionalUrl)
                                        this.correction = data;
                                        val.correction = data
                                        return val
                                    } catch (error) {
                                        this.correction = []
                                        throw error
                                    }
                                } else {
                                    this.correction = []
                                }
                            }
                        })
                    }
                })
            },
            // Método para ver los permisos del roll
            availableValidations() {
                Object.entries(this.$permissions.portaladministrativo.moduleControl).forEach(([permission, status]) => { if (status && permission != 'NAME' && permission != 'STATUS' && permission != 'READ') this.permissions.push(permission) })
            }, 
        },
        mounted() {
            this.getService();
            this.availableValidations()
        },
   }
</script>

<style lang="sass" scoped>
    .v-list-item--link 
        margin-left: 10px

    .settings-container 
        background: #ebebeb
        width: 300px
</style>