<template>
    <div class="subjects-tab">
        <div class="subjects-tab__tabs">
            <div :class="['individual-tab', {'selected': selectedTab == 0}]" @click="selectedTab = 0">{{ $t('modules.portalalumno.dashboard.subjectsTab.inProgress.title') }}</div>
            <div :class="['individual-tab', {'selected': selectedTab == 1}]" @click="selectedTab = 1">{{ $t('modules.portalalumno.dashboard.subjectsTab.regular.title') }}</div>
            <div :class="['individual-tab', {'selected': selectedTab == 2}]" @click="selectedTab = 2">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.title') }}</div>
        </div>
        <div class="subjects-tab__content elevation-3">
            <InProgressSubjects v-if="selectedTab === 0"></InProgressSubjects>
            <RegularisedSubjects v-if="selectedTab === 1"></RegularisedSubjects>
            <ApprovedSubjects v-if="selectedTab === 2"></ApprovedSubjects>
        </div>
    </div>
</template>

<script>
    import InProgressSubjects from './InProgressSubjects'
    import RegularisedSubjects from './RegularisedSubjects'
    import ApprovedSubjects from './ApprovedSubjects';

    export default {
        name: 'SubjectsTab',
        components: {
            InProgressSubjects,
            RegularisedSubjects,
            ApprovedSubjects,
        },
        data() {
            return {
                selectedTab: 0,
            }
        },
    }
</script>

<style lang="sass" scoped>
  $max-height: 254px

  .subjects-tab
    width: 100%
    background: #fff
    border-radius: 5px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08)
    &__tabs
      width: 100%
      height: 41px
      background: var(--v-secondary-base)
      border-radius: 5px 5px 0 0
      display: flex
      justify-content: flex-start
      .individual-tab
        width: 104px
        height: 92.7%
        color: #fff
        box-sizing: content-box
        display: flex
        justify-content: center
        align-items: center
        font-size: 14px
        &:hover
          border-bottom: 3px solid #fff
          cursor: pointer
        &.selected
          border-bottom: 3px solid #fff
    &__content
        width: 100%
        display: flex
        max-height: $max-height

  @media only screen and (max-width: 600px) 
    .subjects-tab
      &__tabs
        justify-content: space-evenly
      &__content
          max-height: $max-height * 2
</style>