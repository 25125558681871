<template>
	<v-container fluid class="d-flex pa-0 fullHeight">
		<v-container fluid class="scrollableContent pa-0">
			<v-container class="container-custom px-6">
				<Breadcrumbs
					:title="$t('modules.academic.documentation.breadcrumbs.title')"
					:description="$t('modules.academic.documentation.breadcrumbs.description')"
				/>
				<article v-if="loading" class="d-flex justify-center my-12">
					<v-progress-circular indeterminate size="56" color="primary" />
				</article>
				<section v-else class="mt-6">
					<div class="d-flex justify-center align-center mx-4">
						<v-btn
							block
							color="primary lighten-5"
							class="py-6 primary--text elevation-0"
							v-if="!newTypeVisible"
							@click="newTypeVisible = true"
						>
							{{ $t('modules.academic.documentation.docTypes.addNewTypeBtn') }}
							<v-icon small>mdi-plus</v-icon>
						</v-btn>
						<article class="d-flex justfy-space-between align-center px-12 py-1" style="width: 100%" v-else>
							<span class="font-weight-bold text--secondary mx-6">
								{{ $t('modules.academic.documentation.docTypes.addNewTypeName') }}
							</span>
							<v-text-field
								v-model="$v.newTypeName.$model"
								:error-messages="
									$v.newTypeName.$dirty && $v.newTypeName.$invalid ? $t('modules.academic.documentation.form.required') : ''
								"
								hide-details
								@blur="$v.newTypeName.$touch()"
								@keyup.enter="saveNewTypeModal"
								class="pt-2 pb-0 mx-6"
								:label="$t('modules.academic.documentation.docTypes.addNewTypeTextField')"
								outlined
								:disabled="loading"
							/>
							<v-btn @click="saveNewTypeModal" class="primary rounded-pill mx-6" :disabled="loading">
								{{ $t('actions.save') }}
							</v-btn>
							<v-btn @click="clearNewType" class="rounded-pill mx-6" :disabled="loading">
								{{ $t('actions.cancel') }}
							</v-btn>
						</article>
					</div>
					<v-col class="viewSpaces">
						<v-divider />
						<v-expansion-panels hover multiple :value="expandableArray">
							<v-expansion-panel v-for="(docType, i) in docTypes" :key="i" class="mx-0">
								<v-expansion-panel-header class="">
									<div class="d-flex justify-space-between align-center">
										<span class="font-weight-bold text--secondary" style="text-transform: uppercase">
											{{ docType.description }}
										</span>
										<v-btn text color="primary" @click.native.stop="addNewSubTypeBtn(i)" expanded="true">
											{{ $t('modules.academic.documentation.docTypes.addNewSubTypeBtn') }}
											<v-icon small>mdi-plus</v-icon>
										</v-btn>
									</div>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-divider class="mb-6" />
									<template v-if="!!docType.subtypeSimpleDocs.length">
										<SubTypeTable
											:subtypeSimpleDocs="docType.subtypeSimpleDocs"
											@getDocTypes="getDocTypes"
											:typeId="docType.id"
											ref="subTypeTableComponent"
										/>
									</template>
									<v-btn v-else block text color="primary" @click="addNewSubTypeBtn(i)">
										{{ $t('modules.academic.documentation.docTypes.addNewSubTypeBtn') }}
										<v-icon small>mdi-plus</v-icon>
									</v-btn>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
						<v-divider />
					</v-col>
				</section>
			</v-container>
		</v-container>
		<v-dialog
			v-model="successMessage.active"
			max-width="600px"
			:persistent="successMessage.type === 'success'"
			@click:outside="resetModal"
		>
			<SuccessMessage 
				class="white"
				:type="successMessage.type"
				:title="successMessage.title"
				:actionPrimary="successMessage.actionPrimary"
				:actionSecondary="successMessage.actionSecondary"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { $documentations } from '../Services';
import SubTypeTable from '../Components/SubTypeTable';
import { required, helpers } from 'vuelidate/lib/validators';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';


export default {
	name: 'DocumentationView',
	components: {
		Breadcrumbs,
		SubTypeTable,
		SuccessMessage,
	},
	data() {
		return {
			loading: true,
			docTypes: [],
			expandableArray: [],
			newTypeVisible: false,
			newTypeName: '',
			successMessage: {
				active: false,
				actionPrimary: null,
				actionSecondary: null,
				title: null,
				type: null,
			},
		};
	},
	async mounted() {
		await this.getDocTypes();
	},
	methods: {
		async getDocTypes() {
			try {
				this.loading = true;
				const response = await $documentations.find('types');
				this.docTypes = response.data.typeSubtypeDocs;
				this.expandableArray = [0, response.data.typeSubtypeDocs.length];
			} catch (e) {
				return e;
			} finally {
				this.loading = false;
			}
		},
		addNewSubTypeBtn(i) {
			this.expandableArray = [i, this.docTypes.length];
			this.$refs.subTypeTableComponent[this.$refs.subTypeTableComponent?.length - 1]?.reset();
			if (this.docTypes[i].subtypeSimpleDocs[this.docTypes[i].subtypeSimpleDocs?.length-1]?.documentation) return;
			this.docTypes[i].subtypeSimpleDocs.push({
				description: '',
				subDescription: '',
				required: true,
				validity: 0,
				status: true,
				documentation: {
					id: this.docTypes[i].id,
				},
			});
		},
		clearNewType() {
			this.newTypeVisible = false;
			this.newTypeName = '';
			this.$v.$reset();
		},
		saveNewTypeModal() {
			if (!this.newTypeVisible || !this.newTypeName) return this.$v.$touch();
			this.successMessage.active = true;
			this.successMessage.type = 'warning';
			this.successMessage.title = `
				${(this.$t(`modules.academic.documentation.popUp.type.insert`))} 
				"${this.newTypeName}" 
				${this.$t('modules.academic.documentation.popUp.continue')}
			`;
			this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.saveNewType(); } };
			this.successMessage.actionSecondary = { text: this.$t('actions.cancel'), callback: () => { this.resetModal() } };
		},
		async saveNewType() {
			if (!this.newTypeVisible || !this.newTypeName) return;
			this.$v.$touch();
			if (this.$v.$invalid) return;
			try {
				this.loading = true;
				await $documentations.create({
					name: this.newTypeName,
					description: this.newTypeName,
					required: true,
				});
				this.successMessage.active = true;
				this.successMessage.type = 'success';
				this.successMessage.title = this.$t(`modules.academic.documentation.popUp.type.inserted`);
				this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { 
					this.successMessage.type = null;
					this.newTypeVisible = false;
					this.newTypeName = '';
					this.$v.$reset();
					this.resetModal();
					this.getDocTypes();
				} };
				this.successMessage.actionSecondary = null;
			} catch (e) {
				this.successMessage.active = true;
				this.successMessage.type = 'error';
				this.successMessage.title = this.$t(`responseCodeMeaning.E209`);
				this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.resetModal() } };
				this.successMessage.actionSecondary = null;
				throw e;
			} finally {
				this.loading = false;
			}
		},
		resetModal(){
			if(this.successMessage.type === 'success') return;
			this.successMessage.active = false;
			this.successMessage.type = null;
			this.successMessage.title = null;
			this.successMessage.actionPrimary = null;
			this.successMessage.actionSecondary = null;
		},
	},
	validations: {
		newTypeName: { required, alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i) },
	},
};
</script>

<style scoped lang="sass">
.v-expansion-panel::before
	box-shadow: none
</style>
