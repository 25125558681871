<template>
  <v-item-group active-class="selected" v-model="selectedCardValue">
    <v-container>
      <v-row v-for="(item, index) in subjects" :key="index" cols="12" md="4"><v-col>
          <v-item v-slot="{ toggle }" :value="item.alumnoMateriaId">
            <v-card class="d-flex flex-column pa-4" @click="toggle" v-on:click="$emit('cardSelection', { id: item.alumnoMateriaId, attemptsNumber: item.cantIntentos })" style="background-color: #f5f5f5">
              <div>
                <div class="font-weight-medium text-h6 py-2 card-subject">{{ item.materia }}</div>
                <div class="text-caption py-2 card-caption">{{ item.estado }}</div>
                <v-divider></v-divider>
                <div class="text-caption text-right py-2 card-caption">{{ $t('modules.portalalumno.exams.subjectStep.card.attempts', { attemptsNumber: item.cantIntentos }) }}</div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  name: 'ExamsSubjectCardList',
  props: {
    subjects: Array,
    subjectButton: Object
  },
  data() {
    return {
      selectedCardValue: null
    };
  },
  watch: {
    subjectButton: {
      handler(val) {
        if (val.error) this.selectedCardValue = null;
      },
      deep: true
    }
  }
};
</script>

<style lang="sass" scoped>

.card-caption
  color: #777

.card-subject
  color: #0088cc

.selected
  background-color: #61BCCC !important
  color: white

.selected .card-caption
  color: white

.selected .card-subject
  color: white

</style>