<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!updateStatus">
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">

          <OutlinedCard :title="$t('modules.examTitleNotes.update.labels.detail')" class="mb-6">
            <v-row no-gutters>
              <v-col sm="4" offset-sm="1" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.update.labels.name') }}</span>
              </v-col>
              <v-col sm="6" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${selectedExam.name} ${selectedExam.lastname}` }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col sm="4" offset-sm="1" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.update.labels.act') }}</span>
              </v-col>
              <v-col sm="6" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ act }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-6">
              <v-col sm="4" offset-sm="1" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${$t('modules.examTitleNotes.update.labels.calificacion')} *` }}</span>
              </v-col>
              <v-col sm="6" class="align-start pt-1 pb-3">
                <v-text-field
                    v-model="$v.nota.$model"
                    :error-messages="($v.nota.$dirty && $v.nota.$anyError) ? $t('modules.examTitleNotes.update.validation_errors.calificacion') : ''"
                    outlined
                    :label="`${$t('modules.examTitleNotes.update.labels.calificacion')} *`"
                    type="text"
                    @blur="$v.nota.$touch()"
                    @keypress="$validateDecimalInputNumber($event, $v.nota.$model, 7)"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col sm="4" offset-sm="1" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${$t('modules.examTitleNotes.update.labels.fecha')} *` }}</span>
              </v-col>
              <v-col sm="6" class="align-start pt-1 pb-3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="$v.fecha.$model"
                  transition="scale-transition"
                  offset-y
                  bottom
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :error-messages="$v.fecha.$invalid && $v.fecha.$dirty ? $t('modules.examTitleNotes.update.validation_errors.fecha') : ''"
                      v-model="$v.fecha.$model"
                      :label="`${$t('modules.examTitleNotes.update.labels.fecha')} *`"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="$v.fecha.$model"
                    no-title
                    color="primary"
                    scrollable
                    :max="new Date().toISOString().substr(0, 10)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">{{ $t('actions.cancel') }}</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save($v.fecha.$model)">{{ $t('actions.save') }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col sm="10" offset-sm="1" class="d-flex align-start pb-0 pt-4">
                  <v-textarea
                    outlined
                    :label="`${$t('modules.examTitleNotes.update.labels.justification')} *`"
                    :error-messages="($v.justificacion.$dirty && $v.justificacion.$invalid) ? $t('modules.examTitleNotes.update.validation_errors.justification') : ''"
                    @blur="$v.justificacion.$touch()"
                    v-model="$v.justificacion.$model"
                  ></v-textarea>
              </v-col>
            </v-row>
            
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$emit('closeDrawer')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="updateButton.loading"
              :success="updateButton.success"
              :error="updateButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.created')"
              :errorText="'Error'"
              :disabled="!canUpdate || !canUpdateExam"
              @end="resetButtonValues"
              @clicked="updateExam"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      class="mt-12"
    />
  </v-container>
</template>

<script>

import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $examsTitle } from '../Services';

export default {
  name: 'ExamTitlesNotesUpdate',
  props: {
    selectedExam: Object,
    isOpen: Boolean
  },
  components: {
    OutlinedCard,
    Button,
    SuccessMessage,
  },
  data () {
    return {
      menu:false,
      updateButton:{
        loading:false,
        success:false,
        error:false
      },
      nota: null,
      fecha: null,
      act: null,
      justificacion: null,
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
      },
      updateStatus: false
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.UPDATE
    },
    canUpdateExam() {
      return this.nota && !this.$v.nota.$anyError && 
          this.fecha && !this.$v.fecha.$anyError && 
          (this.justificacion || this.$v.justificacion.$dirty) && !this.$v.justificacion.$anyError
    },
  },
  methods: {
    async updateExam () {
      if (!this.canUpdate) return false;
      this.updateButton.loading = true;
        
      try {
        await $examsTitle.update(this.selectedExam.id_exam, {
          description: this.justificacion, 
          score: parseFloat(this.nota), 
          date: this.fecha
        })

        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.examTitleNotes.update.success_message.title')
        this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('closeDrawer');}}
        this.updateButton.success = true;

      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.examTitleNotes.update.error_message.title');
        this.successMessage.actionPrimary = {text: this.$t('actions.return'), callback: () => { this.updateStatus = false }}
        this.updateButton.error = true;
      }
      finally {
        this.updateStatus = true;
        this.resetButtonValues();
      }
    },
    resetButtonValues () {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    },
    clearData(){
      this.resetButtonValues();
      this.justificacion = null;
      this.menu = false;
      this.updateStatus = false;
      this.$v.$reset();
    }
  },
  watch : {
    selectedExam: {
      handler (value) {
        this.nota = value.grade;
        this.fecha = value.date;
        this.act = value.act;
        this.justificacion = value.description;
      },
      deep: true
    },
    isOpen(value) {
      if(!value) this.clearData();
    }
  },
  validations: {
    nota: {
      required,
      minValue: minValue(0), 
      maxValue: maxValue(7), 
    },
    fecha: {required},
    justificacion: {required}
  }
}
</script>