<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @click:clear="search = '';keyup()"
                    @keyup="keyup"
                    @keyup.esc="search = ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    v-model="selectedItems"
                    :show-select="true"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    :totalItems="totalItems"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table"
                    style="table-layout: fixed; cursor:pointer"
                    @click:row="(row) => $emit('click-row', row)"
                    must-sort
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :server-items-length="totalItems"
                    @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
                    @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
                    >
                    <template v-slot:top>
                        <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
                            <v-divider></v-divider>
                            <div class="py-3">
                                <span class="caption mx-4 cursor-pointer hover-7-inverted" @click="$emit('changeStatus', selectedItems)">
                                    <v-icon class="mr-2">mdi-redo</v-icon>
                                    {{ $t('modules.exceptions.payments.headers.checkBox') }}
                                    </span>
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </template>
                    <template v-slot:[`header.data-table-select`]="{ on, props }">
                        <v-simple-checkbox 
                            class="superTable__table__item__checkbox" 
                            color="primary" 
                            v-bind="props" 
                            v-on="on" 
                            v-if="false">
                        </v-simple-checkbox>
                    </template>
                    <template  v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                        <v-simple-checkbox
                            class="superTable__table__item__checkbox"
                            color="primary"
                            :value="isSelected"
                            @input="select($event)"
                            v-if="item && (item.paymentStatus.value == 115 || item.paymentStatus.value == 117)"
                        ></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.paymentAmount`]="{item}">
                        {{ item.paymentAmount | $currency }}
                    </template>
                    <template v-slot:[`item.term`]="{item}">
                        {{ item.term ? item.term : '-' }}
                    </template>

                    <template v-slot:[`item.paymentStatus`]="{item}">
                        <v-chip class="ma-2" :color="paymentStatusColor(item)" :text-color="item.paymentStatus.value == 116 ? 'black' : 'white'">{{ item.paymentStatus.meaning }}</v-chip>
                    </template>

                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="itemsPerPage.toString()"
                    outlined
                    @change="$emit('length-pagination-change', itemsPerPage)"
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination 
                    @next="$emit('nextPagination', page)"
                    @previous="$emit('previousPagination', page)"
                    @input="$emit('input-pagination', $event)"
                    v-model="page" 
                    :length="pageCount" 
                    class="superTable__pagination">
                </v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    v-model="page"
                    max="pageCount"
                    outlined
                    v-model.number="goToPage"
                    @blur="changePage"
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('modules.communities.table.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SuperTable',
        props: {
            data: Array,
            headers: Array,
            permissions: {
                debt_inscription: false,
                confirm_preregistration: false,
                revert_enrollment: false,
                change_modality: false
            },
            clearSelected: String,
            deleteTitle: String,
            sortBy: String,
            sortDesc: Boolean,
            pageCount: { type: Number, default: 1 },
            page: { type: Number, default: 1 },
            totalItems: Number
        },
        data() {
            return {
                search: '',
                timeout: null,
                goToPage: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
                selectedItems: [],
            }
        },
        methods: {
            
            paymentStatusColor(item) {
                if (item.paymentStatus.value == 114) return 'orange'
                if (item.paymentStatus.value == 115) return 'green'
                if (item.paymentStatus.value == 116) return 'default'
                if (item.paymentStatus.value == 117) return 'red'
            },
            keyup () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.searchAction()
                    clearTimeout(this.timeout);
                },1000)
            },
            searchAction() {
                this.$emit('searchAction', this.search)
            },
            canShow(action, career) {
                if (action == 'debtInscription') return this.permissions.debt_inscription && career.exception && career.careerStatus.value == 54
                else if (action == 'confirmPreregistration') return this.permissions.confirm_preregistration && career.careerStatus.value == 51
                else if (action == 'revertEnrollment') return this.permissions.revert_enrollment && career.careerStatus.value == 54
                else if (action == 'changeModality') return this.permissions.change_modality && !career.oldModality && career.careerStatus.value == 54
            },
            changePage() {
                if (this.goToPage > this.pageCount) this.goToPage = this.page;
                else this.$emit('input-pagination', this.goToPage)
            },
        },
        watch:{
            page(val) {
                if (val > this.pageCount) this.page = this.pageCount;
                if (val <= 1) this.page = 1;
                if (this.page !== this.goToPage) this.goToPage = this.page;
            },
            clearSelected() {
                this.selectedItems = [];
            },
        },
        computed: {
            itemsCount () {
                const counter = {
                    totalItems: this.totalItems,
                    startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
                    endItem: this.page * this.itemsPerPage
                };
                if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
                return counter
            },
            areItemsSelected(){
                const status = [];
                this.selectedItems.forEach(element => status.push(element.paymentStatus.value));
                const set = new Set (status);
                return ((set.size != 0 && set.size <= 2) && (set.has(115) || set.has(117)));
            },
        },
    };
</script>