<template>
  <div>
    <v-container fluid v-if="!finished">
      <v-row>
        <v-col cols="12">
          <v-card outlined class="py-8 px-10">
            <h3>{{ $t('modules.benefits.details.drawer.students') }}</h3><br>
            <p>{{ $t('modules.benefits.details.drawer.description') }}</p>
            <v-col cols="8" v-if="companies.length">
              <v-autocomplete
                :label="$t('modules.benefits.details.drawer.company')"
                outlined
                :items="companies"
                item-text="description"
                item-value="id"
                v-model="selectedCompany"
              ></v-autocomplete>
            </v-col>
            <SuperTableAssign
              @searchName="helperSearcher"
              :headers="headers"
              :users="availableItems"
              :withAverage="withAverage"
              :minAverage="minAverage"
              :canSelect="canSelect"
              :pageCount="pagination.pagesAmount"
              :page="pagination.currentPage"
              :totalItems="pagination.totalItems"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              :loading="loading"
              @inputPagination="onInputPagination"
              @lengthPaginationChange="onLengthPaginationChange"
              @toggleItem="toggleItem"
              :clearSelected = "clearSelected"
            v-if="benefitType != 10"
            ></SuperTableAssign>
              <SuperTableAssignRefFriend
              @searchName="helperSearcher"
              :headers="headers"
              :users="availableItems"
              :withAverage="withAverage"
              :minAverage="minAverage"
              :canSelect="canSelect"
              :pageCount="pagination.pagesAmount"
              :page="pagination.currentPage"
              :totalItems="pagination.totalItems"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              :loading="loading"
              @inputPagination="onInputPagination"
              @lengthPaginationChange="onLengthPaginationChange"
              @toggleItem="toggleItem"
              :clearSelected = "clearSelected"
              v-else
            ></SuperTableAssignRefFriend>
          </v-card>
        </v-col>
        <v-col class="pb-8 d-flex justify-contentn-end justify-end">
          <Button
            white
            :text="$t('actions.cancel')"
            @clicked="cancel"
            :disabled="sending"
            @end="resetButtonValues()"
            class="mr-4"
          ></Button>

          <Button
            :loading="sending"
            :success="success"
            :error="error"
            :text="$t('actions.add')"
            :successText="$t('actions.added')"
            :errorText="'Error'"
            :disabled="!addedItems.length || (!!companies.length && !selectedCompany)"
            @end="resetButtonValues()"
            @clicked="checkLimit"
          ></Button>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </div>
</template>
<script>
import SuperTableAssign from '../Components/SuperTable/SuperTableAssign'
import SuperTableAssignRefFriend from '../Components/SuperTable/SuperTableAssignRefFriend'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import Button from '@/components/Button/Button';
import { $studentsWithoutBenefit, $users, $benefits } from '../Services'

export default {
  name: 'BenefitsAssign',
  components: {
    SuperTableAssign,
    SuccessMessage,
    Button,
    SuperTableAssignRefFriend
  },
  props:{
    insideDrawer: Boolean,
    withAverage: Boolean,

    minAverage: {
      type: Number,
      default: () => null,
    },
    items: {
      type: Array,
      default: () => []
    },
    benefitId: Number,
    companies: Array,
    benefitType: Number
  },
  data() {return {
    finished: false,
    loading: false,
    sending: false,
    success: false,
    error: false,
    addedItems: [],
    availableItems: [],
    selectedCompany: null,
    successMessage: {
      type: '',
      title: '',
      actionPrimary: null,
      actionSecondary: null
    },
    pagination: {
      limit: 20,
      page: 1,
      currentPage: 1,
      pagesAmount: 1,
      totalItems: 0
    },
    sort: {
      sortBy: 'name',
      sortDesc: false,
    },
    search:'',
    clearSelected:'',
  }},
  computed: {
    headers() {
      return [
        { text: this.$t('modules.benefits.details.table.identification'), value: 'identification', show: true, sort: () => 0 },
        { text: this.$t('modules.benefits.details.drawer.headers.name'), value: 'name', show: true, sort: () => 0 },
        this.benefitType == 10 ? { text: this.$t('modules.benefits.details.drawer.headers.token'), value: 'token', show: true, sort: () => 0 } : '',
        { text: this.$t('modules.benefits.details.drawer.headers.average'), value: 'average', sortable: false, show: this.withAverage }
      ].filter(item => item.show);
    },
    canSelect() {
      return   this.addedItems.length >= 0 ? true : false ;
    }
  },
  methods: {
    helperSearcher(search){
      this.search = search
      this.getAvailableStudents()
    },
    cancel() {
      this.addedItems = []
      this.clearSelected = new Date().getMilliseconds().toString();
      this.$emit('closeDrawer', false);
    },
    async getAvailableStudents() {
      if(this.loading) return
      this.loading = true;
      try {
        let sortCol = 'name';
        switch (this.sort.sortBy) {
        case 'name':
        default:
          sortCol = 'user.userData.lastname';
          break;
        }
        const referredFriend = 10
        const uniqueDiscount = 410
        const params = {
        page: this.pagination.page - 1,
        length: this.pagination.limit,
        orderBy: sortCol,
        orientation: this.sort.sortDesc ? 'desc' : 'asc',
        search: this.search,
        getAll: (this.benefitType == referredFriend || this.benefitType == uniqueDiscount)
        }
        const responseData = await $studentsWithoutBenefit.find( null, null, {params} );
        const auxStudents = responseData.data.content.map(item => {
          return {
            id: item.id,
            name: `${item.lastname} ${item.name}`,
            avatar: `${$users.baseUrl}/users/${item.userId}/avatar`,
            average: null,
            isSelected: false,
            token: null,
            identification: item.username
          };
        });
        this.pagination.pagesAmount = responseData.data.totalPages
        this.pagination.totalItems = responseData.data.totalElements
        auxStudents.forEach((student, index) => {
          const added = this.addedItems.find(el => el.id == student.id);
          if(added) auxStudents[index] = {...student, ...added};
        });
        this.availableItems = auxStudents;
      } catch (error) {
        this.availableItems = []
        throw error
      }
      finally {
        this.loading = false;
      }
    },

    toggleItem(item){
      this.addedItems = item
    },

    resetTable() {
      this.addedItems = [];
      this.getAvailableStudents();
      this.finished = false;
      this.sending = false;
      this.resetButtonValues()
      this.successMessage = {
        type: '',
        title: '',
        actionPrimary: null,
        actionSecondary: null
      }
    },
    checkLimit() {
        this.save()
    },

    async save() {
      this.sending = true;

      let payload = []
      if(this.benefitType == 10){
        payload = {
          students: this.addedItems.map(row => ({id: row.id.$model, referrerToken: row.token.$model})),
          communityId: this.selectedCompany
        };
      } else {
          payload = {
            students: this.addedItems.map(row => ({id: row.id})),
            communityId: this.selectedCompany
          };
        }
      try {
        await $benefits.create(payload,null,`assign-student/${this.benefitId}`);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.benefits.details.drawer.success.title');
        this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => {
          this.$emit('closeDrawer', true);
          this.resetTable();
        }};
        this.successMessage.actionSecondary = { text: 'Seguir agregando', callback: () => {
          this.resetTable();
          this.$emit('reloadBenefit');
        }};
        this.success = true
      }
      catch(e) {
        this.successMessage.type = 'error';
        this.successMessage.title = e.codeMeaning;
        this.successMessage.actionPrimary = { text: this.$t('modules.benefits.details.drawer.success.actions.back'), callback: () => this.finished = false };
        this.successMessage.actionSecondary = null;
        this.error = true
      }
      finally {
        this.finished = true;
        this.resetButtonValues()
      }
    },
    resetButtonValues () {
      this.sending = false;
      this.success = false;
      this.error = false;
    },
    async onInputPagination(event) {
      this.pagination.page = event;
      this.pagination.currentPage = event;
      this.getAvailableStudents();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1
      };
      this.getAvailableStudents();
    },
  },
  watch: {
    sort: {
      handler(){
        this.getAvailableStudents();
      },
      deep: true
    }
  },
  mounted() {
    this.getAvailableStudents();
  }
}
</script>
