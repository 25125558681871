import sanctionsTranslations from './Sanctions/Translations'
import periodsTranslations from './Periods/Translations'
import practiceTermsTranslations from './ProfessionalPracticeTerms/Translations'
import equivalencesTranslations from './Equivalences/Translations'

import professionalizingPractice from './ProfessionalizingPractice/Translations'
import documentation from './Documentation/Translations'

const academicTranslations = {
    es_CL: {
        sanctions: { ...sanctionsTranslations.es_CL},
        professionalizingPractice: {...professionalizingPractice.es_CL},

    },
    es_PE: {
        periods: { ...periodsTranslations.es_PE },
        professionalizingPractice: {...professionalizingPractice.es_PE},
        sanctions: { ...sanctionsTranslations.es_PE},
        documentation: { ...documentation.es_PE },
    },
    es: {
        periods: { ...periodsTranslations.es },
        sanctions: { ...sanctionsTranslations.es },
        practiceTerms: { ...practiceTermsTranslations.es },
        equivalences: { ...equivalencesTranslations.es },
        professionalizingPractice: {...professionalizingPractice.es},
        documentation: { ...documentation.es },
    },
    en: {
        periods: { ...periodsTranslations.en },
        sanctions: { ...sanctionsTranslations.en },
        practiceTerms: { ...practiceTermsTranslations.en },
        equivalences: { ...equivalencesTranslations.es }
    }
};

export default academicTranslations
