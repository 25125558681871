<template>
  <div>
    <v-container fluid class="pa-0 pb-8 fullHeight d-flex">
      <v-container fluid class="scrollableContent pa-0">
        <v-container  class="container-custom">
          <Breadcrumbs :description="$t('modules.scholarships.details.breadcrumbs.description')" :title="summary.title">
            <v-menu offset-y v-if="canUpdate || canDelete">
              <template v-slot:activator="{ on: menu }">
                <v-btn text color="primary" v-on="{ ...menu }" :disabled="!scholarship.id">
                  <v-icon>mdi-dots-vertical</v-icon>
                  {{$t('modules.scholarships.details.breadcrumbs.actions')}}
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="canUpdate" @click="editScholarship(scholarship.id)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{$t('modules.scholarships.table.search.edit')}} 
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canDelete" @click="deleteScholarshipPopUp(scholarship.id)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{$t('modules.scholarships.table.search.delete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </Breadcrumbs>
        </v-container>
        <v-container  class="container-custom pt-0 pb-8">
          <v-row justify="start" align-content="stretch">
            <v-col cols="12 pt-0">
              <v-sheet color="#f5f6f9" class="px-3 ScholarshipsDetails-info-sheet">
                <v-row>
                  <v-col cols="6" class="py-0 my-3" style="border-right: 1px solid #cecece !important;">
                    <table class="ScholarshipsDetails-info-table" height="100%">
                        <thead>
                          <tr>
                            <th class="primary--text">{{ $t('modules.scholarships.details.resume.productType') }}</th>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.discountType') }}</th>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.average') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(prod, i) in summary.prods" :key="i" :class="{'with-line': prod.first}">
                            <td>{{ prod.name }}</td>
                            <td class="text-center">{{ prod.value }}</td>
                            <td class="text-center">{{ prod.average == 0? $t('modules.scholarships.details.resume.noAverage') : prod.average }}</td>
                          </tr>
                        </tbody>
                    </table>
                  </v-col>
                  <v-col cols="6">
                    <table class="ScholarshipsDetails-info-table" height="100%">
                      <thead>
                          <tr>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.applySince') }}</th>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.status') }}</th>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.renovation') }}</th>
                            <th class="primary--text text-center">{{ $t('modules.scholarships.details.resume.documentation') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="with-line">
                            <td class="text-center">{{ summary.from }}</td>
                            <td class="text-center">{{ summary.status }}</td>
                            <td class="text-center">{{ summary.renew }}</td>
                            <td class="text-center">
                              <p class="my-2" v-for="(doc, i) in summary.documentation" :key="i">{{ doc }}</p>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="pt-1 pb-2">{{ $t('modules.scholarships.details.table.title') }}</h3>
            <v-row class="my-0 py-0">
              <v-col cols="3" align-content="stretch">
                <v-sheet color="#f5f6f9" class="px-3 ScholarshipsDetails-info-sheet">
                  <v-row justify="center" align="center" style="height:100%">
                    <v-col cols="8" justify="center" align="center">
                      <strong class="primary--text">{{ $t('modules.scholarships.details.resume.scholarshipStudents') }}</strong><br>
                    </v-col>
                    <v-col cols="4" justify="center" align="center"><h1>{{ summary.studentCount }}</h1></v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="3" align-content="stretch" v-if="scholarship.limit !== null">
                <v-sheet color="#f5f6f9" class="px-3 ScholarshipsDetails-info-sheet">
                  <v-row justify="center" align="center" style="height:100%">
                    <v-col cols="8" justify="center" align="center">
                      <strong class="primary--text">{{ $t('modules.scholarships.details.resume.availableSlots') }}</strong><br>
                    </v-col>
                    <v-col cols="4" justify="center" align="center"><h1>{{ summary.availableSlots }}</h1></v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
              <SuperTableDetails
                :headers="headers"
                :users="summary.studentsList"
                :clear-selected="clearSelected"
                :canCreate="canUpdate && (summary.availableSlots === null || summary.availableSlots > 0)"
                @add="tmpDrawer.open = true"
                @remove="remove"
                @bulk-delete="bulkRemove"
                :page-count="pagination.pagesAmount"
                :page="pagination.page"
                :total-items="totalItems"
                @searchAction="searchAction"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
                :canAssign="canAssign && (summary.availableSlots === null || summary.availableSlots > 0)"
              ></SuperTableDetails>
            </v-col>
          </v-row>
          <TemporaryRightDrawer
            v-if="canAssign && openedDrawer && (summary.availableSlots === null || summary.availableSlots > 0)"
            @tmpDrawerOpen="onCloseDrawer"
            :open="tmpDrawer.open"
            :title="tmpDrawer.title"
            :description="tmpDrawer.description"
          >
            <ScholarshipsAssign
              @closeDrawer="onCloseDrawer"
              @reloadScholarship="getScholarship"
              :items="summary.studentsList"
              :limit="scholarship.limit"
              :products="summary.prods"
              :withAverage="summary.withAverage"
              :minAverage="summary.minAverage"
              :scholarshipId="scholarship.id"
              :availableSlots="summary.availableSlots"
              insideDrawer
            ></ScholarshipsAssign>
          </TemporaryRightDrawer>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import moment from 'moment'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import SuperTableDetails from '../Components/SuperTable/SuperTableDetails'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import ScholarshipsAssign from './ScholarshipsAssign'
import { $scholarships, $users } from '../services';

export default {
  name: 'ScholarshipsDetails',
  components: {
    Breadcrumbs,
    SuperTableDetails,
    TemporaryRightDrawer,
    ScholarshipsAssign
  },
  data() {return {
    title: '',
    scholarship: {},
    students: {},
    headers: [
      {
        text: this.$t('modules.scholarships.details.table.identification'),
        value: 'identification'
      },
      {
        text: this.$t('modules.scholarships.details.table.name'),
        value: 'name'
      }, 
      {
        text: this.$t('modules.scholarships.details.table.career'),
        value: 'career'
      }, 
      {
        text: '',
        value: 'action',
        sortable: false
      },
    ],
    clearSelected: '',
    openedDrawer: true,
    tmpDrawer: {
      open: false,
      title: this.$t('modules.scholarships.details.drawer.title'),
      description: this.$t('modules.scholarships.details.drawer.subtitle')
    },
    totalItems: null,
    pagination: {
        limit: 20,
        page: 1,
        pageAmount: 1
    },
    sort: {
        sortBy: 'identification',
        sortDesc: false,
    },
    loading:false,
  }},
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.scholarships.UPDATE
    },
    canAssign() {
      return this.$permissions.portaladministrativo.prices.scholarships.ASSIGN
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.scholarships.DELETE
    },
    summary() {
      if(!this.scholarship.scholarshipProduct) return {
        title: '',
        prods: [],
        withAverage: false,
        minAverage: 0,
        from: '',
        status: '',
        renew: '',
        documentation: '',
        studentCount: 0,
        studentsList: [],
        availableSlots: 0
      };

      const auxStudents = [];
      if(this.students) {
        this.students?.forEach((item ) => {
          item.id;
          item.identification = `${item.identification}`;
          item.studentId;
          item.name = `${item.lastName} ${item.name}`;
          item.career = item.careerDescription ? `${item.careerCode} - ${item.careerDescription}` : '-'
          item.avatar = `${$users.baseUrl}/users/${item.userId}/avatar`;
          item.deleteId = item.id;
          auxStudents.push(item);
        });
      }

      let previousName = '';
      let withAverage = false;
      let minAverage = 10;
      const auxProds = this.scholarship.scholarshipProduct.map(prod => {

        let auxValue = 0;
        const auxName = prod.billingProduct.name;

        switch(prod.discountType) {
          case 'porcentual': 
            auxValue = `${prod.percentage}%`;
            break;
          case 'monto':
          default:
            auxValue = this.$options.filters.$currency(prod.amount);
            break;
        }

        if (prod.average > 0) withAverage = true;
        if (prod.average < minAverage) minAverage = prod.average;

        const auxObject = {
          id: prod.billingProduct.id || '0',
          name: previousName == auxName ? '' : auxName,
          status: prod.billingProduct.status,
          value: auxValue,
          average: prod.average,
          first: (previousName != auxName)
        };

        previousName = auxName;

        return auxObject;
      });

      const auxDocuments = [];
      this.scholarship.scholarshipDocs.forEach((item) => {
        auxDocuments.push(item.documentation.name)
        
      });

      return {
        title: this.scholarship.description,
        prods: auxProds,
        withAverage,
        minAverage,
        from: moment(this.scholarship.from).format('YYYY-MM-DD'),
        status: this.scholarship.status? this.$t('modules.scholarships.details.resume.active') : this.$t('modules.scholarships.details.resume.inactive'),
        renew: this.scholarship.renew? this.$t('modules.scholarships.details.resume.automatic') : this.$t('modules.scholarships.details.resume.manual'),
        documentation: auxDocuments,
        studentCount: this.scholarship.studentCount || 0,
        studentsList: auxStudents,
        availableSlots: this.scholarship.limit !== null ? this.scholarship.limit - this.scholarship.studentCount : null
      }
    }
  },
  methods: {
    onCloseDrawer() {
      this.getScholarship();
      this.tmpDrawer.open = false
    },
    async getScholarship() {
      try {
        this.loading=true;
      
        const params = {
            id: this.$route.params.id,
            page: this.pagination.page - 1,
            length: this.pagination.limit,
            orderBy: this.sort.sortBy,
            orientation: this.sort.sortDesc ? 'desc' : 'asc',
            search: this.search
        }
        const {data: scholarship} = await $scholarships.findById(this.$route.params.id)
        const {data: students} = await $scholarships.findById('students',null,{ params })
        this.scholarship = scholarship;
        this.students = students.content;
        this.totalItems = students.totalElements
        this.pagination.pagesAmount = students.totalPages
      } catch (error) {
        this.scholarship={};
        this.students={};
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw error;
      }finally{
        this.loading=false;
      }
    },
    async remove(idStudent) {
      try {
        const params={
          idStudent,
          idScholarship: this.$route.params.id
        }
       
        await $scholarships.removeStudent({params});
        this.getScholarship();
      }
      catch(e) {
        this.$store.dispatch('commons/openPopUp', {
          title: e.codeMeaning,
          content: null,
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        })
      }
    },
    async bulkRemove(students) {
      const itemsToDelete = students.map(el => ({id: el.deleteId, studentId: el.studentId}));
      try {
        await $scholarships.removeStudents(itemsToDelete);
        this.getScholarship();
      }
      catch(e) {
        this.$store.dispatch('commons/openPopUp', {
          title: e.codeMeaning,
          content: null,
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        })
      }
    },
    editScholarship(id) {
      if (this.canUpdate) this.$router.push(`/scholarships/update/${id}`);
    },
    deleteScholarshipPopUp(id) {
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [{value: this.scholarship.description}],
        actionPrimary: {text: 'Aceptar', callback: () => this.deleteScholarship(id)},
        actionSecondary: {text: 'Cancelar', callback() {}},
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async deleteScholarship(id) {
      try {
        await $scholarships.delete(id);
        this.$router.push(`/scholarships`);
      } catch(e) {
        this.$store.dispatch('commons/openPopUp', {
          title: e.codeMeaning,
          content: null,
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        })
      }
    },
    onInputPagination(event) {
        this.pagination.page = event;
        this.getScholarship();
    },
    onLengthPaginationChange(event) {
        this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.getScholarship();
    },
    searchAction(data) {
        this.pagination.page = 1;
        this.search = data;
        this.getScholarship();
    },
  },
  mounted() {
    this.getScholarship();
  },
  watch: {
    sort: {
      handler() {
        this.getScholarship();
      },
      deep: true
    }
  }
};
</script>

<style lang="sass">
  .ScholarshipsDetails-info-sheet
    border-radius: 5px
    border: solid 1px #cecece
    height: 100%

    span, strong 
      font-size: 14px

    h1
      font-size: 40px

    .ScholarshipsDetails-info-table 
      background: transparent
      width: 100%
      border-spacing: 0px
      
      tr 
        td, th
          text-align: left
          padding-top: 10px
          padding-bottom: 10px
          border-bottom: none !important
          font-size: 14px
        
        &.with-line td
          border-top: 1px solid #cecece !important
</style>