<template>
    <div>
        <p class="body-2 mb-10">{{ $t('modules.portalalumno.procedures.careerChange.description') }}</p>

        <v-sheet class="grey lighten-3 justify-start info-sheet mb-4">
            <v-row>
                <v-col cols="12" class="ml-7">
                    <div class="primary--text">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.currentlyStudying') }}</div>
                    <div v-if="studentCareer" class="font-weight-bold">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.currentlyCareer', { career: studentCareer.description, studyPlan: studentCareer.studyPlan.description }) }}</div>
                </v-col>
            </v-row>
        </v-sheet>

        <OutlinedCard :title="$t('modules.portalalumno.procedures.careerChange.dataSection.selectCareer')" class="mb-6">
            <v-row>
                <v-col sm="12" class="py-0">
                    <v-card-text class=" body-2 pa-0 primary--text font-weight-bold">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.selectCareer') }}</v-card-text>
                </v-col>
                <v-col sm="10">
                    <v-autocomplete
                        outlined
                        :label="$t('modules.portalalumno.procedures.careerChange.dataSection.selectCareer')"
                        v-model="$v.careerChange.career.$model"
                        :items="careers"
                        :error="$v.careerChange.career.$anyError"
                        @blur="$v.careerChange.career.$touch()"
                        item-text="name"
                        return-object
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" class="pb-0">
                <v-card-text class=" body-2 pa-0 primary--text font-weight-bold" v-if="!activePlan">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.studyPlan') }}</v-card-text>
                </v-col>
                <v-col v-if="!activePlan" sm="10">
                    <v-autocomplete
                        outlined
                        :label="$t('modules.portalalumno.procedures.careerChange.dataSection.studyPlan')"
                        v-model="$v.careerChange.studyPlan.$model"
                        :items="studyPlans"
                        :error="$v.careerChange.studyPlan.$anyError"
                        @blur="$v.careerChange.studyPlan.$touch()"
                        item-text="description"
                        return-object
                        :disabled="!$v.careerChange.career.$model"
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" class="pb-0">
                    <v-card-text class="body-2 pa-0 primary--text font-weight-bold">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.reason') }}</v-card-text>
                </v-col>
                <v-col sm="12" class="pb-0">
                    <v-card-text class="body-2 pa-0">{{ $t('modules.portalalumno.procedures.careerChange.dataSection.reasonSubtitle') }}</v-card-text>
                </v-col>

                <v-col sm="5">
                    <v-autocomplete
                        outlined
                        :label="$t('modules.portalalumno.procedures.careerChange.dataSection.reasonType')"
                        v-model="$v.careerChange.processingsReasonType.$model"
                        :items="reasons"
                        :error="$v.careerChange.processingsReasonType.$anyError"
                        @blur="$v.careerChange.processingsReasonType.$touch()"
                        item-text="meaning"
                        hide-details
                    ></v-autocomplete>
                </v-col>

                <v-col sm="12" v-if="showField">
                    <v-textarea
                        outlined
                        :error="$v.careerChange.reason.$anyError"
                        :label="$t('modules.portalalumno.procedures.careerChange.dataSection.reasonField')"
                        :placeholder="$t('modules.portalalumno.procedures.careerChange.dataSection.reasonPlaceholder')"
                        v-model.trim="$v.careerChange.reason.$model"
                        @blur="$v.careerChange.reason.$touch()"
                        required
                        hide-details
                    ></v-textarea>
                </v-col>
            </v-row>
        </OutlinedCard>

        <div class="d-flex justify-end mb-12">
            <Button
                white
                :text="$t('actions.cancel')"
                @clicked="$emit('goBack')"
                class="mr-4"
            ></Button>
            <Button
                :loading="createButton.loading"
                :success="createButton.success"
                :error="createButton.error"
                :disabled="!canCreate"
                :text="$t('actions.next')"
                :successText="$t('actions.created')"
                :errorText="$t('actions.error')"
                @end="resetButtonValues"
                @clicked="nextStep"
            ></Button>
        </div>
    </div>
</template>

<script>
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import Button from '@/components/Button/Button';
  import { $types, $careers, $studyPlans, $setting } from '../../services';
  import { mapGetters } from 'vuex'

  export default {
    name: 'CareerChange',
    props: {
      careerChange: Object,
    },
    components: {
      OutlinedCard,
      Button
    },
    data () {
      return {
        reasons: [],
        careers: [],
        studyPlans: [],
        activePlan:'',
        createButton: {
          loading: false,
          success: false,
          error: false
        },
      }
    },
    computed: {
      computedCareerChange() {
        return { ...this.careerChange };
      },
      showField() {
        return this.careerChange.processingsReasonType === 324
      },
      canCreate() {
        return this.$permissions.portalalumno.procedures.CAREER_CHANGE && !this.$v.$invalid
      },
      ...mapGetters({
        studentCareer: 'commons/getStudentCareer',
        studentCeo: 'commons/getStudentCeo',
      }),
    },
    watch: {
      computedCareerChange: {
        async handler(val, oldVal) {
          if (val.career?.id != oldVal.career?.id) {
            try {
              const {data} = await $studyPlans.find(null, null, {params: {careerId: val.career.id, filterByStatus: true}});
              if(!this.activePlan){
                this.studyPlans = data.content.map(e => {
                  return {
                    description: `${e.description} - ${e.degree}`,
                    id: e.id
                  }
                })

              }else{
               this.careerChange.studyPlan = data.content.shift();
              }
            } catch {
              this.studyPlans = [];
            }
          }
        },
        deep: true
      }
    },
    mounted() {
      this.getReasonTypes()
      this.getCareers()
      this.clearData()
      this.getActivePlan()
    },
    methods: {
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      goBack() {
        this.$trackEvent('Tramites', 'Cancelar', 'Cambio de carrera')
        this.$router.push('/procedures')
      },
      nextStep() {
        this.$trackEvent('Tramites', 'Siguiente', 'Cambio de carrera')
        this.$emit('nextStep', this.careerChange)
      },
      async getCareers() {
        try {
          const {data} = await $careers.find(null, null, {params: {page: 0, length: 500 ,status: true }});
          this.careers = data.content.map(career => ({
            id: career.id,
            name: `${career.code} - ${career.description}`,
            description: career.description
          })).sort((a, b) => (a.description < b.description) ? -1 : 1)
        } catch (err) {
          this.careers = [];
          throw err;
        }
      },
      async getActivePlan(){
        try {
          const {data} = await $setting.find('SINGLE_STUDY_PLAN_ACTIVE')
          this.activePlan = data.value
        } catch (error) {
          this.activePlan = ''
          throw error
        }
      },
      async getReasonTypes() {
        const resp = await $types.find(null, null, { params: { type: 'UPDATE_CAREER_PROCESSING' } });
        resp.data.sort((a, b) => a.meaning < b.meaning ? -1 : 1)
        this.reasons = resp.data
      },
      clearData() {
        this.careerChange.reason = null
        this.careerChange.career = null
        this.careerChange.studyPlan = null
        this.careerChange.processingsReasonType = null
        this.careerChange.price = null
      }
    },
    validations: {
      careerChange: {
        studyPlan: {required},
        career: { required },
        processingsReasonType: { required },
        reason: {
          required: requiredIf(function() { return this.showField })
        },
      }
    },
  }
</script>

<style lang="sass" scoped>
  .info-sheet
    border-radius: 10px !important
    border: solid 1px #cecece
    font-size: 14px
</style>