<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.studentSubjects.table.breadcrumbs.title')"
                    :description="$t('modules.studentSubjects.table.breadcrumbs.description')"
                ></Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="users"
                            :permissions="{ create: canCreate }"
                            @openStudyPlan="openStudyPlan"
                            @openPractice="openPractice"
                            @searchAction="searchAction"
                        ></SuperTable>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import { $students } from '../Services';

    export default {
        name: 'StudentSubjectsTable',
        components: {
            SuperTable,
            Breadcrumbs,
        },
        data () {
            return {
                search: '18439357',
                users: [],
                headers: [
                    {
                        text: this.$t('modules.exceptions.table.headers.identification'),
                        value: 'identification',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.name'),
                        value: 'name'
                    },{
                        text: this.$t('modules.exceptions.table.headers.lastname'),
                        value: 'lastname',
                    },{
                        text: this.$t('modules.exceptions.table.headers.career'),
                        value: 'career',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.career_status'),
                        value: 'careerStatus.meaning',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.term'),
                        value: 'term',
                        sortable: false,
                        align:'center',
                    },{
                        text: '',
                        value: 'action',
                        sortable: false
                    }
                ],
            }
        },
        methods: {
            openStudyPlan(item) {
                if (this.canCreate) this.$router.push({ name: 'student_subjects_create', params: {id: item.id, career: item.career, term: item.term} })
            },
            openPractice(item){
                if (this.canCreate) this.$router.push({ name: 'enroll_practice', params: {id: item.id, career: item.career, term: item.term} })
            },
            searchAction(data) {
                this.search = data;
                this.getStudent();
            },
            async getStudent() {
                try {
                    const student = await $students.find('studentCareersByIdentification', null, {params: { identification: this.search }})
                    this.users = []
                    if (student.data.studentCareers.length) {
                        student.data.studentCareers.forEach(career => {
                            this.users.push({
                                id: career.studentCareer,
                                name: student.data.name,
                                lastname: student.data.lastname,
                                identification: student.data.identification,
                                career: career.careerName,
                                careerStatus: career.careerStatus,
                                term: career.termValidCode,
                                termId: career.termValidId,
                                exception: career.exception,
                                oldModality: career.oldModality,
                            })
                        })
                    }
                } catch (error) {
                    this.users = [];
                }
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.students.subjects.CREATE
            },
        },
    }
</script>
