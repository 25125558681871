<template>
    <v-menu
        content-class=""
        min-width="320px"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="$isPortalAlumno"
                icon
                color="primary"
                class="mr-6"
                v-bind="attrs" v-on="on"
            >
                <v-badge
                    :content="notifications.length"
                    :value="notifications.length"
                    color="red"
                    bordered
                    overlap
                >
                    <v-icon>mdi-bell</v-icon>
                </v-badge>
            </v-btn> 
        </template>

        <v-list class="pa-0" dense>
            <v-list-item class="px-6 pb-0">
                <v-list-item-content>
                    <v-list-item-title class="text-start">{{$t('components.appBar.communications.title')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <div v-if="!notifications.length" class="d-flex flex-column align-center grey lighten-5">
                <img class="mt-2" width="200" height="100" :src="require('@/assets/todotranquilo.svg')" />
                <p class="text-center text-body-1 primary--text mt-3 mb-0 font-weight-bold">{{$t('components.appBar.communications.empty_title')}}</p> 
                <p class="text-body-2 text-center mt-0">{{$t('components.appBar.communications.empty_subtitle')}}</p> 
            </div>
            
            <template v-else v-for="(notification, i) in notifications">
                <v-divider v-if="i > 0" class="ml-16-12" :key="`divider-${notification.id}`"></v-divider>
                <v-list-item class="py-2 px-4 read" :key="notification.id" @click="openNotifications(notification.id)" >
                    <v-list-item-avatar color="#f5f5f5" size="35" class="my-1">
                        <v-img :src="`data:image/jpeg;base64,${notification.avatar}`" ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="notification.name" class="font-weight-bold"></v-list-item-title>
                        <v-list-item-subtitle  v-html="notification.title"></v-list-item-subtitle>
                    </v-list-item-content>
                    <span class="time caption grey--text text--darken-2 pt-1 pr-2">{{ notification.received_at }}</span>
                </v-list-item>
            </template>

            <v-divider></v-divider>
            <v-list-item @click="openNotifications" class="px-4 pb-0">
                <v-list-item-content>
                    <v-list-item-title class="text-center text-body-2 primary--text cursor-pointer" v-text="$t('components.appBar.communications.see_all')"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import SockJS from 'sockjs-client'
    import Stomp from 'webstomp-client'
    import moment from 'moment';
    import { mapGetters } from 'vuex'
    import api from '@/api';
    const { $messages }  = api
    const { $studentmessage, $connect } = $messages

    export default {
        name: 'Notifications',
        data() {
            return {
                studentLoaded: false,
                notifications: [],
                stompClient: null,
            }
        },
        computed: {
            ...mapGetters({
                studentId: 'commons/getStudentId',
                user: 'commons/getUserData',
                notificationsKey: 'commons/getNotificationsKey',
            }),
        },
        beforeDestroy() {
            this.disconnectWS()
        },
        mounted() {
            this.getNotifications()
            this.connectWS()
        },
        methods: {
            openNotifications(id = null) {
                if (this.studentId) {
                    if (id) this.$router.push(`/communications?message=${id}`);
                    else this.$router.push('/communications');
                }
            },
            async getNotifications(){    
                try {
                    if (this.studentId) {
                        const { data } = await $studentmessage.findById('student', this.studentId, {params: {page: 0, length: 5, read: false }})
                        this.notifications = data.content.map(notification => {
                            const days = moment().diff(notification.createdDate, 'days')
                            const minutes = moment().diff(notification.createdDate, 'minutes')
                            
                            let received_at = ''
                            if (minutes < 60) received_at = this.$t('components.appBar.communications.time.minutes', { minutes })
                            else if (days <= 0) received_at = this.$tc('components.appBar.communications.time.hours', Math.floor(minutes / 60))
                            else if (days == 1) received_at = this.$t('components.appBar.communications.time.yesterday')
                            else received_at = moment(notification.createdDate).locale('es').format('D MMM')

                            notification.received_at = received_at
                            return notification
                        })
                    } else {
                        this.notifications = []
                    }
                } catch (error) {
                    this.notifications = []
                    throw error
                }
            },
            async connectWS() {
                return false
                // eslint-disable-next-line no-unreachable
                if (!this.studentId || this.stompClient != null) return false

                // const socket = new SockJS('http://localhost:9013/test-messaging');
                // const socket = new SockJS('http://10.169.21.220:9013/test-messaging');
                // const socket = new SockJS('https://serviciosdev.teclab.edu.ar/gw/messages/test-messaging');

                const uri = await $connect.getUri()
                const socket = new SockJS(uri);
                this.stompClient = Stomp.over(socket);
                this.stompClient.connect({}, () => {
                        this.stompClient.subscribe(`/topic/newMessage/${this.studentId}`, () => {
                        this.newNotification()
                    });
                }, error => {
                        this.disconnectWS()
                    throw error
                });
            },
            async disconnectWS() {
                return false
                // eslint-disable-next-line no-unreachable
                if (this.stompClient != null) {
                    await this.stompClient.disconnect();
                    this.stompClient = null
                }
            },
            newNotification() {
                this.getNotifications()
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('components.appBar.communications.hello', { student: this.$options.filters.$capitalizeAll(this.user.name) }),
                    content: [{ value: this.$t('components.appBar.communications.new_message') }],
                    actionPrimary: { text: this.$t('actions.see'), callback: () => { this.openNotifications() } },
                    actionSecondary: { text: this.$t('actions.close'), callback () {} },
                    icon: { color: 'info', name: 'mdi-bell' },
                    color: 'primary',
                })
            },
        },
        watch:{
            studentId(id) {
                if (id) {
                    this.getNotifications()
                    this.connectWS()
                } else this.disconnectWS()
            },
            notificationsKey() {
                this.getNotifications()
            }
        }
    }
</script>

<style lang="sass" scoped>
    .time
        font-size: 0.65rem !important
        position: absolute
        top: 0
        right: 0
</style>