<template>
  <v-container>
    <v-container class="container-custom">
          <Breadcrumbs
              :title="procedureTitle"
              :description="$t('modules.academic.professionalizingPractice.update.breadcrumbs.subtitle')"
          />
          <v-stepper v-model="step" class='stepper paymentStepper mt-3 elevation-0' :alt-labels="$isMobile">
            <v-stepper-header class="stepper-header elevation-2 rounded mb-6" style="border: 1px solid gainsboro;">
              <v-stepper-step editable class="stepper-step" :complete="step > 1" :color="step > 1 ? 'green' : 'primary'" step="1">
                  {{ $t('modules.academic.professionalizingPractice.update.steppers.header.start.title') }}
              </v-stepper-step>
              <v-divider class="mx-12" />
              <v-stepper-step editable class="stepper-step" :complete="step > 2" :color="step > 2 ? 'green' : 'primary'" step="2" >
                  {{ $t('modules.academic.professionalizingPractice.update.steppers.header.managment') }}
              </v-stepper-step>
              <v-divider class="mx-12" />
              <v-stepper-step editable step="3" class="stepper-step">
                  {{ $t('modules.academic.professionalizingPractice.update.steppers.header.finish') }}
              </v-stepper-step>
            </v-stepper-header>  
            <v-expansion-panels class=" mb-6" style="border: 1px solid gainsboro;">
              <v-expansion-panel >
                <v-expansion-panel-header>
                  <div class="d-flex justify-start">
                    <v-avatar class="primary">
                      <v-icon large dark>mdi-account</v-icon>
                    </v-avatar>
                    <div class="ml-4">
                      <h1 class="text-body-1 font-weight-bold">{{ dataStudent.nombre }}, {{ dataStudent.apellido }}</h1>
                      <h2 class="text-body-2">{{$t("modules.academic.professionalizingPractice.update.steppers.header.start.drop_text_alumno")}}</h2>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <DatosStudentListGroup 
                    :datosCompany="datosCompany"
                    :dataStudent="dataStudent"
                  />
                  <PPFiles
                    :datosCompany="datosCompany" 
                    :legajo="legajo"
                    @error="error"
                    @loading="loadingSet"
                    :loading="loading"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels class=" mb-6" style="border: 1px solid gainsboro;">
              <v-expansion-panel >
                <v-expansion-panel-header>
                  <div class="d-flex justify-start">
                    <v-avatar class="primary">
                      <v-icon large dark >mdi-briefcase</v-icon>
                    </v-avatar>
                    <div class="ml-4">
                      <h1 class="text-body-1 font-weight-bold">{{ datosCompany.razonSocial }}</h1>
                      <h2 class="text-body-2">
                      {{ $t( "modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.title" ) }}
                      </h2>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <DatosCompanyListGroup 
                      :datosCompany="datosCompany"
                      :newPracticaEmpresa="newPracticaEmpresa"
                      :step="step"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-stepper-items>
              <v-stepper-content step="1" class="ma-0 pa-0">
                <StartProcedure
                  :disponibilidadHoraria="disponibilidadHoraria"
                  :newPracticaEmpresa="newPracticaEmpresa"
                  :dataStudent="dataStudent" 
                  :datosCompany="datosCompany" 
                  :dataPP="datosPP" 
                  :updatePP="updatePP"
                  @fetchData="fetchData"
                  @error="error"
                  @loading="loadingSet"
                  :loading="loading"
                  @changeStep="changeStep"
                />
              </v-stepper-content>
              <v-stepper-content step="2"  class="ma-0 pa-0">
                <StartProcedure
                  :disponibilidadHoraria="disponibilidadHoraria"
                  :newPracticaEmpresa="newPracticaEmpresa"
                  :dataStudent="dataStudent" 
                  :step="step" 
                  :datosCompany="datosCompany" 
                  :dataPP="datosPP" 
                  :updatePP="updatePP"
                  @error="error"
                  @loading="loadingSet"
                  :loading="loading"
                  @changeStep="changeStep"
                />
              </v-stepper-content>
              <v-stepper-content step="3"  class="ma-0 pa-0">
                <v-row>
                  <v-col md="12">
                    <StartProcedure
                      :disponibilidadHoraria="disponibilidadHoraria"
                      :newPracticaEmpresa="newPracticaEmpresa"
                      :dataStudent="dataStudent" 
                      :step="step" 
                      :datosCompany="datosCompany" 
                      :dataPP="datosPP" 
                      :updatePP="updatePP"
                      @error="error"
                    @loading="loadingSet"
                    :loading="loading"
                    @changeStep="changeStep"
                    /> 
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <OutlinedCard v-if="false"
            :title="$t('modules.academic.professionalizingPractice.update.observations')"
            class="mt-5"
          >
            <v-textarea
              v-model="remarks"
              outlined
              name="input-7-4"
              :disabled="!addObservation"
              :label="$t('modules.academic.professionalizingPractice.update.observations')"
            ></v-textarea>
            <Button
              :text="$t('actions.edit')"
              class="d-flex justify-end"
              @clicked="addObservation = true"
              v-if="!addObservation"
              :disabled="loading"
            />
            <div class="d-flex justify-end align-center" v-else>
              <Button
                :text="$t('actions.cancel')"
                class="d-flex justify-end"
                white
                @clicked="addObservation = false"
                :disabled="loading"
              />
              <Button
                primary
                :text="$t('actions.save')"
                class="d-flex justify-end ml-3"
                @clicked="updateObservacion"
                :disabled="loading"
              />
            </div>
          </OutlinedCard>
        </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import StartProcedure from '../Components/StartProcedure'
import Button from '@/components/Button/Button';
import { 
  $agreements, 
  $updateEstadoPracticaEmpresa,
  $updateObservacion,
} from '../Services';
import PPFiles from '../Components/Steps/PPFiles';
import DatosStudentListGroup from '../Components/Steps/DatosStudentListGroup';
import DatosCompanyListGroup from '../Components/Steps/DatosCompanyListGroup';


export default {
  name: 'ProfessionalizingPracticeUpdate',
  components: {
      Button,
      Breadcrumbs,
      StartProcedure,
      OutlinedCard,
      PPFiles,
      DatosStudentListGroup,  
      DatosCompanyListGroup,
  },
  data(){
    return {
        dataStudent: {},
        step: '1',
        datosCompany: {},
        datosPP: {},
        state: false,
        addObservation: false,
        legajo: null,
        academicRegister: null,
        remarks: '',
        newPracticaEmpresa : {},
        disponibilidadHoraria : [],
        loading: false,
    }
  },
  computed: {
      procedureTitle() {
        const user = this.datosPP.convenioId ? `${this.datosPP.convenioId}` : '';
        return this.$t('modules.academic.professionalizingPractice.update.breadcrumbs.agreement', {convenio: user});
      },  
  },
  mounted(){
      this.fetchData(this.$route.params.id)
      this.academicRegister = this.$route.params.id
  },
  methods: {
    async fetchData(legajo){
      this.legajo = legajo
        try {
            this.loadingSet(true);
            const data = await $agreements.find(legajo);
            this.dataStudent = data.data.datosPersonales;
            this.datosCompany = data.data.empresa;
            this.datosPP = data.data.practicaProfesionalizante;
            this.newPracticaEmpresa = {
                cargoReferente : data.data.empresa.cargoReferente,
                contacto       : data.data.empresa.contacto,
                cuit           : data.data.empresa.cuit,
                direccion      : data.data.empresa.direccion,
                email          : data.data.empresa.email,
                legajo         : data.data.empresa.legajo,
                razonSocial    : data.data.empresa.razonSocial,
                telefono       : data.data.empresa.telefono,
                flag           : false,
            };
            this.disponibilidadHoraria = data.data.disponibilidadHoraria;
            if(this.datosCompany.estadoPPId == 267){
              this.step = '1';
            } else if(this.datosCompany.estadoPPId == 268 || this.datosCompany.estadoPPId == 279){
              this.step = '2';
            } else if(this.datosCompany.estadoPPId == 269){
              this.step = '3';
            }
        } catch(err){
            this.error();
            throw err;
        } finally {
          this.loadingSet(false);
        }
    },
    async updatePP(value){
      try {
        this.loadingSet(true);
        const params = {
          legajo: `${this.academicRegister}`, 
          estadoId: value, 
          convenioId: this.datosPP.convenioId,
          validation: this.datosCompany.validacion,
        }
        const res = await $updateEstadoPracticaEmpresa.create(null, { params });
        this.datosCompany.estadoPPId = value;
        switch (value) {
          case 267:
          this.step = '1';
              break;
          case 268: {
            this.step = '2';
            if (!this.datosCompany.validacion) {
              this.datosCompany.empresaContinuaProceso = true;
              this.datosCompany.empresaCumpleRequisitos = true;
            }
            break;
          }
          case 269:
          this.step = '3';
              break;
          default:
            this.step = '1';
              break;
        }
        return res;
      } catch(err){
        this.error();
        throw err;
      } finally {
        this.loadingSet(false);
      }
    },
    async updateObservacion(){
      try {
        this.loadingSet(true);
        const res = await $updateObservacion.create(null, { params: { legajo: `${this.legajo}`, stepId: this.datosCompany.estadoPPId, observacion: this.remarks } });
        return res;
      }catch(err){
        this.error();
        throw err;
      } finally {
        this.loadingSet(false);
      }
    },
    error(){
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('responseCodeMeaning.E216'),
        actionPrimary: {text: this.$t('actions.return'), callback() {}},
        actionSecondary: null,
        icon: {color: 'warning', name: 'mdi-close'},
        color: 'error',
      });
    },
    loadingSet(value){
      this.loading = value;
    },
    changeStep(value){
      this.step = value;
    }
  },
}
</script>
