<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.admission.preregistration.create.breadcrumbs.title')"
        :description="$t('modules.admission.preregistration.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <div :class="showEmailSummary ? 'd-none' : 'd-flex'">
            <div class="col-8 pl-0 pt-0">
              <div ref="preregistrationScroll" class="preregistration-scroll">
                <PreregistrationInfo
                  ref="preregistrationInfo"
                  :careers="careers"
                  :saleTerms="saleTerms"
                  :student-data-update="studentDataUpdate"
                  @setStudentData="(changedStudentData) => { this.preregStudentData =  changedStudentData}"
                  @validationError="updatePreregistrationError"
                  @validationSuccess="updatePreregistration"
                ></PreregistrationInfo>
                <div class="d-flex justify-end pa-3">
                  <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="$router.replace('/admission/preregistration')"
                    @end="resetButtonValues"
                    class="mr-4"
                  ></Button>
                  <Button
                    :loading="updateButton.loading"
                    :success="updateButton.success"
                    :error="updateButton.error"
                    :text="$t('actions.save')"
                    :successText="$t('actions.saved')"
                    :errorText="$t('actions.error')"
                    :disabled="!canUpdate"
                    @end="resetButtonValues"
                    @clicked="validatePreregistration()"
                  ></Button>
                </div>
              </div>
            </div>
            <Summary
              class="col-4"
              summary-update
              is-created
              :total="total"
              :admission-term="admissionTerm"
              :payment-link-button="paymentLinkButton"
              @showEmailSummary="() => { this.showEmailSummary = true }"
              @sendPaymentLink="sendPaymentLink"
            ></Summary>
          </div>
          <EmailSummary
            v-if="showEmailSummary"
            :prepaids="prepaids"
            :dues="dues"
            :total="total"
            :city="preregStudentData.city"
            :prereg-student-data="preregStudentData"
            @showForm="showForm"
          ></EmailSummary>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import PreregistrationInfo from '../Components/PreregistrationInfo';
import Summary from '../Components/Summary';
import EmailSummary from '../Components/EmailSummary';
import {$careers, $preRegister, $terms, $pagoAutogestionado, $prepaids, $studyPlans} from '../Services';
import {mapGetters} from 'vuex';

export default {
  name: 'PreregistrationUpdate',
  components: {
    Breadcrumbs,
    Button,
    PreregistrationInfo,
    Summary,
    EmailSummary
  },
  data() {
    return {
      studentDataUpdate: null,
      studentPreregistration: null,
      isBianualArancel: false,
      arancelAmount: null,
      preregStudentData: null,
      careers: [],
      saleTerms: [],
      total: {
        prices: [],
        savings: [],
        cost: 0,
        saving: 0,
      },
      dues: null,
      prepaids: [],
      admissionTerm: null,
      showEmailSummary: false,
      paymentLinkButton: {
        loading: false,
        success: false,
        error: false
      },
      updateButton: {
        loading: false,
        success: false,
        error: false
      }
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.UPDATE;
    },
    ...mapGetters({
      userId: 'commons/getUserID',
      userData: 'commons/getUserData'
    }),
  },
  mounted() {
    this.getCareers()
    this.getSaleTerms()
    if (!this.$isTeclab) {
      this.getPrepaids()
    }
    this.getPreRegister()
  },
  methods: {
    async getCareers() {
      try {
        const {data} = await $careers.find(null, null, { params: { page: 0, length: 500, status: true } })
        this.careers = data.content
      } catch (err) {
        this.careers = []
        throw err
      }
    },
    async getSaleTerms() {
      try {
        const {data} = await $terms.find('current-sales')
        this.saleTerms = data.content
      } catch (err) {
        this.saleTerms = []
        throw err
      }
    },
    async getPrepaids() {
      try {
        // with cityId
        /*const {data: prepaids} = await $prepaids.find(null, null, { params: { termId: this.preregStudentData.selectedSaleTerm.id, careerId: this.selectedCareer.id, cityId: this.city.idCity || this.city.idRegion, studentType: this.studentType, average: this.average }})*/
        // with ceoId
        const {data} = await $prepaids.find(null, null, { params: { termId: this.preregStudentData.selectedSaleTerm.id, careerId: this.selectedCareer.id, ceoId: 546, studentType: this.studentType, average: this.average }})
        this.prepaids = data.sort((a, b) => (a.dayFrom > b.dayFrom) ? 1 : -1)
      } catch (err) {
        this.prepaids = []
        throw err
      }
    },
    async getPreRegister() {
      try {
        const {data} = await $preRegister.findById(this.$route.params.id)
        this.studentPreregistration = data.studentPreregistation
        this.admissionTerm = data.term.code

        let changeOneArancelFlag = false
        if (data.studentPreregistation?.filter(preregistration => preregistration.billingProduct.id == this.$sisConstants('modules.products.arancel')).length > 1) {
          this.isBianualArancel = true
          changeOneArancelFlag = true
        }

        data.studentPreregistation?.forEach(({billingProduct, scholarship, benefit, discount, amount: preregistrationAmount}, index) => {
          const productAmount = data.studentPreregistation.reduce((acc, product) => {
            if (product.billingProduct.id == billingProduct.id) {
              acc += product.amount
            }
            return acc
          }, 0)

          this.total.prices.push({
            id: billingProduct.id,
            key: `${billingProduct.name} ${index}`,
            name: billingProduct.name,
            amount: preregistrationAmount
          })
          this.total.cost += parseFloat(preregistrationAmount)

          let percentage = 0
          let amount = 0
          let discountId = null
          let benefitId = null
          let scholarshipId = null
          const details = []

          const bianual = billingProduct.id == this.$sisConstants('modules.products.matricula') || data.studentPreregistation.filter(preregistration => preregistration.billingProduct.id == this.$sisConstants('modules.products.arancel')).length > 1
          const discountDetails = discount?.discountDetails
          if (discountDetails) {
            discountId = discount.id
            const discountPercentage = bianual ? discountDetails.bianualPercentage : discountDetails.bimesterPercentage
            const discountAmount = (bianual ? discountDetails.bianualAmount : discountDetails.bimesterAmount) || discountPercentage * (productAmount - (this.$isTeclab ? 0 : amount)) / 100
            details.push({
              name: `${this.$t('modules.admission.preregistration.update.summary.discount')} - ${billingProduct.name}`,
              type: 'DISCOUNT',
              percentage: discountPercentage,
              amount: discountAmount
            })
            percentage += parseFloat(discountPercentage || '0')
            amount += parseFloat(discountAmount)
          }

          if (benefit) {
            const benefitDetails = benefit.benefitDetail
            if (benefitDetails) {
              benefitId = benefit.id
              const benefitAmount = benefitDetails.amount || benefitDetails.percentage * (productAmount - (this.$isTeclab ? 0 : amount)) / 100
              details.push({
                name: `${benefit.community.description} - ${billingProduct.name}`,
                type: 'BENEFIT',
                percentage: benefitDetails.percentage,
                amount: benefitAmount
              })
              percentage += parseFloat(benefitDetails.percentage || '0')
              amount += parseFloat(benefitAmount)
            }
          }

          //TODO: check scholarship
          if (scholarship) {
            const [scholarshipDetails] = scholarship?.scholarshipList
            if (scholarshipDetails) {
              scholarshipId = scholarship.id
              const scholarshipAmount = scholarshipDetails.amount || scholarshipDetails.percentage * productAmount / 100
              details.push({
                name: `${scholarship.description} - ${billingProduct.name}`,
                type: 'SCHOLARSHIP',
                percentage: scholarshipDetails.percentage,
                amount: scholarshipAmount
              })
              percentage += parseFloat(scholarshipDetails.percentage || '0')
              amount += parseFloat(scholarshipAmount)
            }
          }

          if (percentage > 100) {
            percentage = 100
            this.total.saving += amount - productAmount
            amount = productAmount
          }

          this.total.savings.push({
            id: billingProduct.id,
            key: `${billingProduct.name} ${index}`,
            name: billingProduct.name,
            percentage,
            amount: (this.isBianualArancel && changeOneArancelFlag && billingProduct.id == this.$sisConstants('modules.products.arancel')) ? 0 : amount,
            discountId,
            benefitId,
            scholarshipId,
            details
          })

          if (this.isBianualArancel && changeOneArancelFlag && billingProduct.id == this.$sisConstants('modules.products.arancel')) {
            changeOneArancelFlag = false
            this.arancelAmount = productAmount
          }

        })

        if (data.benefitReferred) {
          this.total.savings.push({
            key: `${data.benefitReferred.description} ${data.benefitReferred.id}`,
            name: data.benefitReferred.description,
            percentage: null,
            amount: data.benefitReferred.referredAmount,
            benefitId: data.benefitReferred.id,
            referredFriend: true
          })
        }

        this.total.saving = parseFloat(this.total.savings.reduce((acc, saving) => {
          acc -= saving.amount
          return acc
        }, 0))

        this.studentDataUpdate = data
      } catch (err) {
        this.studentDataUpdate = null
        throw err
      }
    },
    showForm() {
      this.showEmailSummary = false
      this.$el.scrollIntoView();
    },
    async sendPaymentLink() {
      try {
        this.paymentLinkButton.loading = true
        const { name, lastname } = this.userData;
        const body = {
          email: this.preregStudentData.email,
          name: this.preregStudentData.name,
          identification: this.preregStudentData.identification,
          adviser: this.$options.filters.$capitalize(`${lastname}, ${name}`)
        }
        await $pagoAutogestionado.create(body)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.successMessage.paymentLink'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: {color: 'success', name: 'mdi-check'},
          color: 'primary',
        });
        this.paymentLinkButton.success = true
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.paymentLink'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        this.paymentLinkButton.error = true
        throw err
      } finally {
        this.paymentLinkButton.loading = false
        this.paymentLinkButton.error = false
        this.paymentLinkButton.success = false
      }
    },
    validatePreregistration() {
      this.updateButton.loading = true
      this.$refs.preregistrationInfo.validateStudentData()
    },
    updatePreregistrationError() {
      this.updateButton.error = true
      this.updateButton.loading = false
    },
    async updatePreregistration() {
      try {
        const {preregStudentData} = this
        if (this.$isTeclab) {
          try {
            const {data} = await $studyPlans.find(null, null, { params: { careerId: preregStudentData.selectedCareer.id, filterByStatus: true } })
            preregStudentData.selectedStudyPlan = {
              value: data.content.pop().id
            }
          } catch {
            preregStudentData.selectedStudyPlan = null
          }
        }
        const updatePreregistration = {
          name: preregStudentData.name,
          lastname: preregStudentData.lastname,
          birthDate: preregStudentData.birthDate,
          birthPlace: preregStudentData.birthPlace,
          nationality: preregStudentData.selectedNationality,
          maritalStatus: preregStudentData.selectedMaritalStatus,
          genderId: preregStudentData.selectedGender,
          identificationType: preregStudentData.selectedIdentificationType,
          identification: preregStudentData.identification,
          serialNumber: preregStudentData.serialNumber,
          email: preregStudentData.email,
          cellphone: preregStudentData.telephone,
          phone: null,
          address: {
            street: preregStudentData.address,
            number: preregStudentData.number,
            floor: preregStudentData.floor,
            apartment: preregStudentData.apartment,
            zipCode: preregStudentData.zipCode,
            cityWithState: preregStudentData.city,
            cityId: preregStudentData.city.idCity,
          },
          institution: preregStudentData.selectedInstitution,
          levelType: preregStudentData.selectedStudentLevel,
          userId: this.userId,
          termId: preregStudentData.selectedSaleTerm.id,
          cityId: this.city?.idCity || null,
          // ceoId: !this.$isTeclab ? 546 : 1,
          careerId: preregStudentData.selectedCareer.id,
          studyPlanId: preregStudentData.selectedStudyPlan?.value || null,
          good_average: null,
          benefitToken: null,
          preregistation: this.total.savings.length ? this.total.savings.map(saving => {
            if (saving?.referredFriend) {
              return {
                installment: null,
                installmentAmount: null,
                billingProduct: null,
                amount: null,
                discount: null,
                benefit: {
                  id: saving.benefitId,
                  referred: true,
                  community: null,
                  credits: saving.amount
                },
                scholarship: null,
              }
            } else {
              const [price] = this.total.prices.filter(price => price.id == saving.id)
              const savingAmount = saving.id == this.$sisConstants('modules.products.arancel') && this.isBianualArancel ? (this.arancelAmount / 2) : saving.amount
              const amount = price.amount - savingAmount
              return {
                installment: this.dues ? (this.dues?.value || this.dues.due.installments) : 1,
                installmentAmount: this.dues ? parseFloat((amount / (this.dues?.value || this.dues.due.installments)).toFixed(2)) : parseFloat(amount.toFixed(2)),
                billingProduct:
                  {
                    id: saving.id
                  },
                amount: price.amount,
                discount: saving.discountId ? {id: saving.discountId} : null,
                benefit: saving.benefitId ? {
                  id: saving.benefitId,
                  referred: false,
                  community: {id: this.selectedBenefit?.community} || null,
                  credits: saving.details.find(detail => detail.type == 'BENEFIT')?.amount / (saving.id == this.$sisConstants('modules.products.arancel') &&  this.isBianualArancel ? 2 : 1) || 0
                } : null,
                scholarship: saving.scholarshipId ? {id: saving.scholarshipId} : null,
              }
            }
          }) : null
        }
        await $preRegister.update(this.$route.params.id, updatePreregistration, {}, 'student');
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.update.successMessage.update'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'success', name: 'mdi-check' },
          color: 'primary',
        });
        this.updateButton.success = true;
        this.updateButton.loading = false;
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.preregistration'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.updateButton.loading = false;
        this.updateButton.error = true;
        throw error;
      }
    },
    resetButtonValues() {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    }
  }
}
</script>

<style scoped>

</style>
