<template>
  <OutlinedCard
    v-if="!show"
    :title="$t('modules.admission.preregistration.create.priceSimulator.title')"
    disabled
    class="pb-4 mb-6"
  >
  </OutlinedCard>
  <OutlinedCard
    v-else
    :title="$t('modules.admission.preregistration.create.priceSimulator.title')"
    class="mb-6"
  >
    <v-row class="mt-n4">
      <v-col class="d-flex align-center justify-space-between ">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.priceSimulator.subtitles.priceList') }}
        </span>
        <v-switch
          v-if="!$isTeclab"
          class="my-auto py-0"
          :label="$t('modules.admission.preregistration.create.priceSimulator.subtitles.goodAverage')"
          v-model="average"
          @change="$emit('averageChange', average)">
        </v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6" v-for="price in prices" :key="`priceSimulator-${price.key}`">
        <SpecialCheck
          :item="price"
          @updateItem="updateSelectedPrices"
        >
        </SpecialCheck>
      </v-col>
    </v-row>
    <template v-if="discounts.length">
      <v-row class="py-2">
        <v-col class="col-12">
          <span class="text-h6">
            {{ $t('modules.admission.preregistration.create.priceSimulator.subtitles.discounts') }}
          </span>
        </v-col>
        <v-col class="col-12">
          <v-alert
            class="mb-0"
            text
            color="success"
          >
            {{ $t('modules.admission.preregistration.create.priceSimulator.alerts.discount', {date: discounts[0].to}) }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <template v-for="discount in discounts">
          <v-col class="col-6" v-for="details in discount.details" :key="`priceSimulator-${discount.code}${details.billingProduct.id}`">
            <SpecialCheck
              :item="{
                label: `${details.billingProduct.name} ${details.percentage ? `<span class='success--text'>${details.percentage}% Off</span>` : ''}`,
                amount: getSavingAmount('DISCOUNT', details.billingProduct.id),
                selected: true
              }"
              color="#BEE4E133"
              text-color="success"
              :has-checkbox="false"
            ></SpecialCheck>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col class="col-12">
        <span class="text-h6">
          {{ $t('modules.admission.preregistration.create.priceSimulator.subtitles.benefits') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-autocomplete
          :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.labels.communityCompany')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.communityCompany')"
          outlined
          clearable
          :items="communityCompanies"
          item-text="description"
          v-model="selectedCommunityCompany"
          return-object
          @change="setBenefit('Comunidad')"
        >
        </v-autocomplete>
      </v-col>
      <v-col class="col-6">
        <SpecialCheck
          :disabled="!selectedCommunityCompanyBenefit"
          :has-checkbox="false"
          :item="{
            label: selectedCommunityCompanyBenefit ? `${selectedCommunityCompanyBenefit.details[0].billingProduct.name} ${selectedCommunityCompanyBenefit.details[0].percentage ? `<span class='success--text'>${selectedCommunityCompanyBenefit.details[0].percentage}% Off</span>` : ''}`
            : '0% Off',
            amount: selectedCommunityCompanyBenefit ? getSavingAmount('BENEFIT', selectedCommunityCompanyBenefit.details[0].billingProduct.id) : 0,
            selected: !!selectedCommunityCompanyBenefit
          }"
          :color="selectedCommunityCompanyBenefit ? '#BEE4E133' : ''"
          :text-color="selectedCommunityCompanyBenefit ? 'success' : ''"
        ></SpecialCheck>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <SpecialCheck
          :disabled="!referredFriend"
          :item="{
            label: $t('modules.admission.preregistration.create.priceSimulator.benefits.labels.referredFriend'),
            amount: selectedReferredFriend ? referredFriend.benefitReferredAmount : 0,
            selected: selectedReferredFriend
          }"
          :color="selectedReferredFriend ? '#BEE4E133' : ''"
          :text-color="selectedReferredFriend ? 'success' : ''"
          @updateItem="setBenefit(selectedReferredFriend ? null : 'Amigo referido')"
        ></SpecialCheck>
      </v-col>
    </v-row>
    <v-row v-if="$isTeclab">
      <v-col class="col-6">
        <v-autocomplete
          :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.labels.referredCompany')"
          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.referredCompany')"
          outlined
          clearable
          :items="referredCompanies"
          item-text="name"
          item-value="id"
          v-model="selectedReferredCompany"
          return-object
          :menu-props="{ closeOnContentClick: true }"
          @change="setBenefit('Empresa referida')"
        >
          <template v-slot:prepend-item>
            <v-btn class="justify-space-around" text block @click="dialog = true">
              {{ 'Agregar empresa referida' }}
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="col-6">
        <SpecialCheck
          :disabled="selectedBenefit != 'Empresa referida'"
          :has-checkbox="false"
          :item="{
            label: selectedBenefit == 'Empresa referida' ? `${selectedReferredCompanyBenefit.details[0].billingProduct.name} ${selectedReferredCompanyBenefit.details[0].percentage ? `<span class='success--text'>${selectedReferredCompanyBenefit.details[0].percentage}% Off</span>` : ''}`
            : '0% Off',
            amount: selectedBenefit == 'Empresa referida' ? getSavingAmount('BENEFIT', selectedReferredCompanyBenefit.details[0].billingProduct.id) : 0,
            selected: selectedBenefit == 'Empresa referida'
          }"
          :color="selectedBenefit == 'Empresa referida' ? '#BEE4E133' : ''"
          :text-color="selectedBenefit == 'Empresa referida' ? 'success' : ''"
        ></SpecialCheck>
      </v-col>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="800px"
        >
          <OutlinedCard
            :title="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.title')"
            class="new-referred-company-card"
          >
            <v-row>
              <v-col cols="8">
                <v-text-field
                  :error-messages="($v.newReferredCompany.name.$dirty && $v.newReferredCompany.name.$invalid) ?
                  ((!$v.newReferredCompany.name.required) ? $t('modules.admission.preregistration.validations.businessNameRequired') :
                  (!$v.newReferredCompany.name.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.businessNameAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.businessNameMaxLength')) :
                  ''"
                  @blur="$v.newReferredCompany.name.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.newReferredCompany.name.$model,60, true, true)"
                  v-model="$v.newReferredCompany.name.$model"
                  :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.name')"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :error-messages="($v.newReferredCompany.cuit.$dirty && $v.newReferredCompany.cuit.$invalid) ?
                  ((!$v.newReferredCompany.cuit.required) ? $t('modules.admission.preregistration.validations.cuitRequired') :
                  (!$v.newReferredCompany.cuit.cuitFormat) ? $t('modules.admission.preregistration.validations.cuitFormat') :
                  $t('modules.admission.preregistration.validations.cuitUnique')) :
                  ''"
                  :loading="loadingCuit"
                  v-mask="'##-########-#'"
                  @blur="validateCuit"
                  v-model="$v.newReferredCompany.cuit.$model"
                  :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.cuit')"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :error-messages="($v.newReferredCompany.contactName.$dirty && $v.newReferredCompany.contactName.$invalid) ?
                  ((!$v.newReferredCompany.contactName.required) ? $t('modules.admission.preregistration.validations.contactNameRequired') :
                  (!$v.newReferredCompany.contactName.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.contactNameAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.contactNameMaxLength')) :
                  ''"
                  @blur="$v.newReferredCompany.contactName.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.newReferredCompany.contactName.$model,60, true, true)"
                  v-model="$v.newReferredCompany.contactName.$model"
                  :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.contactName')"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :error-messages="($v.newReferredCompany.position.$dirty && $v.newReferredCompany.position.$invalid) ?
                  ((!$v.newReferredCompany.position.required) ? $t('modules.admission.preregistration.validations.positionRequired') :
                  (!$v.newReferredCompany.position.alphabeticWithSpaces) ? $t('modules.admission.preregistration.validations.positionAlphabeticWithSpaces') :
                  $t('modules.admission.preregistration.validations.positionMaxLength')) :
                  ''"
                  @blur="$v.newReferredCompany.position.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.newReferredCompany.position.$model,60, true, true)"
                  v-model="$v.newReferredCompany.position.$model"
                  :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.position')"
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  :error-messages="($v.newReferredCompany.email.$dirty && $v.newReferredCompany.email.$invalid) ?
                  ((!$v.newReferredCompany.email.required) ? $t('modules.admission.preregistration.validations.emailRequired') :
                  $t('modules.admission.preregistration.validations.emailFormat')) :
                  ''"
                  @blur="$v.newReferredCompany.email.$touch()"
                  v-model="$v.newReferredCompany.email.$model"
                  ref="email"
                  :label="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.email')"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <TelephoneInput
                  :value.sync="$v.newReferredCompany.cellphone.$model"
                  :required="$v.newReferredCompany.cellphone.$dirty && $v.newReferredCompany.cellphone.$invalid"
                  :placeholder="$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.cellphone')"
                  @blur="$v.newReferredCompany.cellphone.$touch()"
                  @validate="isValidPhone"
                  ref="telephone"
                />
              </v-col>
            </v-row>
            <div class="d-flex justify-end pa-3">
              <Button
                white
                :text="$t('actions.cancel')"
                @clicked="clearReferredCompanyData"
                class="mr-4"
              ></Button>
              <Button
                :loading="createReferredCompanyButton.loading"
                :success="createReferredCompanyButton.success"
                :error="createReferredCompanyButton.error"
                :text="$t('actions.create')"
                :successText="$t('actions.created')"
                :errorText="$t('actions.error')"
                :disabled="!canCreate"
                @end="resetButtonValues"
                @clicked="createReferredCompany"
              ></Button>
            </div>
          </OutlinedCard>
        </v-dialog>
      </v-row>
    </v-row>
<!--    SCHOLARSHIP SELECTION -->
<!--    <v-divider class="my-3"></v-divider>-->
<!--    <v-row>-->
<!--      <v-col class="col-12">-->
<!--        <span class="text-h6">-->
<!--          {{ $t('modules.admission.preregistration.create.priceSimulator.subtitles.scholarships') }}-->
<!--        </span>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col class="col-6">-->
<!--        <v-autocomplete-->
<!--          :label="$t('modules.admission.preregistration.create.priceSimulator.scholarship.labels.select')"-->
<!--          :no-data-text="$t('modules.admission.preregistration.validations.selectsEmpty.scholarship')"-->
<!--          outlined-->
<!--          clearable-->
<!--          :items="scholarships"-->
<!--          item-text="description"-->
<!--          item-value="id"-->
<!--          v-model="selectedScholarship"-->
<!--          return-object-->
<!--          @change="scholarshipChange"-->
<!--        >-->
<!--        </v-autocomplete>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row v-if="selectedScholarship">-->
<!--      <v-col class="col-6" v-for="scholarship in selectedScholarship.scholarshipProduct" :key="`priceSimulator-${scholarship.id}${scholarship.billingProduct.id}`">-->
<!--        <SpecialCheck-->
<!--          :item="{-->
<!--            label: `${scholarship.billingProduct.name} ${scholarship.percentage ? `<span class='success&#45;&#45;text'>${scholarship.percentage}% Off</span>` : ''}`,-->
<!--            amount: getSavingAmount('SCHOLARSHIP', scholarship.billingProduct.id),-->
<!--            selected: true-->
<!--          }"-->
<!--          color="#BEE4E133"-->
<!--          text-color="success"-->
<!--          :has-checkbox="false"-->
<!--        ></SpecialCheck>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import SpecialCheck from '../Components/SpecialCheck';
import Button from '@/components/Button/Button';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import {email, helpers, maxLength, required} from 'vuelidate/lib/validators';
import { $referredCompanies, $communityBenefitBilling } from '../Services';

export default {
  name: 'PriceSimulator',
  components: {
    OutlinedCard,
    SpecialCheck,
    Button,
    TelephoneInput
  },
  props: {
    show: { type: Boolean, default: false },
    prices: { type: Array, default: () => [] },
    discounts: Array,
    benefits: { type: Array, default: () => [] },
    studentToken: { type: Object, default: null },
    scholarships: { type: Array, default: () => [] },
    total: Object
  },
  data() {
    return {
      average: false,
      selectedBenefit: null,
      communityId: 47,
      communityCompanies: [],
      selectedCommunityCompany: null,
      communityCompanyBenefits: [],
      selectedCommunityCompanyBenefit: null,
      referredFriendId: 10,
      referredFriend: null,
      selectedReferredFriend: false,
      referredCompanyId: 292,
      referredCompanies: [],
      selectedReferredCompany: null,
      referredCompanyBenefits: [],
      selectedReferredCompanyBenefit: null,
      newReferredCompany: {
        name: null,
        cuit: null,
        contactName: null,
        position: null,
        email: null,
        cellphone: null
      },
      telephoneValid: true,
      cuitUnique: null,
      loadingCuit: false,
      dialog: false,
      createReferredCompanyButton: {
        loading: false,
        success: false,
        error: false
      },
      selectedScholarship: null
    }
  },
  validations: {
    newReferredCompany: {
      name: {
        required,
        maxLength: maxLength(60),
        alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
      },
      cuit: {
        required,
        cuitFormat: helpers.regex('cuitFormat', /^\d{2}-\d{8}-\d$/i),
        unique() {
          return this.cuitUnique != false;
        },
      },
      contactName: {
        required,
        maxLength: maxLength(60),
        alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
      },
      position: {
        required,
        maxLength: maxLength(60),
        alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
      },
      email: {
        required,
        email
      },
      cellphone: {
        valid() {
          return this.telephoneValid
        }
      }
    },
  },
  watch: {
    benefits(benefits) {
      if (this.show) {
        this.communityCompanyBenefits = []
        this.referredFriend = null
        this.referredCompanyBenefits = []
        benefits.map(benefit => {
          switch (benefit.type.value) {
            case this.communityId:
              this.communityCompanyBenefits.push(benefit)
              break
            case this.referredFriendId:
              this.referredFriend = benefit
              break
            case this.referredCompanyId:
              this.referredCompanyBenefits.push(benefit)
              break
          }
        })
      }
    },
    studentToken(data) {
      if (data) {
        if (data?.communityId) {
          const benefit = this.benefits.find(benefit => benefit.id == data.id)
          if (benefit) {
            if (benefit.type.value == this.communityId) {
              this.selectedCommunityCompany = this.communityCompanies.find(company => company.id == data.communityId)
              this.setBenefit('Comunidad')
            }
            if (benefit.type.value == this.referredCompanyId) {
              this.selectedReferredCompany = this.referredCompanies.find(company => company.id == data.communityId)
              this.setBenefit('Empresa referida')
            }
          }
        } else {
          this.setBenefit('Amigo referido')
          this.$emit('setBenefit', null)
          this.selectedReferredCompany = null
          this.selectedReferredCompanyBenefit = null
        }
      } else {
        this.selectedBenefit = null
        this.selectedReferredFriend = false
        this.selectedCommunityCompany = null
        this.selectedCommunityCompanyBenefit = null
        this.selectedReferredCompany = null
        this.selectedReferredCompanyBenefit = null
        this.$emit('setBenefit', null)
        this.$emit('setReferredFriend', null)
      }
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.CREATE;
    },
  },
  mounted() {
    this.getReferredCompanies()
    this.getCommunities()
  },
  methods: {
    updateSelectedPrices() {
      this.$emit('selectedPrices', this.prices.filter(price => price.selected))
    },
    setBenefit(benefitType) {
      switch (benefitType) {
        case 'Comunidad': {
          this.selectedCommunityCompanyBenefit = this.communityCompanyBenefits.find(benefit => benefit.id == this.selectedCommunityCompany?.benefitId)
          if (this.selectedCommunityCompanyBenefit) {
            this.selectedCommunityCompanyBenefit.description = this.selectedCommunityCompany.description
            this.selectedCommunityCompanyBenefit.community = this.selectedCommunityCompany.id
          }
          this.selectedBenefit = this.selectedCommunityCompany ? benefitType : null
          this.selectedReferredFriend = false
          this.selectedReferredCompany = null
          this.selectedReferredCompanyBenefit = null
          this.$emit('setBenefit', this.selectedCommunityCompany ? this.selectedCommunityCompanyBenefit : null)
          this.$emit('setReferredFriend', null)
          break
        }
        case 'Empresa referida': {
          this.selectedReferredCompanyBenefit = this.referredCompanyBenefits.find(benefit => benefit.id == this.selectedReferredCompany?.benefit.id)
          if (this.selectedReferredCompanyBenefit) {
            this.selectedReferredCompanyBenefit.description = this.selectedReferredCompany.name
            this.selectedReferredCompanyBenefit.community = this.selectedReferredCompany.id
          }
          this.selectedBenefit = this.selectedReferredCompany ? benefitType : null
          this.selectedCommunityCompany = null
          this.selectedCommunityCompanyBenefit = null
          this.$emit('setBenefit', this.selectedReferredCompany ? this.selectedReferredCompanyBenefit : null)
          break
        }
        case 'Amigo referido': {
          this.selectedReferredFriend = true
          if (this.selectedBenefit == 'Comunidad') {
            this.selectedBenefit = null
            this.$emit('setBenefit', null)
            this.selectedCommunityCompany = null
            this.selectedCommunityCompanyBenefit = null
          }
          this.$emit('setReferredFriend', this.referredFriend)
          break
        }
        default: {
          this.selectedReferredFriend = false
          this.$emit('setReferredFriend', null)
        }
      }
    },
    getSavingAmount(type, savingId) {
      return this.total.savings.filter(saving => saving.id == savingId)[0]?.details.find(detail => detail.type == type)?.amount
    },
    scholarshipChange() {
      this.$emit('setScholarship', this.selectedScholarship)
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.telephone.handleInput(this.newReferredCompany.cellphone, phoneObject)
    },
    async validateCuit() {
      this.$v.newReferredCompany.cuit.$touch();
      if (this.$v.newReferredCompany.cuit.$model) {
        this.cuitUnique = null;
        this.loadingCuit = true;
        try {
          const response = await $referredCompanies.find('exists', null, {params: {cuit: this.$v.newReferredCompany.cuit.$model}});
          this.cuitUnique = !response.data.exists;
          this.loadingCuit = false;
        } catch (error) {
          this.cuitUnique = null;
          throw error;
        } finally {
          this.$v.newReferredCompany.cuit.$touch();
        }
      }
    },
    async getReferredCompanies() {
      try {
        const {data} = await $referredCompanies.find(null, null, { params: { page: 0, length: 500 } })
        this.referredCompanies = data.companies
      } catch (err) {
        this.referredCompanies = []
        throw err
      }
    },
    async getCommunities() {
      try {
        const {data} = await $communityBenefitBilling.find(null, null, { params: { page: 0, length: 500 } })
        this.communityCompanies = data.content
      } catch (err) {
        this.communityCompanies = []
        throw err
      }
    },
    async createReferredCompany() {
      try {
        this.createReferredCompanyButton.loading = true
        if (this.$v.newReferredCompany.$invalid) {
          await this.$v.newReferredCompany.$touch()
          this.$refs.telephone.handleBlur()
          this.createReferredCompanyButton.error = true;
          this.createReferredCompanyButton.loading = false;
        } else {
          await $referredCompanies.create(this.newReferredCompany);
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.admission.preregistration.create.successMessage.referredCompany'),
            actionPrimary: {
              text: this.$t('actions.close'), callback() {},
            },
            icon: {color: 'success', name: 'mdi-check'},
            color: 'primary',
          });
          this.getReferredCompanies()
          this.clearReferredCompanyData()
          this.createReferredCompanyButton.success = true
          this.createReferredCompanyButton.loading = false
        }
      } catch (err) {
        this.dialog = false
        this.referredCompanies = []
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.create.errorMessage.referredCompany'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        this.createReferredCompanyButton.loading = false
        this.createReferredCompanyButton.error = true
        throw err
      }
    },
    clearReferredCompanyData() {
      this.dialog = false
      this.newReferredCompany = {
        name: null,
        cuit: null,
        contactName: null,
        position: null,
        email: null,
        cellphone: ''
      }
      this.$v.newReferredCompany.$reset()
    },
    resetButtonValues() {
      this.createReferredCompanyButton.loading = false;
      this.createReferredCompanyButton.success = false;
      this.createReferredCompanyButton.error = false;
    },
  }
};
</script>

<style scoped lang="sass">

  .new-referred-company-card
    margin-bottom: 0 !important

  .special-check-container
    height: 46px
    display: flex
    cursor: pointer
    flex-flow: row nowrap
    justify-content: space-between
    align-content: center
    border-radius: 5px
    padding: 10px 10px
    border: 1px solid #d3d3d3 !important

</style>
