<template>
    <OutlinedCard
        :title="title"
        :switchLabel="(userData.$model.status) ? $t('modules.users.enable') : $t('modules.users.disable')"
        :switchValue="userData.$model.status"
        @switchChange="() => {this.userData.$model.status = !this.userData.$model.status; this.userData.$touch()}"
        switcher
        class="mb-6"
    >
        <v-container fluid>
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.avatar') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pb-4">
                    <AvatarUpload
                        rounded
                        :image="imagePreview"
                        :model="userData.$model.avatar"
                        @save="createImage"
                    ></AvatarUpload>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.name') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <v-text-field
                        ref="name"
                        v-model="userData.name.$model"
                        :error-messages="(userData.name.$dirty && userData.name.$anyError) ? $t('modules.users.update.sectionPersonal.validation_errors.name') : ''"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionPersonal.fields.name')"
                        @blur="userData.name.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.lastname') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <v-text-field
                        ref="lastname"
                        v-model="userData.lastname.$model"
                        :error-messages="(userData.lastname.$dirty && userData.lastname.$anyError) ? $t('modules.users.update.sectionPersonal.validation_errors.lastname') : ''"
                        outlined
                        type="text"
                        :label="$t('modules.users.update.sectionPersonal.fields.lastname')"
                        @blur="userData.lastname.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.birthDate') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-4 mb-4">
                    <BirthdayDatePicker
                        ref="birthDate"
                        @save="(date) => {userData.birthDate.$model = date}"
                        v-model="userData.birthDate.$model"
                        :label="$t('modules.users.update.sectionPersonal.fields.birthDate')">
                    </BirthdayDatePicker>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="isStudent">
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.birthPlace') }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <AutocompleteInput
                  ref="birthPlace"
                  :value.sync="userData.birthPlace.$model"
                  :required="userData.birthPlace.$invalid"
                  :errorMessages="$t('modules.users.update.sectionPersonal.validation_errors.birthPlace')"
                  :label="$t('modules.users.update.sectionPersonal.fields.birthPlace')"
                  disable-buttons
                ></AutocompleteInput>
              </v-col>
            </v-row>

            <v-row no-gutters v-if="isStudent">
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.nationality') }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-select
                  ref="nationality"
                  :error-messages="userData.nationality.$error ? $t('modules.users.update.sectionPersonal.validation_errors.nationality') : ''"
                  :label="$t('modules.users.update.sectionPersonal.fields.nationality')"
                  outlined
                  :items="nationalities"
                  item-text="demonym"
                  item-value="id"
                  v-model="userData.nationality.$model"
                  @blur="userData.nationality.$touch()"
                  return-object
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.genre') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-select
                        ref="gender"
                        v-model="userData.gender.$model"
                        :items="genderTypes"
                        :error-messages="(userData.gender.$dirty && userData.gender.$invalid) ? $t('modules.users.update.sectionPersonal.validation_errors.gender') : ''"
                        :label="$t('modules.users.update.sectionPersonal.fields.genre')"
                        @blur="userData.gender.$touch()"
                        outlined
                        return-object
                        item-text="meaning">
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.identification') }}</span>
                </v-col>
                <v-col sm="2" class="align-start pt-1 pb-3">
                    <v-select
                        ref="identificationType"
                        v-model="userData.identificationType.$model"
                        :items="identificationTypes"
                        :error-messages="(userData.identificationType.$dirty && userData.identificationType.$invalid) ? $t('modules.users.create.validation_errors.personalForm.identification') : ''"
                        :label="$t('modules.users.update.sectionPersonal.fields.identification')"
                        @blur="userData.identificationType.$touch()"
                        outlined
                        return-object
                        item-text="meaning">
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row no-gutters>
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.identificationNumber') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <IdentificationInput
                        ref="identification"
                        v-model="userData.identification.$model"
                        :loading="loadingCode"
                        @preTimer="$emit('update:codeUnique', null)"
                        @posTimer="$emit('validateCode')"
                        :label="$t('modules.users.create.personalForm.identificationNumber') + ' *'"
                        :error-messages="(userData.identification.$dirty && userData.identification.$invalid) ? (!userData.identification.unique ? $t('modules.users.create.validation_errors.personalForm.identification_unique') : $t('modules.users.create.validation_errors.personalForm.identification')) : ''"
                        @blur="userData.identification.$touch()"
                        @validateRut="(status) => {this.$emit('validateRut', status)}"
                        :idType="userData.identificationType.$model.meaning"
                    ></IdentificationInput>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="!$isTeclab">
                <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                    <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPersonal.fields.serialNumber') }}</span>
                </v-col>
                <v-col sm="4" class="align-start pt-1 pb-3">
                    <v-text-field
                        :label="$t('modules.preRegistration.form.docSerial')"
                        v-model="userData.$model.serialNumber"
                        v-mask="['XXX.XXX.XXX.XXX.XXX.XXX','XX.XXX.XXX.XXX.XXX.XXX.XXX']"
                        outlined
                    />
                </v-col>
            </v-row>
        </v-container>
    </OutlinedCard>
</template>

<style lang="sass" scoped>
    .no-shadow
        border-top: 1px solid rgba(0, 0, 0, 0.12)
        border-bottom: 1px solid rgba(0, 0, 0, 0.12)
        &:before
             box-shadow: unset !important
</style>

<script>
import BirthdayDatePicker from '@/components/BirthdayDatePicker/BirthdayDatePicker'
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import AvatarUpload from '@/components/AvatarUpload/AvatarUpload'
import { mapGetters } from 'vuex';

export default {
    name: 'UserEditPersonalForm',
    props: {
        genderTypes: Array,
        identificationTypes: Array,
        nationalities: Array,
        userData: Object,
        avatarFile: null,
        loadingCode: Boolean,
        isStudent: { type: Boolean, default: false }
    },
    components: {
        BirthdayDatePicker,
        AutocompleteInput,
        IdentificationInput,
        OutlinedCard,
        AvatarUpload,
    },
    data () {
        return {
            title: this.$t('modules.users.update.sectionPersonal.title'),
            menu: false,
        }
    },
    created() {
        this.imagePreview = this.defaultAvatar
    },
    methods: {
        createImage (file) {
            try {
                this.$emit('update:avatarFile',file);
                this.userData.$model.avatar = file;
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.setImagePreview(e.target.result);
                };
                reader.onerror = () => {
                    this.setImagePreview(this.userData.$model.originalAvatar);
                    reader.abort();
                };
                reader.readAsDataURL(this.userData.$model.avatar);
            } catch (err) {
                this.setImagePreview(this.userData.$model.originalAvatar);
            }
        },
        setImagePreview(image) {
            this.imagePreview = image;
        },
    },
    computed: {
        identificationMask() {
            if(this.userData.identificationType.$model.value === 17)
                return ['#####-X','######-X','#######-X', '########-X'];
            else
                return 'XXXXXXXXXXXXXXX';
        },
        ...mapGetters({
            defaultAvatar: 'commons/defaultAvatar',
        }),
    },
    watch: {
        userData: {
            handler(value){
                if (typeof value.$model.avatar == 'string') this.imagePreview = value.$model.avatar;
                this.identificationMask
            },
            deep: true,
        },
    },
    
}
</script>