const communicationsTranslations = {
    es: {

        breadcrumbs: {
            title: 'Comunicaciones',
        },
        time: {
            minutes: 'Hace {minutes} min.',
            hours: 'Hace {n} hora | Hace {n} horas',
            yesterday: 'Ayer',
        },
        no_message_selected: 'Seleccioná un mensaje de la lista <br> para ver el detalle',

        messagingContent:{
            containerIconTodoTranquilo:{
                title:'Todo tranquilo por acá.',
                subtitle:'No tenés nada nuevo.'
            },
        },
        messaginSearch:{
            input:{
                placeholder:'Buscar'
            },
            select:{
                label:'Todos'
            },
            filters:{
                read:'Leidos',
                noRead:'No leidos',
                all:'Todos',
            },
            icon:{
                title:'Oops!',
                subtitle:'No encontramos nada'
            }
        }
    },
    en: {
    }


}


export default communicationsTranslations