<template>
  <v-container fluid class="pa-0 fullHeight">
    <div class="container-fluid mt-0 scrollableContent">
      <div class="first-row">
        <StudentInformation v-if="student && exam.subject" :student="student" :subject="exam.subject">
        </StudentInformation>
      </div>
      <div class="second-row">
        <Questions v-if="exam.enunciados && exam.enunciados.length" :questions.sync="exam.enunciados"
          :totalQuestions="totalQuestions" :totalViewedQuestions.sync="viewed" :actualQuestion.sync="actualQuestion"
          @checkSavedAnswer="checkSavedAnswer"></Questions>
        <GraphicQuestions v-if="exam.enunciados && exam.enunciados.length" :readOnly="isExamPreview"
          :questions.sync="exam.enunciados" :actualQuestion.sync="actualQuestion" :assestmentTime="exam.tiempoRestante"
          @checkSavedAnswer="checkSavedAnswer" @exams-finish="endExam"
          @current-time="($event) => { currentTime = $event }" @time-over="endExam"></GraphicQuestions>
      </div>
      <div class="third-row">
        <Answers ref="answers" v-if="selectedQuestion" :readOnly="isExamPreview" :current-time="currentTime"
          :question.sync="selectedQuestion" :examId="exam.id" @saving-answers="($event) => { overlay = $event }"
          @next-question="goToNextQuestion">
        </Answers>
      </div>
      <v-overlay :value="overlay">
        <h2 class="loading-message">{{ $t('modules.exams.assestment.loading.message') }}</h2>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <AlertPopup :active="alert.active" :title="alert.title" :content="alert.content"
        :actionPrimary="alert.actionPrimary" :actionSecondary="alert.actionSecondary" :icon="alert.icon"
        :color="alert.color"></AlertPopup>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import Constants from '@/plugins/constants'
import { pefByCourseAndUser, getExam, getExamPreview } from '../Services'

export default {
  components: {
    AlertPopup: () => import('../Components/AlertPopup.vue'),
    StudentInformation: () => import('../Components/StudentInformation.vue'),
    GraphicQuestions: () => import('../Components/GraphicQuestions.vue'),
    Questions: () => import('../Components/Questions.vue'),
    Answers: () => import('../Components/Answers.vue'),
  },
  props: {
    examPreviewId: { type: String },
    isExamPreview: { type: Boolean, default: false }
  },
  data() {
    return {
      studentId: 0,
      student: {},
      exam: {
      },
      viewed: 0,
      actualQuestion: 0,
      selectedQuestion: null,
      overlay: true,
      index: 0,
      drawer: null,
      header: null,
      currentTime: 0,
      alert: {
        active: false,
        title: null,
        content: null,
        actionPrimary: null,
        actionSecondary: null,
        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
        color: 'primary',
      }
    }
  },
  watch: {
    actualQuestion() {
      this.index = this.exam.enunciados.findIndex((question) => question.id === this.actualQuestion);
      this.selectedQuestion = this.exam.enunciados[this.index];
    }
  },
  methods: {
    checkSavedAnswer(callback) {
      if (this.isExamPreview) return
      const question = this.exam.enunciados.find((question) => question.status.actual);
      let edited = false
      question.respuestas.forEach(res => {
        if (res.elegida != res.original) edited = true
        if (edited) return false
      })
      if (edited) {
        this.alert.title = this.$t('modules.exams.assestment.answers.unsaved_title')
        this.alert.content = this.$t('modules.exams.assestment.answers.unsaved_content')
        this.alert.actionPrimary = {
          text: this.$t('actions.save'), callback: async () => {
            this.alert.active = false
            question.respuestas.forEach(res => { res.original = res.elegida })
            await this.$refs.answers.saveAnswers(true)
            callback()
          }
        }
        this.alert.actionSecondary = {
          text: this.$t('actions.not_save'), callback: () => {
            this.alert.active = false
            question.respuestas.forEach(res => { res.elegida = res.original })
            callback()
          }
        }
        this.alert.active = true
      } else {
        callback()
      }
    },
    buildStudentFullName(student) {
      if (Object.keys(student).length ?? false) return
      const name = student.name.split(' ')
      const lastname = student.lastName.split(' ')
      return `${name[0].charAt(0).toUpperCase()}${name[0].slice(1).toLowerCase()} ${lastname[0].charAt(0).toUpperCase()}${lastname[0].slice(1).toLowerCase()}`
    },
    goToNextQuestion() {
      const answers = [...this.exam.enunciados];
      const newIndex = (this.index + 1 === this.exam.enunciados.length) ? this.index : this.index + 1;

      answers[this.index].status.actual = false;
      answers[newIndex].status.actual = true;
      answers[newIndex].status.viewed = true;

      this.actualQuestion = answers[newIndex].id;
      this.exam.enunciados = [...answers];
      this.$scrollTo(`#question-${newIndex}`, 500, { container: '#scrollable-questions', easing: 'ease-in' })
    },
    async endExam() {
      this.exam.tiempoRestante = moment.duration(this.currentTime).asMinutes();
      this.exam.horaFin = moment().format('HH:mm:ss');
      window.localStorage.setItem('exam', JSON.stringify(this.exam));
      try {
        await fetch(`${Constants.URL_BASE}/q-system/exams/${this.exam.id}/finalize`, {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0' },
        })
      } catch (error) {
        this.alert.title = error.codeMeaning
        this.alert.content = this.$t('modules.exams.assestment.popUp.errorFinishingExam.content')
        this.alert.actionPrimary = null
        this.alert.actionSecondary = { text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback: () => { this.alert.active = false } }
        this.alert.active = true
      } finally {
        this.$router.push({ name: 'practica-review', params: { student: this.student, examId: this.exam.id, subject: this.exam.subject } })
      }
    },
    setExamPreview({ exam }) {
      const respuestas = exam.answerDto.map((res, idx) => ({
        descripcion: res.description,
        media: res.multimedia,
        orden: idx + 1, ...res
      }))
      this.exam.enunciados = [
        {
          id: exam.id,
          descripcion: exam.description,
          media: exam.multimedia,
          tipoPreguntaId:exam.questionType.value,
          respuestas
        }
      ]

      this.setEnunciados({ enunciados: this.exam.enunciados })
    },
    setExam({ exam, data }) {
      this.exam = exam;
      this.exam.fecha = moment().format('YYYY-MM-DD');
      this.exam.horaInicio = moment().format('HH:mm:ss');

      this.student = data?.student;
      this.student.fullName = this.buildStudentFullName(this.student);

      this.exam.subject = data?.subject || '';

      this.setEnunciados({ enunciados: this.exam.enunciados })
    },
    setEnunciados({ enunciados }) {
      enunciados.forEach((enunciado) => {
        enunciado.status = {
          answered: false,
          viewed: enunciado.isRevisar && enunciado.isRevisar.toLowerCase() === 's',
          toReview: enunciado.isRevisar && enunciado.isRevisar.toLowerCase() === 's',
          actual: false,
        };

        enunciado.respuestas.forEach((respuesta) => {
          respuesta.original = respuesta.elegida
          if (respuesta.elegida && respuesta.elegida.toLowerCase() === 's') {
            enunciado.status.answered = true;
            enunciado.status.viewed = true;
          }
        });
      });
    }
  },
  computed: {
    totalQuestions() {
      return this.exam.enunciados ? this.exam.enunciados.length : 0;
    }
  },
  async mounted() {
    try {
      if (this.isExamPreview) {
        const { exam } = await getExamPreview({ id: this.examPreviewId })
        this.setExamPreview({ exam })
      } else {
        const { courseId, userId } = this.$route.params
        const { data } = await pefByCourseAndUser({ courseId, userId })
        const { exam } = await getExam({ examId: data.examId, studentId: data.student.id})
        this.setExam({ exam, data })
      }
      // TODO: (Diego) remove the next line
    } catch (error) {
      this.alert.title = error.codeMeaning
      this.alert.content = ''
      this.alert.actionPrimary = null
      this.alert.actionSecondary = { text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback() { window.close() } }
      this.alert.active = true
      throw error
    } finally {
      this.overlay = false;
    }
  }
}
</script>

<style lang="sass" scoped>
    .scrollableContent
        height: 100vh

    .container-fluid
        display: flex
        flex-direction: column
        margin-top: 3rem
        padding: 1rem

        .first-row
            display: flex
            flex-direction: column
        .second-row
            display: flex
            flex-direction: row
            padding: .5rem 0

            div:first-child
                width: 60%

            div:last-child
                width: 40%

        .loading-message
            position: relative
            left: -4rem
</style>
