import bannersTranslations from './Banners/Translations';
import templateTranslations from './Template/Translations/index'

const messagingTranslations = {
    es_CL: {
        template: { ...templateTranslations.es_CL },
    },
    es: {
        banners: { ...bannersTranslations.es },
        template: { ...templateTranslations.es },
    },
    en: {
        banners: { ...bannersTranslations.en },
        template: { ...templateTranslations.en },
    }
};

export default messagingTranslations
