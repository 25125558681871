<template>
    <div>
        <PaymentsSingleCardView
          ref="debitCard"
          v-if= "true"
          :resumen="resumen"
          :unselectedReenrollmentsAccountIds="unselectedReenrollmentsAccountIds"
          :summaryButtons="summaryButtons"
          :selectedMethod="selectedMethod"
          hide-installments
          @goBackPayment="$emit('goBackPayment')"
          @changeStep="(status) => { $emit('changeStep', status) }"
        ></PaymentsSingleCardView>
        <v-container v-else fluid class="d-flex pa-0 fullHeight">
            <v-container fluid class="px-0">
                <div class="px-md-10">
                    <h4 class="primary--text mt-0">
                        <v-btn icon class="btn-back" @click="$emit('goBackPayment')"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingTitle') }}
                    </h4>


                    <div class="mt-6">
                        <PaymentBillingData
                            ref="billingData"
                            :title="false"
                            @update="(data) => { billingData = data }"
                            @validate="(val) => { billingDataValid = val }"
                        ></PaymentBillingData>
                    </div>
                </div>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import PaymentsSingleCardView from './PaymentsSingleCardView';
    import PaymentBillingData from '../Components/PaymentBillingData/PaymentBillingData'
    import { $payments } from '../Services';
    import { mapGetters } from 'vuex'
    import commons from '../Mixins/commons';
 

    export default {
        name: 'PaymentsDebitView',
        mixins: [ commons ],
        props: {
            resumen: Object,
            selectedMethod: Object,
            summaryButtons: Object,
            unselectedReenrollmentsAccountIds: { type: Array, default() { return [] } }
        },
        components: {
            PaymentsSingleCardView,
            PaymentBillingData
        },
        data() {
            return {
                billingDataValid: false,
                billingData: null,
                secondAmount: null,
                firstAmount: null,
            };
        },
        computed: {
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserData: 'commons/getStudentUserData',
                loggedUsername: 'commons/getUserName',
            })
        },
        methods: {
            async redirectPay() {
                this.summaryButtons.primary.loading = true
                
                // await this.$refs.billingData.save()
                const nameArray = this.billingData.name.split(' ');

                let desc = '';
                const conceptos = this.resumen.items.subtotal.items;

                conceptos.forEach((concepto,index) => {
                    if(index === 0) desc = concepto.title;
                    else desc += ` - ${concepto.title}`
                });

                this.firstAmount= this.$isTeclab ? this.resumen.total.toFixed(2) : this.resumen.total.toFixed(0)
                this.firstAmount = this.$encrypter(this.firstAmount);
          
                if(this.secondAmount!==null){this.secondAmount = this.$encrypter(parseFloat(this.secondAmount).toFixed(2));}
    
                const paymentData = {
                    legajo: this.studentRegister,
                    amount: this.firstAmount,
                    description: desc,
                    payment_method_id: 'webpay',
                    token: '',
                    installments: 1,
                    payer_email: this.studentUserData.email,
                    issuer_id: null,
                    usr_portal: this.loggedUsername,
                    discount_intent: this.resumen.paymentIntent,
                    intencion_de_pago_id: this.$paymentsIdToArray(this.resumen.items.subtotal.items),
                    user_type: 'STUDENT',
                    firstName: nameArray[0],
                    lastName: nameArray[nameArray.length - 1],
                    secondAmount: this.secondAmount,
                    idsPending: this.unselectedReenrollmentsAccountIds
                }

                try {
                    const response = await $payments.create(paymentData)
                    const win = window.open(response.data.external_resource_url, '_blank');
                    win.focus();
                    this.summaryButtons.primary.success = true
                    this.$store.commit('commons/SET_SIGNED_USERDATA', true)
                    this.$emit('changeStep', 'debitOk');
                } catch (error) {
                    this.summaryButtons.primary.error = true
                    this.$emit('changeStep', 'debitError');
                    throw error
                } finally {
                    this.summaryButtons.primary.loading = false
                }
            },
        },
        watch: {
            billingDataValid(value) {
                this.summaryButtons.primary.disabled = !value
            }
        }
    };
</script>
