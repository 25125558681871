<template>
  <div>
    <div class="my-3" v-if="title && title != ''">
      <h4 class="title font-weight-bold">{{ title }}</h4>
      <h4 class="body-2">{{ subtitle }}</h4>
    </div>
    <div
      class="d-flex flex-column drop-container"
      :class="{'is-dragging': dragover}"
      align="center"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      @drop.prevent="drop"
    >
      <v-file-input
        class="d-none"
        :multiple="multiple"
        :name="`fields[${customId}][]`"
        @change="onChange"
        :accept="extension"
        hide-input
        :id="customId"
        prepend-icon
        ref="file"
        :value="value"
      ></v-file-input>
      <h2 class="mt-8 title text-center" v-if="!files.length">Arrastra o haz clic para agregar un archivo</h2>
      <div v-else class="mt-4">
        <div class="px-8 py-2 file d-flex align-center" v-for="(file, index) in $v.files.$each.$iter" :key="`file-${index}`">
          <v-icon class="mr-3" color="primary" v-if="getFileImage(file.$model.file) == 'image'">mdi-image</v-icon>
          <v-icon class="mr-3" color="primary" v-else-if="getFileImage(file.$model.file) == 'excel'">mdi-file-excel-outline</v-icon>
          <v-icon class="mr-3" color="primary" v-else>mdi-file-document-outline</v-icon>
          <h2 class="subtitle-1 mr-4 text-center overflow-hidden" :style="hasTypeValidation ? 'flex: 1' : ''">{{ truncateText(file.$model.file.name, hasTypeValidation ? truncateTextLength / 2 : truncateTextLength) }}</h2>
          <v-autocomplete
            class="mx-4"
            v-if="hasTypeValidation"
            v-model="file.docType.$model"
            :items="docTypes"
            label="Tipo de documento"
            :error-messages="file.docType.$dirty && file.docType.$invalid ? 'Error' : ''"
            @blur="file.docType.$touch()"
            item-value="id"
            item-text="name"
            hide-details
            return-object
            outlined
            style="max-width: 200px"
          ></v-autocomplete>
          <button class="ml-2" type="button" @click="remove(index)" title="Eliminar archivo"><v-icon>mdi-delete</v-icon></button>
        </div>
      </div>
      <v-spacer></v-spacer>
      <template v-if="multiple || !files.length">
        <Button
          class="my-5"
          outlined
          text="Cargar archivo"
          icon="mdi-upload"
          @clicked="onPickFile"
        ></Button>
        <h5 class="caption text-center mb-2">Tamaño maximo de archivo: {{ limitSize }}</h5>
      </template>
    </div>
    <template v-if="required || limitSizeError || typeFileError">
      <v-alert class="mb-0 mt-4" text type="error">{{ errorMessage }}</v-alert>
      <v-progress-linear color="red" :value="validationPercentage"></v-progress-linear>
    </template>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import Button from '@/components/Button/Button';

export default {
  name: 'DropFiles',
  components: {
    Button
  },
  props: {
    value: { type: [File, Array] },
    title: { type: String },
    subtitle: { type: String },
    extension: { type: String },
    typeFile: { type: [ String, Array ] },
    limitSizeBytes: { type: String },
    size: { type: String, default: 'lg' },
    required: { type: Boolean, default: false },
    docTypes: { type: Array, default() { return [] } },
    multiple: { type: Boolean, default: false },
    truncateTextLength: { type: Number, default: 100 }
  },
  data() {
    return {
      customId: `fileId-${(new Date()).getTime()}`,
      files: [],
      limitSizeError: false,
      typeFileError: false,
      dragover: false,
      fileTypes: [],
      validationTimeout: null,
      validationPercentage: 0,
    }
  },
  computed: {
    hasTypeValidation() {
      return this.docTypes.length > 1
    },
    limitSize() {
      if (this.limitSizeBytes.length > 6) return `${this.limitSizeBytes.slice(0, -6)} MB`
      else return `${this.limitSizeBytes.slice(0, -3)} KB`
    },
    errorMessage() {
      if (this.limitSizeError) return 'Tamaño máximo excedido'
      if (this.typeFileError) return 'Tipo de archivo inválido'
      if (this.required) return 'Debe seleccionar un archivo'
      return ''
    }
  },
  mounted() {
    if (this.multiple) {
        if (Array.isArray(this.value)) this.files = this.value
        else this.files = []
    } else {
        if (this.value instanceof File) this.files.push(this.value)
        else this.files = []
    }
    if (this.typeFile) {
      if (typeof this.typeFile == 'string') this.fileTypes = [ this.typeFile ]
      else this.fileTypes = this.typeFile

      let excelFound = false
      this.fileTypes = this.fileTypes.map(type => {
        if (type == 'image') return 'image/*'
        else if (type == 'excel') {
          excelFound = true
          return 'application/vnd.ms-excel'
        } else if (type == 'pdf') return 'application/pdf'
        else return type
      })

      if (excelFound) this.fileTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    }
  },
  methods: {
    getFileImage(file) {
      if (file.type.match('image/*')) return 'image'
      else if (file.type.match('application/vnd.ms-excel') ||
        file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) return 'excel'
      else return 'file'
    },
    onPickFile() {
      document.querySelector(`#${this.customId}`).click();
    },
    truncateText (str, truncateLength) {
      if (str.length < Number(truncateLength)) return str
      const charsKeepOneSide = Math.floor((Number(truncateLength) - 1) / 2)
      return `${str.slice(0, charsKeepOneSide)}…${str.slice(str.length - charsKeepOneSide)}`
    },
    async onChange(e) {
      const fileArray = e?.[0] ? e : [e]
      this.limitSizeError = false
      this.typeFileError = false

      if (fileArray.length) {
        if (this.multiple || !this.files.length) {
          for (let i = 0; i < fileArray.length; i++) {
            const file = fileArray[i];
            const exists = this.files.find(f => f.file.name == file.name)

            if (!exists) {
              const isValid = await this.validFile(file)
              const docType = this.docTypes && this.docTypes.length == 1 ? this.docTypes[0] : null
              if (isValid) this.files.push({ docType, file })
              else {
                clearTimeout(this.validationTimeout);
                this.validationPercentage = 0
                this.validationTimeout = setTimeout(() => {
                  this.updateValidationProgress()
                  clearTimeout(this.validationTimeout);
                }, 1000)
              }
            }
          }
        }
      }
    },
    updateValidationProgress() {
      setTimeout(() => {
        this.validationPercentage += 0.5
        if (this.validationPercentage <= 110 && (this.limitSizeError || this.typeFileError)) this.updateValidationProgress()
        else {
          this.limitSizeError = false
          this.typeFileError = false
          this.validationPercentage = 0
        }
      }, 25)
    },
    validFile(file) {
      if (this.limitSizeBytes >= file.size) {
        const match = this.fileTypes.find(t => file.type.match(t))
        if (!match) {
          this.typeFileError = true
          return false
        }

        return true
      } else {
        this.limitSizeError = true
        return false
      }
    },
    remove(index) {
      this.files.splice(index, 1);
    },
    drop(event) {
      event.preventDefault();
      this.onChange(event.dataTransfer.files);
      this.dragover = false
    },
    triggerOuterValidation() {
      this.$v.$touch()
    }
  },
  watch: {
    files: {
      handler(files) {
        if (files) {
          if (!files.length) this.$emit('input', this.multiple ? files : null)
          else if (!this.docTypes.length) this.$emit('input', this.multiple ? files.map(f => f.file) : files[0].file)
          else this.$emit('input', this.multiple ? files : files[0].file)
        }
      }, deep: true
    }
  },
  validations: {
    files: {
      $each: {
        docType: {
          required: requiredIf(function() { return this.hasTypeValidation })
        },
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.drop-container
  width: 100%
  min-height: 200px
  border-radius: 5px
  border: #BCC4CB dashed 2px

  &.is-dragging
    background-color: rgba(61, 72, 56, 0.08)
</style>
