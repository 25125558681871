const studentSubjectsTranslations = {
    es_CL: {
        table: {
            actions: {
                enroll_subjects: 'Inscribir a asignatura',
            },
            breadcrumbs: {
                headers: {
                    identification: 'RUT',
            }
            }
        },
        error_message:{
            requiredIdentification: 'Ingrese un RUT',
        },
        enrollment:{
            labels: {
                rut: 'RUT',
            }
        },
        create: {
            textField:{
                subject: 'Asignatura',
                identification: 'RUT ',
            },
            subject: 'Asignatura',
            codeSection: {
                title: 'Datos de la práctica profesional',
                subtitle: 'Asigna la información básica de la práctica profesional que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                },
            error_message:{
                    requiredIdentification: 'Ingrese un RUT',
                },
        },
    },
    es_PE: {
        create: {
            textField:{
                city: 'Distrito '
            },
            error_message:{
                requiredSubjects:'Ingrese un curso',
                requiredCityWithState: 'Ingrese el distrito',
                subjectNull: 'El estudiante ya aprobó los cursos o los tiene en curso'
            }
        },
        textField:{
            subject: 'Curso',
        },
        table: {
            breadcrumbs: {
                description: 'Gestión de cursos'
            },
            actions: {
                debt_inscription: 'Habilitar inscripción a cursos con deuda',
                enroll_subjects: 'Inscribir a curso',
                homologar: 'Homologar cursos',
                convalidar: 'Convalidar cursos',
            } 
        },
        enrollment: {
            breadcrumbs: {
                title: 'Inscripción a cursos',
            },
            success_message: {
                title: 'Cursos homologados correctamente'
            },
            error_message: 'Ocurrió un error homologando los cursos'
        }
    },
    es: {
        create: {
        textField:{
            Namebusiness: 'Nombre de la empresa ',
            address: 'Domicilio ',
            identification: 'CUIT ',
            email: 'Correo referente ',
            term: 'Periodo ',
            city: 'Ciudad ',
            subject: 'Materia',
            cellphone: 'Tel.',
            contactName: 'Referente',
            header_subject: 'Cursando',
            },
            error_message:{
                requiredTerms:'Ingrese un periodo',
                requiredSubjects:'Ingrese una asignatura',
                requiredIdentification: 'Ingrese un CUIT',
                requiredBusinessName: 'Ingrese el nombre',
                requiredAddress: 'Ingrese la dirección',
                requiredCityWithState: 'Ingrese la ciudad',
                requiredContactName: 'Ingrese el nombre del referente',
                requiredEmail: 'Ingrese un email',
                cuitFormat: 'Formato de CUIT inválido',
                cuitUnique: 'CUIT ya existente',
                businessNameAlphabeticWithSpaces: 'Solo alfanuméricos',
                businessNameMaxLength: 'Máximo 60 caracteres',
                cityWithStateRequired: 'Ingrese la ',
                identificationUnique: 'Identificación ya existente',
                subjectNull: 'El alumno ya aprobó las asignaturas o las tiene en curso'
            },
        successMessage: {
            title: 'La inscripción se realizó correctamente',
            },
            errorMessage: {
            title: 'Ocurrió un error al inscribirse la práctica profesional',
            },
        
        codeSection: {
            title: 'Datos de la práctica profesional',
            subtitle: 'Asigna la información básica de la práctica profesional que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
            }
            },
        table: {
            breadcrumbs: {
                title: 'Inscripción por excepción',
                description: 'Gestión de asignaturas'
            },
            headers: {
                identification: 'DNI',
                name: 'Nombre',
                lastname: 'Apellido',
                career: 'Carrera',
                career_status: 'Estado de carrera',
                term: 'Periodo',
            },
            actions: {
                success: 'La excepción se realizó correctamente',
                error: 'Ocurrió un error al realizar la excepción',
                confirmation_title: '¿Desea confirmar la excepción para el siguiente estudiante?',
                debt_inscription: 'Habilitar inscripción a asignaturas con deuda',
                confirm_preregistration: 'Firma de contrato presencial',
                revert_enrollment: 'Revertir rematricula',
                change_modality: 'Cambiar a modalidad 2019',
                enroll_subjects: 'Inscribir a materia',
                enroll_practice: 'Inscribir a practica',
                homologar: 'Homologar asignaturas',
                convalidar: 'Convalidar asignaturas',
            }
        },
        enrollment: {
            breadcrumbs: {
                title: 'Inscripción a asignaturas',
                titlePractice: 'Inscripción a práctica profesional',
                description: 'Gestión de inscripciones'
            },
            labels: {
                student: 'Estudiante',
                rut: 'DNI',
                career: 'Carrera',
                studyPlan: 'Plan de estudio',
                term: 'Periodo',
            },
            success_message: {
                title: 'Asignaturas homologadas correctamente',
                actions: {
                    back: 'Volver'
                }
            },
            error_message: 'Ocurrió un error homologando las asignaturas',
        }
    },
    en: {

    }
};

export default studentSubjectsTranslations