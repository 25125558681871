<template>
  <SignInForm
    @logIn="logIn"
    :logoPath="logoPath"
    :loading="loading"
    :errorAuth="errorAuth"
  ></SignInForm>
</template>

<script>
import redirects from '@/constants/redirects';
import SignInForm from '../Components/SignInForm/SignInForm'
import Configuration from '@/constants/configuration'

export default {
  components: {
    SignInForm
  },
  props: {
    logoPath: String
  },
  data () {
    return {
      loading: false,
      errorAuth: false,
      serverError: false,
      msgWelcome: '¡Hola!'
    }
  },
  mounted() {
    console.log(Configuration.value('env'))  // eslint-disable-line
  },
  methods: {
    logIn (data) {
      this.errorAuth = false;
      this.serverError = false;
      this.loading = true;
      this.$auth.login(data.email, data.pass, data.advisor)
        .then(() => {
          this.loading = false;
          window.localStorage.setItem('alumnoSeleccionado', null);
          if (!this.$route.query.redirect) this.$router.push(redirects.URL_LOGIN_REDIRECT)
          else this.$router.push(this.$route.query.redirect);
        }).catch((err) => {
          if (err === 'invalid_grant') this.errorAuth = true;
          else this.serverError = true;
          this.loading = false;
        })
    }
  }
}
</script>
