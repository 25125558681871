<template>
    <v-container fluid class="d-flex pa-0 ">
        <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
        <v-container fluid class=" pa-0">
            <v-container class="container-custom px-6">
            <Breadcrumbs
                :title="$t('modules.academic.professionalizingPractice.breadcrumbs.title')"
                :description="$t('modules.academic.professionalizingPractice.breadcrumbs.description')"
                :callback="goBack"
            >
            </Breadcrumbs>
            <v-row>
                    <v-row>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.professionalizingPractice.cards_data.qualified_students')" :data_procedures="qualified_students" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.professionalizingPractice.cards_data.initialized_procedures')" :data_procedures="initialized_procedures" />
                        </v-col>
                        <v-col sm="3" class="px-0">
                            <PanelDataTable :data_text="$t('modules.academic.professionalizingPractice.cards_data.management_procedures')" :data_procedures="management_procedures" />
                        </v-col>
                        <v-col sm="3" class="px-0">  
                            <PanelDataTable :data_text="$t('modules.academic.professionalizingPractice.cards_data.completed_procedure')" :data_procedures="completed_procedure" />
                        </v-col>
                    </v-row>
            </v-row>
            <v-col class="viewSpaces">
                <SuperTable
                    @search="searchAction"
                    :headers="headers" 
                    :items="dataStudentPP"
                    @update="(id)=>{updateProcedure(id)}"
                    :loading="loading"
                    :page-count="pagination.pagesAmount"
                    :page="pagination.page"
                    :sortBy.sync="sort.sortBy"
                    :sortDesc.sync="sort.sortDesc"
                    :totalItems="totalItems"
                    @input-pagination="onInputPagination"
                    @length-pagination-change="onLengthPaginationChange"
                />
            </v-col>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import PanelDataTable from '../Components/PanelDataTable.vue'
import { $summary, $status, $filter, $professionalizingPractices,$types} from '../Services'
import SuperTable from '../Components/SuperTable'
import AsideBar from '@/components/AsideBar/AsideBar'

export default {
    name: 'ProfessionalizingPracticeView',
    components: { 
        Breadcrumbs,
        PanelDataTable,
        SuperTable,
        AsideBar,
    },
    data() {
        return {
            amount: null,
            percentage: null,
            professionalizingPractice: null,
            completed_procedure: {},
            initialized_procedures: {},
            management_procedures: {},
            qualified_students: {},
            procedureArray: [],
            termId: null,
            statusProcedure: null,
            showTable: false,
            identificationStudent: null,
            dataStudentPP: [],
            headers: [
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.student'),
                    value: 'completeName'
                },
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.dni'),
                    value: 'identification'
                },
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.place'),
                    value: 'businessName'
                },
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.locality'),
                    value: 'cityName'
                },
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.career'),
                    value: 'subjectDescription'
                },
                {
                    text: this.$t('modules.academic.professionalizingPractice.table.headers.status'),
                    value: 'statePPId'
                },
                {
                    text: '',
                    sortable: false,
                    value: 'action'
                }
            ],
            asidebar: {
                title: this.$t('modules.academic.professionalizingPractice.filters.title'),
                items: [
                    {
                        name: this.$t('modules.academic.professionalizingPractice.filters.term'),
                        filters: [
                            {
                                name: this.$t('modules.academic.professionalizingPractice.filters.sub1'),
                                type: 'autocomplete',
                                value: null,
                                clearable: true,
                                items: [],
                            }
                        ]
                    },{
                        name: this.$t('modules.academic.professionalizingPractice.filters.condition'),
                        filters: [
                            {
                                name: this.$t('modules.academic.professionalizingPractice.filters.sub2'),
                                type: 'autocomplete',
                                value: null,
                                clearable: true,
                                items: [],
                            },
                        ]
                    }
                ]
            },
            loading: false,
            sort: {
				sortBy: 'statePPId',
				sortDesc: true,
			},
			pagination: {
				limit: 20,
				page: 1,
				pagesAmount: 1,
			},
			totalItems: null,
        }
    },
    async mounted(){
        await this.getSummary();
        await this.getFilterTerms();
        await this.getFilterStatus();
        await this.getDataPP();
    },
    methods: {
        searchAction(data) {
            this.pagination.page = 1;
            this.getDataPP(data);
        },
        async getFilterStatus(){
            try {
                const res = await $types.find('', null, {params:{type:'STATUS_PP_ID'}})
                res.data.forEach(term => {
                    this.asidebar.items[1].filters[0].items.push({text:term.meaning, value: term.value})
                });
                this.asidebar.items[1].filters[0].value = this.asidebar.items[1].filters[0].items[0].value;
            } catch(error) {
                return error
            }
        },
        changed(){
            const body = {
                    search: '', 
                    period: this.asidebar.items[0].filters[0].value ? this.asidebar.items[0].filters[0].value : null, 
                    status: this.asidebar.items[1].filters[0].value ? this.asidebar.items[1].filters[0].value : null
                }
            this.getDataPP(body)
        },
        async getFilterTerms(){
            try{
                const res = await $professionalizingPractices.find('list')
                    res.data.content.forEach(term => {
                    this.asidebar.items[0].filters[0].items.push({text:term.code, value: term.id})
                });
                this.asidebar.items[0].filters[0].value = this.asidebar.items[0].filters[0].items[0].value;
            } catch(error){
                return error
            }
        },
        async getDataPP(body){
            try{
                this.loading = true;
                const params = {
					page: this.pagination.page - 1,
					length: this.pagination.limit,
					orderBy: this.sort.sortBy,
					orientation: this.sort.sortDesc ? 'desc' : 'asc',
                };
                const object = {
                    search: '',
                    period: this.asidebar.items[0].filters[0].value,
                    status: this.asidebar.items[1].filters[0].value, 
                }
                const payload = body ? body : object;
                const res = await $filter.create(payload, {params: {...params}});
                this.dataStudentPP = res.data.content.map((student, i) => {
                    return ({
                        id: i,
                        completeName: student.nombre,
                        identification: student.dni,
                        businessName: student.dondeHaraLaPP ? student.dondeHaraLaPP : '' ,
                        cityName: student.localidadDondeHaraLaPP ? student.localidadDondeHaraLaPP : '',
                        subjectDescription: student.carrera,
                        statePPId: student.estado?.estado,
                        studentCareerId: student.legajo,
                    })
                });
                this.totalItems = res.data.totalElements;
                this.pagination.pagesAmount = res.data.totalPages;
            } catch(error){
                this.dataStudentPP = [];
				this.pagination.page = 1;
				this.pagination.pagesAmount = 1;
				this.totalItems = 0;
				throw error;
            } finally {
                this.loading = false;
            }
        },
        goBack(){
            if(!this.showTable){
                this.$router.push('/');
            } else {
                this.showTable = false
            }
        },
        async getSummary(){
            try{
                const data = await $summary.find()
                    this.professionalizingPractice = data.data.totalPracticasProfesionalizantes
                    this.completed_procedure = data.data.finalizados
                    this.initialized_procedures = data.data.iniciados
                    this.management_procedures = data.data.enGestion
                    this.qualified_students = data.data.habilitados
            } catch(err){
                return false
            }
        },
        async getInitializedProcedures(value){
            try {
                const procedures = await $status.find(value)
                this.procedureArray = procedures.data.students.map(procedure => {
                    return {
                        id: procedure.id,
                        completeName: procedure.nombre,
                        identification: procedure.dni,
                        businessName: procedure.dondeHaraLaPP,
                        cityName: procedure.localidadDondeHaraLaPP,
                        subjectDescription: procedure.carrera,
                        statePPId: procedure.estado ? procedure.estado.estado : '',
                        studentCareerId: procedure.legajo
                    };
                });
            } catch(err){
                return false
            }
        },
        updateProcedure(legajo){
            this.$router.push(`professionalizing-practice/update/${legajo}`);
        },
        setValueProcedure(value){
            this.showTable = true
            this.statusProcedure = value
            this.getInitializedProcedures(this.statusProcedure)
        },
		onInputPagination(event) {
			this.pagination.page = event;
			this.getDataPP();
		},
		onLengthPaginationChange(event) {
			this.pagination = {
				page: 1,
				limit: event,
				pagesAmount: 1,
			};
			this.getDataPP();
		},
    },
    watch: {
		sort: {
			handler() {
				this.getDataPP();
			},
			deep: true,
		},
	},

};
</script>