    <template>
    <v-row class="pl-3 pr-3">
        <v-col class="d-flex" cols="12" sm="4">
            <v-select
                @blur="$v.selectedCreditCard.$touch()"
                v-model="selectedCreditCard"
                @change="fetchBank"
                :items="cards"
                :loading="loadingCard"
                label="Tarjeta"
                item-text="name"
                return-object
                :error-messages="$v.selectedCreditCard.$error ? $t('components.payments.inputErrors.required') : ''"
                outlined
            ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4" v-if="selectedCreditCard != null && banks && banks.length">
            <v-select
                v-model="selectedBank"
                @change="fetchDues"
                :items="banks"
                :loading="loadingBank"
                label="Banco"
                item-text="name"
                return-object
                :error-messages="$v.selectedBank.$error ? $t('components.payments.inputErrors.required') : ''"
                @blur="$v.selectedBank.$touch()"
                outlined
            ></v-select>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
            <v-select
                v-show="selectedBank != null || banks == null"
                v-model="selectedInstallment"
                @change="setDue"
                label="Cuotas"
                :items="installments"
                item-text="recommended_message"
                return-object
                :error-messages="$v.selectedInstallment.$anyError ? $t('components.payments.inputErrors.required') : ''"
                @blur="$v.selectedInstallment.$touch()"
                outlined
            ></v-select>
        </v-col>
    </v-row>
</template>

<script>
    /*
        Recibe el monto total a cobrar y devuelve dos instancias
        @resetDue = Metodo que se emite al cambiar de tarjeta o banco seleccionado
        @setDue = Metodo que se emite al seleccionar cantidad de cuotas, devuelve un objeto con los siguientes datos
            resumen: { total, cft, tea}
            creditCard: { id, settings }
            bankId ---> Id del banco seleccionado si corresponde, sino NULL
            due: { installments, total_amount }
     */
    import api from '@/api'
    import { required } from 'vuelidate/lib/validators'
    import {mapGetters} from 'vuex';

    const { $payment } = api
    const { $paymentMethod, $config } = $payment

    export default {
        name: 'MercadoPagoInstallments',
        props: {
            total: {type: Number, default: null},
            careerId: {type: Number, default: null},
            studentType: {type: Number, default: null}
        },
        data() {
            return {
                loadingCard: false,
                selectedCreditCard: null,
                cards: [],
                selectedBank: null,
                banks: [],
                loadingBank: false,
                installments: [],
                selectedInstallment: null,
            }
        },
        computed: {
            ...mapGetters({
                mpConfig: 'commons/getMpConfig',
            })
        },
        async mounted() {
            this.fetchMethods()
            await this.getConfig()
            window.Mercadopago.setPublishableKey(this.$getMpKey());
        },
        methods: {
            async getConfig() {
              try {
                const response = await $config.find()
                await this.$store.dispatch('commons/setMpConfig', response.data);
              } catch {
                await this.$store.dispatch('commons/setMpConfig', null);
              }
            },
            async fetchMethods() {
                try {
                    this.loadingCard = true
                    const response = await $paymentMethod.find(null, null, {params: { payment_type_id: 'credit_card' }})
                    response.data.payment_methods.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    this.cards = response.data.payment_methods
                } finally {
                    this.resetDue()
                    this.loadingCard = false
                }
            },
            async fetchBank() {
                try {
                    this.loadingCard = true
                    this.selectedBank = null
                    this.$v.selectedBank.$reset()
                    const response = await $paymentMethod.find('card_issuers', null, {params:
                     { payment_method_id: this.selectedCreditCard.id }})
                    
                    if (response.data.issuers.length <= 0) {
                        this.banks = null
                    } else {
                        response.data.issuers.sort((a, b) => (a.name > b.name) ? 1 : -1)
                        this.banks = response.data.issuers
                    }
                    this.fetchDues()
                } finally {
                    this.resetDue()
                    this.loadingCard = false
                }
            },
            async fetchDues() {
                try {
                    this.loadingBank = true
                    this.resetDue()
                    const params = {
                        payment_method_id: this.selectedCreditCard.id,
                        amount: this.total,
                        studentType: this.studentType,
                        careerId: this.careerId,
                    }
                    if (this.selectedBank != null) params.card_issuer = this.selectedBank.id
                    const {data} = await $paymentMethod.find('installments', null, {params})
                    this.installments = data.installments?.payer_costs ?? data.installments.find(installment => installment.processing_mode == 'aggregator')?.payer_costs;
                } finally {
                    this.loadingBank = false
                }
            },
            resetDue() {
                this.selectedInstallment = null
                this.$v.selectedInstallment.$reset()
                this.$emit('resetDue')
            },
            setDue(value) {
                const tax = value.labels.find(label => label.includes('CFT') && label.includes('TEA'))?.split('|') ?? ('CFT_0,0%|TEA_0,0%').split('|')
                const cft = (tax.length) ? tax[0].split('_') : ['CFT', '0,0%']
                const tea = (tax.length) ? tax[1].split('_') : ['TEA', '0,0%']

                this.$emit('setDue', {
                    resumen: {
                        total: value.total_amount,
                        cft: cft[1],
                        tea: tea[1]
                    },
                    creditCard: {
                        id: this.selectedCreditCard.id,
                        name: this.selectedCreditCard.name,
                        settings: this.selectedCreditCard.settings
                    },
                    bankId: (this.selectedBank) ? this.selectedBank.id : null,
                    due: {
                        installments: this.selectedInstallment.installments,
                        installment_amount: this.selectedInstallment.installment_amount,
                        total_amount: this.selectedInstallment.total_amount,
                        credit_card_fee: this.selectedInstallment?.credit_card_fee ?? null
                    }
                })
            },
            changeAmount() {
                if (this.selectedCreditCard && ((this.banks && this.selectedBank) || this.banks == null)) this.fetchDues()
                this.resetDue()
            },
        },
        validations: {
            selectedCreditCard: {required},
            selectedBank: {required},
            selectedInstallment: {required},
        },
    }
</script>