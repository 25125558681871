<template>
  <div>
    <v-container fluid class="pa-0 pb-8" v-if="showView">
      <template v-if="!createUserStatus">
        <v-container class="container-custom">
          <Breadcrumbs
            :title="$t('modules.users.create.breadcrumbs.title')"
            :description="$t('modules.users.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-container>
        <Stepper
          :stepper="stepper"
          :currentStep="currentStep"
          navButtons
          :loading="loading"
          :canFinish="canFinish"
          :finishButton="finishButton"
          :canGoToNextStep="canGoToNextStep"
          @changeStep="changeStep"
          @confirm="createUser"
          @resetFinishButtonValues="resetFinishButtonValues"
        >
          <v-container class="container-custom">
            <v-row justify="center" no-gutters>
              <v-col sm="10">
                <v-stepper-content step="1" class="px-0">
                  <UsersCreatePersonalForm
                    @cancel="goToUserList"
                    :activeStep="currentStep === 1"
                    @nextStep="enableStep(2)"
                    @disableNextStep="disableStep(2)"
                    :userData="$v.userData"
                    :avatarFile.sync="userData.avatar"
                    @setImagePreview="(image) => {this.imagePreview = image}"
                    @validateCode="validateCode"
                    :loadingCode="loadingCode"
                    :codeUnique.sync="codeUnique"
                  ></UsersCreatePersonalForm>
                </v-stepper-content>
                <v-stepper-content step="2" class="px-0">
                  <UsersCreateContactForm
                    @cancel="goToUserList"
                    :activeStep="currentStep === 2"
                    @nextStep="enableStep(3)"
                    @disableNextStep="disableStep(2)"
                    :userData="$v.userData"
                    @setImagePreview="(image) => {this.imagePreview = image}"
                  ></UsersCreateContactForm>
                  
                </v-stepper-content>
                <v-stepper-content step="3" class="px-0">
                  <OutlinedCard>
                    <TransferList
                      :avatar="false"
                      :loading="loadingSearchRoles"
                      :firstListTitle="$t('modules.users.create.transferList.firstTitle')"
                      :secondListTitle="$t('modules.users.create.transferList.secondTitle')"
                      :availableItems.sync="roles"
                      :added-items.sync="rolesAdded"
                      areEquals="id"
                      @searchItemLeftOnBackend="searchRoles"
                      @searchItemRightOnBackend="searchRolesAdded"
                    ></TransferList>
                    <v-alert
                      text
                      type="warning"
                      class="mt-4"
                      v-if="rolesAdded.length === 0"
                    >{{ $t('modules.users.create.validation_errors.roles') }}</v-alert>
                    <div class="d-flex justify-end">
                      <Button
                        v-if="canCreateRole"
                        class="mt-4"
                        depressed
                        outlined
                        :text="$t('modules.users.create.createRol')"
                        @clicked="tmpDrawer.open = true"
                      ></Button>
                    </div>
                  </OutlinedCard>
                </v-stepper-content>
              </v-col>
            </v-row>
          </v-container>
        </Stepper>
      </template>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
      <TemporaryRightDrawer
        v-if="canCreateRole"
        :open="tmpDrawer.open"
        @tmpDrawerOpen="tmpDrawerOpen"
        :title="tmpDrawer.title"
        :description="tmpDrawer.description"
      >
        <RolesCreate
          @closeDrawer="() => {this.tmpDrawer.open = false; this.getRoles()}"
          insideDrawer
        ></RolesCreate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import UsersCreatePersonalForm from '../Components/UsersCreatePersonalForm/UsersCreatePersonalForm';
import UsersCreateContactForm from '../Components/UsersCreateContactForm/UsersCreateContactForm';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Stepper from '@/components/Stepper/Stepper';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import RolesCreate from '../../Roles/Views/RolesCreate';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TransferList from '@/components/TransferList/TransferList';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import { required, email, minLength, requiredIf } from 'vuelidate/lib/validators';
import { $users, $roles } from '../services';

import { mapGetters } from 'vuex';

export default {
  components: {
    UsersCreatePersonalForm,
    UsersCreateContactForm,
    Breadcrumbs,
    Stepper,
    TemporaryRightDrawer,
    RolesCreate,
    TransferList,
    OutlinedCard,
    Button,
    SuccessMessage
  },
  data () {
    return {
      paginationLimit : 100,
      loadingSearchRoles: false,
      showView: true,
      currentStep: 1,
      tmpDrawer: {
        open: false,
        title: this.$t('modules.users.create.tmpDrawer.title'),
        description: this.$t('modules.users.create.tmpDrawer.description')
      },
      stepper: [
        {
          title: this.$t('modules.users.create.stepper.personal.title'),
          button: this.$t('modules.users.create.stepper.personal.button'),
          available: true,
        },{
          title: this.$t('modules.users.create.stepper.contact.title'),
          button: this.$t('modules.users.create.stepper.contact.button'),
          available: false,
        },{
          title: this.$t('modules.users.create.stepper.roles.title'),
          button: this.$t('modules.users.create.stepper.roles.button'),
          available: false,
        }
      ],
      finishButton: {
        loading: false,
        success: false,
        error: false,
        text: this.$t('actions.create'),
        successText: this.$t('actions.created'),
        errorText: 'error',
      },
      canGoToNextStep: false,
      createUserStatus: null,
      codeUnique: null,
      loadingCode: false,
      userData: {
        status: true,
        name: null,
        lastname: null,
        avatar: null,
        email: null,
        birthDate: new Date().toISOString().substr(0, 10),
        gender: [],
        identificationType: {
          // meaning: 'DNI',
          // type: 'IDENTIFICATION_TYPE',
          // value: 183,
        },
        identification: null,
        phone: '',
        cellphone: '',
        celCountry: {},
        address: {
          street: null,
          number: null,
          floor: null,
          apartment: null,
          zipCode: null,
          region: null,
          cityWithState: {
            idCity: null,
            idRegion: null,
            cityState: null
          }
        },
        roles: []
      },
      roles: [],
      rolesAdded: [],
      imagePreview: null,
      successMessage: {
        type: 'success',
        title: this.$t('modules.users.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.users.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/users') } },
      }
    }
  },
  computed: {
    canFinish () {
      return (this.canCreateUser && this.$v.formValid.$anyDirty && !this.$v.formValid.$anyError && this.rolesAdded.length !== 0);
    },
    canCreateUser() {
      return this.$permissions.portaladministrativo.users.CREATE
    },
    canCreateRole() {
      return this.$permissions.portaladministrativo.roles.CREATE
    },
    loading() {
      return this['commons/getLoadingState'].status
    },
    ...mapGetters([
      'commons/getLoadingState',
      'commons/getUserID',
    ]),
  },
  mounted () {
    this.setDefaultIdType()
    this.getRoles();
  },
  methods: {
    validateRUT(){
      if(this.userData){
        if(this.userData.identificationType.$dirty && this.userData.identificationType.$invalid) {
          if(!this.userData.identification.required){            
            return this.$t('modules.users.create.validation_errors.personalForm.identification')
          }else if(!this.userData.identification.unique){
            return this.$t('modules.users.create.validation_errors.personalForm.identification_unique')
          }else if(!this.userData.identification.digitoVerificador){
            return this.$t('modules.users.create.validation_errors.personalForm.identification_format')
          }
        }
      }
    },
    searchRoles (event) {
      this.getRoles(event);
    },
    searchRolesAdded (event) {
      this.rolesAdded = event
    },
    async validateCode() {
        this.$v.userData.identification.$touch();
        if (this.$v.userData.identification.$model) {
            this.codeUnique = null;
            this.loadingCode = true;
            try {
                const response = await $users.find('exists', null, {params: {identification: this.$v.userData.identification.$model}});
                this.codeUnique = !response.data.exists;
                this.loadingCode = false;
            } catch (error) {
                this.codeUnique = null;
                throw error
            } finally {
                this.$v.userData.identification.$touch();
            }
        }
    },
    async getRoles(rol) {
      this.loadingSearchRoles = true;
      let roles = [];

      const params = {
        orderBy: 'id',
        orientation: 'desc',
        page: 0,
        length: 500,
      }

      if (rol) params.name = rol
      const { data } = await $roles.find(null, null, { params });
      if (data && data.content.length) roles = data.content.filter(rol => rol.rolType.meaning != 'Alumno')

      this.roles = roles.map(item => ({ id: item.id, name: item.name }) );
      this.loadingSearchRoles = false;
    },
    changeStep (step) {
      this.currentStep = step;
      this.canGoToNextStep = false;
    },
    resetFinishButtonValues () {
      this.finishButton.loading = false
      this.finishButton.success = false
      this.finishButton.error = false
    },
    async createUser () {
      if (!this.canCreateUser) return false
      this.finishButton.loading = true;

      const roles = this.rolesAdded.map(role => {
        return { id: role.id };
      });

      const address = {
        street: this.userData.address.street,
        number: this.userData.address.number,
        floor: this.userData.address.floor,
        apartment: this.userData.address.apartment,
        zipCode: this.userData.address.zipCode,
        cityWithState: {
          idCity : this.userData.address.cityWithState.idCity,
          idRegion : this.userData.address.cityWithState.idRegion,
        }
      };

      const newUser = {
        status: true,
        userData: {
          name: this.userData.name,
          lastname: this.userData.lastname,
          email: this.userData.email,
          birthDate: this.userData.birthDate,
          gender: {value: this.userData.gender.value},
          identificationType: {value: this.userData.identificationType.value},
          identification: this.userData.identification,
          phone: this.userData.phone,
          cellphone: this.userData.cellphone,
          address,
        },
        roles,
        userUpdate: this['commons/getUserID'],
      };

      try {
        const user = await $users.create(newUser);
        if (user.data.id) {
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.users.create.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.users.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/users') } };

          if (this.userData.avatar) {
            const formData = new FormData();
            formData.append('file', this.userData.avatar);
            this.$users.sendAvatar(formData, user.data.id).then(() => {
              this.createUserStatus = true;
            });
          } else {
            this.createUserStatus = true;
          }

        } else {
          this.successMessage.type = 'error';
          this.successMessage.title = this.$t('modules.users.create.error_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.users.create.success_message.actions.back'), callback: () => { this.createUserStatus = false } },
          this.successMessage.actionSecondary = null;
          this.createUserStatus = true;
        }

        this.resetFinishButtonValues();

      } catch (err) {
        this.resetFinishButtonValues();
        this.successMessage.type = 'error';
        this.successMessage.actionPrimary = { text: this.$t('modules.users.create.success_message.actions.back'), callback: () => { this.createUserStatus = false } },
        this.successMessage.actionSecondary = null;
        if (err.code && err.code === 'E203') {
          this.successMessage.title = this.$t('modules.users.create.error_message.existing_user');
        } else {
          this.successMessage.title = this.$t('modules.users.create.error_message.title');
        }
        this.finishButton.error = true;
        this.createUserStatus = true;
      }
    },
    clearData () {
      this.resetFinishButtonValues();
      this.$v.$reset();
      this.currentStep = 1;
      this.stepper[0].available = true;
      this.stepper[1].available = false;
      this.stepper[2].available = false;
      this.canGoToNextStep = false;
      this.userData = {
        status: true,
        name: null,
        lastname: null,
        avatar: null,
        email: null,
        birthDate: new Date().toISOString().substr(0, 10),
        gender: [],
        identificationType: [],
        identification: null,
        phone: '',
        cellphone: '',
        address: {
          street: null,
          number: null,
          floor: null,
          apartment: null,
          zipCode: null,
          region: null,
          cityWithState: null
        },
        roles: []
      };
      this.roles = [];
      this.getRoles();
    },
    goToUserList () {
      this.$router.push('/users');
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open
    },
    enableStep (step) {
      this.stepper[step - 1].available = true;
      this.canGoToNextStep = true
    },
    disableStep (step) {
      this.stepper[step - 1].available = false;
      this.canGoToNextStep = false
    },
    setDefaultIdType() {
      if (this.$isTeclab) this.userData.identificationType = { meaning: 'DNI', value: 183 }
      else if (this.$isIPP) this.userData.identificationType = { meaning: 'RUT', value: 17 }
    }
  },
  validations: {
    userData: {
      name: { required },
      lastname: { required },
      birthDate: { required },
      gender: { required },
      identification: {
        required,
        minLength: minLength(7), 
        unique() {
          return this.codeUnique !== false;
        },
      },
      identificationType: { required },
      email: { required, email },
      cellphone: { required },
      phone: {},
      address: {
        street: { required },
        number: {},
        floor: {},
        apartment: {},
        zipCode: {},
        region: {},
        cityWithState: {
          idCity: requiredIf(function (cityWithState) {
            return cityWithState.idRegion == null
          }),
          idRegion: requiredIf(function (cityWithState) {
            return cityWithState.idCity == null
          }),
        }
      },
      contactFormValid: ['userData.email', 'userData.address.street', 'userData.address.cityWithState.idCity'],
      personalFormValid: ['userData.name', 'userData.lastname', 'userData.gender', 'userData.identification', 'userData.identificationType'],
    },
    formValid: ['userData.personalFormValid', 'userData.contactFormValid'],
  },
}
</script>
