const checking_account_traslations = {
  es_PE: {
      account: {
          debts: '¡Revisa tus pagos! Tenés una deuda pendiente de ${calcDebts}',
          empty_state: {
              subtitle: 'Consultá pagos pendientes.'
          }
      },
      credits: {
          description: `Consulta los términos y condiciones en el <a href="/reglamento" style="text-decoration: none">Reglamento.</a>`,
      }
  },  
  es: {
    account: {
      breadcrumb: {
        title: 'Cuenta corriente'
      },
      debts: '¡Revisá tus pagos! Tenés una deuda pendiente de ${calcDebts}',
      noDebts: 'Tu saldo está al día',
      table: {
        check: 'Comprobante',
        concept: 'Concepto',
        amount: 'Monto',
        receiptDate: 'Emitido el {date}'
      },
      empty_state: {
        title: 'No hay comprobantes generados.',
        subtitle: 'Consultá pagos pendientes.'
      }
    },
    ticket: {
      title: 'Detalle de ',
      reference: 'Referencia',
      date: 'Fecha',
      paymentMethod: 'Forma de pago',
      detail: 'Detalle',
      total: 'Total',
      btnDownload: 'Descargar'
    },
    credits: {
      breadcrumb: {
        title: 'Créditos Teclab',
      },
      subtitle: 'La suma de tus créditos se da a través de los beneficios Comunidad Teclab, Amigo Referido e Incentivo por desempeño académico.',
      description: `Consultá los términos y condiciones en el <a href="/reglamento" style="text-decoration: none">Reglamento.</a>`,
      control: {
        title: 'Créditos acumulados',
        confirm: 'Creditos canjeados en el cuatrimestre',
        failed: 'Creditos al vencer el {date}'
      },
      table: {
        date: 'Fecha',
        detail: 'Detalle',
        points: 'Puntos',
      }
    }
  }
};

export default checking_account_traslations;
