import subjectsTranslations from './Subjects/Translations';
import studyPlanTranslations from './StudyPlan/Translations';

const academicChargeTranslations = {
    es_CL: {
        subjects: { ...subjectsTranslations.es_CL },
        studyPlan: { ...studyPlanTranslations.es_CL },
    },
    es_PE: {
        subjects: { ...subjectsTranslations.es_PE },
        studyPlan: { ...studyPlanTranslations.es_PE },
    },
    es: {
        subjects: { ...subjectsTranslations.es },
        studyPlan: { ...studyPlanTranslations.es },
    },
    en: {
        subjects: { ...subjectsTranslations.en },
        studyPlan: { ...studyPlanTranslations.en },
    }
};

export default academicChargeTranslations
