<template>
  <v-item-group active-class="selected" v-model="selectedCardValue">
    <v-container>
      <v-row v-for="(item, index) in schedules" :key="index" cols="12" md="4">
        <v-col>
          <v-item v-slot="{ toggle }" :value="item.idCeoAgenda">
            <v-card class="d-flex justify-space-between align-center pa-4" @click="toggle" v-on:click="$emit('cardSelection', selectedCardValue)" style="background-color: #f5f5f5">
              <div>
                <div class="text-caption font-weight-medium card-caption">{{ $t('modules.portalalumno.exams.scheduleStep.card.schedule') }}</div>
                <div class="font-weight-light py-2">{{ item.horario }}</div>
              </div>
              <div>
                <div class="text-caption font-weight-medium card-caption">{{ $t('modules.portalalumno.exams.scheduleStep.card.availableSpots') }}</div>
                <div class="text-center py-2 card-availability">{{ item.lugaresDisponibles }}</div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
  export default {
    name: 'ExamsScheduleCardList',
    props: {
      schedules: Array,
      scheduleButton: Object
    },
    data() {
      return {
        selectedCardValue: null
      };
    },
    watch: {
      scheduleButton: {
        handler(val) {
          if (val.success) this.selectedCardValue = null;
        },
        deep: true
      }
    }
  };
</script>

<style lang="sass" scoped>

$card-teal: #61BCCC

.card-caption
  color: #B2B9B3

.card-availability
  color: $card-teal

.selected
  background-color: $card-teal !important
  color: white

.selected .card-caption
  color: white

.selected .card-availability
  color: white

</style>