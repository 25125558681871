const examEnablerTranslations = {
    es_PE: {
        table: {
            headers: {
                students: 'Estudiantes'
            }
        },
        update: {
            headers: {
                student: 'Estudiante',
                subject: 'Curso'
            },
            references_card: {
                students_enrolled: 'Estudiantes inscritos: {n}',
            },
        },
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Habilitación de exámenes',
                description: 'Administración de exámenes'
            },
            references_card: {
                available_tables: 'Mesas disponibles',
                filter: 'Filtrar por:',
            },
            headers: {
                tables_type: 'Tipo de mesa',
                schedule: 'Horario',
                students: 'Alumnos',
                status: 'Estado',
            },
            empty_boards: 'No hay mesas disponibles',
            items: {
                schedule: '{start} hs a {end} hs',
                students: '{students} de {total}',
                status: ' | {count} error | {count} errores',
            }
        },
        update: {
            breadcrumbs: {
                title: 'Mesa de exámen: {horarios}',
                description: 'Detalle de una mesa',
            },
            headers: {
                student: 'Alumno',
                identification: 'RUT',
                career: 'Carrera',
                subject: 'Asignatura',
                status: 'Estado',
                exam: 'Exámen',
                proctoring:'Proctoring'
            },
            references_card: {
                students_enrolled: 'Alumnos inscritos: {n}',
                references: {
                    title: 'Referencias:',
                    notebooks_exam: 'Notebook',
                    laboratory_exam: 'Laboratorio',
                },
                act_number: 'Acta N° {act_number}',
                actions: {
                    make_act: 'Generar acta',
                    enable_exams: 'Habilitar exámenes',
                    enable_all: 'Habilitar todos',
                }
            },
            items: {
                button_actions:{
                    enable: 'Habilitar',
                    enabling: 'Habilitando',
                    disable: 'Deshabilitar',
                    generate: 'Generar',
                },
                states: {
                    disabled: 'Deshabilitado'
                },
                error: 'Error',
                generated: 'Generado', 
            },
            modal: {
                disabler: {
                    title:'Deshabilitar exámen N° {exam_number}',
                    subtitle: 'Estás por deshabilitar el EXÁMEN FINAL de',
                    selectReason: 'Selecciona el motivo',
                    reason: 'Motivo',
                    justification: 'Justificación',
                },
                information: {
                    title: 'Exámen N° {exam_number}',
                    text1: 'El <strong>EXÁMEN FINAL</strong> de <strong>{student}</strong> ha sido deshabilitado el {date} a las {hour}.',
                    text2: '<strong>Motivo:</strong> {reason}',
                    text3: '<strong>Justificación:</strong> {justify}',
                },
                bulkProctoring:{
                    title:'Elija un proctoring'
                }
            },
            tableTop:{
                description:'Cambiar proctoring'
            }
        },
    },
    en: {

    }
}


export default examEnablerTranslations
