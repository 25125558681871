<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    v-model="tableFilter"
                    @click:clear="clearAction"
                    @keyup.enter="searchAction"
                    @keyup.delete="clearAction"
                ></v-text-field>
            </v-col>
            <v-col sm="4">
                <Button
                class="ml-4"
                @clicked="searchAction"
                outlined
                depressed
                :text="$t('actions.search')"
                ></Button>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :items="items"
                    :headers="headers"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table"
                    style="table-layout: fixed"
                    :loading="loading"
					:server-items-length="totalItems"
                    :sort-by="sortBy"
					:sort-desc="sortDesc"
					@update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
					@update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"

                >
                <template v-slot:[`item.action`]="{item}">
                        <v-menu bottom left v-if="item.statePPId && item.statePPId != 266">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item
                                    @click="goToStudentData(item.studentCareerId)"
                                >
                                    <v-list-item-title>
                                        <v-icon>mdi-pencil</v-icon> {{$t('modules.careers.table.search.edit')}}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.businessName`]="{item}">
                        <p class="ma-2 text-center" v-if="item.businessName == ''">-</p>
                        <p class="ma-2" v-else>{{item.businessName}}</p>
                    </template>
                    <template v-slot:[`item.cityName`]="{item}">
                        <p class="ma-2 text-center" v-if="item.cityName == ''">-</p>
                        <p class="ma-2" v-else>{{item.cityName}}</p>
                    </template>
                    <template v-slot:[`item.statePPId`]="{item}">
                        <p class="ma-2" v-if="!item.statePPId">Habilitado</p>
                        <p class="ma-2" v-if="item.statePPId == 267">Inicializado</p>
                        <p class="ma-2" v-if="item.statePPId == 269">Finalizado</p>
                        <p class="ma-2" v-if="item.statePPId == 268">Gestión</p>
                        <p class="ma-2" v-if="item.statePPId == 279">Firmado</p>
                        <p class="ma-2" v-if="item.statePPId == 266">Habilitado</p>
                        <p class="ma-2" v-if="item.statePPId == 270">Rechazado</p>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="itemsPerPage.toString()"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                    @change="$emit('length-pagination-change', itemsPerPage)"
                />
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination
					:value="page"
					:length="pageCount"
					@next="$emit('next-pagination', page)"
					@previous="$emit('previous-pagination', page)"
					@input="$emit('input-pagination', $event)"
					class="superTable__pagination"
				/>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    max="pageCount"
                    outlined
                    class="superTable__paginationInput d-inline-block mr-4"
                    v-model.number="goToPage"
                    @blur="changePage"
                />
                <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="sass" scoped>
.v-expansion-panels
    .v-expansion-panel-content
        padding-left: 10px
        padding-bottom: 0px
        padding-right: 16px
</style>

<script>
import Button from '@/components/Button/Button';

export default {
    name: 'SuperTable',
    components: {
        Button
    },
    props: {
        items: Array,
        headers: Array,
        loading: Boolean,
        sortBy: String,
		sortDesc: Boolean,
		pageCount: { type: Number, default: 1 },
		page: { type: Number, default: 1 },
		totalItems: Number,

    },
    data() {
        return {
            itemsPerPage: 20,
            itemsPerPageAvailable: [20, 50, 100, 200],
            tableFilter: null,
            searchStudent: null,
            goToPage: 1,
        }
    },
    methods:{
        goToStudentData(value){
            this.$emit('update', value)
        },
        searchAction(){
            const body = {
                search: this.tableFilter ? this.tableFilter : '',
                period: null,
                status: null,
            }
            this.$emit('search', body)
        },
        clearAction(){
            const body = {
                search: '',
                period: null,
                status: null, 
            }
            this.$emit('search', body)
        },
        changePage() {
			if (this.goToPage > this.pageCount) this.goToPage = this.page;
			else this.$emit('input-pagination', this.goToPage);
		},

    },
    watch:{
        page (val) {
            if (val > this.pageCount) this.page = this.pageCount;
            if (val < 1) this.page = 1;
            if (this.page !== this.goToPage) this.goToPage = this.page;
        },
    },
    computed: {
        itemsCount () {
            const counter = {
                totalItems: this.totalItems,
                startItem: ( ( this.page * this.itemsPerPage ) - this.itemsPerPage ) +  1,
                endItem: this.page * this.itemsPerPage
            };
        if ( counter.endItem > this.totalItems )counter.endItem = this.totalItems
        return counter
        }
    },
};
</script>
