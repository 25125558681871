const ProceduresTranslations = {
  es_PE: {
      initUpdate: {
          subjectsEmpty: 'No existen cursos homologados entre las carreras.',
      }
  },
  es: {
    grid: {
      breadcrumbs: {
        title: 'Gestión de Documentación',
        description: 'Administración de documentación.'
      },
      cards: {
        requireCopy: 'Requiere copia física.',
        notRequireCopy: 'No requiere copia física.'
      }
    },
    initTable: {
      breadcrumbs: {
        title: 'Trámites Iniciados',
        description: 'Administración de trámites iniciados.'
      },
      headers: {
        number: 'N° de Trámite',
        student: 'Estudiante',
        category: 'Categoría',
        type: 'Tipo de Trámite',
        date: 'Fecha',
        paymentDate: 'Pago',
        status: 'Estado',
      },
      search: 'Buscar',
      bulkdelete: 'Eliminar',
      edit: 'Editar',
      delete: 'Eliminar',
      goToPage: 'Ir a página',
      approve: 'Aprobar',
      observe: 'Observar',
      reject: 'Rechazar',
    },
    table: {
      breadcrumbs: {
        title: 'Trámites',
        description: 'Administración de trámites.'
      },
      headers: {
        code: 'Código',
        name: 'Nombre de Trámite',
        type: 'Tipo de Trámite',
        tariffed: 'Arancelado',
      },
      search: 'Buscar',
      bulkdelete: 'Eliminar',
      edit: 'Editar',
      delete: 'Eliminar',
      goToPage: 'Ir a página',
    },
    initUpdate: {
      receiptHeaders: {
        concept: 'Concepto',
        amount: 'Total',
        repay: 'Reembolso'
      },
      reason: 'Motivo del cambio',
      changeStudyPlan: 'Cambio del plan de estudios',
      noReason: 'No se especificó motivo',
      subjectsEmpty: 'No existen materias homologadas entre las carreras.',
      documentationEmpty: 'No hay documentación para mostrar.',
      documentation: 'Documentación',
      receipts: 'Facturas',
      receiptsEmpty: 'No hay facturas para mostrar.',
      receiptNumber: 'Factura {receiptNumber}',
      receiptDate: 'Emitida el {date}',
      receiptConcept: 'Concepto',
      receiptAmount: 'Monto',
      receiptRepay: 'Reembolso',
      title: '{procedure} de {name}',
      subtitle: 'Iniciado el {date}',
      career: 'Carrera',
      type: 'Tipo',
      benefit: 'Beneficio',
      scholarship: 'Beca',
      motive: 'Motivo',
      uploaded: 'Entregado el {date}',
      documentOwner: 'Propietario',
      documentType: 'Tipo',
      status: 'Estado',
      needsMoreDocs: 'Tiene documentación pendiente',
      messages: 'Mensajes',
      comments: 'Comentarios',
      reject: 'Rechazar',
      aprove: 'Aprobar',
      observe: 'Observar',
      identificationTeclab: 'DNI',
      identificationIpp: 'RUT',
      identificationEmpty: '(Sin datos de identificación)',
      accountHolder: 'CUIL del titular',
      accountCBU: 'CBU',
      bank: 'Banco',
      stateEmpty: 'Sin datos de estado',
      name: 'Nombre',
      lastname: 'Apellido',
      contact: 'Contacto',
      contactTime: 'Horario de contacto',
      raicException: 'Excepción RAIC',
      alertMessage: {
        description: '¿Seguro desea {status} el trámite? No se podrá modificar.'
      },
      error_message: 'No se pudo actualizar correctamente el trámite',
      success_message: {
        title: 'Trámite actualizado correctamente',
        actions: {
          primary_text: 'Cerrar',
          back: 'Volver',
        },
      },
      validations: {
        receiptRepayRequired: 'Monto requerido',
        receiptRepayBetween: 'Entre 0 y {maxAmount}'
      }
    }
  },
  en: { }
}
export default ProceduresTranslations;