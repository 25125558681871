import { render, staticRenderFns } from "./Assestment.vue?vue&type=template&id=fd2edf0c&scoped=true&"
import script from "./Assestment.vue?vue&type=script&lang=js&"
export * from "./Assestment.vue?vue&type=script&lang=js&"
import style0 from "./Assestment.vue?vue&type=style&index=0&id=fd2edf0c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd2edf0c",
  null
  
)

export default component.exports