<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewScholarship">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.scholarships.update.breadcrumbs.title')"
            :description="$t('modules.scholarships.update.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.scholarships.update.sectionData.title')"
                :subtitle="$t('modules.scholarships.update.sectionData.subtitle')"
                :switchValue="newScholarship.status"
                :switchLabel="changeStatuslabel"
                @switchChange="changeStatus()"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      outlined
                      :error="$v.newScholarship.description.$anyError"
                      :error-messages="descriptionErrors"
                      :label="`${$t('modules.scholarships.update.sectionData.items.name')} *`"
                      v-model.trim="$v.newScholarship.description.$model"
                      @blur="()=>$v.newScholarship.description.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3">
                    <v-text-field
                      outlined
                      :error="$v.newScholarship.code.$anyError"
                      :error-messages="codeErrors"
                      :loading="loadingCode"
                      @paste="onPaste"
                      :label="`${$t('modules.scholarships.update.sectionData.items.code')} *`"
                      :value="$v.newScholarship.code.$model"
                      @input="$v.newScholarship.code.$model = $event.toUpperCase()"
                      @blur="()=>{$v.newScholarship.code.$touch(); this.loadingCode=true; validateCode($v.newScholarship.code.$model)}"
                      required
                      type="text"
                      min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3">
                    <v-menu
                      ref="menu"
                      v-model="item.menu"
                      :close-on-content-click="false"
                      :return-value.sync="newScholarship.from"
                      transition="scale-transition"
                      offset-y
                      required
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model.trim="$v.newScholarship.from.$model"
                          @blur="()=>$v.newScholarship.from.$touch()"
                          :label="`${$t('modules.scholarships.update.sectionData.items.from')} *`"
                          :error="$v.newScholarship.from.$anyError"
                          :error-messages="fromErrors"
                          append-icon="mdi-calendar"
                          readonly
                          required
                          outlined
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title color="primary" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-select
                      outlined
                      multiple
                      :label="`${$t('modules.scholarships.create.sectionData.items.relation')} *`"
                      v-model="selectedBillingProducts"
                      :items="billingProduct"
                      @blur="selectedBillingProductsTouch"
                      item-text="name"
                      :error-messages="errorMesagesSelected ? $t('modules.scholarships.create.sectionData.error.selectedBillingProducts'):''"
                      return-object
                    ></v-select>
                  </v-col>
                  <v-col cols="3" justify="center" align="center">
                    <v-switch
                      class="mt-1"
                      v-model="withAverage"
                      color="primary"
                      :label="`Aplica a promedio`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <MultipleFormGroupCustom
                  :selectedBillingProducts="this.selectedBillingProducts"
                  :discountType="this.discountType"
                  :withAverage="withAverage"
                  @errorMultipleForm="updateErrorStatus($event)"
                ></MultipleFormGroupCustom>
                <v-divider v-if="this.selectedBillingProducts.length===0" class="my-4"></v-divider>
                <v-row>
                  <v-col sm="12">
                    <v-card-title
                      class="title font-weight-bold ma-0 px-0 pt-2"
                    >{{ $t('modules.scholarships.update.sectionRenewal.title') }}</v-card-title>
                    <v-card-text
                      class="body-2 pa-0 ma-0"
                    >{{ $t('modules.scholarships.update.sectionRenewal.subtitle') }}</v-card-text>
                    <v-switch
                      v-model="newScholarship.renew"
                      color="primary"
                      :label="$t('modules.scholarships.update.sectionRenewal.items.renewalCheck')"
                    ></v-switch>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.scholarships.update.sectionDocumentation.title')"
                :subtitle="$t('modules.scholarships.update.sectionDocumentation.subtitle')"
                class="mb-6"
              >
                <TransferList
                  :avatar="false"
                  :loading="loadingSearchDocs"
                  :firstListTitle="$t('modules.scholarships.create.sectionDocumentation.transferList.firstTitle')"
                  :secondListTitle="$t('modules.scholarships.create.sectionDocumentation.transferList.secondTitle')"
                  :availableItems.sync="docs"
                  :added-items.sync="docsAdded"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchDocs"
                  @searchItemRightOnBackend="searchDocsAdded"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="docsAdded.length===0"
                >{{ $t('modules.scholarships.update.warning.no_selected_warning') }}</v-alert>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.scholarships.update.sectionRestriction.title')"
                class="mb-6"
              >
                <v-radio-group v-model="limited" class="no-message ma-0">
                  <v-radio
                    :label="$t('modules.scholarships.create.sectionRestriction.items.radioFirst')"
                    color="primary"
                    :value="0"
                  ></v-radio>
                  <div class="d-flex">
                    <v-radio
                      :label="$t('modules.scholarships.create.sectionRestriction.items.radioSecond')"
                      color="primary"
                      :value="1"
                    ></v-radio>
                    <v-text-field
                      :error-messages="($v.limit.$invalid && $v.limit.$dirty) ? $t('modules.scholarships.create.errors.limit') : ''"
                      outlined
                      v-model.number="$v.limit.$model"
                      type="text"
                      @paste="onPaste"
                      @keypress="$validateIntegerInputNumber($event, $v.limit.$model, null, 6)"
                      placeholder="0"
                      @blur="$v.limit.$touch()"
                      :disabled="!limited"
                    ></v-text-field>
                  </div>
                </v-radio-group>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/scholarships')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  @end="resetButtonValues()"
                  @clicked="verifyScholarship"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :title="successMessage.title"
        :type="successMessage.type"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<style lang="sass">
  .containerPadding
    padding-left: 34px
    padding-right: 34px
</style>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import MultipleFormGroupCustom from '../Components/MultipleFormGroupCustom/MultipleFormGroupCustom';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { required, minValue, decimal, requiredIf } from 'vuelidate/lib/validators';
import TransferList from '@/components/TransferList/TransferList';
import { $products, $documentations, $scholarships } from '../services';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { mapGetters } from 'vuex';

export default {
  name: 'ScholarshipsUpdate',
  components: {
    OutlinedCard,
    Breadcrumbs,
    TransferList,
    Button,
    MultipleFormGroupCustom,
    SuccessMessage
  },
  data () {
    return {
      paginationLimit: 150,
      loadingSearchDocs: false,
      errorMesagesSelected: false,
      radioValue1: undefined,
      radioValue2: undefined,
      errorChild: false,
      insideDrawer: false,
      limit: null,
      limited: 0,
      item: {
        menu: false,
        date: '',
        relation: '',
        percentage: '',
        amount: ''
      },
      groups: [
        {
          billingProduct: {
            id: null
          },
          average: 1,
          percentage: 0,
          amount: 0
        }
      ],
      withAverage: true,
      createNewScholarship: null,
      successMessage: {
        title: null,
        actionPrimary: null
      },
      finishButton: {
        loading: false,
        success: false,
        error: false,
        text: this.$t('actions.create'),
        successText: this.$t('actions.created'),
        errorText: 'error'
      },
      loadingCode: false,
      validationErrorCode: false,
      originalCode: 0,
      discountType: [this.$t('modules.scholarships.update.discountType.percentege'), this.$t('modules.scholarships.update.discountType.amount')],
      firstTimeChange: true,
      docs: [],
      docsAdded: [],
      discountValue: this.$t('modules.scholarships.update.discountType.percentege'),
      date: '',
      billingProduct: [],
      selectedBillingProducts: [],
      auxDetails: [],
      newScholarship: {
        description: null,
        code: null,
        from: null,
        status: true,
        renew: true,
        limit: 1,
        scholarshipDocs: [],
        scholarshipProduct: []
      },
      createButton: {
        loading: false,
        success: false,
        error: false
      }
    };
  },
  async mounted () {
    let aux = [];
    this.billingProduct = await $products.find();
    this.billingProduct = this.billingProduct.data.content.map(product => {
      return {
        id: product.id,
        name: product.name,
        discountValue: this.$t('modules.scholarships.update.discountType.percentege'),
        details: [
          {
            billingProduct: {
              id: null
            },
            average: null,
            percentage: null,
            amount: null
          }
        ]
      };
    });
    aux = await $scholarships.findById(this.$route.params.id);
    this.selectedBillingProducts = aux.data.scholarshipProduct.reduce(
      (acc, item) => {
        if (acc.length) {
          const index = acc.findIndex(
            finded => finded.id === item.billingProduct.id
          );
          if (index !== -1) {
            acc[index].details.push({
              billingProduct: {
                id: item.billingProduct.id
              },
              average: item.average,
              percentage: item.percentage ? item.percentage : null,
              amount: item.amount ? item.amount : null
            });
          } else {
            item.percentage = item.percentage ? item.percentage : null
            item.amount = item.amount ? item.amount : null
            acc.push(this.createProduct(item));

          }
          
          this.withAverage = !!item.average;

          return acc;
        }
        item.percentage = item.percentage ? item.percentage : null
        item.amount = item.amount ? item.amount : null
        acc.push(this.createProduct(item));
        return acc;
      },
      []
    );
    this.fetchData(this.$route.params.id);
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.scholarships.UPDATE
    },
    ...mapGetters({userId: 'commons/getUserID',}),
    canUpdateScholarship () {
      let validationCount = 0;
      Object.keys(this.newScholarship).forEach(key => {
        if (
          key !== 'limit' && (
            this.newScholarship[key] === null ||
            this.newScholarship[key] === '' ||
            this.docsAdded.length === 0 ||
            this.selectedBillingProducts.length === 0 ||
            this.errorChild === true ||
            this.validationErrorCode === true ||
            this.loadingCode === true)
        ) {
          validationCount++;
        }
        if (this.limit === null && this.limited === 1) {
          validationCount++;
        }
      });
      if (this.$v.limit.$anyError) {
        validationCount++;
      }
      if (validationCount > 0) {
        return false;
      } else {
        return true;
      }
    },
    changeStatuslabel () {
      return this.newScholarship.status
        ? this.$t('modules.scholarships.update.sectionData.labelEnable')
        : this.$t('modules.scholarships.update.sectionData.labelDisable');
    },
    descriptionErrors () {
      const errors = [];
      if (!this.$v.newScholarship.description.$anyError) return errors;
      if (!this.$v.newScholarship.description.required)
        errors.push(
          this.$t('modules.scholarships.update.validations.descriptionRequired')
        );
      return errors;
    },
    codeErrors () {
      const errors = [];
      if (this.validationErrorCode) {
        errors.push(
          this.$t('modules.scholarships.create.validations.codeExist')
        );
      }
      if (!this.$v.newScholarship.code.$anyError) return errors;
      if (!this.$v.newScholarship.code.required)
        errors.push(
          this.$t('modules.scholarships.update.validations.codeRequired')
        );
      return errors;
    },
    fromErrors () {
      const errors = [];
      if (!this.$v.newScholarship.from.$anyError) return errors;
      if (!this.$v.newScholarship.from.required)
        errors.push(
          this.$t('modules.scholarships.update.validations.fromRequired')
        );
      return errors;
    },
  },
  methods: {
    checkStatus () {
      if (this.newScholarship.status) {
        this.updateScholarship();
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.scholarships.update.warning_popup.title'),
          content: this.$t('modules.scholarships.update.warning_popup.content'),
          actionPrimary: {
            text: this.$t('modules.scholarships.update.warning_popup.update'),
            callback: this.updateScholarship
          },
          actionSecondary: {
            text: this.$t('modules.scholarships.update.warning_popup.cancel'),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      }
    },
    returnName (array, id) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          return array[i].name;
        }
      }
    },
    selectedBillingProductsTouch () {
      if (!this.selectedBillingProducts.length) {
        this.errorMesagesSelected = true
      } else {
        this.errorMesagesSelected = false
      }
    },
    createProduct (item) {
      return {
        id: item.billingProduct.id,
        name: this.returnName(this.billingProduct, item.billingProduct.id),
        discountValue: item.discountType,
        details: [
          {
            billingProduct: {
              id: item.billingProduct.id
            },
            average: item.average,
            percentage: item.percentage,
            amount: item.amount
          }
        ]
      };
    },
    async validateCode (value) {
      if (
        !(
          value.toString() === this.originalCode ||
          value === '' ||
          value === null
        )
      ) {
        this.loadingCode = true;
        const response = await $scholarships.find('exists', null, {
          params: {
            code: value
          }
        });
        if (response.data.exists) {
          this.validationErrorCode = true;
        } else {
          this.validationErrorCode = false;
        }
      }
      this.loadingCode = false;
    },
    updateErrorStatus ($event) {
      this.errorChild = $event;
    },
    updateDiscountValue () {
      this.$v.groups.$reset();
    },
    onPaste () {
      event.preventDefault();
    },
    onWithoutRestrictionClick () {
      this.limit = 0;
      this.newScholarship.limit = 0;
    },
    onWithRestrictionClick () {
      this.limit = 1;
      this.newScholarship.limit = 1;
    },
    changeStatus () {
      this.newScholarship.status = !this.newScholarship.status;
    },
    async fetchData (id) {
      await this.getScholarshipsDocs();
      const temp = await $scholarships.findById(id);
      this.newScholarship = [].concat(temp.data);
      this.newScholarship = this.newScholarship.map(scholarship => {
        return {
          id: scholarship.id,
          description: scholarship.description,
          code: scholarship.code,
          from: scholarship.from,
          status: scholarship.status,
          renew: scholarship.renew,
          limit: scholarship.limit,
          scholarshipDocs: scholarship.scholarshipDocs
            ? scholarship.scholarshipDocs
            : [],
          scholarshipProduct: []
        };
      });
      this.newScholarship = this.newScholarship.shift();
      this.originalCode = this.newScholarship.code;
      this.limit = this.newScholarship.limit;
      this.limited = (this.newScholarship.limit !== null) ? 1 : 0;
      this.groups = this.newScholarship.scholarshipProduct
        ? JSON.parse(JSON.stringify(this.newScholarship.scholarshipProduct))
        : [];
      this.docsAdded = this.newScholarship.scholarshipDocs
        ? this.newScholarship.scholarshipDocs.map(item => {
          return {
            id: item.documentation.id,
            name: item.documentation.name
          };
        })
        : [];
      this.docs = this.docs.filter(
        item =>
          !this.docsAdded.map(item => item.id).includes(item.id)
      )
        .map(item => ({ name: item.name, id: item.id }));
    },
    // async getDiscountType() {
    //   const fetchedDiscountTypes = await $types.find(null, null, { params: { type: "SUBJECT_TYPE"}})
    //   this.discountType = fetchedDiscountTypes.data;
    //   this.discountType = this.modalityTypes.map(type => {
    //     return {
    //       id: type.value,
    //       name: type.meaning
    //     };
    //   });
    // },
    async getBillingProducts () {
      this.billingProduct = await $products.find();
      this.billingProduct = this.billingProduct.data.content.map(product => {
        return {
          id: product.id,
          name: product.name,
          discountValue: this.$t('modules.scholarships.update.discountType.percentege'),
          details: [
            {
              billingProduct: {
                id: null
              },
              average: null,
              percentage: null,
              amount: null,
              error: true
            }
          ]
        };
      });
    },
    searchDocs (event) {
      this.getScholarshipsDocs(event);
    },
    searchDocsAdded (event) {
      this.docsAdded = event
    },
    async getScholarshipsDocs (doc) {
      this.loadingSearchDocs = true
      let documentations = [];
      if (doc) {
        documentations = await $documentations.find(null, null, {
          params: { name: doc, length: this.paginationLimit }
        });
      } else {
        documentations = await $documentations.find(null, null, {
          params: { length: this.paginationLimit }
        });
      }
      if (documentations.data && documentations.data.length) {
        documentations = documentations.data.map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
        this.docs = documentations;
      }
      this.loadingSearchDocs = false
    },
    async verifyScholarship () {
      if (!this.newScholarship.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.scholarships.update.warning_status.title'),
          content: null,
          actionPrimary: {
            text: this.$t(
              'modules.scholarships.update.warning_status.actions.primary_text'
            ),
            callback: () => {
              this.updateScholarship();
            }
          },
          actionSecondary: {
            text: this.$t(
              'modules.scholarships.update.warning_status.actions.secondary_text'
            ),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary'
        });
        this.createButton.loading = false;
      } else {
        this.updateScholarship();
      }
    },
    async updateScholarship () {
      if (!this.canUpdate) return false
      this.createButton.loading = true;
      this.newScholarship.userId = this.userId
      this.newScholarship.scholarshipDocs = this.docsAdded.map(item => {
        return {
          documentation: {
            id: item.id
          },
          fileName: null,
          from: null
        };
      });
      this.newScholarship.scholarshipProduct = []
      this.selectedBillingProducts.map(item => {
        const itemReturn = item.details.map(item2 => {
          const itemReturn2 = {
            billingProduct: {
              id: null
            },
            average: Number(item2.average),
            percentage:
              item.discountValue === this.$t('modules.scholarships.update.discountType.percentege') || item.discountValue === this.$t('modules.scholarships.update.discountType.percentege_lowercase')
                ? Number(item2.percentage ? item2.percentage : 0)
                : null,
            amount:
              item.discountValue === this.$t('modules.scholarships.update.discountType.amount') || item.discountValue === this.$t('modules.scholarships.update.discountType.amount_lowercase')
                ? Number(item2.amount ? item2.amount : 0)
                : null
          };
          itemReturn2.billingProduct.id = item.id;

          this.newScholarship.scholarshipProduct.push(itemReturn2);
          return itemReturn2;
        });
        item.details = itemReturn;

        return item.details;
      });
      this.newScholarship.limit = (this.limited === 1) ? this.limit : null
      try {
        await $scholarships.update(this.$route.params.id, this.newScholarship);
        this.successMessage.type = 'success';
        this.successMessage.title = `${
          this.newScholarship.description
          } ${this.$t(
            'modules.scholarships.update.success_message.description'
          )}`;
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = {
            text: this.$t(
              'modules.scholarships.update.success_message.actions.primary_text'
            ),
            callback: () => {
              this.$router.push('/scholarships');
            }
          };
          this.successMessage.actionSecondary = null;
        } else {
          this.successMessage.actionPrimary = {
            text: this.$t(
              'modules.scholarships.update.success_message.actions.primary_text'
            ),
            callback: () => {
              this.$emit('closeDrawer');
            }
          };
          this.successMessage.actionSecondary = null;
        }
        this.createNewScholarship = true;
        this.clearData();
      } catch (error) {
        // this.successMessage.type = "error";
        // this.successMessage.title = this.$t(
        //   "modules.scholarships.create.error_message.title"
        // );
        // this.successMessage.actionSecondary = null;
        // if (!this.insideDrawer) {
        // this.successMessage.type = 'error';
        // this.successMessage.title = this.$t("modules.scholarships.create.error_message.title");
        // this.successMessage.actionSecondary = null;
        // this.createNewScholarship = true;
        // this.createButton.loading = false;
        // this.createButton.error = true;
        this.createButton.error = true;

        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.scholarships.update.error_message.title', {
            action: 'crear'
          }),
          content: error.message,
          actionPrimary: {
            text: this.$t('modules.scholarships.update.errors.popup.btnPrimary'),
            callback () { }
          },
          actionSecondary: null,
          icon: { color: 'error', name: 'mdi-cancel' },
          color: 'primary'
        });

        // } else {
        //   this.successMessage.actionPrimary = {
        //     text: this.$t(
        //       "modules.scholarships.create.success_message.actions.primary_text"
        //     ),
        //     callback: () => {
        //       this.$emit("closeDrawer");
        //     }
        //   };
        // }
      } finally {
        this.createButton.loading = false;
        this.createButton.error = true;
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.limit = 1
      this.newScholarship.description = null
      this.newScholarship.code = null
      this.newScholarship.from = null
      this.newScholarship.status = true
      this.newScholarship.renew = true
      this.newScholarship.limit = 1
      this.newScholarship.scholarshipDocs = []
      this.newScholarship.scholarshipProduct = []
      // this.getBillingProducts()
      this.selectedBillingProducts = []
      this.docsAdded = []
      this.getScholarshipsDocs()
    }
  },
  validations: {
    newScholarship: {
      code: {
        required
      },
      description: {
        required
      },
      from: {
        required
      },
      status: {
        required
      },
      renew: {
        required
      },
      limit: {
        required
      },
      scholarshipDocs: {
        required
      },
      scholarshipProduct: {
        required
      }
    },
    limit: {
      decimal,
      minValue: minValue(0),
      required: requiredIf(function () {
        return this.limited
      })
    }
  }
};
</script>
