
const appsTranslations = {
    es_CL: {
        label: {
            instrucciones_app_mobile_1: 'En el campo escuela/distrito ingresar: "ipp"',
            instrucciones_app_mobile_2: 'Aparecerá una sección "ipp.instructure.com" pidiendo el usuario y contraseña:',
            instrucciones_app_mobile_3: 'En usuario ingresa tu RUT.',
            instrucciones_app_mobile_4: 'En contraseña ingresa tu RUT.',
            instrucciones_app_descarga: 'Pasos para descargarla en tu computador',
            instrucciones_app_descarga_6: 'Tip: conéctate a las charlas desde tu computador. Así podrás concentrarte y visualizar mejor lo que compartan los docentes.',
        },
        klarway:{
            klarway_empadronamiento_5: 'Luego del empadronamiento, descarga el programa en tu notebook o computador.',
        }       
    },
    es_PE: {
        label: {
            instrucciones_app_mobile_2: 'Aparecerá una sección "teclabperu.instructure.com" pidiendo el usuario y contraseña:',
        },
        klarway: {
            klarway_que_es_1: 'Es la modalidad que te permite realizar tus exámenes finales en remoto, desde donde estés.',
            klarway_empadronamiento: '¿Cuáles son los pasos a seguir para realizar el examen?',
            klarway_empadronamiento_5b: 'Recuerda que solo puedes realizar los exámenes desde la versión de escritorio siempre que hayas cumplido con el paso 1.',
        },
        sumadi: {
            sumadi_que_es_1: 'SUMADI es uno de los software que utilizarás para realizar exámenes finales.',
            sumadi_como_funciona_1: 'Solo tienes que descargarlo e instalarlo.Una vez listo, ingresas tu usuario y contraseña y podrás realizar tu examen.',
        }
    },
    es_MX: {
        label: {
            instrucciones_app_mobile_2: 'Aparecerá una sección "teclabperu.instructure.com" pidiendo el usuario y contraseña:',
        },
        klarway: {
            klarway_que_es_1: 'Es la modalidad que te permite realizar tus exámenes finales en remoto, desde donde estés.',
            klarway_empadronamiento: '¿Cuáles son los pasos a seguir para realizar el examen?',
            klarway_empadronamiento_5b: 'Recuerda que solo puedes realizar los exámenes desde la versión de escritorio siempre que hayas cumplido con el paso 1.',
        },
        sumadi: {
            sumadi_que_es_1: 'SUMADI es uno de los software que utilizarás para realizar exámenes finales.',
            sumadi_como_funciona_1: 'Solo tienes que descargarlo e instalarlo.Una vez listo, ingresas tu usuario y contraseña y podrás realizar tu examen.',
        }
    },
    es: {
        breadcrumbs: {
            title: 'Aplicaciones de Utilidad',
            description: 'Instructivo para la descarga de aplicaciones'
        },
        label: {
            canvas_studen_app: 'Canvas Student App',
            zoom: 'Zoom',
            instrucciones_app_descarga: 'Pasos para descargarla en tu computadora',
            instrucciones_zoom_1: 'Ingresa al',
            instrucciones_app_descarga_2: 'Haz click en “Regístrese, es gratuita”.',
            instrucciones_app_descarga_3: 'Ingresa tu fecha de nacimiento y dale a “Continuar”. ',
            instrucciones_app_descarga_4: 'Finalmente, escribe el correo que usas para estudiar y haz click en “Registrarse”.',
            instrucciones_app_descarga_5: 'Te llegará un mail de activación, ¡selecciona “Activar cuenta” y listo!',
            instrucciones_app_descarga_6: 'Tip: conéctate a las charlas desde tu computadora. Así podrás concentrarte y visualizar mejor lo que compartan los docentes.',
            instrucciones_app_mobile: 'Instrucciones App Mobile',
            instrucciones_app_mobile_1: 'En el campo escuela/distrito ingresar: "teclab"',
            instrucciones_app_mobile_2: 'Aparecerá una sección "teclab.instructure.com" pidiendo el usuario y contraseña:',
            instrucciones_app_mobile_3: 'En usuario ingresa tu DNI.',
            instrucciones_app_mobile_4: 'En contraseña ingresa tu DNI.',
        },
        klarway:{
            exams: 'Exámenes',
            klarway_que_es: '¿Qué es el Sistema de Exámenes 100% Online?',
            klarway_que_es_1: 'Es la modalidad que te permite rendir tus exámenes finales en remoto, desde donde estés.',
            klarway_como_funciona: '¿Cómo funciona?',
            klarway_como_funciona_1: 'Nuestro Sistema de Exámenes 100% Online se apoya en softwares de reconocimiento mundial, como',
            klarway_como_funciona_2: ' Klarway',
            klarway_empadronamiento: '¿Cuáles son los pasos a seguir para rendir el examen?',
            klarway_empadronamiento_2: 'Paso 1: Empadronamiento',
            klarway_instalacion: 'Instala Klarway en tu celular y haz el proceso de empadronamiento.',
            klarway_instalacion_4: 'VER INSTRUCTIVO',
            klarway_empadronamiento_4: 'Paso 2: Descarga la aplicación',
            klarway_empadronamiento_5: 'Luego del empadronamiento, descarga el programa en tu notebook o computadora.',
            klarway_empadronamiento_5b: 'Recuerda que solo puedes rendir desde la versión de escritorio siempre que hayas cumplido con el paso 1.',
            klarway_empadronamiento_6: 'Descarga el enlace que corresponda a tu sistema operativo:',
            klarway_empadronamiento_7: 'Si necesitas información adicional sobre el examen, revisa el siguiente instructivo, en el que se detalla el proceso de inscripción:',
        },
        zoom: {
            zoom_descarga: 'Sitio Web de Zoom'
        },
        sumadi:{
            sumadi_que_es: '¿Qué es SUMADI?',
            sumadi_que_es_1: 'SUMADI es uno de los software que utilizarás para rendir exámenes finales.',
            sumadi_instalacion: 'Instala SUMADI siguiendo estos pasos:',
            sumadi_como_funciona: '¿Cómo funciona?',
            sumadi_como_funciona_1: 'Solo tienes que descargarlo e instalarlo.Una vez listo, ingresas tu usuario y contraseña y podrás rendir tu examen.',
            sumadi_instalacion_1: 'Descarga el programa en tu notebook.',
            sumadi_instalacion_2: 'Sigue los pasos de instalación.',
            sumadi_instalacion_3: 'Cierra todos los programas abiertos.',
            sumadi_instalacion_4: 'Ingresa con tu usuario y contraseña del campus',
           
        }
    },
    en:  {
    }
};


export default appsTranslations