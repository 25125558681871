const moduleControlTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Control de módulos',
                description: 'Análisis de estado'
            },
            headers: {
                name: 'Nombre',
                description: 'Descripción',
                status:'Estado'
            },
            asidebar:{
                title: 'Módulos',
                academic: 'Académica',
                pricing: 'Administrador de Precios',
                student: 'Estudiantes',
                qSystem: 'Exámenes',
                canvas: 'Canvas'
            }
        },
    },
    en: {

    }
};

export default moduleControlTranslations;