<template>
  <div>
    <div v-if="loading" class="d-flex justify-center align-center mt-5">
      <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-for="(item, index) in items" :key="index"> 
      <v-hover v-slot:default="{ hover }">
        <v-card
          :class="['mx-auto card-outter', item.specialCard ? 'special-card' : 'transparent-card' , hover ? 'color-selected' : 'color-unselected']"
          elevation="0"
          @click="$emit('chipClick', {index, item})"
        >
          <v-tooltip top max-width="300px" color="rgba(0, 0, 0, 0.8)" v-if="item.description">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" three-line>
                <v-list-item-content class="py-5">
                  <v-row>
                    <v-col cols="12" class="mt-0 pt-0" v-if="showChips">
                      <div class="d-flex justify-end mt-0 pt-0">
                        <v-chip v-if="showChips" class="text-chip hidden-md-and-down"  rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                        <v-icon v-else :class="{'white--text': item.specialCard}">mdi-chevron-right</v-icon>
                      </div>
                    </v-col>
                    <v-col cols="12" class="d-flex py-0 ">
                      <div class="pl-0 pl-sm-3  container-text d-flex flex-column align-center align-sm-start justify-center " >
                        <v-list-item-title :class="['header-title mb-2  text-center text-sm-left align-self-center align-self-sm-start', item.specialCard ? 'white--text':'']">
                          <v-badge v-if="item.specialCard" color="red" content="1" class="ml-2 mr-6 "></v-badge>
                          {{ item.name }}
                        </v-list-item-title> 
                        <v-list-item-subtitle :class="['subtitle text-center text-sm-left', item.specialCard ? 'white--text':'']">{{ item.description }}</v-list-item-subtitle>
                        <v-chip v-if="showChips" class="text-chip hidden-lg-and-up mt-2 " x-small rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div class="text-left py-2">
              <span>{{ item.description }}</span>
            </div>
          </v-tooltip>
          <v-list-item three-line v-else>
            <v-list-item-content class="py-5">
              <v-row>
                <v-col cols="12" class="d-flex py-0">
                  <div class="mr-3" style="flex: 1">
                    <v-list-item-title :class="['header-title mb-2', item.specialCard ? 'white--text' : '']">
                      <v-badge v-if="item.specialCard" color="red" content="1" class="ml-2 mr-6"></v-badge>
                      {{ item.name }}
                    </v-list-item-title> 
                    <v-chip v-if="showChips" class="text-chip hidden-lg-only mt-2" x-small rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                  </div>
                  <div class="d-flex align-center">
                    <v-chip v-if="showChips" class="text-chip hidden-md-and-down" rounded :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
                    <v-icon v-else :class="{'white--text': item.specialCard}">mdi-chevron-right</v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-hover>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: [Array],
      showChips: Boolean,
      loading: Boolean,
    },
    data () {
      return {}
    },
    methods: {
      getColor(status) {
        for (let i = 0; i < this.items.length; i++) {
          if (status === this.items[i].status) return this.items[i].value;
        }
      },
    },
  }
</script>

<style lang="sass" scoped>

.subtitle
  font-size: 13px !important

.card-outter
  background-color: #eeeeee
  border-radius: 10px !important

.header-title
  font-size: 15px !important
  font-family: Roboto !important
  font-weight: bold !important
  font-style: normal
  text-align: left
  color: #274280

.transparent-card
  background-color: transparent
  margin: 10px
  border-radius: 10px
  
  &:hover
    background-color: white

.special-card
  background-color: #5cb3ff
  color: white
  margin: 10px
  border-radius: 10px
  
  &:hover
    background-color: #0074d9

.text-chip
  font-size: 12px !important
.container-text 
    width: 100%  

</style>