<template>
  <v-container class="pa-0 pb-8 Studentusers" fluid>
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.users.details.breadcrumbs.title')"
        :description="$t('modules.users.details.breadcrumbs.description')">
      </Breadcrumbs>
      
      <!-- EXPANSION1 -->
      <v-row class="mb-10">
        <v-col cols="8">
          <!--  Datos personales  -->
          <v-expansion-panels multiple :value="[0]" class="mb-6" v-if="userDetails">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 pl-8 pr-2">
                <v-row class="pt-5 pb-5">
                  <v-col sm="6">
                    <h2 class="title primary--text">{{$t('modules.users.details.dataSection.title')}}</h2>
                  </v-col>
                  <v-col sm="6" class="text-right">
                    <v-btn @click="tmpDrawerOpen" rounded color="primary" class="mr-3" outlined small>{{ $t('modules.users.details.dataSection.buttonEdit') }}</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-3">
                <v-row class="px-2">
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.name') }}</h3>
                    <h2 class="body-2">{{ userDetails.name ? userDetails.name : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.lastname') }}</h3>
                    <h2 class="body-2">{{ userDetails.lastname ? userDetails.lastname : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.birthDate') }}</h3>
                    <h2 class="body-2">{{ userDetails.birthDate ? userDetails.birthDate : $t('modules.users.noData') }}</h2>
                  </v-col>
                  
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.docType') }}</h3>
                    <h2 class="body-2">{{ userDetails.identificationType ? userDetails.identificationType.meaning : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.docNumber') }}</h3>
                    <h2 class="body-2">{{ userDetails.identification ? userDetails.identification : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <template v-if="$isIPP">
                    <v-col sm="4">
                      <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.serialNumber') }}</h3>
                      <h2 class="body-2">{{ userDetails.serialNumber ? userDetails.serialNumber : $t('modules.users.noData') }}</h2>
                    </v-col>
                  </template>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.gender') }}</h3>
                    <h2 class="body-2">{{ userDetails.gender ? userDetails.gender.meaning : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.maritalStatus') }}</h3>
                    <h2 class="body-2">{{ userDetails.maritalStatus ? userDetails.maritalStatus.meaning : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.cellphone') }}</h3>
                    <h2 class="body-2">{{ userDetails.cellphone ? userDetails.cellphone : $t('modules.users.noData')}}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.birthPlace') }}</h3>
                    <h2 class="body-2">{{ userDetails.birthPlace && userDetails.birthPlace.cityState ? userDetails.birthPlace.cityState : $t('modules.users.noData')}}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.nationality') }}</h3>
                    <h2 class="body-2">{{ userDetails.nationality ? userDetails.nationality.demonym : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.phone') }}</h3>
                    <h2 class="body-2">{{ userDetails.phone ? userDetails.phone : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.city') }}</h3>
                    <h2 class="body-2">{{ userDetails.address && userDetails.address.cityWithState.cityState ? userDetails.address.cityWithState.cityState : $t('modules.users.noData')}}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.street') }}</h3>
                    <h2 class="body-2">{{ street }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.zipCode') }}</h3>
                    <h2 class="body-2">{{ userDetails.address && userDetails.address.zipCode ? userDetails.address.zipCode : $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.previousStudiesLevel') }}</h3>
                    <h2 class="body-2">{{ student.level && student.level.meaning ? student.level.meaning : $t('modules.users.noData')}}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.previousStudiesInstitutionCity') }}</h3>
                    <h2 class="body-2">{{
                      student.institution && student.institution.city ? student.institution.city : 
                      student.institution && student.institution.region ? student.institution.region : $t('modules.users.noData')}}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.previousStudiesInstitutionName') }}</h3>
                    <h2 class="body-2">{{ student.institution && student.institution.name ? student.institution.name : $t('modules.users.noData')}}</h2>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!--  Datos Laborales  -->
          <v-expansion-panels multiple :value="[0]" class="mb-6" v-if="workDetails">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 pl-8 pr-2">
                <v-row class="pt-5 pb-5">
                  <v-col sm="6">
                    <h2 class="title primary--text">{{$t('modules.users.details.workSection.title')}}</h2>
                  </v-col>
                  <v-col sm="6" class="text-right">
                    <v-btn @click="tmpWorkDrawerOpen" rounded color="primary" class="mr-3" outlined small>{{ $t('actions.edit') }}</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-3">
                <v-row class="px-2">
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.workingStatus') }}</h3>
                    <h2 class="body-2">{{ (workDetails.workStatus && workDetails.workStatus.meaning) || $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.workersQuantity') }}</h3>
                    <h2 class="body-2">{{ (workDetails.workersQuantity && workDetails.workersQuantity.meaning) || $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.companyName') }}</h3>
                    <h2 class="body-2">{{ workDetails.companyName || $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.bossName') }}</h3>
                    <h2 class="body-2">{{ workDetails.bossName || $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.bossEmail') }}</h3>
                    <h2 class="body-2">{{ workDetails.bossEmail || $t('modules.users.noData') }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.users.details.workSection.bossTelephone') }}</h3>
                    <h2 class="body-2">{{ workDetails.bossCelNumber || $t('modules.users.noData') }}</h2>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Carreras -->
          <v-expansion-panels class="mb-6" multiple v-for="item in careerList" :key="item.career.id">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 pl-8 pr-2">
                <v-row class="py-3">
                  <v-col sm="7">
                    <h2 class="title primary--text">{{ item.career.description }}</h2>
                    <h3 class="caption font-weight-bold">{{ $t('modules.users.details.historySection.studentCareer') }}&nbsp;{{ item.id ? item.id : '-' }}</h3>
                    <h3 class="caption font-weight-bold">{{ $t('modules.users.details.historySection.studyPlan') }}&nbsp;{{ item.career.studyPlan ? item.career.studyPlan.description : '-' }}</h3>
                  </v-col>
                  <v-col sm="5" class="d-flex align-center justify-end">
                      <div class="career-status d-flex align-center justify-end mr-3" :class="getColor(item)">
                          <div class="icon-container mr-2">
                            <v-icon>{{ getIcon(item) }}</v-icon>
                          </div>
                          <h4 class="primary--text">{{ getText(item) }}</h4>
                      </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-3">
                <v-row>
                  <v-col sm="4">
                      <v-card class="mx-auto" outlined>
                        <v-list-item three-line>
                          <div class="icon-calendar pa-3 mr-2"><v-icon color="primary">mdi-calendar-range</v-icon></div>
                          <div>
                            <div class="caption text-muted font-weight-bold">{{ $t('modules.users.details.historySection.initialTerm') }}</div>
                            <h3 class="subtitle primary--text" :class="item.periodCode ? '' : 'text-center'">{{ item.periodCode ? item.periodCode : '-' }}</h3>
                          </div>
                        </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col sm="4">
                      <v-card class="mx-auto" outlined>
                        <v-list-item three-line>
                          <div class="icon-calendar pa-3 mr-2"><v-icon color="primary">mdi-calendar-today</v-icon></div>
                          <div>
                            <div class="caption text-muted font-weight-bold">{{$t('modules.users.details.historySection.startDate')}}</div>
                            <h3 class="subtitle primary--text" :class="item.classStartDate ? '': 'text-center'">{{ item.classStartDate ? item.classStartDate  : '-' }}</h3>
                          </div>
                        </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col sm="4">
                      <v-card class="mx-auto" outlined>
                        <v-list-item three-line>
                          <div class="icon-calendar pa-3 mr-2"><v-icon color="primary">mdi-calendar</v-icon></div>
                          <div>
                            <div class="caption text-muted font-weight-bold">{{$t('modules.users.details.historySection.degreeDate')}}</div>
                            <h3 class="subtitle primary--text" :class="item.graduationDate ? '': 'text-center'">{{ item.degreeDate ? formDate(item.degreeDate) : '-' }}</h3>
                          </div>
                        </v-list-item>
                    </v-card>
                  </v-col>
                  <v-col sm="4">
                      <v-card class="mx-auto" outlined>
                        <v-list-item three-line>
                          <div class="icon-calendar pa-3 mr-2"><v-icon color="primary">mdi-calendar</v-icon></div>
                          <div>
                            <div class="caption text-muted font-weight-bold">{{$t('modules.users.details.historySection.graduationDate')}}</div>
                            <h3 class="subtitle primary--text" :class="item.graduationDate ? '': 'text-center'">{{ item.graduationDate ? formDate(item.graduationDate) : '-' }}</h3>
                          </div>
                        </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="12" v-if="benefitsDescription">
                    <h3 class="title primary--text mb-3">{{ $t('modules.users.details.historySection.benefitsTitle') }}</h3>
                    <p class="primary--text ml-5 mb-0">{{ benefitsDescription }}</p>
                  </v-col>
                  <v-col sm="12" v-if="scholarshipDescription">
                    <h3 class="title primary--text mb-3">{{ $t('modules.users.details.historySection.scholarshipsTitle') }}</h3>
                    <p class="primary--text ml-5 mb-0">{{ scholarshipDescription }}</p>
                  </v-col>
                  <v-col sm="12" class="mt-3">
                    <span class="primary--text font-weight-bold">{{ `${$t('modules.users.details.historySection.modality')}: ` }}</span>
                    <span>{{ item.oldModality ? '2019' : '2020' }}</span>
                  </v-col>
                  <v-col col="12" class="mt-3" v-if="item.contractDTO && item.contractDTO.length">
                    <h3 class="title primary--text mb-3">{{ $t('modules.users.details.historySection.contractTitle') }}</h3>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.sign_date') }}</th>
                            <th class="text-center primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.period') }}</th>
                            <th class="text-center primary--text font-weight-bold">{{ $t('modules.users.details.dataSection.download') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="contract in item.contractDTO" :key="contract.id">
                            <td class="text-center">{{ contract.signatureDate }}</td>
                            <td class="text-center">{{ contract.termCode }}</td>
                            <td class="text-center">
                              <v-btn v-if="contract.download" color="primary" rounded icon @click="downloadContract(contract.id)">
                                <v-icon>mdi-cloud-download</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Documentation -->
          <v-expansion-panels class="mb-6" v-if="documents.length">
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 pl-8 pr-2">
                <v-row class="pt-5 pb-5">
                  <v-col sm="12">
                    <h2 class="title primary--text">{{ $t('modules.users.details.summarySection.obligatoryDocumentation') }}</h2>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-3">
                <v-row class="px-2">
                  <v-col cols="12" sm="12">
                    <v-list class="py-0">
                      <v-list-item two-line v-for="(doc, index) in documents" :key="index">
                        <v-btn text icon color="primary" @click="downloadDoc(doc.name, doc.id)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <v-list-item-avatar tile class="mx-3">
                          <img :src="require(`@/assets/icons/pdf.svg`)">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title><strong>{{ doc.documentation ? doc.documentation.name : 'Documento' }}</strong></v-list-item-title>
                          <v-list-item-subtitle>{{ doc.name ? doc.name : '' }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
        </v-col>
        <v-col cols="4" v-if="userDetails">
          <v-card class="mx-auto px-6 py-4">
            <div class="text-center pa-6">
              <v-avatar size="160">
                <v-img :src="userDetails.avatar ? userDetails.avatar : ''"></v-img>
              </v-avatar>
            </div>
            <h2 class="text-center primary--text">{{ userDetails.name }}&nbsp;{{ userDetails.lastname }}</h2>
            
            <v-divider class="my-6"></v-divider>

            <h3 class="subtitle-1 primary--text mb-2">{{ $t('modules.users.details.summarySection.personalInfo') }}</h3>
            <div class="caption">{{ userDetails.identificationType.meaning }}</div>
            <h3 class="caption font-weight-bold mb-2">{{ userDetails.identification }}</h3>
            <div class="caption">{{ $t('modules.users.details.summarySection.email') }}</div>
            <h3 class="caption font-weight-bold mb-2">{{ userDetails.email }}</h3>

            <Button
              :text="$t('modules.users.details.summarySection.resetPassword')"
              class="d-flex mx-auto mt-6"
              outlined
              @clicked="warningChangePassword"
              :loading="passwordButton.loading"
              :success="passwordButton.success"
              :error="passwordButton.error"
              :successText="$t('actions.success')"
              :errorText="$t('actions.error')"
              @end="resetButton"
            ></Button>

            <v-divider class="my-6"></v-divider>

            <div class="d-flex justify-space-between">
              <div class="caption font-weight-bold">{{ $t('modules.users.details.summarySection.career') }}</div>
              <div class="success--text font-weight-bold">{{ $t('modules.users.details.summarySection.course') }}</div>
            </div>
            <div v-for="(item, index) in careerListInCourse" :key="index">
              <h4 class="primary--text">{{ item.career.description }}</h4>
              <p class="caption">{{ item.id }}</p>
            </div>

            <v-divider class="my-6"></v-divider>

            <div class="caption font-weight-bold">{{ $t('modules.users.details.summarySection.obligatoryDocumentation') }}</div>
            <div class="mb-6">
              <h4 class="mt-1 primary--text" v-if="documents.length">{{ documents.length }}&nbsp;{{ documents.length > 1 ? $t('modules.users.details.summarySection.uploadedDocuments') : $t('modules.users.details.summarySection.uploadedDocument') }}</h4>
              <h4 class="mt-1 primary--text" v-else>{{ $t('modules.users.details.summarySection.noDocuments') }}</h4>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <TemporaryRightDrawer
        @tmpDrawerOpen="tmpDrawerOpen"
        :open="tmpDrawer.open"
        :title="tmpDrawer.title"
        :description="tmpDrawer.description"
      >
        <UsersUpdate
          ref="usersUpdate"
          @closeDrawer="onCloseDrawer"
          insideDrawer
        ></UsersUpdate>
      </TemporaryRightDrawer>
      <TemporaryRightDrawer
        @tmpDrawerOpen="tmpWorkDrawerOpen"
        :open="tmpWorkDrawer.open"
        :title="tmpWorkDrawer.title"
        :description="tmpWorkDrawer.description"
      >
        <UsersWorkForm
          :student-id="student ? student.id : null"
          :work-details="workDetails"
          @closeDrawer="onCloseWorkDrawer"
        ></UsersWorkForm>
      </TemporaryRightDrawer>
    </v-container>
  </v-container>
</template>

<script>
import { $student, $students, $studentProcessing, $users, $work } from '../services'
import download from 'downloadjs'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import UsersUpdate from './UsersUpdate'
import Button from '@/components/Button/Button'
import UsersWorkForm from '../Components/UsersWorkForm/UsersWorkForm';
import moment from 'moment'

export default {
  name: 'UsersDetails',
  components: {
    Breadcrumbs,
    TemporaryRightDrawer,
    UsersUpdate,
    UsersWorkForm,
    Button
  },
  data() {
    return {
      passwordButton: {
        loading: false,
        success: false,
        error: false,
      },
      tmpDrawer: {
        open: false,
        title: this.$t('modules.users.update.breadcrumbs.title'),
        description: this.$t('modules.users.update.breadcrumbs.description')
      },
      tmpWorkDrawer: {
        open: false,
        title: this.$t('modules.users.details.workSection.drawer.title'),
        description: this.$t('modules.users.details.workSection.drawer.description')
      },
      student: null,
      userDetails: null,
      workDetails: null,
      careerList: [],
      scholarshipsList: [],
      scholarshipDescription: '',
      benefitsList: [],
      benefitsDescription: '',
      documents: [],
      loading: false,
      panelDocument: []
    };
  },
  computed : {
    street() {
      if (!this.userDetails.address) return ''
      const street = this.userDetails.address.street ? this.userDetails.address.street : ''
      const number = this.userDetails.address.number ? ` ${this.userDetails.address.number}` : ''
      const floor = this.userDetails.address.floor ? ` ${this.userDetails.address.floor}` : ''
      const apartment = this.userDetails.address.apartment ? ` ${this.userDetails.address.apartment}` : ''
      return `${street}${number}${floor}${apartment}`
    },
    careerListInCourse() {
      return this.careerList.filter(career => career.status.value == 54)
    }
  },
  methods: {
    resetButton() {
      this.passwordButton.loading = false
      this.passwordButton.success = false
      this.passwordButton.error = false
    },
    async downloadContract(id) {
      const response = await $students.find(`getStudentContract/${id}`, null, {responseType: 'blob'})
      const content = response.headers['content-type'];
      download(response.data, `Contrato RUT ${this.userDetails.identification}.pdf`, content)
    },
    async changePassword () {
      try {
        this.passwordButton.loading = true
        const newPass = await $users.updatePassword(this.$route.params.id, 'new-password')
        this.passwordButton.success = true
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.details.changePassword.success', {newPass: newPass.data.newPassword}),
          content:'',
          actionPrimary: {text: this.$t('actions.accept'), callback() {}},
          icon: {color: 'success', name: 'mdi-check'},
          color: 'primary'
        })
      } catch {
        this.passwordButton.error = true
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.details.changePassword.error'),
          content:'',
          actionPrimary: {text: this.$t('actions.accept'), callback() {}},
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary'
        })
      } finally {
        this.passwordButton.loading = false
      }
    },
    warningChangePassword(){
     this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.users.update.sectionContact.fields.resetPasswordWarning'),
        content: '',
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.changePassword()},
        actionSecondary: {text: this.$t('actions.cancel'), callback () {}},
        icon: {color: 'warning', name: 'mdi-alert'},
        color: 'primary',
      })
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
      if (!this.tmpDrawer.open) {
        this.$refs.usersUpdate.fetchData()
        this.getUsersDetails()
      }
    },
    tmpWorkDrawerOpen() {
      this.tmpWorkDrawer.open = !this.tmpWorkDrawer.open;
      if (!this.tmpWorkDrawer.open) {
        this.getWorkDetails()
      }
    },
    onCloseDrawer(){
      this.tmpDrawer.open = !this.tmpDrawer.open;
      this.getUsersDetails()
      this.$refs.usersUpdate.fetchData()
    },
    onCloseWorkDrawer(){
      this.tmpWorkDrawer.open = !this.tmpWorkDrawer.open;
      this.getWorkDetails()
    },
    async downloadDoc(name, id){
      const response = await $studentProcessing.getDocument(id, {responseType: 'blob'})
      const content = response.headers['content-type'];
      download(response.data, name, content)
    },
    getColor(item) {
      switch (item.status.value) {
        case 51:
          return  'status-preinscripted'
        case 54:
          return 'status-course'
        case 55:
          return 'status-graduate'
        case 56:
          return 'status-freeze'
        case 57:
        case 181:
        case 182:
          return  'status-anulated'
        case 145:
          return  'status-certificated'
        default:
          return ''
      }
    },
    getIcon(item) {
      switch (item.status.value) {
        case 51:
          return  'mdi-account-card-details-outline'
        case 54:
          return 'mdi-account-check-outline'
        case 55:
          return 'mdi-account-star-outline'
        case 56:
          return 'mdi-account-lock-outline'
        case 57:
        case 181:
        case 182:
          return  'mdi-account-off-outline'
        case 145:
          return  'mdi-school-outline'
        default:
          return ''
      }
    },
    getText(item) {
      switch (item.status.value) {
        case 51:
          return  this.$t('modules.users.details.status.preinscriptedEquivalent')
        case 54:
          return this.$t('modules.users.details.status.curseEquivalent')
        case 55:
          return this.$t('modules.users.details.status.graduateEquivalent')
        case 56:
          return this.$t('modules.users.details.status.freezeEquivalent')
        case 57:
          return this.$t('modules.users.details.status.freeze')
          // return  this.$t('modules.users.details.status.anulatedEquivalent')
        case 145:
          return this.$t('modules.users.details.status.certificatedEquivalent')
        case 181:
          return this.$t('modules.users.details.status.deleted_no_subject')
        case 182:
          return this.$t('modules.users.details.status.deleted_no_enrollment')
        default:
          return ''
      }
    },
    async getUsersDetails() {
      this.loading = true
      const resp = await $student.find(`detail/${this.$route.params.id}`)
      this.student = resp.data.student
      this.userDetails = resp.data.student.user.userData
      this.scholarshipsList = resp.data.scholarship ? resp.data.scholarship.documentations : []  
      this.scholarshipDescription = resp.data.scholarship ? resp.data.scholarship.description : null
      this.benefitsList = resp.data.benefit ? resp.data.benefit.documentations : []
      this.benefitsDescription = resp.data.benefit ? `${resp.data.benefit.description} - ${this.getBenefitPercentage(resp.data.benefit.benefitDetails)}` : null

      if (resp.data.obligatoryDocumentation) resp.data.obligatoryDocumentation.forEach(doc => { this.documents.push(doc) });
      if (resp.data.benefit && resp.data.benefit.documentations) resp.data.benefit.documentations.forEach(doc => { this.documents.push(doc) });
      if (resp.data.scholarship && resp.data.scholarship.documentations) resp.data.scholarship.documentations.forEach(doc => { this.documents.push(doc) });
      this.userDetails.avatar =  `${$users.baseUrl}/users/${resp.data.student.user.id}/avatar`
      this.careerList = resp.data.careerList
      this.loading = false
    },
    async getWorkDetails() {
      this.loading = true
      try {
        const {data} = await $work.find(null, null, { params: { studentId: this.student.id } })
        this.workDetails = data
      } catch(err) {
        this.workDetails = null
        throw err
      } finally {
        this.loading = false
      }
    },
    getBenefitPercentage(details) {
      if (details?.length) {
        const detail = details[0] // eslint-disable-line
        const product = detail.billingProduct == 81 ? 'Arancel' : 'Matricula'
        const amount = detail.amount ? this.$options.filters.$currency(detail.amount) : `${detail.percentage}%`
        return `${product} ${amount}`
      }
    },
    formDate(date){
      return moment(date).format('YYYY-MM-DD')
    }
  },
  async mounted() {
    await this.getUsersDetails();
    this.getWorkDetails();
  }
};
</script>

<style lang="sass" scoped>
  .subtitle-1
    font-size: 1rem !important

  .v-expansion-panel-header
    background-color: #eaf0ff

  .text-muted 
    color: #999 !important

  .icon-calendar
    border-radius: 50%
    justify-content: center
    align-items: center
    background-color: #eaf0ff

  .text-underline
    text-decoration: underline

  .icon-image
    position: relative
    top: 3px
    margin-left:5px
    margin-rigth: 5px

  .download-card
    cursor: pointer

  .career-status

    .icon-container
      padding: 5px
      border-radius: 50%
      background: grey
      
      .v-icon
        padding: 3px
        border-radius: 50%
        color: white
        font-size: 17px

    &.status-preinscripted
      .icon-container
        background: #deeeed
      .v-icon
        background: #FFA000

    &.status-course
      .icon-container
        background: #dae2f4
      .v-icon
        background: #00AF23

    &.status-freeze        
      .icon-container
        background: #dae2f4
      .v-icon
        background: #D3D3D3

    &.status-anulated        
      .icon-container
        background: #deeeed
      .v-icon
        background: #FF0000

    &.status-graduate        
      .icon-container
        background: #deeeed
      .v-icon
        background: #3B77FF
    
    &.status-certificated        
      .icon-container
        background: #deeeed
      .v-icon
        background: var(--v-primary-base)

</style>
