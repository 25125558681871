<template>
<div>
  <VCard  outlined :class="`StudyPlan__card cursor-grab text-left py-2 px-3 mt-2 ${item['opcional'] ? 'red lighten-5' : haveCorrelatives ? 'indigo lighten-4' : 'grey lighten-5'} `" >
    <div class="caption StudyPlan__card__title">{{item["code"]}} - {{item["description"]}}</div>
    <v-menu top :offset-x="true"  :close-on-content-click="true">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text icon class="StudyPlan__card__button">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
        >
          Opcional <v-switch color="primary" v-model="item['opcional']"></v-switch>
        </v-list-item>

        <v-list-item
          @click="openCorrelativesPopUp"
          
        >
          Prerrequisitos
        </v-list-item>
      </v-list>
    </v-menu>
  </VCard>
</div>
</template>

<script>
export default {
  name: 'SubjectsItem',
  props:{
    item: Object
  },
  methods:{
    openCorrelativesPopUp(){
      this.$emit('open')
    },
  },
  computed:{
    haveCorrelatives(){
      return this.item?.correlatives?.length > 0;
    }
  }
}
</script>

<style lang="sass" scoped>
.StudyPlan
  &__card
    box-shadow:  0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06) !important
    &:hover, &:active
      z-index: 2
      box-shadow: 0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
    &__title
      padding-right: 2rem
    &__button
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
</style>