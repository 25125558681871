<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="clearData"
          @keyup.enter="searchAction"
          @keyup.esc="() => {this.search = ''; this.clearData()}"
        ></v-text-field>
      </v-col>
      <v-col sm="4">
        <Button
          class="ml-4"
          @clicked="searchAction"
          outlined
          depressed
          :text="$t('actions.search')"
        ></Button>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          show-select
          :multi-sort="false"
          :headers="headers"
          :items="users"
          :totalItems="totalItems"
          :page.sync="page"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)" 
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)" 
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="userId"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:top v-if="permissions.delete">
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                <span
                  v-if="permissions.delete"
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  @click="$emit('bulk-delete', selectedItems)"
                >
                  <v-icon size="22" class="mr-2">mdi-delete</v-icon>{{ deleteTitle }}
                </span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">
              <v-avatar size="32" class="mr-4">
                <img :src="item.avatar" />
              </v-avatar>
              <div>{{ item.name }} {{ item.lastname }}</div>
            </div>
          </template>

          <template v-slot:[`item.roles`]="{item}">
              <v-row v-for="role in item.roles" v-bind:key="role.id">
                  <v-col sm="12" class="py-0">
                      {{ role.name }}
                  </v-col>
              </v-row>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.ceos`]="{item}">
              <v-row v-for="ceo in item.ceos" v-bind:key="ceo.id">
                  <v-col sm="12" class="py-0">
                      {{ ceo.description }}
                  </v-col>
              </v-row>
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-menu bottom left>
              <template v-slot:activator="{ on }" v-if="permissions.read || permissions.update || permissions.delete">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.read && isAlumno(item.roles)" @click="openDetails(item.userId)">
                  <v-list-item-title>
                    <v-icon>mdi-eye</v-icon> {{$t('modules.users.table.details')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="editItem(item.userId)" v-if="permissions.update">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item.userId)" v-if="permissions.delete">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon> {{ $t('actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('lengthPaginationChange', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model.number="goToPage"
          max="pageCount"
          outlined
          @blur="changePage"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass">
    .superTable
        &__table
            &__item
                *
                    font-size: 14px !important
                &__checkbox
                    .v-icon
                        opacity: 0.1
                        transition: opacity 200ms ease
                        font-size: 20px !important
                        &.mdi-checkbox-marked
                            opacity: 1
                &__name
                    display: flex
                    align-items: center
                    font-weight: 500 !important
                &__action
                    opacity: 0.1
                    transition: opacity 200ms ease
                    font-size: 20px !important
                    &.mdi-vertical-dots-marked
                        opacity: 1
                    & + .v-menu__content
                        border-radius: 2px
                        border: 1px solid red
            tbody tr:hover
                .superTable__table__item__checkbox .v-icon, .superTable__table__item__action
                    opacity: 1
            .v-data-table-header
                .superTable__table__item__checkbox:hover
                    .v-icon
                        opacity: 1
            .v-menu.v-menu--inline
                display: none !important
        &__search
            .v-input
                &__slot
                    border-radius: 24px !important
                    padding: 0 24px !important
                &__prepend-inner
                    margin-top: 10px !important
            .v-text-field__details
                display: none !important
        &__pagination
            .v-pagination__navigation, .v-pagination__item
                border-radius: 50%
                outline: 0
                box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 1px 5px 0px rgba(0, 0, 0, 0.1)
        &__paginationSelect
            min-width: 20px
            width: min-content
            .v-input
                &__slot
                    min-height: 30px !important
                    padding: 0 0 0 10px !important
                    margin-bottom: 0 !important
                &__append-inner
                    padding: 0 !important
            .v-select
                &__selections
                    padding: 0 !important
            .v-text-field__details
                display: none
        &__paginationInput
            min-width: 45px
            width: min-content
            .v-input
                &__slot
                    min-height: 30px !important
                    margin-bottom: 0 !important
            .v-text-field__details
                display: none
        &__bulkActions
            &.show
                animation-fill-mode: forwards
                animation-name: smoothShow
                animation-duration: 1s
                animation-timing-function: ease-in
            &.hide
                animation-fill-mode: forwards
                animation-name: smoothHide
                animation-duration: 1s
                animation-timing-function: ease-in
                div
                    transform: scale(0)
                    transition: transform 1s step-end

    @keyframes smoothHide
        0%
            opacity: 1
            height: 60px
        15%
            opacity: 0
        50%
            height: 0
        100%
            height: 0
            opacity: 0

    @keyframes smoothShow
        0%
            opacity: 0
            height: 0
        15%
            opacity: 0
        50%
            height: 60px
        100%
            height: 60px
            opacity: 1
</style>

<script>
import Button from '@/components/Button/Button';
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTable',
  components: {
    Button
  },
  props: {
    users: Array,
    headers: Array,
    query: String,
    deleteTitle: String,
    permissions: {
      read: false,
      delete: false,
      update: false,
    },
    pageCount: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    },
    sortBy: String,
    sortDesc: Boolean,
    totalItems: Number
  },
  data () {    return {
      selectedItems: [],
      search: '',
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50],
      markedUsers: [],
      goToPage: 1,
    }  },
  methods: {
    isAlumno(roles) {
      return roles.find(role => role.name === 'Alumno')
    },
    getAvatar (id) {
      return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
    },
    editItem (id) {
      this.$emit('updateUser', id);
    },
    deleteItem (id) {
      this.$emit('deleteUser', id);
    },
    openDetails(id) {
      this.$emit('openDetails', id);
    },
    searchAction() {
      if (this.search !== '') {
        this.$emit('searchAction', this.search);
      }
    },
    clearData() {
      this.$emit('searchAction', null);
    },
    changePage() {
      if (this.goToPage > this.pageCount) {
        this.goToPage = this.page;
      } else {
        this.$emit('inputPagination', this.goToPage)
      }
    }
  },
  watch: {
    page (val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    'query': function (val) {
      if (val) this.search = val;
    },
  },
  computed: {
    areItemsSelected () {
      return !!this.selectedItems.length
    },
    itemsCount () {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems
      }

      return counter;
    }
  },
};
</script>
