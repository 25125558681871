<template>
    <MultipleFormGroup
    :items="details.$each.$iter"
    :length="details.$model.length"
    @removeGroup="removeGroup"
    @addGroup="addGroup"
    >
        <template  v-slot:default="{item}">
            <v-container>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-select 
                                :label="$t('modules.pricing.billing.detailCard.labelConcept')"
                                 :items="billingProducts"
                                 item-text="billingProducts.name"
                                 outlined
                                item-value="billingProducts.name"
                                v-model="item.description.$model"
                                class="no-message"
                                :error-messages="(item.description.$dirty && item.description.$invalid) ?
                              ((!item.description.required) ? $t('modules.messaging.banners.validations.buttonTextRequired') :'') : ''"
                              @blur="item.price.$touch()"
                        ></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field 
                                :label="$t('modules.pricing.billing.detailCard.labelPrice')"
                                outlined
                                type="text"
                                v-model.trim="item.price.$model"
                                class="no-message"
                                 :error-messages="(item.price.$dirty && item.price.$invalid) ?
                                ((!item.price.required) ? $t('modules.messaging.banners.validations.buttonTextRequired') :'') : ''"
                                @blur="item.price.$touch()"
                        ></v-text-field>
                    </v-col>
                 </v-row>          
            </v-container>
        </template>
    </MultipleFormGroup>
  
</template>

<script>
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup.vue'

    export default {
        name: 'DetailCard',
        components:{
            MultipleFormGroup
        },
        props:['details', 'billingProducts'],
        data() {
            return {
                
            }
        },
        methods: {
            addGroup() {
                const mockup =
                    {
                    description:null,
                    price:null
                    };
                    this.$emit('addGroup', mockup);
               
            },
            removeGroup(index) {
                this.$emit('removeGroup', index)
            },
             productsAvailable (idSelected) {
                const products = this.billingProducts.filter((product)=>{
                    let found = false;
                    this.academic.$model.groups.forEach((group)=>{
                        if (group.selectedProduct && group.selectedProduct.name === product.name && group.selectedProduct.id !== idSelected){
                            found = true;
                            return null;
                        }
                    });
                    if (found) return null
                    else return product
                })
                return products
            },
        },
    }
</script>

<style lang="sass" scoped>

</style>