const examsTranslations = {
    es: {
        breadcrumbs: {
            title: 'Exámenes',
        },
        stepperHeader: {
            boards: 'Mesas',
            schedules: 'Horarios',
            subjects: 'Materias'
        },
        dateStep: {
            title: '¿Quieres inscribirte a un examen?',
            subtitle: 'Primero selecciona una fecha disponible',
            requestList: {
                title: 'Inscripciones',
                inscriptionList: {
                  emptyListMessage: 'Todavía no te has inscrito en ningún examen'
                },
                error_message: {
                    title: 'Ocurrió un error al obtener las inscripciones a examenes'
                },
                attempts:{
                    text_1: 'Llevas',
                    text_2: ' intento(s)',
                },
                pendingPayment: {
                  title: 'Pendiente de pago',
                  subtitle: 'Se anulará tu inscripción si no realizas el pago antes de {pendingPaymentDate}'
                }
            },
            error_message: {
                title: 'Ocurrió un error al obtener los horarios disponibles'
            },
            calendarCaption: {
                selectedDate: 'Fecha seleccionada',
                notAvailableDate: 'Fecha no disponible'
            }
        },
        scheduleStep: {
            title: 'Estos son los horarios disponibles para rendir el',
            card: {
              schedule: 'Horario',
              availableSpots: 'Lugares disponibles'
            },
            error_message: {
                title: 'Ocurrió un error al obtener las materias disponibles',
                examLimitReached:'Has alcanzado el máximo de inscripciónes por día'
            },
        },
        subjectStep: {
            title: 'Turno de examen',
            card: {
              attempts: 'Lleva {attemptsNumber} intento(s)'
            },
            subtitle: 'Estas son las materias disponibles para rendir tu examen final',
            emptyListMessage: 'No tiene materias disponibles para rendir'
        },
        notification_proctoring:{
            text_1: 'La herramienta para rendir tu examen final es ',
            text_2: 'Instalá la aplicación en tu computadora hoy y dejá todo listo.',
            text_3: '¡Importante!',
            text_4: 'Si es la primera vez que la usás, debés hacer tu proceso de empadronamiento.',
            text_5: 'Encontrá todas las instrucciones en ',
            text_6: 'Apps',
        },
        create: {
            attempts_message: {
                title: 'Se agotaron los 3 intentos para rendir esta materia',
                subtitle1: 'A partir del 4to intento, debes abonar un derecho de examen',
                subtitle2: 'Si aceptas podrás pagar y presentarte a rendir el día y hora que seleccionaste'
            },
            success_message: {
                title: '¡Felicitaciones!',
                subtitle: 'Te inscribiste para rendir el examen final de',
                pendingPayment: {
                  title: 'Se generó tu pedido de examen',
                  subtitle: 'Ahora te redireccionaremos a Pagos para que puedas completar el proceso',
                  goToPayments: 'Ir a Pagos'
                }
            },
            error_message: {
                title: 'Se produjo un error al realizar el pedido de examen',
                E203: 'Ya se encuentra inscripto a este examen'
            },
        },
        delete: {
            confirmation_delete: {
                title: '¿Esta seguro de anular?',
                title_exam_request: 'Si anula el pedido le contará como un intento ya que está dentro de los 5 días previos al examen ¿Está seguro que desea anular?',
            },
            error_message: {
                title: 'No se pudo eliminar el pedido de examen'
            }
        }
    },
    en: {},
    es_CL:{
        stepperHeader: {
          subjects: 'Asignaturas'
        },
        subjectStep: {
          subtitle: 'Estas son las asignaturas disponibles para rendir tu examen final',
          emptyListMessage: 'No tiene asignaturas disponibles para rendir'
        },
        notification_proctoring:{
            text_2: 'Instala la aplicación en tu computador hoy y deja todo listo.',
            text_4: 'Si es la primera vez que la usas, debes hacer tu proceso de empadronamiento.',
            text_5: 'Encuentra todas las instrucciones en ',
        },
    },
    es_PE: {
        stepperHeader: {
            subjects: 'Cursos'
        },
        scheduleStep: {
            error_message: {
                title: 'Ocurrió un error al obtener los cursos disponibles'
            },
        },
        subjectStep: {
            subtitle: 'Estos son los cursos disponibles para realizar tu examen final',
            emptyListMessage: 'No tiene cursos disponibles para realizar',
            subtitle2: 'Si aceptas podrás pagar y presentarte a realizar el día y hora que seleccionaste'
        },
        notification_proctoring:{
            text_1: 'La herramienta para realizar tu examen final es ',
        },
        create: {
            attempts_message: {
                title: 'Se agotaron los 3 intentos para realizar este curso',
            },
            success_message: {
                subtitle: 'Te inscribiste para realizar el examen final de',
            }
        },
    }
};

export default examsTranslations;
