<template>
  <RecoveryForm
    @sendSecurityCode="generateSecurityCode"
    :canGoNextStep="canGoToNextStep"
    :recoveryData="recoveryData"
    :loading="loading"
    :logoPath="logoPath"
  >
    <template v-if="currentPath === '/login/recovery-password'">
      <RecoveryPassForm :passInputs="passInputs"></RecoveryPassForm>
    </template>
    <template v-else>
      <RecoveryUserForm @changeCanGo="changeUserError" :userInputs="userInputs"></RecoveryUserForm>
    </template>
  </RecoveryForm>
</template>

<script>
import RecoveryForm from '../Components/RecoveryForm/RecoveryForm'
import RecoveryPassForm from '../Components/RecoveryForm/RecoveryPassForm'
import RecoveryUserForm from '../Components/RecoveryForm/RecoveryUserForm'

export default {
  name: 'LoginRecovery',
  components: {
    RecoveryForm,
    RecoveryPassForm,
    RecoveryUserForm
  },
  props: {
    logoPath: String,
  },
  data () {
    return {
      loading: false,
      currentPath: '',
      recoveryData: {
        title: '',
        description: '',
        detail: '',
        captcha: 'tokenHere'
      },
      userInputs: {
        phone: {
          input: '',
          errors: '',
          valid: false
        }
      },
      passInputs: {
        email: {
          input: '',
          errors: '',
          valid: false
        },
        checkSMS: false,
        checkMail: false
      },
      requiredData: {
        input: null
      }
    }
  },
  mounted () {
    const routeMatched = this.$route.matched;
    this.currentPath = routeMatched[routeMatched.length - 1].path;

    if (this.currentPath === '/login/recovery-password') {
      this.recoveryData.title = this.$t('modules.login.recovery.password.title');
      this.recoveryData.description = this.$t('modules.login.recovery.password.description');
    } else {
      this.recoveryData.title = this.$t('modules.login.recovery.user.title');
      this.recoveryData.description = this.$t('modules.login.recovery.user.description');
      this.recoveryData.detail = this.$t('modules.login.recovery.user.detail');
    }
  },
  methods: {
    validInput (value) {
      this.requiredData.input = value;
    },
    generateSecurityCode () {
      if (this.currentPath === '/login/recovery-password') {
        this.loading = true;
        const recoverPassInfo = this.passInputs;
        this.$auth.recoverPass(recoverPassInfo.email.input, recoverPassInfo.checkMail, recoverPassInfo.checkSMS)
          .then((data) => {
            this.$store.dispatch('login/setRecoveryInfo', data).then(() => {
              this.$router.push(`security-code-password`)
            })
          }).catch(error => {
            throw new Error(error)
          }).finally(() => {
            this.loading = false;
          })

      } else {
        const recoverUserInfo = this.userInputs;
        this.loading = true;
        this.$auth.recoverEmail(recoverUserInfo.phone.input)
          .then((data) => {
            this.$store.dispatch('login/setRecoveryInfo', data).then(() => {
              this.$router.push(`security-code-user`)
            })
          }).catch(error => {
            throw new Error(error)
          }).finally(() => {
            this.loading = false
          })
      }
    },
    changeUserError () {
      this.userInputs.phone.errors = !this.userInputs.phone.errors
    }
  },
  computed: {
    canGoToNextStep () {
      if (this.currentPath === '/login/recovery-password')
        return this.passInputs.email.valid && (this.passInputs.checkMail || this.passInputs.checkSMS);
      else
        return this.userInputs.phone.valid;
    }
  }
}
</script>
