<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createStatus">
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">

          <OutlinedCard :title="$t('modules.examTitleNotes.create.labels.detail')" class="mb-6">
            <v-row no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.update.labels.name') }}</span>
              </v-col>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${selectedExam.name} ${selectedExam.lastname}` }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-6">
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ $t('modules.examTitleNotes.create.labels.act') }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-text-field
                    v-model="act"
                    outlined
                    :label="$t('modules.examTitleNotes.create.labels.act')"
                    type="text"
                    hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${$t('modules.examTitleNotes.create.labels.calificacion')} *` }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-text-field
                    v-model="$v.nota.$model"
                    :error-messages="($v.nota.$dirty && $v.nota.$anyError) ? $t('modules.examTitleNotes.create.validation_errors.calificacion') : ''"
                    outlined
                    :label="`${$t('modules.examTitleNotes.create.labels.calificacion')} *`"
                    type="text"
                    @blur="$v.nota.$touch()"
                    @keypress="$validateDecimalInputNumber($event, $v.nota.$model, 7)"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col sm="4" class="d-flex align-start pb-0 pt-4">
                  <span class="caption text-uppercase">{{ `${$t('modules.examTitleNotes.create.labels.fecha')} *` }}</span>
              </v-col>
              <v-col sm="4" class="align-start pt-1 pb-3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="$v.fecha.$model"
                  transition="scale-transition"
                  offset-y
                  bottom
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :error-messages="$v.fecha.$invalid && $v.fecha.$dirty ? $t('modules.examTitleNotes.create.validation_errors.fecha') : ''"
                      v-model="$v.fecha.$model"
                      :label="`${$t('modules.examTitleNotes.create.labels.fecha')} *`"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$v.fecha.$model"
                    no-title
                    color="primary"
                    scrollable
                    :max="new Date().toISOString().substr(0, 10)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">{{ $t('actions.cancel') }}</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save($v.fecha.$model)">{{ $t('actions.save') }}</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$emit('closeDrawer')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.load')"
              :successText="$t('actions.created')"
              :errorText="'Error'"
              :disabled="!canCreate || !canCreateExam"
              @end="resetButtonValues"
              @clicked="createExam"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      class="mt-12"
    />
  </v-container>
</template>

<script>

import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $examsTitle } from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'ExamTitlesNotesCreate',
  props: {
    insideDrawer: Boolean,
    selectedExam: Object,
    isOpen: Boolean
  },
  components: {
    OutlinedCard,
    Button,
    SuccessMessage,
  },
  data () {
    return {
      menu:false,
      createButton:{
        loading:false,
        success:false,
        error:false
      },
      nota: null,
      fecha: null,
      act: null,
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
      },
      createStatus: false
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.exam_title_notes.CREATE
    },
    canCreateExam() {
      return this.$v.nota.$dirty && !this.$v.nota.$anyError && this.$v.fecha.$dirty && !this.$v.fecha.$anyError
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  methods: {
    async createExam () {
      if (!this.canCreate) return false;
      this.createButton.loading = true;
        
      try {
        await $examsTitle.create({
          studentSubjectId: this.selectedExam.id,
          score: parseFloat(this.nota), 
          date: this.fecha,
          act: this.act,
          user: this.userId ? this.userId : null
        })

        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.examTitleNotes.create.success_message.title')
        this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('closeDrawer');}}
        this.createButton.success = true;
      } catch {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.examTitleNotes.create.error_message.title');
        this.successMessage.actionPrimary = {text: this.$t('actions.return'), callback: () => { this.createStatus = false }}
        this.createButton.error = true;
      }
      finally {
        this.createStatus = true;
        this.resetButtonValues();
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    clearData(){
      this.resetButtonValues();
      this.nota = null;
      this.fecha = null;
      this.act = null;
      this.menu = false;
      this.createStatus = false;
      this.$v.$reset();
    }
  },
  validations: {
    nota: {
      required,
      minValue: minValue(0), 
      maxValue: maxValue(7), 
    },
    fecha: {required}
  },
  watch: {
    isOpen(value) {
      if(!value) this.clearData()
    }
  }
}
</script>