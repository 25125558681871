<template>
  <v-container class="d-flex">
    <v-row class="mt-8">
      <v-col :sm="fullWidth ? 12 : 6" class="mx-auto d-flex justify-center flex-column">
        <div class="mx-auto d-flex flex-column justify-center iconContainer" :class="[typeClass]">
          <v-icon v-if="type === 'success'" color="success" size="100px">mdi-check-circle</v-icon>
          <v-icon v-else-if="type === 'warning'" color="warning" size="100px">mdi-alert-circle</v-icon>
          <v-icon v-else-if="type === 'error'" color="red" size="100px">mdi-close-circle</v-icon>
        </div>
        <h6 v-if="title" class="headline text-center mt-4 mb-3 primary--text">{{ title }}</h6>
        <p v-if="subtitle" class="text-center mt-2 mb-3">{{ subtitle }}</p>
        <slot></slot>
        <div class="d-flex flex-row mt-7 justify-center" >
          <v-btn
            v-if="actionSecondary"
            large
            rounded
            color="white"
            class="mx-6 my-2 px-8"
            @click="actionSecondary.callback()"
          >{{ actionSecondary.text }}</v-btn>
          <v-btn
            v-if="actionPrimary"
            large
            rounded
            color="primary"
            class="mx-6 my-2 px-8"
            @click="actionPrimary.callback()"
          >{{ actionPrimary.text }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
    .iconContainer
      width: 182px
      height: 182px
      border-radius: 50%
      &.type-success
        background-color: rgba(59, 192, 0, 0.1) !important
      &.type-warning
        background-color: rgba(251, 140, 0, 0.1) !important
      &.type-error
        background-color: rgba(244, 67, 54, 0.1) !important
</style>

<script>
export default {
  name: 'SuccessMessage',
  props: {
    fullWidth: {type: Boolean, default: false},
    type: { type: String, default: 'success' },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    actionPrimary: { type: Object, default: null },
    actionSecondary: { type: Object, default: null },
  },
  data () { return {} },
  computed: {
    typeClass () {
      return `type-${this.type}`;
    }
  }
}
</script>
