<template>
  <div>
    <v-container fluid class="pa-0 pb-8 overflow-y-outo" >
      <v-container class="container-custom" v-if="!updatePeriodStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :breadcrumbs="$t('modules.academic.periods.update.breadcrumbs')"
            :title="$t('modules.academic.periods.update.breadcrumbs.title')"
            :description="$t('modules.academic.periods.update.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.academic.periods.update.cards.title')"
                :subtitle="$t('modules.academic.periods.update.cards.main_subtitle')"
                :switchLabel="formattedPeriodStatus"
                :switchValue="period.status"
                switcher
                @switchChange="onSwitchChange"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="12">
                    <v-row>
                      <v-col sm="3">
                        <span class="grey--text">{{ `${$t('modules.academic.periods.update.cards.name')} *` }}</span>
                      </v-col>
                      <v-col sm="5" class="py-0">
                        <v-text-field
                          outlined
                          v-model="period.description"
                          required
                          ref="description"
                          :error-messages="$v.period.description.$dirty && $v.period.description.$invalid ?
                          $t('modules.academic.periods.update.errors.required') : ''"
                          @blur="onBlur('description')"
                          @keypress="$validateAlphanumeric($event, $v.period.description.$model,40, true)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="3">
                        <span class="grey--text">{{ `${$t('modules.academic.periods.create.cards.code')} *` }}</span>
                      </v-col>
                      <v-col sm="5">
                        <v-text-field
                          outlined
                          ref="code"
                          v-model="period.code"
                          placeholder="XX/XXXX"
                          v-mask="'#A/####'"
                          :error-messages="$v.period.code.$dirty && $v.period.code.$invalid ?
                          ((!$v.period.code.required) ? $t('modules.academic.periods.update.errors.required')
                          : !$v.period.code.minLength ? $t('modules.academic.periods.update.errors.minLength')
                          : $t('modules.academic.periods.update.errors.codeUnique')) : '' "
                          @blur="validateCode"
                          :loading="loadingCode"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="3">
                        <span class="grey--text">{{ `${$t('modules.academic.periods.update.cards.from')} *` }}</span>
                      </v-col>
                      <v-col sm="5">
                        <v-menu
                          ref="dateFromMenu"
                          v-model="dateFromMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period.classStartDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formatDateToCurrent(period.classStartDate)"
                              readonly
                              v-on="on"
                              outlined
                              append-icon="mdi-calendar"
                              :error-messages="$v.period.classStartDate.$dirty && !$v.period.classStartDate.datePairValid ? $t('modules.academic.periods.update.errors.datePair') : ''"
                              @blur="onBlur('classStartDate')"
                            ></v-text-field>
                          </template>

                          <v-date-picker v-model="period.classStartDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onCancelDatePickerClick('dateFromMenu')"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick('dateFromMenu', period.classStartDate)"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="3">
                        <span class="grey--text">{{ `${$t('modules.academic.periods.update.cards.to')} *` }}</span>
                      </v-col>
                      <v-col sm="5">
                        <v-menu
                          ref="dateToMenu"
                          v-model="dateToMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period.classEndDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formatDateToCurrent(period.classEndDate)"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              outlined
                              :error-messages="$v.period.classEndDate.$dirty && !$v.period.classEndDate.datePairValid ? $t('modules.academic.periods.update.errors.datePair') : ''"
                              @blur="onBlur('classEndDate')"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="period.classEndDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onCancelDatePickerClick('dateToMenu')"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick('dateToMenu', period.classEndDate)"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="3">
                        <span class="grey--text">{{ `${$t('modules.academic.periods.update.cards.period')} *` }}</span>
                      </v-col>
                      <v-col sm="5">
                        <v-select
                          v-model="period.termType"
                          :items="terms"
                          item-text="meaning"
                          return-object
                          color="primary"
                          :label="$t('modules.academic.periods.create.cards.period')"
                          class="mr-8 mt-0 "
                          outlined
                          @blur="validateTermType"
                          @change="onChange"
                          :error-messages=" $v.period.termType.$dirty && !$v.period.termType.required ? $t('modules.academic.periods.update.errors.required') : ''"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div v-if="period.termType">
                  <v-card-text
                    class="title font-weight-bold ma-0 px-0 pt-2"
                  >{{ $t('modules.academic.periods.update.cards.enable') }}</v-card-text>
                  <v-card-text
                    class="grey--text body-2 ma-0 px-0 pt-2"
                  >{{ $t('modules.academic.periods.update.cards.subtitle') }}</v-card-text>
                  <template v-for="(qualification, indexQualification) in qualificationsCurrent">
                    <v-row :key="qualification.refDateFrom">
                      <v-col cols="12" sm="8" md="4">
                        <v-checkbox
                          v-model="qualification.isChecked"
                          @change="onQualificationCheckboxClick($event, qualification.identifier)"
                          color="primary"
                          :label="$t(qualification.translation)"
                          class="mr-8 mt-0 no-message"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="2" md="4">
                        <v-menu
                          :ref="qualification.refDateFrom"
                          v-model="qualification.dateFromMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period[qualification.dateFromFieldName]"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :label="$t('modules.academic.periods.update.cards.from')"
                              readonly
                              :value="formatDateToCurrent(period[qualification.dateFromFieldName])"
                              v-on="on"
                              outlined
                              class="v-input__slot"
                              :disabled="!qualification.isChecked"
                              :error-messages="$v.period[qualification.dateFromFieldName].$dirty && !$v.period[qualification.dateFromFieldName].datePairValid ? $t('modules.academic.periods.update.errors.datePair') : ''"
                              @blur="onBlur(qualification.dateFromFieldName)"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="period[qualification.dateFromFieldName]"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onQualificationCancelDatePickerClick(indexQualification, 'dateFromMenu')"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick(qualification.refDateFrom, period[qualification.dateFromFieldName])"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="2" md="4">
                        <v-menu
                          :ref="qualification.refDateTo"
                          v-model="qualification.dateToMenu"
                          :close-on-content-click="false"
                          :return-value.sync="period[qualification.dateToFieldName]"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formatDateToCurrent(period[qualification.dateToFieldName])"
                              :label="$t('modules.academic.periods.create.cards.to')"
                              readonly
                              v-on="on"
                              outlined
                              class="v-input__slot"
                              :disabled="!qualification.isChecked"
                              :error-messages="$v.period[qualification.dateToFieldName].$dirty && !$v.period[qualification.dateToFieldName].datePairValid ? $t('modules.academic.periods.update.errors.datePair') : ''"
                              @blur="onBlur(qualification.dateToFieldName)"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="period[qualification.dateToFieldName]"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="onQualificationCancelDatePickerClick(indexQualification, 'dateToMenu')"
                            >{{ $t('actions.cancel') }}</v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="onOkDatePickerClick(qualification.refDateTo, period[qualification.dateToFieldName])"
                            >OK</v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </OutlineCard>
              <OutlineCard
                :disabled="true"
                :title="$t('modules.academic.periods.update.cards.modality')"
                :subtitle="$t('modules.academic.periods.update.cards.subtitle2')"
                class="mb-6"
              >
                <div class="d-flex">
                  <v-checkbox
                    v-for="modality in modalityTypes"
                    :key="modality.value"
                    v-model="period.modalityType"
                    :value="modality.value"
                    color="primary"
                    :label="modality.meaning"
                    class="mr-8 mt-0 no-message"
                    :error-messages="$v.period.modalityType.$dirty && $v.period.modalityType.$anyError ?
                    $t('modules.academic.periods.update.errors.required') : ''"
                  ></v-checkbox>
                </div>
              </OutlineCard>
              <div v-if="isCursando">
              <OutlineCard
                :title="$t('modules.academic.periods.update.cards.product')"
                :subtitle="$t('modules.academic.periods.update.cards.subtitle3')"
                class="mb-6"
              >
                <TransferList
                  :avatar="false"
                  :loading="loadingSearchProducts"
                  :availableItems.sync="products"
                  :addedItems.sync="$v.period.selectedProducts.$model"
                  ref="selectedProducts"
                  areEquals="id"
                  :firstListTitle="$t('modules.academic.periods.create.transferList.firstTitle')"
                  :secondListTitle="$t('modules.academic.periods.create.transferList.secondTitle')"
                  @searchItemLeftOnBackend="searchProducts"
                  @searchItemRightOnBackend="searchProductsAdded"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="$v.period.selectedProducts.$dirty && $v.period.selectedProducts.$anyError"
                >{{ $t('modules.academic.periods.update.errors.requiredTransferList') }}</v-alert>
              </OutlineCard>
              </div>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="goBack"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canUpdate"
                  @end="resetButtonValues"
                  @clicked="updatePeriod"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import Button from '@/components/Button/Button';
import { $types, $terms, $products } from '../services';
import { required, minLength, helpers, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';
import moment from 'moment'

const datePairValid = (fieldNameStart, fieldNameEnd) => (value, vm) => !helpers.req(value) || moment(vm[fieldNameStart]).isBefore(moment(vm[fieldNameEnd]))

export default {
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    SuccessMessage,
    Button
  },
  props: {
    insideDrawer: Boolean
  },
  data () {
    return {
      loadingCode: false,
      terms:[],
      paginationLimit: 150,
      loadingSearchProducts: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.academic.periods.update.success_message.title'),
        actionPrimary: {          text: this.$t('modules.academic.periods.update.success_message.actions.primary_text'), callback: () => {
          this.$router.push('/academic/periods')
          }        }
      },
      updatePeriodStatus: false,
      codeCopy: '',
      periodValidationLoading: false,
      periodError: '',
      saving: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      period: {
        termType:{},
        description: '',
        code: '',
        studyType: null,
        classStartDate: null,
        classEndDate: null,
        modalityType: [],
        saleDateFrom: null,
        selectedProducts: [],
        saleDateTo: null,
        saleRiDateFrom: null,
        saleRiDateTo: null,
        scholarshipDateFrom: null,
        scholarshipDateTo: null,
        refundDateFrom: null,
        refundDateTo: null,
        refundForceDateFrom: null,
        refundForceDateTo: null,
        repaymentRiDateFrom: null,
        repaymentRiDateTo: null,
        disenrollmentDateFrom: null,
        disenrollmentDateTo: null,
        changeDateFrom: null,
        changeDateTo: null,
        sectionDateFrom: null,
        sectionDateTo: null,
        penaltyRaicFrom: null,
        penaltyRaicTo: null,
        status: true,
        billingProducts: []
      },
      qualifications: [
        {
          identifier: 'Venta Ingresante',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'saleDateFromMenu',
          dateFromFieldName: 'saleDateFrom',
          dateToMenu: false,
          refDateTo: 'saleDateToMenu',
          dateToFieldName: 'saleDateTo',
          translation: 'modules.academic.periods.update.cards.incomingsale'
        },
        {
          identifier: 'Venta Re Ingresante',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'saleRiDateFromMenu',
          dateFromFieldName: 'saleRiDateFrom',
          dateToMenu: false,
          refDateTo: 'saleRiDateToMenu',
          dateToFieldName: 'saleRiDateTo',
          translation: 'modules.academic.periods.update.cards.resale'
        },
        {
          identifier: 'Aplicacion de beca',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'scholarshipDateFromMenu',
          dateFromFieldName: 'scholarshipDateFrom',
          dateToMenu: false,
          refDateTo: 'scholarshipDateToMenu',
          dateToFieldName: 'scholarshipDateTo',
          translation: 'modules.academic.periods.update.cards.application'
        },
        {
          identifier: 'Reembolso sin motivo',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'refundDateFromMenu',
          dateFromFieldName: 'refundDateFrom',
          dateToMenu: false,
          refDateTo: 'refundDateToMenu',
          dateToFieldName: 'refundDateTo',
          translation: 'modules.academic.periods.update.cards.refund'
        },
        {
          identifier: 'Reembolso fuerza mayor',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'refundForceDateFromMenu',
          dateFromFieldName: 'refundForceDateFrom',
          dateToMenu: false,
          refDateTo: 'refundForceDateToMenu',
          dateToFieldName: 'refundForceDateTo',
          translation: 'modules.academic.periods.update.cards.refund2'
        },
        {
          identifier: 'Devolucion reingresantes',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'repaymentRiDateFromMenu',
          dateFromFieldName: 'repaymentRiDateFrom',
          dateToMenu: false,
          refDateTo: 'repaymentRiDateToMenu',
          dateToFieldName: 'repaymentRiDateTo',
          translation: 'modules.academic.periods.update.cards.refund3'
        },
        {
          identifier: 'Desinscripcion a materias',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'disenrollmentDateFromMenu',
          dateFromFieldName: 'disenrollmentDateFrom',
          dateToMenu: false,
          refDateTo: 'disenrollmentDateToMenu',
          dateToFieldName: 'disenrollmentDateTo',
          translation: 'modules.academic.periods.update.cards.unsuscribe'
        },
        {
          identifier: 'Pases',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'changeDateFromMenu',
          dateFromFieldName: 'changeDateFrom',
          dateToMenu: false,
          refDateTo: 'changeDateToMenu',
          dateToFieldName: 'changeDateTo',
          translation: 'modules.academic.periods.update.cards.pases'
        },
        {
          identifier: 'Catedra',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'sectionDateFromMenu',
          dateFromFieldName: 'sectionDateFrom',
          dateToMenu: false,
          refDateTo: 'sectionDateToMenu',
          dateToFieldName: 'sectionDateTo',
          translation: 'modules.academic.periods.update.cards.cathedra'
        },
        {
          identifier: 'Multa raic',
          dateFromMenu: false,
          isChecked: false,
          refDateFrom: 'fromRaicMenu',
          dateFromFieldName: 'penaltyRaicFrom',
          dateToMenu: false,
          refDateTo: 'foRaicMenu',
          dateToFieldName: 'penaltyRaicTo',
          translation: 'modules.academic.periods.create.cards.raic'
        }
      ],
      originalCode: null,
      codeUnique: true,
      qualificationsCurrent: [],
      dateFromMenu: false,
      dateToMenu: false,
      modalityTypes: [],
      products: [],
      rawProducts: [],
      // mockup
      radios: '1',
      input: 400,
      groups: [
        {
          'product': [1, 2, 3],
          'zone': [1, 2, 3],
          'from': '',
          'discountType': [1, 2, 3],
          'semester': '',
          'bimester': '',
          menu: false, //mockup
          date: new Date().toISOString().substr(0, 10), // mockup
        }
      ]
      // end mockup
    }
  },
  computed: {
    formattedPeriodStatus () {
      return this.period.status ? this.$t('modules.academic.periods.update.cards.switch.enable') : this.$t('modules.academic.periods.update.cards.switch.disable')
    },
    isCursando(){
      return this.period.termType.value === 193
    },
    isPractice(){
      return this.period.termType?.meaning === 'Practicas Profesionales'
    },
    canUpdate () {
      return this.$permissions.portaladministrativo.academic.periods.UPDATE
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions',
      userId: 'commons/getUserID'
    })
  },
  validations: {
    period: {
      termType:{
        required
      },
      description: {
        required
      },
      code: {
        required,
        minLength: minLength(7),
        unique() {
        return this.codeUnique != false;
        },
      },
      classStartDate: {
        required,
        datePairValid: datePairValid('classStartDate', 'classEndDate')
      },
      classEndDate: {
        required,
        datePairValid: datePairValid('classStartDate', 'classEndDate')
      },
      modalityType: {
        required,
        minLength: minLength(1)
      },
      saleDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Ingresante')?.isChecked || value.saleDateFrom
        }),
        datePairValid: datePairValid('saleDateFrom', 'saleDateTo')
      },
      saleDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Ingresante')?.isChecked || value.saleDateTo
        }),
        datePairValid: datePairValid('saleDateFrom', 'saleDateTo')
      },
      saleRiDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Re Ingresante')?.isChecked || value.saleRiDateFrom
        }),
        datePairValid: datePairValid('saleRiDateFrom', 'saleRiDateTo')
      },
      saleRiDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Venta Re Ingresante')?.isChecked || value.saleRiDateTo
        }),
        datePairValid: datePairValid('saleRiDateFrom', 'saleRiDateTo')
      },
      scholarshipDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Aplicacion de beca')?.isChecked || value.scholarshipDateFrom
        }),
        datePairValid: datePairValid('scholarshipDateFrom', 'scholarshipDateTo')
      },
      scholarshipDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Aplicacion de beca')?.isChecked || value.scholarshipDateTo
        }),
        datePairValid: datePairValid('scholarshipDateFrom', 'scholarshipDateTo')
      },
      refundDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso sin motivo')?.isChecked || value.refundDateFrom
        }),
        datePairValid: datePairValid('refundDateFrom', 'refundDateTo')
      },
      refundDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso sin motivo')?.isChecked || value.refundDateTo
        }),
        datePairValid: datePairValid('refundDateFrom', 'refundDateTo')
      },
      refundForceDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso fuerza mayor')?.isChecked || value.refundForceDateFrom
        }),
        datePairValid: datePairValid('refundForceDateFrom', 'refundForceDateTo')
      },
      refundForceDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Reembolso fuerza mayor')?.isChecked || value.refundForceDateTo
        }),
        datePairValid: datePairValid('refundForceDateFrom', 'refundForceDateTo')
      },
      repaymentRiDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Devolucion reingresantes')?.isChecked || value.repaymentRiDateFrom
        }),
        datePairValid: datePairValid('repaymentRiDateFrom', 'repaymentRiDateTo')
      },
      repaymentRiDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Devolucion reingresantes')?.isChecked || value.repaymentRiDateTo
        }),
        datePairValid: datePairValid('repaymentRiDateFrom', 'repaymentRiDateTo')
      },
      disenrollmentDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Desinscripcion a materias')?.isChecked || value.disenrollmentDateFrom
        }),
        datePairValid: datePairValid('disenrollmentDateFrom', 'disenrollmentDateTo')
      },
      disenrollmentDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Desinscripcion a materias')?.isChecked || value.disenrollmentDateTo
        }),
        datePairValid: datePairValid('disenrollmentDateFrom', 'disenrollmentDateTo')
      },
      changeDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Pases')?.isChecked || value.changeDateFrom
        }),
        datePairValid: datePairValid('changeDateFrom', 'changeDateTo')
      },
      changeDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Pases')?.isChecked || value.changeDateTo
        }),
        datePairValid: datePairValid('changeDateFrom', 'changeDateTo')
      },
      sectionDateFrom: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Catedra')?.isChecked || value.sectionDateFrom
        }),
        datePairValid: datePairValid('sectionDateFrom', 'sectionDateTo')
      },
      sectionDateTo: {
        required: requiredIf(function(value) {
          return this.qualificationsCurrent.find(qualification => qualification.identifier === 'Catedra')?.isChecked || value.sectionDateTo
        }),
        datePairValid: datePairValid('sectionDateFrom', 'sectionDateTo')
      },
      penaltyRaicFrom: {
        datePairValid: datePairValid('penaltyRaicFrom', 'penaltyRaicTo')
      },
      penaltyRaicTo: {
        datePairValid: datePairValid('penaltyRaicFrom', 'penaltyRaicTo')
      },
      status: {
        required
      },
      selectedProducts:{
        requiredSelectProducts(value) {
          return !this.isCursando || value.length
        }
      },
    }
  },
  methods: {
    validateTermType(){
      this.onBlur('termType')
      this.validateCode()
    },
    async validateCode() {
      this.$v.period.code.$touch();
      if (this.$v.period.code.$model) {
        if (this.originalCode === this.period.code) {
          this.codeUnique = true;
          return;
        }
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $terms.find('exists', null, {params: {code: this.$v.period.code.$model, termType: this.$v.period.termType.$model.value}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.period.code.$touch();
        }
      }
    },
    async onChange (field) {
      if(field.meaning === 'Cursado' ){
        this.qualificationsCurrent = this.qualifications
      }
      //practica
      else if(field.meaning === 'Practicas Profesionales' ){
        this.qualificationsCurrent = this.qualifications.filter(qualification => qualification.identifier === 'Desinscripcion a materias' || qualification.identifier === 'Catedra' )
      }
      //OnBoarding, Nivelacion.
      else{
        this.qualificationsCurrent = this.qualifications.filter(qualification => qualification.identifier === 'Catedra')
      }
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    formatDateToCurrent (date) {
      return date ?
      //  moment(date).format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) 
      moment(date).format( 'DD/MM/YYYY' )
       : null
    },
    onQualificationCancelDatePickerClick (index, field) {
      this.qualifications[index][field] = false
    },
    async onBlur (field) {
      this.$v.period[field].$touch()
    },
    onQualificationCheckboxClick (event, identifier) {
      const selectedQualification = this.qualifications.find(qualification => qualification.identifier === identifier)
      this.$v.period[selectedQualification.dateFromFieldName].$reset()
      this.$v.period[selectedQualification.dateToFieldName].$reset()
      selectedQualification.isChecked = event
      if (event === false) {
        this.period[selectedQualification.dateFromFieldName] = null
        this.period[selectedQualification.dateToFieldName] = null
      } else {
        this.period[selectedQualification.dateFromFieldName] = moment(this.period.classStartDate).subtract(15, 'day').format('YYYY-MM-DD')
        this.period[selectedQualification.dateToFieldName] = moment(this.period.classStartDate).add(15, 'day').format('YYYY-MM-DD')
      }
    },
    setQualificationOnPeriod (field1, value1, field2, value2) {
      this.period[field1] = value1
      this.period[field2] = value2
    },
    onCancelDatePickerClick (model) {
      this[model] = false
    },
    onOkDatePickerClick (ref, date) {
      if (ref === 'dateFromMenu') {
        this.$refs[ref].save(date)
        this.setQualifications()
      } else if (ref === 'dateToMenu') {
        this.$refs[ref].save(date)
      } else {
        this.$refs[ref][0].save(date)
      }
    },
    save (value, field) {
      this.period[field] = value
    },
    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1]
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      this.groups.push({
        check: false,
      })
    },
    formatCodeMask (code) {
      return code.split('/').join('')
    },
    async triggerPeriodUpdate (periodToUpdate) {
      if (this.$v.period.$invalid || !this.codeUnique) {
        this.$v.period.$touch();
        if(!this.codeUnique) {
          this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
        } else {
          Object.keys(this.$v.period).some(input => {
            if (input.includes('$')) return false;
            if (this.$v.period[input].$error) {
              this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
              return true
            }
          })
        }
        this.createButton.error = true;
        this.createButton.loading = false;
      }else{
      if(!this.canUpdate) return false
      this.createButton.loading = true;
      this.qualifications.forEach(qualification => {
        if (!qualification.isChecked) {
          periodToUpdate[qualification.dateFromFieldName] = null
          periodToUpdate[qualification.dateToFieldName] = null
        }
      })
      delete periodToUpdate.id
      periodToUpdate.user = this.userId ? this.userId : null
      periodToUpdate.modalityType = { value: periodToUpdate.modalityType[0] }
      periodToUpdate.billingProducts = []
      periodToUpdate.billingProducts = this.period.selectedProducts.map(product => {
        return { id: product.id };
      });
      try {
        await $terms.update(this.period.id, periodToUpdate)
        this.updatePeriodStatus = true;
        this.saveButton.success = true;
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.academic.periods.update.success_message.title');
        this.successMessage.actionPrimary = {          text: this.$t('modules.academic.periods.update.success_message.actions.primary_text'), callback: () => {
            this.$router.push('/academic/periods')
          }        }
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = (err.code && err.code === 'E203') ? this.$t('modules.academic.periods.update.error_message.exists') : this.$t('modules.academic.periods.update.error_message.title');
        this.updatePeriodStatus = true;
        this.saveButton.loading = false;
        this.saveButton.error = true;
        this.successMessage.actionPrimary = {          text: this.$t('modules.academic.periods.update.buttons.back'), callback: () => {
            this.updatePeriodStatus = false
          }        };
        this.products = this.rawProducts.filter(product => {
          return !this.period.selectedProducts.find(selectedProduct => selectedProduct.id === product.id)
        })
      } finally {
        this.createButton.loading = false;
        this.createButton.error = true;
      }
      }
    },
    async updatePeriod () {
      const periodToUpdate = { ...this.period }

      if (!periodToUpdate.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.academic.periods.update.warning_popup.title'),
          content: this.$t('modules.academic.periods.update.warning_popup.content'),
          actionPrimary: {
            text: this.$t('modules.academic.periods.update.buttons.create'),
            callback: async () => {
              await this.triggerPeriodUpdate(periodToUpdate)
            }
          },
          actionSecondary: {
            text: this.$t('modules.academic.periods.update.buttons.cancel'),
            callback: () => {
              this.resetButtonValues()
            }
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      } else {
        await this.triggerPeriodUpdate(periodToUpdate)
      }
    },
    formatFetchedPeriod (fetchedPeriod) {
      this.period.id = fetchedPeriod.data.id
      this.period.status = fetchedPeriod.data.status
      this.period.description = fetchedPeriod.data.description
      this.period.code = fetchedPeriod.data.code
      this.period.classStartDate = fetchedPeriod.data.classStartDate
      this.period.classEndDate = fetchedPeriod.data.classEndDate
      this.period.modalityType = [fetchedPeriod.data.modalityType.value]
      this.period.saleDateFrom = fetchedPeriod.data.saleDateFrom
      this.period.saleDateTo = fetchedPeriod.data.saleDateTo
      this.period.saleRiDateFrom = fetchedPeriod.data.saleRiDateFrom
      this.period.saleRiDateTo = fetchedPeriod.data.saleRiDateTo
      this.period.scholarshipDateFrom = fetchedPeriod.data.scholarshipDateFrom
      this.period.scholarshipDateTo = fetchedPeriod.data.scholarshipDateTo
      this.period.refundDateFrom = fetchedPeriod.data.refundDateFrom
      this.period.refundDateTo = fetchedPeriod.data.refundDateTo
      this.period.refundForceDateFrom = fetchedPeriod.data.refundForceDateFrom
      this.period.refundForceDateTo = fetchedPeriod.data.refundForceDateTo
      this.period.repaymentRiDateFrom = fetchedPeriod.data.repaymentRiDateFrom
      this.period.repaymentRiDateTo = fetchedPeriod.data.repaymentRiDateTo
      this.period.disenrollmentDateFrom = fetchedPeriod.data.disenrollmentDateFrom
      this.period.disenrollmentDateTo = fetchedPeriod.data.disenrollmentDateTo
      this.period.changeDateFrom = fetchedPeriod.data.changeDateFrom
      this.period.changeDateTo = fetchedPeriod.data.changeDateTo
      this.period.sectionDateFrom = fetchedPeriod.data.sectionDateFrom
      this.period.sectionDateTo = fetchedPeriod.data.sectionDateTo
      this.period.billingProducts = fetchedPeriod.data.billingProducts
      this.period.termType = fetchedPeriod.data.termType
    },
    setQualificationsDates (period) {
      this.qualifications.forEach(qualification => {
        if (period[qualification.dateFromFieldName] && period[qualification.dateToFieldName]) {
          this.period[qualification.dateFromFieldName] = period[qualification.dateFromFieldName]
          this.period[qualification.dateToFieldName] = period[qualification.dateToFieldName]
          qualification.isChecked = true
        }
      })
    },
    onSwitchChange () {
      this.period.status = !this.period.status
    },
    searchProducts (event) {
      this.getProducts(event);
    },
    searchProductsAdded (event) {
      this.period.selectedProducts = event
    },
    async getProducts (product) {
      this.loadingSearchProducts = true;
      // eslint-disable-next-line no-unused-vars
      let products = [];
      if (product) {
        products = await $products.find(null, null, {
          params: { name: product, length: this.paginationLimit } // TODO: (Diego) Review with backend in order to find in all items
        });

         this.products = [{ id: 1, name: 'mi gran prueba'}]
      } else {
        products = await $products.find(null, null, {
          params: { length: this.paginationLimit }
        });
      }

      // if (products.data?.content?.length) {
      //   this.rawProducts = [...products.data.content]
      //   this.products = products.data.content.map(item => {
      //     return {
      //       id: item.id,
      //       name: item.name
      //     };
      //   });
      // } else {
      //   this.products.length = 0
      // }

      this.loadingSearchProducts = false;
    },
    setQualifications () {
      const dateFrom = moment(this.period.classStartDate).subtract(15, 'days').format('YYYY-MM-DD')
      const dateTo = moment(this.period.classStartDate).add(15, 'days').format('YYYY-MM-DD')
      // this.qualifications.forEach(qual => { qual.isChecked = true })
      this.qualifications.forEach(item => {
        if (item.isChecked) {
          this.period[item.dateFromFieldName] = dateFrom
          this.period[item.dateToFieldName] = dateTo
        }
      });
    },
    async fetchTerms(){
      const terms = await  $types.find(null, null, { params: { type: 'PERIOD_TYPE' } })
      this.terms = terms.data
    }
  },
  async mounted () {
    let fetchedPeriod = [];
    let fetchedModalityTypes = [];
    let fetchedProducts = [];
    this.fetchTerms()

    try {
      fetchedPeriod = await $terms.findById(this.$router.history.current.params.id);
    } catch (error) {
      fetchedPeriod = []
    }
    this.onChange(fetchedPeriod.data.termType)
    this.codeCopy = fetchedPeriod.data.code
    this.originalCode = fetchedPeriod.data.code;
    fetchedPeriod.data.code = this.formatCodeMask(fetchedPeriod.data.code)
    // this.period = this.formatFetchedPeriod(fetchedPeriod)
    await this.formatFetchedPeriod(fetchedPeriod)
    this.setQualificationsDates(fetchedPeriod.data)
    this.period.selectedProducts = fetchedPeriod.data.billingProducts.map(product => ({ id: product.id, name: product.name }))

    try {
      fetchedModalityTypes = await $types.find(null, null, { params: { type: 'MODALITY_TYPE' } })
    } catch (error) {
      fetchedModalityTypes = []
    }

    this.modalityTypes = fetchedModalityTypes.data

    try {
      fetchedProducts = await $products.find(null, null, { params: { length: this.paginationLimit } })
    } catch (error) {
      fetchedProducts = []
    }

    this.products = fetchedProducts.data.content.filter(product => !(this.period.selectedProducts.map(product => product.id).includes(product.id))).map(product => ({ name: product.name, id: product.id }))
    this.rawProducts = fetchedProducts.data.content
    this.$v.period.$touch()
  }
}
</script>
