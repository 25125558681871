<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs 
                isDescriptionFullLength
                :title="$t('modules.portalalumno.academic_charge.subjects.breadcrumbs.title')"
                :description="$t('modules.portalalumno.academic_charge.subjects.breadcrumbs.description')"
                :analytics="{category: 'Asignaturas', label: 'Inscripcion asignaturas'}"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col cols="12">
                    <div class="fullHeight">
                        <v-container class="container-custom">
                            <v-container>
                                <v-row v-if="loading">
                                    <v-col sm="12" cols="12" class="d-flex mt-6">
                                        <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col sm="12" md="8" class="mx-auto pt-0 mb-8" v-if="hasOptatives">
                                        <v-expansion-panels>
                                            <v-expansion-panel style="border-radius: 8px">
                                                <v-expansion-panel-header class="pa-0" disable-icon-rotate style="background-color: #4586de; border-radius: 8px; min-height: 48px !important">
                                                    <ImageBanner
                                                        leftImage
                                                        src="images/portalalumno/salida_intermedia.png"
                                                        alt="Salida intermedia"
                                                        textColor="white"
                                                        :title="$t('modules.portalalumno.academic_charge.subjects.optativeModule.bannerText')"
                                                        backgroundColor="#4586de"
                                                    ></ImageBanner>
                                                    <template v-slot:actions>
                                                        <span class="mx-6" style="font-family: 'DM Sans', 'Roboto' !important; font-size: 16px; letter-spacing: 0.25px; font-weight: 600;color: rgba(255,255,255,0.6);">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.see_more') }}</span>
                                                    </template>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content class="px-2 py-4">
                                                    <h3 class="title primary--text">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.title') }}</h3>
                                                    <v-list-item-title class="font-weight-bold mt-8">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.what_is_title') }}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-2" style="white-space: normal !important">
                                                        <ul>
                                                            <li>{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.what_is_text') }}</li>
                                                        </ul>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title class="font-weight-bold mt-4">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.requisites_title') }}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-2" style="white-space: normal !important">
                                                        <ul>
                                                            <li>{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.requisites_text') }}</li>
                                                        </ul>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title class="font-weight-bold mt-4">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.how_title') }}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-2" style="white-space: normal !important">
                                                        <ul>
                                                            <li>{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.how_text') }}</li>
                                                        </ul>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title class="font-weight-bold mt-4">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.when_title') }}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-2" style="white-space: normal !important">
                                                        <ul>
                                                            <li>{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.when_text') }}</li>
                                                        </ul>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title class="font-weight-bold mt-4">{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.about_title') }}</v-list-item-title>
                                                    <v-list-item-subtitle class="mt-2" style="white-space: normal !important">
                                                        <ul>
                                                            <li>{{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.about_text') }}</li>
                                                        </ul>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle class="mt-6" style="white-space: normal !important">
                                                        {{ $t('modules.portalalumno.academic_charge.subjects.optativeModule.resumen') }}
                                                    </v-list-item-subtitle>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                    <v-col sm="12" md="8" class="mx-auto pt-0">
                                        <h4 class="mt-0 mb-6 subtitle-1" v-if="subjects.length">{{ $t('modules.portalalumno.academic_charge.subjects.subjects_title') }}</h4>
                                        <div class="text-center" v-else>
                                            <v-icon large class="mb-4">mdi-receipt</v-icon>
                                            <h4 class="mt-0 mb-6 subtitle-1">{{ $t('modules.portalalumno.academic_charge.subjects.no_subjects_title') }}</h4>
                                        </div>


                                        <SubjectItem
                                            v-for="(subject, index) in subjects" :key="`${index}-${subject.matId}`"
                                            :subject="subject"
                                            @remove="removePopup(subject)"
                                        ></SubjectItem>
                                    </v-col>

                                    <v-col sm="12" md="8" class="mx-auto pt-0" v-if="availableSubjects.length">
                                        <v-divider></v-divider>
                                    </v-col>

                                    <v-col sm="12" md="8" class="mx-auto" v-if="availableSubjects.length">
                                        <div v-if="debt" class="d-flex justify-center"> 
                                            <div class="text-center px-3 py-5">
                                                <div class="mt-5">
                                                    <v-icon color="warning" size="100px" block>mdi-alert</v-icon>        
                                                </div>
                                                <h1 class="headline font-weight-bold primary--text">{{ $t('modules.portalalumno.academic_charge.subjects.debt.title') }}</h1>
                                                <p class="body-2 mx-10">{{ $t('modules.portalalumno.academic_charge.subjects.debt.description') }}</p>
                                                <div class="mt-10 mb-5 d-flex justify-center align-center">
                                                    <Button :text="$t('modules.portalalumno.academic_charge.subjects.debt.action')" @clicked="redirectPayments" primary></Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <h4 class="mt-0 mb-6 subtitle-1">{{ $t('modules.portalalumno.academic_charge.subjects.available_title') }}</h4>

                                            <SubjectItem
                                                v-for="(subject, index) in availableSubjects" :key="`${subject.matId}-${index}`"
                                                :subject="subject"
                                            ></SubjectItem>

                                            <div class="d-flex my-12 justify-end">
                                                <Button
                                                    :text="$t('modules.portalalumno.academic_charge.subjects.enroll')"
                                                    :loading="createButton.loading"
                                                    :success="createButton.success"
                                                    :error="createButton.error"
                                                    :successText="$t('actions.created')"
                                                    :errorText="'Error'"
                                                    @end="resetButtonValues"
                                                    @clicked="confirmPopup"
                                                    :disabled="!canEnroll"
                                                ></Button>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12" md="8" class="mx-auto" v-else-if="!subjects.length">
                                        <div v-if="$isTeclab" class="d-flex justify-center">
                                            <div class="text-center px-3 py-5">
                                                <div class="mt-5">
                                                    <v-icon color="warning" size="100px" block>mdi-alert</v-icon>        
                                                </div>
                                                <h1 class="headline font-weight-bold primary--text px-sm-16">{{ $t('modules.portalalumno.academic_charge.subjects.debt.orNoSubjects') }}</h1>
                                                <div class="mt-10 mb-5 d-flex justify-center align-center margin-bot">
                                                    <Button :text="$t('modules.portalalumno.academic_charge.subjects.debt.action')" @clicked="redirectPayments" primary></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import ImageBanner from '@/components/Banner/ImageBanner';
    import { $materias } from '../Services'
    import SubjectItem from '../Components/SubjectItem'
    import { mapGetters } from 'vuex'

    export default {
        name: 'SubjectsView',
        components: {
            Breadcrumbs,
            SubjectItem,
            Button,
            ImageBanner,
        },
        data() {
            return {
                hasOptatives: false,
                loading: false,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                debt: false,
                subjects: [],
                availableSubjects: [],
            }
        },
        mounted () {
            this.getSubjects()
        },
        computed: {
            canEnroll() {
                const error = this.availableSubjects.filter(subject => subject.selected && subject.matElectivas && !subject.selecMatElectId)?.length > 0
                if (error) return false
                else return this.canCreate && this.availableSubjects.filter(subject => subject.selected)?.length > 0
            },
            ...mapGetters({
                studentUserId: 'commons/getStudentUserID',
                studentRegister: 'commons/getStudentActualRegister',
                student: 'commons/getStudentData',
            }),
            canCreate() {
                return this.$permissions.portalalumno.academic_charge.subjects.CREATE;
            },
            userId() {
                return this['commons/getUserID']
            },
            ...mapGetters([
                'commons/getUserID'
            ]),
        },
        methods: {
            redirectPayments() {
                this.$router.push('/payments');
            },
            removePopup(subject) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.portalalumno.academic_charge.subjects.unenroll_title_confirmation'),
                    content: [{ value: subject.selecMatElect ? subject.selecMatElect : subject.materia }],
                    actionPrimary: { text: this.$t('modules.portalalumno.academic_charge.subjects.unenroll'), callback: () => this.removeSubject(subject) },
                    actionSecondary: { text: this.$t('actions.cancel'), callback () { subject.selected = true } },
                    icon: { color: 'warning', name: 'mdi-alert' },
                    color: 'primary',
                });
            },
            async removeSubject(subject) {
                const params = {
                    usuario: this.userId ? this.userId : this.studentUserId,
                    leg: this.studentRegister,
                    subjectId : subject.elecId ? subject.elecId : subject.matId,
                    turnoCursadoId: subject.turnoCursadoId,
                }

                try{
                    this.$trackEvent('Asignaturas', 'Desinscripcion', 'Inscripcion a asignaturas')
                    await $materias.delete(null, { params }, 'inscripcion')
                    this.createButton.success = true;
                } catch {
                    this.createButton.error = true;
                } finally {
                    this.createButton.loading = false
                    this.getSubjects()
                }
            },
            async getSubjects() {
                this.loading = true
                const resp = await $materias.find('inscripcion', null, {params: {leg: this.studentRegister}})
                this.hasOptatives = false
                resp.data.items.map(subject => {
                    if (subject.optativa) this.hasOptatives = true
                    subject.selected = (subject.inscripto == true)
                    return subject
                })
                
                this.subjects = resp.data.items.filter(subject => subject.inscripto == true)
                this.debt = this.student.studentDue && this.student.studentDue.countPaymentsDebt >= 3 && !this.student.exception
                this.availableSubjects = resp.data.items.filter(subject => subject.inscripto == false)

                this.loading = false
            },   
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            confirmPopup() {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.portalalumno.academic_charge.subjects.enroll_title_confirmation'),
                    content: null,
                    actionPrimary: { text: this.$t('actions.confirm'), callback: () => this.confirm() },
                    actionSecondary: { text: this.$t('actions.cancel'), callback () {} },
                    icon: { color: 'warning', name: 'mdi-alert' },
                    color: 'primary',
                });
            },
            async confirm(){
                if (!this.canEnroll) return false

                try{
                    this.createButton.loading = true;
                    const subjects = this.availableSubjects.filter(subject => subject.selected).map(sub => {
                        return {
                            matId: sub.selecMatElectId ? sub.selecMatElectId : sub.matId,
                            turnoCursadoId: sub.turnoCursadoId,
                            elecId: sub.selecMatElectId ? sub.matId : null,
                        }
                    })
                    const data = {
                        items : subjects,
                        leg: this.studentRegister,
                        user: this.studentUserId ? this.studentUserId : null,
                    }

                    this.$trackEvent('Asignaturas', 'Inscripcion', 'Inscripcion a asignaturas')
                    await $materias.create(data, null, 'inscripcion')
                    this.createButton.success = true;
                } catch {
                    this.createButton.error = true;
                } finally {
                    this.createButton.loading = false
                    this.getSubjects()
                }
            }
        },
    }
</script>

<style lang="sass" scoped>

    .alert-message
        width: 90%
        min-height: 72px
        background-color: rgba(#ed8b00, 10%)
        border-radius: 10px

        p
            flex: 1
            font-size: 12px
            color: rgba(0, 0, 0, 0.75)

    .payment
        border-radius: 5px
        padding: 20px
        border: solid 1px #cecece
        height: 100%
        font-size: 14px

        &-list
            width: 90%
    
    .button-container
        width: 90%
    
</style>