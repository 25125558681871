<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom">
        <Breadcrumbs :title="this.$t('modules.portalalumno.practice.breadcrumbs.title')"></Breadcrumbs>
        <v-row justify="center">
          <v-col class="viewSpaces" cols="12">
            <v-stepper v-model="step" class="stepper paymentStepper elevation-0 stepperPP" :alt-labels="$isMobile">
              <v-stepper-header 
                class="stepper-header rounded-lg elevation-2 mb-5" 
                :class="!$vuetify.breakpoint.mobile ? 'px-10' : ''" 
              >
                    <v-stepper-step class="stepper-step " :complete="step > 1" :color="step > 1 ? 'green' : 'primary'" step="1">
                      <div :class=" step == 1 ? 'primary--text' : '' " class=" ">
                        {{ $vuetify.breakpoint.xs ? $t('modules.portalalumno.practice.stepper.stepper_mobile.step_1') : $t('modules.portalalumno.practice.stepper.stepper_one.step_1') }}
                      </div>
                    </v-stepper-step>
                    <v-divider class="mx-3" ></v-divider>
                    <v-stepper-step class="stepper-step " :complete="step > 2" :color="step > 2 ? 'green' : 'primary'" step="2" >
                      <div :class=" step == 2 ? 'primary--text' : '' " class=" ">
                        {{ $vuetify.breakpoint.xs ? $t('modules.portalalumno.practice.stepper.stepper_mobile.step_2') : $t('modules.portalalumno.practice.stepper.stepper_one.step_2') }}
                      </div>
                    </v-stepper-step>
                    <v-divider class="mx-3" ></v-divider>
                    <v-stepper-step class="stepper-step " step="3">
                      <div :class=" step == 3 ? 'primary--text' : '' " class=" ">
                        {{ $vuetify.breakpoint.xs ? $t('modules.portalalumno.practice.stepper.stepper_mobile.step_3') : $t('modules.portalalumno.practice.stepper.stepper_one.step_3') }}
                      </div>
                    </v-stepper-step>
                    <v-divider class="mx-3" ></v-divider>
                    <v-stepper-step class="stepper-step " step="4">
                      <div :class=" step == 4 ? 'primary--text' : '' " class=" ">
                        {{ $vuetify.breakpoint.xs ? $t('modules.portalalumno.practice.stepper.stepper_mobile.step_4') : $t('modules.portalalumno.practice.stepper.stepper_one.step_4') }}
                      </div>
                    </v-stepper-step>
                    <v-divider class="mx-3" ></v-divider>
                    <v-stepper-step class="stepper-step" step="5">
                      <div :class=" step == 5 ? 'primary--text' : '' " class=" ">
                        {{ $vuetify.breakpoint.xs ? $t('modules.portalalumno.practice.stepper.stepper_mobile.step_5') : $t('modules.portalalumno.practice.stepper.stepper_one.step_5') }}
                      </div>
                    </v-stepper-step>
              </v-stepper-header>  
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row >
                    <v-col md="4" sm="12" :order="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'last' : 'first'">
                      <InfoCards @openModal="openModal" :requirementDates="requirements"/>
                    </v-col>
                    <v-col md="8" sm="12">
                      <div class="text-center pb-8">
                        <div class="boards-title ">
                          <h3 class=" text-left mb-4">
                          {{ studentName }}
                          </h3>
                        </div>
                        <div class="text-caption boards-subtitle mt-0 text-left text--secondary">
                          <h3>
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.subtitle') }}
                          </h3>
                        </div>
                      </div>
                      <RequirementsPP :requirements="requirements" @getNameCompany="getInfo" @setNameBusiness="setNameBusiness" @getConditionsPP="getConditionsPP"/>
                      <div class="mx-auto mt-8 pt-4 d-flex justify-end">
                        <Button
                            :icon="'mdi-chevron-right'"
                            :text="$t('actions.continue')"
                            @clicked="getConditionsPP"
                            :disabled="requirements ?  !(requirements.pagosAlDia && requirements.materiasOK && requirements.enabled) || !name_business  : true"
                        ></Button>
                      </div>
                      <p v-if="!$isTeclab" class="mt-3">
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.button_diabled') }}
                      <a
                        style="text-decoration:none" 
                        class="primary--text"
                        :href="`mailto:${$t('modules.portalalumno.practice.stepper.dateStep.requirements.email')}`"
                      >
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.email') }}
                      </a>
                      </p>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-row>
                    <v-col md="4" sm="12" :order="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'last' : 'first'">
                      <InfoCards @openModal="openModal" :requirementDates="requirements"/>
                    </v-col>
                    <v-col md="8" sm="12">
                      <div class="text-center pb-8">
                        <div class="boards-title">
                          <h3 class="text-left mb-4">
                          {{ $t('modules.portalalumno.practice.stepper.dateStep.about.title') }}
                          </h3>
                        </div>
                        <div class="text-caption boards-subtitle mt-0 text-left">
                          <h2 class="body-2 text--secondary">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.about.subtitle') }}
                          </h2>
                        </div>
                      </div>
                      <AboutPP :companyPractice="companyPractice" @goBack="goBack" @changeStep="changeStep" />
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-row>
                    <v-col md="4" sm="12" :order="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'last' : 'first'">
                      <InfoCards @openModal="openModal" :requirementDates="requirements"/>
                    </v-col>
                    <v-col md="8" sm="12">
                      <v-container>
                        <h2 class="font-weight-regular">
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.title') }}
                        </h2>
                        <span class="text--secondary body-1">
                          {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.first_subtitle') }}
                        </span>
                      </v-container>
                      <AssignCompany :selectedCompany="selectedCompany" :companyPractice="companyPractice"/>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-row>
                    <v-col md="4" sm="12" :order="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'last' : 'first'">
                      <InfoCards @openModal="openModal" :requirementDates="requirements"/>
                    </v-col>
                    <v-col md="8" sm="12">
                      <v-container>
                        <h2 class="font-weight-regular">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.title') }}
                        </h2>
                        <span class="text--secondary body-1">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.subtitle') }}
                        </span>
                      </v-container>
                      <AgreementPP :signatures="signatures" />
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <v-row>
                    <v-col md="4" sm="12" :order="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'last' : 'first'">
                      <InfoCards @openModal="openModal" :requirementDates="requirements"/>
                    </v-col>
                    <v-col md="8" sm="12">
                      <section class="text-center pb-2">
                          <div class="d-flex justify-center align-center mb-4">
                            <v-img
                              max-height="190px"
                              max-width="201px"
                              contain
                              :src="require(`@/assets/images/portalalumno/pp_finished.svg`)"
                            />
                          </div>
                        <div class="boards-title primary--text">
                          <h2 class="primary--text text-center mb-4">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.authorized.title') }}
                          </h2>
                        </div>
                        <div class="text-caption boards-subtitle mt-0 text-center">
                          <h2 class="text--secondary">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.authorized.subtitle') }}
                          </h2>
                        </div>
                        <div class="mt-3 text--secondary font-weight-bold" v-if="!$isTeclab">
                          <p >
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.authorized.text') }}
                          </p>
                          <p>
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.authorized.congratulations') }}
                          </p>
                        </div>
                      </section>
                      <div class="mx-auto justify-center mt-8 d-flex pt-4">
                        <Button
                            :text="$t('modules.portalalumno.practice.stepper.dateStep.authorized.start')"
                            primmary
                            @clicked="goToCanvas"
                        ></Button>
                      </div>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
        </v-row>
      </v-container>
      <ModalPP :open="!accessNextStep" @close="closeModal" :showList="showList" :subitem="subitem" :footer="footer" :list="listItems" :title="titleModal" :text="textModal" :showButton="showButton" @changeValue="changeValue"/>
    </v-container>
  </div>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import RequirementsPP from '../Components/RequirementsPP.vue';
    import InfoCards from '../Components/InfoCards.vue';
    import AboutPP from '../Components/AboutPP.vue';
    import AssignCompany from '../Components/AssignCompany.vue';
    import AgreementPP from '../Components/AgreementPP.vue';
    import ModalPP from '../Components/ModalPP.vue';
    import { mapGetters } from 'vuex'
    import {$presentationLetter, $requirements, $companyPractice, $student, $examenes, $practiceAgreement} from '../Services'
    import download from 'downloadjs';

    export default {
        name: 'ProfessionalizingPracticeView',
        components: {
            Button,
            Breadcrumbs,
            RequirementsPP,
            InfoCards,
            ModalPP,
            AssignCompany,
            AgreementPP,
            AboutPP
        },
        data() {
            return {
                step: 1,
                accessNextStep: true,
                titleModal: null,
                textModal: null,
                razonSocial: null,
                name_business: null,
                listItems: {
                  first_item: null,
                  second_item: null,
                  third_item: null,
                  quarter_item: null,
                  fifth_item: null,
                  sixth_item: null,
                  seventh_item: null,
                  eighth_item: null,
                },
                subitem: {
                  first_text: null,
                  second_text: null
                },
                footer: null,
                showButton: false,
                presentationLetter: null,
                companyPractice: null,
                requirements: null,
                selectedCompany: null,
                studentLocation: null,
                arrayLocation: [],
                showList: false,
                signatures: null
            }
        },
        mounted(){
          this.getRequirements()
          this.getCompanyPractice()
        },
        methods: {
          changeStep(){
            this.step++
          },
          setNameBusiness(value){
            this.name_business = value;
          },
          async getInfo(value){
            this.razonSocial = value;
            try {
              const data = await $presentationLetter.find(null,null,{params:{legajo:`${this.academicRegister}`,razonSocial:`${this.razonSocial}`}});
              this.presentationLetter = data.data;
              download(data.data, this.razonSocial, data.headers.get('content-type'));
            } catch(err){
              return err
            }
          },
          async getRequirements(){
            try {
              const data = await $requirements.find(`${this.academicRegister}`)
              this.requirements = data.data
            }catch (err){
              return false
            }
          },
          async getCompanyPractice(){
            try {
              const data = await $companyPractice.find(null, null, {params: {studentCareerId:`${this.academicRegister}`}})
              this.companyPractice = data.data
              this.selectedCompany = this.companyPractice.tipoEmpresaSeleccionId
              if(this.companyPractice){
                if(!this.companyPractice.estadoPPId){
                    this.step = 1
                } else if(this.companyPractice.estadoPPId == 267 ){
                  this.step = 3
                } else if (this.companyPractice.estadoPPId == 268 || this.companyPractice.estadoPPId == 279){
                  this.getSignatureData()
                  this.step = 4
                } else if ( this.companyPractice.estadoPPId == 269){
                  this.step = 5
                }
              }
            }catch(err){
              return false
            }
          },
          nextStep(value){
            this.accessNextStep = value
            if(!this.accessNextStep){
              this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.title_conditions')
              this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.conditions')
              this.showButton = true
            } else {
              this.accessNextStep = true
              this.step++
              // this.getLocality()
            }
          },
          async getConditionsPP(){
            if (!(this.requirements.pagosAlDia && this.requirements.materiasOK) || !this.name_business) return;
            try {
              const data = await $student.find('ppTermConditionStatus', null, {params: {studentId: `${this.studentId}`, type:'PRACTICA'}})
              this.nextStep(data.data.accepted)
            } catch(err){
              return err
            }
          },
          async changeValue(){
            try {
              const data = await $student.update('signPpTermCondition', null, {params: {studentId: `${this.studentId}`, type: 'PRACTICA' }})
              this.acceptConditions = data.data.accepted
              this.accessNextStep = true
              this.nextStep(true)
            } catch(err){
              return false
            }
          },
          goBack() {
            this.step--;
          },
          async getLocality(){
            try {
              const res = await $examenes.find('provinciasConLeg', null, { params: {leg: `${this.academicRegister}` }})
              const localidad = res.data.localidadesList.find(x => x.id == res.data.localidadId).descripcion 
              const provincia = res.data.provinciasList.find(x => x.id == res.data.provincia).descripcion 
              this.studentLocation = `${localidad} - ${provincia}`
              this.arrayLocation.push(this.studentLocation)
            }catch(err){
              return err
            }
          },
          openModal(value){
            this.accessNextStep = false
            switch (value) {
              case 'aboutPP':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.text')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.subtitle')
                this.showList = true
                this.listItems.first_item = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.first_item')
                this.listItems.second_item = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.second_item')
                this.listItems.third_item = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.third_item')

                break;
              case 'Practice':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.text')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.textPP')
                this.showList = false
                break;
              case 'Requirements':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.text')
                this.showList = true
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_one')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_two')}`
                this.listItems.third_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_three')}`
                this.listItems.quarter_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_four')}`
                this.listItems.fifth_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_five')}`

                break;
              case 'Requirements[0]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.first.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.first.text')
                this.showList = false
                break;
              case 'Requirements[1]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.subtitle')
                this.showList = true
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.first_item')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.second_item')}`
                break;
              case 'Course':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.subtitle')
                this.showList = true
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.list.first_item')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.list.second_item')}`
                break;
              case 'Course[0]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.schedule.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.schedule.text')
                this.showList = false
                break;
              case 'Course[1]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.tasks.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.tasks.text')
                this.showList = false
                break;
              case 'Course[2]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.qualification.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.qualification.text')
                this.showList = false
                break;
              case 'Course[3]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.reglamento.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.reglamento.text')
                this.showList = false
                break;
              case 'Place':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.subtitle')
                this.showList = false
                break;
              case 'Place[0]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.title_list')
                this.showList = true
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.item_one')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.item_two')}`
                this.listItems.third_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.item_three')}`
                this.listItems.quarter_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.item_four')}`
                this.listItems.fifth_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.item_five')}`
                break;
              case 'Place[1]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.search_company.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.search_company.text')
                this.showList = false
                break;
              case 'Place[2]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.list_company.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.list_company.text')
                this.showList = false
                break;
              case 'Place[3]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.text')
                this.showList = false
                break; 
              case 'Exceptions':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.text')
                this.showList = false
                break;  
              case 'Exceptions[0]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.title')
                this.textModal = null
                this.showList = true
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.item_one')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.item_two')}`
                this.listItems.third_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.item_three')}`
                break;   
              case 'Exceptions[1]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.title')
                this.showList = true
                this.textModal = null
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_one')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_two')}`
                this.listItems.third_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_three')}`
                this.listItems.quarter_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_four')}`
                this.listItems.fifth_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_five')}`
                this.listItems.sixth_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_six')}`
                this.listItems.seventh_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_seven')}`
                this.listItems.eighth_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.step_eight')}`
                break;    
              case 'Steps':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.text')
                this.showList = false
                break;   
              case 'Steps[0]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.inscription.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.inscription.text')
                this.showList = false
                break;      
              case 'Steps[1]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.assign.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.assign.text')
                this.showList = false
                break;     
              case 'Steps[2]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.subtitle')
                this.showList = true  
                this.listItems.first_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.company.title')}`
                this.listItems.second_item = `- ${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.singular.title')}`
                this.subitem.first_text = `${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.company.text')}`
                this.subitem.second_text = `${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.singular.text')}`
                this.footer =  `${this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.footer')}`
                break;    
              case 'Steps[3]':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.start.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.start.text')
                this.showList = false 
                break;  
              case 'Calendar':
                this.titleModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.calendar.title')
                this.textModal = this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.calendar.text')
                this.showList = false 
                break;    
              default:
                break;
            }
          },
          closeModal(){
            this.footer = null
            this.subitem.first_text = null
            this.subitem.second_text = null
            this.listItems.first_item = null
            this.listItems.second_item = null 
            this.listItems.third_item = null
            this.listItems.quarter_item = null
            this.listItems.fifth_item = null
            this.listItems.sixth_item = null
            this.listItems.seventh_item = null
            this.listItems.eighth_item = null
            this.accessNextStep = true
          },
          async getSignatureData(){
            if(this.companyPractice.estadoPPId == 268 || this.companyPractice.estadoPPId == 279){
              try{
                const data = await $practiceAgreement.find(null, null, {params: {studentCareerId:`${this.academicRegister}`}})
                this.signatures = data.data
              }catch(err){
                return err
              }
            }
          },
          goToCanvas(){
            this.$redirectToCanvas(this.canvasId, this.studentDataId.student.user.name)
          }
        },
        computed: {
          ...mapGetters({
              studentId: 'commons/getStudentId',
              academicRegister:'commons/getStudentActualRegister',
              studentData: 'commons/getStudentUserData',
              canvasId: 'commons/getCanvasId',
              studentDataId: 'commons/getStudentData',
          }),
          studentName() {
            const user = this.studentData.name ? `${this.studentData.name}` : '';
            return this.$t('modules.portalalumno.practice.stepper.dateStep.requirements.title', {name: user});
          },  
        },
    };
</script>

<style lang="sass" scoped>
.stepperPP
  .v-stepper__step 
    padding: 0 !important
  
  .v-stepper__header 
    border: 1px solid rgba(100, 100, 100, .2)

@media only screen and (max-width: 959px) 
  .v-stepper__header
    display: flex !important
    flex-direction: row !important
    padding : 5px 15px !important
  
  .v-stepper__step
    flex-basis: 0px !important

  ::v-deep .v-stepper__label
    display: flex !important
    white-space: nowrap !important
    font-size: 0.56rem !important

  .v-stepper__header .v-divider 
    margin: 0px 5px !important
    align-self: center !important
</style>