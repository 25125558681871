<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template>
        <v-container class="container-custom">
          <Breadcrumbs
            :title="$t('modules.benefits.create.breadcrumbs.title')"
            :description="$t('modules.benefits.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-container>
      </template>
      <v-container class="container-custom px-8">
        <template v-if="!createNewBenefitStatus">
          <v-row justify="center">
            <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
              <OutlinedCard
                :title="$t('modules.benefits.create.title')"
                :subtitle="$t('modules.benefits.create.subtitle')"
                :switch-label="(status) ? $t('enabled') : $t('disabled')"
                :switch-value="status"
                @switchChange="(val) => {this.status = val;}"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? ((!$v.description.required) ? $t('modules.benefits.create.validation_errors.name') : (!$v.description.alphaNumWithSpaces) ? $t('modules.benefits.create.validation_errors.descriptionAlphanumeric') :
                      (!$v.description.maxLength) ? $t('modules.benefits.create.validation_errors.descriptionMaxLength') : (!$v.description.minLength) ? $t('modules.benefits.create.validation_errors.codeMinLength') :
                      $t('modules.benefits.create.validation_errors.name')) :
                      ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.benefits.create.labels.name')} *`"
                      outlined
                      @keypress="$validateAlphanumeric($event, $v.description.$model,40, true)"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3">
                    <v-text-field
                      :error-messages="($v.code.$dirty && $v.code.$invalid) ? ((!$v.code.required) ? $t('modules.benefits.create.validation_errors.code') : (!$v.code.alphaNum) ? $t('modules.benefits.create.validation_errors.codeAlphanumeric') :
                      (!$v.code.maxLength) ? $t('modules.benefits.create.validation_errors.codeMaxLength') :
                      (!$v.code.minLength) ? $t('modules.benefits.create.validation_errors.codeMinLength'): $t('modules.benefits.create.validation_errors.code_unique')) :
                      ''"
                      @blur="validateCode"
                      :loading="loadingCode"
                      v-model="$v.code.$model"
                      :label="`${$t('modules.benefits.create.labels.code')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-select
                      :label="`${$t('modules.benefits.create.labels.benefit_type')} *`"
                      :error-messages="($v.selectedBenefitType.$dirty && $v.selectedBenefitType.$invalid) ? $t('modules.benefits.create.validation_errors.type') : ''"
                      :items="benefitTypes"
                      item-text="meaning"
                      outlined
                      return-object
                      @blur="$v.selectedBenefitType.$touch()"
                      v-model="$v.selectedBenefitType.$model"
                    ></v-select>
                  </v-col>
                  <v-col sm="3">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="from"
                      transition="scale-transition"
                      offset-y
                      bottom
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="from"
                          :label="`${$t('modules.benefits.create.academic_performance.from')} *`"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          v-on="on"
                          class="no-message"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        no-title
                        color="primary"
                        scrollable
                        :min="(()=>(new Date().toISOString().substr(0, 10)))()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">{{ $t('actions.cancel') }}</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(from)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-divider class="mt-6 mb-8"></v-divider>
                <AcademicPerformance
                  v-if="$v.selectedBenefitType.$model.value === this.benefitType.academic"
                  :billingProducts="billingProducts"
                  :academic="$v.academic"
                  @removeGroup="(index) => removeGroup(academic.groups, index)"
                  @addGroup="(mockup) => addGroup(academic.groups, mockup)"
                ></AcademicPerformance>
                <Community
                  v-else-if="[this.benefitType.community, this.benefitType.referredCompany, this.benefitType.other].includes($v.selectedBenefitType.$model.value)"
                  :billingProducts="billingProducts"
                  :productSaving="$v.productSaving"
                  @removeGroup="(index) => removeGroup(productSaving.groups, index)"
                  @addGroup="(mockup) => addGroup(productSaving.groups, mockup)"
                ></Community>
                <UniqueDiscount
                  v-else-if="$v.selectedBenefitType.$model.value === this.benefitType.uniqueDiscount"
                  :friend="$v.uniqueDiscount"
                ></UniqueDiscount>
                <ReferredFriend
                  v-else-if="$v.selectedBenefitType.$model.value === this.benefitType.referredFriend"
                  :friend="$v.referredFriend"
                ></ReferredFriend>
              </OutlinedCard>
              <AcademicPerformanceRules
                v-if="$v.selectedBenefitType.$model.value === this.benefitType.academic"
                :rules="$v.academic_rules"
                @removeGroup="(index) => removeGroup(academic_rules.groups, index)"
                @addGroup="(mockup) => addGroup(academic_rules.groups, mockup)"
              ></AcademicPerformanceRules>
              <OutlinedCard
                :title="$t('modules.benefits.create.sectionDocumentation.title')"
                :subtitle="$t('modules.benefits.create.sectionDocumentation.subtitle')"
                class="mb-6"
              >
                <TransferList
                  :avatar="false"
                  :loading="loadingSearchDocs"
                  :firstListTitle="$t('modules.benefits.create.sectionDocumentation.transferList.firstTitle')"
                  :secondListTitle="$t('modules.benefits.create.sectionDocumentation.transferList.secondTitle')"
                  :availableItems.sync="docs"
                  :added-items.sync="docsAdded"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchDocs"
                  @searchItemRightOnBackend="searchDocsAdded"
                ></TransferList>
                 <!-- <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-show="docsAdded.length === 0"
                >{{ $t('modules.benefits.create.sectionDocumentation.no_select') }}</v-alert> -->
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/benefits')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="$t('actions.error')"
                  :disabled="!canCreateBenefit || ($v.formValid.$anyError || $v.code.$invalid ||  $v.description.$invalid )  || !dataValid"
                  @end="resetButtonValues()"
                  @clicked="createBenefitPopUp"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
        <SuccessMessage
          v-else
          :type="successMessage.type"
          :title="successMessage.title"
          :actionPrimary="successMessage.actionPrimary"
          :actionSecondary="successMessage.actionSecondary"
        />
      </v-container>
    </v-container>
  </div>
</template>

<script>
import AcademicPerformance from '../Components/AcademicPerformance/AcademicPerformance';
import AcademicPerformanceRules from '../Components/AcademicPerformance/AcademicPerformanceRules';
import Community from '../Components/Community/Community';
import ReferredFriend from '../Components/ReferredFriend/ReferredFriend';
import UniqueDiscount from '../Components/UniqueDiscount/UniqueDiscount';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $types, $products, $benefits, $documentations } from '../Services';
import { required, requiredUnless, alphaNum, maxLength, maxValue, minLength, minValue, helpers  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'BenefitsCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    AcademicPerformance,
    AcademicPerformanceRules,
    Community,
    ReferredFriend,
    UniqueDiscount,
    TransferList,
  },
  data () {
    return {
      loadingSearchDocs: false,
      docs:[],
      docsAdded:[],
      createNewBenefitStatus: false,
      loadingCode: false,
      codeUnique: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      billingProducts: [],
      successMessage: {
        type: 'success',
        title: this.$t('modules.benefits.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.benefits.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/benefits') } },
        actionSecondary: { text: this.$t('modules.benefits.create.success_message.actions.secondary_text'), callback: () => { this.createNewBenefitStatus = false } }
      },
      benefitType: {
        referredFriend: 10,
        community: 47,
        academic: 48,
        referredCompany: 292,
        other: 357,
        uniqueDiscount: 410
      },

      status: true,
      description: null,
      code: null,
      selectedBenefitType: [],
      benefitTypes: [],
      menu: false,
      from: new Date().toISOString().substr(0, 10),

      academic: {
        groups: [
          {
            selectedProduct: [],
            percentage: null,
            amount: null,
          }
        ],
        average: null,
        subjects: null,
      },
      academic_rules: {
        groups: [
          {
            dayListFrom: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
            dayListTo: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
            dayFrom: null,
            monthFrom: { id : null},
            dayTo: null,
            monthTo: {id : null},
          }
        ]
      },
      productSaving: {
        groups: [
          {
            selectedProduct: [],
            percentage: null,
            amount: null,
          }
        ],
      },
      referredFriend: {
        referredAmount: null,
        referAmount: null,
      },
      uniqueDiscount: {
        referredAmount: null,
      },
    }
  },
  validations() {
    return {
      description: {
        required,
        alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
        maxLength: maxLength(40),
        minLength: minLength(3)
      },
      code: {
        required,
        alphaNum,
        maxLength: maxLength(10),
        minLength: minLength(3),
        unique() {
          return this.codeUnique !== false;
        },
      },
      selectedBenefitType: {required},
      academic: {
        groups: {
          $each: {
            selectedProduct: {required},
            percentage: {required: requiredUnless('amount'), maxValue: maxValue(100), minValue: minValue(1)},
            amount: {required: requiredUnless('percentage'), maxValue: maxValue(10000000000000), minValue: minValue(1)}
          }
        },
        average: {required, maxValue: maxValue(this.$countryConstants.maxAverage), minValue: minValue(1)},
        subjects: {required, maxValue: maxValue(1000), minValue: minValue(1)},
      },
      academic_rules: {
        groups: {
          $each: {
            dayFrom: {required},
            monthFrom: {required},
            dayTo: {required},
            monthTo: {
              required,
              validateDate() {
                const result = this.academic_rules.groups.map(element => {
                  if (element.monthFrom.id < element.monthTo.id) {
                    return true
                  } else if (element.monthFrom.id == element.monthTo.id && parseInt(element.dayFrom) < parseInt(element.dayTo)) {
                    return true
                  }
                  return false
                })
                return !result.includes(false)
              }
            },
          }
        }
      },
      productSaving: {
        groups: {
          $each: {
            selectedProduct: {required},
            percentage: {required: requiredUnless('amount'), maxValue: maxValue(100),},
            amount: {required: requiredUnless('percentage'), maxLength: maxLength(10), minValue: minValue(1)}
          }
        },
      },
      referredFriend: {
        referredAmount: {required, minValue: minValue(1)},
        referAmount: {required, minValue: minValue(1)},
      },
      uniqueDiscount: {
        referredAmount: {required, minValue: minValue(1)},
      },
      formValid: ['description', 'code', 'selectedBenefitType', 'academic_rules', 'referredFriend', 'productSaving', 'academic', 'uniqueDiscount']
    }
  },
  computed: {
    canCreateBenefit () {
      return this.$permissions.portaladministrativo.prices.benefits.CREATE;
    },
    dataValid () {
      if ((this.selectedBenefitType.value === this.benefitType.academic) && this.$v.academic.$anyDirty && !this.$v.academic.$anyError && this.$v.academic_rules.$anyDirty && !this.$v.academic_rules.$anyError) {
        return true;
      } else if (([this.benefitType.community, this.benefitType.referredCompany, this.benefitType.other].includes(this.selectedBenefitType.value)) && this.$v.productSaving.$anyDirty && !this.$v.productSaving.$anyError) {
        return true;
      } else if ((this.selectedBenefitType.value === this.benefitType.referredFriend) && this.$v.referredFriend.$anyDirty && !this.$v.referredFriend.$invalid) {
        return true;
      } else if ((this.selectedBenefitType.value === this.benefitType.uniqueDiscount) && this.$v.uniqueDiscount.$anyDirty && !this.$v.uniqueDiscount.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({userId: 'commons/getUserID',})
  },
  methods: {
    async validateCode () {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $benefits.find('exists', null, { params: { code: this.$v.code.$model } });
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    searchDocs (event) {
      this.getBenefitsDocs(event);
    },
    searchDocsAdded (event) {
      this.docsAdded = event
    },
    async getBenefitsDocs (doc) {
      this.loadingSearchDocs = true
      let documentations = [];
      if (doc) {
        documentations = await $documentations.find(null, null, {
          params: { name: doc, length: this.paginationLimit }
        });
      } else {
        documentations = await $documentations.find(null, null, {
          params: { length: this.paginationLimit }
        });
      }
      if (documentations.data && documentations.data.length) {
        documentations = documentations.data.map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
        this.docs = documentations;
      }
      this.loadingSearchDocs = false
    },
    validCommunity () {
      let valid = true;
      this.$v.productSaving.groups.$each.$iter.forEach(item => {
        if (item.selectedProduct.$error || (item.amount.$error && item.percentage.$error)) valid = false;
      });
      return valid;
    },
    removeGroup (group, index) {
      group.splice(index, 1)
    },
    addGroup (group, mockup) {
      group.push({
        ...mockup
      });
    },
    createBenefitPopUp () {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createBenefit() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createBenefit();
      }
    },
    async createBenefit () {
      if (!this.canCreateBenefit) return false;
      this.createButton.loading = true;

      const data = {
        status: this.status,
        description: this.description,
        code: this.code,
        from: this.from,
        type: { value: this.selectedBenefitType.value },
        details: [],
        documentations: this.docsAdded.map(doc=>{
          return{
            documentation:{
              id: doc.id
            }
          }
        }),
        coursesCount: null,
        average: null,
        rules: [],
        benefitReferrerAmount: null,
        benefitReferredAmount: null,
        userId: this.userId,
      }

      switch (this.selectedBenefitType.value) {
        case this.benefitType.academic:
          this.academic.groups.forEach(group => {
            data.details.push({
              billingProduct: { id: group.selectedProduct.id },
              amount: group.amount,
              percentage: group.percentage,
            })
          });
          data.coursesCount = this.academic.subjects;
          data.average = this.academic.average;
          this.academic_rules.groups.forEach(group => {
            data.rules.push({
              dayFrom: group.dayFrom,
              monthFrom: group.monthFrom.id,
              dayTo: group.dayTo,
              monthTo: group.monthTo.id,
            })
          });
          break;
        case this.benefitType.referredFriend:
          data.details = null
          data.rules = null
          data.benefitReferrerAmount = this.referredFriend.referAmount;
          data.benefitReferredAmount = this.referredFriend.referredAmount;
          break;
        case this.benefitType.uniqueDiscount:
          data.details = null
          data.rules = null
          data.benefitReferredAmount = this.uniqueDiscount.referredAmount;
          break;
        default:
          this.productSaving.groups.forEach(group => {
            data.details.push({
              billingProduct: { id: group.selectedProduct.id },
              amount: group.amount,
              percentage: group.percentage,
            })
          });
          break;
      }

      try {
        await $benefits.create(data);

        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.benefits.create.success_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.benefits.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/benefits') } };
        this.successMessage.actionSecondary = { text: this.$t('modules.benefits.create.success_message.actions.secondary_text'), callback: () => { this.createNewBenefitStatus = false } };

        this.createNewBenefitStatus = true;
        this.createButton.success = true;
        this.clearData();
      } catch (error) { // eslint-disable-line
        this.successMessage.type = 'error';
        if (error.code && error.code === 'E203') {
          this.successMessage.title = this.$t('modules.benefits.create.error_message.repeated');
        } else {
          this.successMessage.title = this.$t('modules.benefits.create.error_message.title');
        }
        this.successMessage.actionSecondary = null;
        this.successMessage.actionPrimary = { text: this.$t('modules.benefits.create.success_message.actions.back'), callback: () => { this.createNewBenefitStatus = false } }

        this.createNewBenefitStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        this.resetButtonValues();
      }
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.loadingCode = false;
      this.codeUnique = null;
      this.status = true;
      this.description = null;
      this.code = null;
      this.selectedBenefitType = [];
      this.from = new Date().toISOString().substr(0, 10);
      this.academic = {
        groups: [
          {
            selectedProduct: [],
            percentage: null,
            amount: null,
          }
        ],
        average: null,
        subjects: null,
      };
      this.academic_rules = {
        groups: [
          {
            dayListFrom: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
            dayListTo: this.$t('modules.benefits.create.academic_performance.rules.dayList.31'),
            dayFrom: null,
            monthFrom: null,
            dayTo: null,
            monthTo: null,
          }
        ]
      };
      this.productSaving = {
        groups: [
          {
            selectedProduct: [],
            percentage: null,
            amount: null,
          }
        ],
      };
      this.referredFriend = {
        referredAmount: null,
        referAmount: null,
      };
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    async fetchData () {
      const allData = Promise.all([
        $types.find(null, null, { params: { type: 'BENEFIT_TYPE' } }),
        $products.find(null, null, { params: { page: 0, length: 500 } }),
      ]);


      const [benefitTypes, billingProducts] = await allData;
      this.benefitTypes = benefitTypes.data;
      this.billingProducts = billingProducts.data.content;

    }
  },
  mounted () {
    this.fetchData()
    this.getBenefitsDocs()
  },
}
</script>
