<template>
  <div>
    <v-card
      class="mb-5 pa-2 rounded-lg elevation-0 primary"
      :class="$isTeclab ? ' lighten-5' : ' lighten-1'"
    >
      <v-card-title class="primary--text font-weight-bold body-1">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_models.title"
          )
        }}
      </v-card-title>
      <p class="mx-4 text--secondary caption mb-0">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_models.text"
          )
        }}
      </p>
      <v-card-actions v-if="$isTeclab" class="ml-2 mt-0 caption">
        <a
          href="https://informacion.teclab.edu.ar/hubfs/PP/Portal%20Alumno/ConveniosPP.pdf"
          target="_blank"
          >Descargalos acá</a
        >
      </v-card-actions>
      <v-card-actions v-else class="ml-2 mt-0 caption">
        <a
          href="https://6058217.fs1.hubspotusercontent-na1.net/hubfs/6058217/Direcci%C3%B3n%20de%20Egresados%20y%20Empleabilidad/Inscripci%C3%B3n%20pr%C3%A1cticas/Convenio%20Individual%20de%20Pr%C3%A1cticas%20(ejemplo).pdf"
          target="_blank"
          >Descargalos acá</a
        >
      </v-card-actions>
    </v-card>
    <v-card
      class="mb-5 pa-2 rounded-lg elevation-0 primary"
      :class="$isTeclab ? 'lighten-5' : ' lighten-1'"
    >
      <v-card-title class="primary--text font-weight-bold body-1">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_rules.title"
          )
        }}
      </v-card-title>
      <p class="mx-4 text--secondary caption">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_rules.text"
          )
        }}
      </p>
      <p v-if="$isTeclab" class="mx-4 text--secondary caption">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_rules.text_2"
          )
        }}
        <a
          href="https://informacion.teclab.edu.ar/hubfs/PP/Portal%20Alumno/ReglamentoPP.pdf"
          target="_blank"
          >acá</a
        >
      </p>
      <p v-else class="mx-4 text--secondary caption">
        <a
          href="https://6058217.fs1.hubspotusercontent-na1.net/hubfs/6058217/Direcci%C3%B3n%20de%20Egresados%20y%20Empleabilidad/Inscripci%C3%B3n%20pr%C3%A1cticas/Reglamento%20de%20Pr%C3%A1cticas%20y%20Titulaciones%20IPP.pdf"
          target="_blank"
        >
          {{
            $t(
              "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_rules.text_2"
            )
          }}
        </a>
      </p>
      <p v-if="$isTeclab" class="mx-4 text--secondary caption">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_rules.text_3"
          )
        }}
        <a
          href="https://informacion.teclab.edu.ar/hubfs/PP/Portal%20Alumno/PPFF.pdf"
          target="_blank"
          >acá</a
        >
      </p>
    </v-card>
    <v-card
      :class="$isTeclab ? 'lighten-5' : ' lighten-1'"
      class="mb-5 pa-2 rounded-lg elevation-0 primary"
    >
      <v-card-title class="primary--text font-weight-bold body-1">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.title"
          )
        }}
      </v-card-title>
      <v-list v-if="$isTeclab" class="primary lighten-5 font-weight-bold">
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.text"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="text--secondary">
            <v-list-item-content>
              <p class="caption">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.textPP"
                  )
                }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <!-- <v-list-item-title @click="$emit('openModal', 'Requirements')"> -->
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <div class="caption text--secondary">
            <p class="mb-0 ml-2">
              {{
                $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.text"
                )
              }}
            </p>
            <ul class="mb-2">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_one"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_two"
                  )
                }}
              </li>
            </ul>
            <p class="mb-0 ml-2">
              {{
                $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_three"
                )
              }}
            </p>
            <ul class="mb-2">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_four"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.list.item_five"
                  )
                }}
              </li>
            </ul>
            <v-list-group sub-group no-action>
              <template v-slot:activator>
                <p class="mb-0 text--secondary">
                  {{
                    $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.first.title"
                    )
                  }}
                </p>
              </template>
              <ul class="mb-2 text--secondary">
                <li>
                  {{
                    $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.first.text"
                    )
                  }}
                </li>
              </ul>
            </v-list-group>
            <v-list-group sub-group no-action>
              <template v-slot:activator>
                <p class="mb-0 text--secondary">
                  {{
                    $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.title"
                    )
                  }}
                </p>
              </template>
              <p class="mb-1 ml-2 text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.subtitle"
                  )
                }}
              </p>
              <ul class="text--secondary">
                <li>
                  {{
                    $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.first_item"
                    )
                  }}
                </li>
                <li>
                  {{
                    $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.first_item"
                    )
                  }}
                </li>
              </ul>
            </v-list-group>
          </div>
        </v-list-group>
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <!-- <v-list-item-title @click="$emit('openModal', 'Course')"> -->
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="caption text--secondary">
            <v-list-item-content>
              {{
                $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.subtitle"
                )
              }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="caption text--secondary">
            <ul>
              <li class="mb-1">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.list.first_item"
                  )
                }}
              </li>
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.list.second_item"
                  )
                }}
              </li>
            </ul>
          </v-list-item>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <p class="text--secondary caption mb-0">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.schedule.title"
                  )
                }}
              </p>
            </template>
            <ul class="caption text--secondary">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.schedule.text"
                  )
                }}
              </li>
            </ul>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <p class="text--secondary caption mb-0">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.tasks.title"
                  )
                }}
              </p>
            </template>
            <ul class="caption text--secondary">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.tasks.text"
                  )
                }}
              </li>
            </ul>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <p class="text--secondary caption mb-0">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.qualification.title"
                  )
                }}
              </p>
            </template>
            <ul class="caption text--secondary">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.qualification.text"
                  )
                }}
              </li>
            </ul>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <p class="text--secondary caption mb-0">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.reglamento.title"
                  )
                }}
              </p>
            </template>
            <ul class="caption text--secondary">
              <li>
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.reglamento.text"
                  )
                }}
              </li>
            </ul>
          </v-list-group>
        </v-list-group>
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <!-- <v-list-item-title @click="$emit('openModal', 'Place')">  -->
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="caption text--secondary">
            {{
              $t(
                "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.subtitle"
              )
            }}
          </v-list-item>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <span class="caption text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.title"
                  )
                }}
              </span>
            </template>
            <div class="caption text--secondary">
              <p class="mb-0">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.title_list"
                  )
                }}
              </p>
              <ul>
                <li
                  v-for="text in $t(
                    'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements'
                  )"
                  :key="text"
                >
                  {{ text }}
                </li>
              </ul>
            </div>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <span class="caption text--secondary">
                {{
                  $t( 'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.search_company.title')
                }}
              </span>
            </template>
              <p class="caption text--secondary mb-0 ml-1">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.search_company.text"
                  )
                }}
              </p>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <span class="caption text--secondary">
                {{
                  $t( 'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.list_company.title')
                }}
              </span>
            </template>
              <p class="caption text--secondary mb-0 ml-1">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.list_company.text"
                  )
                }}
              </p>
          </v-list-group>
        </v-list-group>
          <!--  -->
        <v-list-group >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="caption text--secondary">
            {{
              $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.text"
              )
            }}
          </v-list-item>
          <v-list-group sub-group no-action >
            <template v-slot:activator>
              <span class="caption text--secondary">
                {{
                  $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.title"
                  )
                }}
              </span>
            </template>
              <ul 
                class="caption text--secondary mb-0 ml-1"
              >
                <div
                  v-for="text in $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements')"
                  :key="text"
                >
                  <li v-if="text!='Requisitos'" >
                      {{ text }}
                  </li>
                </div>
              </ul>
          </v-list-group>
          <v-list-group sub-group no-action >
            <template v-slot:activator>
              <span class="caption text--secondary">
                {{
                  $t(
                      "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.title"
                  )
                }}
              </span>
            </template>
              <ul 
                class="caption text--secondary mb-0 ml-1"
              >
                <div
                  v-for="text in $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps')"
                  :key="text"
                >
                  <li v-if="text!='Paso a paso'" >
                      {{ text }}
                  </li>
                </div>
              </ul>
          </v-list-group>
        </v-list-group>
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <!-- <v-list-item-title @click="$emit('openModal', 'Steps')"> -->
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="caption text--secondary">
          {{
            $t(
              "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.text"
            )
          }}
          </v-list-item>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <div class="caption text--secondary">
                  {{ $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.inscription.title'
            ) }}
              </div>
            </template>
            <div class="caption text--secondary">
            {{
                $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.inscription.text')
            }}
            </div>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <div class="caption text--secondary">
                  {{ $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.assign.title'
            ) }}
              </div>
            </template>
            <div class="caption text--secondary">
              {{
                $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.assign.text')
              }}
            </div>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <div class="caption text--secondary">
                  {{ $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.title'
            ) }}
              </div>
            </template>
            <div class="caption text--secondary">
              {{
              $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.subtitle'
            )   
              }}
            </div>
            <ul class="caption text--secondary" v-if="$isTeclab">
              <li> {{
            $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.singular.title')
              }} </li>
              <li> {{
            $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.singular.text')
              }} </li>
              <li> {{
            $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.footer')
              }} </li>
            </ul>
            <ul class="caption text--secondary" v-else>
              <li> {{
            $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.company.title')
              }} </li>
              <li> {{
            $t('modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.agreement.company.text')
              }} </li>
            </ul>
          </v-list-group>
          <v-list-group sub-group no-action>
            <template v-slot:activator>
              <div class="caption text--secondary">
                  {{ $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.start.title'
            ) }}
              </div>
            </template>
            <div class="caption text--secondary">
                  {{ $t(
              'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.start.text'
            ) }}
            </div>
          </v-list-group>
        </v-list-group>
        <!--  -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text--secondary">
                {{
                  $t(
                "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.calendar.title"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="caption text--secondary">
          {{
            $t(
                "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.calendar.text"
            )
          }}
          </v-list-item>
          <ul class="caption text--secondary" v-if="requirementDates">
            <li>
              <p class="mb-0">
                Fecha de cursado
              </p>
              <p >
                {{
                  requirementDates.fechaProximoComienzo + ' - ' + requirementDates.fechaFinPP
                }}
              </p>
            </li>
            <li>
              <p class="mb-0">
                Fecha limite
              </p>
              <p >
                  {{
                    requirementDates.fechaLimiteParaMateriasAprobadas
                  }}
              </p>

            </li>
          </ul>
        </v-list-group>
        <!--  -->
      </v-list>
      <v-list v-else class="primary lighten-1 text--secondary">
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text--secondary font-weight-bold">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.text"
                  )
                }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item class="text--secondary">
            <v-list-item-content>
              <p class="caption mb-2">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.subtitle"
                  )
                }}
              </p>
              <p class="caption mb-2">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.first_item"
                  )
                }}
              </p>
              <p class="caption mb-2">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.second_item"
                  )
                }}
              </p>
              <p class="caption mb-2">
                {{
                  $t(
                    "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.practice.third_item"
                  )
                }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!--  -->
        <v-list-item>
          <v-list-item-title>
            <a
              class="font-weight-bold"
              target="_blank"
              href="https://6058217.fs1.hubspotusercontent-na1.net/hubfs/6058217/Direcci%C3%B3n%20de%20Egresados%20y%20Empleabilidad/Inscripci%C3%B3n%20pr%C3%A1cticas/Preguntas%20frecuentes%20-%20Pr%C3%A1cticas%20IPP.pdf"
            >
              {{
                $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.questions"
                )
              }}
            </a>
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!--  -->
        <v-list-item>
          <v-list-item-title class="text-wrap">
            <a class="font-weight-bold" target="_blank" href="https://ipp.hiringroomcampus.com/">
              {{
                $t(
                  "modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.opportunities"
                )
              }}
            </a>
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>

    <div v-if="$isTeclab" class="body-2">
      <h4 class="mt-2 primary--text">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.help_title"
          )
        }}
      </h4>
      <span
        v-for="word in $t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.help_text'
        ).split(' ')"
        :key="word"
        class="text--secondary"
      >
        <span v-if="word != 'WhatsApp'"> {{ word }} </span>
        <a
          v-else
          href="https://api.whatsapp.com/send?phone=541156320517"
          target="_blank"
        >
          {{ word }}
        </a>
      </span>
    </div>
    <div v-else class="body-2">
      <h4 class="mt-2">
        {{
          $t(
            "modules.portalalumno.practice.stepper.dateStep.requirements.help_title"
          )
        }}
      </h4>
      <span
        v-for="word in $t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.help_text'
        ).split(' ')"
        :key="word"
        class="text--secondary"
      >
        <span v-if="word != 'practicas@ipp.cl'"> {{ word }} </span>
        <a v-else href="mailto:practicas@ipp.cl">
          {{ word }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCards',
  props:['requirementDates'],
  data() {
    return {
      requirements: [
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.first.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.requirements.second.title'
        )}`,
      ],
      courses: [
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.schedule.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.tasks.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.qualification.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.courses.reglamento.title'
        )}`,
      ],
      place: [
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.requirements.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.search_company.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.list_company.title'
        )}`,
      ],
      exceptions: [
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.requirements.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.place.exceptions.steps.title'
        )}`,
      ],
      steps: [
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.inscription.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.assign.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.signature.title'
        )}`,
        `- ${this.$t(
          'modules.portalalumno.practice.stepper.dateStep.requirements.cards.card_info.steps.start.title'
        )}`,
      ],
    };
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
