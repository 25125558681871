<template>
  <v-container fluid class="d-flex flex-row pa-0 fullHeight">
    <v-container class="container-custom">
      <Breadcrumbs :title="procedureTitle" :description="procedureDescription"></Breadcrumbs>
      <v-row justify="center" class="containerPadding" v-if="!updateProcedureStatus && isCommonTemplate">
        <v-col cols="12">
          <v-sheet color="#f5f6f9" class="px-6 py-3">
            <v-row>
              <v-col>
                <div>
                  <h4 class="primary--text">{{
                      $isTeclab ? $t('modules.procedures.initUpdate.identificationTeclab') :
                        $t('modules.procedures.initUpdate.identificationIpp')
                  }}</h4>
                  <span>{{
                      procedure.student && procedure.student.user.userData.identification ?
                        procedure.student.user.userData.identification :
                        $t('modules.procedures.initUpdate.identificationEmpty')
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.career') }}</h4>
                  <span v-if="procedure.degreeToShow">{{ procedure.degreeToShow.description }}</span>
                  <span v-else>{{
                      procedure.student && procedure.student.studentCareers &&
                      procedure.student.studentCareers.career.description || ''
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.type') }}</h4>
                <span>{{ procedure.processing ? procedure.processing.name : '' }}</span>
              </v-col>
              <v-col
                v-if="procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BENEFICIOS' && procedure.community">
                <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.benefit') }}</h4>
                <span>{{ procedure.community.communityName }}</span>
              </v-col>
              <v-col v-if="procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BECAS'">
                <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.scholarship') }}</h4>
                <span>{{ procedure.scholarship ? procedure.scholarship.description : '' }}</span>
              </v-col>
              <v-col>
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.status') }}</h4>
                  <span>{{ procedure.processingStatus && procedure.processingStatus.meaning || '' }}</span>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="procedure.processingCategory && (procedure.processingCategory.name.toUpperCase() == 'BAJA' || procedure.processingCategory.name.toUpperCase() == 'BECAS')">
              <v-col sm="3">
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.motive') }}</h4>
                  <v-tooltip bottom v-if="procedure.processingsReasonType">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon>mdi-information-outline</v-icon> {{ procedure.processingsReasonType.meaning }}
                      </span>
                    </template>
                    <span>{{ procedure.commentary }}</span>
                  </v-tooltip>
                </div>
              </v-col>
              <template
                v-if="procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BAJA'">
                <v-col sm="3">
                  <div>
                    <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.contact') }}</h4>
                    <span>{{ procedure.details[0].telephoneContact }}</span>
                  </div>
                </v-col>
                <v-col sm="3">
                  <div>
                    <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.contactTime') }}</h4>
                    <span>{{ procedure.details[0].contactTime }}</span>
                  </div>
                </v-col>
              </template>
            </v-row>

            <v-row
              v-if="procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BAJA' && procedure.details.cbu">
              <v-col sm="3">
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.bank') }}</h4>
                  <span>{{ procedure.details.bank }}</span>
                </div>
              </v-col>
              <v-col sm="3">
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.accountCBU') }}</h4>
                  <span>{{ procedure.details.cbu }}</span>
                </div>
              </v-col>
              <v-col sm="3">
                <div>
                  <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.accountHolder') }}</h4>
                  <v-tooltip bottom v-if="procedure.details.justificationNotOwnAccount">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon>mdi-information-outline</v-icon> {{ procedure.details.cuil }}
                      </span>
                    </template>
                    <span>{{ procedure.details.justificationNotOwnAccount }}</span>
                  </v-tooltip>
                  <span v-else>{{ procedure.details.cuil }}</span>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <!-- Cambio de centro -->
        <v-col cols="12"
          v-if="(procedure.originCeoName && procedure.originCeoName.title != null) && procedure.destinationCeoName">
          <FromToCard :from="procedure.originCeoName" :to="procedure.destinationCeoName"></FromToCard>
        </v-col>
        <template v-if="refundDetails">
          <v-col cols="12">
            <v-card outlined>
              <span class="ProceduresInitUpdate-card-title primary--text">{{
                  $t('modules.procedures.initUpdate.receipts')
              }}</span>
              <template v-if="receipts.length">
                <v-data-table :items="Object.values($v.receipts.$each.$iter)" :headers="receiptHeaders"
                  class="superTable__table" item-key="receiptNumber.$model" hide-default-footer show-select
                  @input="setSelectedReceipts">
                  <template v-slot:[`item.receiptNumber`]="{ item }">
                    <v-list-item class="d-flex pl-0">
                      <v-btn text icon color="primary" @click="downloadReceipt(item.url.$model)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <v-list-item-avatar tile class="mx-3">
                        <img :src="require(`@/assets/icons/pdf.svg`)">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title><strong>{{
                            $t('modules.procedures.initUpdate.receiptNumber', {
                              receiptNumber:
                                item.receiptNumber.$model
                            })
                        }}</strong></v-list-item-title>
                        <v-list-item-subtitle>{{
                            $t('modules.procedures.initUpdate.receiptDate', {
                              date:
                                dateToString(item.date.$model)
                            })
                        }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:[`item.amount.$model`]="{ item }">
                    {{ item.amount.$model.toFixed(2) }}
                  </template>
                  <template v-slot:[`item.discountAmount`]="{ item }">
                    <v-text-field v-model="item.discountAmount.$model" outlined
                      dense
                      :error-messages="(item.discountAmount.$dirty && item.discountAmount.$invalid) ?
                    ((!item.discountAmount.required) ? $t('modules.procedures.initUpdate.validations.receiptRepayRequired') :
                      $t('modules.procedures.initUpdate.validations.receiptRepayBetween', { maxAmount: item.amount.$model })) : ''"
                      @blur="item.discountAmount.$touch()">
                      <template v-slot:prepend>
                        <span>{{ $countryConstants.currency }}</span>
                      </template>
                    </v-text-field>
                  </template>
                  <template v-slot:[`item.concept.$model`]="{ item }">
                    <v-chip color="primary" class="mr-2" small
                      v-for="(concept, index) in item.concept.$model.split(' ')" :key="`${concept}-${index}`">
                      {{ concept }}
                    </v-chip>
                  </template>
                  <template v-slot:[`header.data-table-select`]="{ on, props }">
                    <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props"
                      v-on="on"></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
                    <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" :value="isSelected"
                      @input="select($event)"></v-simple-checkbox>
                  </template>
                </v-data-table>
              </template>
              <p class="mx-8" v-else>
                {{ $t('modules.procedures.initUpdate.receiptsEmpty') }}
              </p>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <v-card outlined v-if="!needDocumentation">
              <span class="ProceduresInitUpdate-card-title primary--text">{{
                  $t('modules.procedures.initUpdate.documentation')
              }}</span>
              <v-list v-if="procedure.documentations && procedure.documentations.length">
                <v-list-item two-line v-for="(item, index) in procedure.documentations" :key="index">
                  <v-btn text icon color="primary" @click="downloadDoc(item.id, item.fileName)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn :disabled="!isValidExtension(item)" text icon color="gray"
                    @click='gotoPreviewDocument(item, index)'>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-list-item-avatar tile class="mx-3">
                    <img :src="require(`@/assets/icons/pdf.svg`)">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title><strong>{{ item.fileName }}</strong></v-list-item-title>
                    <v-list-item-subtitle>{{
                        $t('modules.procedures.initUpdate.uploaded', {
                          date:
                            dateToString(item.uploadDate)
                        })
                    }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <template
                    v-if="procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BENEFICIOS' && item.ownerType">
                    <span>{{ $t('modules.procedures.initUpdate.documentOwner') }} </span>
                    <div class="ProceduresInitUpdate-selector-container">
                      <v-text-field class="ml-2" v-model="item.ownerType.meaning" disabled outlined hide-details>
                      </v-text-field>
                    </div>
                  </template>
                  <template
                    v-if="procedure.processingCategory && (procedure.processingCategory.name.toUpperCase() == 'BAJA' || procedure.processingCategory.name.toUpperCase() == 'BECAS')">
                    <span>{{ $t('modules.procedures.initUpdate.documentType') }} </span>
                    <div class="ProceduresInitUpdate-selector-container">
                      <v-text-field class="ml-2" v-model="item.documentation.name" disabled outlined hide-details>
                      </v-text-field>
                    </div>
                  </template>
                  <span class="ml-4">{{ $t('modules.procedures.initUpdate.status') }} </span>
                  <div class="ProceduresInitUpdate-selector-container">
                    <v-select class="ml-2" v-model="item.docStatus" item-text="meaning" return-object
                      :disabled="!isPending" outlined hide-details full-width :items="docStatus">
                      <template slot="selection" slot-scope="data">
                        <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                        <span>{{ data.item.meaning }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                        <span>{{ data.item.meaning }}</span>
                      </template>
                    </v-select>
                  </div>
                </v-list-item>
              </v-list>
              <p class="mx-8" v-else>
                {{ $t('modules.procedures.initUpdate.documentationEmpty') }}
              </p>
            </v-card>
          </v-col>
          <v-col cols="12">
            <h3>{{ $t('modules.procedures.initUpdate.messages') }}</h3>
            <br>
            <v-textarea v-model="procedure.reason" outlined hide-details
              :label="$t('modules.procedures.initUpdate.comments')"></v-textarea>
            <div v-if="isPending && procedure.processingCategory.name.toUpperCase() == 'RETIRO'"
              class="d-flex flex-row-reverse">
              <v-checkbox v-model="raicException" class="mt-0 pt-4 no-message" color="primary"
                :label="$t('modules.procedures.initUpdate.raicException')">
              </v-checkbox>
            </div>
          </v-col>
        </template>
        <v-col cols="6">
          <div class="d-flex justify-start mb-5">
            <Button white :text="refundDetails ? $t('actions.return') : $t('actions.cancel')"
              @clicked="refundDetails ? refundDetails = false : $router.push('/procedures')"
              class="mr-4 cancel"></Button>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex justify-end mb-5" v-if="isPending">
            <v-btn outlined rounded large color="primary" v-if="!notDisapprove" class="ml-5" :disabled="!isPending"
              @click="updateProcedurePopup(procedureStatus[0], $t('modules.procedures.initUpdate.reject'))">
              <v-icon left color="red" size="24">mdi-close-circle</v-icon>
              {{ $t('modules.procedures.initUpdate.reject') }}
            </v-btn>
            <v-btn outlined rounded large color="primary" class="ml-5"
              v-if="canAprove && !refundDetails && procedure.processingCategory && procedure.processingCategory.name.toUpperCase() == 'BAJA' && procedure.details.cbu"
              :disabled="!canUpdateData" @click="showRefundDetails()">
              {{ $t('actions.next') }}
            </v-btn>
            <v-btn outlined rounded large color="primary" class="ml-5" v-else-if="canAprove" :disabled="!canUpdateData"
              @click="updateProcedurePopup(procedureStatus[1], $t('modules.procedures.initUpdate.aprove'))">
              <v-icon left color="green" size="24">mdi-check-circle</v-icon>
              {{ $t('modules.procedures.initUpdate.aprove') }}
            </v-btn>
            <v-btn outlined rounded large color="primary" class="ml-5" v-else :disabled="!canUpdateData"
              @click="updateProcedurePopup(procedureStatus[2], $t('modules.procedures.initUpdate.observe'))">
              <v-icon left color="orange" size="24">mdi-pencil-circle</v-icon>
              {{ $t('modules.procedures.initUpdate.observe') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <template v-if="!updateProcedureStatus && isChangeCareer">
        <changeCareerProcedure :subjects="subjects" :prices="prices" :procedure="procedure" :studyPlan="studyPlan"
          @approve="updateProcedurePopup(procedureStatus[1], $t('modules.procedures.initUpdate.aprove'))"
          @disapprove="updateProcedurePopup(procedureStatus[0], $t('modules.procedures.initUpdate.reject'))"
          @getData="getDataAccordingToStudyPlan"
          >
        </changeCareerProcedure>
      </template>
      <SuccessMessage v-if="updateProcedureStatus" :type="successMessage.type" :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary" />
    </v-container>
  </v-container>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import moment from 'moment'
import Button from '@/components/Button/Button'
import changeCareerProcedure from '../Components/ChangeCareerProcedure'
import FromToCard from '@/components/FromToCard/FromToCard'
import { $procedures, $comparePrices, $approvedSubjects, $types, $receipt } from '../Services';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import download from 'downloadjs';
import { mapGetters } from 'vuex';
import { required, between } from 'vuelidate/lib/validators';

const blackList = ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm']

export default {
  name: 'ProceduresInitUpdate',
  components: {
    Breadcrumbs,
    Button,
    SuccessMessage,
    changeCareerProcedure,
    FromToCard
  },
  data() {
    return {
      documents: [],
      loading: false,
      updateProcedureStatus: false,
      refundDetails: false,
      raicException: false,
      procedure: [],
      prices: [],
      subjects: [],
      studyPlan: null,
      receipts: [],
      selectedReceipts: [],
      receiptHeaders: [
        { text: '', value: 'receiptNumber', sortable: false },
        { text: this.$t('modules.procedures.initUpdate.receiptHeaders.concept'), value: 'concept.$model' },
        { text: this.$t('modules.procedures.initUpdate.receiptHeaders.amount'), value: 'amount.$model' },
        {
          text: this.$t('modules.procedures.initUpdate.receiptHeaders.repay'),
          value: 'discountAmount',
          width: '15%',
          sortable: false
        }
      ],
      successMessage: {
        type: 'success',
        title: 'Tramite actualizado correctamente',
        actionPrimary: {
          text: 'Cerrar', callback: () => {
            this.$router.push('/procedures')
          }
        }
      },
      // TODO: check procedures ids MOCK
      processingsWithoutDocumentation: [15, 18, 19, 20],
      notDisapproveProcedures: [16],

      procedureStatus: [
        { value: 92, text: 'Denegado' },
        { value: 93, text: 'Aprobado' },
        { value: 113, text: 'Observado' },
      ],
      docStatus: [],

    }
  },
  validations: {
    receipts: {
      $each:
      {
        receiptId: { required },
        receiptNumber: { required },
        date: { required },
        concept: { required },
        amount: { required },
        url: { required },
        discountAmount: {
          required,
          between(value, group) {
            return between(0, group.amount)(value)
          }
        }
      }
    }
  },
  async mounted() {
    this.fetchData()
  },
  computed: {
    needDocumentation() {
      return (this.procedure.processing) ? this.processingsWithoutDocumentation.includes(this.procedure.processing.id) : false
    },
    notDisapprove() {
      if (this.procedure.processing) {
        return this.notDisapproveProcedures.includes(this.procedure.processing.id);
      }
      return false
    },
    isCommonTemplate() {
      return (this.procedure.processing) ? this.procedure.processing.id !== 15 : true
    },
    isChangeCareer() {
      //const isRootUser = this.userRoles.find(rol => {return this.$sisConstants('core.rootRolesIds').includes(rol.id)}) !== undefined
      const changeCareer = this.procedure.processing?.id === 15
      /*if(changeCareer && !isRootUser){
        this.redirect();
      }*/
      return changeCareer
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.procedures.UPDATE
    },
    procedureTitle() {
      const user = this.procedure.student ? `${this.procedure.student.user.userData.name} ${this.procedure.student.user.userData.lastname}` : '';
      return this.$t('modules.procedures.initUpdate.title', { procedure: this.procedure.description, name: user });
    },
    procedureDescription() {
      const initDate = moment(this.procedure.created);
      return this.$t('modules.procedures.initUpdate.subtitle', { date: initDate.format('DD-MM-YYYY') });
    },
    isPending() {
      return this.procedure.processingStatus && this.procedure.processingStatus.value !== 92 && this.procedure.processingStatus.value !== 93
    },
    canUpdateData() {
      let allConfirmed = true;
      if (this.procedure.documentations) {
        this.procedure.documentations.forEach(element => {
          if (element.docStatus.value != 94 && element.docStatus.value != 95) allConfirmed = false
        })
      }

      // si no necesita documentación.
      if (this.processingsWithoutDocumentation.includes(this.procedure.processing.id)) return true
      // si requiere documentación, pero el length de la documentación es 0 -> permite observar
      else if (this.procedure.documentations && this.procedure.documentations.length === 0) return true
      // si requiere documentación y existe documentación, se fija que todas hayan sido puestas en algún estado (aprovado, rechazado)
      else return allConfirmed
    },
    canAprove() {
      let allConfirmed = true;
      if (this.procedure.documentations) {
        this.procedure.documentations.forEach(element => {
          if (element.docStatus.value != 94) allConfirmed = false
        })
      }

      // si no necesita documentación.
      if (this.processingsWithoutDocumentation.includes(this.procedure.processing.id)) return true
      // si requiere documentación, pero el length de la documentación es 0 -> permite observar
      else if (this.procedure.documentations && this.procedure.documentations.length === 0) return true
      // si requiere documentación y existe documentación, se fija que todas hayan sido puestas en algún estado (aprovado, rechazado)
      else return allConfirmed
    },
    ...mapGetters({
      userRoles: 'commons/getUserRoles',
      userId: 'commons/getUserID'
    })
  },
  methods: {
    redirect() {
      this.$router.push('/procedures')
    },
    dateToString(date) {
      return new Date(date).toISOString().substr(0, 10)
    },
    setSelectedReceipts(receipts) {
      this.selectedReceipts = receipts.map(receipt => ({
        receiptId: receipt.receiptId.$model,
        receiptNumber: receipt.receiptNumber.$model,
        date: receipt.date.$model,
        concept: receipt.concept.$model,
        amount: receipt.amount.$model,
        url: receipt.url.$model,
        discountAmount: receipt.discountAmount.$model
      }))
    },
    async fetchData() {
      const docStatus = await $types.find(null, null, { params: { type: 'STUDENT_DOC_STATUS' } })
      this.docStatus = docStatus.data
      const procedure = await $procedures.findById(this.$route.params.id);
      this.procedure = procedure.data
      this.studyPlan = {
        description: `${this.procedure.studyPlan.description} - ${this.procedure.studyPlan.degree}`,
        code: this.procedure.studyPlan.description,
        id: this.procedure.studyPlan.id
      }
      this.procedure.destinationCeoName = {
        title: procedure.data.destinationCeoName
      }
      this.procedure.originCeoName = {
        title: procedure.data.originCeoName
      }

      if (procedure.data.details) {
        const [details] = procedure.data.details
        this.procedure.details = details
      }
      this.getDataAccordingToStudyPlan()
    },
    async getDataAccordingToStudyPlan(studyPlanSelected){
      if(studyPlanSelected){
        this.procedure.studyPlan = studyPlanSelected
      }
      if (this.procedure.processing.id === 15) {
        // gets de cambio de carrera
        const [prices, subjects] = await Promise.all([
          $comparePrices.findByStudentRegister(this.procedure.studentRecord, this.procedure.destination),
          $approvedSubjects.findByStudentRegister(this.procedure.studentRecord, this.procedure.destination, studyPlanSelected?.id ? studyPlanSelected?.id : this.procedure.studyPlan.id)
        ])
        this.prices = prices.data.prices;
        this.subjects = subjects.data;
      }
    },
    updateProcedurePopup(status, text) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('alert'),
        content: [{ value: this.$t('modules.procedures.initUpdate.alertMessage.description', { status: text }) }],
        actionPrimary: {
          text: this.$t('actions.accept'), callback: () => {
            this.updateProcedure(status)
          }
        },
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: { color: 'warning', name: 'mdi-alert' },
        color: 'primary'
      });
    },
    async showRefundDetails() {
      this.refundDetails = true
      try {
        const { data } = await $receipt.find('list', null, { params: { student: this.procedure.student.id } })
        this.receipts = data.map(receipt => {
          receipt.discountAmount = receipt.discountAmount?.toFixed(2) || 0
          receipt.date = receipt.receiptDetail.date
          receipt.url = receipt.receiptDetail.fileUrl
          return receipt
        })
      } catch (err) {
        this.receipts = []
        throw err
      }

    },
    async updateProcedure(status) {
      if (!this.canUpdate) return false
      try {
        this.procedure.processingStatus = status
        this.procedure.studentRecord = this.procedure.student.studentCareers.id;
        this.procedure.studyPlanId = this.procedure.studyPlan?.id;
        this.procedure.userUpdateId = this.userId
        if (this.selectedReceipts) {
          this.procedure.receipts = this.selectedReceipts.map(receipt => ({
            facturaId: receipt.receiptId,
            reembolsoArancel: receipt.discountAmount || 0
          }))
        }
        delete this.procedure.studyPlan;

        await $procedures.update(this.$route.params.id, this.procedure, { params: { raicException: this.raicException } })
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.procedures.initUpdate.success_message.title');
        this.successMessage.actionPrimary = {
          text: this.$t('modules.procedures.initUpdate.success_message.actions.primary_text'),
          callback: () => {
            this.$router.push('/procedures')
          }
        };
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning) ? error.codeMeaning : this.$t('modules.procedures.initUpdate.error_message');
        this.successMessage.actionPrimary = {
          text: this.$t('modules.procedures.initUpdate.success_message.actions.back'),
          callback: () => {
            this.updateProcedureStatus = false
          }
        };
      } finally {
        this.updateProcedureStatus = true
      }
    },
    async downloadDoc(id, filename) {
      const response = await $procedures.getDocument(id, { responseType: 'blob' })
      const content = response.headers['content-type'];
      download(response.data, filename, content)
    },
    downloadReceipt(url) {
      window.open(url, '_blank')
    },
    async gotoPreviewDocument(document, index) {
      try {
        if (!this.isValidExtension(document)) return
        if (!this.documents[index]) {
          const file = await this.getRawDocument(document)
          this.documents[index] = file
        }
        window.open(this.documents[index], '_blank')
      } catch (error) {
        return error
      }
    },
    async getRawDocument({ id }) {
      const response = await $procedures.getRawDocument(id, { bodyType: 'blob' })
      return URL.createObjectURL(response.data)
    },
    isValidExtension({ fileName }) {
      const extension = fileName.split('.').pop()
      return !blackList.includes(extension)
    }
  },
}
</script>

<style scoped lang="sass">
.containerPadding
  padding-left: 40px
  padding-right: 40px

.ProceduresInitUpdate-card-title
  background: white
  position: relative
  font-weight: bold
  padding: 5px
  top: -13px
  left: 35px

.ProceduresInitUpdate-flexIcon
  display: flex
  justify-content: center
  align-items: center
  width: 50px

.ProceduresInitUpdate-selector-container
  max-width: 180px
</style>
