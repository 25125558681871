<template>
    <v-col cols="12">
        <outlined-card
            :title="$t('modules.pricing.billing.studenData.title')"
            :shadow="false"
            >   
                <v-container class="ml-2">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <div >
                                <p class="text--disabled mb-0">{{ $t('modules.pricing.billing.studenData.name') }}</p>
                                <span>{{infoStudentBilling.name}} {{infoStudentBilling.lastname}}</span>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="3">
                            <div >
                                <p class="text--disabled mb-0">{{ $t('modules.pricing.billing.studenData.typeIdentification') }}</p>
                                <span>{{infoStudentBilling.typeDni}}</span>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="3">
                            <div class="text-center">
                                <p class="mb-0 text--disabled">{{ $t('modules.pricing.billing.studenData.identification') }}</p>
                                <span>{{infoStudentBilling.dni}}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <div >
                                <p class="mb-0 text--disabled">{{ $t('modules.pricing.billing.studenData.city') }}</p>
                                <span>{{infoStudentBilling.city}}</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="5">
                            <div>
                                <p class="mb-0 text--disabled">{{ $t('modules.pricing.billing.studenData.street') }}</p>
                                <span>{{infoStudentBilling.street}}</span>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="3">
                            <div class="text-center">
                                <p class="mb-0 text--disabled" >{{ $t('modules.pricing.billing.studenData.number') }}</p>
                                <span>{{infoStudentBilling.streetNumber}}</span>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <div >
                                <p class="mb-0 text--disabled">{{ $t('modules.pricing.billing.studenData.floor') }}</p>
                                <span>{{infoStudentBilling.streetFloor}}</span>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <div class="text-center">
                                <p class="mb-0 text--disabled">{{ $t('modules.pricing.billing.studenData.depto') }}</p>
                                <span>{{infoStudentBilling.addressDepartment}}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
        </outlined-card>
    </v-col>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard.vue'
    export default {
        name: 'StudentData',
        props:{
            infoStudentBilling: { type: Object  }
        },
        components:{
            OutlinedCard
        },
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>

    
</style>