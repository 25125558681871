<template>
  <v-row>
    <v-col cols="12" :sm="breadcrumbColSize" class="py-0 breadcrumbs d-flex flex-row align-center">
      <div @click="goBack" class="mr-4 cursor-pointer">
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </div>
      <div class="d-flex flex-column">
        <v-breadcrumbs v-if="!hideBreadcrumb" :items="breadcrumbsList" divider="/" class="body-2 pl-0 pt-0 pb-1 customDivider">
          <template v-slot:item="breadcrumb">
            <v-breadcrumbs-item :to="breadcrumb.item.path" link active-class>
              {{ breadcrumb.item.name }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <h1 class="headline primary--text">{{ title }}</h1>
        <h6 class="caption" v-if="description">{{ description }}</h6>
      </div>
    </v-col>
    <v-col v-if="$slots.default" sm="4" class="d-flex justify-end align-end">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<style lang="sass">
.v-breadcrumbs
  li:last-child
    display: none
</style>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    title: String,
    description: String,
    isDescriptionFullLength: { type: Boolean, default: false },
    callback: { type: Function, default: null },
    analytics: { type: Object, default: null },
    hideBreadcrumb: { type: Boolean, default: false },
  },
  data () {
    return {
      breadcrumbsList: []
    }
  },
  computed: {
    breadcrumbColSize () {
      return this.isDescriptionFullLength ? '12' : '8'
    }
  },
  mounted () {
    this.updateList();
  },
  watch: {
    '$route': function () {
      this.updateList();
    }
  },
  methods: {
    goBack () {
      if (this.analytics?.category && this.analytics?.label) this.$trackEvent(this.analytics.category, 'Cancelar', this.analytics.label)
      if (this.callback !== null) this.callback()
      else {
        if (this.$route.matched.length > 2) {
          const currentRoute = this.$route.matched[this.$route.matched.length - 1];
          const previousRoute = this.$route.matched[this.$route.matched.length - 2];

          if (`${previousRoute.path}/` !== currentRoute.path) { // if not parent index
            this.$router.go(-1);
          } else {
            this.$router.push('/');
          }
        } else {
          this.$router.push('/');
        }
      }
    },
    updateList () {
      const routeMatched = this.$route.matched;
      let breadcrumbsTmp = [...routeMatched];
      const {locale, fallbackLocale} = this.$i18n;      

      breadcrumbsTmp.forEach((item, i) => {
        if (i === 0) {
          item.name = this.$t('components.breadcrumbs.home')
          item.path = '/';
        }
        if (!item.name) {
          if (item.meta.name[locale]) item.name = item.meta.name[locale];
          else item.name = item.meta.name[fallbackLocale];
        }
      });

      // Excluimos el breadcrumb en el index del modulo (tabla)
      breadcrumbsTmp = breadcrumbsTmp.filter(item => item.meta.name !== 'Index');

      this.breadcrumbsList = breadcrumbsTmp;
    }
  },
}
</script>
