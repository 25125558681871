<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="px-0">
      <div class="px-md-10">
        <div class="pl-3 pr-3" v-if="!coupon.payment">

          <v-row align="center">
            <v-col class="d-flex" cols="12">

              <div class="outside-container">
                <v-row>
                  <v-col class cols="12">
                    <div class="icon-wrapper" :class="status">
                      <v-icon v-if="status === 'approved' || status === 'debitOk'" color="success" size="100px">mdi-check-circle</v-icon>
                      <v-icon v-else-if="status === 'in_process'" color="yellow" size="100px">mdi-check-circle</v-icon>
                      <v-icon v-else-if="status === 'rejected' || status === 'debitError'" color="red" size="100px">mdi-close-circle</v-icon>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class cols="12">
                    <h4 class="text-center" :class="(status === 'approved' || status === 'debitOk') ? 'success--text' : ((status === 'in_process') ? 'yellow--text' : 'red--text')">{{ title }}</h4>
                    <p class="text-center message_description">{{ description }}</p>
                    <div class="text-center mt-6">
                      <v-btn rounded color="primary" class="px-10" dark @click="goToHome">Ir a inicio</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>

            </v-col>
          </v-row>
        </div>

        <div v-if="coupon.payment" id="printTicket">
          <template v-if="!isAtmPaymentType">
            <div class="coupon-head">
              {{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.nextSteps', {service: paymentTitle}) }} {{ (resumen.items.adminExpenses.monto || resumen.total) | $currency }}
            </div>
            <div class="servipag pl-3 pr-3 mt-2 mb-2">
              <v-row class="pt-2 pb-2">
                <v-col class="text-left pl-10" cols="6">
                  <img width="auto" height="50" :src="logoPath" :alt="`${institute} Logo`" />
                </v-col>
                <v-col class="text-right pr-10" cols="6">
                  <img
                    v-if="coupon.payment.id == 'servipag'"
                    :src="require('@/assets/Servipag.png')"
                    width="126"
                    alt="Servipag"
                  />
                  <img
                    v-if="coupon.payment.id == 'khipu'"
                    :src="require('@/assets/kiphu.png')"
                    width="126"
                    alt="Kiphu"
                  />
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col class="pt-0 mt-0" cols="12" sm="12">
                  <div class="coupon-info-ctc pt-0 mt-0">
                    <v-row class="pt-0 mt-0">
                      <v-col class="text-left cp-number pl-7" cols="6">
                        <br /><br />
                        {{ `${$t('modules.portalalumno.payments.paymentsStudents.view.coupons.couponNumber')} ${coupon.number}` }}
                      </v-col>
                      <v-col class="text-right" cols="6">
                        <div class="cp-total pr-5">{{$t('modules.portalalumno.payments.paymentsStudents.view.summary.total')}}</div>
                        <div class="cp-amount pr-5 pt-2">{{ (resumen.items.adminExpenses.monto || resumen.total) | $currency }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="pl-7 pr-7 pt-0 pb-0">
                      <v-col cols="12" class="px-0 py-0">
                        <div class="separator"></div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-left cp-sent pl-7" cols="12" sm="12">
                        <span>{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.coupons.sentOn')} ${coupon.creationDate}` }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
  
              <v-row>
                <v-col class="text-center" cols="12" sm="12">
                  <p class="cp-instructions pt-0 mt-0">{{$t('modules.portalalumno.payments.paymentsStudents.view.coupons.instructions')}}</p>
                  <p class="cp-instructions-numbers mb-0 pb-0">{{ coupon.manualOpNumber }}</p>
                  <img v-if="coupon.barcode.codeNumber" class="barcode mt-4" :src="barcodeImage" alt="Barcode">
                  <p class="cp-info mt-4">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.info', {service: $isTeclab ? paymentTitle : 'Servipag.com'}) }}</p>
                  <p class="cp-reminder">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.reminder') }}</p>
                </v-col>
              </v-row>
            </div>
  
            <p class="text-center cp-disclaimer pt-5 mb-1">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.reminder') }}</p>
            <p class="text-center cp-disclaimer">{{ $t('modules.portalalumno.payments.paymentsStudents.view.coupons.disclaimer', {org: institute}) }}</p>
          </template>
          <template v-else>
            <AtmTicket 
                :coupon="coupon" 
                :resumen="resumen" 
                :logoPath="logoPath" 
                :institute="institute">
            </AtmTicket>
          </template>
          <div class="d-flex flex-row btn-container justify-center mb-12 mt-4">
            <Button
              class="mx-4 my-2"
              :text="$t('modules.portalalumno.payments.paymentsStudents.view.coupons.print')"
              @clicked="printCoupon"
            ></Button>
            <Button
              outlined  
              class="mx-4 my-2"
              text="Cancelar"
              @clicked="goToHome"
            ></Button>
          </div>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import Button from '@/components/Button/Button';
  import { mapGetters } from 'vuex'
  import AtmTicket from '../Components/AtmTicket';

  export default {
    name: 'PaymentsCompletedView',
    props: {
      resumen: Object,
      status: String,
      coupon: Object,
    },
    components: {
      Button,
      AtmTicket
    },
    data() {
      return {
        title: null,
        description: null,
      }
    },
    methods: {
      goToHome(){
        this.$router.push('/')
      },
      setMessage(){
        switch(this.status){
          case 'approved':
            this.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successMessage.title');
            this.description = '' // Correccion temporal hasta corregir flujo
            // this.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successMessage.description', {total: $options.filters.$currency(this.resumen.items.adminExpenses.monto || this.resumen.total)});
            break
          case 'in_process':
            this.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.processMessage.title');
            this.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.processMessage.description');
            break
          case 'rejected':
            this.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedMessage.title');
            this.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedMessage.description');
            break
          case 'debitOk':
            this.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successDebitMessage.title');
            this.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.successDebitMessage.description');
            break
          case 'debitError':
            this.title = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedDebitMessage.title');
            this.description = this.$t('modules.portalalumno.payments.paymentsStudents.finalView.rejectedDebitMessage.description');
            break
          default:
            break
        }
      },
      printCoupon() {
        this.$htmlToPaper('printTicket');
      },
    },
    computed: {
      paymentType() {
        return this.coupon.payment?.payment_type_id
      },
      isAtmPaymentType() {
        return this.paymentType === 'atm'
      },
      barcodeImage() {
        if (this.coupon.barcode.type && this.coupon.barcode.codeNumber) return `https://barcode.tec-it.com/barcode.ashx?data=${this.coupon.barcode.codeNumber}&code=${this.coupon.barcode.type}&dpi=96&color=000`
        else return require('@/assets/barcode.png')
      },
      paymentInfo() {
        switch(this.$country) {
          case 'chile':
            return 'https://portal.servipag.com';
          case 'argentina':
            return this.paymentTitle
          case 'peru':
            return this.coupon.externalResourceUrl
          default:
            return this.paymentTitle
        }
      },
      paymentTitle() {
        const title = (this.coupon.payment) ? this.coupon.payment.id : ''
        return title.charAt(0).toUpperCase() + title.slice(1)
      },
      institute() {
        if (this.$isIPP) return 'IPP'
        return 'Teclab'
      },
      ...mapGetters({
        logoPath: 'commons/getLogoImageUrl',
      }),
    },
    watch: {
      status() {
        this.setMessage()
      },
    },
  };
</script>

<style scoped lang="sass">
  .icon-wrapper
    padding: 25px
    border-radius: 50%
    height: 148px!important
    width: 148px!important
    margin: 0 auto
    margin-top: 30px
    &.approved
      background-color: rgba(59, 192, 0, 0.1) !important
    &.in_process
      background-color: rgba(59, 192, 0, 0.1) !important
    &.rejected
      background-color: rgba(244, 67, 54, 0.1) !important

  h4
    font-family: "DM Sans", sans-serif
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 1.29
    letter-spacing: -0.72px
    text-align: center

  .message_description
    font-family: "Roboto", sans-serif
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.43
    letter-spacing: 0.25px
    text-align: left
    color: rgba(0, 0, 0, 0.87)
    width: 290px
    margin: 0 auto
    padding-bottom: 20px
    padding-top: 5px

  .outside-container
    width: 534px
    margin: 0 auto




  .servipag
    max-width: 100%
    margin: 0 auto
    background-color: white
    border-radius: 8px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

  .coupon-info-ctc
    border-radius: 4px
    background-color: #eeeeee
    width: 85%
    margin: 0 auto

    .cp-number
      font-family: 'DM Sans'
      font-size: 10px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.5
      letter-spacing: -0.3px
      text-align: left
      color: rgba(33, 33, 33, 0.75)

    .cp-amount
      font-family: 'Roboto'
      font-size: 24px
      font-weight: bold
      font-stretch: normal
      font-style: normal
      line-height: 0.67
      letter-spacing: -0.72px
      color: rgba(33, 33, 33, 0.75)

    .cp-total
      font-family: 'DM Sans'
      font-size: 10px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.5
      letter-spacing: -0.3px
      color: rgba(33, 33, 33, 0.75)

    .cp-sent
      font-family: 'DM Sans'
      font-size: 7px
      font-weight: normal
      font-stretch: normal
      font-style: normal
      line-height: 1.71
      letter-spacing: -0.21px
      color: rgba(33, 33, 33, 0.75)

    .separator
      height: 2px
      background-color: #999999

  .coupon-head
    font-family: 'DM Sans'
    font-size: 18px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    letter-spacing: -0.45px
    text-align: left
    color: #676767
    text-align: center

  .cp-instructions
    font-family: 'Roboto'
    font-size: 10px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.8
    letter-spacing: -0.3px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-instructions-numbers
    font-family: 'Roboto'
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 0.67
    letter-spacing: -0.72px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  img.barcode
    width: 85%

  .cp-info
    font-family: 'Roboto'
    font-size: 10px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 2.29
    letter-spacing: normal
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-reminder
    font-family: 'Roboto'
    font-size: 12px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.8
    letter-spacing: -0.3px
    color: rgba(33, 33, 33, 0.75)
    text-align: center

  .cp-disclaimer
    max-width: 100%
    margin-left: auto
    margin-right: auto
    font-family: 'Roboto'
    font-size: 12px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.67
    letter-spacing: 0.22px
    color: rgba(0, 0, 0, 0.87)

  @media only screen and (max-width: 600px)
    .btn-container
      flex-direction: column !important

  @media (min-width: 600px) and (max-width: 1100px)
    .servipag, .cp-disclaimer
      max-width: 70% !important

  @media only screen and (min-width: 1100px)
    .servipag, .cp-disclaimer
      max-width: 50% !important
</style>
