<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!newCareersSuccess">
        <Breadcrumbs
          :title="$t('modules.careers.create.breadcrumbs.title')"
          :description="$t('modules.careers.create.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.careers.create.sectionData.title')"
                :subtitle="$t('modules.careers.create.sectionData.subtitle')"
                :switch-label="changeStatuslabel"
                switcher
                @switchChange="changeStatus"
                :switchValue="status"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.newCareers.description.$anyError"
                      :error-messages="descriptionErrors"
                      outlined
                      :label="`${$t('modules.careers.create.sectionData.items.description')} *`"
                      v-model.trim="$v.newCareers.description.$model"
                      @blur="$v.newCareers.description.$touch()"
                    />
                  </v-col>
                  <v-col sm="4">
                    <v-text-field
                      :error="$v.newCareers.code.$anyError"
                      :error-messages="codeErrors"
                      :loading="checkingCode"
                      outlined
                      :label="`${$t('modules.careers.create.sectionData.items.code')} *`"
                      required
                      v-model="$v.newCareers.code.$model"
                      @blur="$v.newCareers.code.$touch()"
                    />
                  </v-col>

                  <v-col sm="6">
                    <v-select
                      :error="$v.newCareers.careerType.$anyError"
                      :error-messages="careerTypeErrors"
                      outlined
                      :items="careerTypes"
                      item-text="meaning"
                      return-object
                      :label="`${$t('modules.careers.create.sectionData.items.careerType')} *`"
                      v-model="$v.newCareers.careerType.$model"
                      @blur="$v.newCareers.careerType.$touch()"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.newCareers.legalDescription.$anyError"
                      :error-messages="legalDescriptionErrors"
                      outlined
                      :label="`${$t('modules.careers.create.sectionData.items.legalDescription')}`"
                      v-model.trim="$v.newCareers.legalDescription.$model"
                      @blur="$v.newCareers.legalDescription.$touch()"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :error="$v.newCareers.book.book.$anyError"
                      :error-messages="bookErrors"
                      outlined
                      min="1"
                      max="999999"
                      :label="`${$t('modules.careers.create.sectionData.items.book')}`"
                      v-model.number="$v.newCareers.book.book.$model"
                      @keypress="$validateIntegerInputNumber($event, $v.newCareers.book.book.$model, null, 6)"
                      @blur="$v.newCareers.book.book.$touch()"
                      type="number"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :error="$v.newCareers.book.invoice.$anyError"
                      :error-messages="invoiceErrors"
                      outlined
                      min="0"
                      max="999999"
                      @keypress="$validateIntegerInputNumber($event, $v.newCareers.book.invoice.$model, null, 6)"
                      :label="`${$t('modules.careers.create.sectionData.items.invoice')}`"
                      v-model.number="$v.newCareers.book.invoice.$model"
                      @blur="$v.newCareers.book.invoice.$touch()"
                      type="number"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="2">
                    <v-text-field
                      :error="$v.newCareers.credits.$anyError"
                      :error-messages="creditsErrors"
                      outlined
                      :label="`${$t('modules.careers.create.sectionData.items.credits')} *`"
                      v-model.trim="$v.newCareers.credits.$model"
                      @keypress="$validateIntegerInputNumber($event, $v.newCareers.credits.$model, null, 6)"
                      @blur="$v.newCareers.credits.$touch()"
                      type="number"
                      min="1"
                    />
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      :error="$v.newCareers.hours.$anyError"
                      :error-messages="hoursErrors"
                      outlined
                      :label="`${$t('modules.careers.create.sectionData.items.hours')} *`"
                      v-model.trim="$v.newCareers.hours.$model"
                      @keypress="$validateIntegerInputNumber($event, $v.newCareers.hours.$model, null, 6)"
                      @blur="$v.newCareers.hours.$touch()"
                      type="number"
                      min="1"
                    />
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard
                v-if="canCreate"
                :title="`${$t('modules.careers.create.studyPlans.title')} *`"
                :subtitle="$t('modules.careers.create.studyPlans.subtitle')"
                class="mb-6"
              >
                <v-row>
                  <v-col></v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row v-if="studyPlansTmp.length !== 0" justify="center">
                  <v-col sm="12">
                    <StudyPlansTable
                      :headers="headers"
                      :items="studyPlansTmp"
                      :clear-selected="clearSelected"
                      :permissions="{update: canCreate}"
                      @delete="deleteStudyPlanPopUp"
                      @update="(id, index) => {open(index)}"
                    />
                  </v-col>
                </v-row>
                <div
                  :class="studyPlansTmp.length === 0 ? 'd-flex justify-start' : 'd-flex justify-end'"
                >
                  <Button
                    outlined
                    :text="$t('modules.careers.create.studyPlans.button')"
                    @clicked="open(studyPlansTmp.length)"
                    class="mr-4"
                  ></Button>
                </div>
                <br />
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/careers')"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :disabled="!canCreateCareer"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  @end="resetButtonValues"
                  @clicked="checkDisableAndCreate"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
      <TemporaryRightDrawer
        v-if="canCreate"
        @tmpDrawerOpen="closeDrawerAlert"
        :open="tmpDrawer.open"
        :title="!pickedStudyPlan ? $t('modules.careers.study_plan.drawer.create.title') : $t('modules.careers.study_plan.drawer.edit.title')"
        :description="!pickedStudyPlan ? $t('modules.careers.study_plan.drawer.create.description') : $t('modules.careers.study_plan.drawer.edit.title')"
        :width="100"
        :scroll="false"
      >
        <StudyPlansCreate
          :studyPlan.sync="pickedStudyPlan"
          @cancel="closeDrawerAlert"
          :closed="!tmpDrawer.open"
        />
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import StudyPlansTable from '../Components/StudyPlansTable/StudyPlansTable';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import StudyPlansCreate from './StudyPlanCreate'
import { $careers, $types } from '../Services';
import CareersMixin from '../Mixins'
import { mapGetters } from 'vuex';
export default {
  name: 'CareersCreate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage,
    StudyPlansTable,
    TemporaryRightDrawer,
    StudyPlansCreate
  },
  mixins: [CareersMixin],
  data() {
    return {
      
      loading: false,
      saving: false,
      careerTypes: [],
      selectedCareerFilter: {},
      clearSelected: '',
      studyPlansTmp: [],
      newCareers: {
        legalDescription: null,
        status: true,
        description: null,
        code: null,
        credits: null,
        hours: null,
        careerType: [],
        studyPlans: [],
        book: {
          book: null,
          invoice: null,
          date: null,
          career:null
        }
      },
      status: true,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        title: '',
        actionPrimary: { text: 'Aceptar', callback() {} }
      },
      newCareersSuccess: false,
      pickedStudyPlanIndex: 0,
    };
  },
  async mounted() {
    this.fetchData();
    this.getCareerTypes();
  },
  computed: {
    pickedStudyPlan:{
      set(value){
        this.studyPlansTmp.splice(this.pickedStudyPlanIndex, 1, value)
        this.close()
      },
      get(){
        return this.studyPlansTmp[this.pickedStudyPlanIndex];
      }
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  methods: {
    editStudyPlan(index){
      this.pickedStudyPlanIndex = index;
      this.open();
    },
    close(){
      this.pickedStudyPlanIndex = this.studyPlansTmp.length;
      this.tmpDrawer.open = false;
    },
    open(val = undefined){
      //DO I REALLY NEED THIS FUNCTION??
      if(val === undefined){
        this.pickedStudyPlanIndex  = this.studyPlansTmp.length;
      }else{
        this.pickedStudyPlanIndex = val;
      }
      
      this.$nextTick(() => {
        this.tmpDrawer.open = true;
      })
      
    },
    closeDrawerAlert(){
      this.$store.dispatch('commons/openPopUp', {
        title: `Estás por cerrar sin guardar los cambios`,
        content: null,
        actionPrimary: { text: 'Cerrar', callback: this.close },
        actionSecondary: { text: 'Cancelar', callback() {} },
        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
        color: 'primary'
      });
    },
    changeStatus() {
      this.newCareers.status = !this.newCareers.status;
    },
    removeItems(removedItems) {
      removedItems.forEach(removedItem => {
        const index = this.selectedItems.indexOf(removedItem);
        this.selectedItems.splice(index, 1);
      })
    },
    deleteStudyPlanPopUp(id) {
      this.studyPlansTmp.splice(id, 1)
    },
    async fetchData() {
      if(this.$router.params !== undefined){
        if(this.$router.params.career) {
           this.newCareers = this.$router.params.career;
           this.studyPlansTmp = this.$router.params.career.studyPlans
        }
      }
    },
    async getCareerTypes() {
      try {
        this.careerTypes = await $types.find(null, null, {params: { type: 'CAREER_TYPE' }});
        this.careerTypes = this.careerTypes.data
      } catch (error) {
        this.careerTypes = []
        throw error;
      }
    },
    async checkDisableAndCreate(){
      if(!this.newCareers.status)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.careers.create.disableCareerPopUp'),
          content: null,
          actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
          actionPrimary: { text: this.$t('actions.create'), callback: this.createCareer },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary'
        });
      else
        this.createCareer()
    },
    async createCareer() {
      if (!this.canCreate) return false
      this.createButton.loading = true;
      this.newCareers.studyPlans = this.studyPlansTmp;
      this.newCareers.user = this.userId
      try {
        await $careers.create(this.newCareers);
        this.newCareersSuccess = true;
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.careers.create.success_message.title');
        this.successMessage.actionSecondary = { text: this.$t('modules.careers.create.success_message.actions.secondary_text'), callback: () => { this.newCareersSuccess = false } };
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.careers.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/careers') } };
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.careers.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
        }

        this.createButton.success = true;
        this.clearData()
      } catch (error) {
        this.newCareersSuccess = true;
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning) ? error.codeMeaning : this.$t('modules.careers.create.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.careers.create.success_message.actions.back'), callback: () => { this.newCareersSuccess = false } }
        this.successMessage.actionSecondary = null
        this.createButton.error = true;
      } finally {
        this.resetButtonValues()
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    clearData() {
      this.selectedCareerFilter = {}
      this.clearSelected = ''
      this.studyPlansTmp = []
      this.newCareers = {
        legalDescription: null,
        status: true,
        description: null,
        code: null,
        credits: null,
        hours: null,
        careerType: [],
        studyPlans: [],
        book: {
          book: null,
          invoice: null,
          date: null,
          career:null
        }
      },
      this.status = true
      this.pickedStudyPlanIndex = 0
      this.$v.$reset()
      this.resetButtonValues()
    }
  },
};
</script>
