<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs :title="$t('modules.portalalumno.dashboard.breadcrumbs.title')" :description="$t('modules.portalalumno.dashboard.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" cols="12">

                    <div class="principal-layout margin-bot" v-if="studentData">
                        <div class="layout-grid d-flex flex-wrap">
                            <div class="layout-grid__column--left pr-0 pr-sm-3">
                                <div class="welcome-banner d-flex align-center justify-center mb-5">
                                    <!--Welcome banner component-->
                                    <PrincipalBanner :classStarted="isClassStart" :onboardingStatus="onboardingStatus" v-if="onboardingStatus.showBanners != null"></PrincipalBanner>
                                </div>
                                <div class="subjects-tabs d-flex align-center justify-center mb-5">
                                    <!--Subjects tabs component-->
                                    <SubjectsTab></SubjectsTab>
                                </div>
                                <div class="calendar-container d-flex align-center justify-center mb-5">
                                    <!--Calendar component-->
                                    <Calendar></Calendar>
                                </div>
                            </div>
                            <div class="layout-grid__column--right pl-0 pl-sm-3">
                                <template v-if="onboardingStatus.showBanners && isClassStart">
                                    <OnboardingSidePrimaryBanner v-if="!!currentOnboardingSidePrimaryBanner" :banner="currentOnboardingSidePrimaryBanner"></OnboardingSidePrimaryBanner>
                                    <OnboardingSideSecondaryBanner v-if="!!currentOnboardingSideSecondaryBanner" :banner="currentOnboardingSideSecondaryBanner"></OnboardingSideSecondaryBanner>
                                </template>
                                <div class="secondary-banner--others d-flex align-center justify-center mb-5" v-for="(banner,index) in smallBanners" :key="`smallbanner-${index}`">
                                    <!--Secondary banner component-->
                                    <GenericBanner :banner="banner" @referFriend="showModalReferFriend = true"></GenericBanner>
                                </div>

                            </div>
                        </div>
                        <v-dialog
                            persistent
                            no-click-animation
                            attach=".principal-layout"
                            content-class="first-login-video"
                            v-model="firstLoginVideoDialog"
                        >
                            <iframe
                                v-if="firstLoginVideoDialog"
                                type="text/html" width="640" height="360"
                                iv_load_policy="3" modestbranding="1" showinfo="0"
                                :src="`${firstLoginVideoSrc}?rel=0`"
                                frameborder="0"
                                allowfullscreen
                            ></iframe>
                            <v-btn icon dark class="close-dialog" @click="firstLoginVideoDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                        </v-dialog>
                    </div>

                </v-col>
            </v-row>
        </v-container>
        <ReferFriendModal  :open="showModalReferFriend" @close="showModalReferFriend = false" />
        <FirmNewPP v-if="$isTeclab" :open="!!$route.query.pp || showModalFirmNewPP" @close="showModalFirmNewPP = false" />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import PrincipalBanner from '../Components/Banners/PrincipalBanner';
    import SubjectsTab from '../Components/SubjectsTab';
    import Calendar from '../Components/Calendar';
    import GenericBanner from '../Components/Banners/GenericBanner';
    import OnboardingSidePrimaryBanner from '../Components/Banners/OnboardingSidePrimaryBanner';
    import OnboardingSideSecondaryBanner from '../Components/Banners/OnboardingSideSecondaryBanner';
    import ReferFriendModal from '../Components/ReferFriendModal.vue'
    import FirmNewPP from '../Components/FirmNewPP.vue'
    import AuthService from '@/plugins/auth.service';
    import ListStudents from '@/constants/listStudents';

    import Configuration from '../../../../core/constants/configuration';

    import { $studentAccount, $hubspot, $banners } from '../Services';
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DashboardStudent',
        components: {
            Breadcrumbs,
            PrincipalBanner,
            SubjectsTab,
            Calendar,
            GenericBanner,
            OnboardingSidePrimaryBanner,
            OnboardingSideSecondaryBanner,
            ReferFriendModal,
            FirmNewPP,
        },
        data() {
            return {
                showModalReferFriend: false,
                showModalFirmNewPP: false,
                firstLoginVideoDialog: false,
                firstLoginVideoSrc: 'https://www.youtube.com/embed/z8a-_e9R4zI',
                onboardingStatus: {
                    classStart: null,
                    aproboTest: false,
                    test: false,
                    schedule: false,
                    onbordingDate: null,
                    horario: null,
                    onbording: false,
                    habilidades: false,
                    showBanners: null
                },
                onboardingSidePrimaryBanners: [],
                onboardingSideSecondaryBanners: [],
                smallBanners: [
                    /* {
                        id: 'recordatorioExamenes2B2020',
                        visible: true,
                        color: '#D01171',
                        image: {
                            src: require(`@/assets/dashboard-assets/examenes2B2020R${this.studentData.oldModality ? '19.png' : '20.png'}`).default,
                            big: true,
                            left: true,
                            absolute: false,
                        },
                        text: ['¡Recuerda agendar tus exámenes finales de las asignaturas 2B!',
                            this.studentData.oldModality ?
                            'Si eres Reglamento 2019, podrás rendir entre <b>el 04/01 y el 09/01</b>. ¡Reserva con al menos 3 días de anticipación!' :
                            'Si eres Reglamento 2020, podrás rendir entre <b>el 04/01 y el 23/04</b>. ¡Reserva con al menos 3 días de anticipación!'
                        ],
                        button: {
                            text: 'Agendar ahora',
                            link: '/#/examenes',
                        },
                    },*/

                    // {
                    //     id: 'rematricula',
                    //     visible: true,
                    //     color: '#ffbc2d',   // Orange
                    //     image: {
                    //         src: require('@/assets/dashboard-assets/matricula.png'),
                    //         big: true,
                    //         left: true,
                    //         absolute: true,
                    //     },
                    //     text: ['¿Ya hiciste tu rematrícula?','Obten descuentos y financia hasta en 12 cuotas sin interés'],
                    //     button: {
                    //         text: 'Empezar ahora',
                    //         link: '/procedures',
                    //     },
                    // }, {
                    //     id: 'calendario',
                    //     visible: true,
                    //     color: '#32cccc',   // Green
                    //     image: {
                    //         src: require('@/assets/dashboard-assets/calendario.png'),
                    //         big: true,
                    //         left: true,
                    //         absolute: true,
                    //     },
                    //     text: ['¿Ya revisaste tu calendario?','Descarga el archivo y ten siempre a mano las fechas importantes de cada bimestre'],
                    //     button: {
                    //         text: 'Descargar',
                    //         link: require('@/assets/documents/Calendario-2021-2022.pdf'),
                    //         download: true
                    //     },
                    // }, {
                    //     id: 'tne',
                    //     visible: true,
                    //     color: '#4586de',   // Blue
                    //     image: {
                    //         src: require('@/assets/dashboard-assets/tneprocedure.png'),
                    //         big: true,
                    //     },
                    //     text: ['¡Gestiona tu TNE sin salir del campus!','Empieza tu trámite ahora.'],
                    //     button: {
                    //         text: 'Gestionar',
                    //         link: '/procedures/tne',
                    //     },
                    // }, {
                    //     id: 'materias',
                    //     visible: true,
                    //     color: '#4586de',   // Blue
                    //     image: {
                    //         src: require('@/assets/dashboard-assets/materias.png'),
                    //     },
                    //     text: 'Revisa y gestiona tus asignaturas',
                    //     button: {
                    //         text: 'Ver más',
                    //         link: '/academic-charge/subjects',
                    //     },
                    // }, {
                    //     id: 'reglamento',
                    //     visible: true,
                    //     color: '#f46f6f',   // Red
                    //     image: {
                    //         src: require('@/assets/dashboard-assets/reglamento.png'),
                    //     },
                    //     text: 'Recuerda leer el reglamento antes de iniciar tus clases',
                    //     button: {
                    //         text: 'Leer reglamento',
                    //         link: '/reglamento',
                    //     }
                    // },
                ],
            }
        },
        created() {
            this.getOnboardingStatus()
            if (this.$isIPP) {
              this.getReEnrollmentStatus()
            }
        },
        mounted() {
            this.firstLoginVideoDialog = this.showFirstLoginVideo
            this.fetchBanners()
            const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
            const dniAlumnoBloqueado = Configuration.value('app') == 'portalalumno' && AuthService.isStudent() && alumnoSeleccionado ? ListStudents.find(
                dni => dni == alumnoSeleccionado.userData.username
            ) : null;

            this.showModalFirmNewPP = !!dniAlumnoBloqueado;
        },
        methods: {
            async getReEnrollmentStatus() {
              try {
                const reEnrollmentOptions = await $studentAccount.find(`reEnrollmentStatus/${this.studentRegister}`)
                if (reEnrollmentOptions.data.reEnrollment) {
                  const reenrollmentBanner = this.smallBanners.find(banner => banner.id == 'rematricula')
                  if (reenrollmentBanner) reenrollmentBanner.visible = true
                }
              } catch (err) {
                const reenrollmentBanner = this.smallBanners.find(banner => banner.id == 'rematricula')
                if (reenrollmentBanner) reenrollmentBanner.visible = false
                throw err
              }
            },
            async getOnboardingStatus() {
                try {
                    const {data} = await $hubspot.find('contact/onbording-status', null, { params: {studentId: this.studentId} })
                    this.onboardingStatus = data
                    this.onboardingStatus.onbordingDate = data.onbordingDate ? data.onbordingDate.slice(0,10) : null
                } catch (err) {
                    this.onboardingStatus = {
                        classStart: null,
                        aproboTest: false,
                        test: false,
                        schedule: false,
                        onbordingDate: null,
                        horario: null,
                        onbording: false,
                        habilidades: false,
                        showBanners: false
                    }
                    throw err
                } finally {
                    if (this.onboardingStatus.showBanners && this.isClassStart) {
                       this.setOnboardingSideBanners()
                    }
                }
            },
            setOnboardingSideBanners() {
                const [name] = this.studentData.student.user.userData.name.split(' ')
                this.onboardingSidePrimaryBanners = [
                    {
                        visible: !this.onboardingStatus.test,
                        backgroundColor: '#CBE1EC',
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/01.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.test.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.test.text.comment'),
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.test.buttons.first.text'),
                                size: 'lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingHabilidadesUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-hacertest-side'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.test.buttons.second.text'),
                                size: 'lg',
                                color: '#FFFFFF40',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.paraQueSirve,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-¿paraquesirve?-side'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.schedule,
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/02.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.schedule.text.title', { studentName: this.$options.filters.$capitalizeAll(name) }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.schedule.text.comment')
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.schedule.buttons.first.text'),
                                size: 'lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-fechasdisponibles-bienvenida-side'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isSameOrAfter((new Date()).setHours(0,0,0,0))),
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/04.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.scheduled.text.title', { onboardingDate: this.getOnboardingDate }),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.scheduled.text.comment', { ref: this.$isTeclab ? 'http://bit.ly/CoachAcadémico' : 'mailto:inducciones@ipp.cl' })
                        }
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.schedule && (moment(this.onboardingStatus.onbordingDate).isBefore((new Date()).setHours(0,0,0,0))) && !this.onboardingStatus.onbording,
                        backgroundColor: '#BBE6C0',
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/05.svg'),
                        text: {
                            caption: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.reschedule.text.caption'),
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.reschedule.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.reschedule.text.comment')
                        },
                        buttons: [
                            {
                                outline: false,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.reschedule.buttons.first.text'),
                                size: 'lg',
                                color: '#0F99E2',
                                textColor: '#FFFFFF',
                                action: this.onboardingCharlaBienvenidaUrl,
                                icon: null,
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-nuevasfechas-bienvenida-side'
                                }
                            }
                        ]
                    }, {
                        visible: this.onboardingStatus.test && this.onboardingStatus.onbording,
                        backgroundColor: '#BDE8F0',
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/06.svg'),
                        text: {
                            caption: null,
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.onboarded.text.title'),
                        },
                        buttons: [
                            {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.onboarded.buttons.first.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoUsarPortal,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comousarportal-side'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.onboarded.buttons.second.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoEstudiar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comoestudiar-side'
                                }
                            }, {
                                outline: true,
                                text: this.$t('modules.portalalumno.dashboard.onboarding.sidePrimaryBanner.onboarded.buttons.third.text'),
                                color: '#FFFFFF',
                                textColor: '#0081BD',
                                action: this.onboardingStatus?.comoAprobar,
                                icon: 'mdi-play-box',
                                analytics: {
                                    category: 'Home',
                                    label: 'Banner OBH',
                                    action: 'banner-comoaprobar-side'
                                }
                            }
                        ]
                    }
                ]

                this.onboardingSideSecondaryBanners = [
                    {
                        visible: this.onboardingStatus.test && this.onboardingStatus.habilidades,
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/03.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.lackSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.lackSkills.text.comment')
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.lackSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Banner OBH',
                                action: 'banner-ingresacanvas-habilidades-side'
                            }
                        }
                    }, {
                        visible: this.onboardingStatus.test && !this.onboardingStatus.habilidades,
                        image: require('@/assets/dashboard-assets/onboarding/after-class-start/07.svg'),
                        text: {
                            title: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.enoughSkills.text.title'),
                            comment: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.enoughSkills.text.comment'),
                        },
                        button: {
                            text: this.$t('modules.portalalumno.dashboard.onboarding.sideSecondaryBanner.enoughSkills.button.text'),
                            analytics: {
                                category: 'Home',
                                label: 'Banner OBH',
                                action: 'banner-ingresacanvas-tecnicasestudio-side'
                            }
                        }
                    }
                ]
            },
            async fetchBanners() {
                try {
                    const banners = await $banners.find('details');
                    this.smallBanners = banners.data.map(banner => {
                        const image = banner.images.find(image => !image.background)
                        const bgImg = banner.images.find(image => image.background)?.fileUrl 
                        return {
                            type: banner.type,
                            color: banner.color,
                            bgImg: bgImg ? this.bannerBaseURL+bgImg : null,
                            image: {
                                src: this.bannerBaseURL+image.fileUrl,
                                position: image.position,
                                corner: image.corner,
                            },
                            text: [ banner.title, banner.comment ],
                            buttons: banner.buttons
                        }
                    });

                    
                    this.smallBanners.unshift({
                        type: {
                            meaning: '',
                            type: null,
                            value: 272,
                        },
                        color: '#106ca7',
                        bgImg: '',
                        image: {
                            src: require(`@/assets/theme/${this.$theme}/bannerReferido.gif`),
                            position: 0,
                            corner: false
                        },
                        text: ['', ''],
                        buttons: [{
                            id: 7,
                            link: 'action-referFriend',
                            text: 'Referir amigo'
                        }]
                    });

                } catch (err) {
                    this.smallBanners = []
                    throw err
                }
            },
        },
        computed: {
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentId: 'commons/getStudentId',
                studentData: 'commons/getStudentData',
                bannerBaseURL: 'commons/getAssetsURL'
            }),
            activeBanners() {
                return this.smallBanners.filter(banner => banner.visible == true)
            },
            currentOnboardingSidePrimaryBanner() {
                return this.onboardingSidePrimaryBanners.find(banner => banner.visible == true)
            },
            currentOnboardingSideSecondaryBanner() {
                return this.onboardingSideSecondaryBanners.find(banner => banner.visible == true)
            },
            isClassStart() {
                return this.onboardingStatus.classStart ? moment(this.onboardingStatus.classStart).isBefore(new Date()) : false
            },
            getOnboardingDate() {
                const horario = this.onboardingStatus.horario ? this.onboardingStatus.horario.substr(0, 5) : ''
                if (this.onboardingStatus.onbordingDate) return moment(`${this.onboardingStatus.onbordingDate.substr(0, 10)} ${horario}`).locale('es').format('D [de] MMMM [a las] H[hs]')
                else return moment().locale('es').format('D [de] MMMM [a las] H[hs]')
            },
            showFirstLoginVideo() {
                return !this.$isGestor && this.studentData.firstLogin
            },
            onboardingCharlaBienvenidaUrl() {
              switch(this.$country) {
                case 'chile':
                  if(this.this.onboardingStatus?.aproboTest) {
                    return 'https://instituto.ipp.cl/onboarding-2';
                  }
                  return 'https://instituto.ipp.cl/onboarding-1'
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/charla-de-bienvenida'
                default:
                  return 'https://onboarding.teclab.edu.ar/charla-de-bienvenida'
              }
            },
            onboardingHabilidadesUrl() {
              switch(this.$country) {
                case 'chile':
                  return 'https://instituto.ipp.cl/test-de-habilidades-final';
                case 'argentina':
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
                case 'peru':
                  return 'https://onboarding.teclab.edu.pe/habilidades-teclab'
                default:
                  return 'https://onboarding.teclab.edu.ar/habilidades-teclab'
              }
            },
        },
    }
</script>

<style lang="sass" scoped>

    .layout-grid
        &__column--left
            width: 65%
            div
                width: 100%
        &__column--right
            width: 35%
            div
                width: 100%

    .welcome-banner
        min-height: 200px

    .subjects-tabs
        min-height: 254px

    .calendar-container
        min-height: 290px

    .secondary-banner--others
        min-height: 130px

    .close-dialog
        position: absolute
        top: 10px
        right: 10px

    @media only screen and (max-width: 600px)
        .layout-grid
            div
                width: 100%

</style>

<style lang="sass">
    .principal-layout
        > .v-dialog__content
            > .first-login-video
                position: relative
                width: 640px
                height: 360px
                max-height: 100% !important
                line-height: 0 !important
</style>
