
import GradebookView from '../Views/GradebookView'

const GradebookRoute = [
    {
        path: '',
        component: GradebookView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'gradebook', permission: 'READ' }
    },
]

export default GradebookRoute