const examsTranslations = {
    es_PE: {
        assestment: {
            studentInformation: {
                studentLabel: 'Estudiante:',
                subject: 'Curso:',
            },
        },
        review: {
            subject: 'Curso',
            student: 'Estudiante',
        }
    },
    es: {
        assestment: {
            loading: {
                message: 'Por favor aguarde...'
            },
            popUp: {
                withoutAnswers: {
                    title: 'No seleccionó ninguna respuesta para la presente pregunta, ¿desea continuar con la siguiente pregunta?',
                    content: '',
                    buttonPrimary: 'Siguiente pregunta',
                    buttonSecondary: 'Volver'
                },
                withToReviewOrNotAnswered: {
                    titleReview: 'Tienes {toReview}  pregunta en "revisión" | Tienes {toReview}  preguntas en "revisión"',
                    titleNotAnsered: 'Tienes {notAnswered}  pregunta "no respondida" | Tienes {notAnswered}  preguntas "no respondidas"',
                    finalQuestion: '¿estás seguro que deseas finalizar el examen?',
                    content: 'Una vez que presiones "finalizar", no podras modificar las respuestas seleccionadas.',
                    buttonPrimary: 'Finalizar el examen',
                    buttonSecondary: 'Volver'
                },
                errorSavingAnswer: {
                    title: 'Ha ocurrido un error al intentar guardar su respuesta',
                    content: 'Volveremos a intentarlo en unos minutos',
                    buttonPrimary: 'Siguiente pregunta',
                    buttonSecondary: 'Volver',
                },
                errorFinishingExam: {
                    buttonSecondary: 'Aceptar',
                    content: 'Ha ocurrido un error finalizando el examen'
                },
            },
            studentInformation: {
                logo: {
                    warning: 'Recuerde que si supera el tiempo máximo para el examen, el mismo se guardará automáticamente.'
                },
                studentLabel: 'Alumno:',
                subject: 'Materia:',
                references: 'Referencias',
                answered: 'Preguntas respondidas',
                notAnswered: 'Preguntas no respondidas',
                toReview: 'Preguntas para revisión',
                actual: 'Pregunta actual',
                timeLabel: 'Tiempo restante',
            },
            graphicQuestions: {
                previousButton: 'Anterior pregunta',
                nextButton: 'Siguiente pregunta',
                finishButton: 'Finalizar Examen',
            },
            questions: {
                progressLabel: 'Enunciados',
                answersLabel: 'Respuestas',
                stateLabel: 'Estado',
                seeImage: 'Ver imagen',
            },
            answers: {
                unsaved_title: 'Respuesta sin guardar',
                unsaved_content: '¿Desea guardar los cambios antes de cambiar de pregunta?',
                button: 'Guardar respuestas',
                review: 'Marcar para revisión',
                clearAnswersButton: 'Eliminar respuesta',
            },
        },
        review: {
            subject: 'Materia',
            student: 'Alumno',
            brief: {
                title: 'Resumen',
                status: 'Estado del examen',
                approved: 'Aprobado',
                failed: 'Reprobado',
                calification: 'Calificación obtenida',
                totalQuestions: 'Total de preguntas',
                totalAnswers: 'Total respondidas',
                correctAnswers: 'Correctas',
                incorrectAnswers: 'Incorrectas'
            },
            answers: {
                title: 'Respuestas correctas e incorrectas',
                justification: 'Justificación',
                reviewContent: 'Motivo de la revisión',
                chosenAnswer: 'Respuesta seleccionada',
                correctAnswer: 'Respuesta correcta',
                revision: 'Solicitar revisión',
                finish: 'Finalizar revisión'
            },
            warning: {
                title: 'Tienes preguntas en "Revisión", ¿deseas enviar los comentarios y finalizar el exámen?',
                primaryAction: 'Enviar y finalizar',
                secondaryAction: 'Finalizar sin enviar',
            },
            error: {
                secondaryAction: 'Volver',
            }
        }
    },
    en: {},
};

export default examsTranslations