const templateTranslations = {
    es_CL: {
    },

    es: {
        templateTable: {
            breadcrumbs: {
                title: 'Comunicaciones',
                description: 'Administración de Areas',
            },
            area: 'Área',
            messageCount: 'Cantidad de mensajes',
            createdAt: 'Fecha de creación',
            sortBy: 'Ordenar por',
            date: 'Fecha',
            name: 'Nombre',
            newFolder: 'Nueva Carpeta'
        },
        createMessage: {
            breadcrumbs: {
                title: 'Crear nueva comunicación',
                description: 'Template de creación de mensajes',
            },
            messageBody: 'Comunicación',
            messageBodyHtml: 'Cuerpo del mensaje',
            messageTitle: 'Titulo del mensaje',
            messageSubtitle: 'Subtitulo del mensaje',
            messageSignature: 'Firma de la comunicación',
            messageFullname: 'Nombre completo',
            messagePosition: 'Puesto o Cargo',
            messageTracking: 'Tracking',
            messageImage: 'Agregar Imagen',
            labelSelectVigencia:'Comunicación activa',
            titleVigencia:'Vigencia de la comunicación',
            subtitleVigencia:'Una vez finalizada la fecha de vigencia se podrá generar el reporte.',
            titleDestinatarios:'Destinatarios',
            subtitleDestinatarios:'Cargue el listado de estudiantes a enviar',
            validations:{
                messageContent:{
                    title:{
                        requerido:'Ingrese el titulo del mensaje',
                        maxLength:'Maximo 30 caracteres'
                    },
                    subtitle:{
                        requerido:'Ingrese el subtitulo del mensaje',
                        maxLength:'Maximo 30 caracteres'
                    },
                    name:{
                        requerido:'Ingrese el nombre completo',
                        maxLength:'Maximo 15 caracteres'
                    },
                    place:{
                        requerido:'Ingrese el puesto o cargo',
                        maxLength:'Maximo 15 caracteres'
                    },
                },
                selectVigencia:{
                    requerido:'Seleccione una opcion',
                }
            },
            successMessage:{
                title:'Template creado exitosamente'  
            },
            errorMessage:{
                title :'Ocurrio un error al crear el template'
            }
        },
        editMessage: {
            breadcrumbs: {
                title: 'Editar comunicación',
                description: 'Template de creación de mensajes',
            },
            messageBody: 'Comunicación',
            messageBodyHtml: 'Cuerpo del mensaje',
            messageTitle: 'Titulo del mensaje',
            messageSubtitle: 'Subtitulo del mensaje',
            messageSignature: 'Firma de la comunicación',
            messageFullname: 'Nombre completo',
            messagePosition: 'Puesto o Cargo',
            messageTracking: 'Tracking',
            messageImage: 'Agregar Imagen',
            vigencia:{
                title:'Vigencia de la comunicación',
                subtitle:'Una vez finalizada la fecha de vigencia se podrá generar el reporte.',
                label:'Comunicación activa'
            },
            destinatarios:{
                title:'Destinatarios',
                subtitle:'Cargue el listado de estudiantes a enviar',
                label:'Comunicación activa'
            },
            successMessage:{
                title:'Template editado exitosamente'  
            },
            errorMessage:{
                title :'Ocurrio un error al editar el template'
            }
        },
        newArea:{
            editar:'Editar',
            create:'Nueva',
            title:'Carpeta',
            labelName:'Nombre de la carpeta',
            labelCode:'Codigo',
            error:{
                name:{
                    requerido:'El nombre de la carpeta es obligatorio.',
                    maxLength: 'El nombre de la carpeta tiene mas de 30 caracteres.'
                },
                code:{
                    requerido:'El codigo es obligatorio.',
                    maxLength: 'El codigo  tiene mas de 10 caracteres.'
                }
            }
        },
        areaTemplates: {
            breadcrumbs: {
                description: 'Mensajes del área',
            },
            templateName: 'Nombre',
            templateState: 'Estado',
            newMessage: 'Nuevo Mensaje',
            sendMessage: {
                button: 'Enviar mensaje masivo',
                title: 'Envío masivo',
                description: 'Enviar mensaje masivo a diversos estudiantes',
                drop_title: 'Cargue el listado de estudiantes a enviar',
                download_template: 'Descargar template',
                success_message: 'Se enviaron correctamente {quantity} mensajes',
                error_message: 'Ocurrió un error enviando los mensajes',
                no_files: 'No se detectaron archivos para enviar',
                send_confirmation: '¿Desea enviar el siguiente archivos? No se podra revertir',
                pending_files: 'Hay un archivo pendiente de enviar. ¿Desea salir?',
            }
        }
     },
     en: {
        }
  };

export default templateTranslations
