<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t(`modules.portalalumno.procedures.proceduresInital.procedures.${$route.params.certificateId}.downloadTitle`)"
        :description="$route.params.description"
        :analytics="{category: 'Tramites', label: gaLabel}"
      >
        <v-btn @click="onDownaloadClick" color="primary" :disabled="loading || !fileSource" rounded large>{{$t('modules.portalalumno.procedures.download.download')}}</v-btn>
        <a :href="finalUrl" target="_blank" download ref="downloadButton" v-show="false"></a>
      </Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <div class="d-flex align-center justify-center">
            <v-row justify="center">
              <v-col cols="10">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        class="mb-4"
                        v-model="selectedCareer"
                        @change="getDocument"
                        :items="status"
                        :label="$t('modules.portalalumno.procedures.download.autocompleteCareer.label')"
                        item-text="careerName"
                        :item-value= "valueCareer"
                        return-object
                        hide-details
                        outlined
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item.statusCareer" ></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.careerName"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.studentCareer"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="isCertifateExamFinal" class="d-flex flex-column">
                  <v-col cols="6">
                    <v-autocomplete
                        class="mb-4"
                        v-model="selectedSubject"
                        @change="getExams"
                        :items="subjets"
                        :label="$t('modules.portalalumno.procedures.download.autocompleteSubjet.label')"
                        item-text="descriptionSubject"
                        item-value="codeSubject"
                        hide-details
                        outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        class="mb-4"
                        v-model="selectExam"
                        @change="getDocument"
                        :items="exams"
                        :label="$t('modules.portalalumno.procedures.download.autocompleteExamn.label')"
                        item-text="examDate"
                        item-value="id"
                        hide-details
                        outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <DocumentPreview v-if="!loading" title="" :src="fileSource" class="margin-bot"/>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import { $studentProcessing, $materias, $examenes } from '../services';
  import { mapGetters } from 'vuex'

  export default {
    name: 'CertificateDownload',
    components: {
      DocumentPreview,
      Breadcrumbs
    },
    data() {
      return {
        fileSource: '',
        title: '',
        description: '',
        valueCareer: '',
        careers: [],
        status: [],
        selectedCareer: null,
        loading: true,
        // certificateStatus: {
        //   1: [54], //Alumno Regular
        //   4: [55,145], //Egreso
        //   5: [145], //Horas
        //   10: [55,145,54], //Concentración de Notas
        //   11: [145], //Titulo Digital
        // },
      selectedSubject :null,
      subjets:[],
      selectExam:null,
      exams:[]
      }
    },
    computed: {
      gaLabel() {
        const type = this.$route.params.certificateKey
        // if (type == 1) return 'Certificado de Alumno Regular'
        if (type === 'GRADUATION_CERTIFICATE' || type === 'REGISTERED_STUDENT_CERTIFICATE') return this.$t('modules.portalalumno.procedures.download.certificateLabels.alumnoRegular')
        if (type === 'ACADEMIC_TRANSCRIPT') return this.$t('modules.portalalumno.procedures.download.certificateLabels.analitico')
        else if (type === 'I_AM_TECLAB') return this.$t('modules.portalalumno.procedures.download.certificateLabels.matricula')
        else if (type === 'SYLLABUS_AND_WORKLOAD_CERTIFICATE') return this.$t('modules.portalalumno.procedures.download.certificateLabels.planStudy')
        else if (type === 'FINAL_EXAM_PROOF') return this.$t('modules.portalalumno.procedures.download.certificateLabels.notas')
        else if (type === 'MANDATORY_DOCUMENTATION') return this.$t('modules.portalalumno.procedures.download.certificateLabels.examfinal')
        else if (type === 'PENDING_TITLE_PROOF') return this.$t('modules.portalalumno.procedures.download.certificateLabels.tituloTrami')
        else if (type === 'PARTIAL_CERTIFICATE_PROOF') return this.$t('modules.portalalumno.procedures.download.certificateLabels.tituloInterm')
        else return this.$t('modules.portalalumno.procedures.download.certificateLabels.default')
      },
      finalUrl() {
        return `${this.fileSource}#toolbar=0&statusbar=0&messages=0&navpanes=0`
      },
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentUserId: 'commons/getStudentUserID',
        studentId: 'commons/getStudentId',
      }),
      isCertifateExamFinal(){
          return this.$route.params.certificateKey === 'FINAL_EXAM_PROOF' && this.$isTeclab
      }
    },
    methods: {
      onDownaloadClick () {
        this.$trackEvent('Tramites', 'Descargar', this.gaLabel)
        this.$refs.downloadButton.click();
      },
      async getCareers() {
        this.careers = this.$route.params.careers
        if (this.careers.length) {
          this.selectedCareer = this.careers[0] // eslint-disable-line prefer-destructuring
          this.getDocument();
        }
      },
      async getDocument() {
          try {
            this.loading = true
            let params
            if(!(this.$route.params.certificateKey === 'FINAL_EXAM_PROOF')){
              params  = { idStudentCareer: this.selectedCareer.studentCareer, user: this.studentUserId ? this.studentUserId : null}
            }else{
              params = { idStudentCareer: this.selectedCareer.studentCareer, examId : this.selectExam, user: this.studentUserId ? this.studentUserId : null }
            }
            if (this.selectedCareer?.degreeId) params.degree = this.selectedCareer.degreeId
            const fetchedPDF = await $studentProcessing.find(`certified/${this.$route.params.certificateKey}`, null, { params })
            this.fileSource = fetchedPDF.data
          } catch (error) {
            this.fileSource = '';
          } finally {
            this.loading = false
          }

      },
      async getSubjets(){
        try {
          const resp = await $materias.find('examSubjects',null, {params:{ leg : this.studentRegister}})
          this.subjets = resp.data
        } catch (error) {
          // console.log(error)
        }
      },
      async getExams(){
        try {
          const resp = await $examenes.find('getExamsBySubject',null,{params:{subjectCode: this.selectedSubject, studentCareerId:this.selectedCareer.studentCareer}})
          this.exams = resp.data
        } catch (error) {
          // console.log(error)
        }
      },
      getStatusCareer() {
        try {
          if(this.$route.params.certificateKey === 'PARTIAL_TITLE_DETAILS' || this.$route.params.certificateKey === 'GRADUATION_CERTIFICATE'){
              this.valueCareer = 'degreeId'
            } else {
              this.valueCareer = 'studentCareer'
            }
          const statusCareer = this.$route.params.careers
          const anulado = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.canceled') }, ...statusCareer?.filter(e => e.statusCareer == 'Anulado')]
          const baja = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.low') }, ...statusCareer?.filter(e => e.statusCareer == 'Baja')]
          const cursando = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.studying') }, ...statusCareer?.filter(e => e.statusCareer == 'Cursando')]
          const egresado = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.graduated') }, ...statusCareer?.filter(e => e.statusCareer == 'Egresado')]
          const titulado = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.titled') }, ...statusCareer?.filter(e => e.statusCareer == 'Titulado')]
          const egresadoSinTitulo = [{ divider: true } , { header: this.$t('modules.portalalumno.procedures.status.graduatedWithoutDegree') }, ...statusCareer?.filter(e => e.statusCareer == 'Egresado sin opción a Título')]

          if(anulado[2]){
            this.status.push(...anulado)
          }
          if(baja[2]){
            this.status.push(...baja)
          }
          if(cursando[2]){
            this.status.push(...cursando)
          }
          if(egresado[2]){
            this.status.push(...egresado)
          }
          if(titulado[2]){
            this.status.push(...titulado)
          }
          if(egresadoSinTitulo[2]){
            this.status.push(...egresadoSinTitulo)
          }

        } catch (error) {
            this.status = []
            throw error
        }
      },
    },
    mounted () {
      this.getCareers()
      this.getSubjets()
      this.getStatusCareer()
    }
  }
</script>
