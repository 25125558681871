<template>
    <div>
        <v-container fluid>
            <v-container class="container-custom">
                <Breadcrumbs :title="benefit.description">
                    <v-menu offset-y v-if="canUpdate || canDelete">
                        <template v-slot:activator="{ on: menu }">
                            <v-btn text color="primary" v-on="{ ...menu }" >
                                <v-icon>mdi-dots-vertical</v-icon>
                                {{ $t('modules.benefits.details.breadcrumbs.actions') }}
                            </v-btn>
                        </template>
                        <v-list class="pa-0">
                            <v-list-item v-if="canUpdate" @click="editBenefit(benefit.id)" >
                                <v-list-item-title>
                                    <v-icon>mdi-pencil</v-icon>
                                    {{ $t('actions.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="canDelete" @click="deleteBenefitPopUp(benefit.id)">
                                <v-list-item-title>
                                    <v-icon>mdi-delete</v-icon>
                                    {{ $t('actions.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </Breadcrumbs>
            </v-container>
            <v-container class="container-custom pt-0 pb-8">
                <v-row justify="start" align-content="stretch">
                    <v-col cols="12 pt-0">
                        <v-sheet color="#f5f6f9" class="px-3 BenefitsDetails-info-sheet">
                            <v-row>
                                <v-col cols="8" class="py-0 my-3" style="border-right: 1px solid #cecece !important;">
                                    <table class="BenefisDetails-info-table" height="100%">
                                        <thead>
                                            <tr>
                                                <th class="primary--text text-center">{{ $t('modules.benefits.details.resume.product') }}</th>
                                                <th class="primary--text text-center">{{ $t('modules.benefits.details.resume.productType') }}</th>
                                                <th class="primary--text text-center">{{ $t('modules.benefits.details.resume.discountType') }}</th>
                                                <th v-if="benefit.average" class="primary--text text-center">{{ $t('modules.benefits.details.resume.average') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(prod, i) in benefitDetails" :key="i" :class="{'with-line': prod.first}">
                                                <td class="text-center">{{ prod.title }}</td>
                                                <td class="text-center">{{ prod.typeProd }}</td>
                                                <td class="text-center">{{ prod.discount }}</td>
                                                <td v-if="benefit.average" class="text-center">{{ prod.average }}</td>
                                            </tr>
                                        </tbody> 
                                    </table>
                                </v-col>
                                <v-col cols="4">
                                    <table class="BenefisDetails-info-table" height="100%">
                                        <thead>
                                            <tr>
                                                <th class="primary--text text-center">{{ $t('modules.benefits.details.resume.applySince') }}</th>
                                                <th class="primary--text text-center">{{ $t('modules.benefits.details.resume.status') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="with-line">
                                                <td class="text-center">{{ benefitFrom }}</td> 
                                                <td class="text-center">{{ benefit.status ? 'Aprobado' : 'Desestimado' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12">
                        <h3 class="pt-1 pb-2">{{ $t('modules.benefits.details.table.title') }}</h3>
                        <v-row class="my-0 py-0">
                            <v-col cols="3" align-content="stretch">
                                <v-sheet color="#f5f6f9" class="px-3 BenefitsDetails-info-sheet">
                                    <v-row justify="center" align="center" style="height:100%">
                                        <v-col cols="6" justify="center" align="center">
                                            <strong class="primary--text">{{ $t('modules.benefits.details.resume.BenefitsStudents') }}</strong><br>
                                        </v-col>
                                        <v-col cols="6" justify="center" align="center"><h1>{{ benefit.numberOfElements ? benefit.numberOfElements : 0 }}</h1></v-col>
                                    </v-row>
                                </v-sheet>
                            </v-col>
                        </v-row>
                        <SuperTableDetails
                            :headers="headers"
                            :users="benefit.students ? benefit.students : []"
                            :clear-selected="clearSelected"
                            :canAssign="canAssign"
                            @remove="bulkDeletePopUp"
                            @bulk-delete="bulkDeletePopUp"
                            @showTableAssigStudent="tmpDrawer.open = true"
                            :status="benefitStatus"
                            :page-count="pagination.pagesAmount"
                            :page="pagination.page"
                            :total-items="totalItems"
                            @searchAction="searchAction"
                            :sortBy.sync="sort.sortBy"
                            :sortDesc.sync="sort.sortDesc"
                            @input-pagination="onInputPagination"
                            @length-pagination-change="onLengthPaginationChange"
                        ></SuperTableDetails>
                    </v-col>
                </v-row>
                <TemporaryRightDrawer
                    v-if="canAssign && openedDrawer"
                    @tmpDrawerOpen="onCloseDrawer"
                    :open="tmpDrawer.open"
                    :title="tmpDrawer.title"
                    :description="tmpDrawer.description"
                >
                    <BenefitsAssign
                        @closeDrawer="onCloseDrawer"
                        @reloadBenefit="getBenefit"
                        insideDrawer
                        :items="benefit.students ? benefit.students : []"
                        :benefitId="benefit.id"
                        :benefitType="benefit.type ? benefit.type.value : 0"
                        :companies="companies"
                    ></BenefitsAssign>
                </TemporaryRightDrawer>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import moment from 'moment'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import SuperTableDetails from '../Components/SuperTable/SuperTableDetails'
    import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
    import BenefitsAssign from './BenefitsAssign'
    import {$benefits, $communityBenefitBilling, $referredCompanies} from '../Services';

    export default {
        name: 'BenefisDetails',
        components:{
            Breadcrumbs,
            SuperTableDetails,
            TemporaryRightDrawer,
            BenefitsAssign
        },
        data(){
            return{
                title: '',
                benefit: {},
                benefitType: {
                  referredFriend: 10,
                  referredCompany: 292,
                  community: 47,
                  academic: 48,
                  other: 357
                },
                companies: [],
                clearSelected: '',
                openedDrawer: true,
                tmpDrawer: {
                    open: false,
                    title: this.$t('modules.benefits.details.drawer.title'),
                    description: this.$t('modules.benefits.details.drawer.subtitle')
                },
                totalItems: null,
                pagination: {
                    limit: 20,
                    page: 1,
                    pageAmount: 1
                },
                sort: {
                    sortBy: 'identification',
                    sortDesc: false
                }
            }
        },
        computed:{
            canUpdate() {
                return this.$permissions.portaladministrativo.prices.benefits.UPDATE
            },
            canAssign() {
                return this.$permissions.portaladministrativo.prices.benefits.ASSIGN
            },
            canDelete() {
                return this.$permissions.portaladministrativo.prices.benefits.DELETE
            },
            benefitFrom(){
                return this.benefit ? moment(this.benefit.from).format('YYYY-MM-DD') : ''
            },
            benefitStatus(){
                return true
                // return this.benefit ? this.benefit.status : this.benefit.status
            },
            benefitDetails(){
                
                const produts = []
                if(!this.benefit.details){
                    produts.push({'typeProd': this.$t('modules.benefits.table.refer'),
                                  'discount': this.$options.filters.$currency(this.benefit.benefitReferredAmount)
                    })
                }else{
                    this.benefit.details.forEach(el => {
                    produts.push({'typeProd': el.billingProduct.name,
                                  'discount': el.percentage ? `${el.percentage} %`: this.$options.filters.$currency(el.amount),
                    })
                })
               
                }
 
                produts[0].title = this.benefit.type.meaning;
                produts[0].first = true
                if (this.benefit.average) produts[0].average = this.benefit.average

                return produts
            },
            headers() {
              if (this.benefit.type?.value == this.benefitType.community || this.benefit.type?.value == this.benefitType.referredCompany) {
                return [
                  { text: this.$t('modules.benefits.details.table.identification'), value: 'identification'},
                  { text: this.$t('modules.benefits.details.table.name'), value: 'fullname', sortable: false },
                  { text: this.$t('modules.benefits.details.table.career'), value: 'careerName', sortable: false },
                  { text: this.$t('modules.benefits.details.table.company'), value: 'company', sortable: false },
                  { text: '', value: 'action', sortable: false },
                ]
              } else {
                return [
                  { text: this.$t('modules.benefits.details.table.identification'), value: 'identification' },
                  { text: this.$t('modules.benefits.details.table.name'), value: 'fullname', sortable: false },
                  { text: this.$t('modules.benefits.details.table.career'), value: 'careerName', sortable: false },
                  { text: '', value: 'action', sortable: false },
                ]
              }
            }
        },
        methods: {
            onCloseDrawer() {
                this.tmpDrawer.open = false
                this.getBenefit()
            },
            async getBenefit() {
                try {
                    const {data: benefit} = await $benefits.findById(this.$route.params.id)
                    let sortCol = 'identification';
                    switch (this.sort.sortBy) {
                        default:
                            sortCol = 'student.user.userData.identification';
                        break;
                    }
                    const params = {
                        page: this.pagination.page - 1,
                        length: this.pagination.limit,
                        orderBy: sortCol,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                        id: this.$route.params.id,
                        search: this.search
                    }
                    const {data: students} = await $benefits.findById('students', null, { params })
                    benefit.numberOfElements = students.totalElements
                    benefit.students = !students.content ? [] : students.content.map( (user, index) => {
                        user.student.key = `${index}-${user.student.id}`
                        user.student.fullname = `${user.student.lastname} ${user.student.name}`
                        user.student.careerName = user.student.career != null ? user.student.career[0].description ? `${user.student.career[0].code} - ${user.student.career[0].description}` : '-' :''
                        user.student.deleteId = benefit.id;
                        user.student.company = user.referredCompany?.name
                        return user.student
                    })
                    this.totalItems = students.totalElements
                    this.pagination.pagesAmount = students.totalPages
                    benefit.students.sort((a, b) => (a.fullname > b.fullname) ? 1 : -1)
                    this.benefit = benefit
                    this.getCompanies()
                } catch (error) {
                    this.benefit = {}
                    throw error
                }
            },
            async getCompanies() {
              try {
                if (this.benefit.type?.value == this.benefitType.community) {
                  const {data} = await $communityBenefitBilling.find(null, null, { params: { page: 0, length: 500 } })
                  this.companies = data.content
                } else if (this.benefit.type?.value == this.benefitType.referredCompany) {
                  const {data} = await $referredCompanies.find(null, null, { params: { page: 0, length: 500 } })
                  this.companies = data.companies.map(company => {
                    company.description = company.name
                    return company
                  })
                } else {
                  this.companies = []
                }
              } catch (err) {
                this.companies = []
                throw err
              }
            },
            editBenefit(id) {
                if (this.canUpdate) this.$router.push(`/benefits/update/${id}`);
            },
            deleteBenefitPopUp(id) {
                this.$store.dispatch('commons/openPopUp', {
                    title: '¿Esta seguro de eliminar?',
                    content: [{value: this.benefit.description}],
                    actionPrimary: {text: 'Aceptar', callback: () => this.deleteBenefit(id)},
                    actionSecondary: {text: 'Cancelar', callback() {}},
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            async deleteBenefit (id) {
                try {
                    await $benefits.delete(id);
                    this.$router.push(`/benefits`);
                } catch (e) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: e.codeMeaning,
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                    })
                    throw e
                }
            },
            bulkDeletePopUp(students) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: students.map(student => ({ value: `${student.id} - ${student.fullname}` })),
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkRemove(students)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async bulkRemove(students) {
                try {
                    const studentToDelete = {'ids': students.map(el => el.id)};
                    await $benefits.bulkDelete(studentToDelete, null, `assign-student/delete/${this.benefit.id}` );
                    this.getBenefit();
                } catch(e) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: e.codeMeaning,
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                    })
                }
            },
            onInputPagination(event) {
                this.pagination.page = event;
                this.getBenefit();
            },
            onLengthPaginationChange(event) {
                this.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.getBenefit();
            },
            searchAction(data) {
                this.pagination.page = 1;
                this.search = data;
                this.getBenefit();
            },
        },
        async mounted() {
            await this.getBenefit()
        },
        watch: {
            sort: {
                handler() {
                    this.getBenefit();
                },
                deep: true
            }
        }
    }
</script>

<style lang="sass" scoped>
.BenefitsDetails-info-sheet
    border-radius: 5px
    border: solid 1px #cecece
    height: 100%

    span, strong 
      font-size: 14px

    h1
      font-size: 40px

    .BenefisDetails-info-table 
      background: transparent
      width: 100%
      border-spacing: 0px
      
      tr 
        td, th
          text-align: left
          padding-top: 10px
          padding-bottom: 10px
          border-bottom: none !important
          font-size: 14px
        
        &.with-line td
          border-top: 1px solid #cecece !important
</style>
