<template>
  <v-container class="container-custom" >

    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.admission.contract.title')"
      ></Breadcrumbs>
   
      <TitleComponent :description="$t('modules.portalalumno.admission.contract.subtitle')"/>      
      <v-row >
        <v-col sm="12" cols="12" v-if="loading" class="d-flex mt-6">
          <v-progress-circular class="mx-auto" indeterminate color="primary"></v-progress-circular>
        </v-col>
        <v-col cols="10" sm="11">
          <DocumentPreview :title="headerTitle" :src="fileSource"/>         
        </v-col>
      </v-row>
      <v-row justify="center">        
        <v-col cols="10" align="start">
          <v-btn @click="onSignClick" color="primary" rounded large>{{$t('modules.portalalumno.admission.contract.sign')}}</v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <div class="mb-10">
          <strong class="black--text">{{$t('modules.portalalumno.admission.documentation.help')}}</strong><br>
          <v-btn outlined rounded small color="primary" @click="goToReglamento" class="margin-bot">
            {{$t('modules.portalalumno.admission.documentation.rules')}}
          </v-btn>
        </div>
      </v-row>
      
      <ECertModal v-if="showModal" @close="showModal = !showModal" :token="token"/>
    </v-container>
  </v-container>
</template>
<script>
import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TitleComponent from '@/components/TitleComponent/TitleComponent';
import ECertModal from '../Components/ECertModal'
import { $students } from '../services';
import { mapGetters } from 'vuex'

export default {
  name: 'ContractSign',
  components: {
    ECertModal,
    DocumentPreview,
    Breadcrumbs,
    TitleComponent
  },
  data () {
    return {
      headerTitle: this.$t('modules.portalalumno.admission.careerChangeStep3.dataSection.header'),
      fileSource: '',
      showModal: false,
      token:'',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      studentUserData: 'commons/getStudentUserData',
      studentRegister: 'commons/getStudentActualRegister',
    }),
  },
  async mounted () {
    this.loading = true;
    const response = await $students.findById('contract', this.studentRegister);
    this.fileSource = response.data;
    this.loading = false;
  },
  methods: {
    async onSignClick () {
      this.loading = true;
      try {
        const resp = await $students.signContract('ecert/validateUserData', {
          rut: this.studentUserData.identification,
          documentoB64: this.fileSource.replace('data:application/pdf;base64,', ''),
          legajo: this.studentRegister
        })        
        window.open(`https://serviciovalidacionremota.ecertchile.cl/${resp.data.url}?token=${resp.data.token}`,'_blank');
        this.loading = false;
      } catch (err) {
        this.loading = false
        throw err
      }
    },
    goToReglamento() {
      window.location.href = '#/reglamento-full';
    }
  }
}
</script>