<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewPrepaidStatus">
      <Breadcrumbs
        :title="$t('modules.prepaids.create.breadcrumbs.title')"
        :description="$t('modules.prepaids.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.prepaids.create.codeSection.title')"
            :subtitle="$t('modules.prepaids.create.codeSection.subtitle')"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            @switchChange="(val) => {this.status = val}"
            switcher
            class="mb-6"
          >
            <v-row>
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.description.$dirty && $v.description.$invalid) ?
                  ((!$v.description.required) ? $t('modules.prepaids.validations.nameRequired') :
                  (!$v.description.alphaNumWithSpaces) ? $t('modules.prepaids.validations.nameAlphanumericWithSpaces') :
                  $t('modules.prepaids.validations.nameMaxLength')) :
                  ''"
                  @blur="$v.description.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.description.$model,60, true)"
                  v-model="$v.description.$model"
                  ref="description"
                  :label="`${$t('modules.prepaids.create.codeSection.labels.name')} *`"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                  ((!$v.code.required) ? $t('modules.prepaids.validations.codeRequired') :
                  (!$v.code.alphaNum) ? $t('modules.prepaids.validations.codeAlphanumeric') :
                  (!$v.code.maxLength) ? $t('modules.prepaids.validations.codeMaxLength') :
                  $t('modules.prepaids.validations.codeUnique')) :
                  ''"
                  @blur="validateCode"
                  @keypress="$validateAlphanumeric($event, $v.code.$model,10)"
                  :loading="loadingCode"
                  v-model="$v.code.$model"
                  ref="code"
                  :label="`${$t('modules.prepaids.create.codeSection.labels.code')} *`"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.prepaids.create.studentSection.title')" class="mb-6">
            <v-radio-group class="mt-0" v-model="$v.selectedStudentType.$model" row>
              <v-radio
                v-for="(studentType, index) in studentTypes"
                color="primary"
                ref="studentType"
                :key="index"
                :label="studentType.meaning"
                :value="studentType.value"
              ></v-radio>
              <v-alert
                text
                type="error"
                v-if="$v.selectedStudentType.$anyError"
              >{{ $t('modules.prepaids.validations.studentType') }}
              </v-alert>
            </v-radio-group>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.prepaids.create.termSection.title')"
            :subtitle="$t('modules.prepaids.create.termSection.subtitle')"
            class="mb-6"
          >
            <TransferList
              ref="termsAdded"
              :avatar="false"
              :loading="loadingSearchTerms"
              :firstListTitle="$t('modules.prepaids.create.termSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.prepaids.create.termSection.transferList.secondTitle')"
              :availableItems.sync="terms"
              :added-items.sync="$v.termsAdded.$model"
              areEquals="id"
            ></TransferList>
            <v-alert
              text
              type="error"
              class="mt-4"
              v-if="$v.termsAdded.$dirty && $v.termsAdded.$anyError"
            >{{ $t('modules.prepaids.validations.noSelectedTerms') }}
            </v-alert>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.prepaids.create.conditionSection.title')" class="mb-6">
            <v-checkbox
              :label="$t('modules.prepaids.create.conditionSection.labels.scholarship')"
              class="mr-8 mt-0 no-message"
              color="primary"
              v-model="scholarship"
            ></v-checkbox>
            <v-checkbox
              :label="$t('modules.prepaids.create.conditionSection.labels.average')"
              class="mr-8 mt-0 no-message"
              color="primary"
              v-model="average"
            ></v-checkbox>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.prepaids.create.dayRangeSection.title')" class="mb-6">
            <v-row no-gutters>
              <v-col sm="8">
                <MultipleFormGroup
                  ref="groups"
                  :items="$v.groups.$each.$iter"
                  :length="groups.length"
                  @addGroup="addGroup"
                  @removeGroup="removeGroup"
                  canAddGroup
                >
                  <template v-slot:default="{item}">
                    <v-container fluid class="pa-0">
                      <v-row>
                        <v-col class="pb-0" sm="4">
                          <v-text-field
                            :error-messages="(item.dayFrom.$invalid && item.dayFrom.$dirty) ?
                            ((!item.dayFrom.required) ? $t('modules.prepaids.validations.dayFromRequired') :
                            (!item.dayFrom.isUniqueRange) ? $t('modules.prepaids.validations.uniqueRange') :
                            (!item.dayFrom.isValidRange) ? $t('modules.prepaids.validations.validRange') :
                            $t('modules.prepaids.validations.betweenValues'))
                            : '' "
                            :label="$t('modules.prepaids.create.dayRangeSection.labels.dayFrom')"
                            outlined
                            @keypress="$validateIntegerInputNumber($event, item.dayFrom.$model, 30, null, 1)"
                            @blur="item.dayFrom.$touch()"
                            ref="dayFrom"
                            v-model="item.dayFrom.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="4">
                          <v-text-field
                            :error-messages="(item.dayTo.$invalid && item.dayTo.$dirty) ?
                            ((!item.dayTo.required) ? $t('modules.prepaids.validations.dayToRequired') :
                            (!item.dayTo.isUniqueRange) ? $t('modules.prepaids.validations.uniqueRange') :
                            (!item.dayTo.isValidRange) ? $t('modules.prepaids.validations.validRange') :
                            $t('modules.prepaids.validations.betweenValues'))
                            : '' "
                            :label="$t('modules.prepaids.create.dayRangeSection.labels.dayTo')"
                            @keypress="$validateIntegerInputNumber($event, item.dayTo.$model, 30, null, 1)"
                            @blur="item.dayTo.$touch()"
                            ref="dayTo"
                            outlined
                            v-model="item.dayTo.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="4">
                          <v-text-field
                            :error-messages="(item.percentage.$invalid && item.percentage.$dirty) ?
                            ((!item.percentage.required) ? $t('modules.prepaids.validations.percentageRequired') :
                            $t('modules.prepaids.validations.percentageBetweenValues'))
                            :''"
                            :label="$t('modules.prepaids.create.dayRangeSection.labels.percentage')"
                            outlined
                            ref="percentage"
                            append-icon="mdi-percent"
                            @keypress="$validateIntegerInputNumber($event, item.percentage.$model, 100, null, 1)"
                            @blur="item.percentage.$touch()"
                            v-model="item.percentage.$model"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </MultipleFormGroup>
              </v-col>
            </v-row>
          </OutlinedCard>
          <OutlinedCard :title="$t('modules.prepaids.create.zoneSection.title')" class="mb-6">
            <v-checkbox
              :key="index"
              :label="zone.zoneDescription"
              :value="zone.id"
              class="mr-8 mt-0 no-message"
              color="primary"
              ref="selectedZone"
              v-for="(zone, index) in zones"
              v-model="$v.selectedZone.$model"
            ></v-checkbox>
            <v-alert
              text
              type="error"
              v-if="$v.selectedZone.$anyError"
            >{{ $t('modules.prepaids.validations.zone') }}
            </v-alert>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.prepaids.create.careersSection.title')"
            :subtitle="$t('modules.prepaids.create.careersSection.subtitle')"
            class="mb-6"
          >
            <TransferList
              :avatar="false"
              :loading="loadingSearchCareers"
              :addedItems.sync="$v.selectedCareers.$model"
              areEquals="id"
              ref="selectedCareers"
              :availableItems.sync="availableCareers"
              :firstListTitle="$t('modules.prepaids.create.careersSection.transferList.firstTitle')"
              :secondListTitle="$t('modules.prepaids.create.careersSection.transferList.secondTitle')"
            ></TransferList>
            <v-alert
              text
              type="error"
              class="mt-4"
              v-if="$v.selectedCareers.$dirty && $v.selectedCareers.$anyError"
            >{{ $t('modules.prepaids.validations.noSelectedCareers') }}
            </v-alert>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/prepaids')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="createPrepaid"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TransferList from '@/components/TransferList/TransferList';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
import {required, between, alphaNum, maxLength, helpers} from 'vuelidate/lib/validators';
import {$prepaids, $types, $terms, $careers, $zones} from '../Services';

export default {
  name: 'PrepaidsCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    TransferList,
    MultipleFormGroup
  },
  data() {
    return {
      description: '',
      code: null,
      codeUnique: null,
      studentTypes: [],
      selectedStudentType: 7,
      zones: [],
      selectedZone: [],
      availableCareers: [],
      selectedCareers: [],
      scholarship: false,
      average: false,
      loadingSearchTerms: false,
      loadingSearchCareers: false,
      terms: [],
      termsAdded: [],
      loadingCode: false,
      status: true,
      groups: [
        {
          dayFrom: null,
          dayTo: null,
          percentage: null,
        }
      ],
      createNewPrepaidStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.prepaids.CREATE;
    },
  },
  mounted() {
    this.getStudentType();
    this.getTerms();
    this.getZones();
    this.getCareers();
  },
  methods: {
    async getTerms() {
      this.loadingSearchTerms = true;
      let terms = [];
      try {
        terms = await $terms.find(null, null, {
          params: {
            page: 0,
            length: 20,
            orderBy: 'saleDateFrom',
            orientation: 'desc'
          }
        });
        this.terms = terms.data.content.map(item => {
          item.name = `${item.code} ${item.description}`;
          return item;
        });
      } catch (error) {
        this.terms = [];
        throw error;
      } finally {
        this.loadingSearchTerms = false;
      }
    },
    async getCareers() {
      this.loadingSearchCareers = true;
      let careers = [];
      try {
        careers = await $careers.find(null, null, {
          params: {
            page: 0,
            length: 500,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.availableCareers = careers.data.content.map(item => {
          item.name = `${item.code} ${item.description}`;
          return item;
        });
      } catch (err) {
        this.availableCareers = [];
        throw err;
      } finally {
        this.loadingSearchCareers = false;
      }
    },
    async getZones() {
      this.clearSelected = new Date().getMilliseconds().toString();
      try {
        const zones = await $zones.find(null, null, {
          params: {
            page: 0,
            length: 500,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.zones = zones.data.content;
      } catch (err) {
        this.zones = [];
        throw err;
      }
    },
    async getStudentType() {
      try {
        const fetchedStudentTypes = await $types.find(null, null, {params: {type: 'STUDENT_TYPE'}});
        this.studentTypes = fetchedStudentTypes.data;
      } catch (err) {
        this.studentTypes = [];
        throw err;
      }
    },
    async validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $prepaids.find('exists', null, {params: {code: this.$v.code.$model}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    addGroup() {
      const mockup =
        {
          dayFrom: null,
          dayTo: null,
          percentage: null,
        };
      this.groups.push({
        ...mockup
      });
    },
    removeGroup(index) {
      this.groups.splice(index, 1);
    },
    async createPrepaid() {
      this.createButton.loading = true;
      try {
        if (this.$v.$invalid || !this.codeUnique) {
          this.$v.$touch();
          if(!this.codeUnique) {
            this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
          } else {
            Object.keys(this.$v).some(input => {
              if (input.includes('$')) return false;
              if (this.$v[input].$error) {
                this.$refs[input][0]
                  ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                  : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                return true
              }
            })
          }
          this.createButton.error = true;
          this.createButton.loading = false;
        } else {
          const newPrepaid = {
            description: this.description.trim(),
            code: this.code,
            status: this.status,
            scholarship: this.scholarship,
            average: this.average,
            terms: this.termsAdded.map(term => {
              return {
                id: term.id
              };
            }),
            studentType: {value: this.selectedStudentType},
            details: this.groups.map(detail => {
              return {
                percentage: detail.percentage,
                dayFrom: detail.dayFrom,
                dayTo: detail.dayTo,
              };
            }),
            careers: this.selectedCareers.reduce((a, career) =>
                [...a, ...this.selectedZone.map(zoneId => {
                  return {
                    career: {
                      id: career.id
                    },
                    zone: {
                      id: zoneId
                    }
                  };
                })],
              [])
          };
          await $prepaids.create(newPrepaid);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.prepaids.create.successMessage.title');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/prepaids');
            }
          };
          this.successMessage.actionSecondary = {
            text: this.$t('actions.create_another'), callback: () => {
              this.createNewPrepaidStatus = false;
            }
          };

          this.createNewPrepaidStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
          this.clearData();
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.prepaids.create.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewPrepaidStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;

        this.createNewPrepaidStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.description = null;
      this.code = null;
      this.status = true;
      this.scholarship = false;
      this.average = false;
      this.termsAdded = [];
      this.selectedStudentType = 7;
      this.groups = [];
      this.addGroup();
      this.selectedZone = [];
      this.selectedCareers = [];
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    description: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i)
    },
    code: {
      required,
      alphaNum,
      maxLength: maxLength(10),
      unique() {
        return this.codeUnique != false;
      },
    },
    selectedStudentType: {required},
    termsAdded: {required},
    groups: {
      $each:
        {
          dayFrom: {
            required,
            between: between(1, 30),
            isUniqueRange(value) {
              return this.groups.filter(e =>
                (e.dayFrom ? parseInt(e.dayFrom) <= parseInt(value) : false)
                && e.dayTo ? parseInt(e.dayTo) >= parseInt(value) : false
              ).length <= 1;
            },
            isValidRange(value, thisGroup) {
              if (thisGroup.dayTo) return parseInt(value) < parseInt(thisGroup.dayTo);
              return true;
            },
          },
          dayTo: {
            required,
            between: between(1, 30),
            isUniqueRange(value) {
              return this.groups.filter(e =>
                (e.dayFrom ? parseInt(e.dayFrom) <= parseInt(value) : false)
                && e.dayTo ? parseInt(e.dayTo) >= parseInt(value) : false
              ).length <= 1;
            },
            isValidRange(value, thisGroup) {
              if (thisGroup.dayFrom) return parseInt(value) > parseInt(thisGroup.dayFrom);
              return true;
            },
          },
          percentage: {
            required,
            between: between(1, 100),
          },
        }
    },
    selectedZone: {required},
    selectedCareers: {required},
  }
};
</script>
