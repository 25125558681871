
const billingTranslations = {
    es_PE: {
        billingTable:{
            headers:{
                alumno:'Estudiante',
            },
        },
        billingUpdate:{
            outlineCardStudent:{
                title:'Datos del estudiante',
                subtitle:'Buscá por DNI al estudiante al cual se le generará el comprobante.',
            },
        },
        studenData:{
            title:'Estudiante',
            city:'Distrito'
        },
        BillingCreate:{
            outlineCardAlumno:{
                title:'Datos del estudiante',
                subtitle:'Buscá por DNI al estudiante al cual se le generará el comprobante.'
            }
        },
    },
    es: {
      billingTable:{
          breadcrumbs:{
              title:'Gestion de Comprobantes',
          },
          headers:{
              tipo:'Tipo',
              alumno:'Alumno',
              dni:'DNI',
              date:'Fecha',
              number:'Número de referencia',
              amount:'Monto',
              concept:'Concepto'
          },
          errorMessage:{
            deleteReceipt:'Hubo un error al eliminar el recibo',
            errorSentClient: 'Hubo un error al reenviar al cliente',
            errorSentReceipt: 'Hubo un error al reenviar el comprobante',
            successSentClient: 'Se reenvio el cliente con éxito',
            successSentReceipt: 'Se reenvio el comprobante con éxito',

          },
          massImportDrawer: {
            breadcrumbs: {
              title: 'Carga masiva',
              description: 'Reenviar a SAP diversos comprobantes/clientes'
            },
            title: 'Cargue el listado de comprobantes',
            messages: {
              noFiles: 'No se detectaron archivos para enviar',
              sendConfirmation: '¿Desea enviar el siguiente archivo? No se podrá revertir',
              pendingFiles: 'Hay un archivo pendiente. ¿Desea salir?',
              success: 'Se cargaron correctamente los datos',
              error: 'Ocurrió un error cargando los datos',
            }
          }
      },
      billingUpdate:{
        breadcrumbs:{
          description:'Crear nuevo comprobante',
          title:'Generar Comprobante'
        },
        outlineCardStudent:{
          title:'Datos del alumno',
          subtitle:'Buscá por DNI al alumno al cual se le generará el comprobante.',
        },
        outlineCardFinance:{
          title:'Datos financieros',
          subtitle:'Agregá los datos del comprobante.',
          label:'Tipo de comprobante',
          observation:{
            label:'Observación',
          }
        },
        outlineCardDetail:{
          title:'Detalle',
          subtitle:'Agregá los datos de detalle del comprobante.',
        },
        validations:{
          observations:'Ingrese la observacion'
        }
      },
      studenData:{
        title:'Alumno',
        name:'Nombre y Apellido',
        identification:'Numero de DNI',
        typeIdentification:'Documento',
        city:'Ciudad',
        street:'Calle',
        number:'Número',
        floor:'Piso',
        depto:'Depto'
      },
      detailCard:{
        labelConcept:'Concepto',
        labelPrice: 'Precio'
      },
      BillingCreate:{
        breadcrumbs:{
          title:'Nuevo comprobante',
          description:'Crear nuevo comprobante'
        },
        outlineCardAlumno:{
          title:'Datos del alumno',
          subtitle:'Buscá por DNI al alumno al cual se le generará el comprobante.'
        },
        outlineCardFinance:{
          title:'Datos financieros',
          subtitle:'Agregá los datos del comprobante.',
        },
        outlineCardDetail:{
          title:'Detalle',
          subtitle:'Agregá los datos de detalle del comprobante.',
        },
        total:'Total'
      },
      superTable:{
        itemGenerateNote:'Generar nota de credito',
        itemDeleteReceipt:'Borrar recibo',
        clientResend:'Reenviar cliente',
        receiptResend: 'Reenviar comprobante'
      }

    },
    en: {

    }
};

export default billingTranslations