<template>
  <v-container class="superTable pa-0" fluid>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="validations"
          :expanded.sync="expanded"
          :single-expand="true"
          item-key="name"
          show-expand
          hide-default-footer
          style="table-layout: fixed"
          @update:expanded="getName" 
        >
          <!-- Aca se valida si tiene o no expansion, activando o desactivando el icono de ser necesario   -->
          <template #item.data-table-expand="{ item, expand, isExpanded }">
            <td v-if="item.status != 'OK' && item.optionalUrl" class="text-start">
              <v-btn icon 
                    @click="expand(!isExpanded)" 
                    class="v-data-table__expand-icon"
                    :class="{'v-data-table__expand-icon--active' : isExpanded}">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </td>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">
              <div>{{ item.name }} </div>
            </div>
          </template>

          <template v-slot:[`item.description`]="{item}">
            <div class="superTable__table__item__name">
              <div>{{ item.description }} </div>
            </div>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <div class="superTable__table__item__name">
              <div class="d-flex">
                <v-icon v-if="item.status == 'WARNING'" color="orange" size="20px">mdi-alert</v-icon>
                <v-icon v-else-if="item.status == 'OK'" color="green" size="20px">mdi-check-circle</v-icon>
                <v-icon v-else color="red" size="20px">mdi-close-circle</v-icon>
              </div>
            </div>
          </template>

          <template  v-slot:expanded-item="{headers}">
            <!-- Tabla expansible ver traduccion con i18n -->
            <td :colspan="headers.length" >
              <v-data-table
              :items="correction"
              :headers="headersCorrection"
              hide-default-footer
              >

              </v-data-table>        
            </td>
          </template>  
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuperTable',
  props: {
    validations: Array,
    correction:Array,
    headers: Array,
    sortBy: String,
    sortDesc: Boolean,
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 }
  },
  data(){
    return{
      expanded: [],
    }
  },
  methods: {
    // Tomo el nombre de la validacion para enviarlo y llamar al endpoint correspondiente para traer los datos a corregir
    getName(name){
      name.length > 0 ? this.$emit('getDataCorrectionTable',name[0].name) : [];
    }
  },
  computed: {
    headersCorrection(){
      return this.correction.length != 0 ? Object.keys(this.correction?.[0]).map(item =>({ text : item, value : item , sortable: false})) : [] ;
    },
  },
}
</script>

<style lang="sass" scoped>
  .v-expansion-panels
    .v-expansion-panel-content
      padding-left: 10px
      padding-bottom: 0px
      padding-right: 16px
  .v-data-table ::v-deep 
    .v-data-table__wrapper 
      tbody tr
      .v-data-table__expanded__content
        box-shadow: none
</style>