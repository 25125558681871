<template>
  <div fluid :class="[{'v-card--disabled': payment.options.disabled}, 'pl-3 pr-3']" @click="selectPaymentMethod">
    <v-row class="payment-single" :class="{'active-payments': payment.selected , 'active-reprogram': activeReprogram }">
      <v-col :cols="(showPrepaid) ? 1 : 2" lg="1">
        <v-checkbox color="primary" :disabled="payment.options.disabled || seleccionDisabled" :input-value="payment.selected"></v-checkbox>
      </v-col>

      <v-col class="text-left description" cols="5" :sm="(showPrepaid) ? 3 : 5">
        <span>{{ payment.title }}</span>
        <span class="subdescription">{{ `${$t('modules.portalalumno.payments.paymentsStudents.view.labels.due')}  ${payment.options.paymentInstallment}/${payment.options.paymentInstallments}` }}</span>
        <span class="subdescription hidden-md-and-up" :class="(showPrepaid) ? 'hidden' : ''" v-if="payment.options && (!payment.reenrollmentId || $isIPP)">{{ dueMessage }}</span>
        <small class="itemMandatory" v-if="payment.mandatory && studentData.studentType.value == 8 ">*Item obligatorio de pago para re-matriculacion</small>
        <small class="itemMandatory" v-if="payment.mandatory && studentData.studentType.value == 7">*Item obligatorio de pago para matriculacion</small>
      </v-col>

      <v-col class="amount  d-none d-md-flex"  lg="3" :style="(showPrepaid) ? 'display: none !important' : ''">
        <div class="option-bkg" v-if="payment.options && payment.reenrollmentId">
          <div class="text-center limit-date"
               v-if="systemConfig?.dues && payment.mandatory">
          </div>
          <div v-else class="text-center limit-date">{{ dueMessage }}</div>

        </div>
      </v-col>

      <v-col v-if="!showPrepaid" class="text-right amount " cols="5"  lg="3" md="3">
        <div class="option-payment pr-3" v-if="payment.options.off + payment.options.addedExtraOff > 0">
          <div class="old-price">{{ payment.options.amount | $currency }}</div>
          <div class="money-value">{{ savingAmount | $currency }}</div>
          <div class="discount-value">{{`${savingOff}% off`}} </div>
        </div>
        <div class="option-payment pr-3" v-else>
          <div class="money-value" :class="{'red-text': activeReprogram}">{{ payment.options.amount | $currency }}</div>
        </div>
      </v-col>

      <template v-if="showPrepaid">
        <template v-if="!payment.reenrollmentId">
          <v-col class="text-right amount prepaid py-0 px-0 my-3" :class="{'d-none': !prepaidValid}" cols="5" sm="2"  v-for="(prepaid, index) in prepaids" :key="`${payment.id}-${index}`">
            <div class="option-payment px-3" :class="{'active': prepaidsActive[index] === true}">
              <div class="old-price" v-if="!isFirstPayment">{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
              <div class="money-value">{{ payment.options.amount - (prepaid.percentage * payment.options.amount / 100) - (payment.options.offWithoutPrepaids * payment.options.amount / 100) | $currency }}</div>
              <div class="discount-value">{{`${payment.options.offWithoutPrepaids + prepaid.percentage}% off`}} </div>
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-right amount prepaid py-0 px-0 my-3" :class="{'d-none': !productWithPricePrepaidValid}" cols="5" sm="2"  v-for="(prepaid, index) in productWithPricePrepaids" :key="`${payment.id}-${index}`">
            <div class="option-payment px-3" :class="{'active': productWithPricePrepaidsActive[index] === true}">
              <div class="old-price" v-if="!isFirstPayment">{{ `Hasta el ${prepaid.dayTo}/${payment.options.month}` }}</div>
              <div class="money-value">{{ payment.options.amount - (prepaid.percentage * payment.options.amount / 100) - (payment.options.offWithoutPrepaids * payment.options.amount / 100) | $currency }}</div>
              <div class="discount-value">{{`${payment.options.offWithoutPrepaids + prepaid.percentage}% off`}} </div>
            </div>
          </v-col>
        </template>
        <v-col v-if="!payment.reenrollmentId ? !prepaidValid : !productWithPricePrepaidValid" class="amount d-sm-none d-md-flex" cols="1" sm="5">
          <div class="option-bkg" v-if="payment.options && !payment.reenrollmentId ">
            <div class="text-center limit-date">{{ dueMessage }}</div>
          </div>
        </v-col>

        <v-col class="text-right amount prepaid" 
        :cols="prepaidValid || productWithPricePrepaidValid ? 5 : 6" 
        :sm="prepaidValid || productWithPricePrepaidValid ? 2 : 8" 
        :md="prepaidValid || productWithPricePrepaidValid ? 2 : 3" >
          <div class="option-payment">
            <div class="money-value" :class="{'red-text': activeReprogram}">{{ prepaidInactiveMoneyValue | $currency }}</div>
            <div class="discount-value">{{`${prepaidInactiveDiscountValue}% off`}} </div>
          </div>
        </v-col>
      </template>

    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
  props: {
    systemConfig: Object,
    payment: Object,
    prepaids: Array,
    productWithPricePrepaids: Array,
    canReprogram: {type: Boolean, default: false},
    seleccionDisabled: {type: Boolean, default: false},
  },
  data() {
    return {
      now: moment(),
      dueDate: moment(this.payment.options.dueDate),
      prepaidsActive: [],
      productWithPricePrepaidsActive: [],
      prepaidValid: false,
      productWithPricePrepaidValid: false,
    }
  },
  mounted() {
    this.setActivePrepaid()
    this.setActiveProductWithPricePrepaid()
  },
  computed: {
    prepaidInactiveMoneyValue() {
      if(this.payment.options?.offWithoutPrepaids) {
        return this.payment.options.amount - (this.payment.options.offWithoutPrepaids * this.payment.options.amount / 100)
      }
      return this.payment.options.amount - (this.payment.options.off * this.payment.options.amount / 100)
    },
    prepaidInactiveDiscountValue() {
      if(this.payment.options?.offWithoutPrepaids) {
        return this.payment.options.offWithoutPrepaids
      }
      return this.payment.options.off
      
    },
    dueMessage() {
      return this.payment.status == 117 ? this.payment.options.expiration.replace('Hasta', 'Vencido') : this.payment.options.expiration
    },
    showPrepaid() {
      return this.payment.billingProduct === 81 && this.prepaids.length && !this.payment?.mandatory || this.payment.billingProduct === 81 && this.productWithPricePrepaids.length && !this.payment?.mandatory
    },
    isArancel() {
      return !!this.payment.options.term && this.payment.billingProduct === 81
    },
    isFirstPayment() {
      return this.payment.options.paymentInstallment == 1
    },
    activeReprogram() {
      return this.canReprogram && this.payment.selected && this.payment.repaymentAvailable
    },
    savingAmount() {
      return this.$isTeclab ? this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100) < 0 ? 0 : this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100)
        : this.payment.options.amount - ((this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)) * this.payment.options.amount / 100)
    },
    savingOff() {
      return this.$isTeclab ? (this.payment.options.off + this.payment.options.addedExtraOff) > 100 ? 100 : this.payment.options.off + this.payment.options.addedExtraOff
        : this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)
    },
    ...mapGetters({
                studentData: 'commons/getStudentData',
            }),
  },
  methods: {
    setActivePrepaid() {
      if (this.prepaids.length) {
        let activeFound = false
        this.prepaids.forEach((prep, index) => {
          if (this.isFirstPayment) {
            if (index == 0 && this.isArancel && !this.payment?.mandatory) {
              this.prepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
              this.payment.options.off = this.isArancel ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          } else {
            const prepaidDate = this.dueDate.set('date', prep.dayTo + 1)
            const diff = prepaidDate.diff(this.now, 'seconds')

            if (diff > 0 && !activeFound && this.isArancel && !this.payment?.mandatory) {
              this.prepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
              this.payment.options.off = this.isArancel ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.prepaidsActive.push(false)
            }
          }
        })

        this.prepaidValid = activeFound && this.isArancel && !this.payment?.mandatory
      }
    },
    setActiveProductWithPricePrepaid() {
      if (this.productWithPricePrepaids.length) {
        let activeFound = false
        this.productWithPricePrepaids.forEach((prep, index) => {
          if (this.isFirstPayment && this.isArancel && !this.payment?.mandatory) {
            if (index === 0) {
              this.productWithPricePrepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
              this.payment.options.off = this.isArancel ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.productWithPricePrepaidsActive.push(false)
            }
          } else {
            const prepaidDate = this.dueDate.set('date', prep.dayTo + 1)
            const diff = prepaidDate.diff(this.now, 'seconds')

            if (diff > 0 && !activeFound && this.isArancel && !this.payment?.mandatory) {
              this.productWithPricePrepaidsActive.push(true)
              this.payment.options.offWithoutPrepaids = this.isArancel ? this.payment.options.off : 0
              this.payment.options.prepaidPercentage = this.isArancel ? prep.percentage : 0
              this.payment.options.off = this.isArancel ? this.payment.options.off + prep.percentage : 0
              activeFound = true
            } else {
              this.productWithPricePrepaidsActive.push(false)
            }
          }
        })
        this.productWithPricePrepaidValid = activeFound && this.isArancel && !this.payment?.mandatory
      }
    },
    selectPaymentMethod() {
      if (!this.payment.options.disabled && !this.seleccionDisabled) {
        this.$emit('updatepaymentmethod', !this.payment.selected);
      }
    },
  }
};
</script>

<style scoped  lang="sass">

.payment-single
  font-family: 'Roboto'
  font-size: 12px
  font-weight: bold
  height: 90px
  border-radius: 5px
  box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
  border: solid 1px #d9d9d9
  background-color: white
  margin-bottom: 10px !important

  &.mandatory-payment
    border: solid 1px #0036b1
    .prepaid .active
      background-color: #d9e2fa
  &.active-reprogram
    border: solid 1px #ff5252

  &:hover
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
    border: solid 1px #0036b1
    cursor: pointer

  &.active-payments, &:hover

    .prepaid .active
      background-color: #d9e2fa

.description
  display: flex
  flex-direction: column
  justify-content: space-around
  span
    font-family: 'DM Sans'
    font-size: 16px
    line-height: 1.2
    letter-spacing: -0.48px
    text-align: left
    color: #212121
    margin-bottom: 0

    &.subdescription
      font-family: 'Roboto'
      font-size: 12px
      color: rgba(33, 33, 33, 0.5)
      line-height: 1.2
      letter-spacing: normal
      text-align: left
      margin-bottom: 0

.limit-date
  line-height: 1.33
  letter-spacing: -0.54px
  color: rgba(33, 33, 33, 0.5)
  width: 100%

.option-payment
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-around

.old-price
  line-height: 1.21
  color: rgba(33, 33, 33, 0.5)
  text-decoration: line-through

.money-value
  font-family: 'DM Sans'
  font-size: 18px
  line-height: 1.33
  letter-spacing: -0.54px
  color: var(--v-primary-base)

  &.red-text
    color: #f72b2b !important

.discount-value
  line-height: 1.17
  color: #2d9400

.option-bkg
  width: 100%
  height: 100%
  display: flex
  align-items: center

.prepaid
  border-right: 1px solid #d9d9d9

  .old-price
    text-decoration: none

.text--dark
  color: #212121 !important


</style>