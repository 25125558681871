
// General translations
import applicationsTranslations from './Applications/Translations/index'
import loginApplicationTranslations from './LoginApplication/Translations/index'
import profileTranslations from './Profile/Translations/index'


// Portal administrativo translations
import academicTranslations from './portaladministrativo/Academic/main.i18n.js'
import admissionTranslations from './portaladministrativo/Admission/main.i18n.js';
import benefitsTranslations from './portaladministrativo/Benefits/Translations/index'
import careersTranslations from './portaladministrativo/Careers/Translations'
import commissionsTranslations from './portaladministrativo/Commissions/main.i18n.js'
import CommunitiesTranslations from './portaladministrativo/Communities/Translations/index'
import conciliationTranslations from './portaladministrativo/Conciliation/Translations/index'
import dashboardTranslations from './portaladministrativo/Dashboard/Translations/index'
import degreesTranslations from './portaladministrativo/Degrees/Translations/index'
import departmentsTranslations from './portaladministrativo/Departments/Translations/index'
import discountsTranslations from './portaladministrativo/Discounts/Translations/index'
import ExamEditorTranslations from './portaladministrativo/ExamEditor/Translations'
import ExamEnablerTranslations from './portaladministrativo/ExamEnabler/Translations'
import examExceptionTranslations from './portaladministrativo/ExamException/Translations/index'
import examTestTranslations from './portaladministrativo/ExamTest/Translations/index'
import examTitleNotesTranslations from './portaladministrativo/ExamTitleNotes/Translations/index'
import examsTranslations from './portaladministrativo/Exams/main.i18n'
import ExceptionsTranslations from './portaladministrativo/Exceptions/Translations'
import prepaidsTranslations from './portaladministrativo/Prepaids/Translations'
import pricesTranslations from './portaladministrativo/Prices/Translations'
import pricingTranslations from './portaladministrativo/Pricing/main.i18n.js'
import proceduresTranslations from './portaladministrativo/Procedures/Translations/index'
import productsTranslations from './portaladministrativo/Products/Translations/index'
import quartersTranslations from './portaladministrativo/Quarters/Translations/index'
import reportsTranslations from './portaladministrativo/Reports/Translations'
import rolesTranslations from './portaladministrativo/Roles/Translations/index'
import scholarshipsTranslations from './portaladministrativo/Scholarships/Translations/index'
import sectionsTranslations from './portaladministrativo/Sections/Translations/index'
import settingsTranslations from './portaladministrativo/Settings/Translations/index'
import sistemaQTranslations from './portaladministrativo/SistemaQExamBoard/Translations'
import studentPreRegistrationTranslations from './portaladministrativo/StudentPreRegistration/Translations'
import studentSubjectsTranslations from './portaladministrativo/StudentSubjects/Translations'
import subjectsSyncTranslations from './portaladministrativo/SubjectsSync/Translations';
import subjectsTranslations from './portaladministrativo/Subjects/Translations/index'
import usersTranslations from './portaladministrativo/Users/Translations/index'
import validatorTranslations from './portaladministrativo/Validator/Translations/index'
import zonesTranslations from './portaladministrativo/Zones/Translations/index'
import statusConnectionTranslations from './portaladministrativo/StatusConnection/Translations/index'
import moduleControlTranslations from './portaladministrativo/ModuleControl/Translations/index'

import paymentIntentsTranslations from './portaladministrativo/PaymentIntents/Translations/index'
import messagingTranslations from './portaladministrativo/Messaging/main.i18n'


// Comisiones translations
import comisionesTranslations from './comisiones/i18n'

// Portal Alumno translations
import portalAlumnoTranslations from './portalalumno/i18n'


const modulesTranslations = {
  es_CL: {
    // General translations
    exams_group: {...examsTranslations.es_CL},
    login: { ...loginApplicationTranslations.es_CL },
    subjects_sync: { ...subjectsSyncTranslations.es_CL },
    messaging: { ...messagingTranslations.es_CL },
    zones: { ...zonesTranslations.es_CL },
    examExceptions: { ...examExceptionTranslations.es_CL},
    exceptions: { ...ExceptionsTranslations.es_CL},
    academic: { ...academicTranslations.es_CL },
    studentSubjects: { ...studentSubjectsTranslations.es_CL },
    preRegistration: { ...studentPreRegistrationTranslations.es_CL },

    // Portal Alumno translations
    portalalumno: { ...portalAlumnoTranslations.es_CL },
  },
  es_PE: {
      // General translations
      academic: { ...academicTranslations.es_PE },
      profile: { ...profileTranslations.es_PE },
      users: { ...usersTranslations.es_PE },
      zones: { ...zonesTranslations.es_PE },
      settings: { ...settingsTranslations.es_PE },
      quarters: { ...quartersTranslations.es_PE },
      login: { ...loginApplicationTranslations.es_PE },
      validator: { ...validatorTranslations.es_PE },
      scholarships: { ...scholarshipsTranslations.es_PE },
      prices: { ...pricesTranslations.es_PE },
      preRegistration: { ...studentPreRegistrationTranslations.es_PE },
      careers: {...careersTranslations.es_PE},
      subjects: { ...subjectsTranslations.es_PE },
      sections: { ...sectionsTranslations.es_PE },
      departments: { ...departmentsTranslations.es_PE },
      procedures: { ...proceduresTranslations.es_PE },
      benefits: { ...benefitsTranslations.es_PE },
      examExceptions: { ...examExceptionTranslations.es_PE },
      exam_enabler: { ...ExamEnablerTranslations.es_PE },
      exams: { ...examTestTranslations.es_PE },
      examEditor: { ...ExamEditorTranslations.es_PE },
      studentSubjects: { ...studentSubjectsTranslations.es_PE },
      subjects_sync: { ...subjectsSyncTranslations.es_PE },
      admission: { ...admissionTranslations.es_PE },
      
      // Comisiones translations
      comisiones: { ...comisionesTranslations.es_PE },

      // Portal Alumno translations
      portalalumno: { ...portalAlumnoTranslations.es_PE },
  },  
  es: {
    // General translations
    academic: { ...academicTranslations.es },
    profile: { ...profileTranslations.es },
    users: { ...usersTranslations.es },
    zones: { ...zonesTranslations.es },
    settings: { ...settingsTranslations.es },
    roles: { ...rolesTranslations.es },
    quarters: { ...quartersTranslations.es },
    login: { ...loginApplicationTranslations.es },
    validator: { ...validatorTranslations.es },
    discounts: { ...discountsTranslations.es },
    scholarships: { ...scholarshipsTranslations.es },
    degrees: { ...degreesTranslations.es },
    home: { ...dashboardTranslations.es },
    prices: { ...pricesTranslations.es },
    applications: { ...applicationsTranslations.es },
    product: { ...productsTranslations.es },
    preRegistration: { ...studentPreRegistrationTranslations.es },
    careers: {...careersTranslations.es},
    subjects: { ...subjectsTranslations.es },
    sections: { ...sectionsTranslations.es },
    departments: { ...departmentsTranslations.es },
    procedures: { ...proceduresTranslations.es },
    sistemaQ: {...sistemaQTranslations.es },
    communities: { ...CommunitiesTranslations.es },
    benefits: { ...benefitsTranslations.es },
    examExceptions: { ...examExceptionTranslations.es },
    conciliation: { ...conciliationTranslations.es },
    examTitleNotes: { ...examTitleNotesTranslations.es },
    exam_enabler: { ...ExamEnablerTranslations.es },
    exams: { ...examTestTranslations.es },
    exams_group: {...examsTranslations.es},
    examEditor: { ...ExamEditorTranslations.es },
    exceptions: { ...ExceptionsTranslations.es },
    studentSubjects: { ...studentSubjectsTranslations.es },
    reports: { ...reportsTranslations.es },
    commissions: { ...commissionsTranslations.es },
    prepaids: { ...prepaidsTranslations.es },
    subjects_sync: { ...subjectsSyncTranslations.es },
    admission: { ...admissionTranslations.es },
    paymentIntents: { ...paymentIntentsTranslations.es },
    messaging: { ...messagingTranslations.es },
    pricing: { ...pricingTranslations.es },
    statusconnection: { ...statusConnectionTranslations.es },
    modulecontrol: { ...moduleControlTranslations.es },

    // Comisiones translations
    comisiones: { ...comisionesTranslations.es },

    // Portal Alumno translations
    portalalumno: { ...portalAlumnoTranslations.es },
  },
  en: {
    // General translations
    academic: { ...academicTranslations.en },
    profile: { ...profileTranslations.en },
    users: { ...usersTranslations.en },
    zones: { ...zonesTranslations.en },
    settings: { ...settingsTranslations.en },
    roles: { ...rolesTranslations.en },
    quarters: { ...quartersTranslations.en },
    validator: { ...validatorTranslations.en },
    login: { ...loginApplicationTranslations.en },
    discounts: { ...discountsTranslations.en },
    scholarships: { ...scholarshipsTranslations.en },
    degrees: { ...degreesTranslations.en },
    home: { ...dashboardTranslations.en },
    prices: { ...pricesTranslations },
    applications: { ...applicationsTranslations.en },
    product: { ...productsTranslations.en },
    preRegistration: { ...studentPreRegistrationTranslations.en },
    careers: {...careersTranslations.en},
    subjects: { ...subjectsTranslations.en },
    sections: { ...sectionsTranslations.en },
    departments: { ...departmentsTranslations.en },
    procedures: { ...proceduresTranslations.en },
    sistemaQ: {...sistemaQTranslations.en },
    communities: { ...CommunitiesTranslations.en },
    benefits: { ...benefitsTranslations.en },
    examExceptions: { ...examExceptionTranslations.en },
    conciliation: { ...conciliationTranslations.en },
    examTitleNotes: { ...examTitleNotesTranslations.en },
    exam_enabler: { ...ExamEnablerTranslations.en },
    exams: { ...examTestTranslations.en },
    exams_group: {...examsTranslations.en},
    examEditor: { ...ExamEditorTranslations.en },
    exceptions: { ...ExceptionsTranslations.en },
    studentSubjects: { ...studentSubjectsTranslations.en },
    reports: { ...reportsTranslations.en },
    commissions: { ...commissionsTranslations.en },
    prepaids: { ...prepaidsTranslations.en },
    subjects_sync: { ...subjectsSyncTranslations.en },
    admission: { ...admissionTranslations.en },
    paymentIntents: { ...paymentIntentsTranslations.en },
    messaging: { ...messagingTranslations.en },
    pricing: { ...pricingTranslations.en },
    statusconnection: { ...statusConnectionTranslations.en },
    modulecontrol: { ...moduleControlTranslations.en },

    // Comisiones translations
    comisiones: { ...comisionesTranslations.en },

    // Portal Alumno translations
    portalalumno: { ...portalAlumnoTranslations.en },
  }
}

export default modulesTranslations
