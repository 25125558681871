<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed($event)"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs
            :title="$t('modules.scholarships.table.breadcrumbs.title')"
            :description="$t('modules.scholarships.table.breadcrumbs.description')"
          >
            <Button v-if="canCreate" @clicked="newScholarship" icon="mdi-plus" :text="$t('actions.new_female')" depressed></Button>
          </Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :users="scholarships"
                :clear-selected="clearSelected"
                :pageCount="asidebar.pagination.pagesAmount"
                :page="asidebar.pagination.currentPage"
                :permissions="{read: canRead, update: canUpdate, delete: canDelete}"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                @bulk-delete="(scholarships)=>{bulkDeletePopUp(scholarships)}"
                @deleteUser="(id)=>deleteScholarshipPopUp(id)"
                @updateUser="(id)=>{updateScholarship(id)}"
                @openDetails="(id)=>{openDetails(id)}"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import AsideBar from '@/components/AsideBar/AsideBar';
import Button from '@/components/Button/Button';
import { $products, $scholarships } from '../services';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'ScholarshipsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    AsideBar,
    Button
  },
  data() {
    return {
      clearSelected: '',
      scholarships: [],
      billingProduct: [],
      rawScholarships: [],
      discounts: [],
      asidebar: {
        title: this.$t('modules.scholarships.table.filters.title'),
        pagination: {
          limit: 500,
          page: 0
        },
        items: [
          {
            name: this.$t('modules.scholarships.table.filters.product'),
            filters: [
              {
                name: 'Matricula',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              },
              {
                name: 'Arancel',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              }
            ]
          },
          {
            name: this.$t('modules.scholarships.table.filters.state'),
            filters: [
              {
                name: 'Activo',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              },
              {
                name: 'Inactivo',
                type: 'check',
                value: true,
                items: [],
                dataFiltered: ''
              }
            ]
          },
          {
            name: this.$t('modules.scholarships.table.filters.discountType'),
            filters: [
              {
                name: 'Porcentual',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              },
              {
                name: 'Monto',
                type: 'check',
                items: [],
                value: true,
                dataFiltered: ''
              }
            ]
          }
        ]
      },
      headers: [
        {
          text: this.$t('modules.scholarships.table.headers.code'),
          value: 'code'
        },
        {
          text: this.$t('modules.scholarships.table.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('modules.scholarships.table.headers.status'),
          value: 'status'
        },
        {
          text: this.$t('modules.scholarships.table.headers.alumns'),
          value: 'alumns'
        },
        {
          text: this.$t('modules.scholarships.table.headers.discounts'),
          value: 'discounts'
        },
        {
          text: this.$t('modules.scholarships.table.headers.from'),
          value: 'from'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    };
  },
  mounted() {
    this.getBillingProducts();
    this.fetchData();
  },
  computed: {
    canRead() {
      return this.$permissions.portaladministrativo.prices.scholarships.READ
    },
    canCreate() {
      return this.$permissions.portaladministrativo.prices.scholarships.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.scholarships.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.scholarships.DELETE
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions'
    })
  },
  methods: {
    async getBillingProducts() {
      try {
        this.billingProduct = await $products.find();
        this.billingProduct = this.billingProduct.data.content.map(product => {
          return {
            name: product.name,
            id: product.id,
            type: 'check',
            items: [],
            value: true,
            dataFiltered: ''
          };
        });
        this.asidebar.items[0].filters = this.billingProduct;
      } catch (error) {
        this.billlingProduct = []
        throw error;
      }
    },
    async onInputPagination() {
      // event param
      // this.asidebar.pagination.page = event;
      // if (!this.scholarships[(event - 1) * this.asidebar.pagination.limit]) {
      //   let fetchedScholarships = await $scholarships.find(null, null, {
      //     params: {
      //       page: this.asidebar.pagination.page - 1,
      //       length: this.asidebar.pagination.limit
      //     }
      //   });
      //   fetchedScholarships.data.content.forEach((element, index) => {
      //     this.scholarships[
      //       index + (event - 1) * this.asidebar.pagination.limit
      //     ] = this.formatScholarships(element);
      //   });
      //   this.scholarships = Array.from(this.scholarships);
      // }
      // this.asidebar.pagination.currentPage = event;
    },
    changed() {
      let filteredItems = this.rawScholarships;
      this.asidebar.items.forEach(element => {
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.product')) {
          filteredItems = filteredItems.filter(item => {
            let found = false;

            element.filters.forEach(filter => {
              item.discounts.forEach(discount => {
                if (discount.product === filter.id && filter.value) {
                  found = true;
                  return;
                }
              });

              if (found) return;
            });

            if (found) return item;
          });
        }
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.state')) {
          filteredItems = filteredItems.filter(item => {
            if (
              element.filters[0].name === this.$t('modules.scholarships.table.filters_asidebar.active') &&
              element.filters[0].value === true
            ) {
              if (item.status === true) {
                return item;
              }
            }
            if (
              element.filters[1].name === this.$t('modules.scholarships.table.filters_asidebar.inactive')&&
              element.filters[1].value === true
            ) {
              if (item.status === false) {
                return item;
              }
            }
          });
        }
        if (element.name === this.$t('modules.scholarships.table.filters_asidebar.discountType')) {
          filteredItems = filteredItems.filter(item => {
            let found = false;
            element.filters.forEach(filter => {
              item.discounts.forEach(discount => {
                if (discount.name === filter.name && filter.value) {
                  found = true;
                  return;
                }
              });

              if (found) return;
            });

            if (found) return item;
          });
        }

        return false;
      });
      this.scholarships = filteredItems;
    },
    onLengthPaginationChange(event) {
      this.asidebar.pagination = {
        page: 1,
        limit: event
      };
    },
    deleteScholarshipPopUp(id) {
      const scholarship = this.scholarships.find(
        scholarship => scholarship.id == id
      );
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [
          {
            value: scholarship.name
          }
        ],
        actionPrimary: {
          text: 'Aceptar',
          callback: () => this.deleteScholarship(id)
        },
        actionSecondary: {
          text: 'Cancelar',
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async fetchData() {
      let scholarships;
      try {
        // if (Object.keys(this.currentFormattedFilters).length) {
        // scholarships = await $terms.findWithFilter(this.currentFormattedFilters, {params: {page: this.asidebar.pagination.page, length: this.asidebar.pagination.limit}})
        // } else {
        // scholarships = await $scholarships.find(null, null, {
        //   params: {
        //     page: this.asidebar.pagination.page - 1,
        //     length: this.asidebar.pagination.limit
        //   }
        // });
        scholarships = await $scholarships.find(null, null, {
          params: {
            page: this.asidebar.pagination.page,
            length: this.asidebar.pagination.limit
          }
        });
        // }
        this.scholarships = this.formatScholarships(scholarships.data.content);
        this.rawScholarships = JSON.parse(JSON.stringify(this.scholarships));
        this.asidebar.pagination.pagesAmount = scholarships.data.totalPages;
        // const fetchedModalityTypes = await $types.find(null, null, { params: { type: 'MODALITY_TYPE' } });
        // this.modalityTypes = fetchedModalityTypes.data
        // this.asidebar.items.push(this.generateModalityFilter(this.modalityTypes))
      } catch (error) {
        // eslint=disable-line
      }
    },
    formatScholarships(scholarships) {
      return scholarships.map(scholarship => {
        // let count = 0
        return {
          id: scholarship.id,
          code: scholarship.code,
          name: scholarship.description,
          status: scholarship.status,
          alumns: scholarship.studentCount,
          discounts: scholarship.scholarshipProduct
            ? scholarship.scholarshipProduct.map(product => {
                return {
                  id: product.id,
                  product: product.billingProduct.id,
                  name: product.discountType
                    ? product.discountType.charAt(0).toUpperCase() +
                      product.discountType.slice(1)
                    : ''
                };
              })
            : [],
          from: this.configurationValues.dateFormat
            ? moment(scholarship.from).format(
                this.configurationOptions.dateFormat
                  .find(
                    config =>
                      config.value === this.configurationValues.dateFormat
                  )
                  .text.toUpperCase()
              )
            : moment(scholarship.from).format('YYYY-MM-DD')
        };
      });
    },
    bulkDeletePopUp(scholarships) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.scholarships.table.message.title'),
        content: scholarships.map(scholarship => ({
          value: scholarship.name
        })),
        actionPrimary: {
          text: this.$t('modules.scholarships.table.message.deleteAction'),
          callback: async () => {
            try {
              await this.bulkDelete(scholarships);
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t(
                  'modules.scholarships.table.error_message.bulk_delete_message'
                ),
                content: scholarships.map(scholarship => ({
                  value: scholarship.name
                })),
                actionPrimary: { text: 'Aceptar', callback() {} },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
              });
            }
          }
        },
        actionSecondary: {
          text: this.$t('modules.subjects.table.message.cancelAction'),
          callback() {}
        },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async bulkDelete(scholarships) {
      const itemsForDelete = scholarships.map(scolar => {
        return { id: scolar.id };
      });

      try {
        await $scholarships.bulkDelete(itemsForDelete);
      } finally {
        this.fetchData();
      }
    },
    newScholarship() {
      if (this.canCreate) this.$router.push('/scholarships/create');
    },
    updateScholarship(id) {
      if (this.canUpdate) this.$router.push(`scholarships/update/${id}`);
    },
    openDetails(id) {
      if (this.canRead) this.$router.push(`scholarships/details/${id}`);
    },
    async deleteScholarship(id) {
      try {
        await $scholarships.delete(id);
        this.scholarships = this.scholarships.filter(
          scholarship => scholarship.id !== id
        );
      } finally {
        this.fetchData();
      }
    }
  }
};
</script>
