import { mapGetters } from 'vuex';
import sisConstants from '@/constants/sisConstants.js'
import Constants from '@/plugins/constants'
import CountryConstants from '@/constants/countryConstants'
import md5 from 'js-md5';
import {$configuration} from '@/services';
import { AES, enc } from 'crypto-js';
import Configuration from '@/constants/configuration';

export default {
    methods: {
        $sisConstants (path = '') {
            /*
                Input: String (default: empty string) -> Path to value inside object
                Output: Any Value -> SIS constant in input route
            */
            const keys = path.split('.');
            let result = sisConstants;

            keys.forEach(key => {
                if(result[key]) result = result[key]
                else return path
            })

            return result
        },
        $trackEvent(category, action, label) {
            this.$gtag ? this.$gtag.event(action, {
                'event_category': category,
                'event_label': label,
                'value': 1
            }) : '';
        },
        $validateRutLastCharacter(evt) {
            if( evt.key != 'k' && evt.key != 'K' ){
                evt.preventDefault();
            }
        },
        $validateLengthInput(evt, length) {
            if ((length !== null) && (evt.keyCode != 8) && (evt.keyCode != 9) && (evt.target.value.length >= length)) {
                evt.preventDefault();
            }
        },
        $accessDenied() {
            this.$router.replace('/access-denied')
        },
        $validateIntegerInputNumber(evt, item, max = null, length = null, min=0) {
            const keyCode = (evt.keyCode ? evt.keyCode : evt.which)
            if (keyCode < 48 || keyCode > 57) {
                evt.preventDefault();
            }

            const sum = item ? item + (evt.key ? evt.key : '0') : (evt.key ? evt.key : '0');
            if (sum === '.' || sum === '..' || sum.startsWith('00') || Number(sum) < min || (max !== null && Number(sum) > max)) {
                evt.preventDefault();
            }

            if ((length !== null) && (evt.keyCode != 8) && (evt.keyCode != 9) && (evt.target.value.length >= length)) {
                evt.preventDefault();
            }
        },
        $validateDecimalInputNumber(evt, item, max = null, length = null) {
            const itemString = (item) ? item.toString() : ''
            const keyCode = (evt.keyCode ? evt.keyCode : evt.which)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || itemString.indexOf('.') !== -1)) {
                evt.preventDefault()
            }
            if (itemString != null && itemString.indexOf('.') > -1 && (itemString.split('.')[1].length > 1)) {
                evt.preventDefault()
            }
            const sum = evt.target.value + (evt.key ? evt.key : '0');
            if (sum === '.' || sum === '..' || sum.startsWith('00') || Number(sum) < 0 || (max !== null && Number(sum) > max)) {
                evt.preventDefault();
            }

            if ((length !== null) && (evt.keyCode != 8) && (evt.keyCode != 9) && (evt.target.value.length >= length)) {
                evt.preventDefault();
            }
        },
        $validateAlphanumeric(evt, item, length, withSpaces = false, alphabetic = false) {
            const reg1 = alphabetic ? /[a-zá-úñ]/i : /[a-zá-úñ0-9]/i
            const reg2 = alphabetic ? /[a-zá-úñ]$/i : /[a-zá-úñ0-9]$/i
            if (!reg1.test(evt.key) && !(withSpaces && /\s/.test(evt.key) && reg2.test(item.substring(0, evt.target.selectionStart)))) evt.preventDefault()
            if (evt.target.value.length >= length) evt.preventDefault();
        },
        $redirectToCanvas(canvasId, username) {
            const isBlocked = ( listModules) => {
                  const listModul =   listModules.map(m => m.code)
                  return listModul.includes('portalalumno-canvas')
              }
            if(this.studentData.modulesblocked && isBlocked(this.studentData.modulesblocked) ) return this.$router.push('/access-blocked')
            if (!canvasId) {
                window.open(this.$countryConstants.canvas.url, '_blank')
            } else {
                const d = new Date();
                const date = `${(`0${d.getDate()}`).slice(-2)}${(`0${d.getMonth() + 1}`).slice(-2)}${d.getFullYear().toString()}`;

                fetch(this.$countryConstants.canvas.ssoUrl, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
                    body: `TOKEN=${md5(`${this.$countryConstants.canvas.ssoKey}${date}${username}`)}&LOGIN=${username}`,
                    redirect: 'follow',
                }).then(res => res.text())
                .then(data => {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(data, 'text/html');
                    const form = doc.querySelector('form');
                    form.setAttribute('target', '_blank')

                    const app = window.document.getElementById('app');
                    app.append(form)
                    form.submit()
                    form.remove()
                })
            }
        },
        $getMpKey(ticket = false) {
            if (this.$mpConfig) {
                if (ticket || !this.$isGestor) return this.$mpConfig.publicKeyStudent
                else return this.$mpConfig.publicKeyAdviser
            } else {
                return null
            }
        },
        async $getSystemConfig() {
            const response =  await $configuration.find()
            return response
        },
        $encrypter(value){
            const iv = `${(Math.random()+1).toString(16).substring(2, 10)}${(Math.random()+1).toString(16).substring(2, 10)}`;
            const encrytedValue = AES.encrypt(
                enc.Utf8.parse(Buffer.from(value.toString()).toString('base64')),
                enc.Utf8.parse(Configuration.value('secret')), 
                { iv: enc.Utf8.parse(iv) }
            );
   
            return `${encrytedValue.iv.toString(enc.Base64)};${encrytedValue}`;
        },
        $decryter(value){
            const decryterValue = Buffer.from(
                    AES.decrypt(
                        value.split(';')[1],
                        enc.Utf8.parse(Configuration.value('secret')), 
                        { iv: enc.Base64.parse(value.split(';')[0]) }
                    ).toString(enc.Utf8), 'base64').toString('ascii');
            return decryterValue;        
        },
    },
    computed: {
        ...mapGetters({
            mixinPermissions: 'commons/getCRUDPermissions',
            isUserGestor: 'commons/isUserGestor',
            studentData: 'commons/getStudentData',
            $mpConfig: 'commons/getMpConfig',
        }),
        $isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        $isGestor() {
            return this.isUserGestor
        },
        $theme() {
            return Constants.THEME
        },
        $country() {
            return Configuration.value('country') || 'argentina'
        },
        $isIPP() {
            return this.$theme == 'ipp'
        },
        $isTeclab() {
            return this.$theme == 'teclab'
        },
        $APP () {
            return Constants.APP
        },
        $isPortalAdministrativo() {
            return this.$APP == 'portaladministrativo'
        },
        $isPortalComisiones() {
            return this.$APP == 'comisiones'
        },
        $isPortalAlumno() {
            return this.$APP == 'portalalumno'
        },
        $permissions() {
            const permissions = this.mixinPermissions
            const defaultData = {
                applications: {STATUS: false, READ: false},
                dashboard: {STATUS: false, READ: false},
                settings: {
                    legales: {STATUS: false, READ: false, UPDATE: false},
                    communication: {STATUS: false, READ: false, UPDATE: false},
                    system: {STATUS: false, READ: false, UPDATE: false},
                    billing: {STATUS: false, READ: false, UPDATE: false},
                    commissions: {STATUS: false, READ: false, UPDATE: false},
                },
                comisiones: {
                    liquidations: {STATUS: false, READ: false},
                },
                portalalumno: {
                    academic_charge: {
                        subjects: {STATUS: false, READ: false, CREATE: false, UPDATE: false},
                        study_plan: {STATUS: false, READ: false},
                    },
                    exams: {STATUS: false, READ: false, CREATE: false, DELETE: false},
                    procedures: {STATUS: false, READ: false, REENROLLMENT: false, RETIREMENT: false, CAREER_FREEZE: false, TNE: false, EXAM_CHARGE: false, REINSTATEMENT: false, CAREER_CHANGE: false, SCHOLARSHIP: false, BENEFIT: false, DEFINITIVE_LOW:false, TEMPORARY_LOW:false, PROCEDURE_CEO_CHANGE: false, EXAM_TITLE: false, RAIC: false},
                    apps: {STATUS: false, READ: false},
                    reglamento: {STATUS: false, READ: false},
                    payments: {
                        payments_students: { STATUS: false, READ: false, CREATE: false },
                        checking_account : {STATUS: false, READ: false}
                    },
                    professionalizing_practice: {STATUS: false, READ: false},
                },
                portaladministrativo: {
                    moduleControl: {STATUS: false, READ: false, VALIDATION_TERM: false, VALIDATION_ACCOUNT: false, VALIDATION_DISCOUNT: false, VALIDATION_SUBJECT: false},
                    academic: {
                        careers: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        degrees: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        departments: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        documentation: {STATUS: false, READ: false},
                        gradebook: {STATUS: false, READ: false},
                        intermediate_degree: {STATUS: false, READ: false},
                        periods: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false, FINISH_SUBJECTS_BIANUAL: false, FINISH_SUBJECTS_PRACTICAL: false},
                        professionalizing_practice: {STATUS: false, READ: false, UPDATE: false},
                        sanctions: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        sections: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        subjects: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        subjects_sync: {STATUS: false, READ: false, UPDATE: false},
                        professional_practice_terms: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        equivalences: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},

                    },
                    admission: {
                        pre_registration: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    },
                    commissions: {
                        config: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        objectives: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        prizes: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        ranges: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        sellers: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    },
                    exams: {
                        creation: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        exception: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        enabler: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        editor: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        statements: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    },
                    exceptions: {STATUS: false, READ: false, DEBT_INSCRIPTION: false, CONFIRM_PREREGISTRATION: false, REVERT_ENROLLMENT: false, CHANGE_MODALITY: false, PAYMENT_ADJUSTMENT: false, ALLOW_REENROLLMENT: false},
                    procedures: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    reports: {STATUS: false, READ: false, REPORT_ADMISSIONS: false, REPORT_EXAMS: false, REPORT_GRADUATES: false, REPORT_ENGLISH: false, REPORT_INSCRIPTIONS: false, REPORT_DUE_ACCOUNTS: false, REPORT_LEVELS: false,
                        REPORT_STUDENT_ACCOUNT: false, REPORT_RESERVATIONS: false, REPORT_REENROLLMENTS: false, REPORT_NONRESERVATIONS: false, REPORT_TITLEDS: false, REPORT_PROCESSINGS: false, REPORT_ACADEMIC: false, REPORT_SECTIONS: false,
                        REPORT_NPS: false, REPORT_PPS_APPROVED: false, REPORT_PPS_COMPLETED: false, REPORT_NI_RI: false, REPORT_HISTORY_MP: false, REPORT_SUBJECT_STATUS: false, REPORT_GENERAL_DATA: false, REPORT_PPS: false, REPORT_STATES_QUOTAS: false,
                        REPORT_SEMINAR: false, REPORT_CONFIRMED_SUBJECT: false, REPORT_CARTERA: false, REPORT_INSCRIPTIONS_PPS: false, REPORT_OLD_MODALITY: false, REPORT_CONSOLIDATED_EXAMS: false, REPORT_ENROLLMENTS: false,
                        REPORT_PRACTICE: false, REPORT_TNE: false, REPORT_EXAMS_INFO: false, REPORT_SUBJECT: false, REPORT_ENRROLMENTS: false, REPORT_CURRICULAR_ADVANCEMENT: false, REPORT_INSCRIPTION_PPS: false, REPORT_DISCOUNTS: false, REPORT_MANDATORY_DOCUMENTATION: false },
                    roles: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    users: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false, ASIGNAR_ROLES: false},

                    prices: {
                        benefits: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false, ASSIGN: false},
                        communities: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        conciliations: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        discounts: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        prepaids: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        prices: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        products: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        quarters: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        scholarships: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false, ASSIGN: false},
                        zones: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        paymentIntents: {STATUS: false, READ: false, UPDATE: false},
                        subscription_rules: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        billing:{STATUS:false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        referred_companies: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        interest: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    },

                    students: {
                        exam_title_notes: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        subjects: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                    },
                    messaging:{
                        banners: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false},
                        templates: {STATUS: false, READ: false, CREATE: false, UPDATE: false, DELETE: false, SEND: false},
                        whatsapp: {STATUS: false, READ: false},
                    },
                },

            }

            if (permissions) {
                if (permissions['portaladministrativo-applications']) defaultData.applications = permissions['portaladministrativo-applications']
                if (permissions['portaladministrativo-dashboard']) defaultData.dashboard = permissions['portaladministrativo-dashboard']

                // Settings
                if (permissions['portaladministrativo-settings']) defaultData.settings = permissions['portaladministrativo-settings']
                if (permissions['portaladministrativo-legales']) defaultData.settings.legales = permissions['portaladministrativo-legales']
                if (permissions['portaladministrativo-communication']) defaultData.settings.communication = permissions['portaladministrativo-communication']
                if (permissions['portaladministrativo-system']) defaultData.settings.system = permissions['portaladministrativo-system']
                if (permissions['portaladministrativo-billing-setting']) defaultData.settings.billing = permissions['portaladministrativo-billing-setting']
                if (permissions['portaladministrativo-commissions']) defaultData.settings.commissions = permissions['portaladministrativo-commissions']

                // Portal comisiones
                if (permissions['portaladministrativo-comisiones']) defaultData.comisiones.liquidations = permissions['portaladministrativo-comisiones']

                // Portal alumno
                if (permissions['portalalumno-exams']) defaultData.portalalumno.exams = permissions['portalalumno-exams']
                if (permissions['portalalumno-subjects']) defaultData.portalalumno.academic_charge.subjects = permissions['portalalumno-subjects']
                if (permissions['portalalumno-study-plan']) defaultData.portalalumno.academic_charge.study_plan = permissions['portalalumno-study-plan']
                if (permissions['portalalumno-reglamento']) defaultData.portalalumno.reglamento = permissions['portalalumno-reglamento']
                if (permissions['portalalumno-apps']) defaultData.portalalumno.apps = permissions['portalalumno-apps']
                if (permissions['portalalumno-procedures']) defaultData.portalalumno.procedures = permissions['portalalumno-procedures']
                if (permissions['portalalumno-checking-account']) defaultData.portalalumno.payments.checking_account = permissions['portalalumno-checking-account']
                if (permissions['portalalumno-payments-student']) defaultData.portalalumno.payments.payments_students = permissions['portalalumno-payments-student']
                if (permissions['portalalumno-pp-practice']) defaultData.portalalumno.professionalizing_practice = permissions['portalalumno-pp-practice']

                // Portal administrativo
                if (permissions['portaladministrativo-exceptions']) defaultData.portaladministrativo.exceptions = permissions['portaladministrativo-exceptions']
                if (permissions['portaladministrativo-module-control']) defaultData.portaladministrativo.moduleControl = permissions['portaladministrativo-module-control']
                if (permissions['portaladministrativo-procedures']) defaultData.portaladministrativo.procedures = permissions['portaladministrativo-procedures']
                if (permissions['portaladministrativo-reports']) defaultData.portaladministrativo.reports = permissions['portaladministrativo-reports']
                if (permissions['portaladministrativo-roles']) defaultData.portaladministrativo.roles = permissions['portaladministrativo-roles']
                if (permissions['portaladministrativo-users']) defaultData.portaladministrativo.users = permissions['portaladministrativo-users']

                // Academic's group
                if (permissions['portaladministrativo-careers']) defaultData.portaladministrativo.academic.careers = permissions['portaladministrativo-careers']
                if (permissions['portaladministrativo-degrees']) defaultData.portaladministrativo.academic.degrees = permissions['portaladministrativo-degrees']
                if (permissions['portaladministrativo-departments']) defaultData.portaladministrativo.academic.departments = permissions['portaladministrativo-departments']
                if (permissions['portaladministrativo-sections']) defaultData.portaladministrativo.academic.sections = permissions['portaladministrativo-sections']
                if (permissions['portaladministrativo-subjects']) defaultData.portaladministrativo.academic.subjects = permissions['portaladministrativo-subjects']
                if (permissions['portaladministrativo-subjects_sync']) defaultData.portaladministrativo.academic.subjects_sync = permissions['portaladministrativo-subjects_sync']
                if (permissions['portaladministrativo-periods']) defaultData.portaladministrativo.academic.periods = permissions['portaladministrativo-periods']
                if (permissions['portaladministrativo-documentation']) defaultData.portaladministrativo.academic.documentation = permissions['portaladministrativo-documentation']
                if (permissions['portaladministrativo-gradebook']) defaultData.portaladministrativo.academic.gradebook = permissions['portaladministrativo-gradebook']
                if (permissions['portaladministrativo-intermediate-degree']) defaultData.portaladministrativo.academic.intermediate_degree = permissions['portaladministrativo-intermediate-degree']
                if (permissions['portaladministrativo-sanctions']) defaultData.portaladministrativo.academic.sanctions = permissions['portaladministrativo-sanctions']
                if (permissions['portaladministrativo-pp-practice']) defaultData.portaladministrativo.academic.professionalizing_practice = permissions['portaladministrativo-pp-practice']
                if (permissions['portaladministrativo-practice-terms']) defaultData.portaladministrativo.academic.professional_practice_terms = permissions['portaladministrativo-practice-terms']
                if (permissions['portaladministrativo-equivalences']) defaultData.portaladministrativo.academic.equivalences = permissions['portaladministrativo-equivalences']

                // Admission's group
                if (permissions['portaladministrativo-pre_registration']) defaultData.portaladministrativo.admission.pre_registration = permissions['portaladministrativo-pre_registration']

                // Commission's group
                if (permissions['portaladministrativo-config ']) defaultData.portaladministrativo.commissions.config = permissions['portaladministrativo-config ']
                if (permissions['portaladministrativo-objective']) defaultData.portaladministrativo.commissions.objectives = permissions['portaladministrativo-objective']
                if (permissions['portaladministrativo-prize']) defaultData.portaladministrativo.commissions.prizes = permissions['portaladministrativo-prize']
                if (permissions['portaladministrativo-ranges']) defaultData.portaladministrativo.commissions.ranges = permissions['portaladministrativo-ranges']
                if (permissions['portaladministrativo-sellers']) defaultData.portaladministrativo.commissions.sellers = permissions['portaladministrativo-sellers']

                // Exam's group
                if (permissions['portaladministrativo-creation']) defaultData.portaladministrativo.exams.creation = permissions['portaladministrativo-creation']
                if (permissions['portaladministrativo-exception']) defaultData.portaladministrativo.exams.exception = permissions['portaladministrativo-exception']
                if (permissions['portaladministrativo-editor']) defaultData.portaladministrativo.exams.editor = permissions['portaladministrativo-editor']
                if (permissions['portaladministrativo-enabler']) defaultData.portaladministrativo.exams.enabler = permissions['portaladministrativo-enabler']
                if (permissions['portaladministrativo-statements']) defaultData.portaladministrativo.exams.statements = permissions['portaladministrativo-statements']

                // Student's group
                if (permissions['portaladministrativo-exam_title_notes']) defaultData.portaladministrativo.students.exam_title_notes = permissions['portaladministrativo-exam_title_notes']
                if (permissions['portaladministrativo-student-subjects']) defaultData.portaladministrativo.students.subjects = permissions['portaladministrativo-student-subjects']

                // Messaging's group
                if (permissions['portaladministrativo-banners']) defaultData.portaladministrativo.messaging.banners = permissions['portaladministrativo-banners']
                if (permissions['portaladministrativo-templates']) defaultData.portaladministrativo.messaging.templates = permissions['portaladministrativo-templates']
                if (permissions['portaladministrativo-whatsapp']) defaultData.portaladministrativo.messaging.whatsapp = permissions['portaladministrativo-whatsapp']

                // Prices's group
                if (permissions['portaladministrativo-benefits']) defaultData.portaladministrativo.prices.benefits = permissions['portaladministrativo-benefits']
                if (permissions['portaladministrativo-communities']) defaultData.portaladministrativo.prices.communities = permissions['portaladministrativo-communities']
                if (permissions['portaladministrativo-conciliations']) defaultData.portaladministrativo.prices.conciliations = permissions['portaladministrativo-conciliations']
                if (permissions['portaladministrativo-discounts']) defaultData.portaladministrativo.prices.discounts = permissions['portaladministrativo-discounts']
                if (permissions['portaladministrativo-prices']) defaultData.portaladministrativo.prices.prices = permissions['portaladministrativo-prices']
                if (permissions['portaladministrativo-prepaids']) defaultData.portaladministrativo.prices.prepaids = permissions['portaladministrativo-prepaids']
                if (permissions['portaladministrativo-products']) defaultData.portaladministrativo.prices.products = permissions['portaladministrativo-products']
                if (permissions['portaladministrativo-quarters']) defaultData.portaladministrativo.prices.quarters = permissions['portaladministrativo-quarters']
                if (permissions['portaladministrativo-scholarships']) defaultData.portaladministrativo.prices.scholarships = permissions['portaladministrativo-scholarships']
                if (permissions['portaladministrativo-zones']) defaultData.portaladministrativo.prices.zones = permissions['portaladministrativo-zones']
                if (permissions['portaladministrativo-billing']) defaultData.portaladministrativo.prices.billing = permissions['portaladministrativo-billing']
                if (permissions['portaladministrativo-payment-intents']) defaultData.portaladministrativo.prices.paymentIntents = permissions['portaladministrativo-payment-intents']
                if (permissions['portaladministrativo-subscription-rules']) defaultData.portaladministrativo.prices.subscription_rules = permissions['portaladministrativo-subscription-rules']
                if (permissions['portaladministrativo-referred-companies']) defaultData.portaladministrativo.prices.referred_companies = permissions['portaladministrativo-referred-companies']
                if (permissions['portaladministrativo-interest']) defaultData.portaladministrativo.prices.interest = permissions['portaladministrativo-interest']
            }
            return defaultData
        },
        $countryConstants() {
            return CountryConstants[this.$country]
        },
    },
    filters: {
        $capitalize(val) {
            if (typeof val !== 'string') return ''
            val = val.toLowerCase()
            return val.charAt(0).toUpperCase() + val.slice(1)
        },
        $capitalizeAll(val) {
            if (typeof val !== 'string') return ''
            val = val.toLowerCase().split(' ')
            let res = ''
            val.forEach(str => { res += ` ${str.charAt(0).toUpperCase()}${str.slice(1)}` });
            return res.trim()
        },
        $currency(value) {
            const { currency, currency_decimals } = CountryConstants[Configuration.value('country')]
            const val = (value / 1).toFixed(currency_decimals).replace('.', ',')
            return `${currency} ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
        },
        $roundNumber(value) {
          return Math.round((value + Number.EPSILON) * 100) / 100
        }
    }
}
