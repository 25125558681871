<template>
  <div>
    <v-container class="d-flex flex-row pa-0 fullHeight" fluid>

      <AsignatureSearch :items.sync="asignatures" @change="handleAsignaturesChange" :selected-items="selectedItems"/>

      <v-container class="container-custom scrollableContent">
        <v-row justify="center">
          <v-col sm="10">
            <OutlinedCard :title="$t('modules.careers.study_plan.drawer.basic_data.title')">
            <v-row>
              <v-col sm="9">
                <v-autocomplete
                  outlined
                  :label="$t('modules.careers.study_plan.drawer.basic_data.degree') + ' *'"
                  v-model="$v.degree.$model"
                  :error-messages="degreeErrors"
                  @blur="$v.degree.$touch"
                  @change="changedData"
                  :items="availableDegrees"
                  return-object
                  item-text="description"
                  item-value="value"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2">
                <v-text-field
                  outlined
                  :label="$t('modules.careers.study_plan.drawer.basic_data.plan') + ' *'"
                  v-model.lazy="$v.planYear.$model"
                  :error-messages="planYearErrors"
                  @change="changedData"
                  @blur="$v.planYear.$touch"
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  outlined
                  :label="$t('modules.careers.study_plan.drawer.basic_data.resolution')"
                  v-model="resolution"
                  @change="changedData"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="5">
                <v-select
                  outlined
                  :label="$t('modules.careers.study_plan.drawer.basic_data.modality') + ' *'"
                  v-model="$v.modalityType.$model"
                  :error-messages="modalityTypeErrors"
                  @blur="$v.modalityType.$touch"
                  @change="changedData"
                  :items="availableModalityTypes"
                  return-object
                  item-text="meaning"
                  item-value="id"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4">
                <v-switch class="pt-0 mt-0" color="primary" v-model="duplicateCarousel" label="Duplica carrousel"></v-switch>
              </v-col>
            </v-row>

            </OutlinedCard>
            <OutlinedCard :title="$t('modules.careers.study_plan.drawer.periods_subjects.title')">
              <v-row>
                <v-col sm="4">
                  {{$t('modules.careers.study_plan.drawer.periods_subjects.semester_amount')}}
                </v-col>
                <v-col sm="2">
                  <v-text-field
                    min="1"
                    max="39"
                    outlined
                    type="number"
                    v-model="semesterAmount"
                    @keypress="$validateIntegerInputNumber($event, semesterAmount, 39)"
                    @input="setAmount"
                    ref="numberinput"
                    default="1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </OutlinedCard>

        <v-row justify="center" class="StudyPlan noselect mb-4" no-gutters :key="`year-${index}`" v-for="(semesters,index) in plan">
          <v-col>
            <v-row>
              <v-col class="pb-0">
                <v-card flat class="text-center primary lighten-1 py-2">
                  <span class="headline font-weight-bold primary--text">{{toOrdinal(index)}} Año</span>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="StudyPlan__semester text-center" sm="6" :key="`semester-${semesterIndex}`" v-for="(semester, semesterIndex) in semesters">
                <span class="title StudyPlan__semester__title primary--text">{{toOrdinal(((index * 2) + semesterIndex))}} semestre</span>
                <v-row>
                  <v-col class="pr-1 pb-0">
                    <v-card flat class="text-center primary lighten-4 py-2">
                      <span class="subtitle-1 text-uppercase font-weight-medium white--text">Bimestre A</span>
                    </v-card>
                    <draggable
                      :group="{name:'foo'}"
                      tag="div"
                      class="StudyPlan__dragArea d-flex flex-column"
                      :value="semester.firstBimester"
                      @change="(event)=>moveSubject('ba',semester = ((index * 2) + semesterIndex), event)"
                    >

                      <SubjectsItem 
                        :item.sync="asignature" 
                        @open="selectedSubject = asignature" 
                        v-for="(asignature, index) in semester.firstBimester"
                        :key="`fb-${index}`" 
                      ></SubjectsItem>

                    </draggable>
                  </v-col>
                  <v-col class="pl-1 pb-0">
                    <v-card flat class="text-center primary lighten-4 py-2">
                      <span class="subtitle-1 text-uppercase font-weight-medium white--text">Bimestre B</span>
                    </v-card>
                    <draggable
                      :group="{name:'foo'}"
                      tag="div"
                      class="StudyPlan__dragArea d-flex flex-column"
                      :value="semester.secondBimester"
                      @change="(event)=>moveSubject('bb',semester = ((index * 2) + semesterIndex), event)"
                    >
                    
                      <SubjectsItem 

                        :item.sync="asignature" 
                        @open="selectedSubject = asignature" 
                        v-for="(asignature, index) in semester.secondBimester"
                        :key="`sb-${index}`" 

                      ></SubjectsItem>
                    
                    </draggable>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <draggable
                      :group="{name:'foo'}"
                      tag="div"
                      class="StudyPlan__dragArea d-flex flex-column fullWidth"
                      :value="semester.semesterAsignatures"
                      @change="(event)=>moveSubject('se',semester = ((index * 2) + semesterIndex), event)"
                    >
                      
                      <SubjectsItem 
                        :item.sync="asignature" 
                        @open="selectedSubject = asignature" 
                        v-for="(asignature, index) in semester.semesterAsignatures"
                        :key="`sa-${index}`"
                      ></SubjectsItem>
                    
                    </draggable>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      <div class="d-flex justify-end mb-12">
        <Button
          white
          :text="$t('actions.cancel')"
          @clicked="$emit('cancel')"
          class="mr-4"
        ></Button>

        <Button
          :loading="createButton.loading"
          :success="createButton.success"
          :error="createButton.error"
          :text="!editMode ? $t('actions.create') : $t('actions.save')"
          :successText="$t('actions.created')"
          :errorText="$t('actions.error')"
          :disabled="!canUpdate || $v.$invalid || !subjects.length > 0"
          @clicked="save"
        ></Button>
      </div>
        </v-col>
      </v-row>
      </v-container>
    </v-container>
    <CorrelativeSubjectsPopUp
      v-if="selectedSubject"
      :item="selectedSubject"
      @cancel="selectedSubject = null"
      @save="saveCorrelativesSelectedSubject"
      :availableSubjects="subjects"
    >
    </CorrelativeSubjectsPopUp>
  </div>
</template>

<style scoped lang="sass">
  .StudyPlan
    &__semester
      &__title
        font-size: 22px !important
    &__dragArea
      min-height: 40px
  
</style>

<script>
import AsignatureSearch from '../Components/AsignatureSearch';
import SubjectsItem from '../Components/SubjectsItem';
import CorrelativeSubjectsPopUp from '../Components/CorrelativeSubjectsPopUp';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';

import draggable from 'vuedraggable'

import { $types, $degrees, $studyPlans } from '../Services'
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'


//CONSTANTS 
const SEMESTRAL_DURATION_TYPE = 50;
const BIMESTRAL_DURATION_TYPE = 49; //eslint-disable-line no-unused-vars

const BIMESTER_A_TYPE = 52;
const BIMESTER_B_TYPE = 53;
const BIMESTER_C_TYPE = 180;

const BELONGS_TO_BIMESTER_A = 'ba';
const BELONGS_TO_BIMESTER_B = 'bb';
const BELONGS_TO_SEMESTRAL = 'se';

const PENDING_CORRELATIVITIES = [];

function asignSubjectsToBimester(subject){ 

  const newSubject = 
    {
      correlatives: subject.preRequisites?.map(corr=>{
        
        // SAVE CORRELATIVESWAY = 1

        PENDING_CORRELATIVITIES.push({
          subject: corr.preRequisite.id, 
          correlative: {
            correlativeWay:1,
            subject: {
              id: subject.subject.id,
              code: subject.subject.code,
              description: subject.subject.description,
            },
          }
        })

        return {
          correlativeWay:0,
          subject: {
            code: corr.preRequisite.code,
            description: corr.preRequisite.description,
            id: corr.preRequisite.id,
          }
        }
      }),
      id: subject.subject.id,
      code: subject.subject.code,
      description: subject.subject.description,
      duration: subject.subject.duration
    }

  const wayOne = PENDING_CORRELATIVITIES.filter(pendingCorr => {
    if(pendingCorr.subject === subject.subject.id) return pendingCorr
  }).map(filteredCorr => filteredCorr.correlative)

  newSubject.correlatives = newSubject.correlatives ? newSubject.correlatives.concat(wayOne) : wayOne
  

  //PENDING_CORRELATIVITIES = PENDING_CORRELATIVITIES.filter(pendingCorr => (
  //    pendingCorr.subject !== subject.subject.id
  //  ))
  /**
   * Asigna la materia al periodo correspondiente
   * Una materia semestral pertenece al bimestre ba, pero tiene duracion semestral
   * por lo tanto se chequea su duracion y se asigna que pertenece al periodo 'se'
   * 
   * Las materias que no sean de duracion semestral se las asigna al periodo correspondiente
   * Para las materias del primer bimestre: 'ba', y para las del segundo bimestre 'bb'
   */
  if(subject.subject.duration.value == SEMESTRAL_DURATION_TYPE) newSubject.belongsTo = BELONGS_TO_SEMESTRAL;
  else if(subject.termType.value == BIMESTER_A_TYPE) newSubject.belongsTo = BELONGS_TO_BIMESTER_A; 
  else if(subject.termType.value == BIMESTER_B_TYPE) newSubject.belongsTo = BELONGS_TO_BIMESTER_B;

  //Las materias vienen con un orden desde la api, ese orden debe ser respetado a menos que el usuario decida
  //mover las materias
  newSubject.order = subject.order;

  //Semestre al que pertenece
  newSubject.semester = subject.semester;

  //Si la materia es opcional o no
  newSubject.opcional = subject?.studyPlanSubjectType?.value === 58 

  return newSubject;
}

/**
 * El componente study plan create (revisar nombre) tiene como objetivo:
 * Permitir la creacion de la grilla de un plan de estudio
 * Permitir la modificacion de la grilla de un plan de estudio
 * asi como tambien la modificacion y creacion de sus datos basicos
 * 
 * Este componente no debe enviar datos del plan de estudio a la API, toda informacion del 
 * plan de estudio se mantiene contenida por el componente padre CareersCreate/Update
 * y dicho componente es el que envia actualizaciones del plan de estudio
 * 
 * El componente debe manejar:
 * Cantidad de semestres del plan (Graficamente se debe mostrar division por años)
 * Las materias que pertenecen a cada uno de los bimestres en cada semestre.
 * El orden que tienen las materias en cada bimestre.
 * Los prerequisitos que tiene una materia, y de quien es prerequisito dicha materia.
 * 
 * Es importante considerar que la estructura que maneja la api sobre planes de estudio
 * no corresponde del todo a la funcionalidad de la pantalla. 
 * 
 * Diferencias claras: 
 * API                                                          |     FRONT
 * - Mantiene una division de materias por semestre no por año  | - Muestra años con dos semestres por año
 * - Cada materia mantiene sus prerequisitos nada mas           | - Las materias mantienen de quien son prerequisitos 
 *                                                              |   y cuales son sus prerequisitos
 * - Las materias "semestrales" se diferencian de las del       | - Las materias semestrales estan separadas graficamente
 *   "bimestre A" unicamente por la duracion de la materia      |   de las materias del bimestre A
 * 
 */


export default {
  name: 'StudyPlanCreate',
  components: {
    AsignatureSearch,
    OutlinedCard,
    draggable,
    Button,
    SubjectsItem,
    CorrelativeSubjectsPopUp
  },
  props:{
    studyPlan: Object,
    closed: Boolean,
    isUpdate: Boolean,
    permissions: Object,
  },
  data() {
    return {
      editMode: false,
      semesterAmount: 1,
      asignatures: [],
      loadingAsignatures:false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      planYear: '',
      resolution: '',
      availableDegrees: [],
      degree: null,
      modalityType: null,
      availableModalityTypes: [],
      selectedSemesterAndBimester:null,
      subjects: [],

      selectedSubject: null,
      duplicateCarousel:false
    } 
  },
  async created(){
    // Fetch basico de data para completar los select.
    this.resetToInitialState();
    await this.fetchData();
    this.fetchStudyPlan(this.studyPlan)
  },
  watch:{
    //Si el drawer que contiene el componente se cierra, se reinician todos los datos al estado inicial.
    'closed':function(val){
      if(val){
        this.resetToInitialState();
      }
    },
    //Study plan viene undefined si se esta creando, si se esta editando viene con un valor y se hacen los 
    //fetch de datos necesarios para poder continuar el flujo
    'studyPlan': (val)=>this.fetchStudyPlan(val)
  },
  methods: {
    async fetchStudyPlan(val){
      this.loading = true;
      if(val){
        this.editMode = true;
        if(!val.studyPlan.subjects)// GO FETCH ALL THE STUDY PLAN 
          val = (await $studyPlans.findById(this.studyPlan.id))?.data;
        
        this.modalityType = val.modalityType;
        this.degree = val.degreesFinal;
        this.duplicateCarousel = val.studyPlan.duplicateCarousel
        val.studyPlan.subjects.forEach(subject => {
          if(this.semesterAmount < subject.semester){
            this.semesterAmount = subject.semester;
          }
        });

        this.planYear = val.studyPlan.description;
        this.resolution = val.studyPlan.resolution;
        
        //Parseo de las materias para que correspondan al modelo que trabaja front-end
        
        if(Array.isArray(val.studyPlan.subjects)) this.subjects = val.studyPlan.subjects.sort((subjA,subjB)=>{
          if(subjA.semester < subjB.semester) return -1
          else if(subjA.semester > subjB.semester) return 1
          else{
            if(subjA.termType.value < subjB.termType.value) return -1
            if(subjA.termType.value > subjB.termType.value) return 1
            else{
              if(subjA.order < subjB.order) return -1
              if(subjA.order > subjB.order) return 1
              else return 0
            }
          }
        }).map(subj=>{
          return asignSubjectsToBimester(subj)
        });
        
      }
      this.loading = false;
    },

    changedData() {
      this.$emit('changeStudyPlan')
    },
    saveCorrelativesSelectedSubject (correlatives) {
      this.selectedSubject.correlatives = correlatives
      const normalCorrelatives = correlatives.filter(subject => subject.correlativeWay === 0)
      normalCorrelatives.forEach(subject => {
        const selectedSubject = this.subjects.find(sub => subject.subject.id === sub.id)
        selectedSubject.correlatives = selectedSubject.correlatives ? selectedSubject.correlatives : []
        if (!selectedSubject.correlatives.find(corr => corr.subject.id === this.selectedSubject.id)) {
          selectedSubject.correlatives = [...selectedSubject.correlatives, ...[{correlativeWay: 1, subject: this.selectedSubject}]]
        }
      })
      const invertedCorrelatives = correlatives.filter(subject => (subject.correlativeWay === 1))
      invertedCorrelatives.forEach(subject => {
        const selectedSubject = this.subjects.find(sub => subject.subject.id === sub.id)
        selectedSubject.correlatives = selectedSubject.correlatives ? selectedSubject.correlatives : []
        if (!selectedSubject.correlatives.find(corr => corr.subject.id === this.selectedSubject.id)) {
          selectedSubject.correlatives = [...selectedSubject.correlatives, ...[{correlativeWay: 0, subject: this.selectedSubject}]]
        }
      })
      this.selectedSubject = null;
    },
    handleAsignaturesChange({added, moved, removed}){
      if(added){
        //remove from asignature from subjects
        const { element, newIndex } = added;
        const index = this.subjects.findIndex(s => s.id === element.id);
        
        this.subjects.splice(index, 1);

        //fix subjects order
        const list = this.subjects.filter(s=>s.belongsTo === element.belongsTo)
                                  .filter(s=> s.semester === element.semester )
        
        const subjects = this.subjects.filter(s=>!list.find(subject => subject.id === s.id));

        list.forEach((s, index)=> s.order = index);
        this.subjects = [...subjects, ...list];


        //add to asignatures
        this.asignatures.splice(newIndex, 0, element);
      }

      if(moved){
        const { newIndex, oldIndex } = moved;

        this.asignatures
        .splice(newIndex, 0, this.asignatures.splice(oldIndex, 1)[0]);
        
      }
      if(removed){
        this.asignatures.splice(removed.oldIndex, 1);
      }
    },
    moveSubject(bimester, semesterIn, {added, moved}){
      this.changedData()
      const semester = semesterIn + 1; // TODO modificar esta variable
      if (added) delete added.element.correlatives
      if( moved ){
        let subjects = [...this.subjects];

        
        // let subjects = this.subjects;
        const list = subjects.filter(s => s.semester === semester)
                    .filter(s => s.belongsTo === bimester)
        
        subjects = subjects.filter(s => !list.find(subject => subject.id === s.id));
        
        list
        .splice(moved.newIndex, 0, list.splice(moved.oldIndex, 1)[0]);
        
        list.forEach((subject, index) => subject.order = index);

        this.subjects = [...list, ...subjects];
        
      }

      if(added){
        let {element} = added;
        const {newIndex} = added;

        //ASIGN SUBJECT TO SEMESTRAL OR BIMESTER A/B
        if(!element.belongsTo)
          element = asignSubjectsToBimester({subject: element, termType: {value: BIMESTER_A_TYPE}}); 

        element.semester = semester; 
    
        if(element.belongsTo !== BELONGS_TO_SEMESTRAL && bimester === BELONGS_TO_SEMESTRAL)
          bimester = BELONGS_TO_BIMESTER_A;
        else if(element.belongsTo === BELONGS_TO_SEMESTRAL && bimester !== BELONGS_TO_SEMESTRAL)
          bimester = BELONGS_TO_SEMESTRAL;

        element.belongsTo = bimester;

        let subjects = [...this.subjects];

        const list = subjects.filter(s => s.semester === semester)
                    .filter(s => s.belongsTo === bimester)
        
        subjects = subjects.filter(s => !list.find(subject => subject.id === s.id));
        
        const oldIndex = list.findIndex(s => s.id === element.id); //GET THE SUBJECT INDEX

        if(oldIndex === -1)
          list.splice(newIndex, 0, element);// ADD NEW ITEMS TO STUDY PLAN
        else  
          list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]); //MOVE ITEM INSIDE STUDY PLAN
        
        list.forEach((subject, index) => subject.order = index); //FIX ORDER
        
        this.subjects = [...list, ...subjects];
      }
    },
    async fetchData(){
      try {
        
        const res =  await $types.find(null, null, {
            params: { type: 'MODALITY_TYPE' }
          });
        this.availableModalityTypes = res.data;

        const degrees = await $degrees.find(null, null, { params: { page: 0, length: 500 } })
        this.availableDegrees = degrees.data.content

      } catch {
        this.availableDegrees = []
      }
    },
    
    
    //HELPERS
    toOrdinal (number){
      const first = this.$t('modules.careers.ordinal');
      const second = this.$t('modules.careers.ordinal_2');

      const units = (number+1) % 10;
      let decimals = (number+1) / 10;
      decimals = Math.floor(decimals);
      
      if(decimals === 0)
          return first[units-1].toPascalCase()

      if(units === 0 && decimals != 0)
          return second[decimals-1].toPascalCase()
  
      return `${second[decimals-1].toPascalCase()  } ${  first[units-1]}`
    },
    setAmount(e){
      const amount = parseInt(e)
      if(!amount || amount < 1) return;

      this.changedData()
      this.semesterAmount = amount;
      this.removeSubjects(amount);
    },
    removeSubjects(semesterAmount){
      const notRemovedSubjects = this.subjects.filter(s => s.semester <= semesterAmount);
      const removedSubjects = this.subjects.filter(s => s.semester > semesterAmount);
      
      this.subjects = [...notRemovedSubjects];

      this.asignatures = [...this.asignatures, ...removedSubjects];
    },
    parseCorrelativesToBackend (correlatives) {
      if(correlatives){
        return correlatives.filter(corr => corr.correlativeWay === 0).map(corr => ({
          preRequisite: {id: corr.subject.id},
          passingGrade : true,
          regular : true,
          inProcess : false
        }))
      }else{
        return []
      }
      
    },
    parseSubjectsForSave () {
      let firstBimester = this.subjects.filter(s => s.belongsTo === BELONGS_TO_BIMESTER_A || s.belongsTo === BELONGS_TO_SEMESTRAL);
      firstBimester = firstBimester.map((asignature, order)=>
          ({        
              studyPlanSubjectType: {value: asignature.opcional ? 58 : 59},
              status: true,
              semester:asignature.semester, 
              subject: asignature,
              order,
              preRequisites: this.parseCorrelativesToBackend(asignature.correlatives),
              termType: {value: asignature.belongsTo === BELONGS_TO_BIMESTER_A ? BIMESTER_A_TYPE : BIMESTER_C_TYPE}
          })
      )
      let secondBimester = this.subjects.filter(s => s.belongsTo === BELONGS_TO_BIMESTER_B);
      secondBimester = secondBimester.map((asignature,order)=>
          ({
              studyPlanSubjectType: {
                value: asignature.opcional ? 58 : 59
              },
              status: true,
              semester:asignature.semester, 
              subject: asignature,
              order,
              preRequisites: this.parseCorrelativesToBackend(asignature.correlatives),
              termType: {value: BIMESTER_B_TYPE},
          })
      )
      return [...firstBimester, ...secondBimester];
    },
    openWarningPopUp(invalidSemesters){

      // TRANSLATE

      this.$store.dispatch('commons/openPopUp', {
        title: `Estos semestres no tienen materias`,
        content: invalidSemesters.map((item)=>({value: `${this.toOrdinal(item)} semestre`})),
        actionPrimary: {
          text: 'Cerrar',
          callback(){}
        },
        icon: { color: 'warning', name: 'mdi-eye-off-outline' },
        color: 'primary'
      });
    },
    async save(){
      if (!this.canUpdate) return false

      const subjects = this.parseSubjectsForSave();
      const studyPlan = {
        studyPlan: {
          id: this.studyPlan? this.studyPlan.id : null,
          description: this.planYear,
          resolution: this.resolution,
          subjects,
          duplicateCarousel: this.duplicateCarousel
        },
        modalityType: this.modalityType,
        lmsType: null,
        status: true,
        subjectCount: subjects.length,
        quarterCount: this.semesterAmount,
        degreesFinal : this.degree,
        degrees : [],
        user: this.userId ? this.userId : null
      }

      if (this.isUpdate) {
        try {
          await $studyPlans.update(this.studyPlan ? this.studyPlan.id : null, studyPlan);
          this.$emit('close');
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.careers.study_plan.successUpdate'),
            actionPrimary: {
              text: this.$t('actions.close'),
              callback(){}
            },
            icon: { color: 'success', name: 'mdi-check' },
            color: 'primary'
          });
        }
        catch (err){
          this.$emit('close');
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.careers.study_plan.errorUpdate'),
            actionPrimary: {
              text: this.$t('actions.close'),
              callback(){
              }
            },
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary'
          });
        }

      } else {  
        this.$emit('update:studyPlan', studyPlan)
      }

      this.resetToInitialState();
      this.fetchData();
      this.asignatures = undefined;
    },
    resetSubjectsArray(){
      this.subjects = [];
    },
    resetToInitialState(){
      PENDING_CORRELATIVITIES.splice(0,PENDING_CORRELATIVITIES.length)
      this.planYear =  '';
      this.resolution =  '';
      this.degree =  null;
      this.modalityType =  null;
      this.subjects = [];
      
      // if(!this.$refs.numberinput) return; 
      // this.$refs.numberinput.reset();
      // this.$refs.numberinput.$emit('input', '1')
      this.semesterAmount = 1;

      this.$v.form.$reset();
    },
    validateSemesters(){
      const invalidSemesters = [];

      for(let semester = 0; semester < this.semesterAmount; ++semester){
        const subjects = this.subjects.filter(s => s.semester === semester)
        if(subjects.length === 0)
          invalidSemesters.push(semester);
      }

      return invalidSemesters;
    }
  },
  computed:{
    ...mapGetters({
      userId: 'commons/getUserID'
    }),

    selectedItems(){
      // we need to check wich subjects are being selected
      return this.subjects.map((subject) => subject.id)
    },

    canUpdate() {
      return this.$permissions.portaladministrativo.academic.careers.UPDATE
    },

    plan(){
      let semesters = []
      for(let i = 0; i < this.semesterAmount; ++i)
        semesters[i] = this.subjects.filter(subject => subject.semester === i+1)
      
      semesters = semesters.map(subjects => ({
        firstBimester: subjects.filter(subject => subject.belongsTo === BELONGS_TO_BIMESTER_A).sort((a,b)=> a.order - b.order),
        secondBimester: subjects.filter(subject => subject.belongsTo === BELONGS_TO_BIMESTER_B).sort((a,b)=> a.order - b.order),
        semesterAsignatures: subjects.filter(subject => subject.belongsTo === BELONGS_TO_SEMESTRAL).sort((a,b)=> a.order - b.order)
      }))

      const yearFormat = []

      for(let i = 0; i < semesters.length; i = i + 2){
        if(semesters[i+1])
          yearFormat.push([semesters[i], semesters[i+1]])
        else
          yearFormat.push([semesters[i]])      
      }
      return yearFormat;
    },
    //ERRORS
    
    degreeErrors(){
      const errors = [];
      if (!this.$v.degree.$anyError) return errors;

      if (!this.$v.degree.required)
        errors.push(this.$t('modules.careers.study_plan.validations.degreeRequired'));

      return errors;
    },
    planYearErrors(){
      const errors = [];
      if (!this.$v.planYear.$anyError) return errors;

      if (!this.$v.planYear.required)
        errors.push(this.$t('modules.careers.study_plan.validations.planYearRequired'));

      return errors;

    },
    modalityTypeErrors(){
      const errors = [];
      if (!this.$v.modalityType.$anyError) return errors;

      if (!this.$v.modalityType.required)
        errors.push(this.$t('modules.careers.study_plan.validations.modalityTypeRequired'));
      return errors;
    }

    //END OF ERRORS
  },
  validations:{
    degree: {required},
    planYear: {required},
    modalityType: {required},
    form: ['degree', 'planYear', 'modalityType']
  }
}
</script>
