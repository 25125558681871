
const examTitleNotesTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Exámen de título',
        description: 'Gestión de notas de exámen de título'
      },
      headers: {
        name: 'Nombre',
        lastname: 'Apellido',
        career: 'Carrera',
        date: 'Fecha de exámen',
        identification: 'Rut',
        career_id: 'Código',
        grade: 'Nota',
        act: 'Acta',
      },
      asidebar: {
        notas: 'Notas',
        alumnos_sin_nota: 'Sin Nota',
        alumnos_con_nota: 'Con nota',
        desde: 'Desde',
        hasta: 'Hasta',
      }
    },
    create:{
      breadcrumbs: {
        title: 'Carga de nota',
        description: 'Notas de exámen de título',
      },
      labels: {
        name: 'Nombre',
        detail: 'Detalle',
        calificacion: 'Calificación',
        fecha: 'Fecha exámen',
        act: 'Acta',
      },
      validation_errors: {
        calificacion: 'Debe ingresar la calificación',
        fecha: 'Debe ingresar la fecha',
      },
      error_message : {
        title: 'Error al cargar la nota'
      },
      success_message : {
        title: 'Nota cargada correctamente'
      }
    },
    update:{
      breadcrumbs: {
        title: 'Actualización de nota',
        description: 'Notas de exámen de título',
      },
      labels: {
        name: 'Nombre',
        detail: 'Detalle',
        calificacion: 'Calificación',
        justification: 'Justificación',
        fecha: 'Fecha exámen',
        act: 'Acta',
      },
      validation_errors: {
        calificacion: 'Debe ingresar la calificación',
        fecha: 'Debe ingresar la fecha',
        justification: 'Ingresa el motivo de la actualización'
      },
      error_message : {
        title: 'Error al actualizar la nota'
      },
      success_message : {
        title: 'Nota actualizada correctamente',
      }
    }
  },
  en: {
  
  }
};
  
export default examTitleNotesTranslations
  