
const departmentsTranslations = {
    es_PE: {
        table: {
            headers: {
                subjects: 'Cursos',
            }
        },
        create: {
            section_subjects: {
                title: 'Cursos',
                subtitle: 'Selecciona los cursos que quieres asociar al departamento.',
            },
            validation_errors: {
                subjects: 'Debes seleccionar al menos un curso',
            }
        },
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Departamentos',
                description: 'Administración de departamentos'
            },
            delete: 'Eliminar',
            accept: 'Aceptar',
            cancel: 'Cancelar',
            bulk_delete: 'Eliminar departamentos',
            bulk_delete_error: 'No se pudieron eliminar los departamentos seleccionados',
            delete_error: 'No se pudo eliminar el deparatamento seleccionado',
            headers: {
                code: 'Código',
                name: 'Nombre',
                subjects: 'Asignaturas',
            }
        },
        create: {
            breadcrumbs: {
                title: 'Nuevo departamento',
                description: 'Crea un nuevo departamento'
            },
            title: 'Datos',
            subtitle: 'Define un nombre e ingresa un código para el nuevo departamento.',
            section_subjects: {
                title: 'Asignaturas',
                subtitle: 'Selecciona las asignaturas que quieres asociar al departamento.',
            },
            labels: {
                name: 'Nombre',
                code: 'Código',
                description: 'Descripción',
                available: 'Disponibles',
                selected: 'Seleccionadas',
            },
            validation_errors: {
                name: 'Ingresa el nombre del departamento',
                name_length: 'El nombre debe tener un largo máximo de 75 caracteres',
                code: 'Ingresa el código del departamento',
                code_unique: 'Código ya existente',
                description: 'Ingresa la descripción para el departamento',
                subjects: 'Debes seleccionar al menos una asignatura',
            },
            success_message: {
                title: 'Departamento creado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear el departamento',
            },
        },
        update: {
            breadcrumbs: {
                title: 'Editar departamento',
                description: 'Modifica el departamento seleccionado'
            },
            success_message: {
                title: 'Departamento modificado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar el departamento',
            },
        },
    },
    en: {

    }
};

export default departmentsTranslations