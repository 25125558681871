export default {

  // common state

  userData: null,
  loggedUserData: {},
  isGestor: false,
  alertPopUp: null,
  changePasswordPopUp: false,
  hasExpired: false,
  configurations: {},
  loading: { counter: 0, drawer: false },
  permissions: null,
  subjects: { inProgress: null, regularised: null, approved: null },
  mpConfig: null,
  notificationsKey: 0,
  
}