import api from '@/api'

const { $pricing, $core, $student, $academic, $hubspot, $payment, $email } = api;

export const { $studentProcessing, $preRegister, $type, $studentAccount, $studentToken} = $student;
export const { $contact } = $hubspot
export const { $careers, $ceos, $terms, $studyPlans } = $academic
export const { $prices, $products, $types, $zones, $scholarships, $billingProps, $benefits, $discounts, $prepaids, $suscriptions, $referredCompanies, $communityBenefitBilling } = $pricing;
export const { $payments } = $payment
export const { $users, $cities, $citiesWithState, $countries, $nationalities, $citiesByState, $institutions } = $core;
export const { $sendEmailHtml, $pagoAutogestionado } = $email
