<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0" v-if="!createNewBilling">
            <v-container fluid class="container-custom px-1">
                <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
                </Breadcrumbs>
            </v-container>
            <v-container  class="container-custom px-16">
                <v-row>
                    <OutlinedCard
                        :title="$t('modules.pricing.billing.billingUpdate.outlineCardStudent.title')"
                        :subtitle="$t('modules.pricing.billing.billingUpdate.outlineCardStudent.subtitle')"
                    >
                    <StudentCard
                    @get-info="getInfo"
                    :infoStudentBilling="infoStudentBilling"
                    :dni="$route.params.identification"
                    />
                    </OutlinedCard>
                </v-row>
                <v-row>
                    <OutlinedCard 
                        :title="$t('modules.pricing.billing.billingUpdate.outlineCardFinance.title')"
                        :subtitle="$t('modules.pricing.billing.billingUpdate.outlineCardFinance.subtitle')"
                    >
                   <v-col cols="12" class="px-0">
                        <v-select 
                                :label="$t('modules.pricing.billing.billingUpdate.outlineCardFinance.label')"
                                :items="listTypesReceipts"
                                 outlined
                                return-object
                                v-model="typeReceipt"
                                class="no-message"
                        ></v-select>
                    </v-col>
                     <PaymentBillingDataTeclab
                        v-if="$isTeclab"
                        ref="billingData"
                        :title="false"
                        @update="(data) => { billingData = data }"
                        @validate="(val) => { billingDataValid = val }"
                        :selectedStudent="selectedstudentId"
                     ></PaymentBillingDataTeclab>
                      <PaymentBillingDataVue
                            v-else 
                            ref="billingData"
                            :title="false"
                            @update="billingData = $event"
                            @validate="billingDataValid = $event"
                      />
                      <div class="d-flex justify-end mb-4">
                        <Button
                            :loading="updateButton.loading"
                            :success="updateButton.success"
                            :error="updateButton.error"
                            :text="'Guardar'"
                            :successText="$t('actions.saved')"
                            :errorText="'Error'"
                            @clicked="editNoteCredit"
                        ></Button>
                      </div>
                      <v-textarea
                        v-model="$v.observation.$model"
                         :error-messages="($v.observation.$dirty && $v.observation.$invalid) ?
                              ((!$v.observation.required) ? $t('modules.pricing.billing.billingUpdate.validations.observations') : '') : null"
                        @blur="$v.observation.$touch()"
                        outlined
                        no-resize
                        :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.observation')"
                        ref="observation"
                      >
                      </v-textarea>
                      <TransferListVue
                        :avatar="false"
                        :loading="loadingReceiptsList"
                        :availableItems.sync="filteredReceipts"
                        :added-items.sync="receiptAbalaible"
                        :text-value="['receiptType','receiptNumber']"
                        areEquals="receiptId"
                        @searchItemLeftOnBackend="searchReceipts"
                        @searchItemRightOnBackend="searchReceiptsAdded"
                      />
                    </OutlinedCard>
                </v-row>
                <v-row >
                    <OutlinedCard
                        :title="$t('modules.pricing.billing.billingUpdate.outlineCardDetail.title')"
                        :subtitle="$t('modules.pricing.billing.billingUpdate.outlineCardDetail.subtitle')"
                    >              
                        <DetailCard
                         ref="details"
                        :details="$v.details"
                        :billingProducts="billingProducts"
                        @removeGroup="(index) => removeGroup(details, index)"
                        @addGroup="(mockup) => addGroup(details, mockup)"
                        />
                        <div class="total-wrapper mr-14">
                            <v-alert outlined color="#6087e1">
                            <div class="total primary--text">Total</div>
                            <div class="total-currency primary--text">{{ detailsTotalPrices | $currency }}</div>
                            </v-alert>
                        </div>
                    </OutlinedCard>
                </v-row>
                 <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                   @clicked="$router.replace('/pricing/billing')"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="'Generar'"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  @clicked="validetaNoteCredit"
                ></Button>
              </div>
            </v-container>
        </v-container>
        <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import StudentCard from '../Components/StudentCard.vue';
import DetailCard from '../Components/DetailCard.vue';
import { $billingData,
    $prices,
    $generatenc,
    $student
}
 from '../Services/index'
import PaymentBillingDataVue from '../../../../portalalumno/Payments/PaymentsStudents/Components/PaymentBillingData/PaymentBillingData.vue';
import PaymentBillingDataTeclab from '../../../../portalalumno/Payments/PaymentsStudents/Components/PaymentBillingData/PaymentBillingDataTeclab.vue';
import TransferListVue from '@/components/TransferList/TransferList.vue';
import SuccessMessage from '../../../../../core/components/SuccessMessage/SuccessMessage.vue';
import {required} from 'vuelidate/lib/validators';

    export default {
        name:'BillingUpdate',
        components: {
            Breadcrumbs,
            OutlinedCard,
            Button,
            StudentCard,
            PaymentBillingDataVue,
            PaymentBillingDataTeclab,
            DetailCard,
            SuccessMessage,
            TransferListVue,
        },
        data() {
            return {
                breadcrumbs: {
                    title: this.$t('modules.pricing.billing.billingUpdate.breadcrumbs.title'),
                    description: this.$t('modules.pricing.billing.billingUpdate.breadcrumbs.description')
                },
                 createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                 updateButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                createNewBilling:false,
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null
                },
                infoStudentBilling: null,
                billingDataValid: false,
                billingData: null,
                selectedstudentId : null,
                loadingReceipt: false,
                receipts: [],
                selectedReceipts: [],
                observation:null,
                details:[{
                    description:null,
                    price: null
                }],
                loadingReceiptsList: false,
                filteredReceipts: [],
                receiptAbalaible: [],
                types:[],
                billingProducts: [],
                listTypesReceipts: ['Nota de Credito'],
                typeReceipt:'Nota de Credito'
            }
        },
          computed:{
            detailsTotalPrices(){
                return   this.details.reduce(function(prev, curr) {
                    return prev + (curr.price == null ? 0 : parseFloat(curr.price)) 
                    }, 0);
            },
        },
        methods: {
            async editNoteCredit(){
                if (!this.billingDataValid){
                    return false
                }{
                this.updateButton.loading = true
                try {
                        const body = {
                            identificationType : {
                                    value : 17
                                },
                                docNumber: this.billingData.id,
                                name : this.billingData.name,
                                address : this.billingData.street,
                                addressNumber : this.billingData.number.toString(),
                                addressFloor : this.billingData.floor.toString(),
                                addressDepartment : this.billingData.apartment.toString(),
                                zipCode : null,
                                city : this.billingData.city_id,
                                cityFacturante: this.billingData.city,
                                stateFacturante : this.billingData.stateFacturante,
                                billingMail : this.billingData.billingMail,
                                collectsIIBB : true,
                                collectsVAT : true,
                                phone : this.billingData.phone,
                                contactName : this.billingData.name,
                                taxTreatment : {
                                    value : this.billingData.taxSituation
                                },
                                savedToSAP : 1,
                                updateSAP : 1,
                                taxSituationType : this.billingData.taxSituationType ? {
                                    value : this.billingData.taxSituationType
                                } : null,
                                receiptType : this.billingData.receiptType ? {
                                    value : this.billingData.receiptType
                                } : null
                            }
                        await $billingData.update(`${this.billingData.user_id}`, body)
                        this.updateButton.success = true;
                        this.updateButton.loading = false;
                     return true
                } catch {
                    this.updateButton.loading = false;
                    this.updateButton.error = true
                    return false
                }
                }
            },
            async getInfo(value){
                    try {
                        const resp = await $billingData.find(null,  null , {params: { identification : value }});
                        this.selectedstudentId = resp.data.student.id ? resp.data.student.id : null;
                        this.billingData = true
                        this.getInfoStudentData()
                    } catch (error) {
                        this.selectedstudentId = null
                    }
            },
            async getInfoStudentData(){
                try {
                    const resp = await $student.find('info', null ,{params: { id : this.selectedstudentId }});
                     this.infoStudentBilling = {
                            name: resp.data.name,
                            lastname: resp.data.lastname,
                            dni: resp.data.identification,
                            city: resp.data.city,
                            typeDni: resp.data.identificationType,
                            street: resp.data.street,
                            streetNumber: resp.data.number,
                            streetFloor: resp.data.floor,
                            addressDepartment: resp.data.apartment,
                        }
                } catch (error){
                    this.infoStudentBilling = []
                     throw error;
                }
            },
            searchReceipts (event) {
                this.getReceipts(event);
            },
            searchReceiptsAdded (event) {
                this.selectedReceipts = event
            },
                async getReceipts (){
                    this.loadingReceiptsList= true
                    // this.filteredReceipts = this.$route.params.listReceipts
                    this.receiptAbalaible = this.$route.params.listReceipts
                    this.loadingReceiptsList= false
            },
             validetaNoteCredit() {
                    if (this.$v.$invalid) {
                        this.$v.$touch();
                        
                        Object.keys(this.$v).some(input => {
                            if (input.includes('$')) return false;
                            if (this.$v[input].$error) {
                            Object.keys(this.$refs).some(ref => {
                                if (ref.includes(input)) {
                                this.$refs[ref][0]
                                    ? this.$refs[ref][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                                    : this.$refs[ref].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                                }
                            })
                            return true;
                            }
                        });
                        
                    } else {
                        this.createNoteCredit()
                    }
                },
            async createNoteCredit(){
                this.createButton.loading = true

                const listReceiptId = this.receiptAbalaible.map(item => item.receiptId)
                const data = {
                    observations : this.observation,
                    refund : false,
                    student : this.selectedstudentId,
                    receiptsIds : listReceiptId,
                    details : this.details
                }
                // console.log(data)

                try {
                    await $generatenc.create(data)
                    this.successMessage.type = 'success';
                    this.successMessage.title = 'Comprobante generado correctamente'
                    this.successMessage.actionPrimary = {
                    text: this.$t('actions.close'), callback: () => {
                        this.$router.push('/pricing/billing');
                    }
                    };
                    this.successMessage.actionSecondary = {
                    text: this.$t('actions.create_another'), callback: () => {
                        this.createNewBilling = false;
                    }
                    };

                    this.createNewBilling = true;
                    this.createButton.success = true;
                    this.createButton.loading = false;
                } catch (error) {
                     this.successMessage.type = 'error';
                    this.successMessage.title = 'Ocurrio un error al crear el comprobante';
                    this.successMessage.actionPrimary = {
                    text: this.$t('actions.return'), callback: () => {
                        this.createNewBilling = false;
                    }
                    };
                    this.successMessage.actionSecondary = null;
                    this.createNewBilling = true;
                    this.createButton.loading = false;
                    this.createButton.error = true;
                    throw error;
                }
            },
            addGroup (group, mockup) {
                group.push({
                    ...mockup
                });
            },
             removeGroup (group, index) {
                group.splice(index, 1)
            },
            async getTypes(){
               try {
                   const resp = await  $prices.find(null, null, null)
                   this.billingProducts = resp.data.content
               } catch {
                   this.types = []
               }
            }
        },
        validations: {
            observation: {required},
            details: {
                $each:
                    {
                    description: {required},
                    price: {required}
                    }
            }
        },
        mounted() {
            this.getReceipts()
            this.getInfo(this.$route.params.identification)
            this.getTypes()
        },
    }
</script>

<style lang="sass" scoped>
.total-wrapper
  border-radius: 5px
  background-color: #d9e2fa
  font-family: 'DM Sans'
  font-weight: bold
  font-stretch: normal
  font-style: normal
  line-height: 1.3
  letter-spacing: -0.6px

  .total
    float: left
    font-size: 20px

  .total-currency
    float: right
    font-size: 24px
</style>