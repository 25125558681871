var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.show)?_c('OutlinedCard',{staticClass:"pb-4 mb-6",attrs:{"title":_vm.$t('modules.admission.preregistration.create.priceSimulator.title'),"disabled":""}}):_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.admission.preregistration.create.priceSimulator.title')}},[_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('modules.admission.preregistration.create.priceSimulator.subtitles.priceList'))+" ")]),(!_vm.$isTeclab)?_c('v-switch',{staticClass:"my-auto py-0",attrs:{"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.subtitles.goodAverage')},on:{"change":function($event){return _vm.$emit('averageChange', _vm.average)}},model:{value:(_vm.average),callback:function ($$v) {_vm.average=$$v},expression:"average"}}):_vm._e()],1)],1),_c('v-row',_vm._l((_vm.prices),function(price){return _c('v-col',{key:`priceSimulator-${price.key}`,staticClass:"col-6"},[_c('SpecialCheck',{attrs:{"item":price},on:{"updateItem":_vm.updateSelectedPrices}})],1)}),1),(_vm.discounts.length)?[_c('v-row',{staticClass:"py-2"},[_c('v-col',{staticClass:"col-12"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('modules.admission.preregistration.create.priceSimulator.subtitles.discounts'))+" ")])]),_c('v-col',{staticClass:"col-12"},[_c('v-alert',{staticClass:"mb-0",attrs:{"text":"","color":"success"}},[_vm._v(" "+_vm._s(_vm.$t('modules.admission.preregistration.create.priceSimulator.alerts.discount', {date: _vm.discounts[0].to}))+" ")])],1)],1),_c('v-row',[_vm._l((_vm.discounts),function(discount){return _vm._l((discount.details),function(details){return _c('v-col',{key:`priceSimulator-${discount.code}${details.billingProduct.id}`,staticClass:"col-6"},[_c('SpecialCheck',{attrs:{"item":{
                label: `${details.billingProduct.name} ${details.percentage ? `<span class='success--text'>${details.percentage}% Off</span>` : ''}`,
                amount: _vm.getSavingAmount('DISCOUNT', details.billingProduct.id),
                selected: true
              },"color":"#BEE4E133","text-color":"success","has-checkbox":false}})],1)})})],2)]:_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('v-row',[_c('v-col',{staticClass:"col-12"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('modules.admission.preregistration.create.priceSimulator.subtitles.benefits'))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('v-autocomplete',{attrs:{"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.labels.communityCompany'),"no-data-text":_vm.$t('modules.admission.preregistration.validations.selectsEmpty.communityCompany'),"outlined":"","clearable":"","items":_vm.communityCompanies,"item-text":"description","return-object":""},on:{"change":function($event){return _vm.setBenefit('Comunidad')}},model:{value:(_vm.selectedCommunityCompany),callback:function ($$v) {_vm.selectedCommunityCompany=$$v},expression:"selectedCommunityCompany"}})],1),_c('v-col',{staticClass:"col-6"},[_c('SpecialCheck',{attrs:{"disabled":!_vm.selectedCommunityCompanyBenefit,"has-checkbox":false,"item":{
            label: _vm.selectedCommunityCompanyBenefit ? `${_vm.selectedCommunityCompanyBenefit.details[0].billingProduct.name} ${_vm.selectedCommunityCompanyBenefit.details[0].percentage ? `<span class='success--text'>${_vm.selectedCommunityCompanyBenefit.details[0].percentage}% Off</span>` : ''}`
            : '0% Off',
            amount: _vm.selectedCommunityCompanyBenefit ? _vm.getSavingAmount('BENEFIT', _vm.selectedCommunityCompanyBenefit.details[0].billingProduct.id) : 0,
            selected: !!_vm.selectedCommunityCompanyBenefit
          },"color":_vm.selectedCommunityCompanyBenefit ? '#BEE4E133' : '',"text-color":_vm.selectedCommunityCompanyBenefit ? 'success' : ''}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('SpecialCheck',{attrs:{"disabled":!_vm.referredFriend,"item":{
            label: _vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.labels.referredFriend'),
            amount: _vm.selectedReferredFriend ? _vm.referredFriend.benefitReferredAmount : 0,
            selected: _vm.selectedReferredFriend
          },"color":_vm.selectedReferredFriend ? '#BEE4E133' : '',"text-color":_vm.selectedReferredFriend ? 'success' : ''},on:{"updateItem":function($event){return _vm.setBenefit(_vm.selectedReferredFriend ? null : 'Amigo referido')}}})],1)],1),(_vm.$isTeclab)?_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('v-autocomplete',{attrs:{"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.labels.referredCompany'),"no-data-text":_vm.$t('modules.admission.preregistration.validations.selectsEmpty.referredCompany'),"outlined":"","clearable":"","items":_vm.referredCompanies,"item-text":"name","item-value":"id","return-object":"","menu-props":{ closeOnContentClick: true }},on:{"change":function($event){return _vm.setBenefit('Empresa referida')}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-btn',{staticClass:"justify-space-around",attrs:{"text":"","block":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s('Agregar empresa referida')+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-circle-outline")])],1)]},proxy:true}],null,false,995754812),model:{value:(_vm.selectedReferredCompany),callback:function ($$v) {_vm.selectedReferredCompany=$$v},expression:"selectedReferredCompany"}})],1),_c('v-col',{staticClass:"col-6"},[_c('SpecialCheck',{attrs:{"disabled":_vm.selectedBenefit != 'Empresa referida',"has-checkbox":false,"item":{
            label: _vm.selectedBenefit == 'Empresa referida' ? `${_vm.selectedReferredCompanyBenefit.details[0].billingProduct.name} ${_vm.selectedReferredCompanyBenefit.details[0].percentage ? `<span class='success--text'>${_vm.selectedReferredCompanyBenefit.details[0].percentage}% Off</span>` : ''}`
            : '0% Off',
            amount: _vm.selectedBenefit == 'Empresa referida' ? _vm.getSavingAmount('BENEFIT', _vm.selectedReferredCompanyBenefit.details[0].billingProduct.id) : 0,
            selected: _vm.selectedBenefit == 'Empresa referida'
          },"color":_vm.selectedBenefit == 'Empresa referida' ? '#BEE4E133' : '',"text-color":_vm.selectedBenefit == 'Empresa referida' ? 'success' : ''}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('OutlinedCard',{staticClass:"new-referred-company-card",attrs:{"title":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.title')}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.newReferredCompany.name.$dirty && _vm.$v.newReferredCompany.name.$invalid) ?
                  ((!_vm.$v.newReferredCompany.name.required) ? _vm.$t('modules.admission.preregistration.validations.businessNameRequired') :
                  (!_vm.$v.newReferredCompany.name.alphabeticWithSpaces) ? _vm.$t('modules.admission.preregistration.validations.businessNameAlphabeticWithSpaces') :
                  _vm.$t('modules.admission.preregistration.validations.businessNameMaxLength')) :
                  '',"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.name'),"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.name.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.newReferredCompany.name.$model,60, true, true)}},model:{value:(_vm.$v.newReferredCompany.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.name, "$model", $$v)},expression:"$v.newReferredCompany.name.$model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##-########-#'),expression:"'##-########-#'"}],attrs:{"error-messages":(_vm.$v.newReferredCompany.cuit.$dirty && _vm.$v.newReferredCompany.cuit.$invalid) ?
                  ((!_vm.$v.newReferredCompany.cuit.required) ? _vm.$t('modules.admission.preregistration.validations.cuitRequired') :
                  (!_vm.$v.newReferredCompany.cuit.cuitFormat) ? _vm.$t('modules.admission.preregistration.validations.cuitFormat') :
                  _vm.$t('modules.admission.preregistration.validations.cuitUnique')) :
                  '',"loading":_vm.loadingCuit,"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.cuit'),"outlined":""},on:{"blur":_vm.validateCuit},model:{value:(_vm.$v.newReferredCompany.cuit.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.cuit, "$model", $$v)},expression:"$v.newReferredCompany.cuit.$model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.newReferredCompany.contactName.$dirty && _vm.$v.newReferredCompany.contactName.$invalid) ?
                  ((!_vm.$v.newReferredCompany.contactName.required) ? _vm.$t('modules.admission.preregistration.validations.contactNameRequired') :
                  (!_vm.$v.newReferredCompany.contactName.alphabeticWithSpaces) ? _vm.$t('modules.admission.preregistration.validations.contactNameAlphabeticWithSpaces') :
                  _vm.$t('modules.admission.preregistration.validations.contactNameMaxLength')) :
                  '',"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.contactName'),"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.contactName.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.newReferredCompany.contactName.$model,60, true, true)}},model:{value:(_vm.$v.newReferredCompany.contactName.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.contactName, "$model", $$v)},expression:"$v.newReferredCompany.contactName.$model"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.newReferredCompany.position.$dirty && _vm.$v.newReferredCompany.position.$invalid) ?
                  ((!_vm.$v.newReferredCompany.position.required) ? _vm.$t('modules.admission.preregistration.validations.positionRequired') :
                  (!_vm.$v.newReferredCompany.position.alphabeticWithSpaces) ? _vm.$t('modules.admission.preregistration.validations.positionAlphabeticWithSpaces') :
                  _vm.$t('modules.admission.preregistration.validations.positionMaxLength')) :
                  '',"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.position'),"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.position.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.newReferredCompany.position.$model,60, true, true)}},model:{value:(_vm.$v.newReferredCompany.position.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.position, "$model", $$v)},expression:"$v.newReferredCompany.position.$model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{ref:"email",attrs:{"error-messages":(_vm.$v.newReferredCompany.email.$dirty && _vm.$v.newReferredCompany.email.$invalid) ?
                  ((!_vm.$v.newReferredCompany.email.required) ? _vm.$t('modules.admission.preregistration.validations.emailRequired') :
                  _vm.$t('modules.admission.preregistration.validations.emailFormat')) :
                  '',"label":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.email'),"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.email.$touch()}},model:{value:(_vm.$v.newReferredCompany.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.email, "$model", $$v)},expression:"$v.newReferredCompany.email.$model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TelephoneInput',{ref:"telephone",attrs:{"value":_vm.$v.newReferredCompany.cellphone.$model,"required":_vm.$v.newReferredCompany.cellphone.$dirty && _vm.$v.newReferredCompany.cellphone.$invalid,"placeholder":_vm.$t('modules.admission.preregistration.create.priceSimulator.benefits.newReferredCompany.labels.cellphone')},on:{"update:value":function($event){return _vm.$set(_vm.$v.newReferredCompany.cellphone, "$model", $event)},"blur":function($event){return _vm.$v.newReferredCompany.cellphone.$touch()},"validate":_vm.isValidPhone}})],1)],1),_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":_vm.clearReferredCompanyData}}),_c('Button',{attrs:{"loading":_vm.createReferredCompanyButton.loading,"success":_vm.createReferredCompanyButton.success,"error":_vm.createReferredCompanyButton.error,"text":_vm.$t('actions.create'),"successText":_vm.$t('actions.created'),"errorText":_vm.$t('actions.error'),"disabled":!_vm.canCreate},on:{"end":_vm.resetButtonValues,"clicked":_vm.createReferredCompany}})],1)],1)],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }