import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '../store'
import { configHttpClient } from './plugins/http-client';
import { setVueInstance } from './helpers/vue-instance';
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import { i18n } from './plugins/i18n';
import AuthService from './plugins/auth.service'
import UserService from './plugins/user.service'
import SettingsService from './plugins/settings.service'
import VueTheMask from 'vue-the-mask'
import infiniteScroll from 'vue-infinite-scroll'
import VueScrollTo from 'vue-scrollto'
import mixin from './mixins/index'
import VueApexCharts from 'vue-apexcharts'
import VueGtag from 'vue-gtag';
import VCalendar from 'v-calendar';
import VueHtmlToPaper from 'vue-html-to-paper';
import Hotjar from 'vue-hotjar'

import Configuration from './constants/configuration';

import 'roboto-fontface/css/roboto/roboto-fontface.css'

require('typeface-dm-sans');

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(AuthService);
Vue.use(UserService);
Vue.use(SettingsService);
Vue.use(VueTheMask)
Vue.use(infiniteScroll)
Vue.use(VueScrollTo)
Vue.use(VueHtmlToPaper)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.mixin(mixin)
Vue.use(VueApexCharts)
Vue.use(VCalendar,{
  componentPrefix: 'vc',
})

String.prototype.toPascalCase = function () {
  return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

if (Configuration.value('gtm') && Configuration.value('gtm') != '') {
  Vue.use(VueGtag, {
    config: { id: Configuration.value('gtm') },
    appName: 'Portal',
    pageTrackerScreenviewEnabled: true
  }, router);
}

const hotjar = Configuration.value('hotjarId');
if (hotjar) {
  Vue.use(Hotjar, {
    id: hotjar,
    isProduction: true
  });
}

// This variable will hold the reference to
// document's click handler
let handleOutsideClick;
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler } = binding.value;
      // This variable indicates if the clicked element is excluded
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target)) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');

configHttpClient(app);
setVueInstance(app);
export default app;
