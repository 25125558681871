<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs :title="$t('modules.portalalumno.apps.breadcrumbs.title')" :description="$t('modules.portalalumno.apps.breadcrumbs.description')"></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <div class="my-6">
                            <!-- CANVAS_STUDENT_APP -->
                            <h4 class="section-title">{{ $t('modules.portalalumno.apps.label.canvas_studen_app') }}</h4>
                            <v-divider style="color:#128DCD;"/>
                            <div>
                                <h6 class="section-subtitle">{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile') }}</h6>
                                <ul class="section-content ml-2">
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_1') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_2') }}</li>
                                <ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_3') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_mobile_4') }}</li>
                                </ul>
                                </ul>
                            </div>
                            <v-row class="d-flex align-center justify-center my-6">
                                    <a @click="clickAction('Google Play')" class="apps-google mx-4" target="_blank" href='https://play.google.com/store/apps/details?id=com.instructure.candroid&hl=es&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                        <img alt='Disponible en Google Play' :src="require('@/assets/images/portalalumno/google_play.png')" />
                                    </a>
                                    <a @click="clickAction('App Store')" class="apps-apple mx-4" target="_blank" href="https://itunes.apple.com/ar/app/canvas-student/id480883488?mt=8">
                                        <img :src="require('@/assets/images/portalalumno/app_store_ios.svg')" />
                                    </a>
                            </v-row>
                            <!-- ZOOM -->
                            <br>
                            <h4 class="section-title">{{ $t('modules.portalalumno.apps.label.zoom') }}</h4>
                            <v-divider style="color:#128DCD;"/>
                            <div>
                                <h6 class="section-subtitle">{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga') }}</h6>
                                <ul class="section-content ml-2">
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_zoom_1') }} <a @click="clickAction('Zoom')" target="_blank" :href="appUrl.zoom.sitio" style="text-decoration: underline">{{ $t('modules.portalalumno.apps.zoom.zoom_descarga') }}</a></li>
                                <ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_2') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_3') }}</li>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_4') }}</li>
                                </ul>
                                    <li>{{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_5') }}</li>
                                </ul>
                                <div class="d-flex align-center">
                                    <v-col cols="12" class="d-flex align-center my-6 justify-center">
                                        <a @click="clickAction('Zoom')"  target="_blank" href='https://zoom.us/download'>
                                        <img alt='Disponible en Google Play' :src="require('@/assets/images/portalalumno/descarga_zoom.png')" />
                                        </a>
                                    </v-col>
                                </div> 
                                {{ $t('modules.portalalumno.apps.label.instrucciones_app_descarga_6') }}
                            </div>
                            <br>
                            <!-- EXAMENES -->
                            <br>
                            <h4 class="section-title">{{ $t('modules.portalalumno.apps.klarway.exams') }}</h4>
                            <v-divider style="color:#128DCD;"/>
                            <v-row>
                                <v-col cols="12" class="section-content">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_que_es') }}
                                        <div>{{ $t('modules.portalalumno.apps.klarway.klarway_que_es_1') }}</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="section-content">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona') }}
                                        <div>{{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona_1') }}<i>{{ $t('modules.portalalumno.apps.klarway.klarway_como_funciona_2') }}</i></div>
                                </v-col>
                            </v-row>
                            <v-row class="my-3">
                                <v-col cols="12" class="mt-5 mb-1">
                                        <img  :src="require('@/assets/images/portalalumno/klarway.png')" height="75%"/>
                                </v-col>
                            </v-row>
                            <!-- KLARWAY -->
                        <v-row>
                            <v-col cols="12" class="section-content pt-0">
                                <div>
                                    <p>{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento') }}</p>
                                    <h3 class="section-subtitle">{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_2')}}</h3>
                                    <div type="1" class="section-content mb-6">
                                        {{ $t('modules.portalalumno.apps.klarway.klarway_instalacion') }}
                                    </div>
                            <!-- VIDEO -->
                                    <v-col :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'" align-self offset-md="1">
                                            <div class="d-flex justify-center align-center"><div class="video-container">
                                                <iframe class="responsive-iframe" allowfullscreen
                                                        :src="appUrl.klarway.video_empadronamiento"
                                                        frameborder="0"
                                                ></iframe>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-row class="d-flex align-center my-6 justify-center">
                                        <a @click="clickAction('Google Play')" class="apps-google mx-4" target="_blank" href="https://play.google.com/store/apps/details?id=com.bivproctor">
                                                <img alt='Disponible en Google Play' :src="require('@/assets/images/portalalumno/google_play.png')" />
                                        </a>
                                        <a @click="clickAction('App Store')" class="apps-apple mx-4" target="_blank" href="https://apps.apple.com/ar/app/klarway/id1528198239">
                                                <img :src="require('@/assets/images/portalalumno/app_store_ios.svg')"  />
                                        </a>
                                        <a @click="clickAction('App Huawei')" class="apps-huawei mx-4"  target="_blank" href="https://bivproctor.work/downloads/android/klarway">
                                                <img :src="require('@/assets/images/portalalumno/Huawei.png')" />
                                        </a>
                                    </v-row>
                            <!-- BOTON VER INSTRUCTIVO -->
                                    <div class="d-flex align-center justify-center my-6">
                                        <a class="instructivo primary" target="_blank" :href="appUrl.klarway.revisar_instructivo" > {{ $t('modules.portalalumno.apps.klarway.klarway_instalacion_4') }}</a>
                                    </div>
                                    <hr class="mt-14" :style="$vuetify.breakpoint.mdAndUp ? 'margin-right:15%;':''">
                                    <div>
                                        <h3 class="section-subtitle">{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_4')}}</h3>
                                                    <p>{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_5')}} <br> {{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_5b')}}</p>
                                                    <p>{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_6')}}</p>
                                    </div>
                                    <v-row  class="d-flex align-center justify-center my-6">
                                            <!-- <a @click="clickAction('Windows')" href="" target="_blank"> -->
                                                <a :href="appUrl.klarway.appWindows" class="mx-0" target="_blank">
                                                    <img  style="margin-bottom: 10px;" :src="require('@/assets/images/portalalumno/btn-macos.svg')"  width="90%" height="100%" />
                                                </a>
                                            <!-- <a @click="clickAction('Mac')" href="" target="_blank"> -->
                                                <a :href="appUrl.klarway.appIOSDesktop" class="mx-0" target="_blank">
                                                    <img  style="margin-bottom: 10px;" :src="require('@/assets/images/portalalumno/btn-windows.svg')"  width="90%" height="100%" />
                                                </a>
                                    </v-row>
                                </div>
                                <hr class="my-6" :style="$vuetify.breakpoint.mdAndUp ? 'margin-right:15%;':''">
                                <p>{{ $t('modules.portalalumno.apps.klarway.klarway_empadronamiento_7')}}</p>
                            <!-- BOTON VER INSTRUCTIVO -->
                                <div class="d-flex align-center justify-center my-6">
                                            <a class="instructivo primary" target="_blank" :href="appUrl.klarway.condiciones"> {{ $t('modules.portalalumno.apps.klarway.klarway_instalacion_4') }}</a>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- SUMADI -->
                        <v-row v-if="$country !== 'peru' || $country !== 'mexico'">
                            <v-col cols="12">
                                <img :src="require('@/assets/images/portalalumno/sumadi.png')" height="100%"/>
                            </v-col>
                            <v-col cols="12" class="section-content">
                                <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_que_es') }}</strong></p>
                                <p class="mb-0">{{ $t('modules.portalalumno.apps.sumadi.sumadi_que_es_1') }}</p>
                            </v-col>
                            <v-col cols="12" class="section-content">
                                <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_como_funciona') }}</strong></p>
                                <p class="mb-0">{{ $t('modules.portalalumno.apps.sumadi.sumadi_como_funciona_1') }}</p>
                            </v-col>
                            <v-col cols="12" class="section-content">
                                <p class="mb-0"><strong>{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion') }}</strong></p>
                                <ul>
                                    <li type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_1') }}</li>
                                    <li type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_2') }}</li>
                                    <li type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_3') }}</li>
                                    <li type="1">{{ $t('modules.portalalumno.apps.sumadi.sumadi_instalacion_4') }}</li>
                                </ul>
                            </v-col>

                            <v-row class="mb-2">
                                <v-col cols="8" sm="6" md="4" lg="3" xl="3">
                                    <a  @click="clickAction('Windows')" target="_blank" href="https://app-electron-latam.sumadi.net/download/app/us/global">
                                        <img style="margin-bottom: 10px;" :src="require('@/assets/images/portalalumno/btn-macos.svg')"  width="90%" height="100%" />
                                    </a>
                                </v-col>
                                <v-col cols="8" sm="6" md="4" lg="3" xl="3" >
                                    <a  @click="clickAction('Mac')" href="https://app-electron-latam.sumadi.net/download/app/us/global" target="_blank">
                                        <img style="margin-bottom: 10px;"  :src="require('@/assets/images/portalalumno/btn-windows.svg')" width="90%" height="100%" />
                                    </a>
                                </v-col>
                            </v-row>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    export default {
        name: 'AppsView',
        components: {
            Breadcrumbs,
        },
        methods: {
            clickAction(action) {
                this.$trackEvent('Apps', action, 'Descargas')
            },
        },
        computed:{
            appUrl(){
                if(this.$isTeclab){
                    return {
                        klarway :{
                            video_empadronamiento: 'https://informacion.teclab.edu.ar/hubfs/EX%C3%81MENES%20FINALES%20KLARWAY/Ex%C3%A1menes%20finales%20-%20Klarway_Teclab2022.mp4',
                            instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            revisar_instructivo: 'https://f.hubspotusercontent40.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/Instructivo%20Klarway-%20INSTALACI%C3%93N%20-Teclab.pdf',
                            condiciones: 'https://5880041.fs1.hubspotusercontent-na1.net/hubfs/5880041/EX%C3%81MENES%20FINALES%20KLARWAY/2022/Instructivo_%202022.pdf',
                            appWindows:'https://bivproctor.work/downloads/windows/teclab-release',
                            appIOSDesktop:'https://bivproctor.work/downloads/macos/teclab-release',
                        },
                        zoom :{
                            sitioDescarga: 'https://zoom.us/download',
                            sitio: 'https://zoom.us/'
                        },
                    }
                }else{
                    return {
                        klarway :{
                            video_empadronamiento: 'https://f.hubspotusercontent10.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/Examenes%20finales%20-%20Klarway_ipp_final.mp4',
                            instructivo: 'https://f.hubspotusercontent10.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/Instructivo%20Klarway-IPP%20-%20FINAL.pdf',
                            revisar_instructivo: 'https://f.hubspotusercontent10.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/Instructivo%20Klarway-IPP%20-%20FINAL.pdf',
                            condiciones: 'https://6058217.fs1.hubspotusercontent-na1.net/hubfs/6058217/EXAMENES%20FINALES%20KLARWAY/PDF_2%20Final.pdf',
                            appWindows:'https://bivproctor.work/downloads/windows/ipp-release',
                            appIOSDesktop:'https://bivproctor.work/downloads/macos/ipp-release'
                        },
                        zoom :{
                            sitio: 'https://zoom.us/download'
                        },
                    } 
                }
                
            }
        }
    }
</script>

<style scoped lang="sass">
    .container-custom
        font-family: "Poppins", sans-serif
        color: #828482
    .section-title
        color: #128DCD
        border-bottom: 1px solid
        font-weight: 700
        font-size: 24px
    .section-subtitle
        color: #828482
        font-weight: 400
        font-size: 24px
        padding: 14px 15px 0px 0px
    .section-content
        font-size: 16px
    .apps-google img 
        width: 170px
    .apps-apple img 
        width: 170px
    .apps-huawei img
        width: 170px
    .list-klarway
        list-style: none
    .instructivo 
        font-family: "DM Sans"
        font-size: 12px
        font-weight: 700
        text-decoration: none
        padding: 13px 34px 13px 34px
        border-radius: 23px
        color: white
        width: 178px
    .video-container
        position: relative
        overflow: hidden
        width: 90%
        padding-top: 56.25%
    .responsive-iframe
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%
</style>
