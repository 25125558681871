const statementTranslations = {
    es_CL: {
        subjectsTable: {
            breadcrumbs: {
                title: 'Asignaturas',
            },
            },
            subject: 'Asignatura',
    },
    es_PE: {
        subjectsTable: {
            breadcrumbs: {
                title: 'Cursos',
            },
            code: 'Código',
            subject: 'Curso'
        },
    },
    es: {
        actions: {
            edit: 'Editar',
            statements: 'Cargar enunciados',
            bulkUpload: 'Carga masiva enunciados',
            preview:'Ver pregunta'
        },
        subjectsTable: {
            breadcrumbs: {
                title: 'Materias',
                description: 'Administración de programas',
            },
                code: 'Código',
                subject: 'Materia',
                countPrograms: 'Cantidad de programas',
                module: 'Módulos',
                questionsFinal: 'Preguntas Final  \n(nivel - cantidad)',
                questionsPractice: 'Preguntas Práctica \n (nivel - cantidad)',
                updated: 'Actualizado',
                state: 'Estado',
                template: 'Template',
                newProgram: 'NUEVO PROGRAMA',
                name: 'Nombre',
        },
        programsCreate: {
            breadcrumbs: {
                title: 'Nuevo programa',
            },
                programName: 'Nombre programa',
                insertName: 'Ingrese nombre del programa',
                code: 'Código',
                cancel: 'Cancelar',
                create: 'Crear',
                next: 'Siguiente',
                finish: 'Finalizar',
                hasBeenCreated: 'ha sido creado',
                loadStatements: 'CARGAR ENUNCIADOS',
                labelEnable: 'Habilitado',
                labelDisable: 'Deshabilitado',
                success_message: {
                    title: 'Programa creado correctamente',
                    actions: {
                        primary_text: 'Cerrar',
                        secondary_text: 'Crear otro',
                        back: 'Volver',
                    },
                },
                validation_errors: {
                    name: 'Ingresa el nombre del programa',
                    code: 'Ingresa el código del programa',
                    code_unique: 'Código ya existente',
                    type: 'Selecciona un tipo de programa',
                    codeRequired: 'Código es requerido',
                    codeAlphanumeric: 'Solo alfanuméricos',
                    codeMaxLength: 'Máximo 5 caracteres',
                    errorDate : 'Fechas invalidas',
                    descriptionRequired: 'Nombre es requerido',
                    descriptionMaxLength: 'Máximo 60 caracteres',
                    descriptionAlphanumericWithSpaces: 'Solo alfanuméricos',
                },
        },
        programsUpdate: {
            breadcrumbs: {
                title: 'Editar programa',
            },
            save: 'Guardar',
            new_change: 'Hacer otro cambio',
            success_message: {
                title: 'Programa actualizado correctamente'
            }
        },
        modules:{
            success_message:{
                title:'Modificaciones realizadas con éxito'
            },
            error_message:{
                title:'Se produjo un error al modificar el programa'
            },
            breadcrumbs: {
                title: 'Cargar nuevo enunciado',
            },
            createModule:'Crear enunciado',
            importModule:'Importar enunciado',
            },
            statementsEdit: {
                breadcrumbs: {
                    title: 'Editar enunciado ',
                    description: 'Editar enunciado',
                },
            },
            statementsCreate: {
                breadcrumbs: {
                    title: 'Crear enunciado ',
                    description: 'Crear enunciado manualmente',
                },
                form:{
                    firstCard:{
                        title:'Caracteristica',
                        modalityTypes:'MODALIDAD',
                        modalityTypesLabel:'Modalidad',
                        examTypes:'TIPO DE EXAMEN',
                        examTypesLabel:'Tipo de examen',
                        questionTypes:'TIPO DE ENUNCIADO',
                        difficulty:'DIFICULTAD',
                        maxTime:'TIEMPO DE RESOLUCION',
                        statementTypes:'PARA APLICAR A',
                        statementTypesLabel:'Para aplicar a',
                        difficultyType: 'Dificultad'
                    },
                    secondCard:{
                        title:'Enunciado',
                        descriptionLabel:'Enunciado',
                        quantity:'CANTIDAD',
                        answer:'Respuesta | Respuestas',
                        answerLabel:'Respuesta',
                        isCorrect:'Correcta',
                        radioTrue:'Verdadero',
                        radioFalse:'Falso',
                        isImage:'Es imagen'
                    },
                    thirdCard:{
                        title:'Justificacíon',
                        descriptionLabel:'Enunciado',
                        isImage:'Es imagen'
                    },
                },
                success_message:{
                    title:'Enunciado creado correctamente',
                    titleUpdate:'Enunciado actualizado correctamente',
                },
                error_message:{
                    require:'Campo obligatorio',
                    anyCheck:'Seleccione una como correcta',
                    fail_server:'Hubo un error al guardar los datos',
                    incomplete: 'Por favor complete el formulario correctamente.'
                },
                warning_message:{
                    modify:'¿Esta seguro de modificar?',
                    disableUnidad:'Si deshabilitada esta unidad, tambien los temas de dicha unidad'
                }
            },
        components: {
            expanded: {
                enable:'Habilitar',
                disable:'Deshabilitar',
                addUnidad:'+ Agregar Unidad',
                addTema:'+ Agregar Tema',
            }
        },
        bulkUpload: {
            bulk: {
                title:'Carga masiva',
                subtitle:'Carga masiva de preguntas',
                description:'Cargue los enunciados',
            }
        },
        massImportDrawer: {
            breadcrumbs: {
                title: 'Carga masiva',
            },
            messages: {
                noFiles: 'No se detectaron archivos para enviar',
                sendConfirmation: '¿Desea enviar el siguiente archivo? No se podrá revertir',
                pendingFiles: 'Hay un archivo pendiente. ¿Desea salir?',
                success: 'Se cargaron correctamente los datos',
                error: 'Ocurrió un error al cargar el archivo',
            }
            }
        },
        en: {
        }
    };

export default statementTranslations
